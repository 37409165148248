import { postJSON, createAuthHeader } from 'src/utils/service';
import { createService } from 'src/services/service';
import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';

const logout = ({ token, gigyaUid }) => {
  return postJSON(endpointWithParams(ENDPOINTS.logout, { gigyaUid }), {
    headers: {
      Authorization: createAuthHeader(token),
    },
    body: {
      token,
    } as FixMe,
  });
};

export const logoutService = ({ devMode = false }: { devMode: boolean }) => {
  if (devMode) {
    return createService(
      () => Promise.resolve({} as FixMe),
      (res) => res,
    );
  }
  return createService(logout, (res) => res);
};
