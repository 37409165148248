import React, { useEffect, useState } from 'react';
import { RenderIf } from 'src/utils/render-if';
import { SidebarMenuComponent } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/sidebar-menu-jello';
import { PatientDataBarComponent } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/patient-data-bar-jello/patient-data-bar-jello.component';
import { DashboardTitleComponent } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/dashboard-title-jello/dashboard-title.component';
import { PeriodSelectors } from 'src/widgets/top-patient-bar/period-selectors-block-jello/period-selectors-block.component';

import { connect } from 'react-redux';
import { mapDispatchers } from 'src/utils';
import styles from './styles.module.css';

import {
  selectPatient,
  selectPatientId,
  selectPatientProfileType,
} from 'src/core/patient';
import {
  selectCGMSummary,
  selectHasCGMMeasurements,
  selectTotalMeasurements,
} from 'src/domains/patient-dashboards/cgm/store/selectors/cgm.selectors';
import { selectActiveDevices } from 'src/widgets/top-patient-bar/patient-info/patient-info.container';
import {
  selectPermissions,
  selectPatientPermissions,
  selectPatternsPermission,
} from 'src/core/permissions/permissions.selectors';
import { selectPatientHasMeasurementData } from 'src/domains/patient-dashboards/bg/store/bg.selectors';
import { fetchPatientRequest } from 'src/core/patient/patient.action';
import { selectUserProfile } from 'src/core/user/user.selectors';
import { withRouter } from 'react-router-dom';
import { getCGMSummaryRequest } from 'src/domains/patient-dashboards/cgm/store/cgm.actions';
import { selectPatientFhirId } from 'src/core';
import { isEmpty } from 'ramda';
import { VisitModuleSidebarAnchor } from '../../../modules/visit-module/sidebar/visit-module-sidebar-anchor';
import { ConditionalRouteForVisitModuleStickyPatientSummaryBar } from '../../../modules/visit-module/routes';

const mapStateToProps = (state, props) => ({
  patient: selectPatient(state),
  patientId: selectPatientId(state),
  permissions: selectPermissions(state),
  patientPermissions: selectPatientPermissions(state),
  patientProfileType: selectPatientProfileType(state),
  activeDevices: selectActiveDevices(state),
  cgmSummary: selectCGMSummary(state),
  cgmHasMeasurements: selectHasCGMMeasurements(state),
  cgmTotalMeasurements: selectTotalMeasurements(state),
  bgmHasMeasurements: selectPatientHasMeasurementData(state),
  patternsPermissions: selectPatternsPermission(state),
  profile: selectUserProfile(state),
  fhirId: selectPatientFhirId(state),
});

const mapDispatchToProps = mapDispatchers({
  fetchPatient: fetchPatientRequest.start,
  getCGMSummary: getCGMSummaryRequest.start,
});

export enum VisitModuleActiveSection {
  NOTES = 'NOTES',
  VIDEOCALL = 'VIDEOCALL',
  CLOSE = 'CLOSE',
}

export const JelloWrapper = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props) => {
    const [visitModuleActiveSection, setVisitModuleActiveSection] =
      useState<VisitModuleActiveSection>(VisitModuleActiveSection.CLOSE);

    useEffect(() => {
      props.fetchPatient({ patientId: props.match?.params?.id });
      if (isEmpty(props.patient)) {
        props.fetchPatient({ patientId: props.match?.params?.id });
      }
      if (props.fhirId) {
        props.getCGMSummary({ fhirId: props.fhirId });
      }
      window.addEventListener('vc-module-close-event', handleEvent);
    }, []);

    const handleEvent = (data: any) => {
      setVisitModuleActiveSection(VisitModuleActiveSection.CLOSE);
    };

    return (
      <>
        <PatientDataBarComponent
          visitModuleActiveSection={visitModuleActiveSection}
          setVisitModuleActiveSection={setVisitModuleActiveSection}
          {...props}
        />
        <div className={styles.wrapper}>
          <SidebarMenuComponent
            patientId={props?.patient?.id}
            location={window.location}
            permissions={props.permissions}
            patientPermissions={props.patientPermissions}
            cgmSummary={props.cgmSummary}
            activeDevices={props.activeDevices}
            patientProfileType={props.patientProfileType}
            profile={props.profile}
          />
          <div className={styles.scroll}>
            <jello-grid class={styles.height}>
              <jello-row class={styles.positionRelative}>
                <jello-col xs-auto class={styles.paddingCol}>
                  <jello-grid>
                    <jello-row class={styles.paddingRow}>
                      <jello-col xs-4 class={styles.title}>
                        <DashboardTitleComponent {...props} />
                      </jello-col>
                      <RenderIf
                        validate={
                          !(
                            location.href?.includes('strip-information') ||
                            location.href?.includes('delivery')
                          )
                        }
                      >
                        <jello-col xs-8 class={styles.title}>
                          <PeriodSelectors />
                        </jello-col>
                      </RenderIf>
                    </jello-row>
                    <jello-row>
                      <jello-col xs-auto>{props.children}</jello-col>
                    </jello-row>
                  </jello-grid>
                </jello-col>
                <jello-col>
                  <ConditionalRouteForVisitModuleStickyPatientSummaryBar
                    component={
                      <VisitModuleSidebarAnchor
                        activeSection={visitModuleActiveSection}
                      />
                    }
                  />
                </jello-col>
              </jello-row>
            </jello-grid>
          </div>
        </div>
      </>
    );
  }),
);

export const withJelloWrapper = (Component) => (props) => {
  const { isJelloActivated } = props;
  return (
    <>
      <RenderIf validate={isJelloActivated}>
        <JelloWrapper {...props}>
          <Component {...props} />
        </JelloWrapper>
      </RenderIf>
      <RenderIf validate={!isJelloActivated}>
        <Component {...props} />
      </RenderIf>
    </>
  );
};
