import React from 'react';

import { Bundle } from 'src/domains/diagnostics/components';

export const BloodGlucoseOverviewBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { BloodGlucoseOverviewContainer } = await import(
        /* webpackChunkName: "bloodGlucoseOverview" */ './scenes/blood-glucose-overview/blood-glucose-overview.container'
      );
      return BloodGlucoseOverviewContainer;
    }}
    bundleDidLoad={(BloodGlucoseOverviewContainer) => (
      <BloodGlucoseOverviewContainer {...props} />
    )}
  />
);

export const GraphBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { GraphContainer } = await import(
        /* webpackChunkName: "graphsBundle" */ './scenes/graphs'
      );
      return GraphContainer;
    }}
    bundleDidLoad={(GraphContainer) => <GraphContainer {...props} />}
  />
);

export const BloodGlucoseGeneralStatsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { BloodGlucoseGeneralStatsContainer } = await import(
        /* webpackChunkName: "bloodGlucoseGeneralStats" */ './widgets/blood-glucose-general-stats/blood-glucose-general-stats.container'
      );
      return BloodGlucoseGeneralStatsContainer;
    }}
    bundleDidLoad={(BloodGlucoseGeneralStatsContainer) => (
      <BloodGlucoseGeneralStatsContainer {...props} />
    )}
  />
);

export const DeviceDetailsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DeviceDetailsContainer } = await import(
        /* webpackChunkName: "deviceDetails" */ './widgets/blood-glucose-general-stats/components/device-details/device-details.container'
      );
      return DeviceDetailsContainer;
    }}
    bundleDidLoad={(DeviceDetailsContainer) => (
      <DeviceDetailsContainer {...props} />
    )}
  />
);

export const StandardWeekBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { StandardWeekDetailContainer } = await import(
        /* webpackChunkName: "standardWeek" */ './scenes/graphs/standard-week-detail/standard-week-detail.container'
      );
      return StandardWeekDetailContainer;
    }}
    bundleDidLoad={(StandardWeekDetailContainer) => (
      <StandardWeekDetailContainer {...props} />
    )}
  />
);

export const StandardDayDetailBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { StandardDayDetailContainer } = await import(
        /* webpackChunkName: "standardDayDetail" */ './scenes/graphs/standard-day-detail/standard-day-detail.container'
      );
      return StandardDayDetailContainer;
    }}
    bundleDidLoad={(StandardDayDetailContainer) => (
      <StandardDayDetailContainer {...props} />
    )}
  />
);

export const InsulinBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { InsulinContainer } = await import(
        /* webpackChunkName: "insulin" */ 'src/domains/diagnostics/scenes/insulin/insulin.container'
      );
      return InsulinContainer;
    }}
    bundleDidLoad={(InsulinContainer) => <InsulinContainer {...props} />}
  />
);

export const LogbookBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { LogbookContainer } = await import(
        /* webpackChunkName: "logbook" */ 'src/domains/diagnostics/widgets/logbook/logbook.container'
      );
      return LogbookContainer;
    }}
    bundleDidLoad={(LogbookContainer) => <LogbookContainer {...props} />}
  />
);

export const LogbookStatsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { LogbookStatsContainer } = await import(
        /* webpackChunkName: "logbookStats" */ 'src/domains/diagnostics/widgets/logbook-stats/logbook-stats.container'
      );
      return LogbookStatsContainer;
    }}
    bundleDidLoad={(LogbookStatsContainer) => (
      <LogbookStatsContainer {...props} />
    )}
  />
);

export const LogbookDiaryBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { LogbookDiaryContainer } = await import(
        /* webpackChunkName: "logbookDiary" */ 'src/domains/diagnostics/widgets/logbook-diary/logbook-diary.container'
      );
      return LogbookDiaryContainer;
    }}
    bundleDidLoad={(LogbookDiaryContainer) => (
      <LogbookDiaryContainer {...props} />
    )}
  />
);

export const Logbook24HoursBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { Logbook24HoursContainer } = await import(
        /* webpackChunkName: "logbook24Hours" */ 'src/domains/diagnostics/widgets/logbook-24hrs/logbook-24hrs.container'
      );
      return Logbook24HoursContainer;
    }}
    bundleDidLoad={(Logbook24HoursContainer) => (
      <Logbook24HoursContainer {...props} />
    )}
  />
);

export const MetabolicBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { MetabolicContainer } = await import(
        /* webpackChunkName: "metabolic" */ './scenes/graphs/metabolic/metabolic.container'
      );
      return MetabolicContainer;
    }}
    bundleDidLoad={(MetabolicContainer) => <MetabolicContainer {...props} />}
  />
);

/*export const InsulinPumpBundle = props => (
  <Bundle
    bundleWillLoad={async () => {
      const { InsulinPumpContainer } = await import(
        webpackChunkName: "insulinPump" 'src/domains/diagnostics/scenes/insulin-pump/insulin-pump.container'
      );
      return InsulinPumpContainer;
    }}
    bundleDidLoad={InsulinPumpContainer => <InsulinPumpContainer {...props} />}
  />
);*/

export const TrendDetailBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { TrendDetailContainer } = await import(
        /* webpackChunkName: "trendDetail" */ './scenes/graphs/trend/trend-detail/trend-detail.container'
      );
      return TrendDetailContainer;
    }}
    bundleDidLoad={(TrendDetailContainer) => (
      <TrendDetailContainer {...props} />
    )}
  />
);

export const TrendTrendBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { TrendTrendContainer } = await import(
        /* webpackChunkName: "trendTrend" */ './scenes/graphs/trend/trend-trend/trend-trend.container'
      );
      return TrendTrendContainer;
    }}
    bundleDidLoad={(TrendTrendContainer) => <TrendTrendContainer {...props} />}
  />
);

export const StandardDayTrendBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { StandardDayTrendContainer } = await import(
        /* webpackChunkName: "standardDayTrend" */ './scenes/graphs/standard-day-trend/standard-day-trend.container'
      );
      return StandardDayTrendContainer;
    }}
    bundleDidLoad={(StandardDayTrendContainer) => (
      <StandardDayTrendContainer {...props} />
    )}
  />
);

export const StandardWeekTrendBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { StandardWeekTrendContainer } = await import(
        /* webpackChunkName: "standardWeekTrend" */ './scenes/graphs/standard-week-trend/standard-week-trend.container'
      );
      return StandardWeekTrendContainer;
    }}
    bundleDidLoad={(StandardWeekTrendContainer) => (
      <StandardWeekTrendContainer {...props} />
    )}
  />
);

export const DeviceSettingsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DeviceSettingsContainer } = await import(
        /* webpackChunkName: "deviceSettings" */ './scenes/device-settings/device-settings.container'
      );
      return DeviceSettingsContainer;
    }}
    bundleDidLoad={(DeviceSettingsContainer) => (
      <DeviceSettingsContainer {...props} />
    )}
  />
);
