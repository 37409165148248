import * as React from 'react';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { TextModal } from '../../pdf-report.style';
import { IconWrapper, ButtonWrapper } from './pdf-report-progress.style';
import { Button, LoadingRing } from 'src/components';

const PdfReportsProgressComponentModal = ({ t }) => (
  <>
    <IconWrapper>
      <LoadingRing infinite />
    </IconWrapper>
    <TextModal textAlign="center">{t('pdfReport.inProgress')}</TextModal>
    <ButtonWrapper>
      <Button disabled={true} label={t('pdfReport.ok')} />
    </ButtonWrapper>
  </>
);

export const PdfReportsProgressModal = withTranslation(
  PdfReportsProgressComponentModal,
);
