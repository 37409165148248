import styled from 'styled-components';
import {
  flex,
  space,
  color,
  textAlign,
  flexDirection,
  alignItems,
  justifyContent,
} from 'styled-system';

import { colors, fontWeights } from 'src/core/styles';
import { convertPxToRem } from 'src/utils';
import { LocalizedText } from 'src/components';

const customBreakpointSmall = '1100px';
const customBreakpointMedium = '1301px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

Container.displayName = 'HypoglycaemiaCard_Container';

export const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 0 ${convertPxToRem(20)};
  background-color: ${(props) =>
    props.blueBG ? colors.blueMarineAlpha : colors.white};
  ${flexDirection};
  ${textAlign};
  ${flex};
  ${space};
  ${color};
  ${justifyContent};
  ${alignItems};

  @media (max-width: ${(props) =>
      props.isHomeDelivery && customBreakpointMedium}) {
    padding: 0;
  }
`;
Row.displayName = 'HypoglycaemiaCard_Row';

export const Block = styled.div`
  display: flex;
  align-items: center;
  ${alignItems};
  ${justifyContent};
  ${flex};
  ${flexDirection};
  ${space};
`;
Block.displayName = 'HypoglycaemiaCard_Block';

export const TotalQty = styled.span`
  font-size: ${convertPxToRem(32)};
  font-weight: 700;
  display: inline-block;
  color: ${colors.grayHypoglycemiaData};
`;
TotalQty.displayName = 'TotalQty';

export const TotalHyposText = styled(LocalizedText)`
  font-size: ${convertPxToRem(20)};
  font-weight: 700;
  margin-left: ${convertPxToRem(3)};
  display: inline-block;
  color: ${colors.grayHypoglycemiaData};
  text-transform: lowercase;
`;
TotalHyposText.displayName = 'TotalHyposText';

export const HypoText = styled(LocalizedText)`
  font-size: ${convertPxToRem(14)};
  display: inline-block;
  font-weight: ${fontWeights.semiBold};
  margin-bottom: ${convertPxToRem(10)};
  color: ${colors.greyMediumTimeRange};
`;
HypoText.displayName = 'HypoText';

export const HypoQty = styled.span`
  font-size: ${convertPxToRem(24)};
  margin-left: ${convertPxToRem(20)};
  color: ${colors.grayHypoglycemiaData};
`;
HypoQty.displayName = 'HypoQty';

export const DayNightText = styled(LocalizedText)`
  display: inline-block;
  font-size: ${convertPxToRem(15)};
  font-weight: ${fontWeights.regular};
  text-transform: lowercase;
  line-height: ${convertPxToRem(24)};

  @media (max-width: ${customBreakpointSmall}) {
    line-height: ${convertPxToRem(18)};
  }
`;
DayNightText.displayName = 'DayNightText';

export const DayNightQty = styled.span`
  margin-left: ${convertPxToRem(5)};
  margin-right: ${convertPxToRem(3)};
  display: inline-block;
  font-size: ${convertPxToRem(15)};
  font-weight: ${fontWeights.semiBold};
`;
DayNightQty.displayName = 'DayNightQty';

export const Circle = styled.span`
  height: ${convertPxToRem(9)};
  width: ${convertPxToRem(9)};
  border-radius: 50%;
  margin-right: ${convertPxToRem(10)};
  display: inline-block;
  &.veryLow {
    background: ${colors.hypo};
  }
  &.low {
    background: ${colors.warning};
  }
`;
Circle.displayName = 'Circle';

export const HypoWrapper = styled.div`
  font-weight: ${fontWeights.bold};
  font-size: ${convertPxToRem(12)};
  padding-bottom: ${convertPxToRem(5)};
  padding-left: ${convertPxToRem(5)};
  margin-top: ${convertPxToRem(10)};
  margin-bottom: ${convertPxToRem(8)};

  @media (max-width: ${customBreakpointSmall}) {
    margin-top: 0;
  }
`;
HypoWrapper.displayName = 'HypoWrapper';

export const HypoDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-left: ${convertPxToRem(10)};
`;
HypoDetailsWrapper.displayName = 'HypoDetailsWrapper';

export const DayNightWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${convertPxToRem(16)};
  margin-bottom: ${convertPxToRem(3)};
  margin-top: ${convertPxToRem(5)};
  font-weight: ${fontWeights.semiBold};
  color: ${colors.grayHypoglycemiaData};

  @media (max-width: ${customBreakpointSmall}) {
    align-items: start;
  }
`;
DayNightWrapper.displayName = 'DayNightWrapper';

export const HypoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
HypoContainer.displayName = 'HypoContainer';

export const BlockBreaker = styled.hr`
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #e6e6e6;
  margin: ${convertPxToRem(5)} 0;
`;
BlockBreaker.displayName = 'BlockBreaker';
