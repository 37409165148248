import * as React from 'react';

import { SvgIcon } from 'src/components/icon';
import { colors } from 'src/core/styles/colors';

type Props = {
  height?: number;
  width?: number;
  fillColor?: string;
  circle?: boolean;
  fillOpacity?: number;
  fillCircleColor?: string;
  originalWidth?: number;
  originalHeight?: number;
  minX?: number;
  minY?: number;
  radius?: string | number;
};

export const CheckmarkFilledIcon = ({
  fillColor = colors.white,
  width,
  height = 35,
  fillOpacity = 1,
  fillCircleColor = colors.white,
  originalWidth = 45,
  originalHeight = 35,
  minX,
  minY,
  radius = '20.8588152',
}: Props) => (
  <SvgIcon
    title="Checkmark"
    width={width}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
    minX={minX}
    minY={minY}
    radius={radius}
  >
    <circle
      id="Oval-3"
      fillOpacity={fillOpacity}
      fill={fillCircleColor}
      cx="21.5561742"
      cy="21.3372775"
      r={radius}
    />
    <svg width="35" height="35" viewBox="-12 -20 60 60">
      <path
        d="M43.054.014a1.72 1.72 0 0 0-1.027.53L13.18 29.855 2.855 20.938a1.711 1.711 0 0 0-1.709-.315 1.753 1.753 0 0 0-1.12 1.349c-.108.62.119 1.253.594 1.657l11.532 9.96a1.71 1.71 0 0 0 2.343-.11L44.478 3.015c.532-.527.673-1.34.35-2.021a1.724 1.724 0 0 0-1.774-.981z"
        fill={fillColor}
        fillRule="nonzero"
      />
    </svg>
  </SvgIcon>
);
