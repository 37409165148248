import { equals, path, pathOr } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';

import { selectEC6UserUnitMeasurement } from 'src/core/user/user.selectors';
import { selectCurrentDashboardLocation } from 'src/domains/general/widgets/store/hcp-dashboard.selectors';

import { selectIsAdministrative } from 'src/domains/general/widgets/store/hcp-dashboard.selectors';

import { DEFAULT_FILTERS } from 'src/domains/general/widgets/components/advanced-search/filters/filters.utils';

import { selectIsHcpOrGp } from 'src/core/user/user.selectors';
import { selectFhirPermission, selectHasMngHome } from 'src/core/permissions';

const UNIT_MMOLL = ' MMOLL';
const UNIT_MGDL = ' MGDL';

export const selectUnitGlucoseForService = createSelector(
  selectEC6UserUnitMeasurement,
  (unit) => (equals(unit, 'mmol/L') ? UNIT_MMOLL : UNIT_MGDL),
);

export const selectHcpDashboard = path(['hcpDashboard']);

export const selectFilters = createSelector(
  selectHcpDashboard,
  pathOr([], ['filters']),
);

export const selectDidSearch = createSelector(
  selectHcpDashboard,
  path(['didSearch']),
);

export const searchConnector = (state) =>
  createStructuredSelector({
    unitGlucoseForService: selectUnitGlucoseForService,
    filters: selectFilters,
    defaultFilters: () => DEFAULT_FILTERS,
    didSearch: selectDidSearch,
    isAdmin: selectIsAdministrative,
    mngHome: selectHasMngHome,
    isHcpOrGp: selectIsHcpOrGp,
    isFhir: selectFhirPermission,
    dashboardLocation: selectCurrentDashboardLocation,
  });
