import { compose, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { countlyEventTrigger } from 'src/navigation/countly';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import {
  includes,
  equals,
  path,
  pathOr,
  pipe,
  isEmpty,
  not,
  length,
  slice,
} from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { PROFESSIONAL_PROFILES } from 'src/widgets/forms/form.constants';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { withTranslation as translateHOC } from 'react-i18next';
import { isValidSearchTerm } from 'src/widgets/patient-search/search-widget/patient-search.validators';
import { DEFAULT_FILTERS } from 'src/domains/general/widgets/components/advanced-search/filters/filters.utils';
import { EVENTS_KEYS } from 'src/widgets/patient/create-patient/countly.constants';

import { withLoader } from 'src/components';
import { selectOngoingRequests } from 'src/core/request';
import {
  selectUserProfile,
  selectEC6UserUnitMeasurement,
  selectIs12HourFormat,
} from 'src/core/user/user.selectors';
import {
  selectIsEMR,
  selectFhirPermission,
  selectPlatformPermission,
} from 'src/core/permissions/permissions.selectors';
import { selectHasCreatePatient } from 'src/core/permissions/permissions.selectors';
import { switchPatient } from 'src/core/index';
import { withTranslation } from 'src/utils/i18n/with-translation';

import {
  clearCriteria,
  search,
  filterPatients,
  sortPatients,
  filterPatientsSet,
  setPage,
  setPerPage,
} from './hcp-dashboard.actions';

export const selectHcpDashboard = path(['hcpDashboard']);

export const selectDashboardPatients = createSelector(
  selectHcpDashboard,
  isEmpty(pathOr([], ['filterPatients']))
    ? pathOr([], ['allPatients'])
    : pathOr([], ['filterPatients']),
);

export const selectDashboardLocation = createSelector(
  selectHcpDashboard,
  pathOr(0, ['dashboarLocation']),
);

export const selectCurrentDashboardLocation = createSelector(
  selectDashboardLocation,
  (location) => (equals(location, 'BG') ? 'BG' : 'CGM'),
);

export const selectBgPatientsNumber = createSelector(
  selectHcpDashboard,
  pathOr(0, ['bgPatientsNumber']),
);

export const selectCgmPatientsNumber = createSelector(
  selectHcpDashboard,
  pathOr(0, ['cgmPatientsNumber']),
);

export const selectPatientsLength = createSelector(
  selectDashboardPatients,
  (patients) => length(patients),
);

export const selectFilters = createSelector(
  selectHcpDashboard,
  pathOr([], ['filters']),
);

export const selectPerPage = createSelector(
  selectHcpDashboard,
  pathOr(10, ['perPage']),
);

export const selectPage = createSelector(
  selectHcpDashboard,
  pathOr(1, ['page']),
);

export const selectIsGeneralPractitioner = createSelector(
  selectUserProfile,
  equals(PROFESSIONAL_PROFILES.GENERAL_PRACTITIONER),
);

export const selectIsAdministrative = createSelector(
  selectUserProfile,
  equals(PROFESSIONAL_PROFILES.ADMINISTRATIVE),
);

export const selectIsPayer = createSelector(
  selectUserProfile,
  equals(PROFESSIONAL_PROFILES.PAYER),
);

export const selectIsFetching = createSelector(
  selectOngoingRequests,
  includes('CLINICAL_STATISTICS'),
);

export const selectIsSearching = createSelector(
  selectHcpDashboard,
  path(['searching']),
);

export const selectIsUpdatingLocationIsolated = createSelector(
  selectHcpDashboard,
  path(['updatingDashboardLocationIsolated']),
);

export const selectHasNoPatients = createSelector(
  selectIsFetching,
  selectDashboardPatients,
  (loading, patients) => !loading && isEmpty(patients),
);

const UNIT_MMOLL = ' MMOLL';
const UNIT_MGDL = ' MGDL';

export const selectUnitGlucoseForService = createSelector(
  selectEC6UserUnitMeasurement,
  (unit) => (equals(unit, 'mmol/L') ? UNIT_MMOLL : UNIT_MGDL),
);

export const selectHasAppliedFilters = createSelector(
  selectFilters,
  pipe(equals(DEFAULT_FILTERS), not),
);

export const selectSearchTerm = createSelector(
  selectHcpDashboard,
  path(['searchTerm']),
);
export const selectActiveSort = createSelector(
  selectHcpDashboard,
  path(['sort']),
);

export const selectPaginatedPatients = createSelector(
  selectDashboardPatients,
  selectPerPage,
  selectPage,
  (patients, perPage: number, page: number) => {
    const start: number = perPage * (page - 1);
    const end: number = start + perPage;
    return slice(start, end, patients);
  },
);

export const selectHasSearch = createSelector(
  selectHcpDashboard,
  path(['hasSearch']),
);

export const hcpDashboardConnector = (state) =>
  createStructuredSelector({
    isLoading: selectIsFetching,
    isSearching: selectIsSearching,
    isGP: selectIsGeneralPractitioner,
    patients: selectPaginatedPatients,
    hasNoPatients: selectHasNoPatients,
    unitsGlucoseRanges: selectEC6UserUnitMeasurement,
    unitGlucoseForService: selectUnitGlucoseForService,
    routes: getCombinedRoutes,
    filters: selectFilters,
    hasAppliedFilters: selectHasAppliedFilters,
    searchTerm: selectSearchTerm,
    results: selectPatientsLength,
    currentPage: selectPage,
    resultsPerPage: selectPerPage,
    hasCreatePatient: selectHasCreatePatient,
    isEMR: selectIsEMR,
    isFhir: selectFhirPermission,
    is12hourTimeFormat: selectIs12HourFormat,
    activeSort: selectActiveSort,
    bgPatientsNumber: selectBgPatientsNumber,
    cgmPatientsNumber: selectCgmPatientsNumber,
    location: selectCurrentDashboardLocation,
    isPlatformUser: selectPlatformPermission,
  });

export const connectToDashboard = (Component) =>
  compose(
    connect(
      hcpDashboardConnector,
      mapDispatchers({
        switchPatient,
        search,
        filterPatients,
        clearCriteria,
        sortPatients,
        filterPatientsSet,
        setPage,
        setPerPage,
        goTo: (path) => push(path),
      }),
    ),
    withHandlers({
      onCreatePatient:
        ({ dispatch, routes }) =>
        () => {
          countlyEventTrigger(EVENTS_KEYS.NEW_PATIENT_PROCESS_INITIATED, {});
          return dispatch(push(routes.patient.createPatient));
        },
      onSearch: (props: any) => () => {
        const {
          filterPatientsSet,
          search,
          searchTerm,
          unitGlucoseForService,
          isFhir,
          location,
          isPlatformUser,
        } = props;
        filterPatientsSet(DEFAULT_FILTERS);
        search(
          unitGlucoseForService,
          location,
          searchTerm,
          isFhir,
          isPlatformUser,
        );
      },
      onClear: (props: any) => () => {
        const {
          filterPatientsSet,
          search,
          clearCriteria,
          unitGlucoseForService,
          location,
          isFhir,
        } = props;
        clearCriteria();
        filterPatientsSet(DEFAULT_FILTERS);
        search(unitGlucoseForService, location, '', isFhir);
      },
    }),
    lifecycle<any, any>({
      componentWillMount() {
        const { searchTerm, onSearch, onClear } = this.props;
        isValidSearchTerm(searchTerm) ? onSearch() : onClear();
      },
    }),
    withLoader({
      loaderProps: {
        infinite: true,
        flexibleHeight: true,
        minHeight: '500',
      },
      validators: {
        isLoading: (isLoading) => !isLoading,
        isSearching: (isSearching) => !isSearching,
      },
    }),
    withTranslation,
    translateHOC('translations'),
  )(Component);
