import * as React from 'react';
import { I18nTranslate } from 'src/utils/i18n/i18n.utils.types';

import { Block } from 'src/components';
import { withTranslation } from 'src/utils/i18n/with-translation';

import {
  ThresholdText,
  PercentageText,
  PercentageSymbol,
  MainText,
  InfoBlock,
  Keyline,
} from './stick-level.styles';

type StickLevelProps = {
  threshold?: number;
  value: number;
  bg: string;
  height: number;
  text: string;
  t: I18nTranslate;
  first?: boolean;
  last?: boolean;
};

const StickLevelComponent: React.FunctionComponent<StickLevelProps> = ({
  threshold,
  value,
  bg,
  height,
  text,
  first,
  last,
  t,
}) => (
  <Block
    height={height}
    minWidth="100%"
    maxWidth="100%"
    mb={last ? 0 : '3px'}
    display="flex"
    justifyContent="center"
  >
    <Block width="10%" textAlign="right" position="relative">
      <ThresholdText>{threshold}</ThresholdText>
    </Block>
    <Block
      bg={bg}
      width="13%"
      height="100%"
      maxWidth="28px"
      borderRadius={first ? '90px 90px 0 0' : last ? '0 0 90px 90px' : 0}
    />
    <Block height="100%" width="67%" display="flex" alignItems="center">
      <Keyline />
      <InfoBlock>
        <PercentageText>{value}</PercentageText>
        <PercentageSymbol>%</PercentageSymbol>
        <MainText>{t(text)}</MainText>
      </InfoBlock>
    </Block>
  </Block>
);

export const StickLevel = withTranslation(StickLevelComponent);
