import { colors, spacing, transitions } from 'src/core/styles';
import { createStyledComponent } from 'src/utils/styles';

export const ProfileDropdownContainer = createStyledComponent<'div', {}>('div')`
  position: relative;
`;
ProfileDropdownContainer.displayName = 'ProfileDropdownContainer';

export const ProfileDropdownAvatarAndNameContainer = createStyledComponent<
  'div',
  {}
>('div')`
  cursor: pointer;
  position: relative;
  margin: ${spacing.three} 0;
  display: flex;
  align-items: center;
`;
ProfileDropdownAvatarAndNameContainer.displayName =
  'ProfileDropdownAvatarAndNameContainer';

// Set hover state on SVG icon within
export const CaretDownContainer = createStyledComponent<'div', {}>('div')`
  display: inline-block;

  path {
    transition: ${transitions.default};
    fill: ${colors.charcoal};
  }

  &:hover,
  &:focus {
    path {
      fill: ${colors.brandBlue};
    }
  }
`;
CaretDownContainer.displayName = 'CaretDownContainer';

export const ProfileNameContainer = createStyledComponent<'span', {}>('span')`
  display: inline-block;
  padding-right: ${spacing.three};
  vertical-align: middle;
  color: ${colors.brandBlue};
  font-weight: 600;  
  text-overflow: ellipsis;
  width: max-content;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    height: max-content;
    white-space: nowrap;
    min-width: 115px;
    text-align: center;
  }
`;
ProfileNameContainer.displayName = 'ProfileNameContainer';

export const IconContainer = createStyledComponent<'div', {}>('div')`
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 2px solid ${colors.brandBlueDark};
  border-radius: 50%;
  position relative;
  transition: ${transitions.default};
  margin-right: ${spacing.three};

  &:hover {
    border-color: ${colors.brandBlue};
  }

  span {
    position absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
IconContainer.displayName = 'IconContainer';
