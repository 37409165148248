import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Bundle } from 'src/navigation/bundle';

export const BgDashboardGpBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { BgDashboardGpContainer } = await import(
        /* webpackChunkName: "bgDashboardGp" */ './bg/scenes'
      );
      return BgDashboardGpContainer;
    }}
    bundleDidLoad={(BgDashboardGpContainer) => (
      <BgDashboardGpContainer {...props} />
    )}
  />
);

export const BgDashboardBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { BgDashboardContainer } = await import(
        /* webpackChunkName: "bgDashboard" */ './bg/scenes'
      );
      return BgDashboardContainer;
    }}
    bundleDidLoad={(BgDashboardContainer) => (
      <BgDashboardContainer {...props} />
    )}
  />
);

export const CgmDashboardBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { CGMDashboard } = await import(
        /* webpackChunkName: "cgmDashboard" */ './cgm/scenes/dashboard/cgm-dashboard.container'
      );
      return CGMDashboard;
    }}
    bundleDidLoad={(CGMDashboard) => <CGMDashboard {...props} />}
  />
);

export const DashboardJelloBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { PatientDashboardJello } = await import(
        /* webpackChunkName: "bgDashboard" */ './patient-dashboard-jello'
      );
      return PatientDashboardJello;
    }}
    bundleDidLoad={(PatientDashboardJello) => (
      <PatientDashboardJello {...props} />
    )}
  />
);
