export const localeMomentMap = {
  'en-us': 'en-us',
  'en-gb': 'en-gb',
  'es-es': 'es',
  'pt-pt': 'pt',
  'pt-br': 'pt-br',
  'fr-fr': 'fr',
  'pl-pl': 'pl',
  'ca-es': 'ca',
  'ru-ru': 'ru',
  'da-dk': 'da',
  'nl-nl': 'nl-nl',
  'fi-fi': 'fi',
  'de-ch': 'de-ch',
  'de-de': 'de-de',
  'it-it': 'it-it',
  'no-no': 'no-no',
  'es-ar': 'es-ar',
  'sv-se': 'sv-se',
};
