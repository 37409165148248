import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { SquareHashIcon, CirclePlayIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles';

import { LegendItem } from './legend-item.component';

import { LegendList, LegendListsContainer } from '../graph-legend.style';

export const DeviceSettingsLegendComponent = ({ t, graph }) => (
  <LegendListsContainer>
    <LegendList noBorder width="auto">
      <LegendItem
        icon={<CirclePlayIcon fillColor={colors.brandBlue} />}
        label={t('deviceSettings.legend.activeProfile')}
      />
    </LegendList>
    <LegendList noBorder width="auto">
      <LegendItem
        icon={<SquareHashIcon />}
        label={t('deviceSettings.legend.valuePrevReport')}
      />
    </LegendList>
    <LegendList noBorder width="auto">
      <LegendItem
        icon={
          <SquareHashIcon
            textColor={colors.charcoal}
            fillColor={colors.silverLight}
            strokeColor={colors.grayLight}
          />
        }
        label={t('deviceSettings.legend.valueChangedLastReport')}
      />
    </LegendList>
  </LegendListsContainer>
);

export const DeviceSettingsLegend = withTranslation(
  DeviceSettingsLegendComponent,
);
