import * as C from './calendar.constants';

export const isDiffDate = (a: moment.Moment, b: moment.Moment) => {
  return a.diff(b, C.DAYS) !== 0;
};

export const getStartEndDates = (
  dateInfo: {
    endDate: string;
    firstMeasurementDate: string;
    lastMeasurementDate: string;
    range: string;
    startDate: string;
  },
  range?: number,
) => {
  const moment = require('moment');
  let startDate;
  let endDate;
  if (range) {
    const lastMeasurement = dateInfo.lastMeasurementDate.split('T')[0];
    endDate = moment(lastMeasurement);
    startDate = moment(lastMeasurement).subtract(range - 1, C.DAYS);
  } else {
    endDate = moment(dateInfo.endDate);
    startDate = moment(dateInfo.startDate);
  }
  return { startDate, endDate };
};

export const noMeasurements = (
  firstMeasurementDate: string,
  lastMeasurementDate: string,
  day: moment.Moment,
) => {
  const moment = require('moment');
  return !day
    .startOf(C.DAY)
    .isBetween(
      moment(firstMeasurementDate.split('T')[0])
        .startOf(C.DAY)
        .subtract(2, C.HOURS),
      moment(lastMeasurementDate.split('T')[0]).endOf(C.DAY),
    );
};

export const getDateFormat = (width: number | undefined) => {
  return !!width && width < C.FORMAT_BREACKPOINT
    ? C.DATE_FORMAT_SHORT
    : C.DATE_FORMAT_LONG;
};

export const getClassName = (
  width: number | undefined,
  isCgmAndDisabled?: string | boolean | undefined,
) => {
  return !!width && width < C.FORMAT_BREACKPOINT && !isCgmAndDisabled
    ? C.PICKER_SMALL
    : C.PICKER_LARGE;
};

export const afterEndDate = (endDate: moment.Moment, day: moment.Moment) => {
  return day.isAfter(endDate);
};

export const beforeStartDate = (
  startDate: moment.Moment,
  day: moment.Moment,
) => {
  return day.isBefore(startDate);
};
