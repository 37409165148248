import { createSelector, createStructuredSelector } from 'reselect';
import { path, pathOr } from 'ramda';

import { selectPatientId } from 'src/core/patient/patient.selector';

import { Program } from '../delivery-configuration.types';
import { convertDeliveryConfigurationDropdownOptions } from './delivery-configuration.utils';

export const selectDeliveryConfigurationState = path(['deliveryConfiguration']);

export const selectDeliveryConfigurations = createSelector(
  selectDeliveryConfigurationState,
  pathOr([], ['configurations']),
);

export const selectDeliveryConfigurationDropdownOptions = createSelector(
  selectDeliveryConfigurations,
  (configs) =>
    convertDeliveryConfigurationDropdownOptions(configs as Program[]),
);

export const selectPatientProgram = createSelector(
  selectDeliveryConfigurationState,
  path(['patientProgram']),
);

export const selectFetchingDeliveryConfigurations = createSelector(
  selectDeliveryConfigurationState,
  path(['isFetchingDeliveryConfiguration']),
);

export const deliveryConfigurationConnector = createStructuredSelector({
  deliveryConfigurationPrograms: selectDeliveryConfigurationDropdownOptions,
  patientId: selectPatientId,
  allPrograms: selectDeliveryConfigurations,
  patientProgram: selectPatientProgram,
  isFetchingDeliveryConfiguration: selectFetchingDeliveryConfigurations,
});
