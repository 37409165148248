import { TFunction } from 'i18next';
import * as React from 'react';
import {
  TrendGraphIconsComponent,
  MetabolicRateIconsComponent,
  StatusIconsComponent,
  BolusIconsComponent,
  GeneralIconsComponent,
  LogbookIconsComponent,
} from './components';
import {
  IconsViewComponentSections,
  IconsSections,
  IconsViewComponentBlock,
} from './icons-view.style';
import {
  FlexWrapperDiv,
  BlockTitle,
  TextBlock,
  SingleBlockText,
} from '../../manufacturer-info-modal.style';

type Props = {
  appVersion?: string;
  t: TFunction;
};

const sectionsIcons = [
  GeneralIconsComponent(),
  TrendGraphIconsComponent(),
  MetabolicRateIconsComponent(),
  LogbookIconsComponent(),
  StatusIconsComponent(),
  BolusIconsComponent(),
];

export const IconsViewComponent: React.FunctionComponent<Props> = ({
  appVersion,
  t,
}: Props) => (
  <FlexWrapperDiv>
    <IconsViewComponentBlock>
      <BlockTitle>
        <jello-text
          class="jello-text-demo__component"
          size="XL"
          weight="semibold"
        >
          {t('manufacturerInfo.icons')}
        </jello-text>
      </BlockTitle>
      <TextBlock>
        <SingleBlockText>
          <jello-text
            class="jello-text-demo__component"
            size="S"
            weight="regular"
          >
            {`${t('manufacturerInfo.genInfoText1')}  ${appVersion}`}
          </jello-text>
        </SingleBlockText>
      </TextBlock>
      <TextBlock>
        <jello-text
          class="jello-text-demo__component"
          size="S"
          weight="regular"
        >
          {`${t('manufacturerInfo.genInfoText2')}`}
        </jello-text>
      </TextBlock>
      <IconsViewComponentSections>
        <IconsSections>{sectionsIcons}</IconsSections>
      </IconsViewComponentSections>
    </IconsViewComponentBlock>
  </FlexWrapperDiv>
);
