import * as React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { SvgIcon } from 'src/components/icon';
import { colors } from 'src/core/styles/colors';

const originalWidth = 12;
const originalHeight = 12;
// tslint:disable-next-line: no-identical-expressions
const aspectRatio = originalWidth / originalWidth;

export const ClockIconComponent = ({
  height = originalHeight,
  fillColor = colors.white,
}) => (
  <SvgIcon
    width={height * aspectRatio}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
  >
    <g
      id="01_Register-note-from-time-tracking"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="01_01"
        transform="translate(-159.000000, -105.000000)"
        fill={fillColor}
      >
        <g id="Dashboard" transform="translate(-1.000000, 0.000000)">
          <g id="Tags/Shared" transform="translate(146.000000, 99.000000)">
            <g
              id="Organization/Time/alarm--add/16px"
              transform="translate(14.000000, 6.000000)"
            >
              <path
                d="M6,1 C9.03756612,1 11.5,3.46243388 11.5,6.5 C11.5,7.95869069 10.9205374,9.35763722 9.8890873,10.3890873 C8.85763722,11.4205374 7.45869069,12 6,12 C2.96243388,12 0.5,9.53756612 0.5,6.5 C0.5,3.46243388 2.96243388,1 6,1 Z M6,2 C3.51471863,2 1.5,4.01471863 1.5,6.5 C1.5,8.98528137 3.51471863,11 6,11 C8.48528137,11 10.5,8.98528137 10.5,6.5 C10.5,4.01471863 8.48528137,2 6,2 Z M6.5,3.5 L6.5,6.29 L8,7.795 L7.295,8.5 L5.5,6.705 L5.5,3.5 L6.5,3.5 Z M1.790821,0.00157762575 L2.49866787,0.707943539 L0.708030285,2.50233536 L0.000183410994,1.79596945 L1.790821,0.00157762575 Z M10.2075756,-0.00281153863 L11.9982132,1.79158029 L11.2903663,2.4979462 L9.49972871,0.703554374 L10.2075756,-0.00281153863 Z"
                id="-Change-color-here"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const ClockIcon = withTranslation(ClockIconComponent);
