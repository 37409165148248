import * as React from 'react';

export const PrintAndDownloadIcon = ({ isEnabled }) => (
  <jello-icon-button
    icon-name="printer"
    size="L"
    variant="secondary"
    {...(!isEnabled && {
      disabled: '',
    })}
  />
);
