import * as React from 'react';

import { Plot, ReversePlot } from 'src/lib';
import { colors, strokeWidth } from 'src/core/styles';
import { RenderIf } from 'src/domains/diagnostics/utils';

const Frame = ({ width, height, yAxisWidth, xAxisHeight }: any) => (
  <React.Fragment>
    <line
      x1={width * yAxisWidth}
      x2={width * yAxisWidth}
      y1={height * xAxisHeight}
      y2={height * (1 - xAxisHeight)}
      strokeWidth={strokeWidth.one}
      stroke={colors.silverDark}
    />
    <line
      x1={width * (1 - yAxisWidth)}
      x2={width * (1 - yAxisWidth)}
      y1={height * xAxisHeight}
      y2={height * (1 - xAxisHeight)}
      strokeWidth={strokeWidth.one}
      stroke={colors.silverDark}
    />
    <line
      x1={width * yAxisWidth}
      x2={width * (1 - yAxisWidth)}
      y1={height * xAxisHeight}
      y2={height * xAxisHeight}
      strokeWidth={strokeWidth.one}
      stroke={colors.silverDark}
    />
    <line
      x1={width * yAxisWidth}
      x2={width * (1 - yAxisWidth)}
      y1={height * (1 - xAxisHeight)}
      y2={height * (1 - xAxisHeight)}
      strokeWidth={strokeWidth.one}
      stroke={colors.silverDark}
    />
  </React.Fragment>
);

export const GraphPlot = ({
  width,
  height,
  xAxisHeight,
  yAxisWidth,
  plotWidth,
  plotHeight,
  subPlotHeight,
  yAxisLeft = () => null,
  yAxisRightTop = () => null,
  yAxisRightBottom = () => null,
  xAxisBottom = () => null,
  xAxisTop = () => null,
  mainGraph = () => null,
  upperSubGraph = () => null,
  lowerSubGraph = () => null,
}: any) => (
  <React.Fragment>
    <Frame
      width={width}
      height={height}
      xAxisHeight={xAxisHeight}
      yAxisWidth={yAxisWidth}
    />

    <RenderIf validate={yAxisLeft}>
      <Plot
        id="y-axis-left"
        x={0}
        y={0}
        width={width * yAxisWidth}
        height={height}
      >
        {yAxisLeft({
          width: width * yAxisWidth,
          height: height * plotHeight,
          y: height * xAxisHeight,
        })}
      </Plot>
    </RenderIf>

    <RenderIf validate={xAxisBottom}>
      <Plot
        id="x-axis-bottom"
        x={0}
        y={height * (plotHeight + xAxisHeight)}
        width={width}
        height={height * xAxisHeight}
      >
        {xAxisBottom({
          width: width * plotWidth,
          height: height * xAxisHeight,
          x: width * yAxisWidth,
        })}
      </Plot>
    </RenderIf>

    <RenderIf validate={yAxisRightTop}>
      <ReversePlot
        id="y-axis-right-top"
        x={width * (plotWidth + yAxisWidth)}
        y={0}
        width={width * yAxisWidth}
        height={height * (subPlotHeight + 2 * xAxisHeight)}
      >
        {yAxisRightTop({
          width: width * yAxisWidth,
          height: height * subPlotHeight,
          y: height * xAxisHeight,
        })}
      </ReversePlot>
    </RenderIf>

    <RenderIf validate={yAxisRightBottom}>
      <Plot
        id="y-axis-right-bottom"
        x={width * (plotWidth + yAxisWidth)}
        y={height * (1 - (subPlotHeight + 2 * xAxisHeight))}
        width={width * yAxisWidth}
        height={height * (subPlotHeight + 2 * xAxisHeight)}
      >
        {yAxisRightBottom({
          width: width * yAxisWidth,
          height: height * subPlotHeight,
          y: height * xAxisHeight,
        })}
      </Plot>
    </RenderIf>

    <RenderIf validate={xAxisTop}>
      <ReversePlot
        id="x-axis-top"
        x={0}
        y={0}
        width={width * plotWidth + width * yAxisWidth * 2}
        height={height * xAxisHeight}
      >
        {xAxisTop({
          width: width * plotWidth,
          height: height * xAxisHeight,
          x: width * yAxisWidth,
        })}
      </ReversePlot>
    </RenderIf>

    <RenderIf validate={mainGraph}>
      <Plot
        id="main-graph-plot"
        x={width * yAxisWidth}
        y={height * xAxisHeight}
        width={width * plotWidth}
        height={height * plotHeight}
      >
        {mainGraph({
          width: width * plotWidth,
          height: height * plotHeight,
        })}
      </Plot>
    </RenderIf>

    <RenderIf validate={lowerSubGraph}>
      <Plot
        id="lower-subgraph-plot"
        x={width * yAxisWidth}
        y={height * (1 - (subPlotHeight + xAxisHeight))}
        width={width * plotWidth}
        height={height * subPlotHeight}
      >
        {lowerSubGraph({
          width: width * plotWidth,
          height: height * subPlotHeight,
        })}
      </Plot>
    </RenderIf>

    <RenderIf validate={upperSubGraph}>
      <ReversePlot
        id="upper-subgraph-plot"
        x={width * yAxisWidth}
        y={height * xAxisHeight}
        width={width * plotWidth}
        height={height * subPlotHeight}
      >
        {upperSubGraph({
          width: width * plotWidth,
          height: height * subPlotHeight,
        })}
      </ReversePlot>
    </RenderIf>
  </React.Fragment>
);
