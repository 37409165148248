import { path, equals } from 'ramda';
import { createStructuredSelector, createSelector } from 'reselect';

import { selectEC6UserUnitMeasurement } from 'src/core/user/user.selectors';

import { selectDataSharingConsentResponse, selectDataSharingHasBeenCalled } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.selector';
import { selectFhirPermission, selectPlatformPermission } from '../permissions';

export const UNIT_MMOLL = 'MMOLL';
const UNIT_MGDL = 'MGDL';

export const selectPatientStartDate = path([
  'ui',
  'patientDateRange',
  'startDate',
]);

export const selectPatientRange = path(['ui', 'patientDateRange', 'range']);

export const selectPatientEndDate = path(['ui', 'patientDateRange', 'endDate']);

export const selectPatientFirstMeasurementDate = path([
  'ui',
  'patientDateRange',
  'firstMeasurementDate',
]);

export const selectPatientLastMeasurementDate = path([
  'ui',
  'patientDateRange',
  'lastMeasurementDate',
]);

export const selectUnitMeasurementForService = createSelector(
  selectEC6UserUnitMeasurement,
  (bgUnit) => (equals(bgUnit, 'mmol/L') ? UNIT_MMOLL : UNIT_MGDL),
);

export const dateRangeConnector = createStructuredSelector({
  range: selectPatientRange,
  startDate: selectPatientStartDate,
  endDate: selectPatientEndDate,
  firstMeasurementDate: selectPatientFirstMeasurementDate,
  lastMeasurementDate: selectPatientLastMeasurementDate,
  dataSharingResponse: selectDataSharingConsentResponse,
  dataSharingCalled: selectDataSharingHasBeenCalled,
  hasUserFhirPermission: selectFhirPermission,
  hasUserPlatformPermission: selectPlatformPermission,
});
