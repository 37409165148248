import * as React from 'react';
import { ThemeInterface } from 'src/theme';
import { withTheme } from 'styled-components';
import { SelectOption } from '../dropdown-select.types';
import {
  DropdownCalendarProps,
  DropdownCalendarState,
} from './dropdown-calendar.types';
import { DisplayValue } from './dropdown-calendar-display-value.component';
import { DisplayOption } from './dropdown-calendar-display-option.component';
import { DropdownButtons } from '../dropdown-buttons';
export class DropdownCalendarComponent extends React.Component<
  DropdownCalendarProps<SelectOption<string | number>>,
  DropdownCalendarState<SelectOption<string | number>>
> {
  constructor(props: DropdownCalendarProps<SelectOption<string | number>>) {
    super(props);
  }

  public handleOnChange = (item: any) => {
    this.setState({ selectedOption: item });
    if (this.props.onChange) {
      this.props.onChange(item);
    }
  };

  public render() {
    const { data, titleOptions, placeholder } = this.props;
    return (
      <DropdownButtons
        data={data}
        invertButtons={true}
        placeholder={placeholder}
        titleOptions={titleOptions}
        onChange={this.handleOnChange}
        selectedOption={this.props.selectedOption}
        DisplayComponent={DisplayValue}
        DisplayOptionComponent={DisplayOption}
      />
    );
  }
}

export const DropdownCalendar = withTheme<
  { theme?: ThemeInterface } & DropdownCalendarProps<
    SelectOption<string | number>
  >,
  ThemeInterface
>(DropdownCalendarComponent);
