import * as React from 'react';
import styled from 'styled-components';

import { combineRems } from 'src/utils';

import { FormLabelProps } from './form-label.types';

export const styleFormLabel = (
  FormLabelComponent: React.FunctionComponent<FormLabelProps>,
) => styled(FormLabelComponent)`
  color: ${({ theme }) => theme.colors.black};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: 1;
  margin: 0 auto
    ${({ theme }) => combineRems(theme.spacing.one, theme.spacing.two)};
  > a {
    color: ${({ theme }) => theme.colors.blueMarine} !important;
  }
`;

export const OptionalSpan = styled.span`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: ${({ theme }) => theme.fontSize.caption};
  padding-left: ${({ theme }) => theme.spacing.one};
  position: relative;
`;

export const AditionalSpan = styled.span`
  float: right;
`;
