import * as React from 'react';
import { SvgIcon } from 'src/components/icon';

type NonConsentsIconProps = {
  width: number;
  height: number;
};

export const NonConsentsIcon: React.FC<NonConsentsIconProps> = ({
  width,
  height,
}): JSX.Element => (
  <SvgIcon
    width={width}
    height={height}
    originalWidth={width}
    originalHeight={height}
  >
    <g clipPath="url(#clip0_2932_169395)">
      <path
        d="M96.1914 191.998C142.786 191.998 180.559 154.226 180.559 107.631C180.559 61.0364 142.786 23.2639 96.1914 23.2639C49.5967 23.2639 11.8242 61.0364 11.8242 107.631C11.8242 154.226 49.5967 191.998 96.1914 191.998Z"
        fill="#E4E9ED"
      />
      <path
        d="M147.295 150.974L96.1914 179.038L45.0879 150.974L96.1914 123.502L147.295 150.974Z"
        fill="#00239A"
      />
      <path
        d="M147.295 150.974L96.1917 179.038L70.752 165.054L122.031 137.39L147.295 150.974Z"
        fill="#0038FF"
      />
      <path
        d="M147.295 133.423L96.1914 161.486L45.0879 133.423L96.1914 105.951L147.295 133.423Z"
        fill="#00CE62"
      />
      <path
        d="M147.295 133.423L96.1917 161.486L70.752 147.519L122.031 119.839L147.295 133.423Z"
        fill="#0BEB71"
      />
      <path
        d="M141.07 117.535V63.4233H50.9111V117.535L95.9987 142.287L141.07 117.535Z"
        fill="#0038FF"
      />
      <path
        d="M141.07 63.4235L95.9987 88.1752L50.9111 63.4235L95.9987 39.1997L141.07 63.4235Z"
        fill="#00239A"
      />
      <path
        d="M95.999 122.927V88.1751L141.071 63.4233V117.535L95.999 142.287V122.927Z"
        fill="#227CFF"
      />
      <path
        d="M12.0801 149.391C18.7518 149.391 24.1602 143.983 24.1602 137.311C24.1602 130.639 18.7518 125.231 12.0801 125.231C5.40846 125.231 0 130.639 0 137.311C0 143.983 5.40846 149.391 12.0801 149.391Z"
        fill="#FF7208"
      />
      <path
        opacity="0.82"
        d="M6.30364 126.719C6.30364 126.719 57.2631 99.519 66.351 94.7671C67.215 94.3191 67.695 94.2551 67.711 94.2551C71.823 93.2791 76.8309 96.8151 79.2469 102.591C81.5989 108.223 80.6229 113.999 77.1829 116.207C77.1349 116.239 18.0315 147.855 18.0315 147.855C17.6635 148.063 17.2795 148.255 16.8795 148.415C10.7516 151.055 3.64767 148.239 0.991692 142.111C-1.50428 136.335 0.879693 129.679 6.30364 126.719Z"
        fill="url(#paint0_linear_2932_169395)"
      />
      <path
        d="M76.3811 116.641C80.5588 114.814 81.7843 108.391 79.1182 102.294C76.4522 96.1977 70.9042 92.7364 66.7265 94.5634C62.5488 96.3903 61.3234 102.814 63.9894 108.91C66.6555 115.007 72.2034 118.468 76.3811 116.641Z"
        fill="white"
      />
      <path
        d="M179.918 149.087C186.59 149.087 191.998 143.679 191.998 137.007C191.998 130.335 186.59 124.927 179.918 124.927C173.246 124.927 167.838 130.335 167.838 137.007C167.838 143.679 173.246 149.087 179.918 149.087Z"
        fill="#FF7208"
      />
      <path
        opacity="0.82"
        d="M185.694 126.415C185.694 126.415 134.734 99.2151 125.646 94.4631C124.782 94.0151 124.302 93.9511 124.286 93.9511C120.174 92.9751 115.167 96.5111 112.751 102.287C110.399 107.919 111.375 113.695 114.815 115.903C114.863 115.935 173.966 147.551 173.966 147.551C174.334 147.759 174.718 147.951 175.118 148.111C181.246 150.751 188.35 147.935 191.006 141.807C193.502 136.031 191.118 129.375 185.694 126.415Z"
        fill="url(#paint1_linear_2932_169395)"
      />
      <path
        d="M128.024 108.633C130.691 102.536 129.465 96.113 125.287 94.2861C121.11 92.4592 115.562 95.9204 112.896 102.017C110.23 108.113 111.455 114.537 115.633 116.364C119.81 118.191 125.358 114.729 128.024 108.633Z"
        fill="white"
      />
      <path
        d="M96.2071 24.1602C102.879 24.1602 108.287 18.7518 108.287 12.0801C108.287 5.40846 102.879 0 96.2071 0C89.5354 0 84.127 5.40846 84.127 12.0801C84.127 18.7518 89.5354 24.1602 96.2071 24.1602Z"
        fill="#FF7208"
      />
      <path
        opacity="0.82"
        d="M96.2068 0C89.5349 0 84.127 5.40795 84.127 12.0799C84.127 12.5759 84.1589 13.0559 84.2229 13.5359V62.5914C84.2229 66.2873 89.6149 69.2953 96.2548 69.2953C102.895 69.2953 108.287 66.2873 108.287 62.5914V12.0799C108.287 5.40795 102.879 0 96.2068 0Z"
        fill="url(#paint2_linear_2932_169395)"
      />
      <path
        d="M96.2548 69.3115C102.9 69.3115 108.287 66.3099 108.287 62.6074C108.287 58.9048 102.9 55.9033 96.2548 55.9033C89.6096 55.9033 84.2227 58.9048 84.2227 62.6074C84.2227 66.3099 89.6096 69.3115 96.2548 69.3115Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2932_169395"
        x1="70.719"
        y1="105.599"
        x2="8.22362"
        y2="139.791"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.13" stopColor="white" />
        <stop offset="0.36" stopColor="white" stopOpacity="0.99" />
        <stop offset="0.48" stopColor="white" stopOpacity="0.96" />
        <stop offset="0.57" stopColor="white" stopOpacity="0.91" />
        <stop offset="0.65" stopColor="white" stopOpacity="0.83" />
        <stop offset="0.73" stopColor="white" stopOpacity="0.73" />
        <stop offset="0.79" stopColor="white" stopOpacity="0.61" />
        <stop offset="0.86" stopColor="white" stopOpacity="0.47" />
        <stop offset="0.91" stopColor="white" stopOpacity="0.3" />
        <stop offset="0.97" stopColor="white" stopOpacity="0.12" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2932_169395"
        x1="6215.91"
        y1="3733.85"
        x2="8716.42"
        y2="5725.72"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.13" stopColor="white" />
        <stop offset="0.36" stopColor="white" stopOpacity="0.99" />
        <stop offset="0.48" stopColor="white" stopOpacity="0.96" />
        <stop offset="0.57" stopColor="white" stopOpacity="0.91" />
        <stop offset="0.65" stopColor="white" stopOpacity="0.83" />
        <stop offset="0.73" stopColor="white" stopOpacity="0.73" />
        <stop offset="0.79" stopColor="white" stopOpacity="0.61" />
        <stop offset="0.86" stopColor="white" stopOpacity="0.47" />
        <stop offset="0.91" stopColor="white" stopOpacity="0.3" />
        <stop offset="0.97" stopColor="white" stopOpacity="0.12" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2932_169395"
        x1="96.2068"
        y1="69.2953"
        x2="96.2144"
        y2="5.94383e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.13" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_2932_169395">
        <rect width="191.999" height="191.998" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
