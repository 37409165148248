import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { ArrowIcon } from 'src/assets/icons';
import { LocalizedText } from 'src/domains/diagnostics/components';
import { colors } from 'src/core';

import { getTableHeaderColumnConfig } from './logbook-stats-table-header.util';
import {
  CaretWrapper,
  HeaderCell,
  HeaderColumn,
  HeaderDayColumn,
  HeaderColumnInner,
  LogbookStatsTableHeaderWrapper,
  DateWrapper,
  Row,
} from './logbook-stats-table-header.style';

import { LOGBOOK_LOGBOOK_MIN_WIDTH } from '../../scenes/graphs/graph.constants';

const columnHeaderRowSizes = [1.5, 2, null];

const LogbookStatsTableHeaderColumn = ({ colSpan, headerRows }) => (
  <HeaderColumn flex={colSpan}>
    {headerRows.map((subColumns, index) => (
      <Row key={index} flex={columnHeaderRowSizes[index]}>
        {subColumns.map((cell, subIndex) => (
          <LogbookStatsTableHeaderColumnCell
            key={`${index}-${subIndex}`}
            row={cell}
            index={index}
          />
        ))}
      </Row>
    ))}
  </HeaderColumn>
);

const LogbookStatsTableHeaderColumnCell = ({
  row: { colSpan, textKeys },
  index,
}) => (
  <HeaderColumnInner flex={colSpan}>
    <HeaderCell
      bold={index < 2}
      borderBottom={{
        color: index < 2 ? colors.blueMarine : 'none',
        size: index === 1 ? '0.125rem' : null,
      }}
      textAlign={index < 2 ? 'left' : 'center'}
    >
      {textKeys.map((textKey, index) => (
        <LocalizedText key={`${textKey}-${index}`} textKey={textKey} />
      ))}
    </HeaderCell>
  </HeaderColumnInner>
);

export const LogbookStatsTableHeaderComponent = ({ bloodGlucoseUnit, t }) => (
  <LogbookStatsTableHeaderWrapper
    minWidth={LOGBOOK_LOGBOOK_MIN_WIDTH}
    height={7.35}
    paddingBottom={0.2}
  >
    <Row>
      <HeaderDayColumn flex={1}>
        <Row>
          <HeaderColumn>
            <HeaderCell bold>
              <DateWrapper>
                <span>{t('graphs.logbookStats.date')}</span>
                <CaretWrapper>
                  <ArrowIcon fillColor={colors.blueMarine} height={8} />
                </CaretWrapper>
              </DateWrapper>
            </HeaderCell>
          </HeaderColumn>
        </Row>
      </HeaderDayColumn>
      {getTableHeaderColumnConfig(bloodGlucoseUnit).map(
        ({ colSpan, headerRows }, index) => (
          <LogbookStatsTableHeaderColumn
            key={index}
            colSpan={colSpan}
            headerRows={headerRows}
          />
        ),
      )}
    </Row>
  </LogbookStatsTableHeaderWrapper>
);

export const LogbookStatsTableHeader = withTranslation(
  LogbookStatsTableHeaderComponent,
);
