import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { LocalizedText } from 'src/components';
import { RenderIf } from 'src/utils';

import { Container, Title } from './graph-legend.style';
import {
  DetailGraphLegend,
  LogbookGraphLegend,
  LogbookStatsGraphLegend,
  TrendGraphLegend,
  MetabolicGraphLegend,
  InsulinPumpLegend,
} from './components';

export const GraphLegendComponent = ({ graphType, t }) => (
  <Container>
    <Title>
      <LocalizedText textKey="graphDetails.legend.legend" />
    </Title>
    <RenderIf validate={graphType === 'detail'}>
      <DetailGraphLegend t={t} />
    </RenderIf>
    <RenderIf validate={graphType === 'logbook'}>
      <LogbookGraphLegend t={t} />
    </RenderIf>
    <RenderIf validate={graphType === 'logbook-stats'}>
      <LogbookStatsGraphLegend t={t} />
    </RenderIf>
    <RenderIf validate={graphType === 'trend'}>
      <TrendGraphLegend t={t} />
    </RenderIf>
    <RenderIf validate={graphType === 'metabolic'}>
      <MetabolicGraphLegend t={t} />
    </RenderIf>
    <RenderIf validate={graphType === 'insulin-pump'}>
      <InsulinPumpLegend t={t} />
    </RenderIf>
  </Container>
);

export const GraphLegend = withTranslation(GraphLegendComponent);
