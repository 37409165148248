import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { TableHeader } from 'src/domains/diagnostics/components';
import { colors } from 'src/core/styles';

import {
  LogbookTableCell,
  LogbookTableRow,
  MealTimeIconsCell,
  MealTimeLabelCell,
  LogbookUnitsHeader,
} from '..';

const mealTimes = ['night', 'breakfast', 'lunch', 'dinner', 'bedTime'];

export const MealTimesTableHeaderComponent = ({
  children,
  t,
  bloodGlucoseUnit,
}) => (
  <TableHeader bg={colors.blueMarineAlpha5}>
    <LogbookTableRow flex={1}>
      <MealTimeLabelCell
        key={'meal time label header - DATE-0'}
        keyText={0}
        mealTime={t('graphs.logbookDiary.date')}
        alignItems="left"
      />

      {mealTimes.map((m, i) => (
        <MealTimeLabelCell
          key={`meal time label header - ${m}-${i + 1}`}
          keyText={`${i + 1}`}
          mealTime={t('general.mealBlocks.' + m)}
          alignItems="left"
          width={'9rem'}
        />
      ))}
      <MealTimeLabelCell
        key={'meal time label header - DATE-6'}
        keyText={6}
        mealTime={t('graphs.logbookDiary.notes')}
        alignItems="left"
      />
    </LogbookTableRow>
    <LogbookTableRow flex={1}>
      <LogbookTableCell />
      {mealTimes.map((m, i) => (
        <MealTimeIconsCell
          key={`meal time icon header - ${m}-${i}`}
          keyText={`${i}`}
          mealTime={m.toUpperCase()}
          alignItems="left"
        />
      ))}
      <LogbookTableCell p={1} />
    </LogbookTableRow>
    <LogbookTableRow flex={1}>
      <LogbookTableCell width="6.7rem" />
      <LogbookUnitsHeader bloodGlucoseUnit={bloodGlucoseUnit} />
    </LogbookTableRow>
  </TableHeader>
);

export const MealTimesTableHeader = withTranslation(
  MealTimesTableHeaderComponent,
);
