import React from 'react';
import { Bundle } from 'src/navigation/bundle';

export const EditPatientBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { EditPatient } = await import(
        /* webpackChunkName: "editPatient" */ './scenes/edit-patient'
      );
      return EditPatient;
    }}
    bundleDidLoad={(EditPatient) => <EditPatient {...props} />}
  />
);

export const EditPatientWithPlatformBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { EditPatientWithPlatform } = await import(
        /* webpackChunkName: "editPatientWithPlatform" */ './scenes/edit-patient'
      );
      return EditPatientWithPlatform;
    }}
    bundleDidLoad={(EditPatientWithPlatform) => (
      <EditPatientWithPlatform {...props} />
    )}
  />
);

export const DeactivatePatientBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DeactivatePatient } = await import(
        /* webpackChunkName: "deactivatePatient" */ './scenes/deactivate-patient'
      );
      return DeactivatePatient;
    }}
    bundleDidLoad={(DeactivatePatient) => <DeactivatePatient {...props} />}
  />
);

export const CreatePatientBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { CreatePatient } = await import(
        /* webpackChunkName: "createPatient" */ './scenes/create-patient'
      );
      return CreatePatient;
    }}
    bundleDidLoad={(CreatePatient) => <CreatePatient {...props} />}
  />
);

export const TimePeriodsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { TimePeriods } = await import(
        /* webpackChunkName: "timePeriods" */ './scenes/time-periods'
      );
      return TimePeriods;
    }}
    bundleDidLoad={(TimePeriods) => <TimePeriods {...props} />}
  />
);

export const ListDevicesBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { ListDevices } = await import(
        /* webpackChunkName: "listDevices" */ './scenes/list-devices'
      );
      return ListDevices;
    }}
    bundleDidLoad={(ListDevices) => <ListDevices {...props} />}
  />
);

export const GraphicSettingsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { GraphicSettings } = await import(
        /* webpackChunkName: "graphicSettings" */ './scenes/graph-settings'
      );
      return GraphicSettings;
    }}
    bundleDidLoad={(GraphicSettings) => <GraphicSettings {...props} />}
  />
);

export const HomeDeliveryJelloBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { HomeDeliveryJello } = await import(
        /* webpackChunkName: "HomeDeliveryJello" */ './scenes/home-delivery-jello'
      );
      return HomeDeliveryJello;
    }}
    bundleDidLoad={(HomeDeliveryJello) => <HomeDeliveryJello {...props} />}
  />
);

export const DeliveryConfigurationBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { DeliveryConfiguration } = await import(
        /* webpackChunkName: "deliveryConfiguration" */ './scenes/delivery-configuration'
      );
      return DeliveryConfiguration;
    }}
    bundleDidLoad={(DeliveryConfiguration) => (
      <DeliveryConfiguration {...props} />
    )}
  />
);

export const NextShipmentBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { NextShipment } = await import(
        /* webpackChunkName: "nextShipment" */ './scenes/next-shipment'
      );
      return NextShipment;
    }}
    bundleDidLoad={(NextShipment) => <NextShipment {...props} />}
  />
);
