import {
  MIN_BG,
  BLOOD_GLUCOSE_UNITS,
} from 'src/domains/diagnostics/store/constants';
import { calculateBloodGlucoseIndex } from 'src/domains/diagnostics/utils/graph-statistics.util';
import { average } from 'src/utils';
import { convertBGMeasurement } from 'src/domains/diagnostics/utils/measurements';

export const roundToNDecimalPlaces = (number, n) => {
  const factor = Math.pow(10, n);
  return Math.round(number * factor) / factor;
};

export const calculateLowBloodGlucoseIndex = (measurements, bloodGlucoseUnit) =>
  average(
    measurements.map((measurement) => {
      const measurementValue = convertBGMeasurement(
        measurement.value,
        bloodGlucoseUnit,
        BLOOD_GLUCOSE_UNITS.MG_PER_DL,
      );
      return measurementValue <= MIN_BG
        ? calculateBloodGlucoseIndex(measurementValue).bgIndex
        : 0;
    }),
  );

export const calculateHighBloodGlucoseIndex = (
  measurements,
  bloodGlucoseUnit,
) =>
  average(
    measurements.map((measurement) => {
      const measurementValue = convertBGMeasurement(
        measurement.value,
        bloodGlucoseUnit,
        BLOOD_GLUCOSE_UNITS.MG_PER_DL,
      );
      return measurementValue > MIN_BG
        ? calculateBloodGlucoseIndex(measurementValue).bgIndex
        : 0;
    }),
  );
