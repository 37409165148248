import React, { Fragment } from 'react';
import { TFunction } from 'i18next';

import { withTranslation } from 'src/utils/i18n';
import { colors } from 'src/core/styles';
import { RenderIf } from 'src/domains/diagnostics/utils/render-if';

import { GraphSvgIcon } from 'src/components/icon';
import { TransparentRectangle } from '../transparent-rectangle';

type Props = {
  height?: number,
  iconColor?: string,
  borderFillColor?: string,
  borderColor?: string,
  withBorder?: boolean,
  big?: boolean,
  width?: string | number,
  fillColor?: string,
  t: TFunction,
};

const AppleEatenIconComponent = ({
  height,
  width,
  iconColor = colors.grayDark,
  borderFillColor = colors.white,
  borderColor = colors.grayDark,
  withBorder = false,
  t,
}: Props) => {
  const originalWidth = withBorder ? 28 : 9.2;
  const originalHeight = withBorder ? 28 : 22;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  const r = withBorder ? 12.5 : 13.5;

  return (
    <GraphSvgIcon
      title={t('graphs.iconTitles.afterMeal')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <Fragment>
        <RenderIf validate={withBorder}>
          <g fill="none" fillRule="evenodd">
            <circle
              stroke={borderColor}
              fill={borderFillColor}
              cx="13.68"
              cy="13.5"
              r={r}
            />
            <path
              d="M14.587 19.892l-.273-.118-.272.121c-.843.374-1.663.248-2.446-.372 1.958-4.304.688-7.226-.27-8.887.595-.295 1.252-.348 2.258.055.686.175 1.242.091 1.493.002.631-.254.958-.235 1.374-.074-1.059 1.745-1.967 4.824.187 9.25-.648.334-1.322.341-2.05.023m-1.256-12.37c.285.42.412.906.381 1.474-.785-.274-2.02-.975-2.145-2.7.567.2 1.32.573 1.764 1.225m4.665 3.226l.525-.679-1.063-.479c-.693-.323-1.354-.627-2.429-.307.094-.949-.09-1.797-.582-2.519-1.115-1.644-3.339-1.99-3.433-2.006l-.694-.102-.076.697c-.195 1.773.384 2.995 1.202 3.806-.484.127-.958.362-1.438.708l-.508.366.327.534c1.237 2.017 2.384 4.512.338 8.621l-.217.436.344.342c1.208 1.205 2.626 1.581 4.025 1.073.44.16.875.238 1.303.238.807 0 1.588-.28 2.307-.84l.45-.348-.268-.5c-2.389-4.5-1.38-7.4-.113-9.04"
              fill={iconColor}
            />
          </g>
        </RenderIf>
        <RenderIf validate={!withBorder}>
          <TransparentRectangle
            width={calculatedWidth}
            height={calculatedHeight}
          />
          <path
            d="M5.436 15.42c-.096-.017-.19-.04-.285-.07-.168-.08-.34-.152-.523-.16v-.002c-.184.01-.356.082-.524.162-.094.03-.19.053-.285.07-.77.137-1.62 0-2.2-.353.083-.138.165-.277.254-.448.226-.434.342-.68.484-.994.347-.763.513-1.268.578-2.244.086-1.285-.035-2.407-.37-3.43-.142-.438-.33-.89-.563-1.355-.098-.198-.197-.396-.313-.586-.01-.014-.018-.028-.026-.042.258-.095.568-.185.697-.206.392-.057.744.042 1.185.167.128.035.257.072.39.106.257.065.484.087.694.093.21-.007.44-.03.695-.094.135-.034.264-.07.39-.107.443-.126.794-.225 1.187-.168.13.02.438.11.696.206-.01.014-.017.028-.026.043-.115.19-.215.39-.315.587-.232.466-.42.917-.563 1.356-.336 1.022-.456 2.144-.37 3.43.065.975.23 1.48.577 2.243.143.314.26.56.485.993.09.17.17.31.253.447-.58.352-1.428.49-2.2.354M4.014 2.867c.285.42.412.908.38 1.475-.784-.273-2.02-.974-2.144-2.698.566.2 1.32.57 1.763 1.224m4.44 4.348c.09-.178.17-.344.267-.5.175-.288.267-.44.342-.576l.175-.31c.044-.048-.008-.148-.03-.195-.038-.082-.085-.16-.136-.235-.33-.482-.828-.69-1.367-.847-.19-.054-.416-.094-.61-.124-.516-.074-.955-.003-1.367.1.07-.908-.124-1.72-.597-2.418C4.013.463 1.79.118 1.695.103L1.002 0 .926.697c-.19 1.735.36 2.94 1.15 3.75-.165.03-.33.07-.49.118-.54.157-1.07.35-1.403.833-.05.074-.1.153-.136.235-.022.047-.074.147-.03.195l.175.31c.076.136.167.288.342.575.095.157.177.323.267.5.198.395.36.784.482 1.16.28.855.38 1.81.305 2.92-.054.815-.175 1.15-.46 1.774-.132.292-.24.523-.452.928-.078.15-.15.27-.22.39-.077.13-.155.26-.238.418-.19.354-.116.77.157 1.005.738.722 1.85 1.013 2.845 1.013.293 0 .576-.024.837-.07.153-.028.304-.066.445-.11.024-.008.073-.03.125-.05.052.02.1.042.124.05.143.044.294.082.447.11.26.046.544.07.838.07.995 0 2.107-.29 2.845-1.012.273-.236.347-.65.158-1.005-.084-.158-.163-.29-.24-.42-.07-.12-.14-.238-.22-.388-.21-.405-.32-.636-.453-.928-.283-.625-.404-.96-.46-1.775-.073-1.11.027-2.064.307-2.92.122-.375.284-.764.482-1.158"
            fill={iconColor}
            fillRule="evenodd"
          />
        </RenderIf>
      </Fragment>
    </GraphSvgIcon>
  );
};

export const AppleEatenIcon = withTranslation(AppleEatenIconComponent);
