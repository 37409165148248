import * as React from 'react';

import {
  SectionHeaderDiv,
  SectionHeaderHeadline,
} from './section-header.style';

import { Keyline } from '../keyline';

import { BaseStyleProps } from '../component.types';

type SectionHeaderProps = {
  title: string;
  textColor: string;
  bottomMargin: any;
  bottomMarginHeadline: number;
  borderColor: string;
  fontWeight: number;
};

export const SectionHeader = (props: SectionHeaderProps & BaseStyleProps) => {
  return (
    <SectionHeaderDiv mb={props.bottomMargin}>
      <SectionHeaderHeadline {...props}>{props.title}</SectionHeaderHeadline>
      <Keyline color={props.borderColor} />
    </SectionHeaderDiv>
  );
};
