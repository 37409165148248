import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { createStructuredSelector } from 'reselect';

import {
  selectAccessEndpoint,
  selectGigyaToken,
  selectOidcClientId,
  selectRedirectUri,
} from '../../core/config/config.selectors';
import { selectIsOidcSessionValid } from '../../core/oidc/oidc.selectors';
import {
  loginRedirect,
  setSuccessRedirect,
  signoutStart,
} from '../../core/session/session.actions';
import { selectIsSignedOut } from '../../core/session/session.selectors';
import { mapDispatchers } from 'src/utils';

import { OIDCRoot } from './login.component';

const connector = createStructuredSelector({
  isSessionValid: selectIsOidcSessionValid,
  isSignedOut: selectIsSignedOut,
  accessEndpoint: selectAccessEndpoint,
  gigyaToken: selectGigyaToken,
  redirectUri: selectRedirectUri,
  oidcClientId: selectOidcClientId,
});

export const dispatchers = mapDispatchers({
  goTo: (path) => push(path),
  loginRedirect,
  setSuccessRedirect,
  signoutStart,
});

export const LoginComponent = connect(connector, dispatchers)(OIDCRoot);
