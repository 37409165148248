import { matchPath } from 'react-router-dom';

export const twoPathsMatch = (exact) => (currentPath) => (path) => {
  const match = matchPath(currentPath, {
    path: path,
    exact: exact,
  });
  return Boolean(match);
};

export const twoPathsMatchExactly = twoPathsMatch(true);
export const twoPathsMatchNotExactly = twoPathsMatch(false);
