import styled from 'styled-components';
import { breakpoints, boxShadow } from 'src/core';
import { BaseCard } from 'src/components';
import { Div } from 'src/components/div';
import { convertPxToRem } from 'src/utils';
import { createStyledComponent } from 'src/utils/styles';
import { colors } from 'src/core/styles/colors';

const handleHeight = 20;

export const PeriodsBarWrapper = styled.div`
  min-width: 100%;
  height: 70px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.silverMedium};
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e6e4e4' fill-opacity='1' fillRule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
`;
PeriodsBarWrapper.displayName = 'PeriodsBarWrapper';

type PeriodBlockProps = {
  bgcolor: string;
};
export const PeriodBlock = styled(Div)`
  height: 100%;
  background-color: ${(props: PeriodBlockProps) => props.bgcolor};
  box-sizing: content-box;
  border-bottom: 1px solid ${(props: PeriodBlockProps) => props.bgcolor};
  position: absolute;
  transition: all 0.1s ease;
`;
PeriodBlock.displayName = 'PeriodBlock';

export const PeriodsTicksWrapper = styled(Div)`
  display: flex;
  height: 16px;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
`;
PeriodsTicksWrapper.displayName = 'PeriodsTicksWrapper';

export const PeriodTick = styled(Div)`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.silverMedium};
`;
PeriodTick.displayName = 'PeriodTick';

export const PeriodTickText = styled(Div)`
  user-select: none;
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${convertPxToRem(14)};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }};

  @media (max-width: ${breakpoints.large}) {
    font-size: ${convertPxToRem(12)};
  }
`;
PeriodTickText.displayName = 'PeriodTickText';

export const PeriodTickTextAbsolute = styled(PeriodTickText)`
  position: absolute;
  min-width: 80px;
  top: -25px;
  margin-left: -40px;
  text-align: center;
`;
PeriodTickTextAbsolute.displayName = 'PeriodTickTextAbsolute';

type IntervalHandleProps = {
  left: string;
};
export const IntervalHandle = styled.div`
  position: absolute;
  top: ${68 / 2 - handleHeight / 2}px;
  left: ${(props: IntervalHandleProps) => props.left};
  margin-left: -10px;
  width: ${handleHeight / 16}rem;
  height: ${handleHeight / 16}rem;
  border-radius: 50%;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: ew-resize;
  transition: left 0.1s ease;
  z-index: 5;

  &:hover > * {
    opacity: 1;
  }
`;
IntervalHandle.displayName = 'IntervalHandle';

const cardwidth = 188;
const beforecardwidth = 20;
export const TimePeriodCard = createStyledComponent<FixMe, any>(BaseCard)`
  position: absolute;
  width: ${convertPxToRem(cardwidth)};
  max-width: ${convertPxToRem(cardwidth)};
  height: ${convertPxToRem(100)};
  border-radius: 3px;
  border-top: 3px solid ${colors.brandBlue};
  box-shadow: ${(props) =>
    boxShadow({
      color: props.theme.colors.charcoal,
      depth: 'popover',
    })};
  padding: 20px 5px;
  text-align: center;
  bottom: 36px;
  margin-left: -${convertPxToRem(cardwidth / 2 - handleHeight / 2)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.2s ease;
  pointer-events: none;
  user-select: none;

  &:after {
    content: '';
    background-color: ${colors.white};
    width: 80%;
    height: 23px;
    display: inline-block;
    position: absolute;
    left: 10%;
    bottom: 0;
  }

  &:before {
    height: ${convertPxToRem(beforecardwidth)};
    width: ${convertPxToRem(beforecardwidth)};
    display: inline-block;
    background-color: ${colors.white};
    content: '';
    bottom: -4px;
    left: ${convertPxToRem(cardwidth / 2 - beforecardwidth / 2)};
    border-radius: 1px;
    position: absolute;
    transform: rotate(-45deg);
    box-shadow: ${(props) =>
      boxShadow({
        color: props.theme.colors.charcoal,
        depth: 'popover',
      })};
  }
`;
TimePeriodCard.displayName = 'TimePeriodCard';

export const TimePeriodCardTitle = createStyledComponent<FixMe, any>('div')`
  color: ${colors.charcoal};
  font-size: ${convertPxToRem(16)};
  font-weight: 600;
  height: ${convertPxToRem(16)};
  line-height: ${convertPxToRem(16)};
  text-align: center;
  margin-bottom: 5px;
`;
TimePeriodCardTitle.displayName = 'TimePeriodCardTitle';

export const TimePeriodCardDisplay = createStyledComponent<FixMe, any>('div')`
  border-top: 1px solid ${colors.silverMedium};
  color: ${colors.charcoal};
  font-size: ${convertPxToRem(21)};
  font-weight: 600;
  line-height: ${convertPxToRem(21)};
  text-align: center;
  width: 90%;
  margin-left: 5%;
  padding-top: 12px;
`;
TimePeriodCardDisplay.displayName = 'TimePeriodCardDisplay';
