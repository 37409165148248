import React from 'react';
import { TFunction } from 'i18next';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { RectangleMarkIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';
import { LocalizedText } from 'src/components/localized-text/localized-text.component';

import {
  IconContainer,
  ListSection,
  ListsContainer,
  List,
  ListElement,
  ListElementSectionHeader,
} from '../../graph-statistics.style';

type GraphDetailTargetRangesProps = {
  abovePercentage: number,
  belowPercentage: number,
  hypoglycaemiaNumber: number,
  hypoglycaemiaPercentage: number,
  targetBloodGlucoseMinimum: number,
  targetBloodGlucoseMaximum: number,
  withinPercentage: number,
  t: TFunction,
};

export const GraphDetailTargetRangesComponent = ({
  abovePercentage,
  belowPercentage,
  hypoglycaemiaNumber,
  hypoglycaemiaPercentage,
  targetBloodGlucoseMinimum,
  targetBloodGlucoseMaximum,
  withinPercentage,
  t,
}: GraphDetailTargetRangesProps) => (
  <ListSection>
    <ListElementSectionHeader>
      <IconContainer>
        <RectangleMarkIcon
          strokeColor={colors.transparentGreen}
          fillColor={colors.transparentGreen}
        />
      </IconContainer>
      <LocalizedText
        textKey={'graphDetails.statistics.targetRanges.targetRange'}
      />
      {` (${targetBloodGlucoseMinimum}-${targetBloodGlucoseMaximum} ${t(
        'graphDetails.mgPerDL',
      )})`}
    </ListElementSectionHeader>
    <ListsContainer>
      <List>
        <ListElement bold>
          <LocalizedText
            textKey={'graphDetails.statistics.targetRanges.above'}
          />
        </ListElement>
        <ListElement bold>
          <LocalizedText
            textKey={'graphDetails.statistics.targetRanges.within'}
          />
        </ListElement>
        <ListElement bold>
          <LocalizedText
            textKey={'graphDetails.statistics.targetRanges.below'}
          />
        </ListElement>
      </List>
      <List>
        <ListElement>{`${abovePercentage}%`}</ListElement>
        <ListElement>{`${withinPercentage}%`}</ListElement>
        <ListElement>{`${belowPercentage}%`}</ListElement>
      </List>
      <List noBorder>
        <ListElement bold>
          <LocalizedText
            textKey={'graphDetails.statistics.targetRanges.hypos'}
          />
        </ListElement>
      </List>
      <List>
        <ListElement>
          {`${hypoglycaemiaPercentage}% (${hypoglycaemiaNumber})`}
        </ListElement>
      </List>
      <List flex={16} />
    </ListsContainer>
  </ListSection>
);

export const GraphDetailTargetRanges = withTranslation(
  GraphDetailTargetRangesComponent,
);
