import React from 'react';
import { isNil } from 'ramda';

import { BLOOD_GLUCOSE_UNITS } from 'src/domains/patient-dashboards/bg/store/bg.constants';
import { strokeWidth, colors } from 'src/core/styles';
import { RenderIf } from 'src/domains/diagnostics/utils';
import { PointSeries, LineSeries } from 'src/lib';
import {
  TargetRange,
  ThresholdLine,
  GridLines,
  XShape,
  SquareShape,
  TriangleShape,
  CircleShape,
} from 'src/domains/diagnostics/components/graph';
import {
  GRAPH_Y_INTERVAL_MG,
  GRAPH_Y_INTERVAL_MMOL,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { toolTipWidth } from 'src/domains/diagnostics/components/tool-tip/detail-tool-tip';
import {
  FULL_OPACITY,
  FADED_OPACITY,
  DATA_TYPE,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  areDatesTheSameDay,
  getClickableCursorStyle,
} from 'src/domains/diagnostics/scenes/graphs/graph.util';

export const DetailGraph = ({
  height,
  width,
  yDirection,
  targetRange,
  threshold,
  horizontalTicks,
  showGridLines,
  lines,
  points,
  meanPoints,
  onLineClick = () => undefined,
  selectedDate,
  onPointMouseOver,
  onPointMouseOut,
  graphYMax,
  collapsed,
  bloodGlucoseUnit,
}) => {
  const GRAPH_Y_INTERVAL =
    bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
      ? GRAPH_Y_INTERVAL_MMOL
      : GRAPH_Y_INTERVAL_MG;
  return (
    <React.Fragment>
      <RenderIf validate={showGridLines}>
        <GridLines
          width={width}
          height={height}
          horizontalCount={graphYMax / GRAPH_Y_INTERVAL}
          verticalCount={horizontalTicks.length}
        />
      </RenderIf>
      <TargetRange
        width={width}
        height={height}
        min={targetRange.min}
        max={targetRange.max}
      />
      <ThresholdLine
        width={width}
        height={height}
        threshold={threshold}
        yDirection={yDirection}
      />
      {lines.map((week) =>
        week.map((points, index) => (
          <LineSeries
            key={index}
            points={points}
            width={width}
            height={height}
            Line={(a, b) => (
              <line
                key={`${a.x}${a.y}${b.x}${b.y}`}
                x1={a.x}
                y1={a.y}
                x2={b.x}
                y2={b.y}
                strokeWidth={
                  areDatesTheSameDay(a.data.date, b.data.date) &&
                  areDatesTheSameDay(a.data.date, selectedDate)
                    ? strokeWidth.four
                    : strokeWidth.one
                }
                stroke={
                  areDatesTheSameDay(a.data.date, b.data.date) &&
                  areDatesTheSameDay(a.data.date, selectedDate)
                    ? colors.turqoise
                    : colors.black
                }
                opacity={
                  isNil(selectedDate) ||
                  areDatesTheSameDay(a.data.date, selectedDate)
                    ? FULL_OPACITY
                    : FADED_OPACITY
                }
              />
            )}
          />
        )),
      )}
      <LineSeries
        points={meanPoints}
        width={width}
        height={height}
        Line={(a, b) => (
          <line
            key={`${a.x}${a.y}${b.x}${b.y}`}
            x1={a.x}
            y1={a.y}
            x2={b.x}
            y2={b.y}
            opacity={isNil(selectedDate) ? FULL_OPACITY : FADED_OPACITY}
            strokeWidth={strokeWidth.two}
            stroke={colors.black}
          />
        )}
      />
      {/* Invisible clickable lines */}
      {lines.map((week) =>
        week.map((points, index) => (
          <LineSeries
            key={index}
            points={points}
            width={width}
            height={height}
            Line={(a, b) => (
              <line
                key={`${a.x}${a.y}${b.x}${b.y}`}
                x1={a.x}
                y1={a.y}
                x2={b.x}
                y2={b.y}
                stroke={colors.white}
                strokeWidth={10}
                opacity={0}
                onClick={onLineClick({
                  date: a.data.date,
                  type: DATA_TYPE.GLUCOSE,
                })}
                cursor={getClickableCursorStyle(!collapsed)}
              />
            )}
          />
        )),
      )}
      <PointSeries
        points={points}
        width={width}
        height={height}
        Shape={({ shape, x, y, strokeColor, fillColor, data }, index) => {
          let ShapeComponent;

          if (shape === 'triangle') {
            ShapeComponent = TriangleShape;
          } else if (shape === 'x') {
            ShapeComponent = XShape;
          } else {
            ShapeComponent = SquareShape;
          }

          return (
            <ShapeComponent
              width={width}
              key={`${x * y}-${index}`}
              x={x}
              y={y}
              fillColor={
                isNil(selectedDate) || fillColor === colors.white
                  ? fillColor
                  : colors.lighterTurqoise
              }
              strokeColor={isNil(selectedDate) ? strokeColor : colors.turqoise}
              opacity={
                isNil(selectedDate) ||
                areDatesTheSameDay(data.date, selectedDate)
                  ? FULL_OPACITY
                  : FADED_OPACITY
              }
              onClick={onLineClick({
                type: DATA_TYPE.GLUCOSE,
                date: data.date,
              })}
              onMouseMove={(event) =>
                onPointMouseOver(
                  event,
                  { type: DATA_TYPE.GLUCOSE, ...data },
                  toolTipWidth,
                )
              }
              onMouseOut={onPointMouseOut}
              cursor="pointer"
            />
          );
        }}
      />
      <PointSeries
        points={meanPoints}
        width={width}
        height={height}
        Shape={({ x, y, fillColor, data, notEnoughData }, index) =>
          !notEnoughData && (
            <CircleShape
              width={width}
              key={`${x}${y}-${index}`}
              x={x}
              y={y}
              fillColor={fillColor}
              opacity={isNil(selectedDate) ? FULL_OPACITY : FADED_OPACITY}
              onMouseMove={(event) =>
                onPointMouseOver(
                  event,
                  { type: DATA_TYPE.MEAN_GLUCOSE, ...data },
                  toolTipWidth,
                )
              }
              onMouseOut={onPointMouseOut}
            />
          )
        }
      />
    </React.Fragment>
  );
};
