import * as React from 'react';

// This is a separate class because pure components need to be wrapped in instances for Ref to work
export class ResizeChildrenWrapper extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      layout: EmptyDomRect,
    };
  }
  public componentWillReceiveProps({ layout }) {
    if (layout === this.state.layout) return;
    this.setState((state) => ({ layout }));
  }
  public render() {
    const { render, element } = this.props;
    const { layout } = this.state;
    const rendered = render(layout);
    const root = React.Children.only(rendered); // .only throws if there are multiple children (eg: an array returned)
    return React.cloneElement(root, { ref: element }); // add the ref to the DOM (note: ref must be used for)
  }
}

const EmptyDomRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};
