import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withBgImage } from 'src/navigation/utils/navigation.utils';
import { CGMSummaryFactoryImpl } from 'src/services/cgm/cgm-summary';
import { countlyEventTrigger } from 'src/navigation/countly';
import { EVENTS_KEYS } from 'src/widgets/patient/create-patient/countly.constants';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { mapDispatchers } from 'src/utils';
import { switchPatient } from 'src/core/patient/patient.action';
import { patientSearchConnector, search } from 'src/core/patient-search';

import { PatientSearchWidget } from './patient-search.component';

const dispatchers = mapDispatchers({
  search,
  switchPatient,
  goTo: (path) => push(path),
});

export const PatientSearch = compose(
  withBgImage,
  connect(patientSearchConnector, dispatchers),
  withHandlers({
    onCreatePatient:
      ({ dispatch, routes }) =>
      () => {
        countlyEventTrigger(EVENTS_KEYS.NEW_PATIENT_PROCESS_INITIATED, {});
        return dispatch(push(routes.patient.createPatient));
      },
    onSearch:
      (props) =>
      ({ fullName, patientID }) =>
        props.search({ fullName, patientID }),
    onHandle: (props) => (patientId, fhirId, isHcpOrGp) => {
      props.switchPatient(patientId);
      if (isHcpOrGp) {
        const checkCgmDate = CGMSummaryFactoryImpl({ devMode: false });
        const { accessToken, apiKey } = props;
        checkCgmDate({ fhirId }, accessToken, apiKey).then((data) => {
          const { firstMeasurementDate: fm, lastMeasurementDate: lm } = data;
          props.goTo(`/patients/${fm && lm ? 'cgm' : 'bg'}/${patientId}`);
        });
      } else {
        props.goTo(`/patients/${patientId}`);
      }
    },
  }),
  withTranslation,
)(PatientSearchWidget);
