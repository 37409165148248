import { PayloadAction } from 'src/app/store/app.types';

import {
  ProfileType,
  ProfileTypesParams,
} from 'src/services/department/profile-types';
import { ProfessionalProfileType } from 'src/services/professionals/get-professionals/get-professionals.types';

export enum ProfileTypes {
  none = '',
  basic = 'STANDARD',
  pickup = 'STANDARD_WITH_STRIP_MANAGEMENT',
  homeDelivery = 'STANDARD_WITH_HOME_DELIVERY',
}

export type Professional = {
  id: number;
  user: {
    id: number;
    name: string;
    surname: string;
    surname2?: string;
    address: {
      id: number;
      address: string;
      postalCode: string;
      city: string;
      province: string;
      country: {
        id: number;
        name: string;
        isoCode: string;
        labelProperty: string;
        labelText?: string;
        language: {
          id: number;
          languageId: string;
          labelProperty: string;
          labelText?: string;
          isoCode: string;
          key: string;
          value?: string;
        };
        timeZone: string;
        profilesList?: string[];
        currency: string;
        prefix: string;
        key: string;
        value?: string;
      };
    };
    fullname: string;
    inactivate: boolean;
    gender?: string;
    phone: string;
    birthday: string;
    email: string;
    loginId: number;
    language: string;
    languageId: number;
    languageIsoCode: string;
    languageCountryIsoCode: string;
    role: string;
    departmentProfileId: number;
    profile: ProfessionalProfileType;
    countryId: number;
    inactivateReason?: string;
    inactivateComment?: string;
    departmentId: number;
    centerId: number;
    areaId: number;
    locked: boolean;
    lastAccess: number;
    passwordReset: boolean;
    viewNotifications: boolean;
    countryIsoCode: string;
    departmentName: string;
    centerName: string;
    accessEnum?: string;
    profileId: number;
    gigyaUid: string;
    activeInGigya?: string;
    hcpIsAccessible: true;
    fakeMail: boolean;
    headerWelcome: string;
    languageIsoCodeComplete: string;
    noPhone: boolean;
  };
  value: string;
  key: string;
};

export enum DepartmentActionType {
  GET_DEPARTMENT_PROFILE_TYPES_START = 'GET_DEPARTMENT_PROFILE_TYPES_START',
  GET_DEPARTMENT_PROFILE_TYPES_ERROR = 'GET_DEPARTMENT_PROFILE_TYPES_ERROR',
  GET_DEPARTMENT_PROFILE_TYPES_SUCCESS = 'GET_DEPARTMENT_PROFILE_TYPES_SUCCESS',
  GET_PROFESSIONALS_START = 'GET_PROFESSIONALS_START',
  GET_PROFESSIONALS_SUCCESS = 'GET_PROFESSIONALS_SUCCESS',
  GET_PROFESSIONALS_ERROR = 'GET_PROFESSIONALS_ERROR',
}

export type DepartmentState = {
  isLoading: boolean;
  types: ProfileType[];
  error: any;
  professionals: Professional[];
};

export type GetProfessionalsParams = {
  departmentId: number;
};

export type DepartmenProfileTypesResponse = ProfileType[];
export type GetProfessionalsSuccessPayload = Professional[];

export type GetDepartmenProfileTypesStartAction = PayloadAction<
  DepartmentActionType.GET_DEPARTMENT_PROFILE_TYPES_START,
  ProfileTypesParams
>;
export type GetDepartmenProfileTypesSuccessAction = PayloadAction<
  DepartmentActionType.GET_DEPARTMENT_PROFILE_TYPES_SUCCESS,
  DepartmenProfileTypesResponse
>;
export type GetDepartmenProfileTypesErrorAction = PayloadAction<
  DepartmentActionType.GET_DEPARTMENT_PROFILE_TYPES_ERROR,
  any
>;

export type GetProfessionalsStartAction = PayloadAction<
  DepartmentActionType.GET_PROFESSIONALS_START,
  GetProfessionalsParams
>;
export type GetProfessionalsSuccessAction = PayloadAction<
  DepartmentActionType.GET_PROFESSIONALS_SUCCESS,
  GetProfessionalsSuccessPayload
>;
export type GetProfessionalsErrorAction = PayloadAction<
  DepartmentActionType.GET_PROFESSIONALS_ERROR,
  any
>;

export type DepartmentActions =
  | GetDepartmenProfileTypesStartAction
  | GetDepartmenProfileTypesSuccessAction
  | GetDepartmenProfileTypesErrorAction
  | GetProfessionalsSuccessAction;

export type DepartmentReducerActions = DepartmentActions;
