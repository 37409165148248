import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { withToolTip } from 'src/utils/with-tool-tip';
import { mapDispatchers } from 'src/domains/diagnostics/utils';
import {
  changeLogbookType,
  changeGraph,
} from 'src/domains/patient-dashboards/bg/store/bg.actions';

import { StandardWeekDetailComponent } from './standard-week-detail.component';
import { standardWeekDetailConnector } from './standard-week-detail.selector';

const dispatchers = mapDispatchers({
  changeGraph,
  changeLogbookType,
});

export const StandardWeekDetailContainer = compose(
  withRouter,
  connect(standardWeekDetailConnector, dispatchers),
  withTheme,
  withToolTip,
  withTranslation,
)(StandardWeekDetailComponent);
