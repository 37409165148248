import { createSelector } from 'reselect';
import { path } from 'ramda';

const selectCheckDataSharingConsentState = path(['checkDataSharingConsent']);

export const selectIsCheckingDataSharingConsent = createSelector(
  selectCheckDataSharingConsentState,
  path(['isCheckingDataSharingConsent']),
);

export const selectCheckDataSharingConsentSuccess = createSelector(
  selectCheckDataSharingConsentState,
  path(['checkDataSharingConsentSuccessful']),
);

export const selectCheckDataSharingConsentError = createSelector(
  selectCheckDataSharingConsentState,
  path(['checkDataSharingConsentHasError']),
);

export const selectDataSharingConsentResponse = createSelector(
  selectCheckDataSharingConsentState,
  path(['dataSharingConsentResponse']),
);

export const selectDataSharingHasBeenCalled = createSelector(
  selectCheckDataSharingConsentState,
  path(['checkDataSharingHasBeenCalled']),
);
