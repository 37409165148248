import * as React from 'react';
import { TFunction } from 'i18next';

import {
  Container,
  Content,
  Card,
  CardContent,
  SectionsContainer,
  CardContainer,
  IconContainer,
} from './additional-info.style';

import { withTranslation } from 'src/utils/i18n/with-translation';

import {
  PREFIX,
  ID,
  ITEM,
  CONTENT,
  TITLE,
  CARD,
  SECTIONS,
  CARD_TYPES,
} from './additional-info.constants';

import { pathOr } from 'ramda';

import { testId } from '@roche/roche-common';

export type ContentProps = {
  t: TFunction;
  data: {
    info: string;
    cards?: string[];
    sections?: {
      info: string;
      cards?: string[];
    }[];
  };
};

export type ContentState = {
  active: string;
};

export class ContentClass extends React.Component<ContentProps, ContentState> {
  public state = { active: '' };

  constructor(props) {
    super(props);
    const { data } = this.props;
    const sections = pathOr([], ['sections'], data);
    const firstSection = sections[0] || {};
    this.state = { active: pathOr(firstSection.info, ['active'], data) };
  }

  public drawCard = (
    sections: boolean,
    cards: string[] = [],
    info: string,
    sInfo: string,
    t: any,
  ) => {
    const { warning, precaution } = CARD_TYPES;
    const s = sections ? `.${SECTIONS}.${sInfo}.` : '.';
    return cards
      .filter((i) => i)
      .map((card, i) => (
        <Card key={`${card}-${i}`} {...testId(`${ID}-${card}`, info)}>
          {[warning, precaution].indexOf(card) > -1 && (
            <IconContainer>
              <jello-icon
                class="icon"
                size="M"
                icon-name="warning--alt"
                variant="secondary"
              />
            </IconContainer>
          )}
          <CardContainer>
            <jello-text size="M" weight="bold" class="note">
              {t(`${PREFIX}${card}`)}
            </jello-text>
            <CardContent
              className="card"
              dangerouslySetInnerHTML={{
                __html: t(`${PREFIX}${info}${s}${CARD}-${i}`.replace('-0', '')),
              }}
            />
          </CardContainer>
        </Card>
      ));
  };

  public render() {
    const { t, data } = this.props;
    const { info, cards, sections = [] } = data;
    const { active } = this.state;
    return (
      <Container {...testId(`${ID}-${CONTENT}`, info)}>
        {!sections.length && (
          <React.Fragment>
            <Content
              className="content"
              dangerouslySetInnerHTML={{
                __html: t(`${PREFIX}${info}.${CONTENT}`),
              }}
            />
            {this.drawCard(false, cards, info, info, t)}
          </React.Fragment>
        )}

        {!!sections.length && (
          <React.Fragment>
            <jello-grid center="">
              <jello-row>
                <jello-col xs-3 sm-3 md-3 l-3 xl-3 class="sections">
                  {sections.map((s) => (
                    <jello-text
                      key={s.info}
                      size="M"
                      weight="semibold"
                      class={s.info === active ? 'section active' : 'section'}
                      onClick={() => this.setState({ active: s.info })}
                      {...testId(`${ID}-${SECTIONS}-${ITEM}`, s.info)}
                    >
                      {t(`${PREFIX}${info}.${SECTIONS}.${s.info}.${TITLE}`)}
                    </jello-text>
                  ))}
                </jello-col>
                <jello-col xs-9 sm-9 md-9 l-9 xl-9>
                  {sections.map((s) => (
                    <SectionsContainer
                      key={s.info}
                      className={s.info === active ? 'active' : ''}
                      {...testId(`${ID}-${SECTIONS}`, s.info)}
                    >
                      <jello-text
                        size="XL"
                        weight="semibold"
                        class="sectionTitle"
                      >
                        {t(`${PREFIX}${info}.${SECTIONS}.${s.info}.${TITLE}`)}
                      </jello-text>
                      <Content
                        dangerouslySetInnerHTML={{
                          __html: t(
                            `${PREFIX}${info}.${SECTIONS}.${s.info}.${CONTENT}`,
                          ),
                        }}
                        {...testId(
                          `${PREFIX}${info}.${SECTIONS}.${s.info}`,
                          'content',
                        )}
                      />
                      {this.drawCard(true, s.cards, info, s.info, t)}
                    </SectionsContainer>
                  ))}
                </jello-col>
              </jello-row>
            </jello-grid>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export const AdditionalInfoContent = withTranslation(ContentClass);
