import {
  diffInDaysInclusiveOfEnds,
  roundToNDecimalPlaces,
} from 'src/domains/diagnostics/utils';

import { BLOOD_GLUCOSE_UNITS } from '../store/constants';

export const calculateIntervalAverageTestsPerDay = (
  measurements,
  startDate,
  endDate,
) => {
  const numberOfIntervalDays = diffInDaysInclusiveOfEnds(startDate, endDate);

  const numberOfIntervalTests = measurements.length;

  return numberOfIntervalTests !== 0 && numberOfIntervalDays !== 0
    ? numberOfIntervalTests / numberOfIntervalDays
    : 0;
};

export const MMOL_PER_L_TO_MG_PER_DL = 18.02;

export const mgdlToMmolL = (m) => m / MMOL_PER_L_TO_MG_PER_DL;
export const mmollToMgdl = (m) => m * MMOL_PER_L_TO_MG_PER_DL;

export const convertBGMeasurement = (measurement, fromUnit, toUnit) => {
  const { MG_PER_DL, MMOL_PER_L } = BLOOD_GLUCOSE_UNITS;

  if (fromUnit && fromUnit !== MG_PER_DL && fromUnit !== MMOL_PER_L) {
    throw new Error(`Invalid source unit for conversion: ${fromUnit}`);
  }

  if (toUnit && toUnit !== MG_PER_DL && toUnit !== MMOL_PER_L) {
    throw new Error(`Invalid destination unit for conversion ${toUnit}`);
  }

  if (fromUnit && fromUnit === MG_PER_DL && toUnit === MMOL_PER_L)
    return mgdlToMmolL(measurement);

  if (fromUnit && fromUnit === MMOL_PER_L && toUnit === MG_PER_DL)
    return mmollToMgdl(measurement);

  if (fromUnit && fromUnit === toUnit) return measurement;
};

/*
  Returns a function to round the blood glucose measurement to the
  appropriate precision, based on bloodGlucoseUnit.
  mg/dL:
    42.0 => 42.0
    42.55 => 43.0
  mmol/L:
    42.0 => 42.0
    42.55 => 42.5
*/
export const roundBGMeasurement = (bloodGlucoseUnit) => (measurement) =>
  typeof measurement === 'number'
    ? roundToNDecimalPlaces(
        measurement,
        bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L ? 1 : 0,
      )
    : measurement;

/*
  Returns a function to round and format the blood glucose measurement
  to the appropriate precision as a string, based on bloodGlucoseUnit:
    mg/dL:
      42.00 => "42"
      42.55 => "43"
    mmol/L:
      42.00 => "42.0"
      42.55 => "42.5"
*/
export const formatBGMeasurement = (bloodGlucoseUnit) => (measurement) =>
  typeof measurement === 'number' && !Number.isNaN(measurement)
    ? measurement.toFixed(
        bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L ? 1 : 0,
      )
    : measurement;
