export const EVENTS_KEYS = {
  DATE_RANGE_CHANGED: 'Date Range Changed',
};

export const SEGMENT_KEYS = {
  DATE_CHANGE_METHOD: 'Date Change Method',
  DATE_CHANGE_LOCATION: 'Date Change Location',
};

export const SEGMENT_VALUES = {
  CGM_VIEW: 'CGM',
  BG_VIEW: 'BG',
  PATTERNS_VIEW: 'Patterns',
  MANUAL: 'Manual',
  ARROWS: 'Arrows',
  TIME_PERIOD_SELECTION: 'Time Period Selection',
};
