import { Action, PayloadAction } from 'src/app/store/app.types';
import { PatientFormState } from 'src/core/patient/patient.types';
import { GetPatientInfoErrorResponse } from 'src/services/patient/patient-info/patient-info.types';
import { EC6Patient } from 'src/services/patient/patient.types';

import {
  EditPatientParams,
  TransformedEditPatientErrorResponse,
  TransformedEditPatientSuccessResponse,
} from 'src/services';

export enum EditPatientActionType {
  EDIT_PATIENT_START = 'EDIT_PATIENT_START',
  EDIT_PATIENT_SUCCESS = 'EDIT_PATIENT_SUCCESS',
  EDIT_PATIENT_ERROR = 'EDIT_PATIENT_ERROR',
  EDIT_EMR_PATIENT_START = 'EDIT_EMR_PATIENT_START',
  EDIT_EMR_PATIENT_SUCCESS = 'EDIT_EMR_PATIENT_SUCCESS',
  EDIT_EMR_PATIENT_ERROR = 'EDIT_EMR_PATIENT_ERROR',
  EDIT_PATIENT_STATUS_MODAL_CONFIRMED = 'EDIT_PATIENT_STATUS_MODAL_CONFIRMED',
  FETCH_PATIENT_FOR_EDIT_START = 'FETCH_PATIENT_FOR_EDIT_START',
  FETCH_PATIENT_FOR_EDIT_SUCCESS = 'FETCH_PATIENT_FOR_EDIT_SUCCESS',
  FETCH_PATIENT_FOR_EDIT_ERROR = 'FETCH_PATIENT_FOR_EDIT_ERROR',
  UPDATE_EDIT_PATIENT = 'UPDATE_EDIT_PATIENT',
  NO_ASSOCIATED_PROFESSIONALS = 'NO_ASSOCIATED_PROFESSIONALS',
}

export type fetchPatientForEditStartActionPayload = { patientId: number };
export type fetchPatientForEditSuccessActionPayload = EC6Patient;
export type fetchPatientForEditErrorActionPayload = GetPatientInfoErrorResponse;
export type RRFLoadAction = any;

export type fetchPatientForEditStartAction = PayloadAction<
  EditPatientActionType.FETCH_PATIENT_FOR_EDIT_START,
  fetchPatientForEditStartActionPayload
>;
export type fetchPatientForEditSuccessAction = PayloadAction<
  EditPatientActionType.FETCH_PATIENT_FOR_EDIT_SUCCESS,
  fetchPatientForEditSuccessActionPayload
>;
export type fetchPatientForEditErrorAction = PayloadAction<
  EditPatientActionType.FETCH_PATIENT_FOR_EDIT_ERROR,
  fetchPatientForEditErrorActionPayload
>;

export type EditPatientSuccessConfirmedAction =
  Action<EditPatientActionType.EDIT_PATIENT_STATUS_MODAL_CONFIRMED>;

export type UpdateEditPatientAction = PayloadAction<
  EditPatientActionType.UPDATE_EDIT_PATIENT,
  EditPatientState
>;

export type NoAssociatedProfessionalsAction =
  Action<EditPatientActionType.NO_ASSOCIATED_PROFESSIONALS>;

export type EditPatientStartAction = PayloadAction<
  EditPatientActionType.EDIT_PATIENT_START,
  EditPatientParams
>;

export type EditPatientSuccessAction = PayloadAction<
  EditPatientActionType.EDIT_PATIENT_SUCCESS,
  TransformedEditPatientSuccessResponse
>;

export type EditPatientErrorAction = PayloadAction<
  EditPatientActionType.EDIT_PATIENT_ERROR,
  TransformedEditPatientErrorResponse
>;

export type EditEMRPatientStartAction = PayloadAction<
  EditPatientActionType.EDIT_EMR_PATIENT_START,
  EditPatientParams
>;

export type EditEMRPatientSuccessAction = PayloadAction<
  EditPatientActionType.EDIT_EMR_PATIENT_SUCCESS,
  TransformedEditPatientSuccessResponse
>;

export type EditEMRPatientErrorAction = PayloadAction<
  EditPatientActionType.EDIT_EMR_PATIENT_ERROR,
  TransformedEditPatientErrorResponse
>;

export type EditPatientActions =
  | EditPatientStartAction
  | EditPatientSuccessAction
  | EditPatientErrorAction
  | EditEMRPatientStartAction
  | EditEMRPatientSuccessAction
  | EditEMRPatientErrorAction
  | fetchPatientForEditStartAction
  | fetchPatientForEditSuccessAction
  | fetchPatientForEditErrorAction
  | RRFLoadAction
  | UpdateEditPatientAction
  | NoAssociatedProfessionalsAction;

export type EditPatientState = {
  isEditingPatient: boolean;
  editWasSuccessful: boolean | null;
  isFetchingPatient: boolean;
  fetchingPatientWasSuccessful: boolean;
  fetchingPatientHasError: boolean;
  patientFormData: PatientFormState;
  patientFormDataBeforeEditing: PatientFormState | null;
};
