import * as React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { PdfReportsProgressModal } from './components/pdf-report-progress';
import { PdfReportsFailModal } from './components/pdf-report-fail';
import { PdfReportsSuccessModal } from './components/pdf-report-success';
import {
  Modal,
  ModalHeader,
  ModalColHeader,
  ModalBody,
  IconWrapper,
} from './pdf-report.style';
import { XIcon } from 'src/assets/icons';
import { DateTime } from 'luxon';

export enum FetchStatus {
  LOADING = 'loading',
  FAILURE = 'failure',
  SUCCESS = 'success',
  NONE = 'none',
}

export class PdfReportsComponentModal extends React.Component<any, any> {
  private subscription;

  constructor(props) {
    super(props);
    this.state = {
      status: FetchStatus.LOADING,
      observableReport: this.props.data.statusReportObservable,
    };
  }

  public componentDidMount() {
    this.subscription = this.state.observableReport.subscribe(
      (patientReportStatus) => {
        this.setState({
          status: patientReportStatus,
        });
      },
    );
  }

  public componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  public onSuccessHandle = () => {
    this.props.destroyModal();
  };

  public onRetryHandle = () => {
    const {
      data: { onRetry },
    } = this.props;
    onRetry();
  };

  public convertDateToHba1cFormat = (dateLastValue) =>
    DateTime.fromISO(dateLastValue, { zone: 'utc' }).toFormat('dd LLL yyyy');

  public render() {
    const {
      destroyModal,
      t,
      data: { patient, patientEndDate, patientStartDate },
    } = this.props;
    const patientInfo = `${patient.firstName}, ${patient.surName} - ID: ${patient.healthCareSystemId}`;

    const contentsElements = {
      [FetchStatus.LOADING]: <PdfReportsProgressModal />,
      [FetchStatus.SUCCESS]: (
        <PdfReportsSuccessModal
          onSuccess={this.onSuccessHandle}
          text={patientInfo}
          patientEndDate={this.convertDateToHba1cFormat(patientEndDate)}
          patientStartDate={this.convertDateToHba1cFormat(patientStartDate)}
        />
      ),
      [FetchStatus.FAILURE]: (
        <PdfReportsFailModal onRetry={this.onRetryHandle} text={patientInfo} />
      ),
    };
    const ElementBody = contentsElements[this.state.status] || <></>;

    return (
      <Modal>
        <ModalHeader>
          <ModalColHeader width="95%">
            {t('pdfReport.reportHeader', { patientInfo })}
          </ModalColHeader>
          <ModalColHeader width="5%">
            <IconWrapper onClick={destroyModal}>
              <XIcon height={14} fillColor="white" />
            </IconWrapper>
          </ModalColHeader>
        </ModalHeader>
        <ModalBody>{ElementBody}</ModalBody>
      </Modal>
    );
  }
}

export const PdfReportsModal = withTranslation(PdfReportsComponentModal);
