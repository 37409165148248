import { map, pipe, prop, sortBy } from 'ramda';
import { FormControlOption } from 'src/widgets/forms/store/utils/types';
import { Program } from '../delivery-configuration.types';

export const convertDeliveryConfigurationDropdownOptions = (
  configuration: Program[],
): FormControlOption[] =>
  pipe(
    map((config) => ({
      label: config.deliveryProgram,
      value: config.deliveryProgramId,
    })),
    sortBy(prop('label')),
  )(configuration);
