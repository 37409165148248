export const DELIVERY_CONFIGURATION_INITIAL_STATE = {
  supplyModelName: 'Model Static',
  initialStock: '150',
  securityPercentage: '30',
  timeGap: '10',
};
export const DELIVERY_CONFIGURATION_FORM_DATA_MODEL = 'deliveryConfiguration';

export const DELIVERY_CONFIGURATION_MIN_NUMBER = 1;
export const DELIVERY_CONFIGURATION_MAX_PERCENTAGE = 100;
export const DELIVERY_CONFIGURATION_MAX_INITIAL_STOCK = 99999;
export const DELIVERY_CONFIGURATION_MAX_TIME_GAP = 100;
