import { createStructuredSelector, createSelector } from 'reselect';
import { path } from 'ramda';
import { selectIsSignedOut, selectIsSessionValid } from 'src/app/session';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';

export const selectPatientProfileType = path(['patient', 'profile']);

export const protectedRouteConnector = createStructuredSelector({
  hasLoggedOut: selectIsSignedOut,
  isAuthenticated: selectIsSessionValid,
  routes: getCombinedRoutes,
  validationFunctionInputs: createSelector(
    selectPatientProfileType,
    (patientProfileType) => patientProfileType,
  ),
});
