import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

export const WireIconComponent = ({
  height = 34,
  fillColor = colors.grayMedium,
  t,
}) => {
  const originalWidth = 76;
  const originalHeight = 34;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="1.-Assign-CGM-device"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="3.-Confirm-Connection-failed"
          transform="translate(-636.000000, -702.000000)"
          fill="#0066CC"
          fillRule="nonzero"
        >
          <g id="Main-card" transform="translate(75.927621, 192.711457)">
            <g id="Main-content" transform="translate(62.072379, 85.288543)">
              <g id="Group-5" transform="translate(498.263326, 425.304145)">
                <g
                  id="noun_24988"
                  transform="translate(37.800000, 16.800000) rotate(-90.000000) translate(-37.800000, -16.800000) translate(21.000000, -21.000000)"
                >
                  <path
                    d="M9.48947368,75.6 C4.91842105,75.6 1.2,71.6058 1.2,66.696 L1.2,15.96 L4.35789474,15.96 L4.35789474,66.696 C4.35789474,69.80484 6.61184211,72.24 9.48947368,72.24 C12.3671053,72.24 14.6210526,69.80568 14.6210526,66.696 L14.6210526,8.904 C14.6210526,3.9942 18.3394737,0 22.9105263,0 C27.4815789,0 31.2,3.9942 31.2,8.904 L31.2,59.64 L28.0421053,59.64 L28.0421053,8.904 C28.0421053,5.79516 25.7881579,3.36 22.9105263,3.36 C20.0328947,3.36 17.7789474,5.79516 17.7789474,8.904 L17.7789474,66.696 C17.7789474,71.6058 14.0605263,75.6 9.48947368,75.6 Z"
                    id="Shape"
                  ></path>
                  <g id="Group" transform="translate(0.000000, 2.400000)">
                    <path
                      d="M5.81809701,5.90290892 L5.81809701,5.86856534 L5.81809701,5.86353945 L0.831156716,5.86353945 L0.831156716,5.86940299 L0.831156716,5.90290892 C0.363215485,5.99002437 0,6.28152604 0,6.63166311 L0,10.9589552 C0,11.3090923 0.363215485,11.600594 0.831156716,11.6877094 C0.918428172,11.7053 1.00486847,11.7270789 1.09878918,11.7270789 L5.5496334,11.7270789 C5.6435541,11.7270789 5.7299944,11.7053 5.81726586,11.6877094 C6.28520709,11.600594 6.648424,11.3090923 6.648424,10.9589552 L6.648424,6.63166311 C6.64925373,6.28152604 6.28603825,5.99002437 5.81809701,5.90290892 Z"
                      id="Shape"
                    ></path>
                    <path
                      d="M5.81809701,5.02589095 L5.81809701,0 L0.831156716,0 L0.831156716,5.02589095 L5.81809701,5.02589095 Z M4.15578358,2.51294548 L4.9869403,2.51294548 L4.9869403,3.35059397 L4.15578358,3.35059397 L4.15578358,2.51294548 Z M1.66231343,2.51294548 L2.49347015,2.51294548 L2.49347015,3.35059397 L1.66231343,3.35059397 L1.66231343,2.51294548 Z"
                      id="Shape"
                    ></path>
                  </g>
                  <g id="Group" transform="translate(26.400000, 61.200000)">
                    <path
                      d="M0.831156716,5.82416997 L0.831156716,5.85851355 L0.831156716,5.86353945 L5.81809701,5.86353945 L5.81809701,5.85767591 L5.81809701,5.82416997 C6.28603825,5.73705452 6.64925373,5.44555285 6.64925373,5.09541578 L6.64925373,0.768123667 C6.64925373,0.417986598 6.28603825,0.126484922 5.81809701,0.0393694791 C5.73082556,0.0217788608 5.64438526,0 5.55046455,0 L1.09962034,0 C1.00569963,0 0.919259328,0.0217788608 0.831987873,0.0393694791 C0.364046642,0.126484922 0.000829733117,0.417986598 0.000829733117,0.768123667 L0.000829733117,5.09541578 C4.57316476e-16,5.44555285 0.363215485,5.73705452 0.831156716,5.82416997 Z"
                      id="Shape"
                    ></path>
                    <path
                      d="M0.831156716,6.70118794 L0.831156716,11.7270789 L5.81809701,11.7270789 L5.81809701,6.70118794 L0.831156716,6.70118794 Z M2.49347015,9.21413341 L1.66231343,9.21413341 L1.66231343,8.37648492 L2.49347015,8.37648492 L2.49347015,9.21413341 Z M4.9869403,9.21413341 L4.15578358,9.21413341 L4.15578358,8.37648492 L4.9869403,8.37648492 L4.9869403,9.21413341 Z"
                      id="Shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const WireIcon = withTranslation(WireIconComponent);
