import * as React from 'react';

import {
  ToolTipNoDataContainer,
  ToolTipNoDataTitle,
  ToolTipNoDataDescription,
} from './tooltip-no-data.style';

import { LocalizedText } from '..';

type ToolTipNoDataDashboardProps = {
  title: string;
  description: string;
  origin?: string;
};

export const ToolTipNoDataDashboardComponent: React.FunctionComponent<
  ToolTipNoDataDashboardProps
> = ({ title, description, origin }) => (
  <ToolTipNoDataContainer className={'ToolTipNoDataContainer'} origin={origin}>
    <ToolTipNoDataTitle>
      <LocalizedText textKey={title} />
    </ToolTipNoDataTitle>
    <ToolTipNoDataDescription>
      <LocalizedText textKey={description} />
    </ToolTipNoDataDescription>
  </ToolTipNoDataContainer>
);
