import { Action, PayloadAction } from 'src/app/store/app.types';
import { ActiveSystemLanguages } from 'src/services/active-system-languages/active-system-languages.types';
import { Diff } from 'src/utils';

export enum LanguagesActionType {
  FETCH_LANGUAGES_START = 'FETCH_LANGUAGES_START',
  FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS',
  FETCH_LANGUAGES_ERROR = 'FETCH_LANGUAGES_ERROR',
}

export type LanguagesPayload = ActiveSystemLanguages;

export type FetchLanguagesStart =
  Action<LanguagesActionType.FETCH_LANGUAGES_START>;
export type FetchLanguagesSuccess = PayloadAction<
  LanguagesActionType.FETCH_LANGUAGES_SUCCESS,
  LanguagesPayload
>;
export type FetchLanguagesError = PayloadAction<
  LanguagesActionType.FETCH_LANGUAGES_ERROR,
  any
>;

export type LanguagesState = {
  isLoading?: boolean;
  activeLanguages: ActiveSystemLanguages;
  error?: any;
};

export type LanguagesEpicActions = FetchLanguagesStart;

export type LanguagesActions =
  | FetchLanguagesStart
  | FetchLanguagesSuccess
  | FetchLanguagesError;

export type LanguagesReducerActions = Diff<
  LanguagesActions,
  LanguagesEpicActions
>;
