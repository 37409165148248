import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withToolTip } from 'src/utils';

import { Logbook24Hours } from './logbook-24hrs.component';
import { logbook24HoursConnector } from './logbook-24hrs.selector';

export const Logbook24HoursContainer = compose(
  connect(logbook24HoursConnector),
  withRouter,
  withToolTip,
)(Logbook24Hours);
