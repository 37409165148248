import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'src/components/protected-route';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';
import { connectWithPatformPermission } from './utils/utils';
import { LoadingRing, Column } from 'src/components';

import {
  EditPatientBundle,
  EditPatientWithPlatformBundle,
  DeactivatePatientBundle,
  CreatePatientBundle,
  TimePeriodsBundle,
  ListDevicesBundle,
  GraphicSettingsBundle,
  DeliveryConfigurationBundle,
  NextShipmentBundle,
  HomeDeliveryJelloBundle
} from './bundles';

export const patientLinks = {
  patientById: '/patients/:id',
  patientBGDashboard: '/patients/bg/:id',
  patients: '/patients',
  editPatient: '/patients/:id/edit',
  deactivatePatient: '/patients/:id/deactivate',
  createPatient: '/patient/create',
  editTimePeriods: '/patients/:id/time-periods',
  listDevices: '/patients/:id/devices',
  graphSettings: '/patients/:id/graph-settings',
  delivery: '/patients/bg/:id/delivery',
  deliveryConfiguration: '/patients/bg/:id/delivery-configuration',
  nextShipment: '/patients/bg/:id/next-shipment',
};

const loadingRing = (
  <Column align="center" height="100vh" justifyContent="center">
    <LoadingRing infinite />
  </Column>
);

export const PatientRoutes = () => (
  <Switch>
    <ProtectedRoute
      exact
      path={patientLinks.editPatient}
      component={connectWithPatformPermission(
        ({ isFetchingPermissions, hasUserPlatformPermission }) => {
          if (isFetchingPermissions) {
            return loadingRing;
          } else if (hasUserPlatformPermission) {
            return <EditPatientWithPlatformBundle />;
          } else {
            return <EditPatientBundle />;
          }
        },
      )}
    />
    <ProtectedRoute
      exact
      path={patientLinks.deactivatePatient}
      component={DeactivatePatientBundle}
      hasPermissions={[PERMISSIONS.PATIENT_MANAGEMENT_DELETE]}
    />
    <ProtectedRoute
      exact
      path={patientLinks.createPatient}
      component={CreatePatientBundle}
      hasPermissions={[PERMISSIONS.REGISTER_PATIENT]}
    />
    <ProtectedRoute
      exact
      path={patientLinks.editTimePeriods}
      component={TimePeriodsBundle}
      hasPermissions={[PERMISSIONS.TIME_BLOCKS_MANAGEMENT]}
    />
    <ProtectedRoute
      exact
      path={patientLinks.listDevices}
      component={ListDevicesBundle}
      hasPermissions={[PERMISSIONS.PATIENT_DEVICES]}
    />
    <ProtectedRoute
      exact
      path={patientLinks.graphSettings}
      component={GraphicSettingsBundle}
      hasPermissions={[PERMISSIONS.RELEVANT_CLINICAL_DATA]}
    />
    <ProtectedRoute
      exact
      path={patientLinks.delivery}
      component={HomeDeliveryJelloBundle}
      hasPermissions={[
        PERMISSIONS.NEXT_SHIPMENT,
        PERMISSIONS.PATIENT_DELIVERY_PROGRAM_CONFIG,
      ]}
    />
    <ProtectedRoute
      exact
      path={patientLinks.deliveryConfiguration}
      component={DeliveryConfigurationBundle}
      hasPermissions={[PERMISSIONS.PATIENT_DELIVERY_PROGRAM_CONFIG]}
    />
    <ProtectedRoute
      exact
      path={patientLinks.nextShipment}
      component={NextShipmentBundle}
      hasPermissions={[PERMISSIONS.NEXT_SHIPMENT]}
    />
  </Switch>
);
