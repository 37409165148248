import {
  PatientSessionEndLoaderImpl,
  PatientSessionEndTransformImpl,
  PatientSessionEndServiceImpl,
} from './patient-session-end.service';
import {
  mockLoaderSuccess,
  // mockLoaderError,
} from './patient-session-end.mock';

export const PatientSessionEndFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoaderSuccess : PatientSessionEndLoaderImpl;
  return PatientSessionEndServiceImpl(loader, PatientSessionEndTransformImpl);
};
