import { hasValue } from 'src/utils/ramda';

export const RangeValidators = {
  isRequired: (string: string) => hasValue(string),
  isValidNum: (string: string) => isInteger(string),
  isValidVal: (string: string, array: number[]) =>
    isWithinLimits(string, array),
  isValidDec: (value: string, unit: string) => isDecimal(value, unit),
  isBiggerThan: (string1: string, string2: string) =>
    isBigger(string1, string2),
};

export type RangeErrorsTypes = {
  isRequired: string;
  isValidNum: string;
  isValidVal: string;
  isValidDec: string;
  isBiggerThan: string;
};

export const RangeErrors: RangeErrorsTypes = {
  isRequired: 'target-ranges.validations.isRequired',
  isValidNum: 'target-ranges.validations.isValidNum',
  isValidVal: 'target-ranges.validations.isValidVal',
  isValidDec: 'target-ranges.validations.isValidDec',
  isBiggerThan: 'target-ranges.validations.isBiggerThan',
};

const FLOAT_NUMERIC_REGEX = /^\d+(\.\d{1})?$/;
export const isInteger = (value: string) =>
  hasValue(String(value || 0).match(FLOAT_NUMERIC_REGEX));

export const isWithinLimits = (data: string, limits: number[]) => {
  if (
    isInteger(data) &&
    hasValue(data) &&
    hasValue(limits[0]) &&
    hasValue(limits[1])
  ) {
    const value = parseFloat(data);
    return value >= limits[0] && value <= limits[1];
  }
  return true;
};

export const isBigger = (string1: string, string2: string) => {
  if (isInteger(string1) && hasValue(string1) && hasValue(string2)) {
    const value1 = parseFloat(string1);
    const value2 = parseFloat(string2);
    return value2 ? value1 > value2 : true;
  }
  return true;
};

export const isDecimal = (value: string, unit: string) => {
  if (hasValue(value)) {
    return unit === 'MGDL' ? String(value).indexOf('.') < 0 : true;
  }
  return true;
};
