import * as React from 'react';
import { SvgIcon } from 'src/components/icon';

type SendInviteIconPropsType = {
  width: number;
  height: number;
  fill: string;
  viewBox: string;
  disabled: boolean;
  style?: React.CSSProperties;
};

export const SendInviteIcon: React.FC<SendInviteIconPropsType> = ({
  width,
  height,
  fill,
  viewBox,
  disabled,
  style,
}): JSX.Element => {
  return (
    <SvgIcon
      width={width}
      height={height}
      originalWidth={width}
      originalHeight={height}
      fill={fill}
      viewBox={viewBox}
      style={style}
    >
      {disabled ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8555 2.14563C13.7177 2.00851 13.513 1.96366 13.3305 2.03063L2.33049 6.03063C2.13626 6.1043 2.00781 6.2904 2.00781 6.49813C2.00781 6.70586 2.13626 6.89196 2.33049 6.96563L6.62549 8.68063L9.79549 5.50063L10.5005 6.20563L7.31549 9.39063L9.03549 13.6856C9.1113 13.876 9.29559 14.0008 9.50049 14.0006C9.70733 13.9964 9.89019 13.8652 9.96049 13.6706L13.9605 2.67063C14.03 2.49006 13.9891 2.28557 13.8555 2.14563Z"
          fill="#BFBFBF"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8555 2.14563C13.7177 2.00851 13.513 1.96366 13.3305 2.03063L2.33049 6.03063C2.13626 6.1043 2.00781 6.2904 2.00781 6.49813C2.00781 6.70586 2.13626 6.89196 2.33049 6.96563L6.62549 8.68063L9.79549 5.50063L10.5005 6.20563L7.31549 9.39063L9.03549 13.6856C9.1113 13.876 9.29559 14.0008 9.50049 14.0006C9.70733 13.9964 9.89019 13.8652 9.96049 13.6706L13.9605 2.67063C14.03 2.49006 13.9891 2.28557 13.8555 2.14563Z"
          fill="#0066CC"
        />
      )}
    </SvgIcon>
  );
};
