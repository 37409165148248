import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { selectAccessToken, selectGigyaToken } from 'src/app/session';
import { State } from 'src/app/store/app.types';
import { GetHba1cCardServiceFnType } from 'src/services/hba1c-card';

import {
  FetchHba1cCardStartAction,
  Hba1cCardActions,
  Hba1cCardActionType,
} from './hba1c-card.types';
import {
  fetchHba1cCardSuccess,
  fetchHba1cCardError,
} from './hba1c-card.actions';

export const fetchHba1cCardEpics: (
  GetLastHba1cInfo: GetHba1cCardServiceFnType,
) => Epic<Hba1cCardActions, State> = (GetLastHba1cInfo) => (action$, store) =>
  action$
    .ofType(Hba1cCardActionType.FETCH_HBA1C_CARD_START)
    .switchMap(({ payload: fhirId }: FetchHba1cCardStartAction) => {
      const accessToken = selectAccessToken(store.getState());
      const apiKey = selectGigyaToken(store.getState());
      return Observable.fromPromise(
        GetLastHba1cInfo(accessToken, apiKey, fhirId),
      )
        .map((lastHba1c) => fetchHba1cCardSuccess(lastHba1c))
        .pipe(catchError(() => Observable.of(fetchHba1cCardError())));
    });
