import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIconDiv } from 'src/components/icon';

type Props = {
  height?: number;
  strokeColor?: string;
  width?: number;
  originalWidth?: number;
  originalHeight?: number;
  minX?: number;
  minY?: number;
};

export const V3ExportTimeTrackerIcon = ({
  height = 42,
  width,
  strokeColor = colors.white,
  originalWidth = 44,
  originalHeight = 44,
  minX,
  minY,
}: Props) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIconDiv
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={width ? width : height * aspectRatio}
      minX={minX}
      minY={minY}
    >
      <g
        id="02_Export-RPM-excel"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M22.3365558,10.6213804 C28.8420698,10.6213804 34.1158308,15.8951414 34.1158308,22.4006554 C34.1158308,24.513271 33.5483069,26.5673734 32.4976871,28.3591357 C32.1177109,27.6837924 31.6549007,27.062606 31.1228601,26.5083859 C31.7465585,25.2674486 32.0965265,23.8735124 32.0965265,22.4006554 C32.0965265,17.1962442 27.7268388,12.9772354 22.3365558,12.9772354 C16.9462729,12.9772354 12.5765852,17.1962442 12.5765852,22.4006554 C12.5765852,25.2692794 13.9041461,27.838527 15.9984355,29.5669046 C15.7106649,30.3188007 15.519197,31.1182909 15.4393559,31.9503823 C12.4820883,29.8114993 10.5572809,26.3308587 10.5572809,22.4006554 C10.5572809,15.8951414 15.8310419,10.6213804 22.3365558,10.6213804 Z M23.8750733,17.0158439 L23.8750733,22.9525985 L20.388408,26.4392639 L18.4902619,24.5411179 L21.1826676,21.8487122 L21.1826676,17.0158439 L23.8750733,17.0158439 Z M13.028427,8.61149978 L14.5374472,10.1298382 L10.7200928,13.9868875 L9.21107266,12.4685492 L13.028427,8.61149978 Z M30.9715832,8.60207612 L34.7889322,12.45912 L33.2799174,13.977453 L29.4625684,10.1204091 L30.9715832,8.60207612 Z"
          id="Combined-Shape"
          fill="#0066CC"
        />
        <polygon
          id="Path"
          fill="#0066CC"
          transform="translate(25.044983, 32.657439) rotate(90.000000) translate(-25.044983, -32.657439) "
          points="26.7644843 33.8754325 24.0255311 37.0300346 25.5224009 38.7474048 30.8304498 32.6574394 25.5224009 26.567474 24.0255311 28.2848443 26.7644843 31.4394464 19.2595156 31.4394464 19.2595156 33.8754325"
        />
      </g>
    </SvgIconDiv>
  );
};
