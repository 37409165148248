// @ts-nocheck
import * as React from 'react';

import { GenerateRowIconsComponent } from '../icon-components.component';

import {
  V3StockFilledIcon,
  DisclaimerIcon,
  CheckmarkFilledIcon,
} from 'src/assets/icons';

import { colors } from 'src/core/styles';
import { JelloIcon } from 'src/components/jello-icon';

const statusIconsTitle = 'manufacturerInfo.status.statusIconsTitle';

const statusComponents = [
  {
    icon: (
      <CheckmarkFilledIcon
        key={1}
        height={20}
        width={20}
        fillColor={colors.white}
        fillCircleColor={colors.brandBlue}
        circle={true}
        originalWidth={55}
        originalHeight={40}
        minX={-6}
        minY={0}
        radius={26}
      />
    ),
    name: 'manufacturerInfo.status.success',
  },
  {
    icon: (
      <JelloIcon size="M" color={colors.red} iconName={'no--enough--data'} />
    ),
    name: 'manufacturerInfo.status.warning',
  },
  {
    icon: (
      <V3StockFilledIcon
        key={3}
        height={22}
        width={20}
        originalWidth={20}
        originalHeight={12}
        minX={0}
        minY={4}
        fillCircleColor={colors.brandBlue}
      />
    ),
    name: 'manufacturerInfo.status.stockStatus',
  },
  {
    icon: (
      <DisclaimerIcon
        key={4}
        height={20}
        width={20}
        fillColor={colors.white}
        iconColor={colors.greyMediumTimeRange}
        borderFillColor={colors.white}
        withBorder={true}
      />
    ),
    name: 'manufacturerInfo.status.noDataAvailable',
  },
];

export const StatusIconsComponent = () => (
  <GenerateRowIconsComponent
    generalIconTags={statusComponents}
    generalIconsTitle={statusIconsTitle}
  />
);
