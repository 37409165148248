import React, { Fragment } from 'react';

import { colors } from 'src/core/styles';
import { RenderIf } from 'src/domains/diagnostics/utils';
import { RectangleSeries } from 'src/lib';
import { DATA_TYPE } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  areDatesTheSameDay,
  getClickableCursorStyle,
} from 'src/domains/diagnostics/scenes/graphs/graph.util';
import { toolTipWidth } from 'src/domains/diagnostics/components/tool-tip';

const RECT_OPACITY = {
  ACTIVE: 0.6,
  PASSIVE: 0.2,
};

const LINE_OPACITY = {
  ACTIVE: 1,
  PASSIVE: 0.4,
};

const LINE_WIDTH = 3;

const isSelected = (selectedDate, crossoverDate, data) =>
  !selectedDate ||
  areDatesTheSameDay(crossoverDate ? crossoverDate : data.date, selectedDate);

export const InsulinGraph = ({
  width,
  height,
  insulinPoints,
  selectedDate,
  onLineClick,
  onPointMouseOver,
  onPointMouseOut,
  collapsed,
}) => (
  <RectangleSeries
    width={width}
    height={height}
    points={insulinPoints}
    Shape={(insulinPoint, index) => {
      const {
        x,
        lineHeight,
        rectHeight,
        rectWidth,
        crossoverDate,
        color,
        date,
      } = insulinPoint;
      return (
        <Fragment
          key={`${x}-${lineHeight}-${rectHeight}-${rectWidth}-${index}`}
        >
          <RenderIf validate={rectHeight}>
            <rect
              x={x}
              y={0}
              width={rectWidth}
              height={rectHeight}
              fill={
                isSelected(selectedDate, crossoverDate, { date })
                  ? color
                  : colors.grayDark
              }
              opacity={
                isSelected(selectedDate, crossoverDate, { date })
                  ? RECT_OPACITY.ACTIVE
                  : RECT_OPACITY.PASSIVE
              }
              onClick={onLineClick({
                type: DATA_TYPE.INSULIN,
                date: crossoverDate ? crossoverDate : date,
              })}
              onMouseMove={(event) =>
                onPointMouseOver(
                  event,
                  { type: DATA_TYPE.INSULIN, ...insulinPoint },
                  toolTipWidth,
                )
              }
              onMouseOut={onPointMouseOut}
              style={{
                pointerEvents: isSelected(
                  selectedDate,
                  crossoverDate,
                  insulinPoint,
                )
                  ? 'auto'
                  : 'none',
              }}
              className="no-user-select"
              cursor={getClickableCursorStyle(!collapsed)}
            />
          </RenderIf>
          <RenderIf validate={lineHeight}>
            <rect
              x={x - LINE_WIDTH / 2}
              y={0}
              width={LINE_WIDTH}
              height={lineHeight}
              fill={
                isSelected(selectedDate, crossoverDate, insulinPoint)
                  ? color
                  : colors.grayDark
              }
              opacity={
                isSelected(selectedDate, crossoverDate, insulinPoint)
                  ? LINE_OPACITY.ACTIVE
                  : LINE_OPACITY.PASSIVE
              }
              onClick={onLineClick({
                type: DATA_TYPE.INSULIN,
                date: crossoverDate ? crossoverDate : date,
              })}
              onMouseMove={(event) =>
                onPointMouseOver(
                  event,
                  { type: DATA_TYPE.INSULIN, ...insulinPoint },
                  toolTipWidth,
                )
              }
              onMouseOut={onPointMouseOut}
              style={{
                pointerEvents: isSelected(
                  selectedDate,
                  crossoverDate,
                  insulinPoint,
                )
                  ? 'auto'
                  : 'none',
              }}
              className="no-user-select"
              cursor={getClickableCursorStyle(!collapsed)}
            />
          </RenderIf>
        </Fragment>
      );
    }}
  />
);
