import React from 'react';

import { colors } from 'src/core/styles';

import {
  PopoverContainerDiv,
  PopoverRectangleContainer,
  PopoverRectangleContainerWithoutBorder,
} from './popover.style';

export const Popover = ({
  backgroundColor = colors.white,
  children,
  pushLeft,
  show,
  width,
  periods,
}) => (
  <PopoverContainerDiv show={show}>
    {periods ? (
      <PopoverRectangleContainerWithoutBorder
        backgroundColor={backgroundColor}
        pushLeft={pushLeft}
        width={width}
      >
        {children}
      </PopoverRectangleContainerWithoutBorder>
    ) : (
      <PopoverRectangleContainer
        backgroundColor={backgroundColor}
        pushLeft={pushLeft}
        width={width}
      >
        {children}
      </PopoverRectangleContainer>
    )}
  </PopoverContainerDiv>
);
