import {
  createAuthHeader,
  putJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import {
  NextShipmentChangeDateLoaderImplType,
  NextShipmentChangeDateServiceImplType,
  OpenId,
} from './next-shipment-change-date.types';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const NextShipmentChangeDateLoaderImpl: NextShipmentChangeDateLoaderImplType =
  (id: number, deliveryDate: string, status: string, openId: OpenId) =>
    putJSON(endpointWithParams(ENDPOINTS.nextShipmentChangeDate, { id }), {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
        deliveryDate,
      },
      body: stringifyBody({ deliveryDate, status }),
    });

export const NextShipmentChangeDateTransformImpl = (data: any) => data;

export const NextShipmentChangeDateServiceImpl: NextShipmentChangeDateServiceImplType =

    (load: NextShipmentChangeDateLoaderImplType, transform) =>
    (id, deliveryDate, status, token) =>
      load(id, deliveryDate, status, token).then(transform);
