import * as React from 'react';
import { connect } from 'react-redux';
import { selectAccessToken, selectGigyaToken } from 'src/app/session';

import { selectJelloPermission, selectPermissions } from 'src/core/permissions';
import {
  selectEC6FullName,
  selectEC6TimeZone,
  selectEC6UserLanguage,
} from 'src/core/user/user.selectors';

import { getConfig } from '@roche/roche-common';
import { selectPatient, selectPatientId, selectPatientFhirId } from 'src/core';
import { additionalInfoActivated } from 'src/domains/diagnostics/components/additional-info';

interface OwnProps {
  children: JSX.Element;
}

interface StateProps {
  patientId: string;
  legacyPatientId: string;
  patientName: string;
  hcpLanguage: string;
  hcpTimezone: string;
  hcpName: string;
  hcpPermissions: string[];
  apiUrl: string;
  clientId: string;
  apiKey: string;
  accessToken: string;
  clientSecret: string;
  additionalInfoFlag: boolean;
  jelloDs: boolean;
}

interface VisitModuleConfigProps {
  activeSection: VisitModuleConfigProps;
}

type Props = VisitModuleConfigProps & StateProps & OwnProps;

const VisitModuleSidebarAnchorConfigComponent = (props: Props) => {
  const children = props.children;
  return (
    <>
      {React.cloneElement(children, {
        patientId: props.patientId,
        legacyPatientId: props.legacyPatientId,
        patientName: props.patientName,
        hcpLanguage: props.hcpLanguage,
        hcpTimezone: props.hcpTimezone,
        hcpName: props.hcpName,
        hcpPermissions: props.hcpPermissions,
        apiUrl: props.apiUrl,
        clientId: props.clientId,
        apiKey: props.apiKey,
        accessToken: props.accessToken,
        clientSecret: props.clientSecret,
        additionalInfoFlag: props.additionalInfoFlag,
        jelloDs: props.jelloDs,
        activeSection: props.activeSection,
      })}
    </>
  );
};

const mapStateToProps = (state): StateProps => ({
  ...state,
  patientId: selectPatientFhirId(state),
  legacyPatientId: selectPatientId(state),
  patientName:
    selectPatient(state).firstName + ' ' + selectPatient(state).surName,
  hcpLanguage: selectEC6UserLanguage(state),
  hcpTimezone: selectEC6TimeZone(state),
  hcpName: selectEC6FullName(state),
  hcpPermissions: selectPermissions(state),
  apiUrl: getConfig().REACT_APP_API_MULE,
  clientId: getConfig().REACT_APP_CLIENT_ID,
  apiKey: selectGigyaToken(state),
  accessToken: selectAccessToken(state),
  clientSecret: getConfig().REACT_APP_CLIENT_SECRET,
  additionalInfoFlag: additionalInfoActivated(),
  jelloDs: selectJelloPermission(state),
});

export const VisitModuleSidebarAnchorConfig = connect(mapStateToProps)(
  VisitModuleSidebarAnchorConfigComponent,
);
