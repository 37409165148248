import { isNil } from 'ramda';
import React from 'react';

import { HandPointIcon } from 'src/assets/icons';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils';

import {
  HandPointIconWrapper,
  LogbookCellBlockContentsBase,
  LogbookCellBlockContentsBG,
  LogbookCellBlockContentsHyper,
  LogbookCellBlockContentsHypo,
} from './logbook-cell-block-contents.style';

import { LOGBOOK_UNITS } from '../../../constants/logbook.constants';

export const LogbookCellBlockContents = ({
  unit,
  bloodGlucoseUnit,
  value,
  belowTargetRange,
  aboveTargetRange,
  hypoSymptoms,
  manuallyEntered,
  children,
}) => {
  if (unit !== LOGBOOK_UNITS.GLUCOSE || isNil(value)) {
    return (
      <LogbookCellBlockContentsBase>{children}</LogbookCellBlockContentsBase>
    );
  }

  const formattedBG = formatBGMeasurement(bloodGlucoseUnit)(value);
  const handPointIcon = manuallyEntered && (
    <HandPointIconWrapper
      shiftRight={aboveTargetRange || belowTargetRange || hypoSymptoms}
    >
      <HandPointIcon />
    </HandPointIconWrapper>
  );

  if (aboveTargetRange) {
    return (
      <LogbookCellBlockContentsHyper>
        {formattedBG} {handPointIcon}
      </LogbookCellBlockContentsHyper>
    );
  } else if (belowTargetRange || hypoSymptoms) {
    return (
      <LogbookCellBlockContentsHypo hypoSymptoms={hypoSymptoms}>
        {formattedBG} {handPointIcon}
      </LogbookCellBlockContentsHypo>
    );
  }
  return (
    <LogbookCellBlockContentsBG>
      {formattedBG} {handPointIcon}
    </LogbookCellBlockContentsBG>
  );
};
