import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { Checkbox, CardMinimizer } from 'src/components';
import { colors } from 'src/core/styles/colors';
import {
  CrossMarkIcon,
  RectangleMarkIcon,
  ConnectingLinesIcon,
  MeanBloodGlucoseIcon,
  GridIcon,
} from 'src/assets/icons';
import { RenderIf } from 'src/utils';

import {
  CheckboxContainer,
  CheckboxesForm,
  GraphOptionsContainer,
  SVGContainer,
} from './graph-options.style';

export class GraphOptionsComponent extends React.Component {
  ref = React.createRef();
  wrapperRef;
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  componentDidMount() {
    if (this.ref.current) {
      this.wrapperRef = this.ref.current;
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const {
      bloodGlucoseToggle = false,
      bgBeforeMealToggle = false,
      bgAfterMealToggle = false,
      meanBloodGlucoseToggle = false,
      connectingLinesToggle = false,
      gridLinesToggle = false,
      match,
      t,
    } = this.props;

    return (
      <GraphOptionsContainer ref={this.ref}>
        <CheckboxesForm model="ui.patientDashboard">
          <RenderIf validate={bloodGlucoseToggle}>
            <CheckboxContainer>
              <Checkbox
                id={t('graphs.detailGraph.bloodGlucose')}
                label={
                  <React.Fragment>
                    <SVGContainer>
                      <CrossMarkIcon />
                    </SVGContainer>
                    {t('graphs.detailGraph.bloodGlucose')}
                  </React.Fragment>
                }
                labelBeforeCheckbox
                modelPath=".showBloodGlucosePoints"
                size={20}
              />
            </CheckboxContainer>
          </RenderIf>
          <RenderIf validate={bgBeforeMealToggle}>
            <CheckboxContainer>
              <Checkbox
                id={t('graphs.detailGraph.bgBeforeMeal')}
                label={
                  <React.Fragment>
                    <SVGContainer>
                      <RectangleMarkIcon fillColor={colors.white} />
                    </SVGContainer>
                    {t('graphs.detailGraph.bgBeforeMeal')}
                  </React.Fragment>
                }
                labelBeforeCheckbox
                modelPath=".showBloodGlucoseBeforeMealPoints"
                size={20}
              />
            </CheckboxContainer>
          </RenderIf>
          <RenderIf validate={bgAfterMealToggle}>
            <CheckboxContainer>
              <Checkbox
                id={t('graphs.detailGraph.bgAfterMeal')}
                label={
                  <React.Fragment>
                    <SVGContainer>
                      <RectangleMarkIcon />
                    </SVGContainer>
                    {t('graphs.detailGraph.bgAfterMeal')}
                  </React.Fragment>
                }
                labelBeforeCheckbox
                modelPath=".showBloodGlucoseAfterMealPoints"
                size={20}
              />
            </CheckboxContainer>
          </RenderIf>
          <RenderIf validate={meanBloodGlucoseToggle}>
            <CheckboxContainer>
              <Checkbox
                id={t('graphs.detailGraph.meanBloodGlucose')}
                label={
                  <React.Fragment>
                    <SVGContainer>
                      <MeanBloodGlucoseIcon />
                    </SVGContainer>
                    {t('graphs.detailGraph.meanBloodGlucose')}
                  </React.Fragment>
                }
                labelBeforeCheckbox
                modelPath=".showMeanBloodGlucose"
                size={20}
              />
            </CheckboxContainer>
          </RenderIf>
          <RenderIf validate={connectingLinesToggle}>
            <CheckboxContainer>
              <Checkbox
                id={t('graphs.detailGraph.connectingLines')}
                label={
                  <React.Fragment>
                    <SVGContainer>
                      <ConnectingLinesIcon />
                    </SVGContainer>
                    {t('graphs.detailGraph.connectingLines')}
                  </React.Fragment>
                }
                labelBeforeCheckbox
                modelPath=".showBloodGlucoseLines"
                size={20}
              />
            </CheckboxContainer>
          </RenderIf>
          <RenderIf validate={gridLinesToggle}>
            <CheckboxContainer>
              <Checkbox
                id={t('graphs.detailGraph.gridLines')}
                label={
                  <React.Fragment>
                    <SVGContainer>
                      <GridIcon />
                    </SVGContainer>
                    {t('graphs.detailGraph.gridLines')}
                  </React.Fragment>
                }
                labelBeforeCheckbox
                modelPath=".showGridLines"
                size={20}
              />
            </CheckboxContainer>
          </RenderIf>
        </CheckboxesForm>
        <CardMinimizer link={`/patients/${match.params.id}`} />
      </GraphOptionsContainer>
    );
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showPopover: false });
    }
  };
}

export const GraphOptions = withTranslation(GraphOptionsComponent);
