import { map, pipe, values, filter } from 'ramda';

import { convertJSDateGMT, toFormat } from 'src/utils';

export const filterForHighestDailyTotalInsulinValues = (totals) => {
  const dailyTotal = totals.reduce((acc, measurement) => {
    const date = toFormat('D')(convertJSDateGMT(measurement.date));

    return {
      ...acc,
      [date]:
        !acc[date] || measurement.bolusValue > acc[date].bolusValue
          ? measurement
          : acc[date],
    };
  }, {});

  return values(dailyTotal);
};

const keepBolusOfRegisterType =
  (registerTypeString) =>
  ({ registerType }) =>
    registerType === registerTypeString;

const toBolusShape = ({ date, value, remark, registerType, bolusType }) => ({
  date,
  bolusValue: value,
  bolusRemark: remark,
  bolusRegisterType: registerType,
  bolusType: bolusType,
});

export const createBolusObject = (registerTypeString) =>
  pipe(filter(keepBolusOfRegisterType(registerTypeString)), map(toBolusShape));
