import styled from 'styled-components';

import { breakpoints } from 'src/core';

export const ThresholdText = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  right: 15px;
  font-size: 14px;
  position: absolute;
  bottom: -11px;
  @media (max-width: ${breakpoints.changeMedium}) {
    font-size: 11px;
    right: 5px;
    bottom: -8px;
  }
`;
ThresholdText.displayName = 'ThresholdText';

export const PercentageText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 16px;
  @media (max-width: ${breakpoints.changeMedium}) {
    font-size: 14px;
  }
`;
PercentageText.displayName = 'PercentageText';

export const PercentageSymbol = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 14px;
  margin-right: ${({ theme }) => theme.spacing.one};
  @media (max-width: ${breakpoints.changeMedium}) {
    font-size: 12px;
  }
`;
PercentageSymbol.displayName = 'PercentageSymbol';

export const InfoBlock = styled.div``;
InfoBlock.displayName = 'InfoBlock';

export const Keyline = styled.div`
  margin: 0 4%;
  background-color: #d2d2d2;
  height: 2px;
  width: 12%;
`;
InfoBlock.displayName = 'InfoBlock';

export const MainText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  @media (max-width: ${breakpoints.changeMedium}) {
    font-size: 12px;
  }
`;
MainText.displayName = 'MainText';
