import React from 'react';
import * as queryString from 'qs';
import { isEmpty, isNil } from 'ramda';
import { customDatadogLog, createSessionId, LOGS } from 'src/app/logger';

export const OIDCSuccessComponent = ({
  location,
  apiGateway,
  gigyaToken,
  redirectUri,
  onComeFromLogin,
  fetchOidcTokens,
  accessToken,
  successRedirect,
  onLoginSuccess,
}) => {
  React.useEffect(() => {
    const qs = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    createSessionId(qs.code);
    customDatadogLog('session', LOGS.login);
    onComeFromLogin();
    fetchOidcTokens({
      token: qs.code,
      apiGateway,
      gigyaToken,
      redirectUri,
    });
  }, []);

  React.useEffect(() => {
    if (accessToken) {
      const redirect =
        isEmpty(successRedirect) || isNil(successRedirect)
          ? '/home'
          : successRedirect;
      onLoginSuccess(redirect);
    }
  }, [accessToken]);

  return <></>;
};
