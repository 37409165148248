// @ts-nocheck
import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { RenderIf } from 'src/utils';
import { RangeConfirmModalContainer } from '../../widgets/target-ranges/components';
import { UnitsConfirmModalContainer } from '../../widgets/user-units-selector/components/index';

type ChangeNavigationRouteProps = {
  shouldBlockNavigation: (nextLocation: string) => any;
  navigate: (lastLocation: any) => any;
  when: boolean;
  header: string;
  title: string;
  content: string;
  component: string;
  disabled?: boolean;
  okBtnText: string;
  cancelBtnText: string;
};

type ChangeNavigationRouteState = {
  inDocument: boolean;
  modalVisible: boolean;
  lastLocation: object;
  confirmedNavigation: boolean;
};

export const CONFIRMATION_COMPONENTS = {
  rangeConfirm: {
    modal: 'rangeConfirmModal',
    id: 'jello-dialog',
  },
  unitsConfirm: {
    modal: 'unitsConfirmModal',
    id: 'jello-dialog',
  },
};

export class ChangeNavigationRoute extends React.Component<
  ChangeNavigationRouteProps,
  ChangeNavigationRouteState
> {
  public state = {
    inDocument: true,
    modalVisible: false,
    lastLocation: {
      pathname: null,
    },
    confirmedNavigation: false,
  };

  public showModal = (location) => {
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });
  };

  public closeModal = (callback) =>
    this.setState(
      {
        modalVisible: false,
      },
      callback,
    );

  public handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation, inDocument } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (
      inDocument &&
      !confirmedNavigation &&
      shouldBlockNavigation(nextLocation)
    ) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  public handleConfirmNavigationClick = () => {
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            navigate(lastLocation.pathname);
          },
        );
      }
    });
  };

  public componentDidMount() {
    document.addEventListener('mouseenter', this.setInDocument, true);
    document.addEventListener('mouseleave', this.setOutDocument, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mouseenter', this.setInDocument, true);
    document.removeEventListener('mouseleave', this.setOutDocument, false);
  }

  public setInDocument = () => this.setState({ inDocument: true });
  public setOutDocument = () => this.setState({ inDocument: false });

  public render() {
    const { when, component } = this.props;
    const { modalVisible } = this.state;

    return (
      <React.Fragment>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <RenderIf validate={modalVisible}>
          {this.selectModalContainer(component)}
        </RenderIf>
      </React.Fragment>
    );
  }

  private readonly selectModalContainer = (component) => {
    switch (component) {
      case CONFIRMATION_COMPONENTS.rangeConfirm.modal:
        return (
          <RangeConfirmModalContainer
            id={CONFIRMATION_COMPONENTS.rangeConfirm.id}
            title={this.props.title}
            content={this.props.content}
            header={this.props.header}
            okBtnText={this.props.okBtnText}
            cancelBtnText={this.props.cancelBtnText}
            disabled={this.props.disabled ? this.props.disabled : null}
            onCancel={this.closeModal}
            onConfirm={this.handleConfirmNavigationClick}
            {...this.props}
          />
        );

      case CONFIRMATION_COMPONENTS.unitsConfirm.modal:
        return (
          <UnitsConfirmModalContainer
            id={CONFIRMATION_COMPONENTS.unitsConfirm.id}
            title={this.props.title}
            content={this.props.content}
            header={this.props.header}
            okBtnText={this.props.okBtnText}
            cancelBtnText={this.props.cancelBtnText}
            disabled={this.props.disabled ? this.props.disabled : null}
            onCancel={this.closeModal}
            onConfirm={this.handleConfirmNavigationClick}
            {...this.props}
          />
        );

      default:
        return <></>;
    }
  };
}
