import React from 'react';
import { reverse } from 'ramda';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { ArrowIcon } from 'src/assets/icons';
import { RenderIf } from 'src/domains/diagnostics/utils';
import { colors } from 'src/core';

import {
  Container,
  ContentContainer,
  Nav,
  NavTabsList,
  NavTabsListItem,
  NavTabsListItemText,
  ArrowUpDown,
} from './vertical-tabs.style';

export class VerticalTabsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: null,
    };
  }
  render() {
    const { height, navItemLabelSelectedStyle, tabs, m, t } = this.props;
    const orderedTabs = reverse(tabs);
    return (
      <Container m={m}>
        <Nav noTabSelected={this.state.selectedTabIndex === null}>
          <NavTabsList>
            {tabs.map((tab, index) => (
              <NavTabsListItem
                key={tab.keyText}
                onClick={() => this.handleTabClick(index)}
                selected={index === this.state.selectedTabIndex}
                selectedStyle={navItemLabelSelectedStyle}
                noTabSelected={this.state.selectedTabIndex === null}
              >
                <NavTabsListItemText
                  selected={index === this.state.selectedTabIndex}
                >
                  {index === 0
                    ? t('graphDetails.legend.legend')
                    : t('graphDetails.statistics.statistics')}
                  <ArrowUpDown selected={index === this.state.selectedTabIndex}>
                    <ArrowIcon
                      height={6}
                      fillColor={
                        index === this.state.selectedTabIndex
                          ? colors.blue
                          : colors.charcoal
                      }
                    />
                  </ArrowUpDown>
                </NavTabsListItemText>
              </NavTabsListItem>
            ))}
          </NavTabsList>
        </Nav>
        <RenderIf validate={this.state.selectedTabIndextabs !== null}>
          <ContentContainer height={height}>
            {this.state.selectedTabIndex !== null
              ? orderedTabs[this.state.selectedTabIndex].content
              : null}
          </ContentContainer>
        </RenderIf>
      </Container>
    );
  }

  handleTabClick = (index) =>
    this.setState({
      selectedTabIndex: index === this.state.selectedTabIndex ? null : index,
    });
}

export const VerticalTabs = withTranslation(VerticalTabsComponent);
