import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { isEmpty, map, pick, pipe, uniq } from 'ramda';
import { withRouter } from 'react-router-dom';

import { createModal, destroyModal, updateModal } from 'src/core/modal';
import { withTranslation } from 'src/utils/i18n/with-translation';

import { PatientInfoProps } from './patient-info.types';
import { PatientInfoComponent } from './patient-info.component';
import {
  selectPatient,
  selectPatientDevices,
  selectPatientId,
} from 'src/core/patient/patient.selector';
import { getCGMSummaryRequest } from 'src/domains/patient-dashboards/cgm/store/cgm.actions';
import { fetchPatientRequest } from 'src/core/patient/patient.action';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { withLoader } from 'src/components';
import { selectPatientFhirId } from 'src/core';

import {
  resetPatientSessionAction,
  savePatientSessionStartAction,
  resetIsRunningAction,
} from 'src/core/patient-session/patient-session.actions';
import {
  selectPatientSessionTime,
  selectPatientSessionEndStatus,
  selectIsPatientSessionRunning,
  selectPatientVisitId,
} from 'src/core/patient-session/patient-session.selectors';

import {
  selectIsRPMEnrolled,
  selectIsTimeTrackerEnrolled,
  selectPermissions,
  selectPlatformPermission,
} from 'src/core/permissions';
import { selectCGMSummary } from 'src/domains/patient-dashboards/cgm/store/selectors/cgm.selectors';
import { addAlertAction } from 'src/core/alert/alert.actions';
import { resetSessionStatusAction } from 'src/core/patient-session/patient-session.actions';

export const selectPatientInfo: any = createSelector(
  [selectPatient],
  pick([
    'firstName',
    'surName',
    'dateOfBirth',
    'diabetesTypeEC6',
    'healthCareSystemId',
    'diagnosticDate',
  ]),
);

export const selectActiveDevices: any = createSelector(
  [selectPatientDevices],
  pipe(
    map((dev) => dev.deviceTypeIcon),
    uniq,
  ),
);

export const PatientInfoContainer = compose<PatientInfoProps, {}>(
  withRouter,
  connect(
    createStructuredSelector({
      patient: selectPatientInfo,
      patientId: selectPatientId,
      fhirId: selectPatientFhirId,
      activeDevices: selectActiveDevices,
      isRPMEnrolled: selectIsRPMEnrolled,
      isTimeTrackerEnrolled: selectIsTimeTrackerEnrolled,
      patientSessionTime: selectPatientSessionTime,
      isPatientSessionRunning: selectIsPatientSessionRunning,
      sessionEndStatus: selectPatientSessionEndStatus,
      CGMSummary: selectCGMSummary,
      visitId: selectPatientVisitId,
      isPlatformUser: selectPlatformPermission,
      permissions: selectPermissions,
    }),
    mapDispatchers({
      fetchPatient: fetchPatientRequest.start,
      getCGMSummary: getCGMSummaryRequest.start,
      resetPatientSession: resetPatientSessionAction,
      endSessionStart: savePatientSessionStartAction,
      resetIsRunning: resetIsRunningAction,
      resetSessionEndStatus: resetSessionStatusAction,
      addAlert: addAlertAction,
      createModal,
      destroyModal,
      updateModal,
    }),
  ),
  lifecycle<PatientInfoProps, {}>({
    componentDidMount() {
      const { fetchPatient, getCGMSummary, patient, fhirId } = this.props;
      const patientId = this.props.match.params.id;
      if (isEmpty(patient)) {
        fetchPatient({ patientId });
      }
      if (fhirId) {
        getCGMSummary({ fhirId });
      }
    },
    componentWillReceiveProps(nextProps) {
      const {
        fhirId,
        getCGMSummary,
        patientId: patientIdStore,
        match: {
          params: { id: patientIdRouting },
        },
      } = this.props;
      const { fhirId: nextFhirId } = nextProps;
      if (patientIdStore && patientIdStore !== +patientIdRouting) {
        location.reload();
      }
      if (nextFhirId !== fhirId && nextFhirId) {
        getCGMSummary({ fhirId: nextFhirId });
      }
    },
  }),
  withTranslation,
  withLoader({
    validators: {
      patient: (patient) => !isEmpty(patient),
    },
    loaderProps: {
      size: 20,
      infinite: true,
    },
  }),
)(PatientInfoComponent);
