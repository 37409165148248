import { connect } from 'react-redux';
import { selectManifestModuleName } from 'src/core/manifest-modules/manifestModules.selectors';
import { compose } from 'recompose';

import {
  selectPatientId,
  selectPatientFhirId,
} from 'src/core/patient/patient.selector';
import { dateRangeConnector } from 'src/core';

import { MODULE_NAME } from './reports.constants';
import { ReportsComponent } from './reports.component';
import { selectJelloPermission } from 'src/core/permissions';

export const ReportsContainer = compose(
  connect((state) => ({
    patientId: selectPatientFhirId(state),
    legacyPatientId: selectPatientId(state),
    startDate: dateRangeConnector(state).startDate,
    endDate: dateRangeConnector(state).endDate,
    moduleName: selectManifestModuleName(MODULE_NAME)(state),
    jelloDs: selectJelloPermission(state),
  })),
)(ReportsComponent);
