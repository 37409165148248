// tslint:disable
import {
  PatientSessionStartLoaderImpl,
  PatientSessionStartTransformImpl,
  PatientSessionStartServiceImpl,
} from './patient-session-start.service';
import {
  mockLoaderSuccess,
  // mockLoaderError,
} from './patient-session-start.mock';

export const PatientSessionStartFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoaderSuccess : PatientSessionStartLoaderImpl;
  return PatientSessionStartServiceImpl(
    loader,
    PatientSessionStartTransformImpl,
  );
};
