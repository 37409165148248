import * as React from 'react';

import { convertPxToRem } from 'src/utils';

export class IFrame extends React.Component<any, any> {
  public static defaultProps = {
    frameBorder: 0,
    onLoad: () => undefined,
    resizeOnLoad: true,
    styleProps: {},
    width: '100%',
  };

  private iframe = {};

  public shouldComponentUpdate() {
    return false;
  }

  public render() {
    const {
      title,
      width,
      height,
      src,
      frameBorder,
      resizeOnLoad,
      onLoad,
      styleProps,
      ...props
    } = this.props;

    return (
      <iframe
        ref={this.setIframeRef}
        style={{ width, height, ...styleProps }}
        onLoad={(a) => {
          if (resizeOnLoad) {
            this.resizeFrame(this.iframe);
          }
          onLoad(this.iframe);
        }}
        title={title}
        src={src}
        frameBorder={frameBorder}
        {...props}
      />
    );
  }

  private resizeFrame = (iframe) => {
    iframe.height = convertPxToRem(
      iframe.contentWindow.document.body.scrollHeight,
    );
  };

  private setIframeRef = (element) => {
    this.iframe = element;
  };
}
