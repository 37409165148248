import * as React from 'react';
import * as H from 'history';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import { TFunction } from 'i18next';
import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { testId } from '@roche/roche-common';
import { selectPatientId } from 'src/core';
import {
  selectPatternsPermission,
  selectTherapyPermission,
} from 'src/core/permissions';

import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { WithPermissions } from 'src/utils/with-permissions/with-permissions.container';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';
import { Button, ButtonSizes } from 'src/components/button';

import { PatientDashboardSelectorContainer } from './patient-dashboards-selector.style';
import { mapDispatchers } from 'src/utils';
import { clearPatientDateRange } from 'src/core/patient-date-range/patient-date-range.action';
import { clearCgmDateRange } from 'src/domains/patient-dashboards/cgm/store/cgm.actions';

type PatientDashboardsSelectorProps = {
  routes: FixMe;
  location: H.Location<{}>;
  patientId: any;
  isPatientDashboardActive: boolean;
  hasPatternsPermission: boolean;
  hasTherapyPermission: boolean;
  t: TFunction;
  clearBgDateRanges: () => void;
  clearCgmDateRanges: () => void;
};

export class PatientDashboardsSelectorComponent extends React.Component<PatientDashboardsSelectorProps> {
  public render() {
    const { routes, t, hasPatternsPermission, hasTherapyPermission } =
      this.props;
    const isBGActive = !!matchPath(this.props.location.pathname, {
      path: routes.dashboard.bgId,
    });
    const isCGMActive = !!matchPath(this.props.location.pathname, {
      path: routes.dashboard.cgmId,
    });
    const isPatternActive = !!matchPath(this.props.location.pathname, {
      path: routes.indicators.main,
    });

    const isTherapyActive = !!matchPath(this.props.location.pathname, {
      path: routes.therapy.main,
    });

    const { dashboard, indicators, therapy } = routes;
    const { patientId } = this.props;

    return (
      <PatientDashboardSelectorContainer>
        <NavLink
          onClick={this.handleClick}
          to={dashboard.bgId.replace(':id', patientId)}
        >
          <Button
            mr={12}
            label={t('topBar.dashboards.bg')}
            buttonStyle={'square'}
            fontSize={'10px'}
            buttonSize={ButtonSizes.AUTO}
            selected={isBGActive}
            inactive={!isBGActive}
            uppercase={true}
            minWidth={52}
            height={32}
            onClick={() => this.cleanIt(isBGActive)}
            {...testId('main-button', 'bgm')}
          />
        </NavLink>
        <NavLink
          onClick={this.handleClick}
          to={dashboard.cgmId.replace(':id', this.props.patientId)}
        >
          <Button
            mr={hasPatternsPermission || hasTherapyPermission ? 12 : 0}
            label={t('topBar.dashboards.cgm')}
            buttonStyle={'square'}
            fontSize={'10px'}
            buttonSize={ButtonSizes.AUTO}
            selected={isCGMActive}
            inactive={!isCGMActive}
            uppercase={true}
            minWidth={52}
            height={32}
            onClick={() => this.cleanIt(isCGMActive)}
            {...testId('main-button', 'cgm')}
          />
        </NavLink>
        <WithPermissions hasPermissions={[PERMISSIONS.ADVANCED_INDICATORS]}>
          <NavLink
            onClick={this.handleClick}
            to={indicators.main.replace(':id', this.props.patientId)}
          >
            <Button
              mr={hasTherapyPermission ? 12 : 0}
              label={t('topBar.dashboards.patterns')}
              buttonStyle={'square'}
              fontSize={'10px'}
              buttonSize={ButtonSizes.AUTO}
              selected={isPatternActive}
              inactive={!isPatternActive}
              uppercase={true}
              minWidth={52}
              height={32}
              onClick={() => this.cleanIt(isPatternActive)}
              {...testId('main-button', 'patterns')}
            />
          </NavLink>
        </WithPermissions>
        <WithPermissions hasPermissions={[PERMISSIONS.THERAPY]}>
          <NavLink
            onClick={this.handleClick}
            to={therapy.main.replace(':id', this.props.patientId)}
          >
            <Button
              label={t('topBar.dashboards.therapy')}
              buttonStyle={'square'}
              fontSize={'10px'}
              buttonSize={ButtonSizes.AUTO}
              selected={isTherapyActive}
              inactive={!isTherapyActive}
              uppercase={true}
              minWidth={52}
              height={32}
              onClick={() => this.cleanIt(isTherapyActive)}
              {...testId('main-button', 'therapy')}
            />
          </NavLink>
        </WithPermissions>
      </PatientDashboardSelectorContainer>
    );
  }

  private cleanIt = (active) => {
    const { clearBgDateRanges, clearCgmDateRanges } = this.props;
    if (!active) {
      clearBgDateRanges();
      clearCgmDateRanges();
    }
  };

  private handleClick = (e) => {
    if (!this.props.patientId) e.preventDefault();
  };
}

export const PatientDashboardsSelector = compose(
  withRouter,
  connect(
    createStructuredSelector({
      routes: getCombinedRoutes,
      patientId: selectPatientId,
      hasPatternsPermission: selectPatternsPermission,
      hasTherapyPermission: selectTherapyPermission,
    }),
    mapDispatchers({
      clearBgDateRanges: clearPatientDateRange,
      clearCgmDateRanges: clearCgmDateRange,
    }),
  ),
  withTranslation,
)(PatientDashboardsSelectorComponent);
