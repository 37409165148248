import React from 'react';
import { Bundle } from 'src/navigation/bundle';

export const RpmSettingsBundle = (props) => (
  <Bundle
    bundleWillLoad={async () => {
      const { RpmSettings } = await import(
        /* webpackChunkName: "rpmSettings" */ './scenes/rpm-settings'
      );
      return RpmSettings;
    }}
    bundleDidLoad={(RpmSettings) => <RpmSettings {...props} />}
  />
);
