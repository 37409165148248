import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { INSIdentityConnector } from 'src/widgets/patient/INS-patient/store/INS-identity.selectors';
import { JELLO_DIALOG_EVENTS } from 'src/components';
import { clearINSIdentity } from 'src/widgets/patient/INS-patient/store/INS-Identity.actions';

import { INS_MODAL_ID } from './ins-request.modal.constants';
import { INSModalComponent } from './ins-request.modal.component';
import { withTranslation } from 'src/utils/i18n/with-translation';

export const INSFetchingModal = compose(
  connect(INSIdentityConnector),
  lifecycle<any, {}>({
    componentDidMount() {
      const modal = document.getElementById(INS_MODAL_ID);
      if (modal) {
        const { destroyModal, dispatch } = this.props;
        modal.addEventListener(JELLO_DIALOG_EVENTS.primaryBtn, () => {
          dispatch(clearINSIdentity());
          return destroyModal();
        });
      }
    },
  }),
  withTranslation,
)(INSModalComponent);
