import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { SvgIcon } from 'src/components/icon';

const BeforeExerciseIconComponent = ({ height, width, t }) => {
  const originalWidth = 20;
  const originalHeight = 8;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.beforeExercise')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons/Graph/Meal/">
          <g id="Group-15-Copy">
            <path
              d="M6.45139753,3.51918 L6.45139753,2.40786 L6.45028621,2.40786 L6.45028621,1.29654 C6.45028621,0.58788828 5.86239793,0 5.15374621,0 L4.78330621,0 C4.07465449,0 3.48676621,0.58788828 3.48676621,1.29654 L3.48306181,1.29654 L3.48306181,2.40786 L3.48676621,2.40786 L3.48676621,5.37138 L3.48306181,5.37138 L3.48306181,6.4827 L3.48676621,6.4827 C3.48676621,7.19135172 4.07465449,7.77924 4.78330621,7.77924 L5.15374621,7.77924 C5.86239793,7.77924 6.45028621,7.19135172 6.45028621,6.4827 L6.45028621,5.37138 L6.45139753,5.37138 L6.45139753,4.26006 L6.45028621,4.26006 L6.45028621,3.51918 L6.45139753,3.51918 Z"
              id="Fill-5"
              fill="#F5A623"
            />
            <polygon
              id="Fill-7"
              fill="#BA7E1B"
              points="6.45139753 2.40786 6.45139753 3.51918 6.45139753 4.26006 6.45139753 5.37138 13.4879053 5.37138 13.4879053 4.26006 13.4879053 3.51918 13.4879053 2.40786"
            />
            <path
              d="M17.8963408,1.29654 L17.3421626,1.29654 L17.3421626,2.40786 L17.3421626,5.37138 L17.3421626,6.4827 L17.8963408,6.4827 C18.6049926,6.4827 19.1928808,5.89481172 19.1928808,5.18616 L19.1928808,2.59308 C19.1928808,1.88442828 18.6049926,1.29654 17.8963408,1.29654"
              id="Fill-9"
              fill="#F5A623"
            />
            <path
              d="M16.4536295,2.40786 L16.4536295,1.29654 L16.4521477,1.29654 C16.4521477,0.58788828 15.8642594,0 15.1556077,0 L14.7851677,0 C14.076516,0 13.4886277,0.58788828 13.4886277,1.29654 L13.4886277,2.40786 L13.4878868,2.40786 L13.4878868,3.51918 L13.4886277,3.51918 L13.4886277,4.26006 L13.4878868,4.26006 L13.4878868,5.37138 L13.4886277,5.37138 L13.4886277,6.4827 C13.4886277,7.19135172 14.076516,7.77924 14.7851677,7.77924 L15.1556077,7.77924 C15.8642594,7.77924 16.4521477,7.19135172 16.4521477,6.4827 L16.4536295,6.4827 L16.4536295,5.37138 L16.4521477,5.37138 L16.4521477,2.40786 L16.4536295,2.40786 Z"
              id="Fill-11"
              fill="#F5A623"
            />
            <path
              d="M2.02111824,1.29654 C1.31246652,1.29654 0.724578241,1.88442828 0.724578241,2.59308 L0.724578241,5.18616 C0.724578241,5.89481172 1.31246652,6.4827 2.02111824,6.4827 L2.57307384,6.4827 L2.57307384,5.37138 L2.57307384,2.40786 L2.57307384,1.29654 L2.02111824,1.29654 Z"
              id="Fill-13"
              fill="#F5A623"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const BeforeExerciseIcon = withTranslation(BeforeExerciseIconComponent);

const DuringExerciseIconComponent = ({ height, width, t }) => {
  const originalWidth = 20;
  const originalHeight = 8;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.DuringExercise')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons/Graph/Meal/">
          <g id="Group-15">
            <path
              d="M6.45139753,3.51918 L6.45139753,2.40786 L6.45028621,2.40786 L6.45028621,1.29654 C6.45028621,0.58788828 5.86239793,0 5.15374621,0 L4.78330621,0 C4.07465449,0 3.48676621,0.58788828 3.48676621,1.29654 L3.48306181,1.29654 L3.48306181,2.40786 L3.48676621,2.40786 L3.48676621,5.37138 L3.48306181,5.37138 L3.48306181,6.4827 L3.48676621,6.4827 C3.48676621,7.19135172 4.07465449,7.77924 4.78330621,7.77924 L5.15374621,7.77924 C5.86239793,7.77924 6.45028621,7.19135172 6.45028621,6.4827 L6.45028621,5.37138 L6.45139753,5.37138 L6.45139753,4.26006 L6.45028621,4.26006 L6.45028621,3.51918 L6.45139753,3.51918 Z"
              id="Fill-5"
              fill="#7ED321"
            />
            <polygon
              id="Fill-7"
              fill="#559014"
              points="6.45139753 2.40786 6.45139753 3.51918 6.45139753 4.26006 6.45139753 5.37138 13.4879053 5.37138 13.4879053 4.26006 13.4879053 3.51918 13.4879053 2.40786"
            />
            <path
              d="M17.8963408,1.29654 L17.3421626,1.29654 L17.3421626,2.40786 L17.3421626,5.37138 L17.3421626,6.4827 L17.8963408,6.4827 C18.6049926,6.4827 19.1928808,5.89481172 19.1928808,5.18616 L19.1928808,2.59308 C19.1928808,1.88442828 18.6049926,1.29654 17.8963408,1.29654"
              id="Fill-9"
              fill="#7ED321"
            />
            <path
              d="M16.4536295,2.40786 L16.4536295,1.29654 L16.4521477,1.29654 C16.4521477,0.58788828 15.8642594,0 15.1556077,0 L14.7851677,0 C14.076516,0 13.4886277,0.58788828 13.4886277,1.29654 L13.4886277,2.40786 L13.4878868,2.40786 L13.4878868,3.51918 L13.4886277,3.51918 L13.4886277,4.26006 L13.4878868,4.26006 L13.4878868,5.37138 L13.4886277,5.37138 L13.4886277,6.4827 C13.4886277,7.19135172 14.076516,7.77924 14.7851677,7.77924 L15.1556077,7.77924 C15.8642594,7.77924 16.4521477,7.19135172 16.4521477,6.4827 L16.4536295,6.4827 L16.4536295,5.37138 L16.4521477,5.37138 L16.4521477,2.40786 L16.4536295,2.40786 Z"
              id="Fill-11"
              fill="#7ED321"
            />
            <path
              d="M2.02111824,1.29654 C1.31246652,1.29654 0.724578241,1.88442828 0.724578241,2.59308 L0.724578241,5.18616 C0.724578241,5.89481172 1.31246652,6.4827 2.02111824,6.4827 L2.57307384,6.4827 L2.57307384,5.37138 L2.57307384,2.40786 L2.57307384,1.29654 L2.02111824,1.29654 Z"
              id="Fill-13"
              fill="#7ED321"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const DuringExerciseIcon = withTranslation(DuringExerciseIconComponent);

const AfterExerciseIconComponent = ({ height, width, t }) => {
  const originalWidth = 20;
  const originalHeight = 8;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.AfterExercise')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons/Graph/Meal/">
          <g id="Group-15-Copy-2">
            <path
              d="M6.45139753,3.51918 L6.45139753,2.40786 L6.45028621,2.40786 L6.45028621,1.29654 C6.45028621,0.58788828 5.86239793,0 5.15374621,0 L4.78330621,0 C4.07465449,0 3.48676621,0.58788828 3.48676621,1.29654 L3.48306181,1.29654 L3.48306181,2.40786 L3.48676621,2.40786 L3.48676621,5.37138 L3.48306181,5.37138 L3.48306181,6.4827 L3.48676621,6.4827 C3.48676621,7.19135172 4.07465449,7.77924 4.78330621,7.77924 L5.15374621,7.77924 C5.86239793,7.77924 6.45028621,7.19135172 6.45028621,6.4827 L6.45028621,5.37138 L6.45139753,5.37138 L6.45139753,4.26006 L6.45028621,4.26006 L6.45028621,3.51918 L6.45139753,3.51918 Z"
              id="Fill-5"
              fill="#CF021B"
            />
            <polygon
              id="Fill-7"
              fill="#730310"
              points="6.45139753 2.40786 6.45139753 3.51918 6.45139753 4.26006 6.45139753 5.37138 13.4879053 5.37138 13.4879053 4.26006 13.4879053 3.51918 13.4879053 2.40786"
            />
            <path
              d="M17.8963408,1.29654 L17.3421626,1.29654 L17.3421626,2.40786 L17.3421626,5.37138 L17.3421626,6.4827 L17.8963408,6.4827 C18.6049926,6.4827 19.1928808,5.89481172 19.1928808,5.18616 L19.1928808,2.59308 C19.1928808,1.88442828 18.6049926,1.29654 17.8963408,1.29654"
              id="Fill-9"
              fill="#CF021B"
            />
            <path
              d="M16.4536295,2.40786 L16.4536295,1.29654 L16.4521477,1.29654 C16.4521477,0.58788828 15.8642594,0 15.1556077,0 L14.7851677,0 C14.076516,0 13.4886277,0.58788828 13.4886277,1.29654 L13.4886277,2.40786 L13.4878868,2.40786 L13.4878868,3.51918 L13.4886277,3.51918 L13.4886277,4.26006 L13.4878868,4.26006 L13.4878868,5.37138 L13.4886277,5.37138 L13.4886277,6.4827 C13.4886277,7.19135172 14.076516,7.77924 14.7851677,7.77924 L15.1556077,7.77924 C15.8642594,7.77924 16.4521477,7.19135172 16.4521477,6.4827 L16.4536295,6.4827 L16.4536295,5.37138 L16.4521477,5.37138 L16.4521477,2.40786 L16.4536295,2.40786 Z"
              id="Fill-11"
              fill="#CF021B"
            />
            <path
              d="M2.02111824,1.29654 C1.31246652,1.29654 0.724578241,1.88442828 0.724578241,2.59308 L0.724578241,5.18616 C0.724578241,5.89481172 1.31246652,6.4827 2.02111824,6.4827 L2.57307384,6.4827 L2.57307384,5.37138 L2.57307384,2.40786 L2.57307384,1.29654 L2.02111824,1.29654 Z"
              id="Fill-13"
              fill="#CF021B"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const AfterExerciseIcon = withTranslation(AfterExerciseIconComponent);
