import { createStructuredSelector } from 'reselect';
import {
  selectIsOidcSessionValid,
  selectIsSignedOut,
} from 'src/app/session';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';

export const protectedRouteConnector = createStructuredSelector({
  isAuthenticated: selectIsOidcSessionValid,
  routes: getCombinedRoutes,
  hasLoggedOut: selectIsSignedOut,
});
