import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import {
  SaveUserUnitsLoaderImplType,
  SaveUserUnitsServiceImplType,
} from './save-user-units.types';

import { ENDPOINTS } from 'src/services/service.constants';

export const saveUserUnitLoaderImpl: SaveUserUnitsLoaderImplType = (
  openId: {
    accessToken: string;
    gigyaToken: string;
  },
  measurementUnit: string,
) =>
  postJSON(ENDPOINTS.saveUserUnits, {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
    body: stringifyBody({ bgUnitMeasurement: measurementUnit }),
  });

export const saveUserUnitTransformImpl = (result) => result;

export const saveUserUnitServiceImpl: SaveUserUnitsServiceImplType =
  (load: SaveUserUnitsLoaderImplType, transform) => (openId, measurementUnit) =>
    load(openId, measurementUnit).then(transform);
