import React, { useEffect, useRef } from 'react';
import { JELLO_OPTION_PICKER_EVENTS } from './option-picker-jello.constants';
import { testId } from '@roche/roche-common';

type OptionPickerProps = {
  id?: string;
  backgroundColor?: string;
  onChange: (e: any) => any;
  onClick: () => void;
  isOpen: boolean;
  options: any[];
  value?: string;
  accessibilityText?: string;
  label?: string;
  selectedIndex?: string;
  disabled?: boolean;
};

export const JelloOptionPicker: React.FunctionComponent<OptionPickerProps> = (
  props,
) => {
  const {
    id,
    backgroundColor,
    label,
    onChange,
    onClick,
    isOpen,
    options,
    value,
    selectedIndex,
    accessibilityText,
    disabled,
  } = props;
  const optionPickerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const optionPickerCustomEvent = optionPickerRef.current;
    optionPickerCustomEvent!.addEventListener(
      JELLO_OPTION_PICKER_EVENTS.onChange,
      onChange,
    );

    return () => {
      optionPickerCustomEvent!.removeEventListener(
        JELLO_OPTION_PICKER_EVENTS.onChange,
        onChange,
      );
    };
  }, []);

  return (
    <>
      <jello-option-picker
        id={id}
        background-color={backgroundColor}
        options={JSON.stringify(options)}
        label={label}
        value={value}
        selected-index={selectedIndex}
        accessibility-text={accessibilityText}
        ref={optionPickerRef}
        isOpen={isOpen}
        onClick={onClick}
        {...(disabled ? { disabled: '' } : null)}
        {...testId('jello-option-picker', value || 'option-14')}
      />
    </>
  );
};
