import { Div } from 'src/components/div';
import styled from 'styled-components';
import { convertPxToRem } from 'src/utils';
import { FixedTableBody } from 'src/components';
import { ControlError } from 'src/components/forms/control-error/control-error.component';
import { FormLabel } from 'src/components/forms';
import { TableCell } from 'src/components';
import { createStyledComponent } from 'src/utils/styles';
import { colors } from 'src/core';
import { InputText } from 'src/widgets/forms/components';
import { TARGET_RANGES_BREAK_POINT } from '../../target-ranges.styles';

type RangeSectionProps = {
  withLabel?: boolean;
};

export const RangeSection = createStyledComponent<'div', RangeSectionProps>(
  'div',
)`
  float: left;
  min-width: ${(props) => (props.withLabel ? '255px' : '180px')};
  &.beforeEating,
  &.afterEating,
  &.beforeBed {
    margin-right: 1rem;
    table {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: ${convertPxToRem(0)};
        right: 1rem;
        left: 0;
        border-bottom: ${({ theme }) => '1px solid ' + theme.colors.grayLight};
      }
    }
  }
`;
RangeSection.displayName = 'RangeSection';

export const RangeTitle = styled.p`
  font-size: ${convertPxToRem(16)};
  text-transform: uppercase;
  height: 25px;
  margin-top: ${convertPxToRem(26)};
  margin-bottom: 4px;
`;
RangeTitle.displayName = 'RangeTitle';

export const RangeCGMTitle = styled(RangeTitle)`
  @media all and (max-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
    display: none;
  }
`;
RangeCGMTitle.displayName = 'RangeCGMTitle';

export const FormContainer = styled(Div)`
  display: inline-block;
  width: 100%;
`;
FormContainer.displayName = 'FormContainer';

export const Spacer = styled(Div)`
  background-color: red;
  display: inline-block;
  height: 25px;
  width: 100%;
`;
Spacer.displayName = 'Spacer';

export const RangeTableBody = styled(FixedTableBody)`
  overflow: visible;
  margin-top: 1.5rem;
`;
RangeTableBody.displayName = 'RangeTableBody';

type RangeErrorProps = {};

export const RangeError = createStyledComponent<FixMe, RangeErrorProps>(
  ControlError,
)`
  background-color: ${(props) => props.theme.colors.trafficRed};
  color: ${(props) => props.theme.colors.white};
  margin-top: ${convertPxToRem(5)};
  position: absolute;
  z-index: 2;
  padding: ${convertPxToRem(5)};
  left: ${convertPxToRem(-20)};
  width: max-content;
  max-width: ${convertPxToRem(160)};
  @media all and (min-width: ${convertPxToRem(1500)}) {
    max-width: ${convertPxToRem(240)};
  }
  font-size: ${convertPxToRem(11)};
  &:before {
    position: absolute;
    top: ${convertPxToRem(-5)};
    left: ${convertPxToRem(45)};
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: ${({ theme }) => '5px solid ' + theme.colors.trafficRed};
    font-size: 0;
    line-height: 0;
  }
  span {
    display: block;
  }
`;
RangeError.displayName = 'RangeError';

export const SpanColor = styled.span`
  width: ${convertPxToRem(20)};
  height: ${convertPxToRem(70)};
  display: inline-block;
`;

export const SpanVeryHigh = styled(SpanColor)`
  background-color: ${(props) => props.theme.colors.blueMarine};
  border-top-left-radius: ${convertPxToRem(50)};
  border-top-right-radius: ${convertPxToRem(50)};
  &.veryHigh-nonMealRelated {
    background-color: ${colors.actualHyper};
  }
`;
SpanVeryHigh.displayName = 'SpanVeryHigh';

export const SpanHigh = styled(SpanColor)`
  background-color: ${(props) => props.theme.colors.blueMarine};
  &.high-nonMealRelated {
    background-color: ${colors.hyper};
  }
`;
SpanHigh.displayName = 'SpanHigh';

export const SpanInTarget = styled(SpanColor)`
  background-color: ${(props) => props.theme.colors.trafficGreen};
  height: ${convertPxToRem(70)};
  &.inTarget-nonMealRelated {
    background-color: ${colors.inTarget};
  }
`;
SpanInTarget.displayName = 'SpanInTarget';

export const SpanLow = styled(SpanColor)`
  background-color: ${(props) => props.theme.colors.trafficOrange};
  &.low-nonMealRelated {
    background-color: ${colors.warning};
  }
`;
SpanLow.displayName = 'SpanLow';

export const SpanVeryLow = styled(SpanColor)`
  background-color: ${(props) => props.theme.colors.trafficRed};
  border-bottom-left-radius: ${convertPxToRem(50)};
  border-bottom-right-radius: ${convertPxToRem(50)};
  &.veryLow-nonMealRelated {
    background-color: ${colors.hypo};
  }
`;
SpanVeryLow.displayName = 'SpanVeryLow';

export const RangeLabel = styled(FormLabel)`
  min-height: inherit;
  font-size: ${convertPxToRem(13)};
  margin-top: ${convertPxToRem(5)};
  margin-bottom: ${convertPxToRem(5)};
`;
RangeLabel.displayName = 'RangeLabel';

export const RangeTargetLabel = styled(RangeLabel)`
  margin-left: ${convertPxToRem(25)};
`;
RangeTargetLabel.displayName = 'RangeTargetLabel';

export const InputCell = styled(TableCell)`
  position: relative;
  min-width: ${convertPxToRem(150)};
`;
InputCell.displayName = 'InputCell';

export const LabelCell = styled(TableCell)`
  span {
    display: inline-block;
    text-transform: uppercase;
    font-size: ${convertPxToRem(10)};
    text-align: left;
    border-bottom: ${({ theme }) => '1px solid ' + theme.colors.grayLight};
    padding-right: ${convertPxToRem(25)};
    min-width: 100%;
    max-width: 82px;
    @media all and (min-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
      max-width: inherit;
    }
  }
`;
LabelCell.displayName = 'LabelCell';

export const Unit = styled.span`
  position: absolute;
  bottom: ${convertPxToRem(16)};
  left: ${convertPxToRem(67)};
  font-size: ${convertPxToRem(12)};
  color: ${(props) => props.theme.colors.grayDark};
`;
Unit.displayName = 'Unit';

export const InputContainer = styled.div`
  position: absolute;
  left: ${convertPxToRem(25)};
  top: ${convertPxToRem(53)};
  right: 0;
  &:before {
    content: ' ';
    width: ${convertPxToRem(25)};
    height: ${convertPxToRem(1)};
    background-color: ${(props) => props.theme.colors.grayLight};
    position: absolute;
    top: ${convertPxToRem(20)};
    left: ${convertPxToRem(-25)};
  }
  input[type='text'] {
    padding: ${convertPxToRem(12)}!important;
    max-width: ${convertPxToRem(62)};
  }
`;
InputContainer.displayName = 'InputContainer';

export const InputContainerDown = styled(InputContainer)`
  top: ${convertPxToRem(-23)};
`;
InputContainerDown.displayName = 'InputContainerDown';

export const InputTextRanges = styled(InputText)`
  border-color: ${(props) => props.theme.colors.borderInputTimeRange};
`;
InputTextRanges.displayName = 'InputTextRanges';
