import styled from 'styled-components';

import { convertPxToRem } from 'src/utils';

import { Div } from '../../../../components';
import { TARGET_RANGES_BREAK_POINT } from '../../../../widgets/target-ranges/target-ranges.styles';

type DefaultSettingsLeftItemProps = {
  active: boolean;
};

export const DefaultSettingsLeftItem = styled.div<DefaultSettingsLeftItemProps>`
  position: relative;
  padding: 12px 10px 10px 16px;
  margin: 8px;
  width: calc(100% - 16px);
  background-color: ${(props) =>
    props.active ? props.theme.colors.colorBrand05 : 'none'};
  &:hover {
    text-decoration: none;
    background-color: ${(props) =>
      props.active ? 'none' : props.theme.colors.backGroundBlueLight};
  }
  &:before {
    position: absolute;
    content: ' ';
    display: ${(props) => (props.active ? 'block' : 'none')};
    width: 4px;
    border-radius: 0px 4px 4px 0px;
    background-color: ${(props) => props.theme.colors.brandBlue};
    height: 100%;
    left: -8px;
    top: 0;
  }
`;

export const DefaultSettingsLeftItemLink = styled.a<DefaultSettingsLeftItemProps>`
  width: 100%;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${(props) => (props.active ? 600 : 400)};
  color: ${(props) => props.theme.colors.brandBlue};
`;

export const DefaultSettingsLeftBlock = styled.div`
  min-width: 23%;
`;

export const DefaultSettingsLeftMenu = styled.div``;

export const DefaultSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

export const DefaultSettingsLeftWrapper = styled.div`
  width: 20%;
  @media (max-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
    width: 28%;
  }
`;

export const DefaultSettingsRightWrapper = styled.div`
  width: 80%;
  @media (max-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
    width: 72%;
  }
`;

export const DefaultSettingsLeftHeader = styled.div`
  padding: 24px 8px 28px 16px;
  color: ${(props) => props.theme.colors.black};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 22px;
`;

export const SettingsButtonsWrapper = styled(Div)`
  margin-left: 2rem;
`;
SettingsButtonsWrapper.displayName = 'SettingsButtonsWrapper';
