import { createGlobalStyle } from 'styled-components';

// @ts-ignore
import nunitoRegularFallback from 'src/assets/fonts/Nunito-Regular.woff';
// @ts-ignore
import nunitoRegular from 'src/assets/fonts/Nunito-Regular.woff2';
// @ts-ignore
import nunitoSemiboldFallback from 'src/assets/fonts/Nunito-SemiBold.woff';
// @ts-ignore
import nunitoSemibold from 'src/assets/fonts/Nunito-SemiBold.woff2';
// @ts-ignore
import nunitoBoldFallback from 'src/assets/fonts/Nunito-Bold.woff';
// @ts-ignore
import nunitoBold from 'src/assets/fonts/Nunito-Bold.woff2';
// @ts-ignore
import imagoBold from 'src/assets/fonts/imago-bold.woff2';
// @ts-ignore
import imagoBoldFallback from 'src/assets/fonts/imago-bold.woff';
// @ts-ignore
import imagoRegular from 'src/assets/fonts/imago-regular.woff2';
// @ts-ignore
import imagoRegularFallback from 'src/assets/fonts/imago-regular.woff';

import { APP_MIN_WIDTH, BASE_FONT_SIZE, colors } from '../core/styles';

// tslint:disable:no-unused-expression
export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .jello-dialog {
    z-index: 310;
  }

  .DateRangePicker_picker {
    z-index: 2 ;
  }

  html {
    font-size: ${BASE_FONT_SIZE}px;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${colors.silverLight};
    min-width: ${APP_MIN_WIDTH};
  }

  body,
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: 'Nunito', -apple-system,BlinkMacSystemFont, 'Segoe UI', sans-serif;
  }
  ::selection {
    background: ${colors.brandBlue};
    color: ${colors.white};
  }

  #app-container {
    min-height: 100vh;
    display: flex;
  }

  .no-user-select {
    user-select: none;
  }

  @font-face {
    font-family: 'Nunito Bold';
    src: url(${nunitoBold}) format('woff2'), url(${nunitoBoldFallback}) format('woff');
  }

  @font-face {
    font-family: 'Nunito Regular';
    src: url(${nunitoRegular}) format('woff2'), url(${nunitoRegularFallback}) format('woff');
  }

  @font-face {
    font-family: 'Nunito Medium';
    src: url(${nunitoSemibold}) format('woff2'), url(${nunitoSemiboldFallback}) format('woff');
  }

  @font-face {
    font-family: 'Imago Bold';
    src: url(${imagoBold}) format('woff2'), url(${imagoBoldFallback}) format('woff');
  }

  @font-face {
    font-family: 'Imago Regular';
    src: url(${imagoRegular}) format('woff2'), url(${imagoRegularFallback}) format('woff');
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none !important;
  }

  @media print {
    body {
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }

    section,
    img,
    svg,
    table,
    ul  {
      page-break-inside: avoid;
    }

    .print-hide {
      display: none;
    }

    .print-no-m {
      margin: 0;
    }

     @page {
        size: portrait;
    }
  }`; // tslint:enable:no-unused-expression
