import React from 'react';
import { Switch } from 'react-router-dom';
import { RouterOutlet } from 'src/navigation/components/router-outlet';

import { ProtectedRoute } from 'src/components/protected-route';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

import {
  CreateProfessionalBundle,
  DefaultGraphicSettingsBundle,
} from './bundles';

export const professionalLinks = {
  createProfessional: '/professional/create',
  defaultGraphicSettings: '/professional/default-graphic-settings',
};

export const ProfessionalRoutes = () => (
  <React.Fragment>
    <RouterOutlet
      key={professionalLinks.createProfessional}
      path={professionalLinks.createProfessional}
    >
      <Switch>
        <ProtectedRoute
          exact
          path={professionalLinks.createProfessional}
          component={CreateProfessionalBundle}
          hasPermissions={[PERMISSIONS.CREATE_PROFESSIONAL]}
        />
      </Switch>
    </RouterOutlet>
    <RouterOutlet
      key={professionalLinks.defaultGraphicSettings}
      path={professionalLinks.defaultGraphicSettings}
    >
      <Switch>
        <ProtectedRoute
          exact
          path={professionalLinks.defaultGraphicSettings}
          component={DefaultGraphicSettingsBundle}
          hasPermissions={[PERMISSIONS.RELEVANT_CLINICAL_DATA]}
        />
      </Switch>
    </RouterOutlet>
  </React.Fragment>
);
