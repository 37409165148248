import { createSelector, createStructuredSelector } from 'reselect';
import { pick, pipe, values, any, path } from 'ramda';
import { getCombinedRoutes } from 'src/navigation';

import {
  selectPatientStartDate,
  selectPatientEndDate,
  selectPatientFirstMeasurementDate,
} from 'src/core/patient-date-range';
import {
  selectPatient,
  selectPatientProfileType,
  selectPatientFhirId,
} from 'src/core/patient';
import {
  selectDashboard,
  selectGraph,
  selectGraphType,
  selectLogbookType,
} from 'src/core/diagnostics';
import { ProfileTypes } from 'src/core';
import {
  selectPatientPermissions,
  selectPermissions,
  selectFhirPermission,
  selectJelloPermission
} from 'src/core/permissions';
import { selectUserProfile } from 'src/core/user/user.selectors';

import { selectDataSharingConsentResponse } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.selector';

export const selectIsFetchingData = createSelector(
  selectDashboard,
  pipe(
    pick([
      'isFetchingPatientDateRange',
      'isFetchingClinicalData',
      'isFetchingTimeIntervals',
      'isFetchingPatient',
      'isFetchingStripDeliveryInfo',
      'isFetchingThreshold',
    ]),
    values,
    any((value) => value === true),
  ),
);

export const selectIsExternalPatient = createSelector(
  selectDashboard,
  path(['isExternalPatient']),
);

export const selectExternalPatient = createSelector(
  selectDashboard,
  path(['externalPatient']),
);
export const selectIsFetchingPatient = createSelector(
  selectDashboard,
  path(['isFetchingPatient']),
);

export const selectIsFetchingPatientDateRange = createSelector(
  selectDashboard,
  path(['isFetchingPatientDateRange']),
);

export const selectPatientHasMeasurementData = createSelector(
  selectPatientFirstMeasurementDate,
  (firstMeasurementDate) => firstMeasurementDate !== null,
);

export const selectShowNewPatientDashboard = createSelector(
  selectPatientHasMeasurementData,
  selectPatientProfileType,
  (patientHasMeasurementData, patientProfileType) =>
    !patientHasMeasurementData && patientProfileType === ProfileTypes.basic,
);

export const selectShowNormalDashboard = createSelector(
  selectPatientHasMeasurementData,
  selectPatientProfileType,
  (patientHasMeasurementData, patientProfileType) =>
    patientHasMeasurementData ||
    (!patientHasMeasurementData && patientProfileType !== ProfileTypes.basic),
);

export const patientDashboardConnector = createStructuredSelector({
  patient: selectPatient,
  graph: selectGraph,
  graphType: selectGraphType,
  logbookType: selectLogbookType,
  isFetchingData: selectIsFetchingData,
  startDate: selectPatientStartDate,
  endDate: selectPatientEndDate,
  routes: getCombinedRoutes,
  patientHasMeasurementData: selectPatientHasMeasurementData,
  isFetchingPatient: selectIsFetchingPatient,
  isFetchingPatientDateRange: selectIsFetchingPatientDateRange,
  showNewBgDashboard: selectShowNewPatientDashboard,
  showNormalDashboard: selectShowNormalDashboard,
  patientPermissions: selectPatientPermissions,
  hcpPermissions: selectPermissions,
  patientFhirId: selectPatientFhirId,
  hasUserFhirPermission: selectFhirPermission,
  dataSharingConsentResponse: selectDataSharingConsentResponse,
  isJelloActivated: selectJelloPermission
});

export const patientExternalConnector = createStructuredSelector({
  isExternalPatient: selectIsExternalPatient,
  externalPatient: selectExternalPatient,
  isJelloActivated: selectJelloPermission,
  profile: selectUserProfile,
});
