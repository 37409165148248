import * as React from 'react';
import { isEmpty } from 'ramda';
import { LocalizedText } from 'src/components';
import { PatientSearchFormFull } from './patient-search.form.full.component';
import { PatientSearchFormSlim } from './patient-search.form.slim.component';

import { SearchResults, PatientRow } from '../components';
import { RenderIf } from 'src/utils/render-if';
import { testId } from '@roche/roche-common';

type searchType = {
  fullName?: any;
  patientID?: any;
};

type SearchProps = {
  didSearch: boolean;
  results: any;
  t: () => void;
  onCreatePatient: () => void;
  onSearch: (search: searchType) => void;
  hasCreatePatient: boolean;
  isHcpOrGp: boolean;
  fhirId: boolean;
  onClick: any;
};

const drawResults = (props) => {
  const { results, onHandle, t, isHcpOrGp, fhirId } = props;
  return (
    <React.Fragment>
      <RenderIf validate={isEmpty(results)}>
        <LocalizedText textKey="patientSearch.noResults" />
      </RenderIf>
      <RenderIf validate={!isEmpty(results)}>
        <SearchResults>
          {results.map((result) => (
            <PatientRow
              isHcpOrGp={isHcpOrGp}
              fhirId={fhirId}
              key={`patient-search-result--${result.id}`}
              onHandle={onHandle}
              t={t}
              {...result}
            />
          ))}
        </SearchResults>
      </RenderIf>
    </React.Fragment>
  );
};

export const PatientSearchWidget: React.FunctionComponent<SearchProps> = (
  props,
) => {
  const { didSearch } = props;
  return (
    <div {...testId('basic-home-screen', 'search')}>
      <RenderIf validate={didSearch}>
        <PatientSearchFormFull {...props}>
          {drawResults(props)}
        </PatientSearchFormFull>
      </RenderIf>
      <RenderIf validate={!didSearch}>
        <PatientSearchFormSlim {...props}>
          {drawResults(props)}
        </PatientSearchFormSlim>
      </RenderIf>
    </div>
  );
};
