import { postJSON } from 'src/utils/service';
import { createService } from 'src/services/service';
import { OidcTokensServiceParamType } from '../../core/oidc/oidc.types';
import { ENDPOINTS } from 'src/services/service.constants';
import { mockResponse } from './oidc.mock';
import moment from 'moment';
import { FORMAT } from '../../constants';

const oidcTokensRequest = (payload: OidcTokensServiceParamType) => {
  const { token, redirectUri } = payload;
  return postJSON(ENDPOINTS.token, {
    body: {
      tokenType: 'CODE',
      token,
      redirectUri,
    } as any,
  });
};

const oidcRefreshRequest = ({
  token,
  redirectUri,
}: OidcTokensServiceParamType) => {
  return postJSON(ENDPOINTS.token, {
    body: {
      tokenType: 'REFRESH',
      token,
      redirectUri,
    } as any,
    headers: {},
  });
};

export const oidcTokensResponseTransform = (oidcTokensData) => {
  const { access_token, refresh_token, expires_in, id_token, token_type } =
    oidcTokensData;
  return {
    accessToken: access_token,
    refreshToken: refresh_token,
    expires_in: expires_in * 1000,
    legacyToken: id_token,
    token_type,
    expiration: moment().add(expires_in, 's').format(FORMAT),
  };
};

export const oidcTokensService = ({ devMode = false }) => {
  if (devMode) {
    return createService(
      () => Promise.resolve(mockResponse as any),
      oidcTokensResponseTransform,
    );
  }
  return createService(oidcTokensRequest, oidcTokensResponseTransform);
};

export const oidcRefreshService = ({ devMode = false }) => {
  if (devMode) {
    return createService(
      () => Promise.resolve(mockResponse as any),
      oidcTokensResponseTransform,
    );
  }

  return createService(oidcRefreshRequest, oidcTokensResponseTransform);
};
