import React from 'react';
import { isEmpty } from 'ramda';
import { AutoSizer } from 'react-virtualized';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  Candlestick,
  ThresholdLine,
  TargetRange,
  BackgroundPanelsFitted,
  GridLines,
  TrendToolTip,
} from 'src/domains/diagnostics/components';
import { ResizeWrapper } from 'src/components/resize-wrapper/resize-wrapper.component';
import {
  Graph,
  Plot,
  VerticalAxis,
  HorizontalAxis,
  TrendSeries,
} from 'src/lib';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls';
import { colors, strokeWidth } from 'src/core/styles';
import { ToolTip } from 'src/domains/diagnostics/components';
import { filterVerticalTicksForViewAndVisibilityTolerance } from 'src/domains/diagnostics/utils/graphs.util';
import { RenderIf } from 'src/domains/diagnostics/utils';
import { withGraphLoader } from 'src/domains/diagnostics/utils';
import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';
import { withToolTip } from 'src/utils/with-tool-tip';

import { StandardDayTrendWrapperDiv } from './standard-day-trend.style';
import {
  COLLAPSED_STD_GRAPH_HEIGHT,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';

import {
  getToolTipValueColor,
  getYAxisTickVisibilityTolerance,
} from '../graph.util';
import {
  BG_UNIT_TO_AXIS_LABEL,
  GRAPH_TYPE_TREND,
  PRIMARY_TICK_COLOR,
  GRAPH_AXES_FONT_MULTIPLIER,
  GRAPH_MAX_HEIGHT,
} from '../graph.constants';

const StandardDayTrendPlot = ({
  x,
  y,
  width,
  height,
  points,
  padding = 0,
  targetRange,
  threshold,
  horizontalTicks,
  verticalTicks,
  showGridLines,
  yDirection = -1,
  onCandleStickMouseOver,
  onCandleStickMouseOut,
}) => (
  <Plot
    id="main-viewport"
    x={x}
    y={y}
    width={width}
    height={height}
    padding={padding}
  >
    <BackgroundPanelsFitted
      width={width}
      height={height}
      count={points.length / 2}
      primary={colors.silverLight}
      secondary={colors.white}
    />
    <RenderIf validate={showGridLines}>
      <GridLines
        width={width}
        height={height}
        verticalCount={horizontalTicks.length}
        horizontalCount={verticalTicks.filter((tick) => tick.gridLine).length}
      />
    </RenderIf>
    <TargetRange
      min={targetRange.min}
      max={targetRange.max}
      width={width}
      height={height}
      plotHeight={400}
    />
    <ThresholdLine
      threshold={threshold}
      width={width}
      height={height}
      plotHeight={400}
    />
    <TrendSeries
      width={width}
      height={height}
      points={points}
      onMouseOver={onCandleStickMouseOver}
      onMouseOut={onCandleStickMouseOut}
      candlestickShape={Candlestick}
      range={points.length}
      pointsOnAxes
    />

    {/* Plot borders */}
    <line
      x1={0}
      y1={0}
      x2={0}
      y2={yDirection * height}
      strokeWidth={strokeWidth.one}
      stroke={colors.grayLight}
    />
    <line
      x1={0}
      y1={0}
      x2={width}
      y2={0}
      strokeWidth={strokeWidth.one}
      stroke={colors.grayLight}
    />
    <line
      x1={0}
      y1={yDirection * height}
      x2={width}
      y2={yDirection * height}
      strokeWidth={strokeWidth.one}
      stroke={colors.grayLight}
    />
    <line
      x1={width}
      y1={0}
      x2={width}
      y2={yDirection * height}
      strokeWidth={strokeWidth.one}
      stroke={colors.grayLight}
    />
  </Plot>
);

const AxesPlot = ({
  x,
  y,
  axisX,
  axisY,
  verticalTicks,
  timeHorizontalTicks,
  timeHorizontalTickLines,
  mealHorizontalTicks,
  iconHorizontalTicks,
  width,
  height,
  innerPlotWidth,
  innerPlotHeight,
  yDirection = -1,
  bloodGlucoseUnit,
  t,
}) => {
  const numberLabelFontSize = (width / 4) * 0.03;
  const textLabelFontSize = height * GRAPH_AXES_FONT_MULTIPLIER.EXPANDED;

  /*
    The goal of manipulating timeHorizontalTicks data below is to add a sudo tick to the end of the X axis of the standard day trend graph for consistency.

    Value 1 was calculated using the the normalization calculation for TimeHorizontalTicks in standard-day-trend selector (line 102)
    Calculation: Sudo tick index (index of the last element of the timeHorizontalTicks array + 1) / timeHorizontalTicks length (timeInterval)

    The Breakfast time block label is used to set the label as the endTime for night is the startTime for Breakfast
  */
  const timeHorizontalTicksXAxis = timeHorizontalTicks.concat({
    value: 1,
    label: timeHorizontalTicks[0].label,
  });

  return (
    <Plot id="main-axes" x={x} y={y} width={width} height={height}>
      <VerticalAxis
        x={axisX * 0.85}
        y={axisY}
        height={innerPlotHeight}
        axisColor={colors.grayLight}
        Tick={(tick) => (
          <React.Fragment key={`${tick.value}`}>
            <line
              x1={axisX * 0.05}
              x2={axisX * 0.85}
              y1={tick.value}
              y2={tick.value}
              strokeWidth={strokeWidth.one}
              stroke={tick.color || PRIMARY_TICK_COLOR}
            />
            <text
              y={tick.value}
              textAnchor="end"
              fontSize={numberLabelFontSize}
              dy="0.5em"
              fill={tick.color || colors.black}
            >
              {tick.label}
            </text>
          </React.Fragment>
        )}
        ticks={verticalTicks}
      />
      <HorizontalAxis
        x={axisX}
        y={(axisY + innerPlotHeight) * 1.065}
        width={innerPlotWidth}
        Tick={(tick) => (
          <React.Fragment key={`${tick.value}`}>
            <text
              textAnchor="middle"
              x={tick.value}
              fontSize={textLabelFontSize}
            >
              {tick.label}
            </text>
          </React.Fragment>
        )}
        ticks={mealHorizontalTicks}
        axisColor={colors.grayLight}
      />
      <HorizontalAxis
        x={axisX}
        y={(axisY + innerPlotHeight) * 1.05}
        width={innerPlotWidth}
        Tick={(tick) => {
          const IconComponent = tick.component;
          const iconWidth = (tick.iconWidthScale * width) / 4;

          return (
            <g
              key={`${tick.value}`}
              transform={`translate(${tick.value - iconWidth / 2})`}
            >
              <IconComponent width={iconWidth} />
            </g>
          );
        }}
        ticks={iconHorizontalTicks}
        axisColor={colors.grayLight}
      />
      <VerticalAxis
        x={axisX * 0.3}
        y={axisY}
        height={innerPlotHeight}
        axisColor={colors.grayLight}
        Tick={(tick) => (
          <React.Fragment key={`${tick.value}`}>
            <text
              y={tick.value}
              textAnchor="middle"
              transform={`rotate(270, 0, ${tick.value})`}
              fontSize={textLabelFontSize}
            >
              {tick.label}
            </text>
          </React.Fragment>
        )}
        ticks={[
          {
            value: 0.5,
            label: t(BG_UNIT_TO_AXIS_LABEL[bloodGlucoseUnit]),
          },
        ]}
      />
      <HorizontalAxis
        x={axisX}
        y={axisY * 0.5}
        width={innerPlotWidth}
        Tick={(tick) => (
          <React.Fragment key={`${tick.value}`}>
            <text
              textAnchor="middle"
              x={tick.value}
              fontSize={numberLabelFontSize}
              fill={colors.grayDark}
            >
              {tick.label}
            </text>
          </React.Fragment>
        )}
        ticks={timeHorizontalTicksXAxis}
        axisColor={colors.grayLight}
      />

      <HorizontalAxis
        x={axisX}
        y={axisY + innerPlotHeight}
        width={innerPlotWidth}
        Tick={({ value, type }) => (
          <React.Fragment>
            <RenderIf validate={type === 'short'}>
              <line
                x1={value}
                y1={height * 0.025 * yDirection}
                x2={value}
                y2={0}
                strokeWidth={strokeWidth.one}
                stroke={colors.grayLight}
              />
            </RenderIf>

            <RenderIf validate={type === 'long'}>
              <line
                x1={value}
                y1={height * 0.045 * yDirection}
                x2={value}
                y2={0}
                strokeWidth={strokeWidth.one}
                stroke={colors.grayLight}
              />
            </RenderIf>
          </React.Fragment>
        )}
        ticks={timeHorizontalTickLines}
        axisColor={colors.grayLight}
      />
    </Plot>
  );
};

const StandardDayTrendWrapperDivWithLoader = withGraphLoader(
  StandardDayTrendWrapperDiv,
);

export const StandardDayTrendComponent = withToolTip(
  ({
    measurements,
    bloodGlucoseUnit,
    graphData,
    graphDetails,
    iconHorizontalTicks,
    mealHorizontalTicks,
    showChangeGraphToggle,
    showGraphDetails = true,
    showGridLines,
    targetRange,
    threshold,
    timeHorizontalTicks,
    timeHorizontalTickLines,
    verticalTicks,
    yDirection = -1,
    toolTip,
    showToolTip,
    hideToolTip,
    flexibleHeight,
    isLoading,
    graphYMax,
    t,
    graph,
    collapsed
  }) => (
    <React.Fragment>
      <ResizeWrapper
        minHeight={
          collapsed ? COLLAPSED_STD_GRAPH_HEIGHT : MIN_EXPANDED_STD_GRAPH_HEIGHT
        }
        render={(height) => {
          const yAxisTickVisibilityTolerance = getYAxisTickVisibilityTolerance({
            bloodGlucoseUnit,
          });

          const filteredVerticalTicks =
            filterVerticalTicksForViewAndVisibilityTolerance(
              verticalTicks,
              flexibleHeight,
              threshold,
              targetRange,
              yAxisTickVisibilityTolerance,
              graphYMax,
            );

          const translatedMealHorizontalTicks = mealHorizontalTicks.map(
            ({ value, label }) => ({
              value,
              label: t(label),
            }),
          );

          return (
            <StandardDayTrendWrapperDivWithLoader
              hasError={isEmpty(measurements) && !isLoading}
              isLoading={isLoading}
            >
              <AutoSizer>
                {({ width }) => {
                   const innerPlotWidth = width * 0.95;
                   const innerPlotHeight = height * 0.8;
                   const innerPlotX = width * 0.04;
                   const innerPlotY = height * 0.1;
                  return (
                  <>
                    <RenderIf
                      validate={
                        !isLoading && showGraphDetails && !isEmpty(measurements)
                      }
                    >
                      <GraphControls
                        showChangeGraphToggle={showChangeGraphToggle}
                        graphStatistics={graphDetails}
                        graphType={GRAPH_TYPE_TREND}
                        graph={graph}
                        width={width}
                      />
                    </RenderIf>
                    <RenderIf validate={width && height}>
                      <Graph
                        viewportRight={width}
                        viewportBottom={height}
                        height={height}
                        anchor="xMidYMid"
                      >
                        <AxesPlot
                          t={t}
                          x={0}
                          y={0}
                          axisX={innerPlotX}
                          axisY={innerPlotY}
                          verticalTicks={filteredVerticalTicks}
                          timeHorizontalTicks={timeHorizontalTicks}
                          timeHorizontalTickLines={timeHorizontalTickLines}
                          mealHorizontalTicks={translatedMealHorizontalTicks}
                          iconHorizontalTicks={iconHorizontalTicks}
                          width={width}
                          height={height}
                          innerPlotWidth={innerPlotWidth}
                          innerPlotHeight={innerPlotHeight}
                          bloodGlucoseUnit={bloodGlucoseUnit}
                        />
                        <StandardDayTrendPlot
                          x={innerPlotX}
                          y={innerPlotY}
                          width={innerPlotWidth}
                          height={innerPlotHeight}
                          points={graphData}
                          verticalTicks={filteredVerticalTicks}
                          horizontalTicks={timeHorizontalTicks}
                          targetRange={targetRange}
                          threshold={threshold.value}
                          showGridLines={showGridLines}
                          onCandleStickMouseOver={showToolTip}
                          onCandleStickMouseOut={hideToolTip}
                        />
                      </Graph>
                    </RenderIf>
                  </>
                  );
                }}
              </AutoSizer>
            </StandardDayTrendWrapperDivWithLoader>
          );
        }}
      />

      <RenderIf validate={toolTip.x && toolTip.y}>
        {renderToolTip(toolTip, threshold, targetRange, bloodGlucoseUnit)}
      </RenderIf>
    </React.Fragment>
  ),
);

export const StandardDayTrend = withTranslation(StandardDayTrendComponent);

const renderToolTip = (
  { x, y, data },
  { data: thresholdData },
  { data: targetRangeData },
  bloodGlucoseUnit,
) => {
  const { stdDev, max, min, mean, count, icon: IconComponent } = data;

  return (
    <ToolTip x={x} y={y}>
      <TrendToolTip
        bloodGlucoseUnit={bloodGlucoseUnit}
        max={max}
        min={min}
        mean={mean && mean.toFixed(1)}
        numMeasurements={count}
        stDev={
          !stdDev || stdDev === EMPTY_VALUE_PLACEHOLDER
            ? stdDev
            : stdDev.toFixed(1)
        }
        icon={IconComponent && <IconComponent height="20" />}
        meanColor={getToolTipValueColor(
          mean,
          thresholdData.value,
          targetRangeData,
        )}
        maxColor={getToolTipValueColor(
          max,
          thresholdData.value,
          targetRangeData,
        )}
        minColor={getToolTipValueColor(
          min,
          thresholdData.value,
          targetRangeData,
        )}
      />
    </ToolTip>
  );
};
