import { createAuthHeader, postJSON } from 'src/utils/service/service.utils';

import {
  DeactivatePatientLoaderImplType,
  DeactivatePatientServiceImplType,
  DeactivatePatientLoaderParams,
  OpenId,
} from './deactivate-patient.types';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const DeactivatePatientLoaderImpl: DeactivatePatientLoaderImplType = (
  openId: OpenId,
  patientId: number,
  params: DeactivatePatientLoaderParams,
) => {
  return postJSON(
    endpointWithParams(ENDPOINTS.deactivatePatient, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
        ...params,
      },
    },
  );
};

export const DeactivatePatientTransformImpl = (data) => data;

export const DeactivatePatientServiceImpl: DeactivatePatientServiceImplType =
  (load: DeactivatePatientLoaderImplType, transform) =>
  (token, patientId, params) =>
    load(token, patientId, params).then(transform);
