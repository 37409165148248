import { always, ifElse, prop } from 'ramda';
import React from 'react';

export const RenderIf = ifElse(
  prop('validate'),
  ({ children }) => <React.Fragment>{children}</React.Fragment>,
  always(null),
);


export const RenderIfElse = ifElse(
  prop('validate'),
  ({ children }) => children?.[0] ? <React.Fragment>{children[0]}</React.Fragment> : null,
  ({ children }) => children?.[1] ?<React.Fragment>{children[1]}</React.Fragment> : null,
);
