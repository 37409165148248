import { compose, isEmpty, reject } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';

import {
  selectIsLoading,
  selectBolusTotalInDateSliderRange,
  selectBolusPlusBasalTotalInDateSliderRange,
} from 'src/domains/diagnostics/store/selectors';
import { colors } from 'src/core/styles';
import { addEmptyFillerRadialSegmentWhenValuesZero } from 'src/domains/diagnostics/utils';
import {
  selectGlucoseMeasurementsIncludingNullValues,
  selectInsulin,
} from 'src/domains/diagnostics/scenes/graphs/logbook.core.selector';
import {
  logBookStatsDayToGraphDetailInsulinReducer,
  selectGraphDetailInsulin,
} from 'src/domains/diagnostics/scenes/graphs/graph.selector';
import { formatDecimalAsPercentage } from 'src/domains/diagnostics/scenes/insulin/utils';
import { selectLogbookStatsData } from 'src/domains/diagnostics/widgets/logbook-stats/logbook-stats.selector';

export const selectBolusBasalData = createSelector(
  selectGlucoseMeasurementsIncludingNullValues,
  selectInsulin,
  (measurements, insulinData) => {
    let bolusAccepted = 0;
    let bolusModified = 0;
    let bolusInsulin1 = 0;
    let bolusInsulin2 = 0;
    let insulin1 = 0;
    let insulin2 = 0;
    for (const measurement of measurements) {
      if (measurement.advicedBolus) {
        if (measurement.advicedBolus.confirmed) {
          measurement.advicedBolus.recommended.totalIU ===
          measurement.advicedBolus.confirmed.totalIU
            ? bolusAccepted++
            : bolusModified++;
        } else {
          measurement.advicedBolus.recommended.totalIU ===
          measurement.advicedBolus.selected.totalIU
            ? bolusAccepted++
            : bolusModified++;
        }
      }
      if (measurement.insulin1) {
        bolusInsulin1++;
        insulin1 += measurement.insulin1;
      }
      if (measurement.insulin2) {
        bolusInsulin2++;
        insulin2 += measurement.insulin2;
      }
    }

    let totalBolus = insulinData.bolus.length;

    return {
      bolusAccepted,
      bolusModified,
      bolusInsulin1,
      bolusInsulin2,
      insulin1,
      insulin2,
      totalInsBolusType: insulinData,
      totalBolus,
    };
  },
);

export const selectAvgBolusBasalPerDay = createSelector(
  selectGraphDetailInsulin,
  selectLogbookStatsData,
  (detailInsulinPerDay, statData) => {
    const [totalUInsulin, basalUInsulin, bolusUInsulin] = statData
      .reduce(logBookStatsDayToGraphDetailInsulinReducer, [[], [], [], []])
      .map(reject(isEmpty));

    const insulinTotal = totalUInsulin.reduce((acc, curr) => acc + curr, 0);
    const insulinBasalTotal = basalUInsulin.reduce(
      (acc, curr) => acc + curr,
      0,
    );
    const insulinBolusTotal = bolusUInsulin.reduce(
      (acc, curr) => acc + curr,
      0,
    );

    const totalBolusValAvg = insulinBolusTotal / insulinTotal;

    const totalBasalValAvg = insulinBasalTotal / insulinTotal;

    return {
      totalBolusAvg: formatDecimalAsPercentage(totalBolusValAvg),
      totalBasalAvg: formatDecimalAsPercentage(totalBasalValAvg),
      totalBolusAvgNumber: totalBolusValAvg,
      totalBasalAvgNumber: totalBasalValAvg,
      detailInsulinPerDay,
      hasBolusData: insulinBolusTotal > 0,
      hasBasalData: insulinBasalTotal > 0,
    };
  },
);

const mapAvgBolusBasalPerDayToAvgBolusBasalPerDaySegments = ({
  totalBolusAvgNumber,
  totalBasalAvgNumber,
  hasBasalData,
  hasBolusData,
}) => [
  {
    name: 'totalBolusAvg-segment',
    value:
      !hasBasalData || !hasBolusData
        ? 0
        : totalBolusAvgNumber / (totalBasalAvgNumber + totalBolusAvgNumber) ||
          0,
    fill: colors.basalBlue,
  },
  {
    name: 'totalBasalAvg-segment',
    value:
      !hasBasalData || !hasBolusData
        ? 0
        : totalBasalAvgNumber / (totalBasalAvgNumber + totalBolusAvgNumber) ||
          0,
    fill: colors.blueMarine,
  },
];

const selectAvgBolusBasalPerDaySegments = createSelector(
  selectAvgBolusBasalPerDay,
  compose(
    addEmptyFillerRadialSegmentWhenValuesZero,
    mapAvgBolusBasalPerDayToAvgBolusBasalPerDaySegments,
  ),
);

export const selectHasNoData = createSelector(
  selectBolusTotalInDateSliderRange,
  selectBolusPlusBasalTotalInDateSliderRange,
  selectIsLoading,
  selectBolusBasalData,
  selectAvgBolusBasalPerDay,
  (
    totalBolus,
    totalBolusPlusBasal,
    isLoading,
    { bolusInsulin1, bolusInsulin2 },
    { hasBolusData, hasBasalData },
  ) =>
    !isLoading &&
    isEmpty(totalBolus) &&
    isEmpty(totalBolusPlusBasal) &&
    bolusInsulin1 === 0 &&
    bolusInsulin2 === 0 &&
    !hasBolusData &&
    !hasBasalData,
);

const selectBolusAdviceData = createSelector(
  selectBolusBasalData,
  ({ bolusAccepted, bolusModified, bolusInsulin1, totalBolus }) => {
    const totalBolusWithIns1 = totalBolus + bolusInsulin1;
    const totalBolusWithoutCal =
      totalBolusWithIns1 - bolusAccepted - bolusModified;
    const bolusAcceptedPercentage = Math.round(
      (bolusAccepted / totalBolusWithIns1) * 100 || 0,
    );
    const bolusModifiedPercentage = Math.round(
      (bolusModified / totalBolusWithIns1) * 100 || 0,
    );

    const bolusWithoutCalPercentage =
      totalBolusWithIns1 > 0
        ? Math.round(100 - bolusAcceptedPercentage - bolusModifiedPercentage)
        : 0;
    return {
      bolusAcceptedPercentage,
      numBolusAccepted: bolusAccepted,
      bolusModifiedPercentage,
      numBolusModified: bolusModified,
      bolusWithoutCalPercentage,
      numBolusWithoutCal: totalBolusWithoutCal,
      totalBolusWithIns1: totalBolusWithIns1,
    };
  },
);
const mapBolusAdviceDataToBolusAdviceDataSegments = ({
  bolusAcceptedPercentage,
  bolusModifiedPercentage,
  bolusWithoutCalPercentage,
}) => [
  {
    name: 'bolusAccepted-segment',
    value: bolusAcceptedPercentage / 100 || 0,
    fill: colors.basalBlue,
  },
  {
    name: 'bolusModified-segment',
    value: bolusModifiedPercentage / 100 || 0,
    fill: colors.blueMarine,
  },
  {
    name: 'bolusWithoutCal-segment',
    value: bolusWithoutCalPercentage / 100 || 0,
    fill: colors.purpleBolusCal,
  },
];

export const removeBolusAdviceDataSegmentWhenValueIsZero = (
  bolusAdviceDataSegments,
) => bolusAdviceDataSegments.filter(({ value }) => value > 0);

const selectBolusAdviceSegments = createSelector(
  selectBolusAdviceData,
  compose(
    removeBolusAdviceDataSegmentWhenValueIsZero,
    addEmptyFillerRadialSegmentWhenValuesZero,
    mapBolusAdviceDataToBolusAdviceDataSegments,
  ),
);

export const insulinDetailsConnector = createStructuredSelector({
  hasNoData: selectHasNoData,
  avgBolusBasalPerDay: selectAvgBolusBasalPerDay,
  avgBolusBasalPerDaySegments: selectAvgBolusBasalPerDaySegments,
  bolusAdviceData: selectBolusAdviceData,
  bolusAdviceSegments: selectBolusAdviceSegments,
  isLoading: selectIsLoading,
});
