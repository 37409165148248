import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';

export const returnDivideBy100 = (value) => value / 100;
export const returnZeroIfEmptyValueOrValue = (value) => {
  if (value === EMPTY_VALUE_PLACEHOLDER) {
    return 0;
  }

  return Math.round(value);
};
