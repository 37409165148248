import { PatientDevice } from 'src/core/list-devices';
import { createAuthHeader, getJSON } from 'src/utils/service/service.utils';

import { convertStringToJSDate, formatDateString } from 'src/utils/date';

import {
  ListDevicesLoaderImplType,
  ListDevicesServiceImplType,
  OpenId,
} from './list-devices.types';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const ListDevicesLoaderImpl: ListDevicesLoaderImplType = (
  openId: OpenId,
  patientId: number,
) =>
  getJSON(endpointWithParams(ENDPOINTS.listDevices, { patientId }), {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
  });

const dateFormat = (string) =>
  formatDateString({
    dateString: string,
    format: 'dd LLL yyyy',
  });

export const ListDevicesTransformImpl = (data: any): PatientDevice[] =>
  data.model.patientDevices.map((patientDevice) => ({
    active: patientDevice.active,
    addedDate: dateFormat(patientDevice.fromDate),
    addedDateJsDate: convertStringToJSDate(patientDevice.fromDate),
    lastDownloadDate: dateFormat(patientDevice.lastDownloadDate),
    lastDownloadDateJsDate: convertStringToJSDate(
      patientDevice.lastDownloadDate,
    ),
    removedDate: dateFormat(patientDevice.toDate),
    removedDateJsDate: convertStringToJSDate(patientDevice.toDate),
    action: !!patientDevice.toDate,
    serial: patientDevice.device.serialNumber,
    brandName: patientDevice.device.deviceModel
      ? patientDevice.device.deviceModel.brandName
      : '',
    name: patientDevice.device.deviceModel
      ? patientDevice.device.deviceModel.name
      : '',
    type: patientDevice.device.deviceTypeIcon,
    resourceType: patientDevice.device.resourceType,
    deviceId: patientDevice.device.id,
  }));

export const ListDevicesServiceImpl: ListDevicesServiceImplType =
  (load: ListDevicesLoaderImplType, transform) => (token, patientId) =>
    load(token, patientId).then(transform);
