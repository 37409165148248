import { path, find, propEq } from 'ramda';

export const selectOrgStripModelStocks = path(['orgStock', 'stripModelStocks']);
export const selectOrgStripModels = path(['orgStock', 'stripModels']);
export const selectOrgAggregateStripStock = path([
  'orgStock',
  'aggregateStripStock',
]);
export const selectOrgStripModelName = (stripModelReference, stripModels) => {
  if (!!stripModelReference && !!stripModels.length) {
    const model = find(propEq('value', stripModelReference))(stripModels);
    return model ? model.label : '-';
  }
  return null;
};
