import * as React from 'react';
import { isNotNil } from 'src/utils';
import { transformDate } from './dropdown-calendar.util';
import { DateRangeIcon } from 'src/assets/icons';
import {
  DisplayOptionContainer,
  VerticalSeparator,
  DisplayValueDate,
  DisplayValueReport,
} from './dropdown-calendar.styles';
import { withTheme } from 'styled-components';

const displayOptionComponent: React.FunctionComponent<any> = ({
  children,
  theme,
  values,
}) => {
  if (isNotNil(values) && isNotNil(values.data)) {
    const date = transformDate(values.data.date);
    const hour = transformDate(values.data.date, 'HH:mm');
    return (
      <DisplayOptionContainer>
        <DateRangeIcon height={12} fillColor={theme.colors.brandBlue} />
        <DisplayValueDate>{date}</DisplayValueDate>
        <VerticalSeparator />
        <div>{hour}</div>
        <DisplayValueReport>View Report</DisplayValueReport>
      </DisplayOptionContainer>
    );
  } else {
    return <>{children}</>;
  }
};

export const DisplayOption = withTheme(displayOptionComponent);
