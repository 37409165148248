import React from 'react';
import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';
import { createStructuredSelector } from 'reselect';
import {
  selectAccessEndpoint,
  selectGigyaToken,
  selectOidcClientId,
  selectRedirectUri,
} from 'src/app/session/core/config/config.selectors';
import { connect } from 'react-redux';
import { LAST_ACTIVE } from 'src/app/session/constants';

/* istanbul ignore next */ export const Redirect = (props) => {
  const { accessEndpoint, gigyaToken, oidcClientId, redirectUri } = props;

  React.useEffect(() => {
    const url = endpointWithParams(ENDPOINTS.loginRedirect, {
      accessEndpoint,
      gigyaToken,
      oidcClientId: encodeURIComponent(oidcClientId || ''),
      redirectUri: encodeURIComponent(redirectUri),
    });
    localStorage.removeItem(LAST_ACTIVE);
    window.location.replace(url);
    // @ts-ignore
    window.store?.persistConfig?.storage?.setItem('persist:root', null);
  }, []);

  return <></>;
};

/* istanbul ignore next */ const connector = createStructuredSelector({
  accessEndpoint: selectAccessEndpoint,
  gigyaToken: selectGigyaToken,
  redirectUri: selectRedirectUri,
  oidcClientId: selectOidcClientId,
});
/* istanbul ignore next */
export const RedirectComponent = connect(connector)(Redirect);
