import * as React from 'react';
import { testId } from '@roche/roche-common';

import { getProps, additionalInfoActivated } from './additional.info.utils';
import { ID, BTN, SIZES } from './additional-info.constants';
import { AdditionalInfoWrapper } from './additional-info.style';

export const AdditionalInfoElement = ({
  children,
  handleClick,
  info,
  profile,
  active,
  style,
  size,
  t,
}) => {
  if (additionalInfoActivated() && !children) {
    return (
      <jello-icon-button
        style={style}
        icon-name="information"
        size={size ? size : SIZES.m}
        variant="tertiary"
        onClick={() => handleClick(getProps(t, info, profile, active))}
        {...testId(ID, `${info}${BTN}`)}
      />
    );
  }
  if (additionalInfoActivated() && !!children) {
    return (
      <AdditionalInfoWrapper
        onClick={() => handleClick(getProps(t, info, profile, active))}
        {...testId(ID, `${info}${BTN}`)}
      >
        {children}
      </AdditionalInfoWrapper>
    );
  }

  return <></>;
};
