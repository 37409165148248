/* tslint:disable */

import styled from 'styled-components';
import { TableCell } from 'src/domains/diagnostics/components';
import { colors, spacing, fontSize } from 'src/core';
import { weight } from 'src/components/fonts/weights';

export const StyledTablePrimaryCell = styled(TableCell)`
  padding: ${spacing.two} ${spacing.three};
  font-size: ${fontSize.p};
  background-color: ${(props) =>
    props.highlight ? colors.blueMarineAlpha : ''};
  color: ${(props) => (props.disabled ? colors.gray : '')};
  font-weight: ${(props) => (props.semiBold === true ? weight.semiBold : '')};
  width: ${(props) => (props.cellWidth ? `${props.cellWidth}%` : 'auto')};
  span {
    padding-left: 0.1rem;
  }
`;

export const StyledHighlightBox = styled.span`
  background-color: ${colors.blueFadedLight};
  color: ${colors.blue};
  border: 1px solid ${colors.blueMarineBorder};
  padding: 0.05rem 0.3rem 0.05rem !important;
  margin-left: 0.1rem;
  font-weight: ${weight.semiBold};
  text-align: center;
  min-width: 1.3rem;
  display: inline-block;
`;

export const StyledBox = styled.span`
  background: ${colors.silverLight};
  padding: 0.05rem 0.3rem 0.05rem !important;
  display: inline-block;
  border: 1px solid ${colors.grayLight};
  text-align: center;
  min-width: 1.3rem;
`;

StyledTablePrimaryCell.displayName = 'TablePrimaryCellDiv';
