import styled from 'styled-components';

import { ControlCheckbox } from 'src/domains/diagnostics/components/forms';
import { borderRadius, boxShadow, colors, transitions } from 'src/core/styles';
import { blendHexColor } from 'src/utils';
import { convertPxToRem } from 'src/domains/diagnostics/utils';

const checkboxActiveColor = colors.brandBlue;
const checkboxDisabledColor = colors.grayLight;
const customMedium = '1024px';

export const CheckboxContainer = styled.label`
  display: flex;
  padding: ${(props) =>
    `${convertPxToRem('4px')} 0 0 ${convertPxToRem(props.leftPadding)}`};
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

export const LeftLabelContainer = styled.div`
  display: flex;
  @media (max-width: ${customMedium}) {
    width: 80%;
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + span {
    background-color: ${checkboxActiveColor};
    border-color: ${checkboxActiveColor};
    &:hover {
      background-color: ${blendHexColor(checkboxActiveColor, 5)};
    }
  }

  &:checked + span > span {
    color: ${colors.white};
    visibility: visible;
  }

  &:hover + span,
  &:focus + span {
    border-color: ${checkboxActiveColor};
    box-shadow: ${boxShadow({
      color: checkboxActiveColor,
      depth: 'base',
    })};
  }

  @media (max-width: ${customMedium}) {
    width: 15px;
    height: 15px;
  }
`;

export const CheckboxControl = CheckboxInput.withComponent(ControlCheckbox);

export const CheckboxCheckMarkContainer = styled.span`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid
    ${(props) => (props.disabled ? checkboxDisabledColor : colors.gray)};
  border-radius: ${borderRadius.three};
  display: flex;
  height: ${(props) => convertPxToRem(props.size)};
  justify-content: center;
  text-align: center;
  transition: ${transitions.default};
  width: ${(props) => convertPxToRem(props.size)};
`;

export const CheckboxCheckMark = styled.span`
  visibility: hidden;
`;
