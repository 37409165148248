import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from 'react-redux-form';

import {
  changeLogbookType,
  changeGraphType,
  changeGraph,
} from 'src/domains/patient-dashboards/bg/store/bg.actions';
import { mapDispatchers } from 'src/domains/diagnostics/utils';
import {
  getTimeIntervalsRequest,
  getThresholdsRequest,
  fetchPatientRequest,
  fetchPatientDateRangeRequest,
  onPatientDateRangeChange,
} from 'src/domains/diagnostics/core';
import { graphConnector } from 'src/domains/diagnostics/scenes/graphs/graph.selector';

const dispatchers = mapDispatchers({
  changeGraph,
  changeGraphType,
  changeLogbookType,
  onDatesChange: onPatientDateRangeChange,
  onFetchPatient: fetchPatientRequest.start,
  onFetchPatientDateRange: fetchPatientDateRangeRequest.start,
  onGetTimeIntervals: getTimeIntervalsRequest.start,
  onGetThresholdsRequest: getThresholdsRequest.start,
  onHideBloodGlucoseLines: () =>
    actions.change('ui.patientDashboard.showBloodGlucoseLines', false),
});

const addLifeCycles = lifecycle({
  componentWillMount() {
    const { onHideBloodGlucoseLines, isGeneralPractitioner } = this.props;
    if (isGeneralPractitioner) onHideBloodGlucoseLines();
  },
  componentDidMount() {
    const {
      onFetchPatient,
      onFetchPatientDateRange,
      onGetThresholdsRequest,
      onGetTimeIntervals,
      hasUserFhirPermission,
      patientFhirId,
      match: { params },
      isLoading,
      dateRange = {},
      endDate,
    } = this.props;

    const patientId = params.id;
    const { end = '' } = dateRange;

    if (!isLoading) {
      onFetchPatient({ patientId });
      onGetTimeIntervals({ patientId });
      onGetThresholdsRequest({ patientId });
    }

    if (!isLoading && !end && !endDate.isValid) {
      onFetchPatientDateRange({
        patientId,
        hasUserFhirPermission,
        patientFhirId,
      });
    }
  },
});

export const connectToGraphs = (Component) =>
  compose(
    connect(graphConnector, dispatchers),
    withRouter,
    addLifeCycles,
  )(Component);

export const connectGraphs = (Component) => compose(connectToGraphs)(Component);
