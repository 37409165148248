import React from 'react';
import { isEmpty } from 'ramda';
import { Route, Redirect } from 'react-router-dom';

import { WithPermissions } from 'src/utils/with-permissions';
import { getConfig } from '@roche/roche-common';

import { SessionGuard } from './session-guard.component';
import { ProfessionalGuard } from './professional-guard.component';

export const ProtectedRoute = ({
  path,
  exact,
  isAuthenticated,
  component: Component,
  routes,
  hasPermissions = [],
  location,
  validateSession,
  accessConditions = [],
  validationFunctionInputs = {},
  validationRedirectPath,
  hasLoggedOut,
  returnAfterLogin,
}) => {
  const { REACT_APP_MOCK } = getConfig();
  if (REACT_APP_MOCK) {
    return (
      <Route
        path={path}
        exact={exact}
        render={(props) => <Component {...props} />}
      />
    );
  }
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        isAuthenticated ? (
          isEmpty(hasPermissions) ? (
            <SessionGuard validateSession={validateSession}>
              <ProfessionalGuard>
                <Component {...props} />
              </ProfessionalGuard>
            </SessionGuard>
          ) : accessConditions.every(
              (validationFunction) =>
                validationFunction(validationFunctionInputs) === true,
            ) ? (
            <SessionGuard validateSession={validateSession}>
              <ProfessionalGuard>
                <WithPermissions
                  hasPermissions={hasPermissions}
                  onAccessDenied={() => <Redirect to={routes.general.home} />}
                >
                  <Component {...props} />
                </WithPermissions>
              </ProfessionalGuard>
            </SessionGuard>
          ) : (
            <Redirect to={validationRedirectPath} />
          )
        ) : (
          <Redirect
            to={{
              pathname: routes.authentication.login,
              search:
                returnAfterLogin || !hasLoggedOut
                  ? `?next=${location.pathname}`
                  : '',
            }}
          />
        )
      }
    />
  );
};
