import { createStructuredSelector, createSelector, Selector } from 'reselect';
import {
  selectDevices,
  selectHasError,
  selectDevicesStatus,
  selectIsFetching,
} from 'src/core/list-devices';
import {
  selectDeviceSettingsList,
  selectDeviceSettingsListStatus,
} from 'src/core/device-settings-list/device-settings-list.selector';
import {
  selectDeviceSettings,
  selectDeviceSettingsStatusAllData,
} from 'src/core/device-settings/device-settings.selector';
import { selectDeviceSettingsStatus } from 'src/core/device-settings/device-settings.selector';
import { isEmpty, nth, isNil } from 'ramda';

import { FetchStatus, Devices, DeviceSetting } from './device-settings.types';

export const selectCurrentPatientDevicesStatus = createSelector(
  [selectHasError, selectIsFetching],
  (hasError, isFetching) => {
    let status;
    if (isFetching) status = FetchStatus.LOADING;
    else if (hasError) status = FetchStatus.FAILURE;
    else {
      status = FetchStatus.SUCCESS;
    }

    return status;
  },
);

export const selectPatientDevices = createSelector(
  selectDevices,
  (devices: Devices[]) => {
    if (!devices) return [];
    return devices
      .sort((a: any, b: any) => {
        return (
          new Date(b.addedDateJsDate).getTime() - a.addedDateJsDate.getTime()
        );
      })
      .map((device) => ({
        value: device.serial,
        label: `${device.name} - ${device.serial}`,
      }));
  },
);

export const selectLatestDeviceSetting: Selector<
  DeviceSetting[],
  DeviceSetting
> = createSelector([selectDeviceSettings], (deviceSettings: DeviceSetting) => {
  return !isEmpty(deviceSettings) && !isNil(deviceSettings)
    ? deviceSettings[0]
    : {};
});

export const selectPreviousDeviceSetting: Selector<
  DeviceSetting[],
  DeviceSetting
> = createSelector([selectDeviceSettings], (deviceSettings: DeviceSetting) => {
  return !isEmpty(deviceSettings) && nth(1, deviceSettings)
    ? nth(1, deviceSettings)
    : {};
});

export const deviceSettingsConnector = () => {
  return createStructuredSelector({
    patientDevicesStatus: selectDevicesStatus,
    patientDevices: selectPatientDevices,
    deviceSettingsList: selectDeviceSettingsList,
    deviceSettingsListStatus: selectDeviceSettingsListStatus,
    deviceSettings: selectDeviceSettings,
    previousDeviceSettings: selectPreviousDeviceSetting,
    deviceSettingsStatus: selectDeviceSettingsStatus,
    deviceSettingsStatusAll: selectDeviceSettingsStatusAllData,
  });
};
