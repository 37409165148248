import {
  getClinicalData,
  getClinicalDataFromFhir,
} from 'src/domains/patient-dashboards/bg/store/bg.actions';
import { requestSequence } from 'src/core/request';

import { selectUnitMeasurementForService } from './patient-date-range.selector';
import {
  FETCH_PATIENT_DATE_RANGE_REQUEST,
  PATIENT_DATE_ACTIONS,
} from './patient-date-range.constant';

export const fetchPatientDateRangeEpic = (fetchDateRangeService) =>
  requestSequence({
    service: fetchDateRangeService,
    actionTypes: FETCH_PATIENT_DATE_RANGE_REQUEST,
  });

export const getClinicalDataOnDatesChangeEpic = () => (action$, store) =>
  action$
    .ofType(PATIENT_DATE_ACTIONS.SET_DATES)
    .mergeMap((action) => {
      const state = store.getState();
      const bgUnit = selectUnitMeasurementForService(state);
      const {
        patientId,
        patientFhirId,
        hasUserFhirPermission,
        startDate,
        endDate,
      } = action.payload;

      return [
        hasUserFhirPermission
          ? getClinicalDataFromFhir.start({
              patientFhirId,
              startDate,
              endDate,
              bgUnit,
            })
          : getClinicalData.start({ patientId, startDate, endDate }),
      ];
    })
    .debounceTime(250);
