import {
  changePasswordServiceImpl,
  changePasswordLoaderImpl,
  changePasswordTransformImpl,
} from './change-password.service';

import { ChangePasswordResponse } from './change-password.types';

import { changePasswordResponseMock } from './change-password.mock';

const mockPost = (): Promise<ChangePasswordResponse> =>
  Promise.resolve(changePasswordResponseMock);

export const ChangePasswordFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockPost : changePasswordLoaderImpl;
  return changePasswordServiceImpl(loader, changePasswordTransformImpl);
};
