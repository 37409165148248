import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app/app';

const container = document.getElementById('root');
const root = createRoot(container!);

import(/* webpackChunkName: "jello" */ '@rdcs/jello-ds/main').then(() =>
  root.render(<App />),
);
