import * as React from 'react';
import { TFunction } from 'i18next';
import { testId } from '@roche/roche-common';
import { DISCLAIMERS } from './manufacturer.constants';

import {
  WarningCard,
  WarningCardContent,
  WarningCardContainer,
  WarningIconContainer,
  WarningSecondaryContainer,
  WarningsMainContainer,
} from '../manufacturer-info-modal.style';

type Props = {
  t: TFunction;
};

export const DisclaimersView = ({ t }: Props) => (
  <WarningsMainContainer {...testId(DISCLAIMERS, '')}>
    <jello-text size="S">
      {t('manufacturerInfo.precautionsWarnings.text1')}
    </jello-text>
    <WarningCard>
      <WarningIconContainer>
        <jello-icon
          class="warning-icon-disclaimers"
          size="S"
          icon-name="warning--alt"
          color="jello-color-brand-02"
        />
      </WarningIconContainer>
      <WarningCardContainer>
        <jello-text size="M" weight="bold" class="warning-box-title">
          {t('manufacturerInfo.precautionsWarnings.WarningTitle')}
        </jello-text>
        <WarningCardContent
          className="warning-text-with-icon-container"
          dangerouslySetInnerHTML={{
            __html: t('manufacturerInfo.precautionsWarnings.WarningText'),
          }}
        />
      </WarningCardContainer>
    </WarningCard>

    <WarningCard>
      <WarningIconContainer>
        <jello-icon
          class="warning-icon-disclaimers"
          size="S"
          icon-name="warning--alt"
          color="jello-color-brand-02"
        />
      </WarningIconContainer>
      <WarningCardContainer>
        <jello-text size="M" weight="bold" class="warning-box-title">
          {t('manufacturerInfo.precautionsWarnings.PrecautionTitle')}
        </jello-text>
        <WarningCardContent
          className="warning-text-with-icon-container"
          dangerouslySetInnerHTML={{
            __html: t('manufacturerInfo.precautionsWarnings.PrecautionText1'),
          }}
        />
      </WarningCardContainer>
    </WarningCard>

    <WarningCard>
      <WarningCardContainer>
        <jello-text size="M" weight="bold" class="warning-box-title">
          {t('manufacturerInfo.precautionsWarnings.NoteTitle')}
        </jello-text>
        <WarningCardContent
          dangerouslySetInnerHTML={{
            __html: t('manufacturerInfo.precautionsWarnings.NoteText'),
          }}
        />
      </WarningCardContainer>
    </WarningCard>

    <WarningSecondaryContainer>
      <jello-text size="XL" weight="semibold">
        {t('manufacturerInfo.precautionsWarnings.title')}
      </jello-text>
    </WarningSecondaryContainer>

    <WarningCard>
      <WarningIconContainer>
        <jello-icon
          class="warning-icon-disclaimers"
          size="S"
          icon-name="warning--alt"
          color="jello-color-brand-02"
        />
      </WarningIconContainer>
      <WarningCardContainer>
        <jello-text size="S" weight="bold" class="warning-box-title">
          {t('manufacturerInfo.precautionsWarnings.PrecautionTitle')}
        </jello-text>
        <WarningCardContent
          className="warning-text-with-icon-container-with-multiple-text"
          dangerouslySetInnerHTML={{
            __html: t('manufacturerInfo.precautionsWarnings.PrecautionText2'),
          }}
        />
      </WarningCardContainer>
    </WarningCard>

    <WarningCard>
      <WarningCardContainer>
        <jello-text size="M" weight="bold" class="warning-box-title">
          {t('manufacturerInfo.precautionsWarnings.NoteTitle')}
        </jello-text>
        <WarningCardContent
          dangerouslySetInnerHTML={{
            __html: t('manufacturerInfo.precautionsWarnings.NoteText2'),
          }}
        />
      </WarningCardContainer>
    </WarningCard>
  </WarningsMainContainer>
);
