import {
  ONE_MATCH_FOUND_CODE,
  NO_MATCH_FOUND_CODE,
  CROSS_ICON,
  CHECKMARK_ICON,
  insModalBtns,
  insModalSubtitles,
  insModalTitles,
} from './ins-request.modal.constants';
import { colors } from 'src/core';

export const setINSSuccessElements = (code) => {
  if (code === ONE_MATCH_FOUND_CODE) {
    return {
      icon: CHECKMARK_ICON,
      color: colors.jelloColorFeedback01,
      title: `${insModalTitles}.matchFound`,
      subtitle: `${insModalSubtitles}.matchFound`,
    };
  } else if (code === NO_MATCH_FOUND_CODE) {
    return {
      icon: CROSS_ICON,
      color: colors.jelloColorFeedback07,
      title: `${insModalTitles}.noMatchFound`,
      subtitle: `${insModalSubtitles}.reviewInfo`,
    };
  } else {
    return {
      icon: CROSS_ICON,
      color: colors.jelloColorFeedback07,
      title: `${insModalTitles}.severalFound`,
      subtitle: `${insModalSubtitles}.reviewInfo`,
    };
  }
};

export const setPrimaryBtnText = (OneMatchFound) =>
  OneMatchFound
    ? `${insModalBtns}.confirm`
    : `${insModalBtns}.reviewPatientInfo`;
