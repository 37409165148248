import styled from 'styled-components';

import { colors, spacing, borderRadius } from 'src/core/styles';
import { GRAPH_LEGEND_TYPES } from 'src/domains/diagnostics/components/graph-legend/graph-legend.constant.js';

export const selectedTabStyle = `
  path,
  circle {
    fill: ${colors.blue};
  }
  .horizontalLines {
    stroke: ${colors.blue};
  }
  color: ${colors.blue};
`;

export const GraphControlsContainer = styled.div`
  margin: 0;
  width: ${(props) => props.width ? props.width + 'px' :'auto'};
`;
GraphControlsContainer.displayName = 'GraphControlsContainer';

export const ButtonsContainer = styled.div`
  display: block;
  text-align: right;
  font-size: '16px';
  margin-top: ${(props) =>
    props.collapsed || props.collapsedGP
      ? '-33px'
      : props.graphType === GRAPH_LEGEND_TYPES.CGM_DAILY_GLUCOSE
      ? '-28px'
      : '-22px'};
  margin-right: ${(props) =>
    props.graphType === GRAPH_LEGEND_TYPES.CGM_DAILY_GLUCOSE ? '20px' : '60px'};
  margin-bottom: ${(props) =>
    props.graphType === GRAPH_LEGEND_TYPES.CGM_DAILY_GLUCOSE ? '0' : '5px'};
  vertical-align: top;
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const NavTabsList = styled.ul`
  list-style-type: none;
  display: inline-block;
  margin-top: 0px;
  padding: 0;
  margin-left: ${(props) =>
    props.marginLeft ? props.marginLeft : spacing.two};
  padding-right: ${(props) => (props.marginLeft ? props.marginLeft : '0.5rem')};
  margin-bottom: 0;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
`;
NavTabsList.displayName = 'NavTabsList';

export const NavTabsListItem = styled.li`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  border: 1px solid ${colors.silverMedium};
  border-bottom: ${(props) =>
    props.selected
      ? `1px solid ${colors.white}}`
      : `1px solid ${colors.silverMedium}`};
  border-right: none;
  padding: 0 ${spacing.three};
  background-color: ${colors.white};

  &:first-child {
    border-radius: ${borderRadius.three} 0 0 ${(props) =>
  props.noTabSelected ? borderRadius.three : 0};
  }
  &:last-child {
    border-radius: 0 ${borderRadius.three} ${(props) =>
  props.noTabSelected ? borderRadius.three : 0} 0;
    border-right: 1px solid ${colors.silverMedium};
  }
  &:only-child {
    border-radius: ${(props) =>
      props.noTabSelected
        ? borderRadius.three
        : `${borderRadius.three} ${borderRadius.three} 0`};
  border-right: 1px solid ${colors.silverMedium}
`;
NavTabsListItem.displayName = 'NavTabsListItem';

export const NavTabsListItemText = styled.div`
  display: inline-block;
  padding: ${spacing.two} 0 0.6875rem 0;
  border-top: 0.1875rem solid transparent;
  border-top-color: ${(props) =>
    props.selected ? colors.blue : 'transparent'};
  color: ${(props) => (props.selected ? colors.blue : colors.charcoal)};
`;
NavTabsListItemText.displayName = 'NavTabsListItemText';

export const ContentContainer = styled.section`
  padding: 0 ${spacing.one};
  margin-left: ${(props) =>
    props.graphType === GRAPH_LEGEND_TYPES.CGM_DAILY_GLUCOSE ? '0' : '10px'};
  margin-right: ${(props) =>
    props.graphType === GRAPH_LEGEND_TYPES.CGM_DAILY_GLUCOSE ? '29px' : '10px'};
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
`;
ContentContainer.displayName = 'ContentContainer';

export const ArrowUpDown = styled.span`
  margin-left: 0.3125rem;
  transform: ${(props) => (props.selected ? 'rotate(180deg)' : null)};
  display: inline-block;
`;
ArrowUpDown.displayName = 'ArrowUpDown';
