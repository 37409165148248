import { GRAPHS as G } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

export const ID = 'additionalInfo';
export const BTN = '-btn';
export const PREFIX = 'modals.additionalInfo.';
export const TITLE = 'title';
export const CONTENT = 'content';
export const SECTIONS = 'sections';
export const ITEM = 'item';
export const CARD = 'card';

export const GRAPHS = { ...G };

export const SIZES = {
  xs: 'XS',
  s: 'S',
  m: 'M',
  l: 'L',
  xl: 'XL',
};

export const INFOS = {
  hba1c: 'hba1c',
  graphReports: 'graphReports',
  glucoseLvlDistrib: 'glucoseLvlDistrib',
  bgStatistics: 'bgStatistics',
  dailyGlucoseProfile: 'dailyGlucoseProfile',
  dailyGlucoseProfileExpanded: 'dailyGlucoseProfileExpanded',
  status: 'status2',
  hypoglycaemiaBgCard: 'hypoglycaemiaBgCard',
  variability: 'variability',
  cgmStatistics: 'cgmStatistics',
  timeInRanges: 'timeInRanges',
  agp: 'agp',
  homeDelivery: 'homeDelivery',
  patientStrip: 'patientStrip',
  stockDetails: 'stockDetails',
  patterns: 'patterns',
  targetRanges: 'targetRanges',
  rpmSettings: 'rpmSettings',
  timeBlocks: 'timeBlocks',
  defaultTargetRanges: 'defaultTargetRanges',
  unitsMeasurement: 'unitsMeasurement',
  gettingStarted: 'gettingStarted',
};

export const USERS = {
  HCP_MASTER: 'HCP_MASTER',
  GENERAL_PRACTITIONER: 'GENERAL_PRACTITIONER',
  ADMINISTRATIVE: 'ADMINISTRATIVE',
  PAYER: 'PAYER',
};

export const CARD_TYPES = {
  note: 'note',
  warning: 'warning',
  precaution: 'precaution',
};

export const EXPANDED_GLUCOSE_PROFILE = {
  overview: 'overview',
  dailyGlucoseProfile: 'dailyGlucoseProfile',
  contextualData: 'contextualData',
};

export const HOME_DELIVERY = {
  overview: 'overview',
  configuration: 'configuration',
  status: 'status',
};

export const PATIENT_STRIP = {
  overview: 'overview',
  delivery: 'delivery',
  prescription: 'prescription',
  alerts: 'alerts',
};

export const PATTERNS = {
  overview: 'overview',
  initialSetup: 'initialSetup',
  configurePatterns: 'configurePatterns',
  viewPatterns: 'viewPatterns',
};

export const RPM_SETTINGS = {
  overview: 'overview',
  settings: 'settings',
  enroll: 'enroll',
};

export const GETTING_STARTED = {
  aboutPlatform: 'aboutPlatform',
  navigatePlatform: 'navigatePlatform',
  homeScreen: 'homeScreen',
  viewPatient: 'viewPatient',
  uploadData: 'uploadData',
  printDownloadReports: 'printDownloadReports',
  remotePatientMonitoring: 'remotePatientMonitoring',
  electronicMedicalRecord: 'electronicMedicalRecord',
  patientCareTracking: 'patientCareTracking',
};

export const CONTENTS = {
  [INFOS.hba1c]: { cards: [CARD_TYPES.note] },
  [INFOS.graphReports]: {
    [USERS.HCP_MASTER]: {
      sections: [
        { info: GRAPHS.TREND },
        { info: GRAPHS.STANDARD_DAY },
        { info: GRAPHS.STANDARD_WEEK },
        { info: GRAPHS.LOGBOOK, cards: [CARD_TYPES.note, CARD_TYPES.note] },
        { info: GRAPHS.METABOLIC_RATE },
        {
          info: GRAPHS.BLOOD_GLUCOSE_GENERAL_STATS,
          cards: [CARD_TYPES.note, CARD_TYPES.note],
        },
        { info: GRAPHS.INSULIN },
        { info: GRAPHS.INSULIN_PUMP },
        { info: GRAPHS.DEVICE_SETTINGS },
      ],
    },
    sections: [
      { info: GRAPHS.TREND },
      { info: GRAPHS.STANDARD_DAY },
      { info: GRAPHS.LOGBOOK, cards: [CARD_TYPES.note, CARD_TYPES.note] },
    ],
  },
  [INFOS.glucoseLvlDistrib]: {},
  [INFOS.bgStatistics]: { cards: [CARD_TYPES.note, CARD_TYPES.note] },
  [INFOS.dailyGlucoseProfile]: { cards: [CARD_TYPES.note] },
  [INFOS.dailyGlucoseProfileExpanded]: {
    sections: [
      {
        info: EXPANDED_GLUCOSE_PROFILE.overview,
        cards: [CARD_TYPES.note],
      },
      {
        info: EXPANDED_GLUCOSE_PROFILE.dailyGlucoseProfile,
        cards: [CARD_TYPES.note],
      },
      {
        info: EXPANDED_GLUCOSE_PROFILE.contextualData,
        cards: [CARD_TYPES.note],
      },
    ],
  },
  [INFOS.status]: { cards: [CARD_TYPES.note] },
  [INFOS.hypoglycaemiaBgCard]: { cards: [CARD_TYPES.note] },
  [INFOS.variability]: {},
  [INFOS.cgmStatistics]: { cards: [CARD_TYPES.note] },
  [INFOS.timeInRanges]: { cards: [CARD_TYPES.note] },
  [INFOS.agp]: {},
  [INFOS.homeDelivery]: {
    sections: [
      { info: HOME_DELIVERY.overview },
      {
        info: HOME_DELIVERY.configuration,
        cards: [CARD_TYPES.note, CARD_TYPES.note],
      },
      { info: HOME_DELIVERY.status },
    ],
  },
  [INFOS.patientStrip]: {
    [USERS.ADMINISTRATIVE]: {
      sections: [
        { info: PATIENT_STRIP.overview, cards: [CARD_TYPES.note] },
        { info: PATIENT_STRIP.delivery },
      ],
    },
    sections: [
      { info: PATIENT_STRIP.overview, cards: [CARD_TYPES.note] },
      { info: PATIENT_STRIP.delivery },
      { info: PATIENT_STRIP.prescription, cards: [CARD_TYPES.note] },
      { info: PATIENT_STRIP.alerts },
    ],
  },
  [INFOS.stockDetails]: {},
  [INFOS.patterns]: {
    sections: [
      {
        info: PATTERNS.overview,
        cards: [CARD_TYPES.note, CARD_TYPES.note],
      },
      {
        info: PATTERNS.initialSetup,
        cards: [CARD_TYPES.note],
      },
      {
        info: PATTERNS.configurePatterns,
      },
      {
        info: PATTERNS.viewPatterns,
        cards: [CARD_TYPES.note],
      },
    ],
  },
  [INFOS.targetRanges]: {},
  [INFOS.rpmSettings]: {
    sections: [
      { info: RPM_SETTINGS.overview, cards: [CARD_TYPES.note] },
      {
        info: RPM_SETTINGS.settings,
        cards: [CARD_TYPES.note],
      },
      { info: RPM_SETTINGS.enroll },
    ],
  },
  [INFOS.timeBlocks]: {},
  [INFOS.defaultTargetRanges]: { cards: [CARD_TYPES.note] },
  [INFOS.unitsMeasurement]: {
    cards: [CARD_TYPES.note, CARD_TYPES.note],
  },
  [INFOS.gettingStarted]: {
    sections: [
      { info: GETTING_STARTED.aboutPlatform },
      { info: GETTING_STARTED.navigatePlatform, cards: [CARD_TYPES.note] },
      { info: GETTING_STARTED.homeScreen },
      { info: GETTING_STARTED.viewPatient, cards: [CARD_TYPES.note] },
      { info: GETTING_STARTED.uploadData, cards: [CARD_TYPES.note] },
      { info: GETTING_STARTED.printDownloadReports, cards: [CARD_TYPES.note] },
      {
        info: GETTING_STARTED.remotePatientMonitoring,
        cards: [CARD_TYPES.note],
      },
      {
        info: GETTING_STARTED.electronicMedicalRecord,
        cards: [CARD_TYPES.note],
      },
      { info: GETTING_STARTED.patientCareTracking, cards: [CARD_TYPES.note] },
    ],
  },
};
