// tslint:disable
import { DateTime } from 'luxon';
import { times } from 'ramda';
import { randomValue } from 'src/utils';

export const mock = {
  information: {
    version: '1.0', //version of the JSON specs
    generatedby: {
      name: 'SmartLife', //name assigned to the source of the info
      version: '1.0', //version of source of the information
    },
  },
  patientId: 'patient uuid',
  hasRoleFhir: false,
  device: [
    {
      description: {
        id: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f', // unique device Id e.g. uuid (roche)
        sourceDeviceId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f', //the device id from source. Format is just ilustrative it is not necessary UUID. String type should be considered.
        type: 'cgm transmitter', // enum type: cgm transmitter | smartphone app | meter | pump
        serialnumber: '4564214', //optional
        name: 'CGM Eversence', //optional
      },
      data: [
        {
          type: 'cgm session', // same session can have several sequences
          startTime: '2019-12-06T00:00:00Z', //ISO8601
          endTime: '2019-12-16T00:17:00Z', //ISO8601
          sensor: {
            id: '12345',
            location: 'subcutaneous', // enum type: subcutaneous | undetermined | other
          },
          header: [
            {
              type: 'timestamp',
            },
            {
              type: 'glucoseValue',
              unit: 'mg/dL', //Based on UCUM
              code: '41653-7', //Based on standard terminology or OMOP
              codesystem: 'https://loinc.org', // standard terminology system identifier
            },
            {
              type: 'rateOfChange',
              unit: '(mg/dL)/min', //Based on UCUM
            },
            {
              type: 'quality',
              unit: 'percentage', //Based on UCUM
            },
          ],
          sequences: [
            {
              interval: 5,
              intervalUnit: 'min', //Based on UCUM
              data: [
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:00:00Z', // ISO8601
                  glucoseValue: 300,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:05:00Z', // ISO8601
                  glucoseValue: 244,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:10:00Z', // ISO8601
                  glucoseValue: 241,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:15:00Z', // ISO8601
                  glucoseValue: 150,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:20:00Z', // ISO8601
                  glucoseValue: 122,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:25:00Z', // ISO8601
                  glucoseValue: 300,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:30:00Z', // ISO8601
                  glucoseValue: 244,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:35:00Z', // ISO8601
                  glucoseValue: 241,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:40:00Z', // ISO8601
                  glucoseValue: 150,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T00:55:00Z', // ISO8601
                  glucoseValue: 122,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T01:05:00Z', // ISO8601
                  glucoseValue: 300,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T01:10:00Z', // ISO8601
                  glucoseValue: 244,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T01:30:00Z', // ISO8601
                  glucoseValue: 241,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T01:35:00Z', // ISO8601
                  glucoseValue: 150,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '8bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T01:40:00Z', // ISO8601
                  glucoseValue: 122,
                  rateOfChange: 1.2,
                  quality: 100,
                },
                {
                  sourceMeasurementId: '6bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T01:50:00Z',
                  glucoseValue: 96,
                  rateOfChange: 2.0,
                  quality: 99,
                },
                {
                  sourceMeasurementId: '5bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-06T01:55:00Z',
                  glucoseValue: 95,
                  rateOfChange: 2.2,
                  quality: 89,
                },
                {
                  sourceMeasurementId: '4bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-07T03:00:00Z',
                  glucoseValue: 88,
                  rateOfChange: 2.2,
                  quality: 89,
                },
                {
                  sourceMeasurementId: '5bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-07T22:05:00Z',
                  glucoseValue: 81,
                  rateOfChange: 2.2,
                  quality: 89,
                },
                {
                  sourceMeasurementId: '4bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-08T00:05:00Z',
                  glucoseValue: 80,
                  rateOfChange: 2.2,
                  quality: 89,
                },
                {
                  sourceMeasurementId: '4bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-07T03:15:00Z',
                  glucoseValue: 75,
                  rateOfChange: 2.2,
                  quality: 89,
                },
                {
                  sourceMeasurementId: '5bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-07T22:25:00Z',
                  glucoseValue: 71,
                  rateOfChange: 2.2,
                  quality: 89,
                },
                {
                  sourceMeasurementId: '4bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-08T00:45:00Z',
                  glucoseValue: 70,
                  rateOfChange: 2.2,
                  quality: 89,
                },
                {
                  sourceMeasurementId: '4bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-07T04:25:00Z',
                  glucoseValue: 60,
                  rateOfChange: 2.2,
                  quality: 89,
                },
                {
                  sourceMeasurementId: '5bb48a2a7-73a7-452d-a0c5-d6a294e5d68f',
                  timestamp: '2019-12-07T21:10:00Z',
                  glucoseValue: 56,
                  rateOfChange: 2.2,
                  quality: 89,
                },
              ],
            },
          ],
          calibration: [
            {
              timestamp: '2019-12-01T09:00:00Z',
              value: 99, // same value defined on header glucoseValue
              action: 'accepted', // enum type: accepted | rejected
              sourceDeviceId: 'the calibration device id',
              process: 'auto', // manual | auto
            },
            {
              timestamp: '2019-12-01T09:15:00Z',
              value: 106, // same value defined on header glucoseValue
              action: 'rejected', // enum type: accepted | rejected
              sourceDeviceId: 'the calibration device id',
              process: 'auto', // manual | auto
            },
          ],
          events: [
            {
              timestamp: '2019-12-01T09:01:00Z',
              type: 'EMWR',
              code: 'E-100', // enum type; for this example, M-80 | E-100
              action: 'snoozed', // enum type; 'acknowledged | missed | snoozed | suppresed | other'
            },
            {
              timestamp: '2019-12-01T09:02:00Z',
              type: 'EMWR',
              code: 'M-80',
              action: 'acknowledged', // enum type; 'acknowledged | missed | snoozed | suppresed | other'
            },
          ],
        },
      ],
    },
  ],
};

let dt = DateTime.fromObject({ year: 2020, month: 2, day: 22, hour: 0 });
const intervals = [5, 10, 15];

export const dynamicMock = (() => {
  return {
    patientId: 'patient uuid',
    hasRoleFhir: false,
    device: times(
      () => ({
        data: times(
          () => ({
            sequences: times(
              () => ({
                interval:
                // @ts-ignore
                  intervals[Math.floor(randomValue(intervals.length))],
                intervalUnit: 'min',
                data: (() => {
                  const arr = Array.apply(null, {
                    // @ts-ignore
                    length: Math.round(randomValue(200)),
                  }).map(function () {
                    return null;
                  });
                  return arr.map((value, i) => {
                    dt = dt.plus({ minutes: 5 });
                    return {
                      timestamp: dt.toISO(),
                      // @ts-ignore
                      glucoseValue: Math.round(randomValue(250)),
                    };
                  });
                })(),
              }),
              // @ts-ignore
              Math.round(randomValue(10)),
            ),
          }),
          1,
        ),
      }),
      2,
    ),
  };
})();

//////////////////////// MOCK PER DAYS /////////////////////////////////////////
let numDias = 7;
let startDate = DateTime.fromObject({
  year: 2020,
  month: 2,
  day: 22,
  hour: 23,
  minute: 20,
});
let numDevices = 2;
let numSequences = 8;
export const dynamicMock2 = (() => {
  let intervalRandom = 0;

  return {
    patientId: 'patient uuid',
    hasRoleFhir: false,
    device: times(
      () => ({
        data: times(
          () => ({
            sequences: times(
              () => ({
                interval: (() => {
                  intervalRandom =
                  // @ts-ignore
                    intervals[Math.floor(randomValue(intervals.length))];
                  return intervalRandom;
                })(),
                intervalUnit: 'min',
                data: (() => {
                  const arr = Array.apply(null, {
                    length:
                      ((60 / intervalRandom) * 24 * numDias) /
                      numSequences /
                      numDevices,
                  }).map(function () {
                    return null;
                  });
                  return arr.map((value, i) => {
                    startDate = startDate.plus({ minutes: intervalRandom });
                    return {
                      timestamp: startDate.toISO(),
                      // @ts-ignore
                      glucoseValue: Math.round(randomValue(250)),
                    };
                  });
                })(),
              }),
              numSequences,
            ),
          }),
          1,
        ),
      }),
      numDevices,
    ),
  };
})();
//////////////////////// MOCK PER DAYS WITH VALUE RANGE PER HOUR ///////////////

numDias = 28;
startDate = DateTime.fromObject({
  year: 2019,
  month: 11,
  day: 20,
  hour: 23,
  minute: 30,
});
numDevices = 2;
numSequences = 8;

function getRandomInt(min, max) {
  // @ts-ignore
  return Math.floor(randomValue(1) * (max - min)) + min;
}
function glucoseValue(hour: number) {
  let value = 0;
  if (hour === 0) {
    value = getRandomInt(45, 210);
  } else if (hour === 1) {
    value = getRandomInt(35, 190);
  } else if (hour === 2) {
    value = getRandomInt(20, 180);
  } else if (hour === 3) {
    value = getRandomInt(0, 150);
  } else if (hour === 4) {
    value = getRandomInt(0, 155);
  } else if (hour === 5) {
    value = getRandomInt(0, 160);
  } else if (hour === 6) {
    value = getRandomInt(15, 170);
  } else if (hour === 7) {
    value = getRandomInt(20, 220);
  } else if (hour === 8) {
    value = getRandomInt(30, 390);
  } else if (hour === 9) {
    value = getRandomInt(70, 400);
  } else if (hour === 10) {
    value = getRandomInt(30, 385);
  } else if (hour === 11) {
    value = getRandomInt(60, 280);
  } else if (hour === 12) {
    value = getRandomInt(20, 245);
  } else if (hour === 13) {
    value = getRandomInt(20, 200);
  } else if (hour === 14) {
    value = getRandomInt(30, 200);
  } else if (hour === 15) {
    value = getRandomInt(30, 190);
  } else if (hour === 16) {
    value = getRandomInt(40, 210);
  } else if (hour === 17) {
    value = getRandomInt(50, 290);
  } else if (hour === 18) {
    value = getRandomInt(60, 310);
  } else if (hour === 19) {
    value = getRandomInt(45, 290);
  } else if (hour === 20) {
    value = getRandomInt(35, 210);
  } else if (hour === 21) {
    value = getRandomInt(30, 215);
  } else if (hour === 22) {
    value = getRandomInt(25, 200);
  } else if (hour === 23) {
    value = getRandomInt(30, 200);
  }
  return value;
}

export const dynamicMock3 = (() => {
  let intervalRandom = 0;

  return {
    patientId: 'patient uuid',
    hasRoleFhir: false,
    device: times(
      () => ({
        data: times(
          () => ({
            sequences: times(
              () => ({
                interval: (() => {
                  intervalRandom =
                  // @ts-ignore
                    intervals[Math.floor(randomValue(intervals.length))];
                  return intervalRandom;
                })(),
                intervalUnit: 'min',
                data: (() => {
                  const arr = Array.apply(null, {
                    length:
                      ((60 / intervalRandom) * 24 * numDias) /
                      numSequences /
                      numDevices,
                  }).map(function () {
                    return null;
                  });
                  return arr.map(() => {
                    startDate = startDate.plus({ minutes: intervalRandom });
                    return {
                      timestamp: startDate.toISO(),
                      glucoseValue: glucoseValue(startDate.get('hour')),
                    };
                  });
                })(),
              }),
              numSequences,
            ),
          }),
          1,
        ),
      }),
      numDevices,
    ),
  };
})();
