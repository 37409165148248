import styled from 'styled-components';

import { weight } from 'src/domains/diagnostics/components/fonts';
import { convertPxToRem } from 'src/utils';
import { colors } from 'src/core/styles';
import { GRAPH_LEGEND_TYPES } from 'src/domains/diagnostics/components/graph-legend/graph-legend.constant.js';

const customMedium = '1024px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) =>
    `${spacing.two} ${spacing.two} 0 ${spacing.two}`};
  height: 100%;
  font-size: ${({ theme }) => convertPxToRem(theme.fontSizes[1])};
  margin-bottom: 5px;
  @media (max-width: ${customMedium}) {
    font-size: 12px;
    padding: 0px 0px 3px 0px;
  }
`;
Container.displayName = 'Container';

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: ${weight.bold};
  padding: ${(props) =>
    props.graphType === GRAPH_LEGEND_TYPES.CGM_DAILY_GLUCOSE
      ? '4px 16px 4px 44px'
      : ({ theme: { spacing } }) => `${spacing.one} ${spacing.three}`};
  margin-bottom: ${({ theme: { spacing } }) => spacing.one};
  font-size: ${({ theme }) => convertPxToRem(theme.fontSizes[3])};
`;
Title.displayName = 'Title';

export const LegendListsContainer = styled.div`
  display: flex;
  height: 100%;
`;
LegendListsContainer.displayName = 'LegendListsContainer';

export const LegendList = styled.ul`
  list-style: none;
  border-right: ${(props) =>
    props.noBorder
      ? 'none'
      : `0.0625rem solid ${props.theme.colors.grayLight}`};
  width: ${(props) => (props.width ? props.width : '20%')};
  margin: 0;
  padding: 0 ${({ theme: { spacing } }) => `${spacing.three} ${spacing.two}`};
  @media (max-width: ${customMedium}) {
    font-size: 12px;
    padding: 0px 2px 2px 5px;
  }
`;
LegendList.displayName = 'LegendList';

export const CgmLegendList = styled.ul`
  list-style: none;
  border-right: ${(props) => `0.0625rem solid ${props.theme.colors.grayLight}`};
  margin: 0 0 14px 0;
  padding: 0;
  @media (max-width: ${customMedium}) {
    font-size: 12px;
    padding: 0px 2px 2px 5px;
  }

  &.targets {
    width: 30%;
    padding: 0 16px 8px 44px;
  }

  &.bolus {
    width: 30%;
    padding: 0 16px 8px 49px;
  }

  &.basal {
    width: 40%;
    padding: 0 16px 8px 41px;
    border-right: none;
  }
`;
CgmLegendList.displayName = 'CgmLegendList';

export const LegendListSectionHeader = styled.div`
  color: ${({ theme }) => theme.colors.grayMedium};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.semiBold};
  padding: ${({ theme }) => theme.spacing.two} 0;
  text-transform: uppercase;
  line-height: 1.08;
  letter-spacing: ${convertPxToRem(1.6)};
  margin-bottom: 0.25rem;
  @media (max-width: ${customMedium}) {
    font-size: 10px;
    line-height: 16px;
  }
`;
LegendListSectionHeader.displayName = 'LegendListSectionHeader';

export const RotatedIconContainer = styled.div`
  display: inline-block;
  transform: rotate(${(props) => `${props.rotationAngle}deg`});
`;
RotatedIconContainer.displayName = 'RotatedIconContainer';

export const Bagde = styled.div`
  font-size: 12px;
  min-width: 75px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: ${colors.black};
  font-weight: bold;
  height: fit-content;
  &.orange {
    background-color: var(--jello-color-glucose-5-very-high);
    padding-top: 4px;
  }
  &.yellow {
    background-color: var(--jello-color-glucose-5-high);
  }
  &.green {
    background-color: var(--jello-color-glucose-5-target);
  }
  &.red {
    background-color: var(--jello-color-glucose-5-low);
  }
  &.darkRed {
    background-color: var(--jello-color-glucose-5-very-low);
    color: ${colors.white};
  }
`;

Bagde.displayName = 'Bagde';
export const LegendLineData = styled.div`
  width: 100%;
  display: inline-flex;
  margin-bottom: 0.5rem;
  align-items: center;
  &:last-of-type {
    margin-bottom: none;
  }
`;

LegendLineData.displayName = 'LegendLineData';

export const LegendColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column
  margin-bottom: 0.5rem;
  &:last-of-type {
    margin-bottom: none;
  }
`;

LegendColumn.displayName = 'LegendColumn';

export const LegendColumnData = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  &:last-of-type {
    margin-bottom: none;
  }
  @media (max-width: ${customMedium}) {
    padding-left: 5px;
  }
`;

LegendColumnData.displayName = 'LegendColumnData';
