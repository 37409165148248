import * as React from 'react';

export const Consent = () => {
  const RocheLogo = require('src/assets/images/roche-logo.svg');
  return (
    <div
      style={{
        position: 'relative',
        textAlign: 'right',
        margin: '40px 40px',
      }}
    >
      <img src={RocheLogo} alt="RocheDiabetes" />
    </div>
  );
};
