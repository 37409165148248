import * as React from 'react';

import { RenderIf } from 'src/utils';
import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  SubTitle,
  SectionCheckBoxes,
  ItemCheckbox,
} from '../time-tracker-modal/time-tracker-modal.style';
import { Checkbox } from 'src/components-ds/checkbox/checkbox.component';
import {
  PatientListKey,
  PatientListItem,
} from '../time-tracker-modal/time-tracker-modal.types';

export type SectionColumnProps = {
  patientListData: PatientListItem[];
  patientListId: PatientListKey;
  subtitle?: string;
  t: (prop: string) => string;
  onCheckboxClick: (patientListId, item) => void;
};

export const SectionColumnComponent = ({
  patientListData,
  patientListId,
  subtitle,
  onCheckboxClick,
  t,
}: SectionColumnProps) => (
  <SectionCheckBoxes>
    <RenderIf validate={subtitle}>
      <SubTitle>{subtitle}</SubTitle>
    </RenderIf>
    {patientListData.map((item: PatientListItem) => (
      <ItemCheckbox key={item.id}>
        <Checkbox
          id={item.id}
          onChange={() => {
            onCheckboxClick(patientListId, item);
          }}
          defaultChecked={false}
          label={t(item.label)}
        />
      </ItemCheckbox>
    ))}
  </SectionCheckBoxes>
);

export const SectionColumn = withTranslation(SectionColumnComponent);
