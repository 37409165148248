import {
  CheckDataSharingConsentActionType,
  CheckDataSharingErrorAction,
  CheckDataSharingStartAction,
} from './check-data-sharing-consent.types';
import { createPayloadAction } from 'src/app/store/app.actions';
import {
  ConsentSuccessPayload,
  ConsentErrorPayload,
} from 'src/services/patient/check-data-sharing-consent/check-data-sharing-consent.types';

export const checkDataSharingConsentStart = (
  payload: string,
): CheckDataSharingStartAction => {
  return createPayloadAction(
    CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_START,
    payload,
  );
};

export const checkDataSharingConsentSuccess = (
  payload: ConsentSuccessPayload,
): ConsentSuccessPayload => {
  return createPayloadAction(
    CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_SUCCESS,
    payload,
  );
};

export const checkDataSharingConsentError = (
  payload: ConsentErrorPayload,
): CheckDataSharingErrorAction =>
  createPayloadAction(
    CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_ERROR,
    payload,
  );
