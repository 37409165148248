/* tslint:disable */

import { Observable } from 'rxjs';
import { Epic } from 'redux-observable';
import {
  selectAccessToken,
  selectGigyaToken,
} from 'src/app/session';
import { catchError } from 'rxjs/operators';
import {
  fetchSettingsListSuccess,
  fetchSettingsListError,
} from './device-settings-list.action';
import { FETCH_DEVICE_SETTINGS_LIST_REQUEST } from './device-settings-list.constant';
import { State } from 'src/app/store/app.types';
import { fetchSettingsStart } from '../device-settings/device-settings.action';

export const fetchDeviceSettingsListEpic: (
  deviceSettingsListService,
) => Epic<any, State> = (deviceSettingsListService) => (action$, store) =>
  action$
    .ofType(FETCH_DEVICE_SETTINGS_LIST_REQUEST.START)
    .switchMap((action) => {
      const settingsParams = action.payload;
      const apiKey = selectGigyaToken(store.getState());
      return Observable.fromPromise(
        deviceSettingsListService(
          settingsParams,
          selectAccessToken(store.getState()),
          apiKey,
        ),
      )
        .mergeMap((deviceSettingsList: any): any => [
          fetchSettingsListSuccess(deviceSettingsList),
          fetchSettingsStart({
            deviceSerialNumber: action.payload.deviceSerialNumber,
            patientId: action.payload.patientId,
            patientType: 'PWD',
            includePrevious: true,
            version: deviceSettingsList[0].version,
          }),
        ])
        .pipe(
          catchError((err) => {
            if (err.status === 404) {
              return Observable.of(fetchSettingsListSuccess([]));
            }
            return Observable.of(fetchSettingsListError());
          }),
        );
    });
