import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { SvgIcon } from 'src/components/icon';

const HyperWarningIconComponent = ({ height, width, t }) => {
  const originalWidth = 18;
  const originalHeight = 16;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.hyperWarning')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="_icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="NEW-ICONS" transform="translate(-657.000000, -527.000000)">
          <g id="Group-4" transform="translate(657.000000, 527.000000)">
            <path
              d="M9.03670957,-1.10204082e-05 C8.47531455,-1.10204082e-05 7.96608793,0.278734776 7.67982181,0.764529796 C5.270873,4.87482686 2.84993838,8.98496522 0.433903224,13.0935938 C0.14756882,13.580806 0.146887758,14.1703758 0.433903224,14.6571252 C0.771542082,15.2295913 1.31053482,15.4299644 1.87344404,15.4285538 L16.1999749,15.4285538 C16.7631464,15.4307579 17.3019099,15.2295252 17.6395157,14.6571252 C17.9265995,14.1703758 17.9259163,13.580806 17.6395157,13.0935938 C15.2457684,8.97209339 12.7731018,4.89182033 10.3935973,0.764529796 C10.1073312,0.278734776 9.59810459,-1.10204082e-05 9.03670957,-1.10204082e-05 Z"
              id="Shape"
              fill="#F5A623"
              fillRule="nonzero"
            />
            <path
              d="M10.8119029,7.23733036 C10.9795015,7.23733036 11.1135783,7.28760918 11.2141375,7.38816835 C11.3146967,7.48872751 11.3649755,7.62280438 11.3649755,7.79040298 L11.3649755,11.8199321 C11.3649755,11.9875307 11.3146967,12.1228047 11.2141375,12.2257581 C11.1135783,12.3287116 10.9795015,12.3801875 10.8119029,12.3801875 C10.6443043,12.3801875 10.5114245,12.3299087 10.4132596,12.2293495 C10.3150947,12.1287903 10.266013,11.9923192 10.266013,11.8199321 L10.266013,10.2109936 L7.93161555,10.2109936 L7.93161555,11.8199321 C7.93161555,11.9875307 7.88133672,12.1228047 7.78077756,12.2257581 C7.6802184,12.3287116 7.54853576,12.3801875 7.38572569,12.3801875 C7.21812708,12.3801875 7.08405021,12.3299087 6.98349105,12.2293495 C6.88293189,12.1287903 6.83265306,11.9923192 6.83265306,11.8199321 L6.83265306,7.79040298 C6.83265306,7.62280438 6.88293189,7.48872751 6.98349105,7.38816835 C7.08405021,7.28760918 7.21812708,7.23733036 7.38572569,7.23733036 C7.55332429,7.23733036 7.68620405,7.28760918 7.78436894,7.38816835 C7.88253384,7.48872751 7.93161555,7.62280438 7.93161555,7.79040298 L7.93161555,9.32033116 L10.266013,9.32033116 L10.266013,7.79040298 C10.266013,7.62280438 10.3150947,7.48872751 10.4132596,7.38816835 C10.5114245,7.28760918 10.6443043,7.23733036 10.8119029,7.23733036 Z"
              id="H"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const HyperWarningIcon = withTranslation(HyperWarningIconComponent);
