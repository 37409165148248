import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation as translateHOC } from 'react-i18next';
import { createModal, destroyModal } from 'src/core/modal';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { withToken } from 'src/utils/with-token';
import { withPatientSummary } from 'src/domains/patient/utils/with-patient-summary';
import { NextShipmentComponent } from './next-shipment.component';
import {
  fetchNextShipment,
  clearNextShipment,
  changeStatusNextShipment,
  changeDateNextShipment,
  nextShipmentConnector,
} from './store';
import { NextShipmentLifeCycleProps } from './next-shipment.types';
import { addLocalFormDispatchHandlers } from 'src/utils/forms';

const dispatchers = mapDispatchers({
  createModal,
  destroyModal,
  fetchNextShipment: fetchNextShipment.start,
  clearNextShipment: clearNextShipment.success,
  changeStatusNextShipment: changeStatusNextShipment.start,
  changeDateNextShipment: changeDateNextShipment.start,
});

export const NextShipment = compose(
  withToken,
  connect(nextShipmentConnector, dispatchers),
  addLocalFormDispatchHandlers,
  withPatientSummary,
  lifecycle<NextShipmentLifeCycleProps, void>({
    componentWillMount() {
      const {
        fetchNextShipment,
        match: { params },
      } = this.props;
      fetchNextShipment(params.id);
    },
    componentWillUnmount() {
      this.props.clearNextShipment();
    },
  }),
  translateHOC('translations'),
)(NextShipmentComponent);
