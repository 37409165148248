import { createSelector, createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { isNil, isEmpty } from 'ramda';

import { selectEC6UserUnitMeasurement } from 'src/core/user/user.selectors';
import { average, fixToDecimalPlace } from 'src/utils';
import { getFormattedStandardDeviation } from 'src/domains/diagnostics/utils';
import {
  selectGlucoseMeasurementsInDateSliderRange,
  selectGlobalDatesAverageTestsPerDay,
  selectGraphThreshold,
  selectIsLoading,
  selectBloodGlucoseUnit,
  selectNumberOfDaysInDateSliderRange,
} from 'src/domains/diagnostics/store/selectors';
import {
  BLOOD_GLUCOSE_UNITS,
  EMPTY_VALUE_PLACEHOLDER,
} from 'src/domains/diagnostics/store/constants';
import {
  calculateHypoRisk,
  hasReliableInfo,
  selectDayInHalfTimeIntervals,
  TRAFFIC_LIGHT_LABELS,
} from 'src/domains/diagnostics/scenes/blood-glucose-overview/store';
import { MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS } from 'src/domains/diagnostics/scenes/blood-glucose-overview/store/blood-glucose-overview.constants';

import {
  toMeanStatus,
  toVariabilityStatus,
  toHypoRiskStatus,
} from './status-card.util';

const getMeasurementValues = (measurements) =>
  measurements.map((measurement) => measurement.value);
const filterNullValues = (measurements) =>
  measurements.filter((measurement) => !isNil(measurement));
const calculateMean = (measurements) =>
  fixToDecimalPlace(average(measurements), 1);

const calculateVariability = (measurements, mean, bloodGlucoseUnit) =>
  getFormattedStandardDeviation(measurements, (standardDeviation) => {
    const fixedMean =
      bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
        ? fixToDecimalPlace(mean, 1)
        : mean.toFixed();
    const fixedSD =
      bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
        ? fixToDecimalPlace(standardDeviation, 1)
        : standardDeviation.toFixed();
    return fixToDecimalPlace((fixedSD / fixedMean) * 100, 1);
  });

const toPercentageSuffix = (value) => `${value}%`;

const selectGlucoseMeasurementValues = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  compose(filterNullValues, getMeasurementValues),
);

const selectNumberOfMeasurements = createSelector(
  selectGlucoseMeasurementValues,
  (measurements) => measurements.length,
);

export const selectHasReliableInfo = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectGlobalDatesAverageTestsPerDay,
  selectDayInHalfTimeIntervals,
  selectNumberOfDaysInDateSliderRange,
  hasReliableInfo,
);

const selectMeanBloodGlucoseValue = createSelector(
  selectGlucoseMeasurementValues,
  calculateMean,
);

export const selectVariabilityValue = createSelector(
  selectGlucoseMeasurementValues,
  selectMeanBloodGlucoseValue,
  selectEC6UserUnitMeasurement,
  calculateVariability,
);

const selectHypoRiskValue = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectBloodGlucoseUnit,
  calculateHypoRisk,
);

export const selectMeanBloodGlucoseStatus = createSelector(
  selectNumberOfMeasurements,
  selectMeanBloodGlucoseValue,
  selectGraphThreshold,
  toMeanStatus,
);

const selectVariabilityStatus = createSelector(
  selectVariabilityValue,
  selectNumberOfMeasurements,
  toVariabilityStatus,
);

const selectHypoRiskStatus = createSelector(
  selectHypoRiskValue,
  selectHasReliableInfo,
  toHypoRiskStatus,
);

const selectHypoRisk = createStructuredSelector({
  value: selectHypoRiskValue,
  status: selectHypoRiskStatus,
});

export const selectMeanBloodGlucose = createStructuredSelector({
  value: selectMeanBloodGlucoseValue,
  status: selectMeanBloodGlucoseStatus,
});

export const selectVariability = createStructuredSelector({
  value: compose(toPercentageSuffix, selectVariabilityValue),
  valueWithoutUnit: selectVariabilityValue,
  status: selectVariabilityStatus,
});

const selectPlaceholderStatusLabel = createSelector(
  selectNumberOfMeasurements,
  (numberOfMeasurements) => {
    if (numberOfMeasurements === 0) {
      return EMPTY_VALUE_PLACEHOLDER;
    }

    if (numberOfMeasurements === 1) {
      return TRAFFIC_LIGHT_LABELS.INSUFFICIENT_INFO;
    }

    return '';
  },
);

export const selectHasSufficiencyToStatisticsCalculation = createSelector(
  selectNumberOfMeasurements,
  (numberOfMeasurements) =>
    numberOfMeasurements >= MINIMUM_MEASUREMENTS_TO_CALCULATE_STATISTICS,
);

const selectShowPlaceholderStatusLabelAndHideValues = createSelector(
  selectPlaceholderStatusLabel,
  (placeholderLabel) => placeholderLabel !== '',
);

export const selectHasData = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectIsLoading,
  (measurements, isLoading) => isLoading || !isEmpty(measurements),
);

export const StatusCardConnector = createStructuredSelector({
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  hasReliableInfo: selectHasReliableInfo,
  hypoRisk: selectHypoRisk,
  meanBloodGlucose: selectMeanBloodGlucose,
  placeholderStatusLabel: selectPlaceholderStatusLabel,
  showPlaceholderStatusLabelAndHideValues:
    selectShowPlaceholderStatusLabelAndHideValues,
  showStatusLabels: selectHasSufficiencyToStatisticsCalculation,
  threshold: selectGraphThreshold,
  variability: selectVariability,
  hasData: selectHasData,
  isLoading: selectIsLoading,
});
