import { dissoc, not } from 'ramda';
import { convertISO, ensureNever, toFormat } from 'src/utils';
import { CreatePatientActionType } from 'src/widgets/patient/create-patient/store/create-patient.types';

import {
  DeviceAssignmentActionType,
  DeviceAssignmentReducerActions,
  DeviceAssignmentState,
} from './device-assignment.types';

const STATE_BEFORE_PATIENT_SELECTION = {
  hasConfirmedDevice: true,
  selectedPatientId: undefined,
  newPatient: undefined,
  displayCreatePatientView: false,
};

export const INITIAL_DEVICE_ASSIGNMENT_STATE: DeviceAssignmentState = {
  isComplete: false,
  associationId: undefined,
  hasConfirmedDevice: false,
  deviceInfo: undefined,
  associatedPatientId: undefined,
  associatedPatient: undefined,
  selectedPatientId: undefined,
  displayCreatePatientView: false,

  isFetchingAssociation: false,
  isUpdatingAssociation: false,
  associationError: undefined,
  updatingError: undefined,
  newPatient: undefined,

  fullName: '',
};

export const deviceAssignmentReducer = (
  state = INITIAL_DEVICE_ASSIGNMENT_STATE,
  action: DeviceAssignmentReducerActions,
): DeviceAssignmentState => {
  switch (action.type) {
    case DeviceAssignmentActionType.SELECT_PATIENT:
      return {
        ...state,
        selectedPatientId: action.payload,
      };
    case DeviceAssignmentActionType.DESELECT_PATIENT:
      return {
        ...state,
        ...STATE_BEFORE_PATIENT_SELECTION,
      };

    case DeviceAssignmentActionType.GET_DEVICE_ASSOCIATION_START:
      return {
        ...state,
        isFetchingAssociation: true,
        associationId: action.payload.associationId,
      };
    case DeviceAssignmentActionType.GET_DEVICE_ASSOCIATION_SUCCESS:
      return {
        ...state,
        associatedPatientId: action.payload.patientId,
        deviceInfo: action.payload.deviceInfo,
        isFetchingAssociation: false,
      };
    case DeviceAssignmentActionType.GET_DEVICE_ASSOCIATION_ERROR:
      return {
        ...state,
        associationError: action.payload,
      };
    case DeviceAssignmentActionType.UPDATE_DEVICE_ASSOCIATION_START:
      return {
        ...state,
        isUpdatingAssociation: true,
      };
    case DeviceAssignmentActionType.UPDATE_DEVICE_ASSOCIATION_ERROR:
      return {
        ...state,
        updatingError: true,
        isUpdatingAssociation: false,
      };
    case DeviceAssignmentActionType.UPDATE_DEVICE_ASSOCIATION_SUCCESS:
      return {
        ...state,
        isUpdatingAssociation: false,
        isComplete: true,
      };
    case DeviceAssignmentActionType.GET_ALREADY_ASSIGNED_PATIENT_SUCCESS:
      return {
        ...state,
        associatedPatient: action.payload,
      };
    case DeviceAssignmentActionType.RESET_ASSIGNMENT:
      return INITIAL_DEVICE_ASSIGNMENT_STATE;
    case DeviceAssignmentActionType.CONFIRM_DEVICE:
      return {
        ...state,
        hasConfirmedDevice: true,
      };
    case DeviceAssignmentActionType.TOGGLE_CREATE_PATIENT_VIEW:
      return {
        ...state,
        displayCreatePatientView: not(state.displayCreatePatientView),
      };
    case DeviceAssignmentActionType.CANCEL_CONFIRMED_DEVICE:
      return {
        ...state,
        hasConfirmedDevice: false,
      };
    case CreatePatientActionType.GET_CREATED_PATIENT_SUCCESS: {
      const newPatient = dissoc('dateOfBirth', {
        ...action.payload,
        birthDate: toFormat('dd/LL/yyyy')(
          convertISO(action.payload.dateOfBirth),
        ),
      });
      return {
        ...state,
        newPatient,
      };
    }
    default:
      ensureNever(action);
      return state;
  }
};
