import { connect } from 'react-redux';
import { SidebarmenuJelloComponent } from './sidebar-menu-jello.component';
import { mapDispatchers } from 'src/utils';
import { createStructuredSelector } from 'reselect';
import { push } from 'react-router-redux';
import { clearPatientDateRange } from 'src/core/patient-date-range/patient-date-range.action';
import { clearCgmDateRange } from 'src/domains/patient-dashboards/cgm/store/cgm.actions';
import {
  deviceListContainsActivedDevice,
  hasPatientCGMAssociation,
} from 'src/domains/patient-dashboards/patient-dashboard-jello/components/patient-data-bar-jello/patient-data-bar-jello.utils';
import { DevicesTypes } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/patient-data-bar-jello/patient-data-bar.constants';
import {
  PATIENT_PERMISSIONS as PP,
  PERMISSIONS as P,
} from 'src/core/permissions/permissions.constants';
import {
  ADMINISTRATIVE,
  GENERAL_PRACTITIONER,
} from 'src/core/user/user.constants';

const mapStateToProps = (_state, props) => {
  const {
    activeDevices,
    cgmSummary,
    permissions,
    patientPermissions,
    profile,
  } = props;
  return {
    disabled: createStructuredSelector({
      bgm: () => false,
      cgm: () =>
        !(
          deviceListContainsActivedDevice([DevicesTypes.Cgm], activeDevices) ||
          hasPatientCGMAssociation({ ...cgmSummary })
        ),
      patterns: () => false,
      stripManagement: () =>
        !(
          patientPermissions.includes(PP.STRIP_MNG_PATIENT) ||
          patientPermissions.includes(PP.HOME_DELIVERY_PATIENT)
        ),
      patientDevices: () => activeDevices.length === 0,
      editPatient: () => false,
    }),
    hidden: createStructuredSelector({
      bgm: () => profile === ADMINISTRATIVE,
      cgm: () => profile === ADMINISTRATIVE,
      patterns: () =>
        profile === ADMINISTRATIVE || profile === GENERAL_PRACTITIONER,
      stripManagement: () =>
        !(
          permissions.includes(P.STRIP_MNG_PROFESSIONAL) ||
          permissions.includes(P.DELIVERY_PROFESSIONAL)
        ),
      patientDevices: () =>
        profile === ADMINISTRATIVE || profile === GENERAL_PRACTITIONER,
      editPatient: () => false,
    }),
    isPatientStrip: patientPermissions.includes(PP.STRIP_MNG_PATIENT),
    isPatientHomeDelivery: patientPermissions.includes(
      PP.HOME_DELIVERY_PATIENT,
    ),
  };
};

const mapDispatchToProps = mapDispatchers({
  goTo: (path) => push(path),
  onClearBgDateRange: clearPatientDateRange,
  onClearCgmDateRange: clearCgmDateRange,
});

export const SidebarMenuComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarmenuJelloComponent);
