import styled from 'styled-components';

import { colors, fontSize, fontWeights, spacing } from 'src/core';

import { FlexContainer, FlexItem } from './flex-grid';

const newBreakPointMedium = '1680px';
export const LegendContainer = styled.div`
  @media (max-width: ${newBreakPointMedium}) {
    width: 100%;
  }
`;

export const TitleDeviceSettings = styled.div`
  color: ${colors.charcoal};
  font-size: ${fontSize.headline};
  font-weight: ${fontWeights.semiBold};
  letter-spacing: 0;
  line-height: 2rem;
`;

export const HeaderItemDeviceSettings = styled(FlexItem)`
  max-width: 300px;

  @media (max-width: ${newBreakPointMedium}) {
    padding: 0 1rem;
  }
`;

export const HeaderLegendContainer = styled(HeaderItemDeviceSettings)`
  font-size: ${fontSize.caption};
  line-height: ${fontSize.p};

  @media (max-width: ${newBreakPointMedium}) {
    width: 100%;
    max-width: inherit;
    flex-direction: column-reverse;
  }
`;

export const HeaderRowDeviceSettings = styled(FlexContainer)`
  padding: ${(props) => (props.expanded ? '0 2rem' : props.expanded)};

  @media (max-width: ${newBreakPointMedium}) {
    flex-direction: ${(props) => (props.expanded ? 'row' : 'column')};
    align-items: flex-start;
    padding: ${(props) => (props.expanded ? '0 1rem' : props.expanded)};
  }
`;

export const HeaderColLeftDeviceSettings = styled(FlexContainer)`
  width: 31%;
  @media (max-width: ${newBreakPointMedium}) {
    order: ${(props) => (props.expanded ? '0' : '1')};
    margin-top: ${spacing.three};
  }
`;

export const HeaderColRightDeviceSettings = styled(FlexContainer)`
  width: 70%;
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: ${newBreakPointMedium}) {
    justify-content: ${(props) => (props.expanded ? 'flex-end' : 'flex-start')};
    width: ${(props) => (props.expanded ? '76%' : '100%')};
  }
`;

export const HeaderSubRowDeviceSettings = styled(FlexContainer)`
  margin-top: 1rem;
  padding: 0 2rem;
  @media (max-width: ${newBreakPointMedium}) {
    padding: 0 1rem;
  }
`;

export const HeaderSubColumnDeviceSettings = styled(FlexContainer)``;

export const HeaderTextDeviceSettings = styled.div`
  color: ${colors.grayDark};
  font-size: ${fontSize.caption};
  font-weight: ${fontWeights.semiBold};
  letter-spacing: 0;
  line-height: 1rem;
  text-align: right;
`;

export const SeparatorDeviceSettings = styled.div`
  width: 1px;
  height: 40px;
  margin: 0 -1px;
  background-color: ${colors.grayLighter};
`;
