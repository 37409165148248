import { pathOr } from 'ramda';

import { CIAM_SDK_NAMESPACE } from 'src/app/session';
import { selectPatientGigyaUid } from 'src/core/patient/patient.selector';

import { selectPlatformPermission } from 'src/core/permissions';
import { selectPatientFhirId } from 'src/core/patient/patient.selector';

import {
  fetchPermissions,
  fetchPatientPermissions,
} from './permissions.actions';
import {
  FETCH_PERMISSIONS,
  FETCH_PATIENT_PERMISSIONS,
} from './permissions.constants';

import { errorMessageRedirect } from './permissions.utils';

export const permissionsEpic = (permissionsService) => (action$, store$) =>
  action$.ofType(FETCH_PERMISSIONS.START).flatMap((action) =>
    permissionsService(
      action.payload,
      pathOr(
        '',
        [CIAM_SDK_NAMESPACE, 'session', 'accessToken'],
        store$.getState(),
      ),
      pathOr(
        '',
        [CIAM_SDK_NAMESPACE, 'config', 'gigyaToken'],
        store$.getState(),
      ),
    )
      .then((data) => fetchPermissions.success(data, action))
      .catch((error) => fetchPermissions.error(error)),
  );

export const patientPermissionsEpic =
  (permissionsService) => (action$, store$) =>
    action$
      .ofType(FETCH_PATIENT_PERMISSIONS.START)
      .debounceTime(2000)
      .flatMap((action) =>
        permissionsService(
          selectPatientGigyaUid(store$.getState()),
          pathOr(
            '',
            [CIAM_SDK_NAMESPACE, 'session', 'accessToken'],
            store$.getState(),
          ),
          pathOr(
            '',
            [CIAM_SDK_NAMESPACE, 'config', 'gigyaToken'],
            store$.getState(),
          ),
          selectPatientFhirId(store$.getState()),
          selectPlatformPermission(store$.getState()),
        )
          .then((data) => fetchPatientPermissions.success(data, action))
          .catch((error) => {
            errorMessageRedirect('/home');
            window.location.reload(false);
            fetchPatientPermissions.error(error);
          }),
      );
