import { therapyLinks } from 'src/modules/therapy/routes';
import { payerPortalLinks } from 'src/modules/payer-portal/routes';
import { newDeviceLinkLinks } from 'src/modules/wdtc-module/routes';

import { authenticationLinks } from './authentication/routes';
import { diagnosticsLinks } from './diagnostics/routes';
import { generalLinks } from './general/routes';
import { indicatorsLinks } from './indicators/routes';
import { patientLinks } from './patient/routes';
import {
  stripManagementLinks,
  stripManagementDashboardAdmLinks,
} from './strip-management/routes';
import { profileLinks } from './profile/routes';
import { professionalLinks } from './professional/routes';
import { rpmLinks } from './rpm/routes';
import { patientDashboardsLinks } from './patient-dashboards/routes';

export const routes = {
  authentication: authenticationLinks,
  diagnostics: diagnosticsLinks,
  general: generalLinks,
  indicators: indicatorsLinks,
  patient: patientLinks,
  stripManagement: stripManagementLinks,
  profile: profileLinks,
  professional: professionalLinks,
  rpm: rpmLinks,
  dashboard: patientDashboardsLinks,
  therapy: therapyLinks,
  payerPortal: payerPortalLinks,
  newDeviceLink: newDeviceLinkLinks,
  adminStrip: stripManagementDashboardAdmLinks,
};
