import styled from 'styled-components';
import { Title } from 'src/components/fonts';
import { fontWeights } from 'src/core/styles';

export const PwModalHeader = styled(Title)`
  font-weight: ${fontWeights.semiBold};
  line-height: 1;
  margin: 0 auto;
`;

PwModalHeader.displayName = 'PwdModalHeader';
