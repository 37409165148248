import styled from 'styled-components';

import { colors, spacing } from 'src/core/styles';

import { convertPxToRem } from '../../utils/rem-calc';

export const Container = styled.div`
  margin-bottom: 1.75rem;
`;
Container.displayName = 'Container';

export const Nav = styled.nav`
  :after {
    content: '';
    display: ${(props) => (props.noTabSelected ? 'none' : 'block')};
    border-bottom: ${(props) =>
      props.noTabSelected
        ? 'none'
        : `${convertPxToRem(1)}
      solid ${colors.quartzBlue}`};
  }
`;
Nav.displayName = 'Nav';

export const NavTabsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: right;
  padding-right: 1.25rem;
  margin-bottom: -0.0625rem;
`;
NavTabsList.displayName = 'NavTabsList';

export const NavTabsListItem = styled.li`
  cursor: pointer;
  height: 2.5rem;
  position: relative;
  border: 0.0625rem solid ${colors.quartzBlue};
  display: inline-block;
  margin: 0;
  margin-left: -0.0625rem;
  border-bottom-color: ${(props) =>
    props.selected
      ? colors.white
      : props.noTabSelected
      ? colors.quartzBlue
      : 'transparent'};
  padding: 0 0.9375rem;
`;
NavTabsListItem.displayName = 'NavTabsListItem';

export const NavTabsListItemText = styled.div`
  display: inline-block;
  color: ${(props) => (props.selected ? colors.blue : colors.charcoal)};
  padding: ${spacing.two} ${spacing.one} ${spacing.one} ${spacing.one};
  border-top: 0.1875rem solid transparent;
  border-top-color: ${(props) =>
    props.selected ? colors.blue : 'transparent'};
`;
NavTabsListItemText.displayName = 'NavTabsListItemText';

export const ContentContainer = styled.section`
  flex: 1;
  padding: 0 ${spacing.one};
  margin: 0;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
`;
ContentContainer.displayName = 'ContentContainer';

export const ArrowUpDown = styled.span`
  margin-left: 0.3125rem;
  transform: ${(props) => (props.selected ? 'rotate(180deg)' : null)};
  display: inline-block;
`;
ArrowUpDown.displayName = 'ArrowUpDown';
