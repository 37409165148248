import React from 'react';

import { withGraphLoader } from 'src/domains/diagnostics/utils';
import { SIZE } from 'src/domains/diagnostics/components/no-data-disclaimer';
import { DuringDaySunIcon, AtNightMoonIcon } from 'src/assets/icons';

import {
  Container,
  Row,
  Block,
  TotalQty,
  TotalHyposText,
  HypoText,
  HypoQty,
  HypoContainer,
  Circle,
  HypoWrapper,
  HypoDetailsWrapper,
  DayNightWrapper,
  DayNightText,
  DayNightQty,
  BlockBreaker,
} from './hypoglycaemia-card.style';

const HypoglycaemiaCardWithLoader = withGraphLoader(
  Container,
  'general.loading',
  { size: SIZE.SMALL },
);

const drawHypo = (id, total, day, night, homeDelivery) => (
  <React.Fragment>
    <BlockBreaker />
    <HypoContainer className={`hypo-${id}`}>
      <HypoWrapper>
        <Block justifyContent={'flex-start'} alignItems={'baseline'}>
          <Circle className={id} />
          <HypoText textKey={`dashboard.hypoglycaemiaCard.${id}`} />
        </Block>
        <Block alignItems={'baseline'} justifyContent="flex-start">
          <HypoQty>{total}</HypoQty>
        </Block>
      </HypoWrapper>
      <HypoDetailsWrapper isHomeDelivery={homeDelivery}>
        <DayNightWrapper>
          <DuringDaySunIcon />
          <DayNightQty>{day}</DayNightQty>
          <DayNightText textKey="dashboard.hypoglycaemiaCard.duringDay" />
        </DayNightWrapper>
        <DayNightWrapper>
          <AtNightMoonIcon />
          <DayNightQty>{night}</DayNightQty>
          <DayNightText textKey="dashboard.hypoglycaemiaCard.atNight" />
        </DayNightWrapper>
      </HypoDetailsWrapper>
    </HypoContainer>
  </React.Fragment>
);

export const HypoglycaemiaCard = ({
  isLoading,
  hasData,
  total,
  totalLow,
  nightLow,
  dayLow,
  totalVeryLow,
  nightVeryLow,
  dayVeryLow,
  isHDeliveryOrStrpMng,
}) => (
  <HypoglycaemiaCardWithLoader isLoading={isLoading} hasError={!hasData}>
    <Row
      flex={1.3}
      justifyContent={'center'}
      alignItems={'center'}
      isHomeDelivery={isHDeliveryOrStrpMng}
    >
      <Block flexDirection={'column'} flex={1} alignItems={'baseline'}>
        <Block alignItems={'baseline'}>
          <TotalQty>{total}</TotalQty>
          <TotalHyposText textKey={'dashboard.hypoglycaemiaCard.hypos'} />
        </Block>
        {drawHypo('low', totalLow, dayLow, nightLow, isHDeliveryOrStrpMng)}
        {drawHypo(
          'veryLow',
          totalVeryLow,
          dayVeryLow,
          nightVeryLow,
          isHDeliveryOrStrpMng,
        )}
      </Block>
    </Row>
  </HypoglycaemiaCardWithLoader>
);
