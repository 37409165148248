import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { IconsViewComponent } from './icons-view.component';

import { appVersion } from '../../manufacturer-info-modal.utils';

export const IconsViewContainer = compose(
  connect((state) => ({
    appVersion,
  })),
  withTranslation,
)(IconsViewComponent);
