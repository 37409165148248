import * as React from 'react';

import { CheckmarkIcon } from 'src/assets/icons';
import { hasValue } from 'src/utils';

import {
  CheckboxCheckMark,
  CheckboxCheckMarkContainer,
  CheckboxContainer,
  CheckboxControl,
  CheckboxInput,
} from './checkbox.style';

type CheckboxState = {};

type CheckboxProps = {
  id: string;
  label?: string | React.ReactFragment | any;
  labelBeforeCheckbox?: boolean;
  size: number;
  disabled?: boolean;
  modelPath?: string;
  defaultValue?: any;
};

export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  public render() {
    const {
      id = '',
      label,
      labelBeforeCheckbox = false,
      size,
      disabled = false,
      modelPath,
      defaultValue,
      ...other
    } = this.props;
    const ControlledOrDefaultInput = hasValue(modelPath)
      ? CheckboxControl
      : CheckboxInput;
    return (
      <CheckboxContainer
        htmlFor={id}
        leftPadding={labelBeforeCheckbox ? 0 : 25}
        rightPadding={!labelBeforeCheckbox ? 0 : 25}
        disabled={disabled}
      >
        <ControlledOrDefaultInput
          id={id}
          type="checkbox"
          disabled={disabled}
          model={modelPath}
          defaultValue={defaultValue}
          {...other}
        />
        <CheckboxCheckMarkContainer
          labelBeforeCheckbox={labelBeforeCheckbox}
          size={size}
          disabled={disabled}
        >
          <CheckboxCheckMark>
            <CheckmarkIcon height={size / 2} />
          </CheckboxCheckMark>
        </CheckboxCheckMarkContainer>
        &nbsp;{label}
      </CheckboxContainer>
    );
  }
}
