import { postJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { PatientReportsLoaderImplType } from './patient-reports.types';
import { OpenId } from '../patient/list-devices';
import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const PatientReportsLoaderImpl: PatientReportsLoaderImplType = (
  { patientId, reportType, startDate, endDate },
  openId: OpenId,
) =>
  postJSON(
    endpointWithParams(ENDPOINTS.patientReports, {
      patientId,
      reportType,
      startDate,
      endDate,
    }),
    {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
        Accept: 'application/json',
      },
    },
  );

export const PatientReportsTransformImpl: any = (data) => data;

export const PatientReportsServiceImpl: any =
  (load, transform) => (params, accessToken) =>
    load(params, accessToken).then(transform);
