import * as queryString from 'qs';
import React from 'react';

import { OIDCProps } from './login.types';

import { LAST_ACTIVE } from '../../constants';

import { customDatadogLog, LOGS } from 'src/app/logger';
import { UserActivityContext } from 'src/app/session';
import { authenticationLinks } from 'src/domains/authentication/routes';

export const OIDCRoot = (props: OIDCProps) => {
  let next;

  // @ts-ignore
  const { onSignOutAction = () => null } =
    React.useContext(UserActivityContext);
  const {
    goTo,
    isSessionValid,
    location,
    homeRoute,
    setSuccessRedirect,
    isSignedOut,
  } = props;

  if (location?.search && isSessionValid) {
    const qs = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    next = qs.next;
  }

  /* istanbul ignore next */ React.useEffect(() => {
    if (isSessionValid) {
      if (next) {
        setSuccessRedirect(next);
        goTo(next);
        customDatadogLog('session', LOGS.redirectNext);
      } else {
        goTo(homeRoute || '/');
        customDatadogLog('session', LOGS.redirectHome);
      }
    }

    if (!isSessionValid) {
      localStorage.removeItem(LAST_ACTIVE);
      customDatadogLog('session', LOGS.redirectLogin);
      if (isSignedOut) {
        goTo(authenticationLinks.redirect);
      } else {
        onSignOutAction();
      }
    }
  }, []);

  return <></>;
};
