import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { mapDispatchers, withToolTip } from 'src/utils';

import { LogbookStats } from './logbook-stats.component';
import { logbookStatsConnector } from './logbook-stats.selector';

const dispatchers = mapDispatchers({});

export const LogbookStatsContainer = compose(
  withRouter,
  connect(logbookStatsConnector, dispatchers),
  withToolTip,
  withTranslation,
)(LogbookStats);
