import {
  PATIENT_ACTIONS,
  SAVE_PATIENT_SESSION_REQUEST,
  EXPORT_PATIENT_SESSION_REQUEST,
  START_PATIENT_SESSION_REQUEST,
  CLEAR_PATIENT_EXPORT_DATA,
} from './patient-session.constants';
import { FetchStatus } from './patient-session.constants';
import { IExportPatientsData, Action } from './patient-session.types';

export type PatientSessionReducerState = {
  sessionTime: number;
  patientSessionId: string | null;
  sessionEndStatus: FetchStatus;
  exportStatus: FetchStatus;
  exportPatientsData: IExportPatientsData[] | null;
  isRunning: boolean;
  visitId: number | null;
};

export const INITIAL: PatientSessionReducerState = {
  sessionTime: 0,
  patientSessionId: null,
  sessionEndStatus: FetchStatus.NONE,
  exportStatus: FetchStatus.NONE,
  exportPatientsData: null,
  isRunning: false,
  visitId: null,
};

export const patientSessionReducer = (
  state: PatientSessionReducerState = INITIAL,
  action: Action,
) => {
  switch (action.type) {
    case PATIENT_ACTIONS.START_PATIENT_SESSION:
      return {
        ...state,
        ...action.payload,
        isRunning: true,
      };
    case PATIENT_ACTIONS.RESET_PATIENT_SESSION:
      return {
        ...state,
        sessionTime: 0,
        patientSessionId: null,
        isRunning: false,
      };
    case PATIENT_ACTIONS.CLEAR_SESSION_STATUS:
      return {
        ...state,
        sessionEndStatus: FetchStatus.NONE,
      };
    case START_PATIENT_SESSION_REQUEST.ERROR:
    case PATIENT_ACTIONS.RESET_IS_RUNNING:
      return {
        ...state,
        isRunning: false,
        sessionEndStatus: FetchStatus.NONE,
      };
    // request start
    case START_PATIENT_SESSION_REQUEST.SUCCESS:
      return {
        ...state,
        visitId: action.payload ? action.payload.id : null,
      };
    // request end
    case SAVE_PATIENT_SESSION_REQUEST.SUCCESS:
      return {
        ...state,
        sessionEndStatus: FetchStatus.SUCCESS,
      };
    case SAVE_PATIENT_SESSION_REQUEST.ERROR:
      return {
        ...state,
        sessionEndStatus: FetchStatus.FAILURE,
      };
    // export
    case EXPORT_PATIENT_SESSION_REQUEST.START:
      return {
        ...state,
        exportStatus: FetchStatus.LOADING,
      };
    case EXPORT_PATIENT_SESSION_REQUEST.SUCCESS:
      return {
        ...state,
        exportStatus: FetchStatus.SUCCESS,
        exportPatientsData: action.payload,
      };
    case EXPORT_PATIENT_SESSION_REQUEST.ERROR:
      return {
        ...state,
        exportStatus: FetchStatus.FAILURE,
      };
    case CLEAR_PATIENT_EXPORT_DATA:
      return {
        ...state,
        exportPatientsData: null,
      };
    default:
      return state;
  }
};
