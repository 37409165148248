// @ts-nocheck
import * as React from 'react';
import { GridItem, GridContainer } from 'src/components';
import { Form } from 'react-redux-form';
import { PencilIcon } from 'src/assets/icons/pencil-icon';
import { MODAL_TYPES } from 'src/core/modal';
import { colors } from 'src/core/styles';

import { BaseCard, Button, ControlButton, LocalizedText } from 'src/components';
import { ControlText } from 'src/components/forms';
import { InputText } from 'src/widgets/forms/components';

import { NextShipmentConfirmationModal } from './components/next-shipment-confirmation-modal';
import { CalendarModal, ConfirmDateModal } from './components/new-date';
import { NextShipmentProps } from './next-shipment.types';
import { getBorderColor } from './next-shipment.utils';

import {
  NEXT_SHIPMENT_MODEL,
  NEXT_SHIPMENT_OK,
  NO_DELIVERY_PROGRAM,
  STATUS as S,
} from './next-shipment.constants';
import {
  NextShipmentSection,
  FormContainer,
  HeaderContainer,
  HeaderTitle,
  ButtonsContainer,
  PencilContainer,
  Label,
  LabelAsterisk,
  ShipmentDate,
  Quantity,
  Caption,
  NoDeliveries,
  Status,
  Error,
} from './next-shipment.styles';

import { RenderIf } from 'src/utils/render-if';

import {
  AdditionalInfo,
  INFOS,
  HOME_DELIVERY,
} from 'src/domains/diagnostics/components/additional-info';

export class NextShipmentComponent extends React.Component<
  NextShipmentProps,
  any
> {
  constructor(props) {
    super(props);
    this.state = {
      newDate: null,
    };
  }

  public render() {
    const { t } = this.props;
    const { shipStatus, shipQtty, resultDescription } = this.props.nextShipment;
    const showButton = [S.ESTIMATED, S.PAUSED].indexOf(shipStatus) > -1;
    const showDateQuantity = [S.ESTIMATED, S.CUT].indexOf(shipStatus) > -1;
    const showNoDelivery = [S.SENT, S.ABORTED].indexOf(shipStatus) > -1;
    const showPaused = S.PAUSED === shipStatus;
    const showStatus = [S.ESTIMATED, S.PAUSED, S.CUT].indexOf(shipStatus) > -1;
    const showError = resultDescription !== NEXT_SHIPMENT_OK;
    const showEditDate = [S.ESTIMATED].indexOf(shipStatus) > -1;
    return (
      <RenderIf validate={!!resultDescription}>
        <BaseCard p={88}>
          <NextShipmentSection mb={80}>
            <HeaderContainer>
              <HeaderTitle>
                <LocalizedText textKey="nextShipment.title" />
                <AdditionalInfo
                  info={INFOS.homeDelivery}
                  active={HOME_DELIVERY.status}
                  style={{ float: 'right' }}
                />
              </HeaderTitle>
            </HeaderContainer>
            <RenderIf validate={showError}>
              <Error>
                <LocalizedText
                  textKey={`nextShipment.${
                    resultDescription === NO_DELIVERY_PROGRAM
                      ? resultDescription
                      : 'genericError'
                  }`}
                />
              </Error>
            </RenderIf>
            <RenderIf validate={!showError}>
              <Form
                model={NEXT_SHIPMENT_MODEL}
                onSubmit={() => this.toggleShipment(shipStatus)}
              >
                <FormContainer>
                  <GridContainer columns={12}>
                    <GridItem span={6}>
                      <LabelAsterisk>
                        {t('nextShipment.nextDeliveryDate')}
                      </LabelAsterisk>
                      <RenderIf validate={showDateQuantity}>
                        <ShipmentDate>
                          <ControlText
                            component={InputText}
                            model=".shipDateFormatted"
                            disabled={true}
                          />
                          <RenderIf validate={showEditDate}>
                            <PencilContainer onClick={() => this.changeDate()}>
                              <PencilIcon
                                fillColor={colors.brandBlue}
                                height={15}
                              />
                            </PencilContainer>
                          </RenderIf>
                        </ShipmentDate>
                        <Quantity>
                          <span>{t('nextShipment.quantityToBeSent')}</span>
                          <ControlText
                            component={InputText}
                            model=".shipQtty"
                            disabled={true}
                          />
                          <RenderIf validate={shipQtty > 1}>
                            <span>{t('nextShipment.vials')}</span>
                          </RenderIf>
                          <RenderIf validate={shipQtty < 2}>
                            <span>{t('nextShipment.vial')}</span>
                          </RenderIf>
                        </Quantity>
                      </RenderIf>
                      <RenderIf validate={showNoDelivery}>
                        <NoDeliveries>
                          {t('nextShipment.noDeliveries')}
                        </NoDeliveries>
                      </RenderIf>
                      <RenderIf validate={showPaused}>
                        <NoDeliveries>
                          {t('nextShipment.pausedMessage')}
                        </NoDeliveries>
                      </RenderIf>
                    </GridItem>
                    <RenderIf validate={showStatus}>
                      <GridItem span={6}>
                        <Label>{t('nextShipment.deliveryStatus')}</Label>
                        <Status
                          style={{ borderColor: getBorderColor(shipStatus) }}
                        >
                          {t(`nextShipment.status.${shipStatus}`)}
                        </Status>
                      </GridItem>
                    </RenderIf>
                  </GridContainer>
                </FormContainer>
                <RenderIf validate={showButton}>
                  <ButtonsContainer>
                    <ControlButton
                      model={NEXT_SHIPMENT_MODEL}
                      type="submit"
                      component={Button}
                      mapProps={{
                        buttonStyle: 'primary',
                        label: t(`nextShipment.button.${shipStatus}`),
                      }}
                      disabled={{ valid: false }}
                    />
                  </ButtonsContainer>
                </RenderIf>
                <Caption>
                  <span>{t('nextShipment.nextDeliveryDateCaption')}</span>
                  {t('nextShipment.caption')}
                </Caption>
              </Form>
            </RenderIf>
          </NextShipmentSection>
        </BaseCard>
      </RenderIf>
    );
  }

  private changeDate() {
    this.setState({ newDate: null });
    const moment = require('moment');
    const { createModal, locale } = this.props;
    const { shipDate } = this.props.nextShipment;
    const date = moment(shipDate).endOf('day');

    createModal({
      key: MODAL_TYPES.CUSTOM,
      data: {
        customComponent: CalendarModal,
        date,
        locale,
        setNewDate: (newDate) => this.setState({ newDate }),
        onConfirmation: () => this.showDateConfirmation(),
      },
    });
  }

  private showDateConfirmation() {
    const { t, destroyModal, createModal, changeDateNextShipment } = this.props;
    const { newDate } = this.state;
    const shipStatus = S.ACTIVE;
    destroyModal();
    if (newDate) {
      createModal({
        key: MODAL_TYPES.CUSTOM,
        data: {
          customComponent: ConfirmDateModal,
          newDate,
          t,
          onConfirmation: () => changeDateNextShipment({ newDate, shipStatus }),
        },
      });
    }
  }

  private toggleShipment(shipStatus) {
    const { createModal } = this.props;
    const status = shipStatus === S.ESTIMATED ? '2' : '1';
    createModal({
      key: MODAL_TYPES.CUSTOM,
      data: {
        customComponent: NextShipmentConfirmationModal,
        onConfirmation: () => this.onConfirmation(status),
        status,
      },
    });
  }

  private onConfirmation(status) {
    const { changeStatusNextShipment, destroyModal, createModal } = this.props;
    destroyModal();
    createModal({
      key: MODAL_TYPES.LOADING,
    });
    changeStatusNextShipment(status);
  }
}
