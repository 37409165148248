import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height?: number,
  fillColor?: string,
};

export const BasicProfileIcon = ({
  height = 24,
  fillColor = colors.grayMedium,
}: Props) => {
  const originalWidth = 51;
  const originalHeight = 51;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M5.133 15h.734C8.69 15 11 17.314 11 20.143v25.714C11 48.686 8.69 51 5.867 51h-.734C2.31 51 0 48.686 0 45.857V20.143C0 17.314 2.31 15 5.133 15zM25 0c2.75 0 5 2.295 5 5.1v40.8c0 2.805-2.25 5.1-5 5.1s-5-2.295-5-5.1V5.1C20 2.295 22.25 0 25 0zm21 29c2.75 0 5 2.31 5 5.133v11.734C51 48.69 48.75 51 46 51s-5-2.31-5-5.133V34.133C41 31.31 43.25 29 46 29z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};
