// tslint:disable:no-identical-functions
// @ts-nocheck
import * as React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  Content,
  Divider,
  ItemRadioButton,
  ModalBottomWithShadow,
  ModalHeaderTimeTracker,
  Row,
  StyledErrorMessage,
  StyledTime,
  Title,
} from './time-tracker-modal.style';
import {
  CreateNoteModalContent,
  ModalBody,
  ModalCard,
  ModalOverlay,
  ModalWrapper,
} from 'src/components-ds/modal/modal.style';
import { SymbolButton } from 'src/components-ds/symbol-button/symbol-button.component';
import { TextInput } from 'src/components-ds/text-input/text-input.component';

import { colors } from 'src/components-ds/colors';
import { Button, InputRadio } from 'src/components';
import { formatTime } from '../time-tracker/time-tracker.utils';
import { ClockIcon } from 'src/assets/icons/clock-icon/clock-icon.component';
import {
  ModalCreationType,
  PatientListItem,
  PatientListKey,
  TimeTrackerModalProps,
  TimeTrackerModalState,
} from './time-tracker-modal.types';
import { SectionColumn } from '../section-column/section-column.component';
import { formatStateToApiRequestFormat } from './time-tracker-modal.utils';
import {
  communicationRadioButton,
  dataTargetAnalysisData,
  goalsTreatmentsData,
  patientCareActivitiesData,
  trainingPatientData,
} from './time-tracker-modal.constants';

export class TimeTrackerModalComponent extends React.Component<
  TimeTrackerModalProps,
  TimeTrackerModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      communication: {
        id: '',
        value: '',
        label: '',
      },
      patientCareNote: '',
      patientCareActivities: [],
      dataTargeAnalysis: [],
      trainingPatient: [],
      goalsAndTreatments: [],
    };
  }

  public handleSaveButtonModal() {
    const {
      destroyModal,
      modalCreationType,
      setIsOpen,
      endSessionStart,
      t,
      patientId,
      data: { onSaveModal },
    } = this.props;

    if (modalCreationType === ModalCreationType.BY_COMPONENT) {
      endSessionStart({
        patientId: patientId.toString(),
        data: formatStateToApiRequestFormat(this.state),
        t,
      });
      setIsOpen(false);
    } else {
      onSaveModal({
        data: formatStateToApiRequestFormat(this.state),
        destroyModal,
      });
    }
  }

  public handleCheckboxClick = (
    listKey: PatientListKey,
    patientItem: PatientListItem,
  ): any => {
    return this.getCheckboxListItemByKey(listKey, patientItem);
  };

  public getCheckboxListItemByKey = (
    key: PatientListKey,
    patientItem: PatientListItem,
  ) => {
    let checkBoxArray = this.state[key];
    const isInArray = checkBoxArray.indexOf(patientItem);
    if (isInArray !== -1) {
      checkBoxArray = checkBoxArray.filter((val, i: number) => i !== isInArray);
    } else {
      checkBoxArray.push(patientItem);
    }
    return {
      [key]: checkBoxArray,
    };
  };

  public render() {
    const { patientSessionTime, patient, showErrorMessage } = this.props.data;
    const {
      t,
      isOpen,
      modalCreationType = ModalCreationType.BY_CREATE_MODAL,
      setIsOpen,
    } = this.props;

    const componentRendered = (
      <div data-testid="time-tracker-modal">
        <ModalOverlay />
        <ModalWrapper>
          <ModalCard>
            <ModalBody>
              <ModalHeaderTimeTracker>
                <span>
                  {`${t('timetracker.newPatient')} ${patient.firstName} ${
                    patient.surName
                  }`}{' '}
                </span>
                <SymbolButton
                  onClick={() => {
                    if (modalCreationType === ModalCreationType.BY_COMPONENT) {
                      setIsOpen(false);
                    } else {
                      if (this.props.data) {
                        this.props.data.onClearInterval();
                      }
                      this.props.destroyModal();
                    }
                  }}
                  color={colors.white}
                >
                  X
                </SymbolButton>
              </ModalHeaderTimeTracker>
              <CreateNoteModalContent>
                <Content direction="column">
                  <Title>{t('timetracker.time')} </Title>
                  <Row>
                    <ClockIcon height="25" fillColor="#8b0e8e" />
                    &nbsp;&nbsp;
                    <StyledTime>{formatTime(patientSessionTime)}</StyledTime>
                  </Row>
                </Content>
                <Divider />
                <Content direction="column">
                  <Title>{t('timetracker.methodCommunication.title')}</Title>
                  <Content>
                    {communicationRadioButton.map((item) => (
                      <ItemRadioButton key={item.id}>
                        <InputRadio
                          label={t(item.label)}
                          id={item.id}
                          value={item.value}
                          name="methodCommunication"
                          onClick={(e) => {
                            this.setState({ communication: { ...item } });
                          }}
                        />
                      </ItemRadioButton>
                    ))}
                  </Content>
                </Content>
                <Divider />
                <Content direction="column">
                  <Title>{t('timetracker.patientCareNote.title')}</Title>
                  <TextInput
                    dataTestId="modal-text-input"
                    width={'600px'}
                    onChange={(e) => {
                      this.setState({ patientCareNote: e.target.value });
                    }}
                    value={this.state.patientCareNote}
                    disabled={false}
                    maxLength={16000}
                  />
                </Content>
                <Divider />
                <Content direction="column">
                  <Title>{t('timetracker.patientCareActivities.title')}</Title>
                  <Content justifyContent={'space-between'}>
                    <Row flex="1" marginRight={'5%'}>
                      <SectionColumn
                        patientListData={patientCareActivitiesData}
                        patientListId={'patientCareActivities'}
                        onCheckboxClick={(patientListId, item) => {
                          this.setState(
                            this.handleCheckboxClick(patientListId, item),
                          );
                        }}
                      />
                      <SectionColumn
                        patientListData={dataTargetAnalysisData}
                        patientListId={'dataTargeAnalysis'}
                        subtitle={t('timetracker.dataTargetAnalysis.title')}
                        onCheckboxClick={(patientListId, item) => {
                          this.setState(
                            // tslint:disable-next-line
                              this.handleCheckboxClick(patientListId, item),
                          );
                        }}
                      />
                    </Row>
                    <Row flex="1">
                      <SectionColumn
                        patientListData={trainingPatientData}
                        patientListId={'trainingPatient'}
                        subtitle={t('timetracker.trainingPatientDevice.title')}
                        onCheckboxClick={(patientListId, item) => {
                          this.setState(
                            this.handleCheckboxClick(patientListId, item),
                          );
                        }}
                      />
                      <SectionColumn
                        patientListData={goalsTreatmentsData}
                        patientListId={'goalsAndTreatments'}
                        subtitle={t('timetracker.goalsAndTratment.title')}
                        onCheckboxClick={(patientListId, item) => {
                          this.setState(
                            this.handleCheckboxClick(patientListId, item),
                          );
                        }}
                      />
                    </Row>
                  </Content>
                </Content>
              </CreateNoteModalContent>
            </ModalBody>
            <ModalBottomWithShadow>
              {showErrorMessage && (
                <StyledErrorMessage>
                  {t('timetracker.reportModal.errorMessage')}
                </StyledErrorMessage>
              )}
              <Button
                disabled={this.state.patientCareNote.length === 0}
                onClick={() => {
                  this.handleSaveButtonModal();
                }}
                label={t('timetracker.save')}
              />
            </ModalBottomWithShadow>
          </ModalCard>
        </ModalWrapper>
      </div>
    );

    return (
      <>
        {modalCreationType === ModalCreationType.BY_COMPONENT
          ? isOpen && componentRendered
          : componentRendered}
      </>
    );
  }
}

export const TimeTrackerModal = withTranslation(TimeTrackerModalComponent);
