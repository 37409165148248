import styled from 'styled-components';

import { fontSize } from 'src/core';

import { FlexContainer, FlexItem } from '../device-settings-header/flex-grid';

const mediumBreakpoint = '1200px';

export const RemainderRow = styled(FlexContainer)`
  @media (max-width: ${mediumBreakpoint}) {
    flex-wrap: wrap;
  }
`;

export const RemainderContainerLeft = styled(FlexContainer)`
  margin-right: 1rem;
  @media (max-width: ${mediumBreakpoint}) {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }
`;

export const RemainderContainerRight = styled(FlexContainer)`
  margin-left: 1rem;
  @media (max-width: ${mediumBreakpoint}) {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }
`;

export const RemainderItem = styled(FlexItem)`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const ReminderTitle = styled(FlexItem)`
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: ${fontSize.p};
  margin: 1rem 0;
`;
