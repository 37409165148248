import * as React from 'react';
import { withTheme } from 'styled-components';

import { isNotNil } from 'src/utils';
import { DateRangeIcon } from 'src/assets/icons';

import { transformDate } from './dropdown-calendar.util';
import {
  DisplayValueContainer,
  VerticalSeparator,
  DisplayValueDate,
} from './dropdown-calendar.styles';

const displayValueComponent: React.FunctionComponent<any> = ({
  children,
  theme,
  values,
}) => {
  if (isNotNil(values) && isNotNil(values.data)) {
    const date = transformDate(values.data.date);
    const hour = transformDate(values.data.date, 'HH:mm');
    return (
      <DisplayValueContainer>
        <DateRangeIcon height={19} fillColor={theme.colors.brandBlue} />
        <DisplayValueDate>{date}</DisplayValueDate>
        <VerticalSeparator />
        <div>{hour}</div>
      </DisplayValueContainer>
    );
  } else {
    return <>{children}</>;
  }
};

export const DisplayValue = withTheme(displayValueComponent);
