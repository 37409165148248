import * as React from 'react';

import { PdfReportsProgressModal } from './components/pdf-report-progress';
import { PdfReportsFailModal } from './components/pdf-report-fail';
import { PdfReportsSuccessModal } from './components/pdf-report-success';
import {
  Modal,
  ModalHeader,
  ModalColHeader,
  ModalBody,
  IconWrapper,
} from './pdf-report.style';
import { XIcon } from 'src/assets/icons';
import { DateTime } from 'luxon';
import {
  ModalOverlay,
  ModalWrapperInTheMiddle,
} from 'src/components-ds/modal/modal.style';
import { Dispatch } from 'react';
import { t } from 'i18next';

export interface PdfReportProps {
  patient: { firstName: string; surName: string; healthCareSystemId: string };
  patientStartDate: string;
  patientEndDate: string;
  patientReportStatus: FetchStatus;
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  onRetry: () => void;
}

export enum FetchStatus {
  LOADING = 'loading',
  FAILURE = 'failure',
  SUCCESS = 'success',
  NONE = 'none',
}

export const ExportToEMRModal = ({
  patient,
  patientStartDate,
  patientEndDate,
  patientReportStatus,
  isOpen,
  setIsOpen,
  onRetry,
}: PdfReportProps) => {
  const convertDateToHba1cFormat = (dateLastValue: string) =>
    DateTime.fromISO(dateLastValue, { zone: 'utc' }).toFormat('dd LLL yyyy');

  const patientInfo = `${patient.firstName}, ${patient.surName} - ID: ${patient.healthCareSystemId}`;

  const contentsElements = {
    [FetchStatus.LOADING]: <PdfReportsProgressModal />,
    [FetchStatus.SUCCESS]: (
      <PdfReportsSuccessModal
        onSuccess={() => setIsOpen(false)}
        text={patientInfo}
        patientEndDate={convertDateToHba1cFormat(patientEndDate)}
        patientStartDate={convertDateToHba1cFormat(patientStartDate)}
      />
    ),
    [FetchStatus.FAILURE]: (
      <PdfReportsFailModal onRetry={onRetry} text={patientInfo} />
    ),
  };
  const ElementBody = contentsElements[patientReportStatus] || <></>;

  return (
    <>
      {isOpen && (
        <ModalOverlay origin={origin} data-testid="export-to-emr-modal">
          <ModalWrapperInTheMiddle>
            <Modal>
              <ModalHeader>
                <ModalColHeader width="95%">
                  {t('pdfReport.reportHeader', { patientInfo })}
                </ModalColHeader>
                <ModalColHeader width="5%">
                  <IconWrapper onClick={() => setIsOpen(false)}>
                    <XIcon height={14} fillColor="white" />
                  </IconWrapper>
                </ModalColHeader>
              </ModalHeader>
              <ModalBody>{ElementBody}</ModalBody>
            </Modal>
          </ModalWrapperInTheMiddle>
        </ModalOverlay>
      )}
    </>
  );
};
