import { testId } from '@roche/roche-common';
import { TFunction } from 'i18next';
import * as React from 'react';

import { colors } from 'src/core';
import { RangeDialogErrorWrapper } from 'src/widgets/modal';

type ExternalLeaveChangingModalProp = {
  id: string;
  header: string;
  title: string;
  content: string;
  okBtnText: string;
  cancelBtnText: string;
  disabled?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  t: TFunction;
};

export const LeaveChangingModalComponent: React.FunctionComponent<
  ExternalLeaveChangingModalProp
> = ({ id, header, title, content, okBtnText, cancelBtnText, t, disabled }) => (
  <React.Fragment>
    <jello-dialog
      id={id}
      class={id}
      visible=""
      header-title={t(header)}
      show-close-button=""
      primary-button-text={disabled ? null : t(okBtnText)}
      secondary-button-text={t(cancelBtnText)}
      {...testId('jello-confirm-dialog', 'modal')}
    >
      <RangeDialogErrorWrapper>
        <jello-icon
          class="jello-icon-warning-color-brand"
          size="M"
          icon-name="warning"
          style={{ color: colors.brandBlue }}
        />
        <jello-heading size="L" weight="bold">
          {t(title)}
        </jello-heading>
        <jello-text size="S" weight="regular">
          {t(content)}
        </jello-text>
      </RangeDialogErrorWrapper>
    </jello-dialog>
  </React.Fragment>
);
