export const ID = 'jello-dialog';

export const JELLO_DIALOG_EVENTS = {
  primaryBtn: 'jello-dialog-primary-action',
  secondaryBtn: 'jello-dialog-secondary-action',
  tertiaryBtn: 'jello-dialog-tertiary-action',
  closeBtn: 'jello-dialog-close-action',
};

export const JELLO_INPUT_EVENTS = {
  inputCheckbox: 'jello-input-checkbox-input',
  inputCheckboxChange: 'jello-input-checkbox-change',
  inputDate: 'jello-input-date-input',
  inputText: 'jello-input-text-input',
  inputBlur: 'jello-input-text-blur',
  inputChange: 'jello-input-text-change',
  inputSelect: 'jello-select-input',
  inputNumber: 'jello-input-number-input',
  blurEmail: 'jello-input-email-blur',
  emailChange: 'jello-input-email-change',
  emailInput: 'jello-input-email-input',
  changeSelect: 'jello-select-change',
  inputPassword: 'jello-input-password-input',
};
