import { createSelector } from 'reselect';
import {
  path,
  filter,
  map,
  isNil,
  compose,
  pick,
  pipe,
  pluck,
  propOr,
  values,
} from 'ramda';

import {
  percentage,
  fixToDecimalPlace,
  convertISOToJsGMT,
  isDateInDateRange,
  toFormat,
} from 'src/utils';
import { objectISODateToJsDateGMT } from 'src/domains/diagnostics/utils';
import {
  toBasalShape,
  createBolusObject,
  bolusTypeToParseFunctionMap,
} from 'src/domains/diagnostics/scenes/graphs/graph.util';
import {
  EMPTY_VALUE_PLACEHOLDER,
  MINUTES_IN_DAY,
  BASAL_REMARKS,
  BOLUS_TYPE,
} from 'src/domains/diagnostics/store/constants';
import { calculateIntervalAverageTestsPerDay } from 'src/domains/diagnostics/utils/measurements';

import { selectStripDeliveryThresholds } from './strip-delivery.selectors';
import {
  selectPatientFirstMeasurementDate,
  selectPatientLastMeasurementDate,
  selectPatientStartDate,
  selectPatientEndDate,
} from './patient-date-range.selector';

import { diffInDaysInclusiveOfEnds } from '../../utils';

// Low level selectors

export const selectBGOverviewTimeInterval = path([
  'ui',
  'patientDashboard',
  'bgOverview',
  'timeInterval',
]);

export const selectBGOverviewEndDate = createSelector(
  path(['ui', 'patientDashboard', 'bgOverview', 'endDate']),
  convertISOToJsGMT,
);

export const selectDashboard = path(['ui', 'patientDashboard']);
export const selectGraph = path(['ui', 'patientDashboard', 'graph']);
export const selectGraphType = path(['ui', 'patientDashboard', 'graphType']);
export const selectLogbookType = path([
  'ui',
  'patientDashboard',
  'logbookType',
]);
export const selectDeviceSettingsType = path([
  'ui',
  'patientDashboard',
  'deviceSettingsType',
]);

export const selectGraphStartTime = path([
  'ui',
  'patientDashboard',
  'graphStartTime',
]);

export const selectIsFetchingClinicalData = path([
  'ui',
  'patientDashboard',
  'isFetchingClinicalData',
]);

export const selectIsFetchingThreshold = path([
  'ui',
  'patientDashboard',
  'isFetchingThreshold',
]);

export const selectIsFetchingTimeIntervals = path([
  'ui',
  'patientDashboard',
  'isFetchingTimeIntervals',
]);

export const selectBloodGlucoseUnit = path([
  'ui',
  'patientDashboard',
  'bloodGlucoseUnit',
]);

export const selectGraphToggles = createSelector(
  selectDashboard,
  pick([
    'showBloodGlucoseLines',
    'showBloodGlucosePoints',
    'showBloodGlucoseAfterMealPoints',
    'showBloodGlucoseBeforeMealPoints',
    'showCarbohydrates',
    'showMeanBloodGlucose',
    'showGridLines',
    'showCarbohydrates',
    'showInsulin',
    'showBasalRate',
  ]),
);

const isNotControlMeasurement = (bg) => !bg.control;
const valueNotNull = (bg) => !isNil(bg.value);
const isValidGlucoseMeasurement = (bg) =>
  valueNotNull(bg) && isNotControlMeasurement(bg);

export const filterByDateRange = (objects, startDate, endDate) =>
  objects.filter(({ date }) => isDateInDateRange(date, startDate, endDate));

const selectAllUnFilteredGlucoseMeasurements = createSelector(
  path(['ui', 'patientDashboard', 'glucoseMeasurements']),
  map(objectISODateToJsDateGMT),
);

const selectAllUnFilteredBGOverviewGlucoseMeasurements = createSelector(
  path(['ui', 'patientDashboard', 'bgOverview', 'glucoseMeasurements']),
  map(objectISODateToJsDateGMT),
);
export const selectGlucoseMeasurements = createSelector(
  path(['ui', 'patientDashboard', 'bgOverview', 'glucoseMeasurements']),
);

const selectAllGlucoseMeasurements = createSelector(
  selectAllUnFilteredGlucoseMeasurements,
  filter(isValidGlucoseMeasurement),
);

const selectAllBGOverviewGlucoseMeasurements = createSelector(
  selectAllUnFilteredBGOverviewGlucoseMeasurements,
  filter(isValidGlucoseMeasurement),
);

export const selectAllGlucoseMeasurementsIncludingNullValues = createSelector(
  selectAllUnFilteredGlucoseMeasurements,
  filter(isNotControlMeasurement),
);

export const selectGlucoseMeasurementsInDateSliderRange = createSelector(
  selectAllGlucoseMeasurements,
  selectPatientStartDate,
  selectPatientEndDate,
  filterByDateRange,
);

export const selectGlucoseMeasurementsBetweenFirstAndLast = createSelector(
  selectAllGlucoseMeasurements,
  selectPatientFirstMeasurementDate,
  selectPatientLastMeasurementDate,
  filterByDateRange,
);

export const selectBGOverviewGlucoseMeasurementsBetweenFirstAndLast =
  createSelector(
    selectAllBGOverviewGlucoseMeasurements,
    selectPatientFirstMeasurementDate,
    selectPatientLastMeasurementDate,
    filterByDateRange,
  );

export const selectGlucoseMeasurementsIncludingNullValuesInDateSliderRange =
  createSelector(
    selectAllGlucoseMeasurementsIncludingNullValues,
    selectPatientStartDate,
    selectPatientEndDate,
    filterByDateRange,
  );

const selectAllBolusesData = createSelector(
  path(['ui', 'patientDashboard', 'insulin', 'bolus']),
  map(objectISODateToJsDateGMT),
);

export const selectAllBasals = createSelector(
  path(['ui', 'patientDashboard', 'insulin', 'basals']),
  compose(map(toBasalShape), map(objectISODateToJsDateGMT)),
);

export const selectBolusesDataInDateSliderRange = createSelector(
  selectAllBolusesData,
  selectPatientStartDate,
  selectPatientEndDate,
  filterByDateRange,
);

export const selectBasalsInDateSliderRange = createSelector(
  selectAllBasals,
  selectPatientStartDate,
  selectPatientEndDate,
  filterByDateRange,
);

export const selectMaxBasalMeasurement = createSelector(
  selectBasalsInDateSliderRange,
  pipe(pluck('basalCbrf'), (basalsValues) => Math.max(...basalsValues)),
);

export const selectBolusesInDateSliderRange = createSelector(
  selectBolusesDataInDateSliderRange,
  createBolusObject('Bolus'),
);

const isValidBolusMeasurement = ({ bolusType }) =>
  !!bolusType && Object.keys(bolusTypeToParseFunctionMap).includes(bolusType);

export const selectValidBolusesInDateSliderRange = createSelector(
  selectBolusesInDateSliderRange,
  (measurements) => measurements.filter(isValidBolusMeasurement),
);

export const selectBolusTotalInDateSliderRange = createSelector(
  selectBolusesDataInDateSliderRange,
  createBolusObject('BolusTotal'),
);

export const selectBolusPlusBasalTotalInDateSliderRange = createSelector(
  selectBolusesDataInDateSliderRange,
  createBolusObject('BolusPlusBasalTotal'),
);

export const selectAdvicedBolusInDateSliderRange = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  map(pick(['advicedBolus'])),
);

// High level selectors

export const selectNumberOfDaysInDateSliderRange = createSelector(
  selectPatientStartDate,
  selectPatientEndDate,
  diffInDaysInclusiveOfEnds,
);

const getPostIdealIntervalFromThreshold = propOr(null, 'postIdealInterval');
export const selectPostIdealIntervalsFromThresholds = createSelector(
  selectStripDeliveryThresholds,
  ({ actualHyper, hyper, hypo, warning }) => ({
    thresholdHyper: getPostIdealIntervalFromThreshold(actualHyper),
    upperLimit: getPostIdealIntervalFromThreshold(hyper),
    lowerLimit: getPostIdealIntervalFromThreshold(warning),
    thresholdHypo: getPostIdealIntervalFromThreshold(hypo),
  }),
);
const renameGraphsIntervalKeys = ({
  upperLimit,
  lowerLimit,
  thresholdHypo,
  thresholdHyper,
}) => ({
  upperHyperThreshold: thresholdHyper,
  glucoseIdealIntervalMax: upperLimit,
  glucoseIdealIntervalMin: lowerLimit,
  hypoglycemiaThreshold: thresholdHypo,
});

export const selectGraphThreshold = createSelector(
  selectPostIdealIntervalsFromThresholds,
  renameGraphsIntervalKeys,
);

export const selectIsFetchingPatientDateRange = path([
  'ui',
  'patientDashboard',
  'isFetchingPatientDateRange',
]);

export const selectIsLoading = createSelector(
  selectIsFetchingPatientDateRange,
  selectIsFetchingClinicalData,
  (isFetchingDateRange, isFetchingData) =>
    isFetchingDateRange || isFetchingData,
);

export const selectGraphLoading = createSelector(
  selectIsFetchingPatientDateRange,
  selectIsFetchingClinicalData,
  selectIsFetchingThreshold,
  selectIsFetchingTimeIntervals,
  (
    isFetchingDateRange,
    isFetchingMeasurements,
    isFetchingThreshold,
    isFetchingTimeIntervals,
  ) =>
    isFetchingDateRange ||
    isFetchingMeasurements ||
    isFetchingThreshold ||
    isFetchingTimeIntervals,
);

const getMeasurementValues = (measurements) =>
  map((measurement) => measurement.value, measurements);

export const selectGraphDetailTargetRanges = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphThreshold,
  (bloodGlucoseMeasurements, thresholdValues) => {
    const {
      glucoseIdealIntervalMin,
      glucoseIdealIntervalMax,
      hypoglycemiaThreshold,
      upperHyperThreshold,
    } = thresholdValues;
    let aboveCount = 0;
    let belowCount = 0;
    let belowAndHypoCount = 0;
    let hypoglycaemiaCount = 0;
    let withinCount = 0;
    let aboveAndHyperCount = 0;
    let aboveNoHyperCount = 0;

    if (!bloodGlucoseMeasurements.length) {
      return {
        upperHyperThreshold,
        aboveAndHyperCount: EMPTY_VALUE_PLACEHOLDER,
        aboveAndHyperPercentage: EMPTY_VALUE_PLACEHOLDER,
        aboveNoHyperCount: EMPTY_VALUE_PLACEHOLDER,
        aboveNoHyperPercentage: EMPTY_VALUE_PLACEHOLDER,
        abovePercentage: EMPTY_VALUE_PLACEHOLDER,
        aboveCount: EMPTY_VALUE_PLACEHOLDER,
        belowPercentage: EMPTY_VALUE_PLACEHOLDER,
        belowCount: EMPTY_VALUE_PLACEHOLDER,
        belowAndHypoCount: EMPTY_VALUE_PLACEHOLDER,
        belowAndHypoPercentage: EMPTY_VALUE_PLACEHOLDER,
        hypoglycemiaThreshold,
        hypoglycaemiaNumber: EMPTY_VALUE_PLACEHOLDER,
        hypoglycaemiaPercentage: EMPTY_VALUE_PLACEHOLDER,
        targetBloodGlucoseMinimum: glucoseIdealIntervalMin,
        targetBloodGlucoseMaximum: glucoseIdealIntervalMax,
        withinPercentage: EMPTY_VALUE_PLACEHOLDER,
        withinCount: EMPTY_VALUE_PLACEHOLDER,
      };
    }

    getMeasurementValues(bloodGlucoseMeasurements).forEach((measurement) => {
      if (!isNil(measurement)) {
        if (measurement < hypoglycemiaThreshold) {
          hypoglycaemiaCount += 1;
          belowAndHypoCount += 1;
        } else if (
          measurement >= glucoseIdealIntervalMin &&
          measurement <= glucoseIdealIntervalMax
        ) {
          withinCount += 1;
        } else if (measurement < glucoseIdealIntervalMin) {
          belowCount += 1;
          belowAndHypoCount += 1;
        } else if (measurement > glucoseIdealIntervalMax) {
          aboveCount += 1;
          if (measurement <= upperHyperThreshold) {
            aboveNoHyperCount += 1;
          } else {
            aboveAndHyperCount += 1;
          }
        }
      }
    });

    return {
      upperHyperThreshold,
      aboveAndHyperPercentage: fixToDecimalPlace(
        percentage(aboveAndHyperCount, bloodGlucoseMeasurements.length),
        1,
      ),
      aboveAndHyperCount,
      aboveNoHyperPercentage: fixToDecimalPlace(
        percentage(aboveNoHyperCount, bloodGlucoseMeasurements.length),
        1,
      ),
      aboveNoHyperCount,
      abovePercentage: fixToDecimalPlace(
        percentage(aboveCount, bloodGlucoseMeasurements.length),
        0,
      ),
      aboveCount,
      belowPercentage: fixToDecimalPlace(
        percentage(belowCount, bloodGlucoseMeasurements.length),
        0,
      ),
      belowCount,
      belowAndHypoCount,
      belowAndHypoPercentage: fixToDecimalPlace(
        percentage(belowAndHypoCount, bloodGlucoseMeasurements.length),
        1,
      ),
      hypoglycemiaThreshold,
      hypoglycaemiaNumber: Number(hypoglycaemiaCount.toFixed(1)),
      hypoglycaemiaPercentage: fixToDecimalPlace(
        percentage(hypoglycaemiaCount, bloodGlucoseMeasurements.length),
        1,
      ),
      targetBloodGlucoseMinimum: glucoseIdealIntervalMin,
      targetBloodGlucoseMaximum: glucoseIdealIntervalMax,
      withinPercentage: fixToDecimalPlace(
        percentage(withinCount, bloodGlucoseMeasurements.length),
        0,
      ),
      withinCount,
    };
  },
);

const reduceToTimeOffByDay = (timeOff, basal) => {
  const { date, basalRemark } = basal;

  let minutes;
  let base;

  if (basalRemark === BASAL_REMARKS.POWER_DOWN) {
    minutes = timeOff.minutes;
    base = date.hour * 60 + date.minute;
  } else if (
    timeOff.latestRemark === BASAL_REMARKS.POWER_DOWN &&
    basalRemark === BASAL_REMARKS.POWER_UP
  ) {
    minutes = timeOff.minutes + date.hour * 60 + date.minute - timeOff.base;
    base = timeOff.base;
  } else {
    minutes = timeOff.minutes;
    base = timeOff.base;
  }

  return {
    ...timeOff,
    minutes,
    latestRemark: basalRemark,
    base,
  };
};

const getNumBolusOfType = (bolusMeasurements, type) =>
  bolusMeasurements.filter((measurement) => measurement.bolusType === type)
    .length;

export const selectTimePoweredOn = createSelector(
  selectBasalsInDateSliderRange,
  selectNumberOfDaysInDateSliderRange,
  (basals, numDays) => {
    const timePoweredOff = basals.reduce(reduceToTimeOffByDay, {
      minutes: 0,
      latestRemark: null,
      base: 0,
    });

    return MINUTES_IN_DAY * numDays - timePoweredOff.minutes;
  },
);

const getBolusTypePerDay = (bolusesByDay, type) => {
  const bolusesArray = Object.values(bolusesByDay);
  let days = 0;
  let numberOfBolus = 0;
  bolusesArray.forEach((boluses) => {
    const bolusType = getNumBolusOfType(boluses.boluses, type);
    numberOfBolus += bolusType;
    days++;
  });
  return { numberOfBolus, days };
};

const groupBolusesByDay = (boluses) => {
  const bolusesByDay = {};

  boluses.forEach((bolus) => {
    let date = bolus.date.toFormat('MM/dd/yyyy');
    if (isNil(bolusesByDay[date])) {
      bolusesByDay[date] = { boluses: [] };
    }
    bolusesByDay[date].boluses = [...bolusesByDay[date].boluses, bolus];
  });

  return bolusesByDay;
};

export const selectBolusPerDay = createSelector(
  selectBolusesInDateSliderRange,
  (boluses) => {
    const bolusesByDay = groupBolusesByDay(boluses);
    const numStdBolusPerDay = getBolusTypePerDay(
      bolusesByDay,
      BOLUS_TYPE.STANDARD,
    );
    const numQuickBolusPerDay = getBolusTypePerDay(
      bolusesByDay,
      BOLUS_TYPE.QUICK,
    );
    const numExtendedBolusPerDay = getBolusTypePerDay(
      bolusesByDay,
      BOLUS_TYPE.EXTENDED,
    );
    const numMultiwaveBolusPerDay = getBolusTypePerDay(
      bolusesByDay,
      BOLUS_TYPE.MULTIWAVE,
    );
    return {
      numStdBolusPerDay:
        numStdBolusPerDay.days !== 0
          ? numStdBolusPerDay.numberOfBolus / numStdBolusPerDay.days
          : 0,
      numQuickBolusPerDay:
        numQuickBolusPerDay.days !== 0
          ? numQuickBolusPerDay.numberOfBolus / numQuickBolusPerDay.days
          : 0,
      numExtendedBolusPerDay:
        numExtendedBolusPerDay.days !== 0
          ? numExtendedBolusPerDay.numberOfBolus / numExtendedBolusPerDay.days
          : 0,
      numMultiwaveBolusPerDay:
        numMultiwaveBolusPerDay.days !== 0
          ? numMultiwaveBolusPerDay.numberOfBolus / numMultiwaveBolusPerDay.days
          : 0,
      numStdBolus: numStdBolusPerDay.numberOfBolus,
      numQuickBolus: numQuickBolusPerDay.numberOfBolus,
      numExtendedBolus: numExtendedBolusPerDay.numberOfBolus,
      numMultiwaveBolus: numMultiwaveBolusPerDay.numberOfBolus,
      totalBoluses: boluses.length,
      totalPerDay:
        boluses.length > 0
          ? boluses.length / Object.values(bolusesByDay).length
          : 0,
    };
  },
);

export const selectGlucoseMeasurementsByDay = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) =>
    values(
      measurements.reduce((datesGroupedByDay, val) => {
        const key = toFormat('yyyy-MM-dd')(val.date);

        const entries = datesGroupedByDay[key] || [];

        return {
          ...datesGroupedByDay,
          [key]: [...entries, val],
        };
      }, {}),
    ),
);

export const selectGlobalDatesAverageTestsPerDay = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectPatientStartDate,
  selectPatientEndDate,
  calculateIntervalAverageTestsPerDay,
);

export const selectFormattedGlobalDatesAverageTestsPerDay = createSelector(
  selectGlobalDatesAverageTestsPerDay,
  (value) => (value === 0 ? null : fixToDecimalPlace(value, 1)),
);

export const selectCarbohydratesMeasurementsInDateSliderRange = createSelector(
  selectAllGlucoseMeasurementsIncludingNullValues,
  (measurements) =>
    measurements
      .filter(({ carbohydrates }) => !!carbohydrates)
      .map(({ date, carbohydrates }) => ({
        date,
        carbohydrates,
      })),
);
