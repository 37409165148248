import { propOr, includes } from 'ramda';
import { actions as reactReduxFormActions } from 'react-redux-form';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { selectAccessToken, selectGigyaToken } from 'src/app/session';

import { CreateProfessionalServiceImplType } from 'src/services';
import { State } from 'src/app/store/app.types';
import { professionalLinks } from 'src/domains/professional/routes';
import { getRouterPathname } from 'src/navigation/store/navigation.selectors';
import { createModal, MODAL_TYPES } from 'src/core';
import { PERMISSIONS } from 'src/core/permissions';
import { STATE_ACTIONS } from 'src/core/state';
import { ConfigurablePermissionsActions } from 'src/core/configurables/configurables.types';
import { selectConfigurablePermissions } from 'src/core/configurables/configurables.selectors';
import {
  LanguagesActions,
  LanguagesActionType,
} from 'src/core/languages/languages.types';
import { GET_CURRENT_USER } from 'src/core/user/user.constants';
import { selectDefaultProfessionalDepartmentId } from 'src/widgets/professional/create-professional/create-professional.selectors';

import {
  createProfessionalError,
  createProfessionalSuccess,
} from 'src/widgets/professional/create-professional/store/create-professional.actions';
import {
  CreateProfessionalStartAction,
  CreateProfessionalActionType,
} from 'src/widgets/professional/create-professional/store/create-professional.types';

import { CreateProfessionalErrorModal } from 'src/widgets/professional/create-professional/components/create-professional-error-modal';
import { CreateProfessionalSuccessModal } from 'src/widgets/professional/create-professional/components/create-professional-success-modal';

import { ProfessionalFormModel } from 'src/widgets/professional/forms/forms.types';
import { selectCurrentUserLanguageByLocale } from 'src/widgets/patient/create-patient/create-patient.selectors';

export const populateCreateProfessionalLanguageEpic =
  () => (action$: any, store: any) =>
    action$
      .ofType(LanguagesActionType.FETCH_LANGUAGES_SUCCESS)
      .flatMap((action) => {
        const currentUserLanguage = selectCurrentUserLanguageByLocale(
          store.getState(),
        );
        const currentUserLanguageISOCode = propOr(
          '',
          'id',
          currentUserLanguage,
        );
        return [
          reactReduxFormActions.change(
            `${ProfessionalFormModel.base}${ProfessionalFormModel.language}`,
            currentUserLanguageISOCode,
            { silent: true },
          ),
        ];
      });

export const createProfessionalEpic: (
  createProfessionalService: CreateProfessionalServiceImplType,
) => Epic<FixMe, any> = (createProfessionalService) => (action$, store) =>
  action$
    .ofType(CreateProfessionalActionType.CREATE_PROFESSIONAL_START)
    .switchMap(({ payload }: CreateProfessionalStartAction) =>
      Observable.fromPromise(
        createProfessionalService(
          payload,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .flatMap((data) => {
          return [
            createProfessionalSuccess(data),
            reactReduxFormActions.reset(ProfessionalFormModel.base),
            reactReduxFormActions.change(
              `${ProfessionalFormModel.base}${ProfessionalFormModel.language}`,
              (payload.professional as any).languageId,
            ),
            reactReduxFormActions.change(
              `${ProfessionalFormModel.base}${ProfessionalFormModel.openConnectivity}`,
              'true',
            ),
            reactReduxFormActions.change(
              `${ProfessionalFormModel.base}${ProfessionalFormModel.professional}`,
              selectDefaultProfessionalDepartmentId(store.getState()),
            ),
            createModal({
              key: MODAL_TYPES.CUSTOM,
              data: {
                customComponent: CreateProfessionalSuccessModal,
              },
            }),
          ];
        })
        .pipe(
          catchError((err) => {
            return Observable.concat(
              Observable.of(createProfessionalError(err)),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: CreateProfessionalErrorModal,
                  },
                }),
              ),
            );
          }),
        ),
    );

export const setDefaultLanguageWhenCreatingProfessionalEpic: () => Epic<
  LanguagesActions | any,
  State
> = () => (action$, store) =>
  action$.ofType(GET_CURRENT_USER.SUCCESS as any).flatMap(() =>
    action$.ofType(LanguagesActionType.FETCH_LANGUAGES_SUCCESS).flatMap(() => {
      const currentRouterPath = getRouterPathname(store.getState());
      const isCreateProfessionalRoute =
        currentRouterPath === professionalLinks.createProfessional;
      const currentUserLanguage = selectCurrentUserLanguageByLocale(
        store.getState(),
      );
      const currentUserLanguageId = propOr('', 'id', currentUserLanguage);
      const languagePath = 'createProfessional.language';

      return currentUserLanguageId && isCreateProfessionalRoute
        ? [reactReduxFormActions.change(languagePath, currentUserLanguageId)]
        : [];
    }),
  );

export const setOpenConnectivityWhenCreatingProfessionalEpic: () => Epic<
  ConfigurablePermissionsActions | any,
  State
> = () => (action$, store) =>
  action$
    .ofType(
      ConfigurablePermissionsActions.FETCH_CONFIGURABLES_SUCCESS,
      STATE_ACTIONS.CLEAR_CREATE_PROFESSIONAL,
    )
    .flatMap(() => {
      const openConnectivity = 'createProfessional.openConnectivity';
      return includes(
        PERMISSIONS.OPEN_CONNECTIVITY_DATA_DOWN,
        selectConfigurablePermissions(store.getState()),
      )
        ? [reactReduxFormActions.change(openConnectivity, 'true')]
        : [reactReduxFormActions.change(openConnectivity, 'false')];
    });
