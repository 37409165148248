import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type InformationIconProps = {
  title?: string;
  height?: number;
  fillColor?: string;
  originalWidth?: number;
  originalHeight?: number;
  innerCircColor?: string;
  innerRadius?: number;
};

export const InformationFilledIcon = ({
  height = 21,
  title,
  fillColor = colors.white,
  originalWidth = 20,
  originalHeight = 21,
  innerCircColor = colors.brandBlue,
  innerRadius = 15,
}: InformationIconProps) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      title={title}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      strokeColor={innerCircColor}
      radius={innerRadius}
    >
      <circle id="Oval-3" fill={innerCircColor} cx="10" cy="10" r="10" />
      <text
        id="i"
        fontFamily="Courier New"
        fontSize="15"
        fontWeight="1500"
        letterSpacing="-0.213284612"
        fill="#FFF"
      >
        <tspan x="6" y="15">
          i
        </tspan>
      </text>
    </SvgIcon>
  );
};
