import {
  DeliveryHistoryLoaderImpl,
  DeliveryHistoryServiceImpl,
  DeliveryHistoryTransformImpl,
} from './delivery-history.service';

export const mockObj = [
  {
    deliveryDate: new Date().toISOString(),
    hcpId: 123,
    id: 1,
    lastStatusId: 1234556,
    prescriptionId: 1234556,
    stripModelReference: 'Aviva',
    stripsDelivered: 1,
    atStart: 10,
    consumed: 1,
    lostStrips: 12,
    atEnd: 3,
    manualDelivered: 2,
  },
  {
    deliveryDate: new Date().toISOString(),
    hcpId: 123,
    id: 2,
    lastStatusId: 1234556,
    prescriptionId: 1234556,
    stripModelReference: 'Aviva',
    stripsDelivered: 2,
    atStart: 12,
    consumed: 132,
    lostStrips: null,
    atEnd: 23,
    manualDelivered: 2,
  },
];

const mockLoader = (): Promise<any> => Promise.resolve(mockObj);

export const DeliveryHistoryFactoryImpl = ({ devMode }) => {
  const loader = devMode ? mockLoader : DeliveryHistoryLoaderImpl;
  return DeliveryHistoryServiceImpl(loader, DeliveryHistoryTransformImpl);
};
