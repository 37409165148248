import { isNil, reject } from 'ramda';
import { createSelector } from 'reselect';

import { getFormattedStandardDeviation } from 'src/domains/diagnostics/utils';
import {
  fixToDecimalPlace,
  toFormat,
  average as getMean,
  standardDeviation as getStd,
} from 'src/utils';
import {
  selectBloodGlucoseUnit,
  selectGlucoseMeasurementsByDay,
} from 'src/domains/diagnostics/store/selectors';

import { STD_DEV_POPULATION } from './metabolic.constant';
import { selectBloodGlucoseDimensions } from './metabolic-axes.selector';

export const selectMetabolicGraphData = createSelector(
  selectGlucoseMeasurementsByDay,
  selectBloodGlucoseDimensions,
  (
    glucoseMeasurementsGroupedByDay,
    { metabolicGraphMaxX, metabolicGraphMaxY },
  ) =>
    // remove days with no non-null BG measurements (otherwise getMean fails with an empty array)
    reject(
      (dailyMeasurements) =>
        !reject(
          isNil,
          dailyMeasurements.map(({ value }) => value),
        ).length,
      glucoseMeasurementsGroupedByDay,
    ).map((dailyMeasurements, index) => {
      // remove null BG measurements
      const dailyValues = reject(
        isNil,
        dailyMeasurements.map(({ value }) => value),
      );
      const mean = getMean(dailyValues);
      const std = getStd(dailyValues);
      const stdRounded = getFormattedStandardDeviation(dailyValues, Math.round);

      return {
        meanRounded: Math.round(mean),
        stdRounded,
        mean,
        std,
        date: dailyMeasurements[0].date,
        dateLabel: toFormat('dd LLL yyyy')(dailyMeasurements[0].date),
        index,
        x: std / metabolicGraphMaxX,
        y: mean / metabolicGraphMaxY,
      };
    }),
);

export const selectFilteredMetabolicGraphData = createSelector(
  selectGlucoseMeasurementsByDay,
  selectBloodGlucoseDimensions,
  selectBloodGlucoseUnit,
  (
    glucoseMeasurementsGroupedByDay,
    { metabolicGraphMaxX, metabolicGraphMaxY },
    bloodGlucoseUnit,
  ) =>
    // remove days with no non-null BG measurements (otherwise getMean fails with an empty array)
    reject(
      (dailyMeasurements) =>
        !reject(
          isNil,
          dailyMeasurements.map(({ value }) => value),
        ).length,
      glucoseMeasurementsGroupedByDay,
    )
      .filter((dailyMeasurements) => dailyMeasurements.length > 1)
      .map((dailyMeasurements, index) => {
        // remove null BG measurements
        const dailyValues = reject(
          isNil,
          dailyMeasurements.map(({ value }) => value),
        );
        const mean = getMean(dailyValues);
        const std = getStd(dailyValues);
        const stdRounded = getFormattedStandardDeviation(
          dailyValues,
          Math.round,
          bloodGlucoseUnit,
        );
        return {
          meanRounded: mean,
          stdRounded,
          mean,
          std,
          date: dailyMeasurements[0].date,
          dateLabel: toFormat('dd LLL yyyy')(dailyMeasurements[0].date),
          index,
          x: std / metabolicGraphMaxX,
          y: mean / metabolicGraphMaxY,
        };
      }),
);
export const selectMeanBGSD = createSelector(
  selectFilteredMetabolicGraphData,
  selectBloodGlucoseDimensions,
  (dailyMeanBGSD, { metabolicGraphMaxX, metabolicGraphMaxY }) => {
    const length = dailyMeanBGSD.length;
    const totalMean = dailyMeanBGSD.reduce(
      (acc, singleDayMeanBGSD) => singleDayMeanBGSD.mean + acc,
      0,
    );
    const totalStd = dailyMeanBGSD.reduce(
      (acc, singleDayMeanBGSD) => singleDayMeanBGSD.std + acc,
      0,
    );
    const mean = totalMean / length;
    const stdDev = totalStd / length;
    return {
      meanRounded: fixToDecimalPlace(mean, 1),
      stdRounded: fixToDecimalPlace(stdDev, 1),
      mean,
      stdDev,
      x: stdDev / metabolicGraphMaxX,
      y: mean / metabolicGraphMaxY,
      selectedDayCount: length,
    };
  },
);
export const selectSD1 = createSelector(
  selectFilteredMetabolicGraphData,
  selectBloodGlucoseDimensions,
  (dailyMetabolicData, { metabolicGraphMaxX, metabolicGraphMaxY }) => {
    const dailyMeanValues = dailyMetabolicData.map(
      (dailyMeasurements) => dailyMeasurements.mean,
    );
    const dailyStdValues = dailyMetabolicData.map(
      (dailyMeasurements) => dailyMeasurements.std,
    );
    return {
      rx: dailyStdValues.length
        ? getStd(dailyStdValues, STD_DEV_POPULATION) / metabolicGraphMaxX
        : 0,
      ry: dailyMeanValues.length
        ? getStd(dailyMeanValues, STD_DEV_POPULATION) / metabolicGraphMaxY
        : 0,
    };
  },
);
