import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { fontSize } from 'src/core/styles';

import {
  DistributionTableRowWrapper,
  IconTableCell,
  StatBadge,
  DistributionTableCell,
} from './distribution-table-row.style';

export const DistributionTableRowComponent = ({ textKey, color, value, t }) => (
  <DistributionTableRowWrapper>
    <DistributionTableCell textAlign="left">
      <IconTableCell>
        <StatBadge color={color} />
        {t(`dashboard.distributionCard.statsTable.${textKey}`)}
      </IconTableCell>
    </DistributionTableCell>
    <DistributionTableCell textAlign="right" fontSize={fontSize.subheading}>
      <span>{value}%</span>
    </DistributionTableCell>
  </DistributionTableRowWrapper>
);

export const DistributionTableRow = withTranslation(
  DistributionTableRowComponent,
);
