import * as React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'src/components/protected-route';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';

import { PayerPortalBundle } from './bundles';

export const payerPortalLinks = {
  main: '/payer-dashboard',
};

export const PayerPortalRoutes = () => (
  <Switch>
    <ProtectedRoute
      exact
      path={[payerPortalLinks.main]}
      component={PayerPortalBundle}
      hasPermissions={[PERMISSIONS.PAYER, PERMISSIONS.PAYER_DASHBOARD]}
    />
  </Switch>
);
