import { path } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { CIAM_SDK_NAMESPACE } from 'src/app/session';
import { hasValue } from 'src/utils';
import { customDatadogLog, LOGS } from 'src/app/logger';

/* istanbul ignore next */ export class ProfessionalGuardComponent extends React.Component<
  any,
  { isVerifying: boolean }
> {
  // tslint:disable-next-line
  static defaultProps: Partial<any> = {
    onSuccess: (props) => props.children,
    onError: (props) => props.children,
    onVerifying: () => <div />,
  };

  public state = {
    isVerifying: true,
  };

  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    if (!hasValue(this.props.user)) {
      this.setState({
        isVerifying: true,
      });
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user || nextProps.error) {
      this.setState({
        isVerifying: false,
      });
    }
  }

  public render() {
    const { isVerifying } = this.state;
    const { onSuccess, onError, onVerifying, user, error } = this.props;
    if (isVerifying) {
      return onVerifying(this.props);
    }
    if (!user || error) {
      customDatadogLog('session', LOGS.sessionProfessionalGuardError, {
        user,
        error,
      });
      return onError(this.props);
    }
    return onSuccess(this.props);
  }
}

export const ProfessionalGuard = compose<any, Partial<any>>(
  connect((state) => ({
    user: path([CIAM_SDK_NAMESPACE, 'config', 'user'])(state),
  })),
)(ProfessionalGuardComponent);
