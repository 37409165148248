import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'react-router-redux';

import { selectPatientFhirId } from 'src/core';
import { AuthenticationRoutes } from 'src/domains/authentication/routes';
import { DashboardRoutes } from 'src/domains/patient-dashboards/routes';
import { DeviceAssignmentRoutes } from 'src/domains/device-assignment/routes';
import { DiagnosticsRoutes } from 'src/domains/diagnostics/routes';
import { GeneralRoutes } from 'src/domains/general/routes';
import { IndicatorsRoutes } from 'src/domains/indicators/routes';
import { PatientRoutes } from 'src/domains/patient/routes';
import { NewDeviceLinkRoutes } from 'src/modules/wdtc-module/routes';
import { TherapyRoutes } from 'src/modules/therapy/routes';
import { PayerPortalRoutes } from 'src/modules/payer-portal/routes';
import { ProfessionalRoutes } from 'src/domains/professional/routes';
import { ProfileRoutes } from 'src/domains/profile/routes';
import { StripManagementRoutes } from 'src/domains/strip-management/routes';
import { RpmRoutes } from 'src/domains/rpm/routes';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import {
  resetPatientSessionAction,
  startPatientSessionAction,
  savePatientSessionStartAction,
  startPatientSessionStartAction,
  SavePatientSessionStartActionProps,
} from 'src/core/patient-session/patient-session.actions';

import { selectPatientId } from 'src/core/patient/patient.selector';
import { createStructuredSelector } from 'reselect';
import { selectPatientSessionId } from 'src/core/patient-session/patient-session.selectors';
import {
  selectIsRPMEnrolled,
  selectIsTimeTrackerEnrolled,
} from 'src/core/permissions/permissions.selectors';
import { handlePatientSession, RouteHistory } from './patient-session.utils';
import { NavigatorWrapper } from './navigation-wrapper';
import { selectIsPatientSessionRunning } from 'src/core/patient-session/patient-session.selectors';
import { selectPermissions, selectJelloPermission } from 'src/core/permissions';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { selectEC6TimeZone } from 'src/core/user/user.selectors';
import { selectUID } from 'src/app/session';

import { LocalizedText } from 'src/components';
import {
  InternetExplorerNoSupportTitle,
  InternetExplorerNoSupportContainer,
} from './navigation.style';

import { ModuleLoaderComponent } from 'src/modules/module-loader';

export type NavigationComponentProps = {
  routeHistory: RouteHistory;
  resetPatientSession: () => any;
  startPatientSession: (patientId: number, sessionTime: number) => any;
  savePatientSessionStart: (props: SavePatientSessionStartActionProps) => any;
  startPatientSessionRequest: (props: any) => any;
  resetSessionStatus: () => any;
  t: (props) => any;
  patientSessionId: number | null;
  isRPMEnrolled: boolean;
  isTimeTrackerEnrolled: boolean;
  patientId: number;
  patientFhirId: string;
  hcpTimezone: string;
  isPatientSessionRunning: boolean;
  location: any;
  permissions: any;
  routes: any;
  goTo: (path: string) => void;
  gigyaUID: string;
  isBrowserInternetExplorer: boolean;
  isJelloActivated: boolean;
};

export class NavigationComponent extends React.Component<
  NavigationComponentProps,
  {}
> {
  public componentDidUpdate() {
    const currentRoute = this.props.location.pathname;
    handlePatientSession({
      ...this.props,
      routeHistory: { currentRoute },
    });
  }

  public render() {
    const {
      gigyaUID,
      permissions,
      routes,
      goTo,
      isBrowserInternetExplorer,
      isJelloActivated,
    } = this.props;

    if (isBrowserInternetExplorer) {
      return (
        <InternetExplorerNoSupportContainer>
          <InternetExplorerNoSupportTitle>
            <LocalizedText textKey={`internet-explorer-nosupport.title`} />
          </InternetExplorerNoSupportTitle>
          <LocalizedText textKey={`internet-explorer-nosupport.description`} />
        </InternetExplorerNoSupportContainer>
      );
    }

    return (
      <NavigatorWrapper
        gigyaUID={gigyaUID}
        permissions={permissions}
        routes={routes}
        goTo={goTo}
        isJelloActivated={isJelloActivated}
      >
        <Route
          key="trailing-slash"
          path="______/:url*/"
          exact
          strict
          render={(props) => (
            <Redirect to={`${props.location.pathname.slice(0, -1)}`} />
          )}
        />
        <ModuleLoaderComponent />
        <AuthenticationRoutes key="authentication-routes" />
        <GeneralRoutes key="general-routes" />
        <DashboardRoutes key="dashboard-routes" />
        <PatientRoutes key="patient-routes" />
        <StripManagementRoutes key="strip-management-routes" />
        <DiagnosticsRoutes
          key="diagnostics-routes"
          patientId={this.props.patientId}
        />
        <IndicatorsRoutes key="indicators-routes" />
        <ProfileRoutes key="profile" path="/profile" />
        <ProfessionalRoutes key="professional-routes" />
        <DeviceAssignmentRoutes key="device-assignment" />
        <RpmRoutes key="rpm-routes" />
        <TherapyRoutes key="therapy-routes" />
        <PayerPortalRoutes key="payer-portal-routes" />
        <NewDeviceLinkRoutes key="new-device-link-routes" />
      </NavigatorWrapper>
    );
  }
}

const structuredSelector = createStructuredSelector({
  patientId: selectPatientId,
  patientFhirId: selectPatientFhirId,
  patientSessionId: selectPatientSessionId,
  gigyaUID: selectUID,
  isRPMEnrolled: selectIsRPMEnrolled,
  isTimeTrackerEnrolled: selectIsTimeTrackerEnrolled,
  isPatientSessionRunning: selectIsPatientSessionRunning,
  hcpTimezone: selectEC6TimeZone,
  permissions: selectPermissions,
  routes: getCombinedRoutes,
  isJelloActivated: selectJelloPermission,
});

export const Navigation = compose(
  connect(
    structuredSelector,
    mapDispatchers({
      resetPatientSession: resetPatientSessionAction,
      startPatientSession: startPatientSessionAction,
      savePatientSessionStart: savePatientSessionStartAction,
      startPatientSessionRequest: startPatientSessionStartAction,
      goTo: (path) => push(path),
    }),
  ),
)(NavigationComponent);
