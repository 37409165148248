import { compose } from 'recompose';
import { connect } from 'react-redux';

import { mapDispatchers } from 'src/domains/diagnostics/utils';

import {
  DistributionCard,
  StickDistributionCard,
} from './distribution-card.component';
import { DistributionCardConnector } from './distribution-card.selector';

const dispatchers = mapDispatchers({});

const composed = compose(connect(DistributionCardConnector, dispatchers));

export const DistributionCardContainer = composed(DistributionCard);

export const StickDistributionCardContainer = composed(StickDistributionCard);
