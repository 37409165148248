/* tslint:disable */

import {
  transpose,
  reduce,
  max,
  times,
  concat,
  hasPath,
  path,
  head,
  mergeAll,
  isNil,
  isEmpty,
} from 'ramda';
import {
  HeaderRowType,
  BodyRowType,
} from 'src/domains/diagnostics/components/table-primary/table-primary.types';
import * as React from 'react';
import { StyledBox } from 'src/domains/diagnostics/components/table-primary/table-primary-cell/table-primary-cell.style';

import {
  colors,
  // fontWeights
} from 'src/core';
import { CirclePlayComponent } from 'src/assets/icons';

import {
  getLabelFromAttribute,
  getUnitFromAttribute,
} from './tab-basal-bolus.constants';

import { UnitValue } from '../../mock-data/tables/tables.components.style';
import { convertToHourMinuteFormat } from '../../device-settings.utils';

const moment = require('moment');

const UNCHANGED = 'UNCHANGED';
const PREV_INS_UNDEFINED = 'PREVIOUS_INSULIN_UNDEFINED';

export const convertBasalProfilesToTable = (
  ipBasalProfiles,
  previousIpBasalProfiles,
  activeIpProfile,
  disabledBasalProfileIds,
  isPreviousSettingsSelected,
  t,
  isCollapsedView,
  is12HourFormat,
) => {
  const ipProfilesWithPreviousValue = addPreviousValueToIpBasalProfiles(
    ipBasalProfiles,
    previousIpBasalProfiles,
    disabledBasalProfileIds,
    isPreviousSettingsSelected,
  );
  const basalListWithStaticData = addStaticExtraRowsDataToBasalBolusStructure(
    ipProfilesWithPreviousValue,
    t,
  );
  // add empty cells
  const rowListWithEmptyBlocks = normalizeDataInTable(basalListWithStaticData);
  const arrayTransposed = transpose(rowListWithEmptyBlocks);
  return mapTransposedDataToTableComponentStructure(
    arrayTransposed,
    activeIpProfile,
    disabledBasalProfileIds,
    isPreviousSettingsSelected,
    isCollapsedView,
    is12HourFormat,
  );
};

export const addPreviousValueToIpBasalProfiles = (
  ipBasalProfiles,
  previousIpBasalProfiles,
  disabledBasalProfileIds,
  isPreviousSettingsSelected,
) => {
  // iterate over profiles
  const profilesWithPrevTimeBlocks = ipBasalProfiles.map((profile) => {
    if (disabledBasalProfileIds.includes(profile.IdProfile)) {
      return profile;
    }
    // find profile with same name in previous version
    const matchedPrevBasalProfile = head(
      previousIpBasalProfiles.filter(
        (previousProfile) => previousProfile.IdProfile === profile.IdProfile,
      ),
    );
    if (matchedPrevBasalProfile) {
      // iterate over profile block and find same blocks by StartTime
      const timeBlockListWithPrevValues = profile.IPTimeBlocks.map(
        (currentTimeBlock) => {
          // iterate over matchedPrevBasalProfile time blocks and search for current StartTime
          const matchedPreviousTimeBlock = head(
            matchedPrevBasalProfile.IPTimeBlocks.filter(
              (currentBlock) =>
                currentBlock.StartTime === currentTimeBlock.StartTime,
            ),
          );
          // if blocks StartTime match
          if (matchedPreviousTimeBlock) {
            // compare all InsulinUnits
            return getBlocksWithPreviousValue(
              currentTimeBlock,
              matchedPreviousTimeBlock,
            );
          }
          return currentTimeBlock;
        },
      );

      return {
        ...profile,
        PrevInsulinUnitsPerDay: matchedPrevBasalProfile.InsulinUnitsPerDay
          ? matchedPrevBasalProfile.InsulinUnitsPerDay
          : PREV_INS_UNDEFINED,
        IPTimeBlocks: timeBlockListWithPrevValues,
      };
    }
    // return unchanged profile
    return profile;
  });
  return profilesWithPrevTimeBlocks;
};

export const getBlocksWithPreviousValue = (
  currentTimeBlock,
  matchedPreviousTimeBlock,
) => {
  if (currentTimeBlock.InsulinUnits === matchedPreviousTimeBlock.InsulinUnits) {
    return {
      ...currentTimeBlock,
    };
  }
  return {
    ...currentTimeBlock,
    PreviousInsulinUnits: matchedPreviousTimeBlock.InsulinUnits
      ? matchedPreviousTimeBlock.InsulinUnits
      : '-',
  };
};

export const getPathValueOrDash = (objectPath, object) =>
  hasPath(objectPath, object) ? path(objectPath, object) : '-';

export const generateBlockCellHighlighted = (
  attrPath,
  block,
  prevBlock,
  unit,
) => ({
  value: <StyledBox>{getPathValueOrDash(attrPath, block)}</StyledBox>,
  valueHighlighted: getPathValueOrDash(attrPath, prevBlock),
  unit: <UnitValue>{transformUnit(unit, attrPath)}</UnitValue>,
});

export const transformUnit = (unit, attrPath) =>
  isNil(unit) && attrPath[1] === 'Insulin' ? 'U' : unit;

export const generateBlockCell = (
  attrPath,
  block,
  unit,
  highlightCurrentValue,
) => ({
  value: highlightCurrentValue ? (
    <StyledBox>{getPathValueOrDash(attrPath, block)}</StyledBox>
  ) : (
    getPathValueOrDash(attrPath, block)
  ),
  unit: <UnitValue>{transformUnit(unit, attrPath)}</UnitValue>,
});

export const shouldCompareBlock = (attrPath, block, prevBlock) =>
  path(attrPath, block) !== path(attrPath, prevBlock) && block && prevBlock;

export const generateCellsForBlockAttribute = (
  block,
  prevBlock,
  unit,
  attrPath,
  isPreviousSettingsSelected,
  isCollapsedView,
): any => {
  if (isPreviousSettingsSelected) {
    // if they attr is different
    if (shouldCompareBlock(attrPath, block, prevBlock)) {
      return generateBlockCellHighlighted(attrPath, block, prevBlock, unit);
    }
  }
  const highlightCurrentValue =
    shouldCompareBlock(attrPath, block, prevBlock) && !isCollapsedView;
  // unchanged cell
  return generateBlockCell(attrPath, block, unit, highlightCurrentValue);
};

export const convertBolusTimeBlocksToTable = (
  bolusTimeBlocks,
  prevTimeBlocks,
  meterSettings,
  isPreviousSettingsSelected,
  isCollapsedView,
  is12HourFormat,
) => {
  const blockSettings = [
    { path: ['TargetRanges', 'Low'], setting: meterSettings.BloodGlucose },
    { path: ['TargetRanges', 'High'], setting: meterSettings.BloodGlucose },
    { path: ['CarbRatio', 'Insulin'], setting: meterSettings.Insuline },
    {
      path: ['CarbRatio', 'Carbohydrates'],
      setting: meterSettings.Carbohydrates,
    },
    {
      path: ['InsulinSensitivity', 'Insulin'],
      setting: meterSettings.Insuline,
    },
    {
      path: ['InsulinSensitivity', 'BloodGlucose'],
      setting: meterSettings.BloodGlucose,
    },
  ];

  return bolusTimeBlocks.map((block) => {
    const matchedPrevBlock = head(
      prevTimeBlocks.filter(
        (prevBlock) => block.StartTime === prevBlock.StartTime,
      ),
    );
    const cells = blockSettings.map((attribute) =>
      generateCellsForBlockAttribute(
        block,
        matchedPrevBlock,
        attribute.setting,
        attribute.path,
        isPreviousSettingsSelected,
        isCollapsedView,
      ),
    );

    return {
      type: BodyRowType.Basic,
      data: [
        {
          value:
            block.StartTime && block.StartTime !== ''
              ? convertToHourMinuteFormat(block.StartTime, is12HourFormat)
              : '-',
        },
        {
          value:
            block.EndTime && block.EndTime !== ''
              ? convertToHourMinuteFormat(block.EndTime, is12HourFormat)
              : '-',
        },
        ...cells,
      ],
    };
  });
};

const addStaticExtraRowsDataToBasalBolusStructure = (basalList, t) => {
  // generate first two rows that are special for this table
  const addedTwoRowsBasalList = basalList.map((basalItem) => {
    const extraRows = [
      {
        ...basalItem,
        InsulinUnitsPerDayUnit: `${getLabelFromAttribute('Units')}/${t(
          getLabelFromAttribute('Day'),
        )}`,
      },
      {
        ...basalItem,
        InsulinUnitsPerDayUnit: '',
        InsulinTimeString: `${t(
          getLabelFromAttribute('Time'),
        )} / ${getLabelFromAttribute('Units')}/${getLabelFromAttribute('H')}`,
      },
    ];
    const timeBlocksExtended = addProfileAttributesToTimeBlocks(
      basalItem.IPTimeBlocks,
      basalItem.IdProfile,
    );
    const basalItemWithExtraRows = extraRows.concat(timeBlocksExtended);

    return basalItemWithExtraRows;
  });

  return [...addedTwoRowsBasalList];
};

const addProfileAttributesToTimeBlocks = (IPTimeBlocks, profileId) =>
  IPTimeBlocks.map((block) => ({ ...block, IdProfile: profileId }));

const normalizeDataInTable = (basalListWithStaticData) =>
  basalListWithStaticData.map((basalItem) => {
    const firstItemProfileId = head(basalItem)
      ? head(basalItem).IdProfile
      : undefined;
    const largestNumOfBlocks = findLargestNumberOfBlocks(
      basalListWithStaticData,
    );
    const numberOfEmptyCellsToAdd = largestNumOfBlocks - basalItem.length;
    const emptyCellList = times(
      () => ({ InsulinUnits: 'EMPTY', IdProfile: firstItemProfileId }),
      numberOfEmptyCellsToAdd,
    );
    const finalList = concat(basalItem, emptyCellList);
    return finalList;
  });

const findLargestNumberOfBlocks = (basalListWithStaticData) => {
  const lengthOfProfileBlocks = basalListWithStaticData.map(
    (basalItem) => basalItem.length,
  );
  const largestNumOfBlocks = reduce(max, -Infinity, lengthOfProfileBlocks);

  return largestNumOfBlocks;
};

const cellValueOrDash = (cellValue) =>
  cellValue || cellValue === 0 ? cellValue : '-';

export const generateFirstRowBasal = (
  cellItem,
  styles,
  isPreviousSettingsSelected,
  isCollapsedView,
  isActive,
  isDisabled,
) => {
  const currentValue = cellValueOrDash(cellItem.InsulinUnitsPerDay);
  const cellContentNotStyled = <span style={styles}>{currentValue}</span>;
  const cellContentPrevious = (
    <>
      <StyledBox>
        <span style={styles}>{currentValue}</span>
      </StyledBox>
    </>
  );
  const cellContentValue = isCollapsedView
    ? cellContentNotStyled
    : cellContentPrevious;
  // changed
  if (
    cellItem.PrevInsulinUnitsPerDay &&
    cellItem.PrevInsulinUnitsPerDay !== PREV_INS_UNDEFINED
  ) {
    if (cellItem.PrevInsulinUnitsPerDay !== cellItem.InsulinUnitsPerDay) {
      const prevValue = isPreviousSettingsSelected
        ? cellItem.PrevInsulinUnitsPerDay
        : '';
      return generateCell(
        cellContentValue,
        isActive,
        isDisabled,
        prevValue,
        cellItem.InsulinUnitsPerDayUnit,
      );
    } else {
      // unchanged
      const cellContent = cellValueOrDash(cellItem.InsulinUnitsPerDay);
      return generateCell(
        cellContent,
        isActive,
        isDisabled,
        null,
        cellItem.InsulinUnitsPerDayUnit,
      );
    }
  } else if (cellItem.PrevInsulinUnitsPerDay === PREV_INS_UNDEFINED) {
    const prevValue = isPreviousSettingsSelected ? '-' : '';
    // show prev dash cell
    return generateCell(
      cellContentValue,
      isActive,
      isDisabled,
      prevValue,
      cellItem.InsulinUnitsPerDayUnit,
    );
  }

  // unchanged
  const cellContent = cellValueOrDash(cellItem.InsulinUnitsPerDay);
  return generateCell(
    cellContent,
    isActive,
    isDisabled,
    null,
    cellItem.InsulinUnitsPerDayUnit,
  );
};

const mapTransposedDataToTableComponentStructure = (
  arrayTransposed,
  activeIpProfile,
  disabledBasalProfileIds,
  isPreviousSettingsSelected,
  isCollapsedView,
  is12HourFormat,
) => {
  const styles = {
    padding: '0.05rem 0.3rem 0.05rem!important',
    display: 'inline-block',
    minWidth: '1rem',
    alignItems: 'center',
  };
  // map it to table structure
  return arrayTransposed.map((cellList) => {
    const row = cellList.map((cellItem, idx) => {
      const isActive = cellItem.IdProfile === activeIpProfile;
      const isDisabled = disabledBasalProfileIds.includes(cellItem.IdProfile);
      if (isCellEmpty(cellItem)) {
        return generateCell('-', isActive, isDisabled, null);
      }
      // cell from second row
      if (cellItem.InsulinTimeString) {
        return generateCell(
          cellItem.InsulinTimeString,
          isActive,
          isDisabled,
          null,
        );
      }
      // generate  first  row
      if (cellItem.InsulinUnitsPerDayUnit) {
        return generateFirstRowBasal(
          cellItem,
          styles,
          isPreviousSettingsSelected,
          isCollapsedView,
          isActive,
          isDisabled,
        );
      }
      // normal cell
      const previousInsulin =
        cellItem.PreviousInsulinUnits && isPreviousSettingsSelected
          ? cellItem.PreviousInsulinUnits
          : '';
      let value;
      if (cellItem.PreviousInsulinUnits && !isCollapsedView) {
        value = (
          <span>
            {convertToHourMinuteFormat(cellItem.StartTime, is12HourFormat)} /{' '}
            <StyledBox>{cellValueOrDash(cellItem.InsulinUnits)}</StyledBox>
          </span>
        );
      } else {
        value = (
          <span>
            {convertToHourMinuteFormat(cellItem.StartTime, is12HourFormat)} /{' '}
            {cellValueOrDash(cellItem.InsulinUnits)}
          </span>
        );
      }
      return {
        value: value,
        valueHighlighted: previousInsulin,
        highlight: isActive,
        disabled: isDisabled,
      };
    });
    return generateRow(row);
  });
};

export const isCellEmpty = (cell) => cell.InsulinUnits === 'EMPTY';
export const generateRow = (row) => ({
  type: BodyRowType.Basic,
  data: row,
});

export const generateCell = (
  value,
  highlight = false,
  isDisabled = false,
  valueHighlighted,
  unit = null,
): any => ({
  unit: unit,
  value: value,
  highlight: highlight,
  disabled: isDisabled,
  valueHighlighted: valueHighlighted,
});

export const generateAutoName = (id, name) => `${name} ${id}`;

export const generateBasalHeader = (basalList, activeIpProfile, t) => {
  const listOfProfiles = basalList.map((item) => ({
    name:
      isNil(item.Name) || isEmpty(item.Name)
        ? generateAutoName(
            item.IdProfile,
            t('deviceSettings.tabBasalBolus.profile'),
          )
        : item.Name,
    id: item.IdProfile,
    active: item.IdProfile === activeIpProfile,
  }));

  const basalHeaderRows = [
    {
      type: HeaderRowType.Extended,
      data: [
        {
          value: t('deviceSettings.tabBasalBolus.basal'),
          colSpan: 8,
        },
      ],
    },
    {
      type: HeaderRowType.Empty,
      data: [{ value: '' }],
    },
    {
      type: HeaderRowType.Basic,
      data: listOfProfiles.map((profile) => ({
        value: profile.name,
        highlight: profile.active,
        icon: profile.active && (
          <CirclePlayComponent height={10} fillColor={colors.brandBlue} />
        ),
      })),
    },
  ];

  return basalHeaderRows;
};

export const generateBolusHeader = (bolusTimeBlocks, t) => [
  {
    type: HeaderRowType.Extended,
    data: [
      {
        value: t('deviceSettings.tabBasalBolus.bolus'),
        colSpan: 8,
      },
    ],
  },
  {
    type: HeaderRowType.Extended,
    data: [
      {
        value: t('deviceSettings.tabBasalBolus.timeBlock'),
        colSpan: 2,
      },
      {
        value: t('deviceSettings.tabBasalBolus.targetRange'),
        colSpan: 2,
      },
      {
        value: t('deviceSettings.tabBasalBolus.carboRatio'),
        colSpan: 2,
      },
      {
        value: t('deviceSettings.tabBasalBolus.insulinSensitive'),
        colSpan: 2,
      },
    ],
  },
  {
    type: HeaderRowType.Basic,
    data: [
      {
        value: t('deviceSettings.tabBasalBolus.startTime'),
      },
      {
        value: t('deviceSettings.tabBasalBolus.endTime'),
      },
      {
        value: t('deviceSettings.tabBasalBolus.lowerLimit'),
      },
      {
        value: t('deviceSettings.tabBasalBolus.upperLimit'),
      },
      {
        value: t('deviceSettings.tabBasalBolus.insulin'),
      },
      {
        value: t('deviceSettings.tabBasalBolus.carbs'),
      },
      {
        value: t('deviceSettings.tabBasalBolus.insulin'),
      },
      {
        value: 'BG',
      },
    ],
  },
];

export const renderTBRtable = (
  data,
  previousCustomTBR,
  headerTitle,
  isPreviousSettingsSelected,
  isCollapsedView,
) => {
  const dataWithPrevious = addPreviousValueToCells(data, previousCustomTBR);
  return {
    body: dataWithPrevious.map((row) =>
      getRowTable(row, isPreviousSettingsSelected, isCollapsedView),
    ),
    header: getHeaderTable([[...headerTitle]]),
  };
};

export const compareCurrentWithPrevItem = (currentItem, prevItem) => {
  const keys = [
    {
      current: 'Adjustment',
      previous: 'PreviousAdjustment',
    },
    {
      current: 'Duration',
      previous: 'PreviousDuration',
    },
  ];
  const mergedCellData = keys.map((key) => {
    // 2 values are same
    if (currentItem[key.current] === prevItem[key.current]) {
      return {
        Name: currentItem.Name,
        [key.current]: currentItem[key.current],
        [key.previous]: UNCHANGED,
      };
    }
    return {
      Name: currentItem.Name,
      [key.current]: currentItem[key.current],
      [key.previous]: prevItem[key.current],
    };
  });
  return mergeAll(mergedCellData);
};

export const addPreviousValueToCells = (data, previousCustomTBR) =>
  data.map((item) => {
    // find item with same name
    const foundItem = head(
      previousCustomTBR.filter((prevItem) => item.Name === prevItem.Name),
    );
    if (foundItem) {
      return compareCurrentWithPrevItem(item, foundItem);
    }
    return item;
  });

export const getRowTable = (
  value,
  isPreviousSettingsSelected,
  isCollapsedView,
) => {
  const connection = {
    Adjustment: 'PreviousAdjustment',
    Duration: 'PreviousDuration',
  };
  const ignoreKeys = ['PreviousAdjustment', 'PreviousDuration'];
  const keys = Object.keys(value);
  const keysWithoutIgnored = keys.filter(
    (key) => ignoreKeys.indexOf(key) === -1,
  );
  const values = keysWithoutIgnored.map((key) => {
    const previousValue = value[connection[key]] || '-';
    const currentValue = value[key] || '-';
    const unit = <UnitValue>{getUnitFromAttribute(key)}</UnitValue>;
    const finalValue = !isNil(previousValue) ? (
      <StyledBox>{currentValue || '-'}</StyledBox>
    ) : (
      currentValue || '-'
    );

    if (key === 'Name' || previousValue === UNCHANGED || isCollapsedView) {
      return {
        value: currentValue,
        colSpan: 2,
        unit: unit,
      };
    } else if (!isPreviousSettingsSelected && previousValue) {
      return {
        value: finalValue,
        colSpan: 2,
        unit: unit,
      };
    }
    return {
      value: <>{finalValue}</>,
      colSpan: 2,
      valueHighlighted: previousValue,
      unit: unit,
    };
  });
  return {
    type: BodyRowType.Basic,
    data: [...values],
  };
};

export const getHeaderTable = (header, type = HeaderRowType.Extended) =>
  header.map((row) => ({
    type: type,
    data: row,
  }));

export const normalizeTime = (data) => {
  const resData = {};
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (
      moment(value, ['hh:mm:ss', 'hh:mm', 'HH:mm:ss', 'HH:mm'], true).isValid()
    ) {
      resData[key] = moment
        .duration(moment(value, ['HH:mm:ss', 'HH:mm']).format('HH:mm'))
        .asMinutes();
    } else {
      resData[key] = value;
    }
  });
  return resData;
};
