/* eslint-disable */
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import NotificationContainer from 'src/modules/notification-module';

import { V3HomeIcon } from 'src/assets/icons';

import {
  IconContainer,
  IconWrapper,
  NotificationModuleWrapper,
} from './icons-container.style';

import { MenuDropdown } from '../menu-dropdown/menu-dropdown.component';
import { HelpDropdown } from '../help-dropdown/help-dropdown.component';

const icons = [
  {
    id: 'home-icon',
    Component: V3HomeIcon,
    link: '/home',
    // target: '_self',
  },
  {
    id: 'help-icon',
    Component: HelpDropdown,
    // Component: V3HelpIcon,
    // link: '/help',
    // target: '_blank',
  },
  {
    id: 'notification-icon',
    Component: () => (
      <NotificationModuleWrapper>
        <NotificationContainer />
      </NotificationModuleWrapper>
    ),
  },
  {
    id: 'menu-icon',
    Component: MenuDropdown,
  },
];

type IconItem = {
  isHomeRoute: boolean;
  isHomeCgmRoute: boolean;
  hasNotificationCenterPermission: boolean;
  clearCriteria: () => void;
};

export const IconsContainer: React.FunctionComponent<IconItem> = ({
  isHomeRoute,
  isHomeCgmRoute,
  hasNotificationCenterPermission,
  clearCriteria,
}) => (
  <IconContainer>
    {icons.map((icon) => {
      const { id, Component, link } = icon;
      if (
        (id === 'notification-icon' && !hasNotificationCenterPermission) ||
        (id === 'notification-icon' &&
          hasNotificationCenterPermission &&
          !isHomeRoute &&
          !isHomeCgmRoute)
      ) {
        return <></>;
      }
      return (
        <IconWrapper
          key={id}
          className={[
            'icon',
            id,
            id === 'home-icon' && isHomeRoute ? 'at-home' : '',
          ].join(' ')}
        >
          {!!link && id !== 'home-icon' && (
            <NavLink to={link}>
              <Component />
            </NavLink>
          )}
          {!!link && id === 'home-icon' && (
            <NavLink to={link} onClick={() => clearCriteria()}>
              <Component />
            </NavLink>
          )}
          {!link && <Component />}
        </IconWrapper>
      );
    })}
  </IconContainer>
);
IconsContainer.displayName = 'IconsContainer';
