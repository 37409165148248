/* tslint:disable */

import * as React from 'react';
import { RenderIf } from 'src/utils';
import { TablePrimaryBody } from './table-primary-body/table-primary-body.style';
import { TablePrimaryHeader } from './table-primary-header/table-primary-header.style';
import { Table } from 'src/domains/diagnostics/components';
import { TablePrimaryProps } from './table-primary.types';
import { TablePrimaryRows } from './table-primary-rows/table-primary-rows.component';

export const TablePrimary = ({
  bodyData,
  headerData,
  rowStyles,
  cellStyles,
}: TablePrimaryProps) => {
  return (
    <Table>
      <RenderIf validate={headerData}>
        <TablePrimaryHeader>
          <TablePrimaryRows
            rowStyles={rowStyles}
            cellStyles={cellStyles}
            headerRowsList={headerData}
          />
        </TablePrimaryHeader>
      </RenderIf>
      <RenderIf validate={bodyData}>
        <TablePrimaryBody>
          <TablePrimaryRows
            rowStyles={rowStyles}
            cellStyles={cellStyles}
            headerRowsList={bodyData}
          />
        </TablePrimaryBody>
      </RenderIf>
    </Table>
  );
};
