import { createAuthHeader, getJSON } from 'src/utils';

import { ENDPOINTS } from 'src/services/service.constants';

export const PatientListLoaderImpl = (token) =>
  getJSON(
    ENDPOINTS.patientList,
    {},
    {
      Authorization: createAuthHeader(token),
    },
  );

export const PatientListTransformImpl = (results) =>
  results.map(({ id, name, surname, surname2 }) => ({
    id,
    firstName: name,
    surName: surname,
    surName2: surname2,
  }));

export const PatientListServiceImpl =
  (load, transform) => (token) =>
    load(token).then(transform);
