import styled from 'styled-components';

import { ControlCheckbox } from 'src/components/forms';
import { borderRadius, boxShadow, colors, transitions } from 'src/core';
import { blendHexColor, convertPxToRem } from 'src/utils';
import { createStyledComponent } from 'src/utils/styles';

const checkboxActiveColor = colors.brandBlue;
const checkboxDisabledColor = colors.grayLight;
const checkboxDisabledDarkColor = colors.grayMedium;

type CheckboxStyleProps = {
  leftPadding?;
  rightPadding?;
  disabled?;
  size?;
  labelBeforeCheckbox?;
  htmlFor?;
  model?;
};

export const CheckboxContainer = createStyledComponent<
  'label',
  CheckboxStyleProps
>('label')`
  display: flex;
  position: relative;
  padding-left: ${(props) => convertPxToRem(props.leftPadding)};
  padding-right: ${(props) => convertPxToRem(props.rightPadding)};
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;
CheckboxContainer.displayName = 'CheckboxContainer';

export const CheckboxInput = createStyledComponent<'input', CheckboxStyleProps>(
  'input',
)`
  position: absolute;
  opacity: 0;

  &:checked + span {
    background-color: ${(props) =>
      props.disabled ? checkboxDisabledDarkColor : checkboxActiveColor};
    border-color: ${(props) =>
      props.disabled ? checkboxDisabledDarkColor : checkboxActiveColor};
    &:hover {
      background-color: ${blendHexColor(checkboxActiveColor, 5)};
    }
  }

  &:checked + span > span {
    color: ${colors.white};
    visibility: visible;
  }

  &:hover + span,
  &:focus + span {
    border-color: ${checkboxActiveColor};
    box-shadow: ${boxShadow({
      color: checkboxActiveColor,
      depth: 'base',
    })};
  }
`;
CheckboxInput.displayName = 'CheckboxInput';

export const CheckboxControl = CheckboxInput.withComponent(
  ControlCheckbox as any,
);

export const CheckboxCheckMarkContainer = createStyledComponent<
  'span',
  CheckboxStyleProps
>('span')`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid
    ${(props) => (props.disabled ? checkboxDisabledColor : colors.gray)};
  border-radius: ${borderRadius.three};
  display: flex;
  height: ${(props) => convertPxToRem(props.size)};
  justify-content: center;
  left: ${(props) => (props.labelBeforeCheckbox ? 'inherit' : 0)};
  position: absolute;
  right: ${(props) => (!props.labelBeforeCheckbox ? 'inherit' : 0)};
  text-align: center;
  top: 0;
  transition: ${transitions.default};
  width: ${(props) => convertPxToRem(props.size)};
`;
CheckboxCheckMarkContainer.displayName = 'CheckboxCheckMarkContainer';

export const CheckboxCheckMark = styled.span`
  visibility: hidden;
`;
CheckboxCheckMark.displayName = 'CheckboxCheckMark';
