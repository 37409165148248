// @ts-nocheck
import * as React from 'react';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { TextModal } from '../../pdf-report.style';
import { IconWrapper, ButtonWrapper } from './pdf-report-fail.style';
import { Button } from 'src/components/button';
import { DisclaimerIcon } from 'src/assets/icons';
import { colors } from 'src/core';

const PdfReportsFailComponentModal = ({ onRetry, t, text }) => (
  <>
    <IconWrapper>
      <DisclaimerIcon
        withBorder
        width={75}
        height={75}
        borderFillColor={colors.red}
        iconColor={colors.white}
        borderColor={colors.white}
      />{' '}
    </IconWrapper>
    <TextModal textAlign="center">{t('pdfReport.error')}</TextModal>
    <TextModal textAlign="center">
      {t('pdfReport.pdfNotGenerated', { patientInfo: text })}
    </TextModal>
    <ButtonWrapper>
      <Button onClick={onRetry} label={t('pdfReport.retry')} />
    </ButtonWrapper>
  </>
);

export const PdfReportsFailModal = withTranslation(
  PdfReportsFailComponentModal,
);
