import { CountlySystem } from '@roche/roche-common';

export const countlyEventTrigger = (
  eventTitle: string,
  segment: { [segKey: string]: any },
): void => {
  const countly = new CountlySystem(window.Countly);
  if (countly.Countly) {
    countly.trackCustomEvent(eventTitle, segment);
  }
};
