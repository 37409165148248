import { getJSON, createAuthHeader } from 'src/utils/service/service.utils'; //eslint-disable-line

import { removePermissionsEMR } from './permissions.utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

import { selectPatientFhirId } from 'src/core/patient/patient.selector';

import { randomValue } from 'src/utils/stat.js';

const TARGET_ID_TYPES = {
  hcp: 'HCP',
  user: 'USER',
  fhir: 'FHIR',
  gigya: 'GIGYA',
  association: 'ASSOCIATION',
  hcid: 'HCID',
}

export const PermissionsServiceLoaderImpl = (
  _params,
  accessToken
  ) =>
    getJSON(
      endpointWithParams(ENDPOINTS.permissions, { random: randomValue(1) }),
      {
        headers: {
          Authorization: createAuthHeader(accessToken),
        },
      },
    );


export const PatientPermissionsServiceLoaderImpl = (
  gigyaUid,
  accessToken,
  gigyaToken,
  fhirId,
  hasRolePlatform,
) => {
  const headers =  {
    Authorization: createAuthHeader(accessToken),
    ...(hasRolePlatform) && {targetIdType: TARGET_ID_TYPES.fhir, targetId: fhirId}
  }

  if(hasRolePlatform) {
    return getJSON(ENDPOINTS.permissionsWithPlatform,
      { headers },
    );
  }

  return getJSON(
    endpointWithParams(ENDPOINTS.permissionsPatients, { gigyaUid, random: randomValue(1) }),
    { headers },
  );
}

export const transformPermissions = (p) => removePermissionsEMR(p);

 export const PermissionsService =
  (load, transform) => (query, accessToken, gigyaToken, fhirId, hasRolePlatform) =>
    load(query, accessToken, gigyaToken, fhirId, hasRolePlatform).then(transform);
