import React from 'react';
import { countlyEventTrigger } from 'src/navigation/countly';
import { BG_GRAPH_SEGMENT_VALUES } from 'src/domains/patient-dashboards/components/tabs-header/tabs-header.countly.constans';
import {
  BG_GRAPH_LEGEND_SEGMENT_VALUES,
  BG_GRAPH_LEGEND_STATISTIC_KEYS,
  BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS,
} from 'src/domains/diagnostics/components/graph-controls/graph-controls.countly.constans';

import {
  GraphLegend,
  GraphStatistics,
} from 'src/domains/diagnostics/components';
import { MenuIcon } from 'src/assets/icons';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

const keyTextTabs = {
  legend: 'legend',
  statistics: 'statistics',
};
export const getGraphDetailTabs = (
  graphType,
  graphStatistics,
  showChangeGraphToggle,
  graph,
) => {
  const tabs = [
    {
      label: <MenuIcon />,
      keyText: keyTextTabs.legend,
      content: <GraphLegend {...{ graphType, showChangeGraphToggle, graph }} />,
    },
  ];

  if (
    [
      GRAPHS.LOGBOOK,
      GRAPHS.DEVICE_SETTINGS,
      GRAPHS.LOGBOOK_STATS,
      GRAPHS.INSULIN_PUMP,
      GRAPHS.LOGBOOK_24HR,
    ].includes(graphType)
  ) {
    return tabs;
  }

  return [
    ...tabs,
    {
      label: '%',
      keyText: keyTextTabs.statistics,
      content: (
        <GraphStatistics
          graph={graph}
          graphType={graphType}
          graphDetails={graphStatistics}
        />
      ),
    },
  ];
};

export const graphTypeSelected = (graph) => {
  switch (graph) {
    case GRAPHS.TREND:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_TREND;
    case GRAPHS.STANDARD_DAY:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_STANDARD_DAY;
    case GRAPHS.STANDARD_WEEK:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_STANDARD_WEEK;
    case GRAPHS.LOGBOOK:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_LOGBOOK;
    case GRAPHS.LOGBOOK_DIARY:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_LOGBOOK_DIARY;
    case GRAPHS.LOGBOOK_STATS:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_LOGBOOK_STATS;
    case GRAPHS.LOGBOOK_24HR:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_LOGBOOK_24H;
    case GRAPHS.METABOLIC_RATE:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_METABOLIC_RATE;
    case GRAPHS.INSULIN:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_INSULIN;
    case GRAPHS.INSULIN_PUMP:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_INSULIN_PUMP;
    case GRAPHS.BLOOD_GLUCOSE_GENERAL_STATS:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_BLOOD_GLUCOSE_GENERAL_STATS;
    case GRAPHS.DEVICE_SETTINGS:
      return BG_GRAPH_SEGMENT_VALUES.SELECTED_GRAPH_DEVICE_SETTINGS;
    default:
      return BG_GRAPH_SEGMENT_VALUES.NO_NAME_GRAPH_SELECTED;
  }
};

export const legendStatisticsTabOpenedCountly = (
  tab,
  showChangeGraphToggle,
  graph,
) => {
  switch (tab.keyText) {
    case keyTextTabs.legend:
      return countlyEventTrigger(
        BG_GRAPH_LEGEND_STATISTIC_KEYS.BG_GRAPH_LEGEND_OPENED,
        {
          [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_GRAPH_TYPE]:
            graphTypeSelected(graph),
          [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_LOCATION]:
            showChangeGraphToggle
              ? BG_GRAPH_LEGEND_SEGMENT_VALUES.GRAPH_PAGE
              : BG_GRAPH_LEGEND_SEGMENT_VALUES.BG_DASHBOARD,
        },
      );
    case keyTextTabs.statistics:
      return countlyEventTrigger(
        BG_GRAPH_LEGEND_STATISTIC_KEYS.BG_GRAPH_STATISTICS_OPENED,
        {
          [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_GRAPH_TYPE]:
            graphTypeSelected(graph),
          [BG_GRAPH_LEGEND_STATISTIC_SEGMENT_KEYS.BG_LOCATION]:
            showChangeGraphToggle
              ? BG_GRAPH_LEGEND_SEGMENT_VALUES.GRAPH_PAGE
              : BG_GRAPH_LEGEND_SEGMENT_VALUES.BG_DASHBOARD,
        },
      );
    default:
      return;
  }
};
