// @ts-nocheck
import * as React from 'react';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { LocalForm, actions } from 'react-redux-form';
import { createStructuredSelector } from 'reselect';
import { matchPath, withRouter } from 'react-router-dom';
import { match } from 'react-router';
import * as H from 'history';

import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { breakpointsMap } from 'src/core';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { LocalizedText } from 'src/components';

import { onPatientDatesRangeChange } from 'src/core/patient-date-range/patient-date-range.action';
import { selectIsAdministrative } from 'src/domains/general/widgets/store/hcp-dashboard.selectors';
import { selectEC6UserLanguage } from 'src/core/user/user.selectors';
import { dateRangeConnector } from 'src/core/patient-date-range/patient-date-range.selector';
import { piConnector } from 'src/core/pi/pi.selectors';
import {
  cgmDateConnector,
  selectCGMSelectedDate,
} from 'src/domains/patient-dashboards/cgm/store/selectors/cgm.selectors';
import { selectFhirPermission } from 'src/core/permissions/permissions.selectors';
import { selectPatientFhirId } from 'src/core/patient/patient.selector';
import { onCgmDatesRangeChange } from 'src/domains/patient-dashboards/cgm/store/cgm.actions';
import {
  Periods,
  BlockWrapper,
  ControlContainer,
  Title,
} from './period-selectors.style';
import { SpacerShort } from '../patient-info/patient-info.style';

import { generatePeriodsList } from './period-selectors.constants';
import { Calendar } from './calendar';

import { getStartEndDates } from './calendar/calendar.utils';
import { RenderIf } from 'src/utils';
import { isNotEqual } from 'src/utils';
import { map } from 'ramda';
import { countlyEventTrigger } from 'src/navigation/countly';
import {
  EVENTS_KEYS,
  SEGMENT_KEYS,
  SEGMENT_VALUES,
} from './period-selectors.countly.constants';
import { ReportsContainer } from 'src/modules/reports-module/reports.container';
import { JelloOptionPicker } from 'src/components/option-picker-jello';
import { JELLO_OPTION_PICKER_BACKGROUND } from 'src/app/app.jello.constants';

import { periodSelectorAccessibilityText } from 'src/domains/patient-dashboards/patient-dashboard-jello/patient-dashboard-jello.constants.ts';

type dateInfoBg = {
  startDate: any;
  endDate: any;
  firstMeasurementDate: any;
  lastMeasurementDate: any;
};

type dateInfoPi = {
  calculationPeriod: any;
  hasPatientPatternsConfiguration: boolean;
};

type PeriodSelectorsState = {
  buttonSelected: number;
  comboSelected: string;
  wrapWidth: number;
  isPatternActive: boolean;
  isBgActive: boolean;
  isCgmActive: boolean;
  dateInfo: any;
  displayCal: boolean;
  isOpen: boolean;
};

type PeriodSelectorsOutterProps = {
  showPeriod?: boolean;
  showArrowCalendar?: boolean;
  idCalendar?: string;
  classNameCalendar?: string;
  onDateCalendarChange?: (
    patientId: any,
    startDate: any,
    endDate: any,
    range: any,
  ) => any;
};

type PeriodSelectorsProps = PeriodSelectorsOutterProps & {
  routes: FixMe;
  location: H.Location<{}>;
  dateControls: boolean;
  t: TFunction;
  locale: any;
  dateInfoBg: dateInfoBg;
  hasUserFhirPermission: boolean;
  dateInfoCgm: dateInfoBg;
  cgmSelectedDate: string;
  dateInfoPi: dateInfoPi;
  patientFhirId: string;
  match: match<{ id: string }>;
  onDatesChange: (
    patientId: any,
    startDate: any,
    endDate: any,
    range: any,
    hasUserFhirPermission: boolean,
    patientFhirId: string,
  ) => any;
  onCgmDatesChange: (startDate: any, endDate: any, range: any) => any;
  firstMeasurementDate: any;
  lastMeasurementDate: any;
  isAdmin: boolean;
};

export class PeriodSelectorsBlockComponent extends React.Component<
  PeriodSelectorsProps,
  PeriodSelectorsState
> {
  public formDispatch;

  public constructor(props) {
    super(props);

    const { routes, dateInfoBg, dateInfoCgm } = props;
    const isCgmActive = this.checkPath([routes.dashboard.cgmId]);
    const isPatternActive = this.checkPath([routes.indicators.main]);
    let dateInfo = isCgmActive ? dateInfoCgm : dateInfoBg;
    let range = dateInfo?.range;

    if (isPatternActive) {
      const piData = this.getPatternsData(props);
      dateInfo = piData.dateInfo;
      range = piData.range;
    }

    this.state = {
      buttonSelected: range,
      comboSelected: `${range}`,
      wrapWidth: window.outerWidth,
      isPatternActive,
      isBgActive: false,
      isCgmActive: false,
      dateInfo,
      displayCal: false,
      isOpen: false,
    };
  }

  public componentDidMount() {
    this.adaptToWindowSize();
    this.initState(this.props);
    window.addEventListener('resize', this.adaptToWindowSize);
    this.setState({ isOpen: false });
  }

  public componentWillReceiveProps(newProps) {
    const { isBgActive, isCgmActive, isPatternActive } = this.state;
    const { dateInfoBg: nG, dateInfoCgm: nC, dateInfoPi: nP } = newProps;
    const { dateInfoBg: oG, dateInfoCgm: oC, dateInfoPi: oP } = this.props;

    const handleNulls = (o) => map((n) => n || '', o);

    if (isBgActive && isNotEqual(handleNulls(nG))(handleNulls(oG))) {
      this.initState(newProps);
    }

    if (isCgmActive && isNotEqual(handleNulls(nC))(handleNulls(oC))) {
      this.initState(newProps);
    }

    if (isPatternActive && isNotEqual(handleNulls(nP))(handleNulls(oP))) {
      this.initState(newProps);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.adaptToWindowSize);
  }

  public render() {
    const { t, isAdmin, showPeriod = true, cgmSelectedDate } = this.props;
    const { wrapWidth, isPatternActive, isOpen } = this.state;
    const breakPoint = breakpointsMap[1];

    return (
      <React.Fragment>
        {!isAdmin && (
          <React.Fragment>
            <jello-grid>
              <jello-row style={{ float: 'right' }}>
                <jello-col>
                  <RenderIf validate={showPeriod}>
                    <LocalForm
                      model="configuration"
                      getDispatch={(dispatch) => (this.formDispatch = dispatch)}
                      initialState={this.state}
                      onChange={(e) => this.setButton(Number(e.comboSelected))}
                    >
                      <JelloOptionPicker
                        id={'periodsOptionPicker'}
                        label={t('patientBar.rangeTitle') || ''}
                        options={generatePeriodsList(t)}
                        value={this.state.comboSelected}
                        backgroundColor={
                          JELLO_OPTION_PICKER_BACKGROUND.BACKGROUND_01
                        }
                        disabled={!this.isEnabled() || !!cgmSelectedDate}
                        accessibilityText={periodSelectorAccessibilityText}
                        isOpen={isOpen}
                        onClick={this.handleOnClickPeriodSelector}
                        onChange={(e) => {
                          const range = e.target.value;
                          this.countlyTimePeriod(range);
                          this.actionCombo(range);
                          // TODO: DCM esto sobra ??
                          this.setState({
                            displayCal: isPatternActive
                              ? false
                              : `${range}` === '4',
                          });
                          this.setState({ ...this.state, isOpen: false });
                        }}
                      />
                    </LocalForm>
                  </RenderIf>
                </jello-col>

                <jello-col style={{ paddingTop: 0 }}>
                  {this.drawCalendar()}
                </jello-col>
                <jello-col style={{ paddingTop: 0 }}>
                  <ReportsContainer />
                </jello-col>
              </jello-row>
            </jello-grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  // patterns: if no calculationPeriod -> no button selected
  // if has value but not 14 or 28 -> CUSTOM
  public getPatternsData = (props) => {
    const { dateInfoBg, dateInfoPi } = props;
    const dateInfo = Object.assign({}, dateInfoBg, dateInfoPi);
    const cp =
      !!dateInfoPi.hasPatientPatternsConfiguration &&
      dateInfoBg.lastMeasurementDate
        ? dateInfo.calculationPeriod
        : null;
    const range = cp ? ([14, 28].indexOf(cp) > -1 ? cp : 4) : 0;
    return { dateInfo, range };
  };

  handleOnClickPeriodSelector = () => {
    this.setState({ ...this.state, isOpen: true });
  };

  public isDisabled = () => {
    const { dateInfo, isBgActive, isCgmActive, isPatternActive } = this.state;
    const {
      hasPatientPatternsConfiguration,
      calculationPeriod,
      lastMeasurementDate,
    } = { ...dateInfo };
    if (isPatternActive) {
      return !(
        hasPatientPatternsConfiguration &&
        calculationPeriod &&
        lastMeasurementDate
      );
    }
    if (isBgActive || isCgmActive) {
      return !dateInfo.lastMeasurementDate || !!this.props.cgmSelectedDate;
    }
    return true;
  };

  public isEnabled = () => {
    const { dateInfo, isBgActive, isCgmActive } = this.state;
    const hasLastMeasurementDate = !!dateInfo?.lastMeasurementDate;
    return (isBgActive || isCgmActive) && hasLastMeasurementDate;
  };

  public adaptToWindowSize = () => {
    const wrapWidth = window.outerWidth;
    this.setState({ ...this.state, wrapWidth });
  };

  private setButton = (data: number) => {
    const breakPoint = breakpointsMap[1];
    const { match } = this.props;
    const { isPatternActive, wrapWidth } = this.state;
    if (wrapWidth > breakPoint) {
      this.countlyTimePeriod(data);
    }
    const patientId = match.params.id;

    if (patientId) {
      this.setState(
        {
          ...this.state,
          displayCal: isPatternActive ? false : `${data}` === '4',
          buttonSelected: data,
          comboSelected: data.toString(),
          dateInfo: {
            ...this.state.dateInfo,
            range: data,
          },
        },
        () => {
          const { dateInfo } = this.state;
          if (
            `${data}` !== '4' &&
            `${data}` !== '0' &&
            dateInfo.lastMeasurementDate
          ) {
            const { startDate, endDate } = getStartEndDates(dateInfo, data);
            if (isNotEqual(this.state.dateInfo)(this.props.dateInfoBg)) {
              this.onDatesChange(startDate, endDate);
            }
          }
        },
      );
    }
  };

  private onDatesChange = (startDate, endDate) => {
    const moment = require('moment');
    const {
      onCgmDatesChange,
      onDatesChange,
      match,
      patientFhirId,
      hasUserFhirPermission,
    } = this.props;

    const { isCgmActive, buttonSelected } = this.state;
    const patientId = match.params.id;
    const sDate = startDate.startOf('day');
    sDate.parseZone().utc(true).format();
    const eDate = endDate.endOf('day');
    eDate.parseZone().utc(true).format();
    if (patientId) {
      isCgmActive
        ? onCgmDatesChange(sDate, eDate, buttonSelected)
        : onDatesChange(
            patientId,
            sDate,
            eDate,
            buttonSelected,
            hasUserFhirPermission,
            patientFhirId,
          );
    }
    this.setState({
      dateInfo: {
        ...this.state.dateInfo,
        startDate: moment.utc(sDate).format(),
        endDate: moment.utc(eDate).format(),
        range: buttonSelected,
      },
    });
  };

  private countlyTimePeriod = (timePeriod) => {
    if (timePeriod !== '4') {
      countlyEventTrigger(EVENTS_KEYS.DATE_RANGE_CHANGED, {
        [SEGMENT_KEYS.DATE_CHANGE_METHOD]: SEGMENT_VALUES.TIME_PERIOD_SELECTION,
        [SEGMENT_KEYS.DATE_CHANGE_LOCATION]: this.state.isBgActive
          ? SEGMENT_VALUES.BG_VIEW
          : this.state.isCgmActive
          ? SEGMENT_VALUES.CGM_VIEW
          : SEGMENT_VALUES.PATTERNS_VIEW,
      });
    }
  };

  private checkPath = (paths) => {
    const { location } = this.props;
    let success = false;
    paths.forEach((path) => {
      const checkIt = !!matchPath(location.pathname, {
        path,
        exact: true,
      });
      if (checkIt) success = true;
    });
    return success;
  };

  private actionCombo = (range) => {
    if (this.formDispatch) {
      this.formDispatch(
        actions.load('configuration', { comboSelected: range.toString() }),
      );
    }
  };

  private initState = (props) => {
    const { routes, dateInfoBg, dateInfoCgm } = props;
    const isPatternActive = this.checkPath([routes.indicators.main]);
    const isBgActive = this.checkPath([
      routes.dashboard.bgId,
      routes.diagnostics.trend,
      routes.diagnostics.standardDay,
      routes.diagnostics.standardWeek,
      routes.diagnostics.logbook,
      routes.diagnostics.metabolicRate,
      routes.diagnostics.bloodGlucoseGeneralStats,
      routes.diagnostics.bloodGlucoseGeneralStatsAll,
      routes.diagnostics.bloodGlucoseGeneralStatsById,
      routes.diagnostics.insulin,
      routes.diagnostics.insulinPump,
      routes.diagnostics.deviceSettings,
    ]);
    const isCgmActive = this.checkPath([routes.dashboard.cgmId]);
    let dateInfo;
    let range;

    if (isPatternActive) {
      const piData = this.getPatternsData(props);
      dateInfo = piData.dateInfo;
      range = piData.range;
      if ([4, 7, 14, 28].indexOf(range) > -1) {
        const { startDate, endDate } = getStartEndDates(
          dateInfo,
          dateInfo.calculationPeriod,
        );
        this.onDatesChange(startDate, endDate);
        dateInfo.startDate = startDate;
        dateInfo.endDate = endDate;
      }
      this.actionCombo(range);
    } else if (isBgActive || isCgmActive) {
      dateInfo = isCgmActive ? dateInfoCgm : dateInfoBg;
      range = !dateInfo.lastMeasurementDate ? 0 : dateInfo.range || 14;
      this.actionCombo(range);
    } else {
      dateInfo = dateInfoBg;
      this.actionCombo(0);
    }

    this.setState({
      ...this.state,
      dateInfo,
      buttonSelected: range,
      comboSelected: `${range}`,
      isPatternActive,
      isBgActive,
      isCgmActive,
    });
  };

  private drawCalendar = () => {
    const {
      buttonSelected,
      wrapWidth,
      isPatternActive,
      isBgActive,
      isCgmActive,
      dateInfo,
      displayCal,
    } = this.state;
    const {
      locale,
      showArrowCalendar = true,
      idCalendar,
      classNameCalendar,
      onDateCalendarChange,
    } = this.props;
    const { calculationPeriod } = { ...dateInfo };

    let props: any = {};
    const defaultRange = buttonSelected || 14;

    const piProps = {
      range: calculationPeriod,
      maxDateRange: 0,
    };

    const bgProps = {
      range: defaultRange,
      maxDateRange: 0,
    };

    const cgmProps = {
      range: defaultRange,
      maxDateRange: 28,
    };

    if (isPatternActive) props = { ...props, ...piProps };
    if (isBgActive) props = { ...props, ...bgProps };
    if (isCgmActive) props = { ...props, ...cgmProps };

    return (
      <Calendar
        dateInfo={dateInfo}
        wrapWidth={wrapWidth}
        onDatesChange={
          onDateCalendarChange ? onDateCalendarChange : this.onDatesChange
        }
        locale={locale}
        range={this.state.buttonSelected}
        disabled={this.isDisabled()}
        maxDateRange={props.maxDateRange}
        isPatternActive={isPatternActive}
        isCgmActive={isCgmActive}
        displayCal={displayCal}
        showArrows={showArrowCalendar}
        id={idCalendar}
        className={classNameCalendar}
        cgmSelectedDate={this.props.cgmSelectedDate}
      />
    );
  };
}

export const PeriodSelectors = withRouter(
  // @ts-ignore
  withTranslation(
    connect(
      null,
      mapDispatchers({
        onDatesChange: onPatientDatesRangeChange,
        onCgmDatesChange: onCgmDatesRangeChange,
      }),
    )(
      connect(
        createStructuredSelector({
          routes: getCombinedRoutes,
          locale: selectEC6UserLanguage,
          dateInfoBg: dateRangeConnector,
          isAdmin: selectIsAdministrative,
          hasUserFhirPermission: selectFhirPermission,
          patientFhirId: selectPatientFhirId,
          dateInfoPi: piConnector,
          dateInfoCgm: cgmDateConnector,
          cgmSelectedDate: selectCGMSelectedDate,
        }),
      )(PeriodSelectorsBlockComponent),
    ),
  ),
);
