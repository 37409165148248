import styled from 'styled-components';

import {
  spacing,
  colors,
  fontWeights,
  borderRadius,
  boxShadows,
  fontSize,
  ButtonReset,
  transitions,
} from 'src/core';
import { BaseCard, Display1 } from 'src/components';
import { convertPxToRem, combineRems } from 'src/utils';

export const SearchPatientContainer = styled.div`
  max-width: ${convertPxToRem(670)};
  width: 100%;
`;

export const SearchPatientWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
  flex-direction: column;
`;

export const SearchPatientDiv = styled(BaseCard)`
  border-top: 5px solid ${colors.brandBlue};
  margin-bottom: ${combineRems(spacing.one, spacing.three)};
`;

export const SearchPatientFormDiv = styled.div`
  padding: ${convertPxToRem(25)};
`;

export const PatientSearchFields = styled.div`
  width: 100%;
`;

export const AddNewPatientButton = styled(ButtonReset)`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.white};
  border-radius: ${borderRadius.six};
  box-shadow: ${boxShadows.two};
  display: flex;
  font-size: ${fontSize.heading};
  height: ${convertPxToRem(80)};
  justify-content: center;
  transition: ${transitions.default};
  width: 100%;
  &.active {
    color: ${colors.brandBlue};
    &:hover,
    &:focus {
      border-color: ${colors.brandBlue};
    }
  }
  &.disabled {
    cursor: default;
    color: ${colors.grayMedium};
  }
`;

export const TitleName = styled(Display1)`
  color: ${colors.brandBlue};
  font-weight: ${fontWeights.semiBold};
  ${(props) => props.uppercase && 'text-transform: uppercase'};
  line-height: 1;
  margin: 0 auto ${combineRems(spacing.two, spacing.three)};
`;

export const Description = styled.div`
  font-size: ${fontSize.subheading};
  color: ${colors.charcoal};
  margin: 0;
`;

export const PatientSearchFieldLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.black};
`;

PatientSearchFieldLabel.displayName = 'PatientSearchFieldLabel';
