// tslint:disable
import { postJSON, stringifyBody, createAuthHeader } from 'src/utils/service/service.utils';
import { PatientSessionStartLoaderImplType } from './patient-session-start.types';
import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const PatientSessionStartLoaderImpl: PatientSessionStartLoaderImplType =
  ({ patientId, hcpTimezone }, openId) =>
    postJSON(endpointWithParams(ENDPOINTS.patientSessionStart, { patientId }), {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),        
        Accept: 'application/json',
      },
      body: stringifyBody({
        patientId,
        hcpTimezone,
      }),
    });

export const PatientSessionStartTransformImpl: any = (data) => data;

export const PatientSessionStartServiceImpl: any =
  (load, transform) => (params, openId) =>
    load(params, openId).then(transform);
