import { hasValue } from 'src/utils/ramda';

import {
  DELIVERY_CONFIGURATION_MIN_NUMBER,
  DELIVERY_CONFIGURATION_MAX_PERCENTAGE,
  DELIVERY_CONFIGURATION_MAX_INITIAL_STOCK,
  DELIVERY_CONFIGURATION_MAX_TIME_GAP,
} from './delivery-configuration.constants';

export const DeliveryConfigurationValidators = {
  isRequired: (string: string) => hasValue(string),
  isValidNumber: (string: string) => isInteger(string),
  isValidStock: (string: string) => isWithinInitialStockLimit(string),
  isValidPercentage: (string: string) => isWithinPercentageLimit(string),
  isValidTimeGap: (string: string) => isWithinTimeGapLimit(string),
};

export type DeliveryConfigurationErrorsTypes = {
  isRequired: string;
  isValidNumber: string;
  isValidStock: string;
  isValidPercentage: string;
  isValidTimeGap: string;
};

export const DeliveryConfigurationErrors: DeliveryConfigurationErrorsTypes = {
  isRequired: 'patient.deliveryConfiguration.validations.isRequired',
  isValidNumber: 'patient.deliveryConfiguration.validations.isValidNumber',
  isValidStock: 'patient.deliveryConfiguration.validations.isValidStock',
  isValidPercentage:
    'patient.deliveryConfiguration.validations.isValidPercentage',
  isValidTimeGap: 'patient.deliveryConfiguration.validations.isValidTimeGap',
};

const NUMERIC_REGEX = /^[0-9]*$/;
export const isInteger = (value: string) =>
  hasValue((String(value) || '').match(NUMERIC_REGEX));

export const isWithinInitialStockLimit = (stock: string) => {
  const value = parseInt(stock, 10);
  return (
    hasValue(value) &&
    value >= DELIVERY_CONFIGURATION_MIN_NUMBER &&
    value <= DELIVERY_CONFIGURATION_MAX_INITIAL_STOCK
  );
};

export const isWithinPercentageLimit = (percentage: string) => {
  const value = parseInt(percentage, 10);
  return (
    hasValue(value) &&
    value >= DELIVERY_CONFIGURATION_MIN_NUMBER &&
    value <= DELIVERY_CONFIGURATION_MAX_PERCENTAGE
  );
};

const isWithinTimeGapLimit = (percentage: string) => {
  const value = parseInt(percentage, 10);
  return (
    hasValue(value) &&
    value >= DELIVERY_CONFIGURATION_MIN_NUMBER &&
    value <= DELIVERY_CONFIGURATION_MAX_TIME_GAP
  );
};
