import * as React from 'react';

import { XIcon } from 'src/assets/icons';
import { Badge } from 'src/components/badge';
import { colors } from 'src/core/styles';

type ErrorBadgeProps = {
  size: number;
};

export const ErrorBadge: React.FunctionComponent<ErrorBadgeProps> = ({
  size,
}) => (
  <Badge
    bgColor={colors.trafficRed}
    icon={<XIcon height={size * 0.38} />}
    size={size}
    emptyInnerCircle={false}
    disabled={false}
  />
);
