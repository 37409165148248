import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  HashIcon,
  RectangleMarkIcon,
} from 'src/assets/icons';
import { colors } from 'src/core/styles';
import { RenderIf } from 'src/domains/diagnostics/utils';

import { LegendItem } from './legend-item.component';

import {
  LegendList,
  LegendListsContainer,
  LegendListSectionHeader,
} from '../graph-legend.style';

export const LogbookGraphLegendComponent = ({ t, graph }) => (
  <LegendListsContainer>
    <LegendList noBorder width={'30%'}>
      <LegendListSectionHeader>
        {t('graphDetails.legend.targetRangeSectionHeader')}
      </LegendListSectionHeader>
      <LegendItem
        icon={
          <RectangleMarkIcon
            fillColor={colors.quartzBlue}
            strokeColor={colors.quartzBlue}
          />
        }
        label={t('graphDetails.legend.aboveTargetRange')}
      />
      <LegendItem
        icon={
          <RectangleMarkIcon
            fillColor={colors.trafficRed2}
            strokeColor={colors.trafficRed2}
          />
        }
        label={t('graphDetails.legend.belowTargetRange')}
      />
      <RenderIf validate={graph === '24hour'}>
        <LegendItem
          icon={<HashIcon height={12} fillColor="#008D05" />}
          label={t('graphDetails.legend.inTargetRange')}
        />
      </RenderIf>
      <RenderIf validate={graph !== '24hour'}>
        <LegendItem
          icon={
            <RectangleMarkIcon
              fillColor={colors.white}
              strokeColor={colors.trafficRed2}
            />
          }
          label={t('graphDetails.legend.hypoSymptoms')}
        />
      </RenderIf>
    </LegendList>
  </LegendListsContainer>
);

export const LogbookGraphLegend = withTranslation(LogbookGraphLegendComponent);
