import { lensPath, set } from 'ramda';

import {
  STATE_ACTIONS,
  FETCH_PATIENT_DATE_RANGE_REQUEST,
  FETCH_PATIENT_REQUEST,
} from 'src/core';
import { DEVICE_SETTINGS_TYPE_BASAL_BOLUS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { TIME_INTERVAL } from 'src/domains/diagnostics/constants/diagnostics.constants';

import {
  FETCH_BG_OVERVIEW_END_DATE_REQUEST,
  FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST,
  FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST,
  GET_CLINICAL_DATA_REQUEST,
  GET_CLINICAL_DATA_FROM_FHIR_REQUEST,
  PATIENT_DASHBOARD_ACTIONS,
  FETCH_CHECK_EXTERNAL_PATIENT_REQUEST,
  SET_IS_EXTERNAL_PATIENT,
} from './bg.constants';

export const INITIAL_PATIENT_DASHBOARD_STATE = {
  bgOverview: {
    endDate: null,
    glucoseMeasurements: [],
    timeInterval: TIME_INTERVAL.WEEKLY_INTERVALS, // Update default to UPLOAD_DATE when available
  },
  externalPatient: {
    patientId: '',
    patientExternalId: '',
  },
  bloodGlucoseUnit: null,
  glucoseMeasurements: [],
  graph: 'standard-day',
  graphStartTime: '0:00',
  graphType: 'details',
  logbookType: '24hour',
  deviceSettingsType: DEVICE_SETTINGS_TYPE_BASAL_BOLUS,
  insulin: { basals: [], bolus: [] },
  isFetchingClinicalData: false,
  isFetchingPatient: false,
  isFetchingPatientDateRange: false,
  isFetchingStripDeliveryInfo: false,
  isFetchingThreshold: false,
  isFetchingTimeIntervals: false,
  isExternalPatient: false,
  showBloodGlucoseAfterMealPoints: true,
  showBloodGlucoseBeforeMealPoints: true,
  showBloodGlucoseLines: true,
  showBloodGlucosePoints: true,
  showBasalRate: true,
  showCarbohydrates: true,
  showInsulin: true,
  showGridLines: true,
  showMeanBloodGlucose: true,
};

export const patientDashboardReducer = (
  state = INITIAL_PATIENT_DASHBOARD_STATE,
  action,
) => {
  switch (action.type) {
    case FETCH_CHECK_EXTERNAL_PATIENT_REQUEST.START: {
      return {
        ...state,
        isExternalPatient: true,
      };
    }
    case FETCH_CHECK_EXTERNAL_PATIENT_REQUEST.ERROR: {
      return {
        ...state,
        isExternalPatient: false,
      };
    }
    case FETCH_CHECK_EXTERNAL_PATIENT_REQUEST.SUCCESS: {
      return {
        ...state,
        externalPatient: {
          patientId:
            action.payload !== 404
              ? action.payload.PWDID
              : FETCH_CHECK_EXTERNAL_PATIENT_REQUEST.ERROR,
          patientExternalId: action.payload.HEALTH_CARE_SYSTEM_ID,
        },
      };
    }
    case SET_IS_EXTERNAL_PATIENT: {
      return {
        ...state,
        isExternalPatient: action.payload.isExternalPatient,
      };
    }
    case FETCH_PATIENT_DATE_RANGE_REQUEST.START: {
      return {
        ...state,
        isFetchingPatientDateRange: true,
      };
    }
    case FETCH_PATIENT_DATE_RANGE_REQUEST.ERROR: {
      return {
        ...state,
        isFetchingPatientDateRange: false,
      };
    }
    case FETCH_PATIENT_DATE_RANGE_REQUEST.SUCCESS: {
      const { bgOverview } = state;
      return {
        ...state,
        isFetchingPatientDateRange: false,
        bgOverview: {
          ...bgOverview,
          endDate: action.payload.latestMeasurement,
        },
      };
    }
    case FETCH_BG_OVERVIEW_END_DATE_REQUEST.SUCCESS: {
      return {
        ...state,
        bgOverview: {
          ...state.bgOverview,
          endDate: action.payload.latestMeasurement,
        },
      };
    }
    case FETCH_BG_OVERVIEW_CLINICAL_DATA_REQUEST.SUCCESS:
    case FETCH_BG_OVERVIEW_CLINICAL_DATA_FROM_FHIR_REQUEST.SUCCESS: {
      const path = lensPath(['bgOverview', 'glucoseMeasurements']);
      const { measurements } = action.payload;
      return set(path, measurements, state);
    }
    case FETCH_PATIENT_REQUEST.START: {
      return {
        ...state,
        isFetchingPatient: true,
      };
    }
    case FETCH_PATIENT_REQUEST.SUCCESS: {
      return {
        ...state,
        isFetchingPatient: false,
      };
    }
    case FETCH_PATIENT_REQUEST.ERROR: {
      return {
        ...state,
        isFetchingPatient: false,
      };
    }
    case STATE_ACTIONS.CLEAR_PATIENT_DASHBOARD:
      return INITIAL_PATIENT_DASHBOARD_STATE;
    case PATIENT_DASHBOARD_ACTIONS.CHANGE_GRAPH: {
      return {
        ...state,
        graph: action.payload,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.CHANGE_GRAPH_TYPE: {
      return {
        ...state,
        graphType: action.payload,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.CHANGE_LOGBOOK_TYPE: {
      return {
        ...state,
        logbookType: action.payload,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.CHANGE_DEVICE_SETTINGS: {
      return {
        ...state,
        deviceSettingsType: action.payload,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.SET_BLOOD_GLUCOSE_OVERVIEW_END_DATE: {
      const { bgOverview } = state;
      return {
        ...state,
        bgOverview: {
          ...bgOverview,
          endDate: action.payload.endDate,
        },
      };
    }
    case GET_CLINICAL_DATA_REQUEST.START:
    case GET_CLINICAL_DATA_FROM_FHIR_REQUEST.START: {
      return {
        ...state,
        isFetchingClinicalData: true,
      };
    }
    case GET_CLINICAL_DATA_REQUEST.SUCCESS:
    case GET_CLINICAL_DATA_FROM_FHIR_REQUEST.SUCCESS: {
      return {
        ...state,
        isFetchingClinicalData: false,
      };
    }
    case GET_CLINICAL_DATA_REQUEST.ERROR:
    case GET_CLINICAL_DATA_FROM_FHIR_REQUEST.ERROR: {
      return {
        ...state,
        isFetchingClinicalData: false,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.SET_CLINICAL_DATA: {
      return {
        ...state,
        bloodGlucoseUnit: action.payload.unit,
        glucoseMeasurements: action.payload.measurements,
        insulin: action.payload.insulin,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_LINES: {
      return {
        ...state,
        showBloodGlucoseLines: !state.showBloodGlucoseLines,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_POINTS: {
      return {
        ...state,
        showBloodGlucosePoints: !state.showBloodGlucosePoints,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_BEFORE_MEAL_POINTS: {
      return {
        ...state,
        showBloodGlucoseBeforeMealPoints:
          !state.showBloodGlucoseBeforeMealPoints,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.TOGGLE_BLOOD_GLUCOSE_AFTER_MEAL_POINTS: {
      return {
        ...state,
        showBloodGlucoseAfterMealPoints: !state.showBloodGlucoseAfterMealPoints,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.TOGGLE_MEAN_BLOOD_GLUCOSE: {
      return {
        ...state,
        showMeanBloodGlucose: !state.showMeanBloodGlucose,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.TOGGLE_BASAL: {
      return {
        ...state,
        showBasalRate: !state.showBasalRate,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.TOGGLE_INSULIN: {
      return {
        ...state,
        showInsulin: !state.showInsulin,
      };
    }
    case PATIENT_DASHBOARD_ACTIONS.TOGGLE_CARBOHYDRATES: {
      return {
        ...state,
        showCarbohydrates: !state.showCarbohydrates,
      };
    }
    default:
      return state;
  }
};
