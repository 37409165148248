import {
  flatten,
  groupBy,
  length,
  map,
  mergeAll,
  pipe,
  reduce,
  sort,
  values,
  filter,
  isEmpty,
  isNil,
} from 'ramda';

import { colors } from 'src/core/styles';
import {
  convertDateToWeeklyFloat,
  epochTime,
  hasSame,
  toFormat,
  toStartOfDay,
  addDays,
  diffDays,
} from 'src/utils';
import { BOLUS_TYPE } from 'src/domains/diagnostics/store/constants';
import { BLOOD_GLUCOSE_UNITS } from 'src/domains/patient-dashboards/bg/store/bg.constants';

import {
  GRAPHS,
  GRAPH_STANDARD_DAY,
  GRAPH_TYPE_DETAILS,
  GRAPH_Y_MIN,
  GRAPH_Y_MAX_MG,
  GRAPH_Y_MAX_MMOL,
  BOLUS_TYPE_ICONS,
  FULL_OPACITY,
  FADED_OPACITY,
  BASAL_PROFILE_CHANGE_TYPE,
  Y_AXIS_TICK_VISIBILITY_TOLERANCE,
  EXTENDED_MULTIWAVE_DEFAULT_BOLUS,
} from './graph.constants';

const MINS_IN_MS = 60000;
const HOURS_IN_MS = MINS_IN_MS * 60;

export const getMeasurementDates = (measurements) =>
  measurements.map((measurement) => measurement.date);

export const measurementsToDateRange = (measurements) =>
  getMeasurementDates(measurements).reduce(
    (acc, date) => ({
      start: !acc.start || acc.start > date ? date : acc.start,
      end: !acc.end || acc.end < date ? date : acc.end,
    }),
    {},
  );

export const isSameDay = (date1, date2) => hasSame('day', date1, date2);

export const removeDuplicateDaysFromSortedDateArray = reduce((dates, date) => {
  if (!dates.length) {
    return [date];
  }
  if (isSameDay(date, dates[dates.length - 1])) {
    return dates;
  }
  return [...dates, date];
}, []);

export const getMeasurementsTotalDays = pipe(
  getMeasurementDates,
  sort((a, b) => new Date(a).getTime() - new Date(b).getTime()),
  removeDuplicateDaysFromSortedDateArray,
  length,
);

export const formatGraphsDate = (date) =>
  date ? toFormat('dd LLL yyyy')(date) : '';

const generateShapeType = (bg, isMean = false) => {
  const shape = isMean
    ? 'circle'
    : bg.beforeMeal || bg.afterMeal
    ? 'rectangle'
    : 'cross';
  return !isMean && bg.value > 400 ? 'triangle' : shape;
};
const generateShapeStyle = (bg) => {
  const strokeColor = bg.aboveTargetRange
    ? colors.blueLight
    : bg.belowTargetRange
    ? colors.red
    : colors.charcoal;
  const fillColor = bg.beforeMeal ? colors.white : strokeColor;
  return {
    strokeColor,
    fillColor,
  };
};
export const generateShape = (bgVal, isMean = false) => {
  const bg = isMean ? { value: bgVal } : bgVal;
  return {
    type: generateShapeType(bg, isMean),
    style: generateShapeStyle(bg),
  };
};

export const getToolTipValueColor = (value, threshold, targetRange) => {
  const { max } = targetRange;

  if (value > max) {
    return colors.blueMarine;
  } else if (value >= threshold && value <= max) {
    return colors.black;
  } else {
    return colors.red;
  }
};

export const isStandardDayDetailGraph = (graph, graphType) =>
  graph === GRAPH_STANDARD_DAY && graphType === GRAPH_TYPE_DETAILS;

const normalizeTargetRange = (
  { glucoseIdealIntervalMin, glucoseIdealIntervalMax },
  floor,
  ceiling,
) => ({
  min: glucoseIdealIntervalMin / ceiling,
  max: glucoseIdealIntervalMax / ceiling,
  data: {
    min: glucoseIdealIntervalMin,
    max: glucoseIdealIntervalMax,
  },
});

export const normalizeGraphTargetRange = (threshold, graphYMax) =>
  normalizeTargetRange(threshold, GRAPH_Y_MIN, graphYMax);

const normalizeThreshold = ({ hypoglycemiaThreshold }, floor, ceiling) => ({
  value: hypoglycemiaThreshold / ceiling,
  data: { value: hypoglycemiaThreshold },
});

export const normalizeGraphThreshold = (threshold, graphYMax) =>
  normalizeThreshold(threshold, GRAPH_Y_MIN, graphYMax);

export const mergeOnDate = (...arraysOfObjectsWithDateProperties) => {
  const byDate = ({ date }) => date;

  return pipe(
    flatten,
    groupBy(byDate),
    values,
    map(mergeAll),
  )(arraysOfObjectsWithDateProperties);
};

export const createVerticalTick = (value) => ({
  value: value,
  label: value.toString(),
  gridLine: true,
});

export const normalizeVerticalTickValues = (verticalTicks, ceiling) => {
  if (!verticalTicks.some((tick) => tick.value === ceiling)) {
    const ceilingVerticalTick = createVerticalTick(ceiling);
    verticalTicks.push(ceilingVerticalTick);
  }

  return verticalTicks
    .filter((tick) => tick.value <= ceiling)
    .map((tick) => ({ ...tick, value: tick.value / ceiling }));
};

const keepBolusOfRegisterType =
  (registerTypeString) =>
  ({ registerType }) =>
    registerType === registerTypeString;

export const toBolusShape = ({
  date,
  value,
  remark,
  registerType,
  bolusType,
}) => ({
  date,
  bolusValue: value,
  bolusRemark: remark,
  bolusRegisterType: registerType,
  bolusType: bolusType,
});

export const toBasalShape = ({
  date,
  cbrf,
  profile,
  remark,
  tbrdec,
  tbrinc,
  tsb,
  tsbDiffMins,
}) => ({
  date,
  basalCbrf: cbrf,
  basalRateProfile: profile,
  basalRemark: remark,
  basalTbrdec: tbrdec,
  basalTbrinc: tbrinc,
  tsb,
  tsbDiffMins,
});

export const createBolusObject = (registerTypeString) =>
  pipe(filter(keepBolusOfRegisterType(registerTypeString)), map(toBolusShape));

export const getBolusTypeIcon = (measurement) => {
  const measurementBolusType =
    measurement.bolusType && measurement.bolusType.toLowerCase();

  const bolusTypeIcons = {
    std: BOLUS_TYPE_ICONS.STANDARD,
    scr: BOLUS_TYPE_ICONS.QUICK,
    ext: BOLUS_TYPE_ICONS.EXTENDED,
    mul: BOLUS_TYPE_ICONS.MULTIWAVE,
  };

  return bolusTypeIcons[measurementBolusType] || BOLUS_TYPE_ICONS.EMPTY;
};

export const togglePointsFilter = (points, toggles) =>
  points.filter((point) => {
    const showAfterMeal =
      point.data.afterMeal && toggles.showBloodGlucoseAfterMealPoints;
    const showBeforeMeal =
      point.data.beforeMeal && toggles.showBloodGlucoseBeforeMealPoints;
    const showRegularPoint =
      !(point.data.afterMeal || point.data.beforeMeal) &&
      toggles.showBloodGlucosePoints;
    return showAfterMeal || showBeforeMeal || showRegularPoint;
  });

export const convertMeasurementsToPoints = (
  measurements,
  thresholds = {},
  bloodGlucoseUnit = '',
) => {
  const GRAPH_Y_MAX =
    bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
      ? GRAPH_Y_MAX_MMOL
      : GRAPH_Y_MAX_MG;
  return measurements.map((measurement) => {
    let shape = 'x';

    if (measurement.value > GRAPH_Y_MAX) {
      shape = 'triangle';
    } else if (
      shape !== 'triangle' &&
      (measurement.beforeMeal || measurement.afterMeal)
    ) {
      shape = 'square';
    }

    const x = convertDateToWeeklyFloat(measurement.date);
    const y = shape === 'triangle' ? GRAPH_Y_MAX : measurement.value;

    let strokeColor = colors.black;

    if (measurement.value > thresholds.glucoseIdealIntervalMax) {
      strokeColor = colors.blueLight;
    } else if (measurement.value < thresholds.hypoglycemiaThreshold) {
      strokeColor = colors.red;
    }

    const fillColor =
      measurement.afterMeal || shape === 'triangle'
        ? strokeColor
        : colors.white;

    return {
      shape,
      x,
      y,
      strokeColor,
      fillColor,
      data: measurement,
    };
  });
};

export const navigateToLogbook = (
  history,
  date,
  changeLogbookType,
  logbookType,
) => {
  if (history.location.pathname.indexOf(GRAPHS.LOGBOOK) !== -1) {
    return;
  }
  const currentPath = history.location.pathname;
  const splittedPath = currentPath.split('/');

  const dateParam = epochTime(toStartOfDay(date));
  !splittedPath.includes(GRAPHS.ROOT)
    ? history.push(
        `${currentPath}/${GRAPHS.ROOT}/${GRAPHS.LOGBOOK}/${dateParam}`,
      )
    : history.push(`${GRAPHS.LOGBOOK}/${dateParam}`);
  changeLogbookType(logbookType);
};

const getSlope = (x1, y1, x2, y2) => (y2 - y1) / (x2 - x1);
const getYIntercept = (x, y, slope) => y - slope * x;
const getY = (x, yIntercept, slope) => slope * x + yIntercept;

export const getYFromTwoPointsAndOneXValue = (x1, y1, x2, y2, x3) => {
  const slope = getSlope(x1, y1, x2, y2);
  const yIntercept = getYIntercept(x1, y1, slope);
  return getY(x3, yIntercept, slope);
};

const DATE_WITHOUT_TIME_FORMAT = 'MMM d, yyyy';
export const areDatesTheSameDay = (dateA, dateB) => {
  const format = toFormat(DATE_WITHOUT_TIME_FORMAT);
  return dateB == null || dateA == null
    ? false
    : format(dateA) === format(dateB);
};

export const createEventHandlerStream =
  (eventStream$, stop$) =>
  (
    singleEventHandler,
    multipleEventHandler, // e.g. double click
  ) => {
    eventStream$
      .bufferWhen(() => eventStream$.debounceTime(250))
      .takeUntil(stop$)
      .subscribe((values) =>
        values.length === 1
          ? singleEventHandler(values)
          : multipleEventHandler(values),
      );
  };

const getMsFromTimeString = (time) => {
  const [hours, mins] = time.split(':');
  return parseInt(hours, 10) * HOURS_IN_MS + parseInt(mins, 10) * MINS_IN_MS;
};

export const parseStandardOrQuickBolus = (
  measurement,
  totalTime,
  startDate,
  insulinYMax,
) => {
  const { overlappingBolusWithGlucose } = measurement;
  const start = startDate.startOf('day');
  const x = ((measurement.date - start) % totalTime) / totalTime;
  // bolus that overlaps with glucose exact timestamp not visible HCPWGRAPHS-1460, HCPWGRAPHS-1480
  const lineHeight = overlappingBolusWithGlucose
    ? 0
    : measurement.bolusValue / insulinYMax;

  return { x, lineHeight };
};

const extensionPeriodInTimeString = (bolusRemark) =>
  bolusRemark
    ? bolusRemark.match(/[0-9]{1,2}:[0-9]{2,}/g)[0]
    : EXTENDED_MULTIWAVE_DEFAULT_BOLUS;

const parseExtendedBolus = (measurement, totalTime, startDate, insulinYMax) => {
  const start = startDate.startOf('day');
  const x = ((measurement.date - start) % totalTime) / totalTime;
  const rectWidth =
    getMsFromTimeString(extensionPeriodInTimeString(measurement.bolusRemark)) /
    totalTime;
  const rectHeight = measurement.bolusValue / insulinYMax;

  return { x, rectWidth, rectHeight };
};

const parseMultiwaveBolus = (
  measurement,
  totalTime,
  startDate,
  insulinYMax,
) => {
  const { overlappingBolusWithGlucose } = measurement;
  const bolusRemark = measurement.bolusRemark
    ? measurement.bolusRemark
    : EXTENDED_MULTIWAVE_DEFAULT_BOLUS;
  const extensionValue = parseFloat(bolusRemark.split('/')[0]);

  const start = startDate.startOf('day');
  const x = ((measurement.date - start) % totalTime) / totalTime;
  const rectWidth =
    getMsFromTimeString(extensionPeriodInTimeString(bolusRemark)) / totalTime;
  // bolus that overlaps with glucose exact timestamp not visible HCPWGRAPHS-1460, HCPWGRAPHS-1480
  const lineHeight = overlappingBolusWithGlucose
    ? 0
    : measurement.bolusValue / insulinYMax;
  const rectHeight = (measurement.bolusValue - extensionValue) / insulinYMax;

  return { x, lineHeight, rectWidth, rectHeight };
};

export const bolusTypeToParseFunctionMap = {
  [BOLUS_TYPE.QUICK]: parseStandardOrQuickBolus,
  [BOLUS_TYPE.STANDARD]: parseStandardOrQuickBolus,
  [BOLUS_TYPE.EXTENDED]: parseExtendedBolus,
  [BOLUS_TYPE.MULTIWAVE]: parseMultiwaveBolus,
};

export const basalRateProfileChanged = ({ basalRemark }) =>
  basalRemark && basalRemark.indexOf('changed') > -1;

export const filterProfileChanges = (measurements) =>
  measurements.reduce((acc, measurement, index) => {
    if (index !== 0 && !basalRateProfileChanged(measurement)) {
      return acc;
    }

    const changeType =
      index === 0
        ? BASAL_PROFILE_CHANGE_TYPE.ACTIVE
        : BASAL_PROFILE_CHANGE_TYPE.PROFILE_CHANGE;

    return [...acc, { ...measurement, changeType }];
  }, []);

export const getBasalProfile = ({ basalRateProfile, basalRemark }, index) =>
  index === 0 ? basalRateProfile : basalRemark.split('changed')[1];

export const filterBasalRateChanges = (measurements) =>
  measurements.filter(
    (measurement) =>
      measurement.basalRemark &&
      measurement.basalRemark.match(/[0-9A-Z]{1}\s*-\s*[0-9A-Z]{1}/g),
  );

const isNotDuplicateLine = (points) =>
  points[0].x !== points[1].x || points[0].y !== points[1].y;

export const filterDuplicateLines = (lines) => lines.filter(isNotDuplicateLine);

export const getOpacity = (selectedDate, measurementDate) =>
  isNil(selectedDate) || areDatesTheSameDay(selectedDate, measurementDate)
    ? FULL_OPACITY
    : FADED_OPACITY;

const addMidnightBasalMeasurements = (measurement, date, endDate) => {
  const startOfNextDay = toStartOfDay(addDays(1)(date));
  const daysToAdd = Math.floor(diffDays(endDate, date).days);

  return [
    { ...measurement, date, endDate: startOfNextDay },
    ...Array.from({ length: daysToAdd }).map((_, index) => ({
      ...measurement,
      date: addDays(index)(startOfNextDay),
      endDate: addDays(index + 1)(startOfNextDay),
    })),
    { ...measurement, date: toStartOfDay(endDate), endDate },
  ];
};

export const addBasalEndDatesAndMidnightMeasurements = (measurements) =>
  measurements.reduce((acc, measurement, index, originalArray) => {
    const nextMeasurement = originalArray[index + 1];
    const isLastMeasurement = index === originalArray.length - 1;

    const { date } = measurement;
    const startOfNextDay = toStartOfDay(addDays(1)(date));
    const endDate = !nextMeasurement ? startOfNextDay : nextMeasurement.date;

    const endsAfterNextDayStart = endDate > startOfNextDay;

    let measurementsToAdd = [
      { ...measurement, date, endDate: isLastMeasurement ? date : endDate },
    ];

    if (endsAfterNextDayStart) {
      measurementsToAdd = addMidnightBasalMeasurements(
        measurement,
        date,
        endDate,
      );
    }

    return [...acc, ...measurementsToAdd];
  }, []);

export const getBasalVerticalPadding = (y1, previousBasal, yMax) =>
  previousBasal && previousBasal.basalCbrf > y1 ? 0.01 / yMax : 0;

export const getYAxisTickVisibilityTolerance = ({
  bloodGlucoseUnit,
  yAxisTickVisibilityToleranceMap = Y_AXIS_TICK_VISIBILITY_TOLERANCE,
}) => yAxisTickVisibilityToleranceMap[bloodGlucoseUnit];

export const isTbrIncrease = ({ basalTbrinc, basalTbrdec }) =>
  !!basalTbrinc && !basalTbrdec;
export const isTbrDecrease = ({ basalTbrinc, basalTbrdec }) =>
  !basalTbrinc && !!basalTbrdec;

export const transformBasal = ({ date, basalCbrf, endDate, ...basalInfo }) => ({
  date: date,
  basalCbrf: basalCbrf,
  endDate: endDate,
  tbr: isTbrIncrease(basalInfo) || isTbrDecrease(basalInfo),
});

export const graphHasMeasurement = (bg, insulin, basal, carbs) =>
  !isEmpty(bg) || !isEmpty(insulin) || !isEmpty(basal) || !isEmpty(carbs);

export const getClickableCursorStyle = (clickable) =>
  clickable ? 'pointer' : 'initial';

export const getDimensionsMapper =
  (totalTime, startDate, graphYMax, measurement) =>
  ({
    color,
    insulinType,
    bolusValue,
    getInsulinDimensionsFn,
    bolusRemark = '', // only bolus insulin has bolusRemark
  }) => ({
    ...measurement,
    color,
    insulinType,
    ...getInsulinDimensionsFn(
      {
        date: measurement.date,
        bolusValue,
        bolusRemark: bolusRemark || '',
        overlappingBolusWithGlucose: measurement.overlappingBolusWithGlucose,
      },
      totalTime,
      startDate,
      graphYMax,
    ),
  });

export const applyHourOffset = (hourOffset) => (dimensions) => ({
  ...dimensions,
  x:
    dimensions.x > hourOffset
      ? dimensions.x - hourOffset
      : dimensions.x - hourOffset + 1,
});

export const sortInsulinBarsDescending = (value1, value2) =>
  value2.lineHeight - value1.lineHeight;

export const barsWithInvalidLineHeight = (value) => {
  const { lineHeight, rectHeight } = value;

  const isExtendedBolus = lineHeight === undefined;
  const hasValidInsulinValue = !isNaN(lineHeight);
  const hasNonZeroInsulinValue = lineHeight !== 0;
  const isOverlappingMultiwaveBolus =
    lineHeight === 0 && rectHeight !== undefined;

  return (
    isExtendedBolus ||
    (hasValidInsulinValue &&
      (hasNonZeroInsulinValue || isOverlappingMultiwaveBolus))
  );
};

/**
 * Quick, Standard and Multiwave sorted based on lineHeight
 * Extended are sorted to the start of the array so they stack
 * below bars (see the W3 specs for SVG Rendering Order)
 * http://www.w3.org/TR/SVG/render.html#RenderingOrder
 */
export const sortAllInsulinBarsDescending = (measurementA, measurementB) => {
  const { lineHeight: lineHeightA } = measurementA;
  const { lineHeight: lineHeightB } = measurementB;

  const isAExtendedBolus = !lineHeightA;
  const isBExtendedBolus = !lineHeightB;

  if (isAExtendedBolus) {
    return -1;
  } else if (isBExtendedBolus) {
    return 1;
  }

  return lineHeightB - lineHeightA;
};
