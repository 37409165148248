import {
  CheckDataSharingConsentState,
  CheckDataSharingConsentActions,
  CheckDataSharingConsentActionType,
} from './check-data-sharing-consent.types';

export const checkDataSharingConsentInitialState: CheckDataSharingConsentState =
  {
    checkDataSharingHasBeenCalled: false,
    isCheckingDataSharingConsent: false,
    checkDataSharingConsentSuccessful: false,
    checkDataSharingConsentHasError: false,
    dataSharingConsentResponse: 0,
  };

export const checkDataSharingConsentReducer = (
  state = checkDataSharingConsentInitialState,
  action: CheckDataSharingConsentActions,
): CheckDataSharingConsentState => {
  switch (action.type) {
    case CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_START:
      return {
        ...state,
        checkDataSharingHasBeenCalled: true,
        isCheckingDataSharingConsent: true,
        checkDataSharingConsentSuccessful: false,
        checkDataSharingConsentHasError: false,
      };
    case CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_SUCCESS:
      return {
        checkDataSharingHasBeenCalled: true,
        isCheckingDataSharingConsent: false,
        checkDataSharingConsentSuccessful: true,
        checkDataSharingConsentHasError: false,
        dataSharingConsentResponse: action.payload,
      };
    case CheckDataSharingConsentActionType.CHECK_DATA_SHARING_CONSENT_ERROR:
      return {
        checkDataSharingHasBeenCalled: true,
        isCheckingDataSharingConsent: false,
        checkDataSharingConsentSuccessful: false,
        checkDataSharingConsentHasError: true,
        dataSharingConsentResponse: action.payload,
      };
    default:
      return state;
  }
};
