import * as React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height: number;
  outlineColor?: string;
  bgColor?: string;
  buttonsColor?: string;
  buttonsStrokeColor?: string;
  screenColor?: string;
  screenStrokeColor?: string;
  t: (key: string, options?: any) => string;
};

export const V3Device1IconComponent = ({
  height = 15,
  outlineColor = colors.brandBlueDark,
  bgColor = colors.white,
  buttonsColor = colors.white,
  buttonsStrokeColor = colors.brandBlue,
  screenColor = colors.blue3,
  screenStrokeColor = colors.brandBlueDark,
  t,
}: Props) => {
  const originalWidth = 23;
  const originalHeight = 24;
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
      title={t('patientBar.deviceType.meter')}
    >
      <g strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
        <g transform="translate(0, 0)">
          <g>
            <g>
              <g transform="translate(-5, -4)">
                <rect
                  stroke={outlineColor}
                  strokeWidth="1.19069767"
                  fill={bgColor}
                  fillRule="nonzero"
                  x="5.58139535"
                  y="4.8372093"
                  width="20.8372093"
                  height="22.3255814"
                  rx="2.23255814"
                />
                <rect
                  stroke={screenStrokeColor}
                  strokeWidth="1.19069767"
                  fill={screenColor}
                  fillRule="nonzero"
                  x="9.6744186"
                  y="9.45899633"
                  width="12.6511628"
                  height="5.64014688"
                />
                <g
                  strokeWidth="1"
                  fillRule="evenodd"
                  transform="translate(10.046512, 18.976744)"
                  fill={buttonsColor}
                  stroke={buttonsStrokeColor}
                >
                  <circle
                    strokeWidth="1.19069767"
                    fillRule="nonzero"
                    cx="2.04651163"
                    cy="2.04651163"
                    r="2.04651163"
                  />
                  <circle
                    strokeWidth="1.19069767"
                    fillRule="nonzero"
                    cx="9.86046512"
                    cy="2.04651163"
                    r="2.04651163"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const V3Device1Icon = withTranslation(V3Device1IconComponent);
