import * as React from 'react';
import { MODAL_TYPES } from 'src/core/modal';
import { RenderIf } from 'src/utils';
import {
  BaseCard,
  Block,
  Button,
  LocalizedText,
  LoadingRing,
} from 'src/components';
import {
  HeaderContainer,
  HeaderTitle,
  ButtonsContainer,
} from './time-periods.styles';
import {
  TimePeriodsComponentState,
  TimePeriodsComponentProps,
  TimeInterval,
} from './time-periods.types';
import { TimePeriodsSummary } from './components/periods-summary/periods-summary.component';
import { TimePeriodsBar } from './components/periods-bar/periods-bar.component';
import { RouteLeavingGuard } from 'src/components/route-guard';
import { PERIODS_COUNTLY_KEYS } from './time-periods.constants';
import { countlyEventTrigger } from 'src/navigation/countly';
import {
  AdditionalInfo,
  INFOS,
  SIZES,
} from 'src/domains/diagnostics/components/additional-info';

export class TimePeriodsComponent extends React.Component<
  TimePeriodsComponentProps,
  TimePeriodsComponentState
> {
  public state: TimePeriodsComponentState = {
    isDirty: false,
    graphUpdated: false,
    dropDownUpdated: false,
  };

  public componentWillReceiveProps({ periods }) {
    this.state.validTimePeriods = periods;
  }

  public componentDidUpdate(prevProps) {
    if (
      this.props.blocksWereSaved &&
      prevProps.blocksWereSaved !== this.props.blocksWereSaved
    ) {
      this.sendCountlyEvent();
    }
  }

  public render() {
    const {
      goTo,
      isReadyToRender,
      patientId,
      moveTimePeriods,
      periods,
      is12hourTimeFormat,
      t,
    } = this.props;
    const { isDirty } = this.state;
    const validTimePeriods = this.state.validTimePeriods || periods;
    return (
      <BaseCard p={88} mb={88}>
        <HeaderContainer>
          <HeaderTitle>
            <LocalizedText textKey="timePeriods.title" />
          </HeaderTitle>
          <AdditionalInfo info={INFOS.timeBlocks} size={SIZES.l} />
        </HeaderContainer>
        <Block mt={40} position="relative">
          <RenderIf validate={!isReadyToRender}>
            <Block
              display="flex"
              minHeight={350}
              alignItems="center"
              justifyContent="center"
            >
              <LoadingRing />
            </Block>
          </RenderIf>
          <RenderIf validate={isReadyToRender && periods}>
            <Block mt={46} mb={90}>
              <TimePeriodsBar
                timePeriods={validTimePeriods}
                moveTimePeriods={moveTimePeriods}
                setCountlyStatus={this.modifyCountlyStatus}
                onUpdateValidTimePeriods={this.onUpdateValidTimePeriods}
                is12hourTimeFormat={is12hourTimeFormat}
              />
            </Block>
            <TimePeriodsSummary
              timePeriods={validTimePeriods}
              setDirty={this.setDirty}
              setCountlyStatus={this.modifyCountlyStatus}
              onUpdateValidTimePeriods={this.onUpdateValidTimePeriods}
              is12hourTimeFormat={is12hourTimeFormat}
              t={t}
            />
            <ButtonsContainer>
              <Button
                onClick={() => goTo(`/patients/${patientId}`)}
                buttonStyle="secondary"
                label={t('timePeriods.buttons.cancel')}
              />
              <Button
                label={t('timePeriods.buttons.save')}
                onClick={() => this.saveTimePeriods(validTimePeriods)}
              />
            </ButtonsContainer>
          </RenderIf>
        </Block>
        <RouteLeavingGuard
          when={isDirty}
          navigate={(path) => goTo(path)}
          shouldBlockNavigation={() => true}
        />
      </BaseCard>
    );
  }

  public setDirty = () => this.setState({ isDirty: true });

  public onUpdateValidTimePeriods = (validTimePeriods: TimeInterval[]) =>
    this.setState({ validTimePeriods, isDirty: true });

  private saveTimePeriods(validTimePeriods) {
    const { createModal, destroyModal, patientId, onUpdateTimePeriods } =
      this.props;
    onUpdateTimePeriods(patientId, validTimePeriods);
    destroyModal();
    createModal({
      key: MODAL_TYPES.LOADING,
    });

    this.setState({ isDirty: false });
  }

  private modifyCountlyStatus = (status: {
    dropDownUpdated?: boolean;
    graphUpdated?: boolean;
  }) => this.setState({ ...this.state, ...status });

  private sendCountlyEvent() {
    const countlyKeys = { ...PERIODS_COUNTLY_KEYS };
    countlyEventTrigger(countlyKeys.EVENT_TITLE, {
      [countlyKeys.SEG_DROPDOWN]: this.state.dropDownUpdated
        ? countlyKeys.VALUE_TRUE
        : countlyKeys.VALUE_FALSE,
      [countlyKeys.SEG_GRAPH]: this.state.graphUpdated
        ? countlyKeys.VALUE_TRUE
        : countlyKeys.VALUE_FALSE,
    });

    this.setState({
      graphUpdated: false,
      dropDownUpdated: false,
    });
  }
}
