import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withTranslation as translateHOC } from 'react-i18next';
import { DeliveryConfigurationLifeCycleProps } from 'src/domains/patient/scenes/delivery-configuration/delivery-configuration.types';
import { deliveryConfigurationConnector } from 'src/domains/patient/scenes/delivery-configuration/store/delivery-configuration.selector';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { withPatientSummary } from 'src/domains/patient/utils/with-patient-summary';

import { createModal, destroyModal } from 'src/core/modal';

import { DeliveryConfigurationComponent } from './delivery-configuration.component';
import {
  fetchDeliveryConfigurationStart,
  saveDeliveryConfigurationStart,
} from './store/delivery-configuration.actions';

const dispatchers = mapDispatchers({
  fetchDeliveryConfiguration: fetchDeliveryConfigurationStart,
  onSaveProgram: saveDeliveryConfigurationStart,
  createModal,
  destroyModal,
  goTo: (path) => push(path),
});

export const DeliveryConfiguration = compose(
  connect(deliveryConfigurationConnector, dispatchers),
  withPatientSummary,
  lifecycle<DeliveryConfigurationLifeCycleProps, void>({
    componentWillMount() {
      const {
        fetchDeliveryConfiguration,
        match: { params },
      } = this.props;
      fetchDeliveryConfiguration(params.id);
    },
  }),
  translateHOC('translations'),
)(DeliveryConfigurationComponent);
