import styled from 'styled-components';

export const AddPatientDiv = styled.div`
  &.active {
    cursor: pointer;
  }
  &.disabled {
    cursor: default;
  }
`;

export const SearchPatientFormDiv = styled.div`
  max-width: 650px;
  width: 80%;
  padding-bottom: ${({ theme }) => theme.spacing.four};
`;

SearchPatientFormDiv.displayName = 'SearchPatientFormDiv';

export const PatientSearchFields = styled.div`
  display: flex;
`;

PatientSearchFields.displayName = 'PatientSearchFields';

export const PatientSearchFieldBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.two};
  margin-right: ${({ theme }) => theme.spacing.three};
  margin-top: inherit;
`;

PatientSearchFieldBlock.displayName = 'PatientSearchFieldBlock';

export const PatientSearchFieldLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.black};
`;

PatientSearchFieldLabel.displayName = 'PatientSearchFieldLabel';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
