import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { withPatientSummary } from 'src/domains/patient/utils/with-patient-summary';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { createModal, destroyModal } from 'src/core/modal';

import { TimePeriodsLifeCycleProps } from './time-periods.types';
import { TimePeriodsComponent } from './time-periods.component';
import {
  fetchPatientTimeBlocksStart,
  moveTimePeriodsUI,
  savePatientTimeBlocksStart,
  patientTimeBlocksStatusReset,
} from './store/time-periods.actions';
import { timePeriodsConnector } from './store/time-periods.selectors';

const dispatchers = mapDispatchers({
  fetchTimeBlocks: fetchPatientTimeBlocksStart,
  moveTimePeriods: moveTimePeriodsUI,
  onUpdateTimePeriods: savePatientTimeBlocksStart,
  resetStatus: patientTimeBlocksStatusReset,
  createModal,
  destroyModal,
  goTo: (path) => push(path),
});

export const TimePeriods = compose(
  withPatientSummary,
  connect(timePeriodsConnector, dispatchers),
  lifecycle<TimePeriodsLifeCycleProps, void>({
    componentWillMount() {
      const {
        fetchTimeBlocks,
        match: { params },
      } = this.props;
      fetchTimeBlocks(params.id);
    },
  }),
  withTranslation,
)(TimePeriodsComponent);
