import { createSelector } from 'reselect';

import { ProfileTypes } from 'src/core/department/department.types';
import { PatientFormState } from 'src/core/patient/patient.types';

import { selectActivePatientForm } from '../../store/forms.selectors';

import { selectPatientPermissions } from 'src/core/permissions';

export const selectProfileType = (isEditing: boolean) =>
  createSelector(
    selectActivePatientForm(isEditing),
    (patientFormState: PatientFormState): ProfileTypes =>
      patientFormState.profileType,
  );

export const selectProfilePermissions = (isEditing: boolean) =>
  createSelector(selectPatientPermissions, (permissions) => permissions);
