import { compose, lifecycle } from 'recompose';
import { JELLO_DIALOG_EVENTS } from 'src/components';
// @ts-ignore
import { clearINSIdentity } from 'src/widgets/patient/INS-patient/store/INS-Identity.actions';

import { INS_ERROR_MODAL_ID } from './ins-request.modal.constants';
import { SaveErrorModalComponent } from 'src/widgets/modal/components/save-error-modal/save-error.modal';
import { withTranslation } from 'src/utils/i18n/with-translation';

export const INSErrorModal = compose(
  lifecycle<any, {}>({
    componentDidMount() {
      const modal = document.getElementById(INS_ERROR_MODAL_ID);
      if (modal) {
        const { destroyModal } = this.props;
        modal.addEventListener(JELLO_DIALOG_EVENTS.primaryBtn, () => {
          return destroyModal();
        });
      }
    },
  }),
  withTranslation,
)(SaveErrorModalComponent);
