import { getJSON } from 'src/utils/service';
import { createAuthHeader } from 'src/utils/service/service.utils';

import {
  FetchConfigurablesLoaderType,
  FetchConfigurablesServiceType,
} from './configurables.types';

import { ENDPOINTS } from 'src/services/service.constants';

export const FetchConfigurablesLoaderImpl: FetchConfigurablesLoaderType = (
  openId: any,
) => {
  return getJSON(ENDPOINTS.configurables, {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
    },
  });
};

export const FetchConfigurablesServiceImpl: FetchConfigurablesServiceType =
  (load, transform) => (openId) =>
    load(openId).then(transform);
