import { not, equals } from 'ramda';

import { translate } from 'src/i18n';

import {
  CLINIC_GUIDE_TYPES,
  frequencyTranslationKeyBase,
  periodTranslationKeyBase,
} from './prescription.constants';

export const therapyToDropdownOption = ({ id, name }) => ({
  value: id,
  label: name,
});

export const clinicGuideToDropdownOption = ({ id, name, isActive, type }) => ({
  value: id,
  label: name,
  inactive: not(isActive) && equals(type, CLINIC_GUIDE_TYPES.CUSTOM),
});

export const frequencyToDropdownOption = ({ id }) => ({
  value: id,
  label: `${frequencyTranslationKeyBase}${id}`,
});

export const stripModelToDropdownOption = ({
  stripModelReference,
  stripModelName,
  stripModelPackageUnits,
}) => ({
  value: stripModelReference,
  label: `${stripModelName} (${stripModelPackageUnits} ${translate(
    stripModelPackageUnits > 1 ? 'prescription.tests' : 'prescription.test',
  )})`,
});

export const quantityToDropdownOption = (quantity) => ({
  value: quantity,
  label: quantity,
});

export const periodToDropdownOption = (period) => ({
  value: period,
  label: `${periodTranslationKeyBase}${period}`,
});

export const reasonToDropdownOption = ({ id, description }) => ({
  value: id,
  label: description,
});
