// tslint:disable
import {
  selectAccessToken,
  selectGigyaToken,
} from 'src/app/session';
import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fetchLanguagesStart } from 'src/core/languages/languages.actions';
import { EditProfileServiceImplType } from 'src/services/profile/edit-profile.types';
import { EditEMRProfileServiceImplType } from 'src/services/profile-emr';
import {
  EditProfileStartAction,
  EditEMRProfileStartAction,
} from 'src/widgets/profile/edit-profile/store/edit-profile.types';
import {
  EditProfileErrorModal,
  EditProfileSuccessModal,
} from 'src/widgets/profile/edit-profile/components';

import { getCurrentUser } from 'src/core/user/user.actions';
import { fetchPermissions } from 'src/core/permissions/permissions.actions';
import { createModal, MODAL_TYPES } from 'src/core';

import {
  editProfileError,
  editProfileSuccess,
  editEMRProfileError,
  editEMRProfileSuccess,
} from './edit-profile.actions';
import { EditProfileActionType } from './edit-profile.types';

export const editProfileEpic: (
  editProfileService: EditProfileServiceImplType,
) => Epic<FixMe, any> = (editProfileService) => (action$, store) =>
  action$
    .ofType(EditProfileActionType.EDIT_PROFILE_START)
    .switchMap(({ payload }: EditProfileStartAction) =>
      Observable.fromPromise(
        editProfileService(
          payload,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .flatMap((data) => [
          editProfileSuccess(data),
          getCurrentUser.start(),
          fetchPermissions.start(),
          fetchLanguagesStart(),
          createModal({
            key: MODAL_TYPES.CUSTOM,
            data: {
              customComponent: EditProfileSuccessModal,
            },
          }),
        ])
        .pipe(
          catchError((err) =>
            Observable.concat(
              Observable.of(editProfileError(err)),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: EditProfileErrorModal,
                  },
                }),
              ),
            ),
          ),
        ),
    );

export const editEMRProfileEpic: (
  editProfileService: EditEMRProfileServiceImplType,
) => Epic<FixMe, any> = (editProfileService) => (action$, store) =>
  action$
    .ofType(EditProfileActionType.EDIT_EMR_PROFILE_START)
    .switchMap(({ payload }: EditEMRProfileStartAction) =>
      Observable.fromPromise(
        editProfileService(
          payload,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .flatMap((data) => [
          editEMRProfileSuccess(data),
          getCurrentUser.start(),
          fetchPermissions.start(),
          createModal({
            key: MODAL_TYPES.CUSTOM,
            data: {
              customComponent: EditProfileSuccessModal,
            },
          }),
        ])
        .pipe(
          catchError((err) =>
            Observable.concat(
              Observable.of(editEMRProfileError(err)),
              Observable.of(
                createModal({
                  key: MODAL_TYPES.CUSTOM,
                  data: {
                    customComponent: EditProfileErrorModal,
                  },
                }),
              ),
            ),
          ),
        ),
    );
