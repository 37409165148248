import { Observable } from 'rxjs';
import { Epic } from 'redux-observable';
import { selectAccessToken, selectGigyaToken } from 'src/app/session';
import { catchError } from 'rxjs/operators';
import { SAVE_PATIENT_REPORTS_REQUEST } from './patient-reports.constant';
import { State } from 'src/app/store/app.types';
import {
  savePatientReportsSuccess,
  savePatientReportsError,
} from './patient-reports.action';

export const generatePatientReportsEpic: (
  patientReportsService,
) => Epic<any, State> = (patientReportsService) => (action$, store) =>
  action$.ofType(SAVE_PATIENT_REPORTS_REQUEST.START).switchMap((action) => {
    const params = action.payload;
    const openId = {
      accessToken: selectAccessToken(store.getState()),
      gigyaToken: selectGigyaToken(store.getState()),
    };
    return Observable.fromPromise(patientReportsService(params, openId))
      .map((result) => savePatientReportsSuccess(result))
      .pipe(catchError((err) => Observable.of(savePatientReportsError(err))));
  });
