import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height: number,
  fillColor?: string,
  strokeColor?: string,
  width?: number,
  minX?: number,
  originalHeight?: number,
  originalWidth?: number,
  tspanY?: number,
};

export const V3HelpIcon = ({
  fillColor = colors.iconColor,
  height = 27,
  tspanY = 24,
  originalWidth = 10.8,
  originalHeight = 27,
  width,
  minX,
  strokeColor = colors.white,
}: Props) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={width ? width : height * aspectRatio}
      height={height}
      minX={minX}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      tspanY={tspanY}
    >
      <g
        id="v3-help"
        strokeWidth="1"
        fill={fillColor}
        stroke="none"
        fillRule="evenodd"
        fontFamily="Nunito-Bold, Nunito"
        fontSize="24"
        fontWeight="bold"
      >
        <g
          id="Main-Menu"
          transform="translate(-126.000000, -255.000000)"
          fill={fillColor}
          stroke="none"
          strokeWidth="1.75"
        >
          <g id="Group-23" transform="translate(111.000000, 240.000000)">
            <g id="Help" transform="translate(11.000000, 13.000000)">
              <text id="?">
                <tspan x="4" y={tspanY}>
                  ?
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
