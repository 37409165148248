import * as React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height: number;
  outlineColor?: string;
  bgColor?: string;
  buttonsColor?: string;
  buttonsStrokeColor?: string;
  screenColor?: string;
  screenStrokeColor?: string;
  t: (key: string, options?: any) => string;
};

export const V3Device2IconComponent = ({
  height = 15,
  outlineColor = colors.brandBlueDark,
  bgColor = colors.white,
  buttonsColor = colors.white,
  buttonsStrokeColor = colors.brandBlue,
  screenColor = colors.blue3,
  screenStrokeColor = colors.brandBlueDark,
  t,
}: Props) => {
  const originalWidth = 33;
  const originalHeight = 18;
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
      title={t('patientBar.deviceType.pump')}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g transform="translate(0, 0)">
          <g>
            <g>
              <g transform="translate(0, -7)">
                <g transform="translate(1.488372, 8.186047)">
                  <polygon
                    stroke={screenStrokeColor}
                    strokeWidth="1.19069767"
                    fill={screenColor}
                    fillRule="nonzero"
                    transform="translate(23.813953, 9.860465) rotate(-270.000000) translate(-23.813953, -9.860465) "
                    points="21.7674419 4.65116279 25.8604651 4.65116279 25.8604651 15.0697674 21.7674419 15.0697674"
                  />
                  <path
                    d="M7.44186047,-5.20930233 L18.6046512,-5.20930233 C19.837659,-5.20930233 20.8372093,-4.209752 20.8372093,-2.97674419 L20.8372093,18.6046512 C20.8372093,19.837659 19.837659,20.8372093 18.6046512,20.8372093 L7.44186047,20.8372093 C6.20885265,20.8372093 5.20930233,19.837659 5.20930233,18.6046512 L5.20930233,-2.97674419 C5.20930233,-4.209752 6.20885265,-5.20930233 7.44186047,-5.20930233 Z"
                    stroke={outlineColor}
                    strokeWidth="1.19069767"
                    fill={bgColor}
                    fillRule="nonzero"
                    transform="translate(13.023256, 7.813953) rotate(-270.000000) translate(-13.023256, -7.813953) "
                  />
                  <polygon
                    stroke={screenStrokeColor}
                    strokeWidth="1.19069767"
                    fill={screenColor}
                    fillRule="nonzero"
                    transform="translate(17.116279, 7.813953) rotate(-270.000000) translate(-17.116279, -7.813953) "
                    points="13.0232558 2.60465116 21.2093023 2.60465116 21.2093023 13.0232558 13.0232558 13.0232558"
                  />
                  <circle
                    stroke={buttonsStrokeColor}
                    strokeWidth="1.19069767"
                    fill={buttonsColor}
                    fillRule="nonzero"
                    cx="5.95348837"
                    cy="5.20930233"
                    r="1.48837209"
                  />
                  <circle
                    stroke={buttonsStrokeColor}
                    strokeWidth="1.19069767"
                    fill={buttonsColor}
                    fillRule="nonzero"
                    cx="5.95348837"
                    cy="10.7906977"
                    r="1.48837209"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const V3Device2Icon = withTranslation(V3Device2IconComponent);
