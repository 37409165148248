import { path } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import {
  selectAccessToken,
  selectGigyaToken,
} from 'src/app/session';

import { resultsToPageNumbers, sliceResultsByCurrentPage } from 'src/utils';
import { selectHasCreatePatient } from 'src/core/permissions/permissions.selectors';
import { selectIsHcpOrGp } from 'src/core/user/user.selectors';

import { PATIENT_SEARCH_RESULTS_PER_PAGE } from './patient-search.constants';

export const getPatientSearch = path(['patientSearch']);

export const getSearchResults = createSelector(
  getPatientSearch,
  (patientSearch) => path(['data'], patientSearch),
);

export const getDidSearch = createSelector(getPatientSearch, (patientSearch) =>
  path(['didSearch'], patientSearch),
);

export const selectIsSearching = createSelector(
  getPatientSearch,
  path(['isSearching']),
);

export const getPatientName = createSelector(
  getPatientSearch,
  (patientSearch) => path(['fullName'], patientSearch),
);
export const getPatientID = createSelector(getPatientSearch, (patientSearch) =>
  path(['patientID'], patientSearch),
);
export const getSearchBarOptions = createSelector(
  getPatientSearch,
  (patientSearch) => path(['searchBar'], patientSearch),
);
export const getCurrentResultsPage = createSelector(
  getPatientSearch,
  path(['resultsPage']),
);
export const getPatientSearchResultsToDisplay = createSelector(
  getSearchResults,
  getCurrentResultsPage,
  (results, page) =>
    sliceResultsByCurrentPage({
      currentPage: page,
      resultsPerPage: PATIENT_SEARCH_RESULTS_PER_PAGE,
    })(results),
);

export const getResultPageNumbers = createSelector(
  getSearchResults,
  resultsToPageNumbers({ resultsPerPage: PATIENT_SEARCH_RESULTS_PER_PAGE }),
);

export const getFhirId = createSelector(getPatientSearch, (patientSearch) =>
  path(['fhirId'], patientSearch),
);

export const patientSearchConnector = createStructuredSelector({
  results: getSearchResults,
  didSearch: getDidSearch,
  routes: getCombinedRoutes,
  hasCreatePatient: selectHasCreatePatient,
  isHcpOrGp: selectIsHcpOrGp,
  fullName: getPatientName,
  patientID: getPatientID,
  fhirId: getFhirId,
  accessToken: selectAccessToken,
  apiKey: selectGigyaToken,
});
