import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  GridIcon,
  HypoLimitIcon,
  RectangleMarkIcon,
  TIcon,
} from 'src/assets/icons';
import { colors } from 'src/core/styles';

import { LegendItem } from './legend-item.component';
import { CheckboxContainerComponent } from './checkbox-container.component';

import {
  LegendList,
  LegendListsContainer,
  LegendListSectionHeader,
  RotatedIconContainer,
} from '../graph-legend.style';
import { LocalizedText } from '../../localized-text';
import { CheckboxesForm } from '../../graph-options/graph-options.style';

export const TrendGraphLegendComponent = ({ t, graph, expandedGraph }) => (
  <LegendListsContainer>
    <CheckboxesForm model="ui.patientDashboard">
      <LegendList>
        <LegendListSectionHeader>
          {t('graphDetails.statistics.bloodGlucose.bloodGlucose')}
        </LegendListSectionHeader>
        <LegendItem
          icon={
            <RotatedIconContainer rotationAngle={45}>
              <RectangleMarkIcon />
            </RotatedIconContainer>
          }
          label={t('graphDetails.legend.meanBloodGlucose')}
        />
        <LegendItem
          icon={<RectangleMarkIcon fillColor={colors.cyan} />}
          label={t('graphDetails.statistics.bloodGlucose.standardDeviation')}
        />
      </LegendList>
      <LegendList>
        <LegendListSectionHeader>
          {t('graphDetails.legend.targetRangeSectionHeader')}
        </LegendListSectionHeader>
        <LegendItem
          icon={
            <RectangleMarkIcon
              strokeColor={colors.green}
              fillColor={colors.white}
            />
          }
          label={t('graphDetails.legend.targetRange')}
        />
        <LegendItem
          icon={<HypoLimitIcon />}
          label={t('graphDetails.legend.hypoLimit')}
        />
      </LegendList>
      <LegendList>
        <LegendListSectionHeader>
          {t('graphDetails.legend.results')}
        </LegendListSectionHeader>
        <LegendItem
          icon={<TIcon />}
          label={t('graphDetails.legend.highestResult')}
        />
        <LegendItem
          icon={
            <RotatedIconContainer rotationAngle={180}>
              <TIcon />
            </RotatedIconContainer>
          }
          label={t('graphDetails.legend.lowestResult')}
        />
      </LegendList>
      <LegendList noBorder>
        <LegendListSectionHeader>
          <LocalizedText textKey="graphDetails.legend.graphVisuals" />
        </LegendListSectionHeader>
        <CheckboxContainerComponent
          t={t}
          id={'graphs.detailGraph.gridLines'}
          modelPath={'.showGridLines'}
          icon={<GridIcon />}
          graph={graph}
          expandedGraph={expandedGraph}
        />
      </LegendList>
    </CheckboxesForm>
  </LegendListsContainer>
);

export const TrendGraphLegend = withTranslation(TrendGraphLegendComponent);
