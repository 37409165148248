// istanbul ignore file
import * as React from 'react';
import { clone, isEmpty, map, prop, equals } from 'ramda';
import { Observable, Subscription } from 'rxjs';
import { Block, LocalizedText } from 'src/components';
import {
  PERIODS_COLOR_CODE,
  PERIODS_DESCRIPTION_KEYS,
} from './../../time-periods.constants';
import { TimeInterval } from './../../time-periods.types';
import { convertTimeToFormat } from 'src/domains/diagnostics/utils/time-format';
import {
  IntervalHandle,
  PeriodBlock,
  PeriodsBarWrapper,
  PeriodsTicksWrapper,
  PeriodTick,
  PeriodTickText,
  PeriodTickTextAbsolute,
  TimePeriodCard,
  TimePeriodCardDisplay,
  TimePeriodCardTitle,
} from './periods-bar.style';
import {
  convertSToTime,
  convertTimeToLeftPosition,
  convertTimeToS,
  convertTimeToWidth,
  firstHandlerLeftPosition,
  getGapsFromPeriodsArray,
  nextLimitAddTotalDaySeconds,
  previousLimitAddTotalDaySeconds,
  THIRTY_MINUTES_SECONDS,
  THREE_HOURS_SECONDS,
  TOTAL_BAR_SECONDS,
  TOTAL_DAY_SECONDS,
} from './periods-bar.utils';

type TimePeriodsBarProps = {
  timePeriods: TimeInterval[];
  moveTimePeriods: (periods: TimeInterval[]) => void;
  onUpdateValidTimePeriods: (periods: TimeInterval[]) => void;
  is12hourTimeFormat: boolean;
  setCountlyStatus: (status: {
    dropDownUpdated?: boolean;
    graphUpdated?: boolean;
  }) => void;
};

type TimePeriodsBarState = {
  timePeriods: TimeInterval[];
  validPeriods: TimeInterval[];
  editedPeriods: TimeInterval[];
  isDraggingHandle: boolean;
  handleId: number;
  wrapWidth: number;
  zeroReference: number;
  handleX: number;
  sPerPx: number;
  currentHandle: any;
  currentHandleIndex: number | null;
};

export class TimePeriodsBar extends React.Component<
  TimePeriodsBarProps,
  TimePeriodsBarState
> {
  public state = {
    timePeriods: [] as TimeInterval[],
    validPeriods: [] as TimeInterval[],
    editedPeriods: [] as any[],
    isDraggingHandle: false,
    handleId: 0,
    wrapWidth: 0,
    zeroReference: 0,
    handleX: 0,
    sPerPx: 0,
    currentHandle: null,
    currentHandleIndex: null,
  };

  public wrap: Element;
  public handlers: Element[] = [];
  public mousemove: Subscription;
  private lastIndex = 7;
  private _statecache: FixMe = {};

  public componentWillMount() {
    const { timePeriods } = this.props;
    this.setState({
      validPeriods: clone(timePeriods),
      timePeriods: clone(timePeriods),
      zeroReference: convertTimeToS(timePeriods[7].startTime),
    });
  }

  public componentDidMount() {
    this.adaptRangeSliderToWindowSize();
    document.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('resize', this.adaptRangeSliderToWindowSize);
    this.mousemove = Observable.fromEvent(this.wrap, 'mousemove')
      .auditTime(125)
      .filter((val) => this.state.isDraggingHandle)
      .subscribe(this.onMouseMove);
  }

  public componentWillReceiveProps(newProps) {
    const { timePeriods } = newProps;

    if (equals(timePeriods, this.props.timePeriods)) return;

    this.setState({
      timePeriods: clone(timePeriods),
      validPeriods: clone(timePeriods),
      zeroReference: convertTimeToS(timePeriods[7].startTime),
    });
  }

  public componentWillUnmount() {
    document.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('resize', this.adaptRangeSliderToWindowSize);
    this.mousemove.unsubscribe();
  }

  public render() {
    const {
      timePeriods,
      zeroReference,
      currentHandleIndex,
      isDraggingHandle,
      validPeriods,
    } = this.state;
    const { is12hourTimeFormat } = this.props;

    return (
      <React.Fragment>
        <TicksReference
          timePeriods={this.props.timePeriods}
          is12hourTimeFormat={is12hourTimeFormat}
        />
        <PeriodsBarWrapper ref={(ref) => (this.wrap = ref)}>
          {timePeriods.map((period, index) => {
            const width =
              convertTimeToWidth(period.startTime, period.endTime) + '%';
            const left =
              index === 7
                ? firstHandlerLeftPosition(period.startTime, zeroReference) +
                  '%'
                : convertTimeToLeftPosition(period.startTime, zeroReference) +
                  '%';
            return (
              <React.Fragment key={'period-fragment-' + index}>
                <IntervalHandle
                  ref={(handle) => (this.handlers[index] = handle)}
                  left={left}
                  onMouseDown={this.onMouseDown(index)}
                  onMouseOver={this.onMouseOver(index)}
                >
                  <TimePeriodInfo
                    visible={currentHandleIndex === index && isDraggingHandle}
                    time={validPeriods[index].startTime}
                    description={validPeriods[index].description}
                    is12hourTimeFormat={is12hourTimeFormat}
                  />
                </IntervalHandle>
                <PeriodBlock
                  width={width}
                  left={left}
                  bgcolor={PERIODS_COLOR_CODE[period.description]}
                />
              </React.Fragment>
            );
          })}
          <IntervalHandle
            id={'period-handle-extra'}
            ref={(handle) => (this.handlers[8] = handle)}
            left={
              firstHandlerLeftPosition(
                timePeriods[7].startTime,
                zeroReference,
              ) +
              80 +
              '%'
            }
            onMouseDown={this.onMouseDown(this.lastIndex)}
            onMouseOver={this.onMouseOver(this.lastIndex + 1)}
          >
            <TimePeriodInfo
              visible={
                currentHandleIndex === this.lastIndex + 1 && isDraggingHandle
              }
              time={validPeriods[7].startTime}
              description={validPeriods[7].description}
              is12hourTimeFormat={is12hourTimeFormat}
            />
          </IntervalHandle>
        </PeriodsBarWrapper>
      </React.Fragment>
    );
  }

  public adaptRangeSliderToWindowSize = () => {
    const width = this.wrap.clientWidth;
    const sPerPx = TOTAL_BAR_SECONDS / width;
    this.setState({
      wrapWidth: width,
      sPerPx,
    });
  };

  public onMouseDown = (handleId) => (e) =>
    this.setState({
      currentHandle: this.handlers[handleId],
      isDraggingHandle: true,
      handleId,
      handleX: e.clientX,
    });

  public onMouseOver = (index) => (e) =>
    this.setState({
      currentHandle: this.handlers[index],
      currentHandleIndex: index,
    });

  public onMouseUp = () => {
    const { moveTimePeriods } = this.props;
    const { isDraggingHandle } = this.state;
    if (isDraggingHandle) moveTimePeriods(this.state.validPeriods);
    this.setState({
      isDraggingHandle: false,
      currentHandle: null,
      currentHandleIndex: null,
    });
    this.clearStateCache();
  };

  public onMouseMove = (e) => {
    const { currentHandle, handleId, handleX, sPerPx } = this.state;
    const { onUpdateValidTimePeriods } = this.props;
    const timePeriods = clone(this.state.timePeriods);
    const validTimePeriods = clone(this.props.timePeriods);
    const shouldCacheState = isEmpty(this._statecache);
    let { clientX } = e;

    if (shouldCacheState) {
      // Index
      const periodIndex = handleId;
      const previousIndex = periodIndex - 1 < 0 ? 7 : periodIndex - 1;
      const nextIndex = periodIndex + 1 > 7 ? 0 : periodIndex + 1;
      const isMovingLimit = this.lastIndex === periodIndex;

      // Relative times in seconds
      const originalSeconds = convertTimeToS(
        this.props.timePeriods[periodIndex].startTime,
      );
      const previousSeconds = convertTimeToS(
        timePeriods[previousIndex].startTime,
      );
      const nextSeconds = convertTimeToS(timePeriods[nextIndex].startTime);

      // Bounds
      let previousLimit =
        (previousSeconds + THIRTY_MINUTES_SECONDS) % TOTAL_DAY_SECONDS;
      let nextLimit = nextSeconds - THIRTY_MINUTES_SECONDS;
      // istanbul ignore text
      nextLimitAddTotalDaySeconds(nextLimit);

      // When moving last period (Night - Rest) bounds are limited to bar size;
      // This means it wont move morea than 3 hours, unless next or previous limit is already smaller that 3 hours
      if (isMovingLimit) {
        if (Math.abs(nextLimit - originalSeconds) > THREE_HOURS_SECONDS) {
          nextLimit =
            (originalSeconds + THREE_HOURS_SECONDS) % TOTAL_DAY_SECONDS;
        }
        if (Math.abs(previousLimit - originalSeconds) > THREE_HOURS_SECONDS) {
          previousLimit = originalSeconds - THREE_HOURS_SECONDS;
          // istanbul ignore text
          previousLimitAddTotalDaySeconds(previousLimit);
        }
      }

      // Controlling crossover bounds
      const isCrossOver = previousLimit > nextLimit;

      // Next cache state
      this._statecache = {
        periodIndex,
        previousIndex,
        nextIndex,
        previousLimit,
        nextLimit,
        isCrossOver,
      };
    }

    const {
      periodIndex,
      isCrossOver,
      previousLimit,
      nextLimit,
      previousIndex,
    } = this._statecache;

    const sMoved = (clientX - handleX) * sPerPx;
    const currentHandleX =
      (currentHandle as any).getBoundingClientRect().left + 10;
    let seconds = convertTimeToS(timePeriods[periodIndex].startTime) + sMoved;

    // Controling if mouse is trying to move further than bounds
    if (!isCrossOver) {
      if (seconds < previousLimit) {
        clientX = currentHandleX;
        seconds = previousLimit;
      }
      if (seconds > nextLimit) {
        clientX = currentHandleX;
        seconds = nextLimit;
      }
    } else {
      if (seconds < previousLimit && seconds > nextLimit) {
        clientX = currentHandleX;
        seconds = sMoved < 0 ? previousLimit : nextLimit;
        // REVIEW
      }
    }

    const startTime = convertSToTime(seconds);
    const previousEndTime = convertSToTime(seconds - 1);
    const validStartSeconds =
      (Math.round(seconds / THIRTY_MINUTES_SECONDS) * THIRTY_MINUTES_SECONDS) %
      TOTAL_DAY_SECONDS;
    const validEndSeconds = validStartSeconds - 1;

    // Rounded values update
    const upgradeAllTimePeriods = (validPeriods) => {
      const timeStart = convertTimeToS(prop('startTime', validPeriods));
      const timeEnd = convertTimeToS(prop('endTime', validPeriods));
      // End hour is :00
      validPeriods.startTime = convertSToTime(
        (Math.round(timeStart / THIRTY_MINUTES_SECONDS) *
          THIRTY_MINUTES_SECONDS) %
          TOTAL_DAY_SECONDS,
      );
      validPeriods.endTime = convertSToTime(
        ((Math.round(timeEnd / THIRTY_MINUTES_SECONDS) *
          THIRTY_MINUTES_SECONDS) %
          TOTAL_DAY_SECONDS) -
          1,
      );
    };
    map(upgradeAllTimePeriods, validTimePeriods);
    validTimePeriods[periodIndex].startTime = convertSToTime(validStartSeconds);
    validTimePeriods[previousIndex].endTime = convertSToTime(validEndSeconds);
    this.setState({
      validPeriods: validTimePeriods,
    });
    onUpdateValidTimePeriods(validTimePeriods);

    // Updates the status for countly Time Block save event
    this.props.setCountlyStatus({ graphUpdated: true });

    // Periods bar handle update
    timePeriods[periodIndex].startTime = startTime;
    timePeriods[previousIndex].endTime = previousEndTime;
    this.setState({ timePeriods, handleX: clientX });
  };

  private clearStateCache() {
    this._statecache = {};
  }
}

const TicksReference = ({ timePeriods, is12hourTimeFormat }) => {
  const ticksReferenceTime = getGapsFromPeriodsArray(timePeriods);

  const firstTick = ticksReferenceTime.shift();
  const lastTick = ticksReferenceTime.pop();
  return (
    <React.Fragment>
      <Block position="relative" height="25px">
        <PeriodTickText position="absolute" left={0}>
          {convertTimeToFormat(firstTick as string, is12hourTimeFormat)}
        </PeriodTickText>
        <PeriodTickText position="absolute" right={0}>
          {convertTimeToFormat(lastTick as string, is12hourTimeFormat)}
        </PeriodTickText>
      </Block>
      <Block display="flex" justifyContent="space-between">
        <PeriodTick />
        <PeriodsTicksWrapper>
          {ticksReferenceTime &&
            ticksReferenceTime.map((tick, i) => {
              return (
                <PeriodTick key={'period-tick-' + i} position="relative">
                  <PeriodTickTextAbsolute>
                    {convertTimeToFormat(tick, is12hourTimeFormat)}
                  </PeriodTickTextAbsolute>
                </PeriodTick>
              );
            })}
        </PeriodsTicksWrapper>
        <PeriodTick />
      </Block>
    </React.Fragment>
  );
};

const TimePeriodInfo = ({ visible, time, description, is12hourTimeFormat }) => {
  return (
    <TimePeriodCard visible={visible}>
      <TimePeriodCardTitle>
        {/* tslint:disable-next-line:jsx-self-close */}
        <LocalizedText
          textKey={PERIODS_DESCRIPTION_KEYS[description]}
        ></LocalizedText>
      </TimePeriodCardTitle>
      <TimePeriodCardDisplay>
        {convertTimeToFormat(time, is12hourTimeFormat)}
      </TimePeriodCardDisplay>
    </TimePeriodCard>
  );
};
