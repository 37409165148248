import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { selectSuccessRedirect } from '../../core';
import {
  selectApiGateway,
  selectGigyaToken,
  selectRedirectUri,
} from '../../core/config/config.selectors';
import { fetchOidcTokensStart } from '../../core/oidc/oidc.actions';
import {
  selectAccessToken,
  selectRefreshToken,
} from '../../core/oidc/oidc.selectors';
import {
  comeFromLogin,
  loginSuccess,
  setSuccessRedirect,
} from '../../core/session/session.actions';
import { mapDispatchers } from 'src/utils';

import { OIDCSuccessComponent } from './success.component';

const dispatchers = mapDispatchers({
  goTo: (path) => push(path),
  onComeFromLogin: comeFromLogin,
  onLoginSuccess: loginSuccess,
  setSuccessRedirect,
  fetchOidcTokens: (payload) => fetchOidcTokensStart(payload),
});

const connector = createStructuredSelector({
  accessToken: selectAccessToken,
  refreshToken: selectRefreshToken,
  apiGateway: selectApiGateway,
  gigyaToken: selectGigyaToken,
  redirectUri: selectRedirectUri,
  successRedirect: selectSuccessRedirect,
});

export const LoginSuccessComponent = compose(connect(connector, dispatchers))(
  OIDCSuccessComponent as any,
);
