import React from 'react';
import { AutoSizer, List } from 'react-virtualized';

import { InsulinPumpTableHeader } from 'src/domains/diagnostics/components/table';
import { LocalizedText } from 'src/domains/diagnostics/components';
import { ResizeWrapper } from 'src/components';
import { SeparateBordersTable } from 'src/domains/diagnostics/components';
import {
  BolusExtendedIcon,
  BolusMultiwaveIcon,
  BolusQuickIcon,
  BolusStandardIcon,
  LightBulbIcon,
  BasalPlusBolusIcon,
  ModifiedBolusIcon,
} from 'src/assets/icons';
import {
  BOLUS_TYPE_ICONS,
  BOLUS_CONDITIONS,
} from 'src/domains/diagnostics/scenes/graphs';
import { GraphWrapperDiv } from 'src/domains/diagnostics/scenes/graphs/graph.style';
import { RenderIf, withGraphLoader } from 'src/domains/diagnostics/utils';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls';
import { LogbookWrapper } from 'src/domains/diagnostics/widgets/logbook';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

import {
  InsulinPumpScrollWrapper,
  InsulinPumpGraphWrapper,
  CardDiv,
  ColumnCellsContainerDiv,
  DateCellDiv,
  DateWeekendCellDiv,
  DateLinesUl,
  GenericCellDiv,
  CommentCellDiv,
  IconWrapper,
} from './';

import { EMPTY_ICON } from './store/insulin-pump.constant';

import {
  COLLAPSED_STD_GRAPH_HEIGHT,
  COLLAPSED_STD_GRAPH_HEIGHT_IN_REM,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
} from '../../scenes/graphs/graph.constants';
import {
  LogbookGraphWrapperDiv,
  CardSectionTitleDiv,
} from '../../widgets/logbook/logbook.style';
import { is12HourFormat, convertTimeToFormat } from '../../utils/time-format';

const cardMarginBottom = 30;
const cardRowHeight = 48;

const renderIcon = (icon) => {
  if (icon === BOLUS_TYPE_ICONS.STANDARD) {
    return <BolusStandardIcon width={14} />;
  } else if (icon === BOLUS_TYPE_ICONS.QUICK) {
    return <BolusQuickIcon width={14} />;
  } else if (icon === BOLUS_TYPE_ICONS.EXTENDED) {
    return <BolusExtendedIcon width={14} />;
  } else if (icon === BOLUS_TYPE_ICONS.MULTIWAVE) {
    return <BolusMultiwaveIcon width={14} />;
  } else if (icon === BOLUS_CONDITIONS.ADVICE) {
    return <LightBulbIcon />;
  } else if (icon === BOLUS_CONDITIONS.MODIFIED) {
    return <ModifiedBolusIcon />;
  } else if (icon === EMPTY_ICON) {
    return '';
  }
};

const LogbookGraphWrapperDivWithLoader = withGraphLoader(
  LogbookGraphWrapperDiv,
  'graphs.insulinPump.loading',
);

export const InsulinPump = ({
  collapsed,
  showDetails,
  daysData,
  isLoading,
  hasData,
  t,
  timeFormat,
  graph,
}) => {
  const tableData = showDetails ? daysData : daysData.slice(0, 2);

  const rowRenderer = ({
    key,
    index: cardIndex,
    isScrolling,
    isVisible,
    style,
  }) => {
    const cardStyle = {
      ...style,
      height: style.height - cardMarginBottom,
    };

    const cardData = tableData[cardIndex];
    const { boluses, isWeekend, date } = cardData;

    const DateCell = isWeekend ? DateWeekendCellDiv : DateCellDiv;

    return (
      <CardDiv key={`card-${cardIndex}`} style={cardStyle}>
        <ColumnCellsContainerDiv>
          {boluses.map((bolus, index) => (
            <DateCell
              key={`bolusDate-${index}`}
              borderBottom={index === boluses.length - 1}
            >
              {index === 0 ? (
                <DateLinesUl>
                  <li>{date.split(', ')[0]},</li>
                  <li>{date.split(', ').slice(1).join(', ')}</li>
                </DateLinesUl>
              ) : (
                <span>&nbsp;</span>
              )}
            </DateCell>
          ))}
          <DateCell averageLabel>{t('graphs.insulinPump.daysTotal')}</DateCell>
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv>
          {boluses.map((bolus, index) => (
            <GenericCellDiv key={`bolusTime-${index}`}>
              {convertTimeToFormat(bolus.time, is12HourFormat(timeFormat))}
            </GenericCellDiv>
          ))}
          <GenericCellDiv>&nbsp;</GenericCellDiv>
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv>
          {boluses.map((bolus, index) => (
            <GenericCellDiv key={`bolusValue-${index}`}>
              {bolus.bolusValue}
            </GenericCellDiv>
          ))}
          <GenericCellDiv fontWeight="bold">
            {cardData.daysTotal.bolusTotal}
          </GenericCellDiv>
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv>
          {boluses.map((bolus, index) => (
            <GenericCellDiv key={`boluses-${index}`}>
              {bolus.types.map((type, index) => (
                <IconWrapper key={`bolusType-${index}`}>
                  {renderIcon(type)}
                </IconWrapper>
              ))}
            </GenericCellDiv>
          ))}
          <GenericCellDiv>&nbsp;</GenericCellDiv>
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv width="auto">
          {boluses.map((bolus, index) => (
            <CommentCellDiv key={`bolusComment-${index}`}>
              <span>&nbsp;</span>
            </CommentCellDiv>
          ))}
          <CommentCellDiv fontWeight="bold">
            <BasalPlusBolusIcon />
            {' = '}
            {cardData.daysTotal.comment.text}
          </CommentCellDiv>
        </ColumnCellsContainerDiv>
      </CardDiv>
    );
  };

  return (
    <React.Fragment>
      <RenderIf validate={showDetails}>
        <CardSectionTitleDiv>
          <LocalizedText textKey="graphs.insulinPump.title" />
        </CardSectionTitleDiv>
      </RenderIf>
      <RenderIf validate={hasData}>
        <GraphControls graphType={GRAPHS.INSULIN_PUMP} graph={graph} />
      </RenderIf>
      <LogbookWrapper
        collapsed={collapsed}
        blueBackground={tableData.length > 0 && !isLoading}
      >
        <LogbookGraphWrapperDivWithLoader
          collapsed={collapsed}
          hasError={tableData.length === 0 && !isLoading}
          isLoading={isLoading}
        >
          <GraphWrapperDiv
            p={0}
            minHeight={collapsed ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM : 0}
            collapsed={collapsed}
          >
            <RenderIf validate={!isLoading && hasData}>
              <SeparateBordersTable
                width="auto"
                clearFill
                pb="0.7rem"
                noBorderSpacing
              >
                <InsulinPumpTableHeader />
              </SeparateBordersTable>
              <InsulinPumpScrollWrapper>
                <ResizeWrapper
                  minHeight={
                    collapsed
                      ? COLLAPSED_STD_GRAPH_HEIGHT
                      : MIN_EXPANDED_STD_GRAPH_HEIGHT
                  }
                  render={(height) => (
                    <InsulinPumpGraphWrapper
                      tableHeight={
                        collapsed ? 'COLLAPSED_STD_GRAPH_HEIGHT' : height
                      }
                    >
                      <AutoSizer>
                        {({ width }) => (
                          <List
                            rowCount={tableData.length}
                            overscanRowCount={1}
                            rowRenderer={rowRenderer}
                            height={height}
                            width={width}
                            rowHeight={({ index }) =>
                              (tableData[index].boluses.length + 1) *
                                cardRowHeight +
                              cardMarginBottom
                            }
                          />
                        )}
                      </AutoSizer>
                    </InsulinPumpGraphWrapper>
                  )}
                  resizeFunction={(clientHeight) => clientHeight}
                />
              </InsulinPumpScrollWrapper>
            </RenderIf>
          </GraphWrapperDiv>
        </LogbookGraphWrapperDivWithLoader>
      </LogbookWrapper>
    </React.Fragment>
  );
};
