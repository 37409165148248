import { partition } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { withoutTargetRange } from 'src/domains/diagnostics/scenes/graphs/logbook.core.utils';

import {
  convertISOGMT,
  convertJSDateGMT,
  toFormat,
  toStartOfDay,
} from 'src/utils';
import {
  selectGlucoseMeasurementsIncludingNullValues,
  selectTimeIntervals,
  selectGraphLoading,
  selectBoluses,
  selectAllGraphThresholds,
} from 'src/domains/diagnostics/scenes/graphs/logbook.core.selector';
import { selectBloodGlucoseUnit } from 'src/domains/diagnostics/store/selectors';
import { selectEC6TimeFormat } from 'src/core/user/user.selectors';

import {
  createMealTimeMatrix,
  getLogbookMealTimeNames,
  getMaxMealTimeRows,
  mealTimeIsBeforeMeal,
  relatedMealTimesMap,
  convertDateStringToMs,
  isCrossoverBlock,
  convertCurrentDateInMs,
} from './logbook.util';

import {
  MEAL_TIMES,
  DAY_IN_MS,
  DAY_START_MS,
} from '../../constants/logbook.constants';
import { selectHasData } from '../status-card/status-card.selector';
import { selectNumberBasals } from '../blood-glucose-general-stats/components/device-details/device-details.selector';

const toLogbookDateFormat = (dateString) => {
  const date = convertISOGMT(dateString);

  const weekDayName = toFormat('EEEE')(date);
  const monthName = toFormat('LLL')(date);

  const monthNameCapitalized =
    monthName.charAt(0).toUpperCase() + monthName.slice(1);

  return `${weekDayName}, ${toFormat('d')(
    date,
  )} ${monthNameCapitalized} ${toFormat('yyyy')(date)}`;
};

const groupByDay = (measurements) =>
  measurements.reduce((measurementsGroupedByDay, measurement) => {
    const date = toStartOfDay(convertJSDateGMT(measurement.date));

    const currentMeasurements = measurementsGroupedByDay[date] || [];

    return {
      ...measurementsGroupedByDay,
      [date]: [...currentMeasurements, measurement],
    };
  }, {});

const toLogbookBGFormat = (
  {
    carbohydrates,
    date,
    value: glucose,
    afterMeal,
    beforeMeal,
    hypoSymptoms,
    insulin1,
    insulin2,
    insulin3,
    manuallyEntered,
  },
  allThresholds,
  timeIntervals,
) => {
  const aboveTargetRange = withoutTargetRange(
    'ABOVE',
    glucose,
    date,
    allThresholds,
    timeIntervals,
    beforeMeal,
    afterMeal,
  );
  const belowTargetRange = withoutTargetRange(
    'BELOW',
    glucose,
    date,
    allThresholds,
    timeIntervals,
    beforeMeal,
    afterMeal,
  );

  return {
    aboveTargetRange,
    belowTargetRange,
    hypoSymptoms,
    afterMeal,
    beforeMeal,
    date,
    glucose,
    bolus: null,
    carbohydrates,
    insulin1,
    insulin2,
    insulin3,
    manuallyEntered,
  };
};

const isolateInsulinObjects = (measurement) => {
  const glucose =
    measurement.glucose || measurement.carbohydrates
      ? { ...measurement }
      : null;
  const insulin1 = measurement.insulin1
    ? {
        ...measurement,
        glucose: null,
        carbohydrates: null,
        insulin: { value: measurement.insulin1 },
      }
    : null;
  const insulin2 = measurement.insulin2
    ? {
        ...measurement,
        glucose: null,
        carbohydrates: null,
        insulin: { value: measurement.insulin2 },
      }
    : null;
  const insulin3 = measurement.insulin3
    ? {
        ...measurement,
        glucose: null,
        carbohydrates: null,
        insulin: { value: measurement.insulin3 },
      }
    : null;
  const bolus = measurement.bolus
    ? {
        ...measurement,
        glucose: null,
        carbohydrates: null,
        insulin: { value: measurement.bolus.value },
      }
    : null;

  const bolusAndInsulinNotNull = [bolus, insulin1, insulin2, insulin3].filter(
    (x) => x,
  );

  if (!glucose) {
    return bolusAndInsulinNotNull;
  } else {
    const insulin = bolusAndInsulinNotNull[0] || { insulin: null };
    const remainder = bolusAndInsulinNotNull.slice(1);
    glucose.insulin = insulin.insulin;
    return [glucose, ...remainder];
  }
};

export const groupMeasurementsByDay = (
  measurements,
  boluses,
  allThresholds,
  timeIntervals,
) => {
  const logbookGlucoseMeasurements = measurements.map((measurement) =>
    toLogbookBGFormat(measurement, allThresholds, timeIntervals),
  );

  const groupedBgMeasurements = groupByDay(logbookGlucoseMeasurements);

  const groupedBoluses = groupByDay(boluses);

  const allTimeObjects = [
    ...logbookGlucoseMeasurements,
    ...boluses,
  ].sort((a, b) => (a.date.getTime() < b.date.getTime() ? -1 : 1));

  groupByDay(allTimeObjects);

  const allDates = [...new Set([...Object.keys(groupByDay(allTimeObjects))])];

  return allDates.reduce((groupedBgAndBolusMeasurements, date) => {
    const bgMeasurements = groupedBgMeasurements[date] || [];
    const boluses = groupedBoluses[date] || [];

    const orphanedBoluses = boluses
      .filter(
        (bolus) =>
          !bgMeasurements.find(
            (bg) => bg.date.getTime() === bolus.date.getTime(),
          ),
      )
      .map((bolus) => ({
        glucose: null,
        carbohydrates: null,
        insulin: { value: bolus.value },
        bolus,
        date: bolus.date,
      }));

    const bolusesWithSameDateAtBgMeasurements = [];
    const mergedBgMeasurementsAndBoluses = bgMeasurements.map((bg) => {
      const bolusesWithSameDate = boluses.find(
        (bolus) =>
          bolus.date.getTime() === bg.date.getTime() &&
          !bolusesWithSameDateAtBgMeasurements.includes(bolus.value),
      );
      if (bolusesWithSameDate && bolusesWithSameDate.value) {
        bolusesWithSameDateAtBgMeasurements.push(bolusesWithSameDate.value);
      }
      const mergedBgMeasurementsAndBolus = {
        ...bg,
        bolus: bolusesWithSameDate,
      };

      return mergedBgMeasurementsAndBolus;
    });

    const sequencedMeasurements = mergedBgMeasurementsAndBoluses // [{}, {} ]
      .map(isolateInsulinObjects) //[[{},{}], [{},{}]]
      .reduce((a, b) => a.concat(b), []); //[{insulin: },{}, {}]

    return {
      ...groupedBgAndBolusMeasurements,
      [date]: [...sequencedMeasurements, ...orphanedBoluses].sort((a, b) => {
        const aTime = a.date.getTime();
        const bTime = b.date.getTime();
        return aTime < bTime ? -1 : bTime < aTime ? 1 : 0;
      }),
    };
  }, {});
};

const isBetween = (min, max, value) => min <= value && value <= max;

export const groupDayMeasurementsByMealTime = (
  measurementsGroupedByDay,
  timeIntervals,
) =>
  measurementsGroupedByDay.reduce(
    (measurementsGroupedByMealtime, measurement, index) => {
      let accumulator = {};
      if (index > 0) {
        accumulator = measurementsGroupedByMealtime;
      } else {
        timeIntervals.forEach(({ description: mealTime }) => {
          accumulator[mealTime] = [];
        });
      }
      // JS Date
      const { date } = measurement;
      const msCurrentDate = convertCurrentDateInMs(date);
      const mealtime =
        timeIntervals.find((timeInterval) => {
          const { startTime, endTime } = timeInterval;
          const startTimeMs = convertDateStringToMs(startTime);
          const endTimeMs = convertDateStringToMs(endTime);
          if (isCrossoverBlock(startTimeMs, endTimeMs)) {
            return (
              isBetween(startTimeMs, DAY_IN_MS - 1, msCurrentDate) ||
              isBetween(DAY_START_MS, endTimeMs, msCurrentDate)
            );
          }
          return isBetween(startTimeMs, endTimeMs, msCurrentDate);
        }) || //defaulting to nightblock if time doesnt fall in any timeblocks
        timeIntervals.filter((timeblock) => timeblock.description === 'NIGHT');
      if (!mealtime) {
        return accumulator;
      }
      const { description: mealtimeName } = mealtime;
      return {
        ...accumulator,
        [mealtimeName]: [...accumulator[mealtimeName], measurement],
      };
    },
    {},
  );

/**
 * Conditionally moves measurements from current mealTime to relatedMealtime
 * (before or after) based on the afterMeal/beforeMeal flags and mealTime value.
 */
export const adjustDayMeasurementsByFlags = (
  dayMeasurementsGroupedByMealtime,
) => {
  const { mealTimes } = dayMeasurementsGroupedByMealtime;

  const adjustedMealTimes = Object.entries(mealTimes).reduce(
    (accumulator, [mealTime, measurementGroup]) => {
      const relatedMealTime = relatedMealTimesMap[mealTime];

      const [toAdjust, noAdjust] = partition(
        ({ afterMeal, beforeMeal }) =>
          (afterMeal && mealTimeIsBeforeMeal(mealTime)) ||
          (beforeMeal && !mealTimeIsBeforeMeal(mealTime)),
      )(measurementGroup);

      return {
        ...accumulator,
        [mealTime]: [...(accumulator[mealTime] || []), ...noAdjust],
        [relatedMealTime]: [
          ...(accumulator[relatedMealTime] || []),
          ...toAdjust,
        ],
      };
    },
    {},
  );

  return {
    day: dayMeasurementsGroupedByMealtime.day,
    date: dayMeasurementsGroupedByMealtime.date,
    mealTimes: adjustedMealTimes,
  };
};

export const groupMeasurementsAsMealTimeCellMatrices = (
  adjustedMeasurementsBasedOnMealTimeFlags,
) =>
  adjustedMeasurementsBasedOnMealTimeFlags.reduce((acc, dayMeasurements) => {
    const { day, date, mealTimes } = dayMeasurements;
    const numberOfRows = getMaxMealTimeRows(mealTimes);
    if (numberOfRows === 0) return acc;
    const logbookMealTimeNames = getLogbookMealTimeNames(
      Object.keys(mealTimes),
    );
    const groupedMealTimes = logbookMealTimeNames.reduce(
      (groupedMealTimesObject, mealTime) => {
        const mealTimeData = mealTimes[mealTime];
        if (!mealTimeData) {
          return groupedMealTimesObject;
        }
        let hasBeforeAndAfterIntervals = true;
        let mealTimeMatrix;
        let numberOfRowsWithContent;
        if (mealTime === MEAL_TIMES.NIGHT || mealTime === MEAL_TIMES.BEDTIME) {
          hasBeforeAndAfterIntervals = false;
          mealTimeMatrix = mealTimeData;
          numberOfRowsWithContent = mealTimeData.length;
        } else {
          const relatedMealTimeData = mealTimes[relatedMealTimesMap[mealTime]];
          mealTimeMatrix = createMealTimeMatrix(
            mealTimeData,
            relatedMealTimeData,
          );
          numberOfRowsWithContent =
            mealTimeData.length + relatedMealTimeData.length;
        }
        return {
          ...groupedMealTimesObject,
          [mealTime]: {
            hasBeforeAndAfterIntervals,
            measurements: mealTimeMatrix,
            numberOfRowsWithContent,
          },
        };
      },
      {},
    );
    return [
      ...acc,
      {
        day,
        date,
        mealTimes: groupedMealTimes,
        numberOfRows,
      },
    ];
  }, []);

export const selectLogbookData = createSelector(
  selectGlucoseMeasurementsIncludingNullValues,
  selectBoluses,
  selectTimeIntervals,
  selectAllGraphThresholds,
  (measurements, bolusesWithoutJSDate, timeIntervals, allThresholds) => {
    const boluses = bolusesWithoutJSDate.map((bolus) => ({
      ...bolus,
      date: new Date(bolus.date.ts),
      value: Number(bolus.bolusValue.toFixed(2)),
    }));

    const measurementsGroupedByDay = groupMeasurementsByDay(
      measurements,
      boluses,
      allThresholds,
      timeIntervals,
    );
    const allDayMeasurementsGroupedByMealtime = Object.keys(
      measurementsGroupedByDay,
    ).map((date) => {
      const mealTimes = groupDayMeasurementsByMealTime(
        measurementsGroupedByDay[date],
        timeIntervals,
      );

      return {
        day: toLogbookDateFormat(date),
        date,
        mealTimes,
      };
    }, []);
    const adjustedMeasurementsBasedOnMealTimeFlags = allDayMeasurementsGroupedByMealtime.map(
      (dayMeasurementsGroupedByMealtime) =>
        adjustDayMeasurementsByFlags(dayMeasurementsGroupedByMealtime),
    );

    const measurementsGroupedAsMealTimeCellMatrices = groupMeasurementsAsMealTimeCellMatrices(
      adjustedMeasurementsBasedOnMealTimeFlags,
    );

    return measurementsGroupedAsMealTimeCellMatrices;
  },
);

export const logbookConnector = createStructuredSelector({
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  logbookData: selectLogbookData,
  isLoading: selectGraphLoading,
  timeFormat: selectEC6TimeFormat,
  hasData: selectHasData,
  numbermeasurementsbasalData: selectNumberBasals,
});
