import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height?: number;
  width?: number;
  fillColor?: string;
  innerColor?: string;
  strokeColor?: string;
  originalWidth?: number;
  originalHeight?: number;
  minX?: number;
  minY?: number;
};

export const V3UserIcon = ({
  height = 18,
  width,
  innerColor = '#E7EEFA',
  fillColor = colors.white,
  strokeColor = colors.white,
  originalWidth = 32,
  originalHeight = 44,
  minX = 0,
  minY = 0,
}: Props) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={width ? width : height * aspectRatio}
      height={height}
      strokeColor={colors.white}
      // innerColor={innerColor}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      minX={minX}
      minY={minY}
    >
      <g
        id="Symbols"
        stroke={strokeColor}
        strokeWidth="1"
        fill={fillColor}
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g
          id="Icon/Primary/Doctor"
          transform="translate(-18.000000, -11.000000)"
          fillRule="nonzero"
          stroke={colors.strokeColor}
          strokeWidth="3"
        >
          <g id="Group">
            <g id="icon" transform="translate(20.000000, 13.000000)">
              <path
                d="M13.9508269,18 C9.85296025,17.9728819 6.28665109,15.237113 5.26225369,11.3348039 C4.19776112,7.41155473 5.91480447,3.27058247 9.46535097,1.19824605 C12.9925307,-0.831990448 17.4725473,-0.237179166 20.3260675,2.64022214 C23.8242816,6.18701585 23.8242816,11.8255432 20.3260675,15.3723369 C18.6502217,17.0607661 16.3499398,18.0088436 13.9508269,18 Z"
                id="Shape"
                fill={innerColor}
              />
              <path
                d="M5.0646046,7.57132402 C5.48650436,4.97001349 7.05258558,2.60649731 9.46535097,1.19824605 C12.9925307,-0.831990448 17.4725473,-0.237179166 20.3260675,2.64022214 C21.726125,4.05972217 22.5658506,5.8142689 22.8452443,7.63567712 L5.0646046,7.57132402 Z"
                id="Path"
                fill={innerColor}
              />
              <path
                d="M14,24 C21.7319865,24 28,30.2680135 28,38 L28,42 L28,42 L0,42 L0,38 C-9.46895252e-16,30.2680135 6.2680135,24 14,24 Z"
                id="Rectangle"
                fill={innerColor}
              />
              <path
                d="M14,33 C11.2385763,33 9,30.7614237 9,28 L9,24 L9,24"
                id="Path-3"
              />
              <circle
                id="Oval"
                fill="#FFFFFF"
                strokeLinecap="round"
                cx="17"
                cy="34"
                r="3"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
