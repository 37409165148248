import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { serverToClientStripModelStocks } from '../org-stock.utils';
import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const GetOrgStockLoaderImpl = ({ clinicId }, accessToken, gigyaToken) =>
  getJSON(
    endpointWithParams(ENDPOINTS.getOrgStock, { clinicId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),        
      },
    },
  );

export const GetOrgStockTransform = ({ totalStock, lastShipments }) => ({
  aggregateStripStock: totalStock,
  stripModelStocks: serverToClientStripModelStocks(lastShipments),
});

export const GetOrgStockServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
