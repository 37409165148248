import styled from 'styled-components';

import { breakpoints } from 'src/core';
import { weight } from 'src/components/fonts/weights';
import { colors } from 'src/core';
import { spacing } from 'src/core/styles';
import { COLLAPSED_STD_GRAPH_HEIGHT_IN_REM } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

export const BasalRateSection = styled.div`
  display: block;
  height: 100%;
  min-height: ${COLLAPSED_STD_GRAPH_HEIGHT_IN_REM};
  width: 100%;
  max-width: 100%;
`;

export const BasalRateContent = styled.div`
  display: block;
`;

export const RadialChartBlockDiv = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;

  svg {
    height: 70px;
    width: 70px;
    @media (max-width: ${breakpoints.changeMedium}) {
      height: 56px;
      width: 56px;
    }
  }
`;

RadialChartBlockDiv.displayName = 'RadialChartBlockDiv';

export const RadialChartContainerDiv = styled.div`
  margin: 0 ${spacing.three} 0 0;
`;

RadialChartContainerDiv.displayName = 'RadialChartContainerDiv';

export const DetailSection = styled.div`
  margin: 0;
`;

DetailSection.displayName = 'DetailSection';

export const DetailRow = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
`;

DetailRow.displayName = 'DetailRow';

export const BasalBolusDetail = styled.div`
  flex-direction: row;
  display: flex;
`;

BasalBolusDetail.displayName = 'BasalBolusDetail';

export const BasalBolusDetailContainer = styled.div`
  flex-direction: column;
  display: flex;
  &:nth-child(1) {
    width: 43%;
    max-width: 43%;
  }
  &:nth-child(2) {
    width: 55%;
    max-width: 55%;
  }
`;

BasalBolusDetailContainer.displayName = 'BasalBolusDetailContainer';

export const BasalBolusDetailLabel = styled.p`
  color: ${colors.charcoal};
  font-size: ${(props) => (props.expanded ? '16px' : '10px')};
  text-transform: uppercase;
  font-weight: ${weight.bold};
  margin-top: 4px;
  margin-bottom: 8px;
`;

BasalBolusDetailLabel.displayName = 'BasalBolusDetailLabel';

export const DetailWrapperDiv = styled.div`
  margin: 0 0 ${(props) => (props.expanded ? spacing.four : spacing.two)};
`;

DetailWrapperDiv.displayName = 'DetailWrapperDiv';

export const InsulinContainerMaximizeWrapper = styled.div`
  margin: ${spacing.four};
`;

InsulinContainerMaximizeWrapper.displayName = 'InsulinContainerMaximizeWrapper';

export const InsulinDataWrapperDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
`;

InsulinDataWrapperDiv.displayName = 'InsulinDataWrapperDiv';
