import styled from 'styled-components';

import { weight } from 'src/domains/diagnostics/components/fonts';
import { combineRems, convertPxToRem } from 'src/domains/diagnostics/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => `${spacing.two} 0 0 ${spacing.two}`};
  height: 100%;
  font-size: ${({ theme }) => convertPxToRem(theme.fontSizes[1])};
  line-height: 1;
`;
Container.displayName = 'Container';

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: ${weight.bold};
  padding: ${({ theme: { spacing } }) => `${spacing.one} ${spacing.three}`};
  margin-bottom: ${({ theme: { spacing } }) => spacing.one};
  font-size: ${({ theme }) => convertPxToRem(theme.fontSizes[3])};
  padding-left: ${({ noIcon, theme: { spacing } }) =>
    noIcon
      ? combineRems(spacing.two, convertPxToRem(12), spacing.three)
      : null};
`;
Title.displayName = 'Title';

export const ListSectionsContainer = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.three};
`;
ListSectionsContainer.displayName = 'ListSectionsContainer';

export const ListSection = styled.div`
  flex: 1;
  flex-direction: column;
  width: 100%;
  border-right: ${(props) =>
    props.borderRight
      ? `0.0625rem solid ${props.theme.colors.grayLight}`
      : 'none'};
`;
ListSection.displayName = 'ListSection';

export const ListsContainerWrapper = styled.div`
  display: flex;
`;
ListsContainerWrapper.displayName = 'ListsContainerWrapper';

export const ListsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  color: ${({ theme: { colors } }) => colors.charcoal};
  padding-left: ${({ noIcon, theme: { spacing } }) =>
    noIcon
      ? combineRems(spacing.two, convertPxToRem(12), spacing.three)
      : null};
`;
ListsContainer.displayName = 'ListsContainer';

export const List = styled.ul`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0 ${({ theme }) => theme.spacing.two};
  padding-left: ${({ iconPadding, theme: { spacing } }) =>
    iconPadding
      ? combineRems(spacing.two, convertPxToRem(12), spacing.three)
      : 0} ;
  }
`;
List.displayName = 'List';

export const ListElement = styled.li`
  font-weight: ${(props) => (props.bold ? weight.bold : weight.normal)};
  padding: ${({ theme }) => theme.spacing.one} 0;
  white-space: nowrap;
  &::first-letter {
    text-transform: uppercase;
  }
`;
ListElement.displayName = 'ListElement';

export const ListElementSectionHeader = styled.div`
  color: ${({ theme }) => theme.colors.grayMedium};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.semiBold};
  padding: ${({ theme: { spacing } }) =>
    `${spacing.two} 0 ${spacing.two} ${spacing.three}`};
  padding-left: ${({ noIcon, theme: { spacing } }) =>
    noIcon
      ? combineRems(spacing.two, convertPxToRem(12), spacing.three)
      : null};
  line-height: 1.08;
  letter-spacing: ${convertPxToRem(1.6)};
`;
ListElementSectionHeader.displayName = 'ListElementSectionHeader';

export const IconContainer = styled.div`
  display: inline-block;
  padding-right: ${({ theme }) => theme.spacing.two};
  position: relative;
  top: ${convertPxToRem(1)};
`;
IconContainer.displayName = 'IconContainer';
