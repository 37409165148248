import React, { Fragment } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { isEmpty, isNil, repeat as RamdaRepeat } from 'ramda';
import { TranslationFunction } from 'react-i18next';

import { ResizeWrapper, SeparateBordersTable } from 'src/components';
import { LogbookDiaryTableHeader } from 'src/domains/diagnostics/components/table';
import {
  withGraphLoader,
  RenderIf,
  formatBGMeasurement,
} from 'src/domains/diagnostics/utils';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls';
import {
  AdditionalInformationButton,
  logbookDisclaimers,
  logbookDisclaimersLastUpdated,
} from 'src/domains/diagnostics/components/additional-information';
import { LogbookWrapper } from 'src/domains/diagnostics/widgets/logbook';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  BolusExtendedIcon,
  BolusMultiwaveIcon,
  BolusQuickIcon,
  BolusStandardIcon,
} from 'src/assets/icons';

import {
  BasalRateCellDiv,
  BloodGlucoseCellDiv,
  BloodGlucoseContentsDiv,
  BloodGlucoseContentsHyperDiv,
  BloodGlucoseContentsHypoDiv,
  CarbohydrateCellDiv,
  CardDiv,
  ColumnCellsContainerDiv,
  DateCellDiv,
  DateLinesUl,
  DateWeekendCellDiv,
  GlucoseIconCellDiv,
  GlucoseIconCellInnerDiv,
  InsulinCellDiv,
  InsulinBolusTypeIconSpan,
  LogbookDiaryScrollWrapper,
  LogbookDiaryWrapperDiv,
  PumpCellDiv,
  PumpTextAfterIconSpan,
  PumpTextBeforeIconSpan,
  RowCellsContainerDiv,
  TimeCellDiv,
  AdvicedBolusCellDiv,
} from './logbook-diary.style';
import { BOLUS_TYPE_ICONS } from './logbook-diary.constant';
import {
  flat,
  renderBloodGlucoseIconType,
  renderPumpIconType,
  transformDataFromHours,
} from './logbook-diary.util';

import { MIN_EXPANDED_STD_GRAPH_HEIGHT } from '../../scenes/graphs/graph.constants';
import { getSelectedRowId } from '../logbook/logbook.util';
import { LogbookGraphWrapper } from '../logbook/logbook.style';
import { additionalInfoActivated } from '../../components/additional-info';

const LogbookDiaryWrapperDivWithLoader = withGraphLoader(
  LogbookDiaryWrapperDiv,
  'graphs.logbook.loading',
);

export const LogbookDiary = ({
  logbookDiaryData,
  match,
  isLoading,
  bloodGlucoseUnit,
  allGlucosemeasurementswithBolusAdvices,
  t,
  is12hourTimeFormat,
  graph,
}) => {
  const cardMarginBottom = 30;
  const cardRowHeight = 40;

  const extraRowsCount = (rowData) =>
    rowData && rowData.glucoseIcons && rowData.glucoseIcons.length - 1;

  const totalExtraRowsCount = (cardData) =>
    cardData.map(extraRowsCount).reduce((c, r) => r + c, 0);

  const getAdvicedBolus = (subItem) => {
    const advicedBolus = allGlucosemeasurementswithBolusAdvices.filter(
      (elemento) => elemento.date === subItem.originalDate,
    );
    return advicedBolus.length > 0 && !!advicedBolus[0].advicedBolus
      ? advicedBolus[0].advicedBolus
      : null;
  };

  logbookDiaryData.map((item) => {
    item.map((subItem) => {
      if (subItem.originalDate) {
        subItem.advicedBolus = getAdvicedBolus(subItem);
      }
    });
  });

  const renderBolusTypeIconType = (icon) => {
    const icons = {
      [BOLUS_TYPE_ICONS.STANDARD]: <BolusStandardIcon width={14} />,
      [BOLUS_TYPE_ICONS.QUICK]: <BolusQuickIcon width={14} />,
      [BOLUS_TYPE_ICONS.EXTENDED]: <BolusExtendedIcon width={14} />,
      [BOLUS_TYPE_ICONS.MULTIWAVE]: <BolusMultiwaveIcon width={14} />,
    };
    return icons[icon];
  };

  const extraRowsComponent = (rowData) => (emptyCellComponent) =>
    extraRowsCount(rowData)
      ? RamdaRepeat(emptyCellComponent, extraRowsCount(rowData))
      : [];

  const rowRenderer = (selectedRowIndex, is12hourTimeFormat) => ({
    key,
    index: cardIndex,
    isScrolling,
    isVisible,
    style,
  }) => {
    const { dateLine1, dateLine2 } = logbookDiaryData[cardIndex][0];
    const cardData = logbookDiaryData[cardIndex];
    const isWeekendDay = ['6', '7'].indexOf(cardData[0].dayOfWeek) > -1;
    const cardStyle = { ...style, height: style.height - cardMarginBottom };

    const renderBloodGlucoseCellContentsDivType = ({
      belowTargetRange,
      aboveTargetRange,
      hypoSymptoms,
      glucoseValue,
    }) => {
      if (!isNil(glucoseValue)) {
        if (aboveTargetRange) {
          return (
            <BloodGlucoseContentsHyperDiv>
              {formatBGMeasurement(bloodGlucoseUnit)(glucoseValue)}
            </BloodGlucoseContentsHyperDiv>
          );
        } else if (belowTargetRange) {
          return (
            <BloodGlucoseContentsHypoDiv withInnerBorder={hypoSymptoms}>
              {formatBGMeasurement(bloodGlucoseUnit)(glucoseValue)}
            </BloodGlucoseContentsHypoDiv>
          );
        }
      }

      return (
        <BloodGlucoseContentsDiv>
          {formatBGMeasurement(bloodGlucoseUnit)(glucoseValue)}
        </BloodGlucoseContentsDiv>
      );
    };

    const isExisting = (obj, par1, par2) => !!obj[par1] && !!obj[par1][par2];
    const renderAdvicedBolusCellContentsDivType = ({ advicedBolus }) => {
      if (
        !!advicedBolus &&
        Object.keys(advicedBolus).length > 0 &&
        isExisting(advicedBolus, 'recommended', 'totalIU') &&
        isExisting(advicedBolus, 'selected', 'totalIU')
      ) {
        const recommended = advicedBolus.recommended.totalIU;
        const selected = advicedBolus.selected.totalIU;
        return recommended === selected
          ? t('graphs.logbookDiary.adviceTo', { recommended })
          : t('graphs.logbookDiary.adviceToAdjustedTo', {
              recommended,
              selected,
            });
      } else {
        return ' ';
      }
    };

    return (
      <CardDiv
        key={`card-${cardIndex}`}
        highlight={selectedRowId === cardIndex}
        style={cardStyle}
      >
        <ColumnCellsContainerDiv>
          {flat(
            cardData.map((rowData, index, originalArray) => {
              const cellValue =
                index === 0 ? (
                  <DateLinesUl>
                    <li>{dateLine1}</li>
                    <li>{dateLine2}</li>
                  </DateLinesUl>
                ) : (
                  <span>&nbsp;</span>
                );
              const CellType = isWeekendDay ? DateWeekendCellDiv : DateCellDiv;
              return [
                <CellType key={`date-cell-${index}`}>{cellValue}</CellType>,
                ...extraRowsComponent(rowData)(
                  <CellType key={`date-cell-${index}`}>
                    <span>&nbsp;</span>
                  </CellType>,
                ),
              ];
            }),
          )}
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv borderRight="none">
          {flat(
            cardData.map((rowData, index) => [
              <TimeCellDiv
                key={`time-cell-${index}`}
                is12hourTimeFormat={is12hourTimeFormat}
              >
                {transformDataFromHours(rowData.time, is12hourTimeFormat)}
              </TimeCellDiv>,
              ...extraRowsComponent(rowData)(
                <TimeCellDiv key={`time-cell-extra-${index}`}>
                  &nbsp;
                </TimeCellDiv>,
              ),
            ]),
          )}
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv borderRight="none">
          {flat(
            cardData.map((rowData, index) => [
              <BloodGlucoseCellDiv key={`bg-cell-${index}`}>
                {renderBloodGlucoseCellContentsDivType(rowData)}
              </BloodGlucoseCellDiv>,
              ...extraRowsComponent(rowData)(
                <BloodGlucoseCellDiv key={`bg-cell-${index}`}>
                  &nbsp;
                </BloodGlucoseCellDiv>,
              ),
            ]),
          )}
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv>
          {flat(
            cardData.map((rowData, index) =>
              rowData.glucoseIcons.map((iconRow) => (
                <RowCellsContainerDiv key={`glucose-icon-row-${index}`}>
                  {iconRow.map((icon, index) => {
                    const firstIcon = index === 0;

                    return (
                      <GlucoseIconCellDiv key={`icon-cell-${index}`}>
                        <GlucoseIconCellInnerDiv firstIcon={firstIcon}>
                          {renderBloodGlucoseIconType(icon)}
                        </GlucoseIconCellInnerDiv>
                      </GlucoseIconCellDiv>
                    );
                  })}
                </RowCellsContainerDiv>
              )),
            ),
          )}
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv>
          {flat(
            cardData.map((rowData, index) => [
              <CarbohydrateCellDiv key={`carbohydrate-cell-${index}`}>
                {rowData.carbohydrates}
              </CarbohydrateCellDiv>,
              ...extraRowsComponent(rowData)(
                <CarbohydrateCellDiv key={`carbohydrate-cell-${index}`}>
                  &nbsp;
                </CarbohydrateCellDiv>,
              ),
            ]),
          )}
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv>
          {flat(
            cardData.map((rowData, index) => [
              <RowCellsContainerDiv key={`insulin-row-${index}`}>
                <InsulinCellDiv>
                  {rowData.bolusType1Value}
                  <InsulinBolusTypeIconSpan>
                    {renderBolusTypeIconType(rowData.bolusTypeIcon)}
                  </InsulinBolusTypeIconSpan>
                </InsulinCellDiv>
                <InsulinCellDiv>{rowData.bolusType2Value}</InsulinCellDiv>
                <InsulinCellDiv>{rowData.bolusType3Value}</InsulinCellDiv>
              </RowCellsContainerDiv>,
            ]),
          )}
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv>
          {flat(
            cardData.map((rowData, index) => [
              <BasalRateCellDiv key={`basal-rate-cell-${index}`}>
                {rowData.basalRateProfile}
              </BasalRateCellDiv>,
              ...extraRowsComponent(rowData)(
                <BasalRateCellDiv key={`basal-rate-cell-${index}`}>
                  &nbsp;
                </BasalRateCellDiv>,
              ),
            ]),
          )}
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv>
          {flat(
            cardData.map((rowData, index) => [
              <PumpCellDiv key={`pump-cell-${index}`}>
                <PumpTextBeforeIconSpan>
                  {rowData.pumpTextBeforeIcon}
                </PumpTextBeforeIconSpan>
                {renderPumpIconType(rowData.pumpIcon)(t)}
                <PumpTextAfterIconSpan>
                  {rowData.pumpTextAfterIcon}
                </PumpTextAfterIconSpan>
              </PumpCellDiv>,
              ...extraRowsComponent(rowData)(
                <PumpCellDiv key={`pump-cell-${index}`}>
                  <PumpTextBeforeIconSpan>&nbsp;</PumpTextBeforeIconSpan>
                  <PumpTextAfterIconSpan>&nbsp;</PumpTextAfterIconSpan>
                </PumpCellDiv>,
              ),
            ]),
          )}
        </ColumnCellsContainerDiv>
        <ColumnCellsContainerDiv borderRight="none" width="100%">
          {flat(
            cardData.map((rowData, index) => [
              <AdvicedBolusCellDiv key={`bolusAdvice-cell-${index}`}>
                {renderAdvicedBolusCellContentsDivType(rowData)}
              </AdvicedBolusCellDiv>,
              ...extraRowsComponent(rowData)(
                <AdvicedBolusCellDiv key={`bolusAdvice-cell-${index}`}>
                  &nbsp;
                </AdvicedBolusCellDiv>,
              ),
            ]),
          )}
        </ColumnCellsContainerDiv>
      </CardDiv>
    );
  };

  const selectedRowId =
    match.params.selectedDate &&
    getSelectedRowId(
      match,
      logbookDiaryData.map(([{ dateStringISO }]) => ({
        date: dateStringISO,
      })),
    );

  return (
    <Fragment>
      <RenderIf validate={logbookDiaryData.length !== 0}>
        <GraphControls
          showChangeGraphToggle
          graphType={GRAPHS.LOGBOOK}
          graph={graph}
        >
          {!additionalInfoActivated() && (
            <AdditionalInformationButton
              content={logbookDisclaimers}
              updated={logbookDisclaimersLastUpdated}
            />
          )}
        </GraphControls>
      </RenderIf>
      <LogbookWrapper blueBackground={!isEmpty(logbookDiaryData) && !isLoading}>
        <LogbookDiaryWrapperDivWithLoader
          hasError={isEmpty(logbookDiaryData) && !isLoading}
          isLoading={isLoading}
        >
          <SeparateBordersTable width="auto" clearFill>
            <LogbookDiaryTableHeader
              bloodGlucoseUnit={bloodGlucoseUnit}
              is12hourTimeFormat={is12hourTimeFormat}
            />
          </SeparateBordersTable>
          <LogbookDiaryScrollWrapper>
            <ResizeWrapper
              minHeight={MIN_EXPANDED_STD_GRAPH_HEIGHT}
              render={(height) => (
                <LogbookGraphWrapper tableHeight={height}>
                  <AutoSizer>
                    {({ width }) => (
                      <List
                        rowCount={logbookDiaryData.length}
                        overscanRowCount={1}
                        rowRenderer={rowRenderer(
                          selectedRowId,
                          is12hourTimeFormat,
                        )}
                        height={height}
                        width={width}
                        rowHeight={({ index }) =>
                          (logbookDiaryData[index].length +
                            totalExtraRowsCount(logbookDiaryData[index])) *
                            cardRowHeight +
                          cardMarginBottom
                        }
                        containerStyle={{ overflow: 'visible' }}
                        scrollToAlignment="start"
                        scrollToIndex={selectedRowId}
                      />
                    )}
                  </AutoSizer>
                </LogbookGraphWrapper>
              )}
              resizeFunction={(clientHeight) => clientHeight}
            />
          </LogbookDiaryScrollWrapper>
        </LogbookDiaryWrapperDivWithLoader>
      </LogbookWrapper>
    </Fragment>
  );
};
