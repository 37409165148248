// @ts-nocheck
import * as React from 'react';
import { DisclaimerIcon, XIcon } from 'src/assets/icons';
import { Block, Button, LocalizedText } from 'src/components';
import { ButtonReset, colors } from 'src/core/styles';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { CommonHeader, ModalBody } from 'src/widgets/modal';

import {
  Buttons,
  Content,
  CustomBody,
  Subheading,
  TitleSpan,
} from './../deactivate-patient-modal.style';

export const DeactivatePatientConfirmationModalComponent = ({
  destroyModal,
  data: { onConfirm },
  t,
}) => (
  <ModalBody>
    <CommonHeader>
      <TitleSpan>
        <LocalizedText fontSize={4} textKey="deactivatePatient.title" />
      </TitleSpan>
      <ButtonReset onClick={destroyModal}>
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <CustomBody>
      <Content>
        <Block mb={3}>
          <DisclaimerIcon
            withBorder={true}
            borderColor={colors.brandBlue}
            iconColor={colors.brandBlue}
            borderFillColor={colors.blueMarineAlpha}
            height={60}
            width={60}
          />
        </Block>
        <Subheading>
          <LocalizedText textKey="deactivatePatient.confirmation" />
        </Subheading>
      </Content>
      <Buttons>
        <Button
          label={t('deactivatePatient.buttons.cancel')}
          buttonStyle="secondary"
          mr={4}
          onClick={destroyModal}
        />
        <Button
          label={t('deactivatePatient.buttons.accept')}
          onClick={onConfirm}
        />
      </Buttons>
    </CustomBody>
  </ModalBody>
);

export const DeactivatePatientConfirmationModal = withTranslation(
  DeactivatePatientConfirmationModalComponent,
);
