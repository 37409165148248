import styled from 'styled-components';

import { Form } from 'src/domains/diagnostics/components/forms';
import { spacing, fontSize, colors } from 'src/core/styles';

export const GraphOptionsTitle = styled.h3`
  font-size: ${fontSize.title};
  border-bottom: 1px solid ${colors.silverMedium};
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

export const GraphOptionsContainer = styled.div`
  margin-left: auto;
`;

export const GearIconContainer = styled.div`
  margin-right: ${spacing.three};
  cursor: pointer;
`;

export const PopoverContentContainer = styled.div`
  padding: ${spacing.three};
`;

export const CheckboxesForm = styled(Form)`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  padding: ${spacing.three} 0;
`;

export const SVGContainer = styled.span`
  margin-right: ${spacing.two};
  display: flex;
  align-items: center;
`;
