import { getConfig } from '@roche/roche-common';

import { queryParamsToQueryString } from './utils/url';

const {
  REACT_APP_DATA_DOG_IS_ENABLED,
  REACT_APP_DATA_DOG_APPLICATION_ID,
  REACT_APP_DATA_DOG_CLIENT_TOKEN,
  REACT_APP_DATA_DOG_SITE,
  REACT_APP_DATA_DOG_SERVICE,
  REACT_APP_DATA_DOG_ENV,
  REACT_APP_DATA_DOG_SAMPLERATE,
  REACT_APP_DATA_DOG_TRACKINTERACTIONS,
  REACT_APP_DATA_DOG_REPLAY_SAMPLE_RATE,
  REACT_APP_DATA_DOG_TRACK_SESSION_SUBDOMAINS,
  REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS,
  REACT_APP_VERSION
} = getConfig();

const authSuccessPath = new RegExp('auth/success[?]code=[^&]*');
const redactedPath = 'auth/success?code=REDACTED';

export const injectScript = ({ path, queryParams, onError, onLoad }) => {
  const script = document.createElement('script');
  script.defer = 'defer';
  script.src = `${path}?${queryParamsToQueryString(queryParams)}`;
  script.onload = onLoad;
  script.onerror = onError;
  document.body.appendChild(script);
};

export const appendScript = ({ path, atributes, onLoad, onError }) => {
  const script = document.createElement('script');
  script.defer = 'defer';
  script.src = path;
  for (const [key, value] of Object.entries(atributes)) {
    script.setAttribute(key, value);
  }
  script.onload = onLoad;
  script.onerror = onError;
  document.head.appendChild(script);
};

export const appendDataDogScript = () => {
  if (
    !REACT_APP_DATA_DOG_IS_ENABLED ||
    REACT_APP_DATA_DOG_IS_ENABLED !== 'true'
  ) {
    return;
  }
  const { DD_RUM }: any = window;
  if (DD_RUM) {
    DD_RUM.init({
      applicationId: REACT_APP_DATA_DOG_APPLICATION_ID,
      clientToken: REACT_APP_DATA_DOG_CLIENT_TOKEN,
      site: REACT_APP_DATA_DOG_SITE,
      service: REACT_APP_DATA_DOG_SERVICE,
      env: REACT_APP_DATA_DOG_ENV,
      version: REACT_APP_VERSION,
      sampleRate: +REACT_APP_DATA_DOG_SAMPLERATE,
      allowedTracingOrigins: REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS
        ? [REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS]
        : [],
      replaySampleRate: +REACT_APP_DATA_DOG_REPLAY_SAMPLE_RATE,
      trackSessionAcrossSubdomains:
        REACT_APP_DATA_DOG_TRACK_SESSION_SUBDOMAINS === 'true',
      trackInteractions: REACT_APP_DATA_DOG_TRACKINTERACTIONS === 'true',
      silentMultipleInit: true,
      beforeSend: (event) => {
        // Remove Access Code from the event's URLs
        event.view.url = event.view.url.replace(authSuccessPath, redactedPath)
        event.view.referrer = event.view.referrer.replace(authSuccessPath, redactedPath)
        if (event.resource && event.resource.url) {
          event.resource.url = event.resource.url.replace(authSuccessPath, redactedPath)
        }
        if (event.error && event.error.resource && event.error.resource.url) {
          event.error.resource.url = event.error.resource.url.replace(authSuccessPath, redactedPath)
        }
      }
    });
  }
};
