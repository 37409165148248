import { getConfig } from '@roche/roche-common';
import { ID, PREFIX, TITLE } from './additional-info.constants';
import { CONTENTS } from './additional-info.constants';
import { pathOr } from 'ramda';
import { TFunction } from 'i18next';

export const getProps = (
  t: TFunction,
  info: string,
  profile: string,
  active?: string,
) => {
  return {
    id: ID,
    headerTitle: t(`${PREFIX}${info}.${TITLE}`),
    showCloseButton: true,
    closeOnAction: true,
    primaryButtonText: null,
    secondaryButtonText: null,
    tertiaryButtonText: null,
    info,
    active,
    ...pathOr(pathOr(null, [info], CONTENTS), [info, profile], CONTENTS),
  };
};

export const additionalInfoActivated = () =>
  `${getConfig().REACT_APP_ADDITIONAL_INFO}` === 'true';
