import { path, pathOr } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { selectEC6UserUnitMeasurement } from 'src/core/user/user.selectors';

import { BLOOD_GLUCOSE_UNITS_FAMILY } from '../user-units-selector.constants';

import { translate } from 'src/i18n';

export const selectUserUnitsMeasurement = path(['userUnitsMeasurement']);

export const selectIsFetchingUserUnits = createSelector(
  selectUserUnitsMeasurement,
  path(['isFetching']),
);

export const selectErrorUserUnits = createSelector(
  selectUserUnitsMeasurement,
  path(['errorUserUnitMeasure']),
);

export const selectSuccessUserUnits = createSelector(
  selectUserUnitsMeasurement,
  path(['successUserUnitMeasure']),
);

export const selectAllUserUnitsMeasurement = createSelector<any, any, any[]>(
  selectUserUnitsMeasurement,
  pathOr([], ['allUserMeasurements']),
);

export const selectGlucoseUnitOptions = createSelector(
  selectAllUserUnitsMeasurement,
  (allMeasurements) =>
    allMeasurements.filter(
      (unitFamily) => unitFamily.name === BLOOD_GLUCOSE_UNITS_FAMILY,
    ),
);

export const selectBloodGlucoseUnitDropDownOptions = createSelector(
  selectGlucoseUnitOptions,
  (allGlucoseUnitOptions) => {
    let allGlucoseUnitOptionsList: any[] = [];
    allGlucoseUnitOptionsList =
      allGlucoseUnitOptions.length > 0 &&
      allGlucoseUnitOptions[0].unitMeasurementModels.map((el) => ({
        label: translate(`unit-settings.${el.unit}`),
        value: translate(`unit-settings.${el.unit}`),
        unit: el.unit,
      }));
    return allGlucoseUnitOptionsList;
  },
);

export const unitConector = createStructuredSelector({
  userUnitMeasurementType: selectEC6UserUnitMeasurement,
  allUserUnitsMeasurement: selectBloodGlucoseUnitDropDownOptions,
  isFetchingUserUnits: selectIsFetchingUserUnits,
  userUnitsError: selectErrorUserUnits,
  userUnitsSuccess: selectSuccessUserUnits,
});
