import * as React from 'react';
import { testId } from '@roche/roche-common';
import styles from './styles.module.css';

import { RenderIf } from 'src/utils';
import {
  MedicalDeviceIcon,
  MedicalDeviceIdIcon,
  CECodeIcon,
} from 'src/assets/icons';
import * as i18n from 'src/utils/i18n';
import { t } from 'i18next';

import {
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
  JELLO_TEXT_SIZES,
  JELLO_TEXT_WEIGHTS,
} from 'src/app/app.jello.constants';

type Props = {
  appVersion?: string;
  moduleDataList?: any;
  rdcpManufactureDate?: string;
};

export const GeneralInformationViewComponent = ({
  appVersion,
  moduleDataList,
  rdcpManufactureDate,
}: Props) => {
  const moduleData = moduleDataList[0] || null;
  const loakliseModuleNameTarget = moduleData
    ? `manufacturerInfo.${moduleData.name}.name`
    : '';

  const manufactureInfo = (date) => (
    <jello-row class={styles.row}>
      <jello-col>
        <jello-icon
          size={JELLO_ICON_SIZES.M}
          icon-name={JELLO_ICON_NAMES.MANUFACTURE}
        />
      </jello-col>
      <jello-col xs-4="">
        <jello-text size={JELLO_TEXT_SIZES.S}>
          {t('manufacturerInfo.manufacturer')}
          <br />
          {t('manufacturerInfo.addressLine1')}
          <br />
          {t('manufacturerInfo.addressLine2')}
          <br />
          {`${t('manufacturerInfo.addressLine3')}`}
          <br />
          {`${t('manufacturerInfo.addressLine4')}`}
        </jello-text>
      </jello-col>
      <jello-col>
        <jello-icon
          size={JELLO_ICON_SIZES.M}
          icon-name={JELLO_ICON_NAMES['MANUFACTURER--DATE']}
        />
      </jello-col>
      <jello-col xs-4="">
        <jello-text size={JELLO_TEXT_SIZES.S}>
          {i18n.getLabel(t('manufacturerInfo.manufactureDate'))} <br />
          {date ? date : t('manufacturerInfo.genInfoRdcpDate')}
        </jello-text>
      </jello-col>
    </jello-row>
  );

  return (
    <div className={styles.generalInfoContainer}>
      <jello-text
        size={JELLO_TEXT_SIZES.XL}
        weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
      >
        {t('manufacturerInfo.general')}
      </jello-text>

      <jello-text size={JELLO_TEXT_SIZES.S} class={styles.row}>
        {i18n.getLabel(
          t('manufacturerInfo.validInfoFor', {
            version: appVersion ? appVersion : '',
          }),
        )}
      </jello-text>

      <jello-text
        size={JELLO_TEXT_SIZES.L}
        weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
        class={styles.row}
      >
        {t('manufacturerInfo.explanationSymbols.title')}
      </jello-text>

      <jello-text size={JELLO_TEXT_SIZES.S} class={styles.row}>
        {t('manufacturerInfo.explanationSymbols.description')}
      </jello-text>

      <jello-grid>
        <jello-row class={styles.row}>
          <jello-col>
            <jello-icon
              size={JELLO_ICON_SIZES.M}
              icon-name={JELLO_ICON_NAMES['WARNING--ALT']}
              class={styles.blueIcon}
            />
          </jello-col>
          <jello-col xs-7="">
            <jello-text size={JELLO_TEXT_SIZES.S}>
              {t('manufacturerInfo.explanationSymbols.caution')}
            </jello-text>
          </jello-col>
        </jello-row>
        <jello-row class={styles.row}>
          <jello-col>
            <MedicalDeviceIcon />
          </jello-col>
          <jello-col xs-4="">
            <jello-text size={JELLO_TEXT_SIZES.S}>
              {t('manufacturerInfo.explanationSymbols.medDevice')}
            </jello-text>
          </jello-col>
          <jello-col>
            <MedicalDeviceIdIcon />
          </jello-col>
          <jello-col xs-4="">
            <jello-text size={JELLO_TEXT_SIZES.S}>
              {i18n.getLabel(
                (
                  t('manufacturerInfo.explanationSymbols.deviceId') || ''
                ).replace(/(.{5})\s*$/, appVersion ? appVersion : ''),
              )}
            </jello-text>
          </jello-col>
        </jello-row>
        {manufactureInfo(rdcpManufactureDate)}

        <jello-row class={styles.endRow}>
          <jello-col>
            <div className={styles.ceContainer}>
              <CECodeIcon />
            </div>
          </jello-col>
          <jello-col xs-4="">
            <jello-text size={JELLO_TEXT_SIZES.S}>
              {t('manufacturerInfo.ceInfo')}
            </jello-text>
          </jello-col>
          <jello-col>
            <jello-icon
              size={JELLO_ICON_SIZES.M}
              icon-name={JELLO_ICON_NAMES['ADDITIONAL-INFORMATION']}
            />
          </jello-col>
          <jello-col xs-4="">
            <jello-text size={JELLO_TEXT_SIZES.S}>
              {t('manufacturerInfo.consultElectronic')}
            </jello-text>
          </jello-col>
        </jello-row>

        <RenderIf validate={moduleData}>
          <jello-row class={styles.row}>
            <jello-col>
              <jello-text
                size={JELLO_TEXT_SIZES.M}
                weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
                {...testId('manufacturerInfo', moduleData.name)}
              >
                {`${t(loakliseModuleNameTarget)} ${t(
                  'manufacturerInfo.version',
                )}${moduleData.version}`}
              </jello-text>
            </jello-col>
          </jello-row>
          {manufactureInfo(moduleData.publishedDate)}
        </RenderIf>
      </jello-grid>
    </div>
  );
};
