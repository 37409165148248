import {
  getJSON,
  endpointTransformer,
  createAuthHeader,
} from 'src/utils/service/service.utils';

import { CheckEmailResponse, CheckEmailParams } from './check-email.types';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const CheckEmailLoaderImpl = (
  { email, hasUserPlatformPermission }: CheckEmailParams,
  accessToken: string,
  gigyaToken: string,
) =>
  getJSON(
    endpointTransformer(endpointWithParams(ENDPOINTS.checkEmail, { email }), [
      '/ciam/api/v1/access/patient/checkEmail',
      '/ciam/api/v2/access/patients/check-email',
      null,
      null,
      hasUserPlatformPermission,
    ]),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );

export const CheckEmailTransformImpl = ({
  model,
}: CheckEmailResponse): boolean => model;

export const CheckEmailServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
