import * as React from 'react';
import { ResizeChildrenWrapper } from './resize-children-wrapper.component';

export class ResizeGraphWrapper extends React.Component<any, any> {
  private cleanupCallbacks: any;
  private getEl: any;
  constructor(props) {
    super(props);
    this.state = {
      layout: EmptyDomRect,
    };

    this.cleanupCallbacks = [];

    this.getEl = (el) => {
      if (!el) return;
      // @ts-ignore
      const observer = new window.ResizeObserver((entries) => {
        const entry = entries.find(({ target }) => target === el);
        if (!entry) return;
        this.setState((state) => ({ layout: entry.contentRect }));
      });
      observer.observe(el);
      this.cleanupCallbacks.push(() => observer.unobserve(el));
    };
  }

  public componentWillUnmount() {
    this.cleanupCallbacks.forEach((callback) => callback());
  }

  public render() {
    return (
      <ResizeChildrenWrapper
        render={this.props.render}
        layout={this.state.layout}
        element={this.getEl}
      />
    );
  }
}

const EmptyDomRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};
