import styled from 'styled-components';

import { fontSize } from 'src/core';
import { colors } from 'src/core/styles/colors';
import { P, Subheading, Title, Row } from 'src/components';
import { convertPxToRem } from 'src/utils';

export const ManufacturerLeftBlock = styled.div`
  min-width: 20.84%;
`;

type ManufacturerLeftItemLinkProps = {
  active: boolean;
};

export const ManufacturerLeftItemLink = styled.a<ManufacturerLeftItemLinkProps>`
  width: 100%;
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: ${(props) =>
    props.active ? props.theme.colors.blue800 : props.theme.colors.brandBlue};
  &: visited {
    text-decoration: none;
    color: ${(props) =>
      props.active ? props.theme.colors.blue800 : props.theme.colors.brandBlue};
  }
`;

export const ManufacturerLeftItem = styled.div`
  width: 100%;
  padding: 12px 10px 10px 15px;
`;

export const ManufacturerLeftMenu = styled.div`
  margin: 24px 10px;
  height: 480px;
`;

export const ManufacturerRightBlock = styled.div`
  width: 100%;
  height: 560px;
  border-left: 1px solid ${(props) => props.theme.colors.gray250};
  padding: 30px 30px 15px 30px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ManufacturerHeader = styled.div`
  background: ${(props) => props.theme.colors.blue600};
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.spacing.two};
  ${(props) => props.theme.spacing.three};
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
`;

export const ManufacturerTitle = styled.div`
  flex-grow: 2;
  height: 20px;
  padding-left: ${(props) => props.theme.spacing.two};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${fontSize.p};
  line-height: 24px;
`;

export const GeneralInformation = styled.div`
  width: 100%;
`;

export const FlexWrapperDiv = styled.div`
  color: ${(props) => props.theme.colors.charcoal};
  display: flex;
`;

export const ManufacturerInfoLogoDiv = styled.div`
  display: flex;
  padding-left: 60px;
  padding-top: 25px;
  min-width: ${convertPxToRem(80)};
`;

export const ManufacturerInfoWhiteLogoDiv = styled.div`
  display: flex;
  min-width: ${convertPxToRem(80)};
  justify-content: center;
  flex-direction: row;
  text-align: center;
  height: 100%;
`;

export const FactoryIconDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: ${(props) => props.theme.spacing.three};
`;

export const MedicalDeviceIconWrapper = styled.div`
  margin-top: 4px;
`;

export const ManufacturerInfoTitle = styled(Title)`
  color: ${(props) => props.theme.colors.black};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : props.theme.fontWeights.semiBold};
  font-size: ${fontSize.headline};
  line-height: 32px;
  margin-bottom: ${(props) => props.theme.spacing.three};
`;

export const ManufacturerInfoSymbolTitle = styled(ManufacturerInfoTitle)`
  font-size: 20px;
  margin-top: 1rem;
`;

export const ManufacturerInfoSubTitle = styled(Subheading)`
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  font-size: ${fontSize.subheading};
  line-height: 24px;
  color: ${(props) => props.theme.colors.black};
`;
export const ManufacturerInfoIntenUseDescription = styled.div`
  font-size: 14px;
  .intended-subtitle {
    font-weight: 700;
    margin-top: 14px;
  }
  .intended-subtitle.product {
    font-weight: 400;
    font-size: 16px;
    b {
      font-weight: 600;
    }
  }
  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    margin: 0;
    line-height: 20px;
  }
  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const ManufacturerInfoImportantInfoDescription = styled.div`
  font-size: 14px;
  .important-info-p {
    margin-top: 1.5rem;
  }
  p {
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 20px;
    margin: 0;
  }
  b {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 24px;
    color: ${(props) => props.theme.colors.black};
  }
`;

export const TextBlock = styled.div`
  color: ${(props) => props.theme.colors.black};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${fontSize.p};
  line-height: 18px;
  padding-right: 2px;
`;
export const CeInfo = styled(Row)`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.black};
  .info {
    font-size: ${fontSize.p};
    line-height: 18px;
    margin: 2px 20px 0 15px;
    &.ceInfo {
      width: 210px;
    }
    &.electronics {
      width: 185px;
    }
  }
`;

export const ManufacturerInformationText = styled.p`
  margin: 0;

  span {
    padding-left: 15px;
  }
`;

export const GeneralUseText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const GeneralUseTextImpressum = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  p {
    margin: 0px;
  }
`;

export const ImpressumMailTo = styled.a`
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const GeneralUseTextNoMargin = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  margin: 0px;
`;

export const GeneralUseTextBold = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin: 0px;
`;

// to preserve spacing and line breaks between trademarks
export const TrademarkSpan = styled.span`
  display: inline-block;
  white-space: pre;
`;
TrademarkSpan.displayName = 'TrademarkSpan';

export const AddressDiv = styled(FlexWrapperDiv)`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  margin-top: 0px;
  padding-top: 20px;
  color: ${(props) => props.theme.colors.black};
  ${ManufacturerInfoLogoDiv} {
    align-items: center;
  }
  ${ManufacturerInfoWhiteLogoDiv} {
    margin-left: ${(props) => props.theme.spacing.three};
  }

  ${P} {
    margin: 0 auto ${(props) => props.theme.spacing.one};
  }
  .info {
    font-size: ${fontSize.p};
    line-height: 18px;
    margin: 2px 20px 0 15px;
    &.ceInfo {
      width: 210px;
    }
`;
export const CautionDiv = styled(FlexWrapperDiv)`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  margin-top: 1rem;
  max-width: 80%;
  color: ${(props) => props.theme.colors.black};
  .warning-alt-icon {
    color: ${(props) => props.theme.colors.brandBlue};
  }
  .info {
    font-size: ${fontSize.p};
    line-height: 18px;
    margin: 2px 20px 0 15px;
    &.ceInfo {
      width: 80%;
    }
`;

export const YearSpan = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: 1rem;
  margin-left: 14px;
  margin-right: ${(props) => props.theme.spacing.two};
`;

export const BlockTitle = styled.div`
  padding-bottom: 30px;
`;

export const ManufactureDate = styled(P)`
  text-align: left;
`;

export const InfoProductOwnerDiv = styled.div`
  width: 212px;
  margin-right: 8px;
`;
export const SingleBlockText = styled.div`
  padding-bottom: 15px;
`;

export const WarningCardContent = styled.div`
  padding-top: 4px;
  font-size: 14px;
`;
export const WarningCard = styled.div`
  background-color: ${colors.colorBrand05};
  border-radius: 0.5rem;
  margin-top: 15px;
  padding: 16px 16px 16px 24px;
  width: 100%;
  .warning-icon-disclaimers {
    color: ${colors.brandBlue};
  }
  p {
    margin: 4px 0 4px 0;
  }
  .warning-sub-text-top {
    margin-bottom: 0;
  }
  .warning-sub-text-bottom {
    margin-top: 0;
  }
`;
export const WarningCardContainer = styled.div`
  .warning-text-with-icon-container,
  .warning-text-with-icon-container-with-multiple-text {
    padding-left: 39px;
  }
  .warning-text-with-icon-container-with-multiple-text {
    padding-top: 0px;
  }
  .warning-box-title {
    text-transform: uppercase;
  }
`;
export const WarningIconContainer = styled.div`
  float: left;
  padding-right: 15px;
`;

export const WarningSecondaryContainer = styled.div`
  padding-top: 16px;
`;

export const WarningsMainContainer = styled.div`
  padding-top: 6px;
  padding-bottom: 9px;
`;
