import * as React from 'react';
import { RenderIf } from 'src/domains/diagnostics/utils';
import { GRAPHS } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

import { Container } from './graph-type-buttons.style';
import {
  prepareListOfDeviceSettingsTabs,
  prepareListOfLogbookTabs,
  prepareListOfGraphTabs,
} from './graph-type-buttons.utils';
import { GraphTabList } from '../graph-tab-list/graph-tab-list.component';
import { ChangeGraphTypeButtonsProps } from './graph-type-buttons.types';

export const ChangeGraphTypeButtons = ({
  graph,
  graphType,
  logbookType,
  changeGraphType,
  changeLogbookType,
  showChangeGraphToggle = false,
  deviceSettingsType,
  changeDeviceSettingsType,
}: ChangeGraphTypeButtonsProps) => {
  const listOfGraphTabs = prepareListOfGraphTabs(graphType);
  const listOfLogbookTabs = prepareListOfLogbookTabs(logbookType);
  const listOfDeviceSettingsTabs = prepareListOfDeviceSettingsTabs();
  return (
    <RenderIf validate={showChangeGraphToggle}>
      <Container>
        <RenderIf
          validate={
            graph === GRAPHS.TREND ||
            graph === GRAPHS.STANDARD_DAY ||
            graph === GRAPHS.STANDARD_WEEK
          }
        >
          <GraphTabList
            listOfTabs={listOfGraphTabs}
            graphType={graphType}
            eventFunc={changeGraphType}
            graph={graph}
          />
        </RenderIf>
        <RenderIf validate={graph === GRAPHS.LOGBOOK}>
          <GraphTabList
            listOfTabs={listOfLogbookTabs}
            graphType={logbookType}
            eventFunc={changeLogbookType}
            graph={graph}
          />
        </RenderIf>
        <RenderIf validate={graph === GRAPHS.DEVICE_SETTINGS}>
          <GraphTabList
            listOfTabs={listOfDeviceSettingsTabs}
            graphType={deviceSettingsType}
            eventFunc={changeDeviceSettingsType}
            graph={graph}
          />
        </RenderIf>
      </Container>
    </RenderIf>
  );
};
