import * as React from 'react';
import { testId } from '@roche/roche-common';

import {
  Button,
  InputText,
  InputError,
  LocalizedText,
  Row,
  Column,
  Widget,
  Form,
  ControlButton,
  FormControl,
  ControlText,
} from 'src/components';
import { CreatePatientIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';

import {
  AddPatientDiv,
  ButtonContainer,
  SearchPatientFormDiv,
  PatientSearchFields,
  PatientSearchFieldBlock,
  PatientSearchFieldLabel,
} from './patient-search.style';
import {
  patientNameErrors,
  patientNameValidators,
} from './patient-search.validators';

import { TitleName } from '../../../components/fonts';

export const PatientSearchFormFull = ({
  onSearch,
  onCreatePatient,
  children,
  t,
  hasCreatePatient,
}) => {
  const patientNameErrorsMessages = {
    isNameLongEnough: t(patientNameErrors.isNameLongEnough),
  };
  return (
    <Widget flexDirection="column" mb={4} mt={4}>
      <SearchPatientFormDiv>
        <Form model="patientSearch" onSubmit={onSearch} autoComplete="off">
          <Row wrap="wrap">
            <Row>
              <Column {...testId('basic-home-screen', 'title')}>
                <TitleName>
                  <LocalizedText textKey="patientSearch.title" />
                </TitleName>
              </Column>
              <Column>
                <Row pt={3}>
                  <Row
                    onClick={() => {
                      if (!hasCreatePatient) {
                        onCreatePatient();
                      }
                    }}
                    justifyContent="flex-end"
                    {...testId('basic-home-screen', 'create-patient')}
                  >
                    <AddPatientDiv
                      className={hasCreatePatient ? 'disabled' : 'active'}
                    >
                      <CreatePatientIcon
                        height={20}
                        fillColor={
                          colors[hasCreatePatient ? 'grayMedium' : 'brandBlue']
                        }
                      />
                      <LocalizedText
                        pl={3}
                        color={hasCreatePatient ? 'grayMedium' : 'brandBlue'}
                        textKey="patientSearch.addPatient"
                      />
                    </AddPatientDiv>
                  </Row>
                </Row>
              </Column>
            </Row>
          </Row>
          <Row py={2} justify="space-between">
            <PatientSearchFields>
              <PatientSearchFieldBlock p={0}>
                <PatientSearchFieldLabel>
                  {t('searchBar.patientId')}
                </PatientSearchFieldLabel>
                <InputText
                  id="patient-id"
                  model="patientSearch.patientID"
                  placeholder={t('searchBar.patientId')}
                  p={2}
                  mr={2}
                  mt={1}
                />
              </PatientSearchFieldBlock>
              <PatientSearchFieldBlock p={0}>
                <PatientSearchFieldLabel>
                  {t('searchBar.name')}
                </PatientSearchFieldLabel>
                <ControlText
                  component={FormControl}
                  id="patient-name"
                  model="patientSearch.fullName"
                  validators={patientNameValidators}
                  label=""
                  mapProps={{
                    valid: ({ fieldValue }) => fieldValue.valid,
                    focus: ({ fieldValue }) => fieldValue.focus,
                  }}
                  placeholder={t('searchBar.name')}
                  p={2}
                  mr={2}
                  mt={1}
                />
                <InputError
                  id="patient-name"
                  messages={patientNameErrorsMessages}
                  model="patientSearch.fullName"
                />
              </PatientSearchFieldBlock>
            </PatientSearchFields>
            <ButtonContainer>
              <ControlButton
                type="submit"
                model="patientSearch"
                component={Button}
                mapProps={{
                  label: t('searchBar.search'),
                }}
              />
            </ButtonContainer>
          </Row>
        </Form>
      </SearchPatientFormDiv>
      {children}
    </Widget>
  );
};
