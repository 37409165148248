import styled from 'styled-components';

import { colors, spacing } from 'src/core/styles';

export const cardSpace = '2rem';
export const borderRadius = {
  three: '0.1875rem', // 3px
  six: '0.375rem', // 6px
  eight: '0.5rem', // 8px
  twenty: '1.25rem', // 20px
  hundred: '6.25rem', // 100px
  circle: '50%',
};

export const LegendContainer = styled.div`
  padding-top: 1rem;
  width: 100%;
  position: absolute;
  margin-top: -21px;
  background: ${colors.white};
  border-top: 3px solid ${colors.silverLight};
`;
LegendContainer.displayName = 'LegendContainer';

export const LegendWrapper = styled.div`
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  padding-left: ${spacing.four};
  padding-right: ${spacing.four};
  padding-top: ${spacing.three};
  padding-bottom: ${spacing.three};
  background: ${colors.white};
  margin-top: -6px;
  border-top: 1px solid ${colors.grayLighter};
`;
LegendWrapper.displayName = 'LegendWrapper';

export const LegendLineData = styled.div`
  width: 100%;
  display: inline-flex;
  margin-bottom: 0.5rem;
  &:last-of-type {
    margin-bottom: none;
  }
`;
LegendLineData.displayName = 'LegendLineData';

export const LegendTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #0166cc;
  margin-bottom: 1rem;
  display: block;
`;
LegendTitle.displayName = 'LegendTitle';

export const Bagde = styled.div`
  font-size: 9px;
  min-width: 75px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  height: fit-content;
`;
Bagde.displayName = 'Bagde';

export const LegendDarkBlue = styled(Bagde)`
  background-color: #83a5cf;
`;
LegendDarkBlue.displayName = 'LegendDarkBlue';

export const LegendLightBlue = styled(Bagde)`
  background-color: #d5def1;
  color: ${colors.black};
`;
LegendLightBlue.displayName = 'LegendLightBlue';

export const LegendBlackLine = styled(Bagde)`
  background-color: ${colors.black};
  height: 3px;
  padding: 0;
  margin-top: 5px;
`;
LegendBlackLine.displayName = 'LegendBlackLine';

export const LegendGreenLine = styled(Bagde)`
  background-color: green;
  height: 3px;
  padding: 0;
  margin-top: 5px;
`;
LegendGreenLine.displayName = 'LegendGreenLine';

export const LegendText = styled.span`
  font-size: 15px;
  margin-left: 20px;
`;
LegendText.displayName = 'LegendText';

export const NavTabsListItem = styled.li`
  right: 11%;
  cursor: pointer;
  display: inline-flex;
  position: absolute;
  top: -34px;
  z-index: 1;
  border: 1px solid ${colors.silverMedium};
  padding: 0 ${spacing.three};
  background: ${colors.white};
  &.selected {
    border-bottom: 1px solid ${colors.white};
  }
  &:last-child {
    border-right: 1px solid ${colors.silverMedium};
  }
  &:only-child {
    border-right: 1px solid ${colors.silverMedium}
`;
NavTabsListItem.displayName = 'NavTabsListItem';

export const NavTabsListItemText = styled.div`
  display: inline-block;
  border-top: 0.1875rem solid transparent;
  padding: 5px 0 5px 0;
  color: ${colors.black};
  font-size: 12px;
  &.selected {
    color: ${colors.brandBlue};
    border-top-color: ${colors.blue};
  }
  color: ${colors.blue};
`;
NavTabsListItemText.displayName = 'NavTabsListItemText';

export const ContentContainer = styled.section`
  padding: 0 ${spacing.one};
  margin: -1px 0 0 0;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
`;
ContentContainer.displayName = 'ContentContainer';

export const ArrowUpDown = styled.span`
  margin-left: 0.3125rem;
  display: inline-block;
  &.selected {
    transform: rotate(180deg);
  }
  svg path {
    fill: ${colors.brandBlue};
  }
`;
ArrowUpDown.displayName = 'ArrowUpDown';

export const Legend = styled.div`
  position: absolute;
`;
Legend.displayName = 'Legend';

/** transform: ${props => (props.selected ? 'rotate(180deg)' : null)}; */
