import styled from 'styled-components';

import { colors, fontSize, spacing } from 'src/core';
import { weight } from 'src/components/fonts/weights';
import { combineRems } from 'src/utils';

import { mapCardStyle } from '../card.style';

const cardHeaderStyles = {
  blue: {
    bgColor: colors.lavender,
    borderBottomColor: colors.quartzBlue,
  },
  customClinicGuides: {
    borderBottomWidth: '1px',
    borderRadius: 0,
    margin: `0 auto ${combineRems(spacing.two, spacing.three)}`,
  },
  default: {
    bgColor: colors.white,
    borderBottomColor: colors.silver,
    borderBottomWidth: '1px',
    borderRadius: '0.375rem 0.375rem 0 0',
  },
  noHeaderBorder: {
    borderBottomColor: 'transparent',
  },
  secondary: {
    bgColor: colors.silverLight,
  },
  customClinicHab1cGuides: {
    justifyContent: 'space-between',
  },
};
const mapCardHeaderStyle = mapCardStyle(cardHeaderStyles);

const cardTitleStyles = {
  customClinicGuides: {
    titleAlign: 'left',
  },
  customClinicHab1cGuides: {
    titleAlign: 'left',
    borderBottomWidth: '1px',
    margin: `0 auto ${combineRems(spacing.two, spacing.three)}`,
  },
  default: {
    fontSize: fontSize.subheading,
    margin: 0,
    textTransform: 'uppercase',
    titleAlign: 'center',
    titleColor: colors.charcoal,
    titleWeight: weight.semiBold,
    width: '100%',
  },
  noTextTransform: {
    textTransform: 'none',
  },
  patientDashboard: {
    borderRadius: '0.375rem 0.375rem 0 0',
    fontSize: fontSize.p,
    lineHeight: 1,
    margin: '16px 0 -0 16px',
    paddingBottom: '16px',
    paddingRight: spacing.one,
    titleAlign: 'left',
    width: 'auto',
  },
};
const mapCardTitleStyle = mapCardStyle(cardTitleStyles);

const cardIconDivStyles = {
  default: {},
  patientDashboard: {
    margin: `${spacing.three} ${spacing.three} 0 auto`,
  },
  graphBgGPGraph: {
    margin: `${spacing.three} ${spacing.three} 0 auto`,
  },
  patientDashboardHba1cCard: {
    marginTop: '1rem',
    marginRight: '1rem',
  },
};
const mapCardIconDivStyle = mapCardStyle(cardIconDivStyles);

export const CardHeaderWrapper = styled.header`
  min-width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: ${mapCardHeaderStyle('bgColor')};
  border-bottom: ${mapCardHeaderStyle('borderBottomWidth')} solid
    ${mapCardHeaderStyle('borderBottomColor')};
  border-radius: ${mapCardHeaderStyle('borderRadius')};
  margin: ${mapCardHeaderStyle('margin')};
  justify-content: ${mapCardHeaderStyle('justifyContent')};
`;
CardHeaderWrapper.defaultProps = {
  cardStyles: ['default'],
};
CardHeaderWrapper.displayName = 'CardHeaderWrapper';

export const CardTitle = styled.h2`
  overflow-wrap: break-word;
  width: ${mapCardTitleStyle('width')};
  margin: ${mapCardTitleStyle('margin')};
  font-size: ${mapCardTitleStyle('fontSize')};
  font-weight: ${mapCardTitleStyle('titleWeight')};
  text-transform: ${mapCardTitleStyle('textTransform')};
  text-align: ${mapCardTitleStyle('titleAlign')};
  color: ${mapCardTitleStyle('titleColor')};
  line-height: ${mapCardTitleStyle('lineHeight')};
  letter-spacing: ${mapCardTitleStyle('letterSpacing')};
  border-bottom: ${mapCardTitleStyle('borderBottom')};
  border-radius: ${mapCardTitleStyle('borderRadius')};
  padding-bottom: ${mapCardTitleStyle('paddingBottom')};
  padding-right: ${mapCardTitleStyle('paddingRight')};
`;
CardTitle.defaultProps = {
  cardStyles: ['default'],
};
CardTitle.displayName = 'CardTitle';

export const IconDiv = styled.div`
  margin: ${mapCardIconDivStyle('margin')};

  jello-icon {
    color: ${(props) => !props.expanded && colors.brandBlue};
  }

  margin-top: ${mapCardIconDivStyle('marginTop')};
  margin-right: ${mapCardIconDivStyle('marginRight')};
  display: inline-flex;
`;
IconDiv.defaultProps = {
  cardStyles: ['default'],
};
IconDiv.displayName = 'IconDiv';
