import { flatten, pipe, reduce, sort } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import {
  combineOverlappingInsulinOneBolusMeasurements,
  flagOverlappingBolusMeasurements,
} from 'src/domains/diagnostics/scenes/graphs/insulin.utilities';

import {
  selectBasalTicks,
  selectShowGridLines,
  selectThresholdPadForBolus,
  selectTargetRangePadForBolus,
  selectGraphDetails,
  selectVerticalTicksPadForBolus,
  selectVerticalLabel,
  selectVerticalAxesCeilingPadForBolus,
} from 'src/domains/diagnostics/scenes/graphs/graph.selector';
import {
  CARBOHYDRATES_Y_MAX,
  INSULIN_Y_MAX,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  filterBasalRateChanges,
  filterProfileChanges,
  togglePointsFilter,
  sortAllInsulinBarsDescending,
} from 'src/domains/diagnostics/scenes/graphs/graph.util';
import {
  selectBloodGlucoseUnit,
  selectPatientStartDate,
  selectPatientEndDate,
  selectCarbohydratesMeasurementsInDateSliderRange,
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphToggles,
  selectGraphLoading,
  selectGraphThreshold,
  selectValidBolusesInDateSliderRange,
  selectBasalsInDateSliderRange,
  selectGlucoseMeasurementsIncludingNullValuesInDateSliderRange,
} from 'src/domains/diagnostics/store/selectors';
import { selectBasalYMax } from 'src/domains/diagnostics/scenes/graphs/graph.selector';
import { sortTwoMeasurementsByAscendingDate } from 'src/domains/diagnostics/utils/time.util';
import { isEqual as luxonDatesEqual } from 'src/utils/date';
import { selectEC6TimeFormat } from 'src/core/user/user.selectors';

import {
  calculateInsulinDimensions,
  calculateFullRange,
  convertBasalMeasurementsToLines,
  convertBasalProfileChangeToPoint,
  convertBasalRateChangeToPoint,
  convertCarbohydratesMeasurementsToLines,
  convertMeasurementsToPoints,
  crossoverDayBreakdown,
  filterTbrLines,
  filterTbrPoints,
  filterTimeSetback,
  generateLines,
  normalizeMeans,
} from './trend-detail.util';

import {
  selectHorizontalDayTicks,
  selectHorizontalMonthYearTicks,
} from '../trend.selector';

export const selectTimeRange = createSelector(
  selectPatientStartDate,
  selectPatientEndDate,
  calculateFullRange,
);

const selectGlucoseMeasurementPoints = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectTimeRange,
  selectPatientStartDate,
  selectGraphThreshold,
  selectVerticalAxesCeilingPadForBolus,
  convertMeasurementsToPoints,
);

const selectFilteredGlucoseMeasurementPoints = createSelector(
  selectGlucoseMeasurementPoints,
  selectGraphToggles,
  togglePointsFilter,
);

const selectDailyMeanBloodGlucose = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectBloodGlucoseUnit,
  selectPatientStartDate,
  selectPatientEndDate,
  selectVerticalAxesCeilingPadForBolus,
  normalizeMeans,
);

const selectMeanBloodGlucosePoints = createSelector(
  selectDailyMeanBloodGlucose,
  selectGraphToggles,
  (means, toggles) => (toggles.showMeanBloodGlucose ? means : []),
);

const selectConnectingLines = createSelector(
  selectGlucoseMeasurementPoints,
  selectPatientStartDate,
  selectPatientEndDate,
  selectTimeRange,
  selectGraphToggles,
  (measurements, startDate, endDate, timeRange, toggles) =>
    toggles.showBloodGlucoseLines
      ? generateLines(measurements, startDate, endDate, timeRange)
      : [],
);

export const selectCarbohydratesLines = createSelector(
  selectCarbohydratesMeasurementsInDateSliderRange,
  selectPatientStartDate,
  selectTimeRange,
  convertCarbohydratesMeasurementsToLines,
);

const selectGraphData = createSelector(
  selectMeanBloodGlucosePoints,
  selectFilteredGlucoseMeasurementPoints,
  selectConnectingLines,
  selectCarbohydratesLines,
  (meanBloodGlucosePoints, glucoseMeasurements, lines, carbohydratesLines) => ({
    meanBloodGlucosePoints,
    glucoseMeasurements,
    lines,
    carbohydratesLines,
  }),
);

export const selectInsulinTicks = (state) => [
  { label: '0', value: 0 },
  { label: '10', value: 10 / INSULIN_Y_MAX },
  { label: '20', value: 20 / INSULIN_Y_MAX },
];

export const selectCarbohydratesTicks = (state) => [
  { label: '0', value: 0 },
  { label: '50', value: 50 / CARBOHYDRATES_Y_MAX },
  { label: '100', value: 100 / CARBOHYDRATES_Y_MAX },
  { label: '150', value: 150 / CARBOHYDRATES_Y_MAX },
];

const normalizeBasal = (basals, yMax, totalTime, startDate) => {
  const start = startDate.startOf('day');

  const normalizedBasals = basals.map((basal) => ({
    ...basal,
    x: ((basal.date - start) % totalTime) / totalTime,
    y: basal.basalCbrf / yMax,
  }));

  return normalizedBasals;
};

const selectBasalLines = createSelector(
  selectBasalsInDateSliderRange,
  selectBasalYMax,
  selectTimeRange,
  selectPatientStartDate,
  (basals, yMax, totalTime, startDate) => {
    const normalizedBasals = normalizeBasal(basals, yMax, totalTime, startDate);
    return convertBasalMeasurementsToLines(normalizedBasals, yMax);
  },
);

export const selectTbrLines = createSelector(
  selectBasalsInDateSliderRange,
  selectTimeRange,
  selectPatientStartDate,
  filterTbrLines,
);

export const selectTbrPoints = createSelector(selectTbrLines, filterTbrPoints);

const selectProfileChanges = createSelector(
  selectBasalsInDateSliderRange,
  selectTimeRange,
  selectPatientStartDate,
  (basals, totalTime, startDate) =>
    filterProfileChanges(basals).map(
      convertBasalProfileChangeToPoint(totalTime, startDate),
    ),
);

const selectBasalRateChanges = createSelector(
  selectBasalsInDateSliderRange,
  selectTimeRange,
  selectPatientStartDate,
  (basals, totalTime, startDate) =>
    filterBasalRateChanges(basals).map(
      convertBasalRateChangeToPoint(totalTime, startDate),
    ),
);

const selectTimeSetback = createSelector(
  selectBasalsInDateSliderRange,
  selectTimeRange,
  selectPatientStartDate,
  filterTimeSetback,
);

const selectInsulinPoints = createSelector(
  selectGlucoseMeasurementsIncludingNullValuesInDateSliderRange,
  selectValidBolusesInDateSliderRange,
  selectTimeRange,
  selectPatientStartDate,
  (glucose, boluses, totalTime, startDate) => {
    const processedGlucose = combineOverlappingInsulinOneBolusMeasurements(
      luxonDatesEqual,
    )(glucose, boluses);
    const processedBoluses = flagOverlappingBolusMeasurements(luxonDatesEqual)(
      glucose,
      boluses,
    );

    return pipe(
      sort(sortTwoMeasurementsByAscendingDate),
      reduce(
        calculateInsulinDimensions(INSULIN_Y_MAX, startDate, totalTime),
        [],
      ),
      reduce(crossoverDayBreakdown(totalTime), []),
      sort(sortAllInsulinBarsDescending),
    )(flatten([processedGlucose, processedBoluses]));
  },
);

export const trendDetailConnector = createStructuredSelector({
  basalLines: selectBasalLines,
  basalTicks: selectBasalTicks,
  basalRateChanges: selectBasalRateChanges,
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  carbohydratesTicks: selectCarbohydratesTicks,
  graphData: selectGraphData,
  graphDetails: selectGraphDetails,
  graphToggles: selectGraphToggles,
  graphYMax: selectVerticalAxesCeilingPadForBolus,
  horizontalDayTicks: selectHorizontalDayTicks,
  horizontalMonthYearTicks: selectHorizontalMonthYearTicks,
  insulinTicks: selectInsulinTicks,
  insulinPoints: selectInsulinPoints,
  isLoading: selectGraphLoading,
  measurements: selectGlucoseMeasurementsInDateSliderRange,
  profileChanges: selectProfileChanges,
  showGridLines: selectShowGridLines,
  targetRange: selectTargetRangePadForBolus,
  tbrLines: selectTbrLines,
  tbrPoints: selectTbrPoints,
  threshold: selectThresholdPadForBolus,
  timeSetback: selectTimeSetback,
  verticalLabel: selectVerticalLabel,
  verticalTicks: selectVerticalTicksPadForBolus,
  timeFormat: selectEC6TimeFormat,
});
