export const UNITS = {
  MMOLL: {
    label: 'mmol/L',
    limits: {
      nonMealRelated: {
        actualHyper: [7.8, 22.2],
        hyper: [5, 16.6],
        warning: [3.3, 7.8],
        hypo: [3, 5.5],
      },
      beforeEating: {
        actualHyper: [7.8, 22.2],
        hyper: [5, 10],
        warning: [3.3, 7.8],
        hypo: [3, 5.5],
      },
      afterEating: {
        actualHyper: [7.8, 22.2],
        hyper: [5, 16.6],
        warning: [3.3, 7.8],
        hypo: [3, 5.5],
      },
      beforeBed: {
        actualHyper: [7.8, 22.2],
        hyper: [5, 16.6],
        warning: [3.3, 7.8],
        hypo: [3, 5.5],
      },
    },
  },
  MGDL: {
    label: 'mg/dL',
    limits: {
      nonMealRelated: {
        actualHyper: [140, 400],
        hyper: [90, 300],
        warning: [60, 140],
        hypo: [54, 100],
      },
      beforeEating: {
        actualHyper: [140, 400],
        hyper: [90, 180],
        warning: [60, 140],
        hypo: [54, 100],
      },
      afterEating: {
        actualHyper: [140, 400],
        hyper: [90, 300],
        warning: [60, 140],
        hypo: [54, 100],
      },
      beforeBed: {
        actualHyper: [140, 400],
        hyper: [90, 300],
        warning: [60, 140],
        hypo: [54, 100],
      },
    },
  },
};
