import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { transformClinicGuides } from './get-clinic-guides.util';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const GetClinicGuidesLoaderImpl = (
  { therapyId },
  accessToken,
  gigyaToken,
) =>

  getJSON(
    therapyId ? endpointWithParams(ENDPOINTS.getClinicGuidesWithTherapy, { therapyId }) : ENDPOINTS.getClinicGuides,
    {
      headers: {
        Authorization: createAuthHeader(accessToken),        
      },
    },
  );

export const GetClinicGuidesTransformImpl = (data) =>
  transformClinicGuides(data);

export const GetClinicGuidesServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
