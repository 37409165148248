import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectPlatformPermission,
  selectIsFetchingPermissions,
} from 'src/core/permissions';

const platformPermissionConnector = createStructuredSelector({
  isFetchingPermissions: selectIsFetchingPermissions,
  hasUserPlatformPermission: selectPlatformPermission,
});

export const connectWithPatformPermission = compose(
  connect(platformPermissionConnector),
);
