import { JELLO_CHIP_COLOR } from 'src/app/app.jello.constants';
import { ProfileTypes } from 'src/core';
import { JELLO_ICON_NAMES } from 'src/app/app.jello.constants';

export const SIDEBAR_BGM_TITLE = 'titleOfContent.bgmTitle';
export const SIDEBAR_CGM_TITLE = 'titleOfContent.cgmTitle';
export const SIDEBAR_STRIP_TITLE = 'sidebarmenu.stripManagement';

export const getTitles = (routes) => [
  {
    id: 'bgm-heading',
    title: SIDEBAR_BGM_TITLE,
    path: routes.dashboard.bgId,
  },
  {
    id: 'cgm-heading',
    title: SIDEBAR_CGM_TITLE,
    path: routes.dashboard.cgmId,
  },
  {
    id: 'strip-admin',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.adminStrip.stripInfoByPatient,
  },
  {
    id: 'strip-information',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.stripManagement.stripInfoByPatient,
  },
  {
    id: 'strip-prescription',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.stripManagement.prescriptionInfoByPatient,
  },
  {
    id: 'strip-alert',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.stripManagement.alerts,
  },
  {
    id: 'strip-alert',
    title: SIDEBAR_STRIP_TITLE,
    path: routes.patient.delivery,
  },
];
export const PATIENT_DASBOARD_TITLE_CHIP = {
  chipColor: JELLO_CHIP_COLOR.ACCENT_06,
  [ProfileTypes.pickup]: {
    text: 'hcpDashboard.stripMng.pickupCenter',
    icon: JELLO_ICON_NAMES.BUILDING,
  },
  [ProfileTypes.homeDelivery]: {
    text: 'createPatientWithPlatform.accountConfig.options.stripMng.homeDelivery',
    icon: JELLO_ICON_NAMES.HOME,
  },
};
