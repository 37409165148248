import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

export const NewPatientIcon = ({
  height = 17,
  fillColor = colors.white,
  strokeColor = colors.white,
  originalWidth = 15,
  originalHeight = 19,
}) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <defs>
        <linearGradient
          x1="114.628169%"
          y1="42.4198227%"
          x2="33.8590802%"
          y2="56.6872289%"
          id="linearGradient-1"
        >
          <stop stopColor="#389CFF" offset="0%"></stop>
          <stop stopColor="#0066CC" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-1319.000000, -136.000000)"
          fill={fillColor}
          stroke={strokeColor}
        >
          <g transform="translate(1131.000000, 86.000000)">
            <g transform="translate(189.000000, 51.000000)">
              <g>
                <path
                  d="M6.80350706,8.42593722 C4.91695259,8.41324302 3.27511379,7.13260876 2.80350706,5.30590811 C2.31344152,3.46940527 3.10392498,1.53098459 4.73850706,0.560908112 C6.36233167,-0.389461071 8.4248183,-0.111025376 9.73850706,1.23590811 C11.3489966,2.89618929 11.3489966,5.53562694 9.73850706,7.19590811 C8.96698991,7.98627465 7.90799816,8.43007698 6.80350706,8.42593722 Z"
                  id="Shape"
                  strokeWidth="1.4"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M0.358507058,16.9659081 C0.240709945,16.960382 0.131171744,16.9037873 0.0585070576,16.8109081 C-0.0016630654,16.7099439 -0.0161995524,16.5882009 0.0185070576,16.4759081 C0.816811752,13.3856981 3.6043479,11.2267633 6.79600706,11.2267633 C9.98766621,11.2267633 12.7752024,13.3856981 13.5735071,16.4759081 C13.6099761,16.5906365 13.5875285,16.715969 13.5135071,16.8109081 C13.4397249,16.9050882 13.3280773,16.9618272 13.2085071,16.9659081 L0.358507058,16.9659081 Z"
                  id="Shape-2"
                  strokeWidth="1.4"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
