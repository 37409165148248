import * as React from 'react';
import { actions } from 'react-redux-form';

import { Form, FormControl } from 'src/components';
import { SearchIcon } from 'src/assets/icons';

import {
  ButtonWrapper,
  ControlTextWrapper,
  SearchPatientError,
  SearchPatientWrapper,
  SearchPatientContainer,
} from './patient-search.styles';
import {
  patientNameErrors,
  patientNameValidators,
} from './patient-search.validators';
import { testId } from '@roche/roche-common';

type patientSearchProps = {
  t: (key: string) => string;
  onSearchHcp: (searchTerm: object) => void;
  onSearchAdmin: (searchTerm: object) => void;
  location: {
    pathname: string;
  };
  isAdmin: boolean;
  filterPatientsSet: (object) => void;
  defaultFilters: object;
  isHcpOrGp: boolean;
  mngHome: boolean;
};
type patientSearchState = {
  model: string;
};
export class PatientSearchComponent extends React.Component<
  patientSearchProps,
  patientSearchState
> {
  public formDispatch;
  public state = {
    model: 'hcpDashboard.searchTerm',
  };
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
  }

  public render() {
    const { t } = this.props;
    const patientNameErrorsMessages = {
      isNameLongEnough: t(patientNameErrors.isNameLongEnough),
      charactersNotAllowed: t(patientNameErrors.charactersNotAllowed),
    };
    return (
      <SearchPatientContainer className={this.getClassNames()}>
        <Form
          model="hcpDashboard"
          onSubmit={this.onSubmit()}
          autoComplete="off"
          getDispatch={(dispatch) => (this.formDispatch = dispatch)}
          onChange={this.validate}
        >
          <SearchPatientWrapper>
            <ControlTextWrapper
              autoFocus={true}
              component={FormControl}
              id="patient-name"
              model={this.state.model}
              validators={patientNameValidators}
              validateOn="blur"
              mapProps={{
                valid: ({ fieldValue }) => fieldValue.valid,
                focus: ({ fieldValue }) => fieldValue.focus,
              }}
              placeholder={this.getPlaceHolder()}
            />
            <ButtonWrapper
              label=""
              type="submit"
              icon={<SearchIcon height={12.24} />}
              {...testId('searchPatientWrapper', 'searchButton')}
            />
            <SearchPatientError
              id="patient-name"
              messages={patientNameErrorsMessages}
              model={this.state.model}
            />
          </SearchPatientWrapper>
        </Form>
      </SearchPatientContainer>
    );
  }

  private getPlaceHolder() {
    const { isHcpOrGp, mngHome, t } = this.props;
    const prefix = 'hcpDashboard.searchPlaceHolder';
    return t(`${prefix}${isHcpOrGp && mngHome ? 'Hcp' : 'Admin'}`);
  }

  private getClassNames() {
    const { isHcpOrGp, mngHome, isAdmin, location } = this.props;
    const isHome = location ? location.pathname === '/home' : false;
    const hiddenHcp = isHome && isHcpOrGp && !mngHome;
    const hiddenAdmin = isHome && isAdmin;
    return `admin-${!!isAdmin} hidden-${hiddenHcp || hiddenAdmin}`;
  }

  private onSubmit() {
    const { isHcpOrGp, mngHome, onSearchHcp, onSearchAdmin } = this.props;
    return isHcpOrGp && mngHome ? onSearchHcp : onSearchAdmin;
  }

  private validate() {
    this.formDispatch(actions.setValidity(this.state.model, true));
  }
}
