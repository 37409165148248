import { createAction, createPayloadAction } from 'src/app/store/app.actions';

import { MysugrStatusResponse } from 'src/services/mysugr-status';

import {
  MysugrStatusActionType,
  MysugrStatusStartAction,
  MysugrStatusSuccessAction,
  MysugrStatusErrorAction,
  MysugrInviteActionType,
  MysugrInviteStartAction,
  MysugrInviteSuccessAction,
  MysugrInviteErrorAction,
  MysugrInviteResetAction,
} from './mysugr.types';

export const mysugrStatusStart = (payload: FixMe): MysugrStatusStartAction =>
  createPayloadAction(MysugrStatusActionType.MYSUGR_STATUS_START, payload);

export const mysugrStatusSuccess = (
  payload: FixMe,
): MysugrStatusSuccessAction =>
  createPayloadAction(MysugrStatusActionType.MYSUGR_STATUS_SUCCESS, payload);

export const mysugrStatusError = (
  payload: MysugrStatusResponse,
): MysugrStatusErrorAction =>
  createPayloadAction(MysugrStatusActionType.MYSUGR_STATUS_ERROR, payload);

export const mysugrInviteStart = (payload: FixMe): MysugrInviteStartAction =>
  createPayloadAction(MysugrInviteActionType.MYSUGR_INVITE_START, payload);

export const mysugrInviteSuccess = (
  payload: FixMe,
): MysugrInviteSuccessAction =>
  createPayloadAction(MysugrInviteActionType.MYSUGR_INVITE_SUCCESS, payload);

export const mysugrInviteError = (payload: string): MysugrInviteErrorAction =>
  createPayloadAction(MysugrInviteActionType.MYSUGR_INVITE_ERROR, payload);

export const mysugrInviteReset = (): MysugrInviteResetAction =>
  createAction(MysugrInviteActionType.MYSUGR_INVITE_RESET);
