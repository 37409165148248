import React from 'react';

import { LocalizedText } from 'src/domains/diagnostics/components';
import { LOGBOOK_UNITS_UNIT } from 'src/domains/diagnostics/constants';
import { colors } from 'src/core/styles';

import {
  LogbookCellBlock,
  LogbookCellBlocksContainer,
  LogbookTableCell,
} from '..';

const BloodGlucoseCarbohydratesAndBolusCells = ({
  children,
  keyText,
  bloodGlucoseUnit,
  width,
}) => [
  <LogbookTableCell key={`${keyText}-cell`} p={1} width={width}>
    <LogbookCellBlocksContainer>
      {[bloodGlucoseUnit, 'CARBOHYDRATES', 'BOLUS'].map((unit) => (
        <LogbookCellBlock
          key={`${keyText}-cell-${unit}-before`}
          flex="1"
          color={colors.blueMarine}
        >
          <LocalizedText textKey={LOGBOOK_UNITS_UNIT[unit]} />
        </LogbookCellBlock>
      ))}
      {children}
    </LogbookCellBlocksContainer>
  </LogbookTableCell>,
];

const NightBedtimeHeader = ({ bloodGlucoseUnit, keyText }) => [
  <BloodGlucoseCarbohydratesAndBolusCells
    key={`${keyText}-nightbedtime`}
    keyText={keyText}
    bloodGlucoseUnit={bloodGlucoseUnit}
    width={'10.5rem'}
  />,
];
const BreakfastLunchDinnerHeader = ({ bloodGlucoseUnit, keyText }) => [
  <BloodGlucoseCarbohydratesAndBolusCells
    key={`${keyText}-bld`}
    bloodGlucoseUnit={bloodGlucoseUnit}
    width={'17.45rem'}
  >
    {[bloodGlucoseUnit, 'BOLUS'].map((unit) => (
      <LogbookCellBlock
        key={`${keyText}-cell-${unit}-after`}
        flex="1"
        color={colors.blueMarine}
      >
        <LocalizedText textKey={LOGBOOK_UNITS_UNIT[unit]} />
      </LogbookCellBlock>
    ))}
  </BloodGlucoseCarbohydratesAndBolusCells>,
];

export const LogbookUnitsHeader = ({ bloodGlucoseUnit }) => [
  <NightBedtimeHeader
    key="nightHeader"
    keyText="nightHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
  />,
  <BreakfastLunchDinnerHeader
    key="breakfastHeader"
    keyText="breakfastHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
  />,
  <BreakfastLunchDinnerHeader
    key="lunchHeader"
    keyText="lunchHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
  />,
  <BreakfastLunchDinnerHeader
    key="dinnerHeader"
    keyText="dinnerHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
  />,
  <NightBedtimeHeader
    key="bedtimeHeader"
    keyText="bedtimeHeader"
    bloodGlucoseUnit={bloodGlucoseUnit}
  />,
  <LogbookTableCell key="detailsHeader" p={1} />,
];
