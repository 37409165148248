import * as React from 'react';

import { SvgIcon } from 'src/components/icon';

export const StatusConfirmationIcon = (props: any) => (
  <SvgIcon
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M60 120c33.138 0 60-26.863 60-60S93.138 0 60 0C26.864 0 0 26.863 0 60s26.863 60 60 60Z"
        fill="#E4E9ED"
      />
      <path
        d="M100.911 60c-.92 54.55-80.91 54.54-81.82 0 .92-54.55 80.91-54.54 81.82 0Z"
        fill="#5DBC68"
      />
      <path
        d="M41.27 60.91 55.38 73.2l23.35-26.4"
        stroke="#fff"
        strokeWidth={5.45}
        strokeMiterlimit={10}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h120v120H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);
