import { TFunction } from 'i18next';
import * as React from 'react';
import { withTranslation } from 'src/utils/i18n/with-translation';

import { iconSingleTag } from 'src/widgets/modal/components/manufacturer-info-modal/components/icons-view/components/icon-component.types';

import {
  IconsGrid,
  IconSectionDiv,
  IconsSingleCard,
  IconCardItems,
  FourIconsDiv,
  FourSingleIconCard,
  FourIconCardItems,
  IconRotateImageItem,
  IconImageItem,
  IconNameItem,
  IconTitleRowDiv,
  IconRowNameItem,
} from './icons-components.style';

const columnizeIcons = (iconsArray) => {
  const arrayToPush: object[] = [];
  for (let i = 0; i * 3 < iconsArray.length; i++) {
    arrayToPush.push(iconsArray.slice(i * 3, i * 3 + 3));
  }
  return arrayToPush;
};

export type GeneralTripleProps = {
  generalIconTags: any;
  generalIconsTitle: string;
  t: TFunction;
};

export const GenerateTripleColumnIcons: React.FunctionComponent<
  GeneralTripleProps
> = ({ generalIconTags, generalIconsTitle, t }: GeneralTripleProps) => (
  <React.Fragment>
    <IconSectionDiv>
      <jello-text
        class="jello-text-demo__component"
        size="M"
        weight="semibold"
      >{`${t(generalIconsTitle)}`}</jello-text>
      <IconsGrid>
        {columnizeIcons(generalIconTags).map((tripleTag: any, i) => (
          <IconsSingleCard key={i}>
            {tripleTag.map((tag: iconSingleTag, j) => (
              <IconCardItems key={j}>
                {tag.nametag ===
                'manufacturerInfo.metabolicRate.meanBGmeanSD' ? (
                  <IconRotateImageItem>
                    {tag.objectComponent}
                  </IconRotateImageItem>
                ) : (
                  <IconImageItem>{tag.objectComponent}</IconImageItem>
                )}
                <IconNameItem>
                  <jello-text
                    class="jello-text-demo__component"
                    size="XS"
                    weight="regular"
                    style={{ display: 'flex', flexdirection: 'row' }}
                  >
                    {`${t(tag.nametag)}`}
                  </jello-text>
                </IconNameItem>
              </IconCardItems>
            ))}
          </IconsSingleCard>
        ))}
      </IconsGrid>
    </IconSectionDiv>
  </React.Fragment>
);

export const GenerateTripleColumnIconsComponent = withTranslation(
  GenerateTripleColumnIcons,
);

export const GenerateRowIcons: React.FunctionComponent<GeneralTripleProps> = ({
  generalIconTags,
  generalIconsTitle,
  t,
}: GeneralTripleProps) => (
  <React.Fragment>
    <IconSectionDiv>
      <IconTitleRowDiv>
        <jello-text
          class="jello-text-demo__component"
          size="M"
          weight="semibold"
        >{`${t(generalIconsTitle)}`}</jello-text>
      </IconTitleRowDiv>
      <FourIconsDiv>
        {generalIconTags.map((Component: any, i) => (
          <FourSingleIconCard key={i}>
            <FourIconCardItems>
              <IconImageItem>{Component.icon}</IconImageItem>
              <IconRowNameItem>
                <jello-text
                  class="jello-text-demo__component"
                  size="XS"
                  weight="regular"
                  style={{ display: 'flex', flexdirection: 'row' }}
                >{`${t(Component.name)}`}</jello-text>
              </IconRowNameItem>
            </FourIconCardItems>
          </FourSingleIconCard>
        ))}
      </FourIconsDiv>
    </IconSectionDiv>
  </React.Fragment>
);

export const GenerateRowIconsComponent = withTranslation(GenerateRowIcons);
