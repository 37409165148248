import { colors } from 'src/core/styles';
import { TimeIntervalDescription } from './time-periods.types';

export const PERIODS_COLOR_CODE = {
  [TimeIntervalDescription.BEFORE_BREAKFAST]: colors.intenseOrange,
  [TimeIntervalDescription.AFTER_BREAKFAST]: colors.paleOrange,
  [TimeIntervalDescription.BEFORE_LUNCH]: colors.intenseBlue,
  [TimeIntervalDescription.AFTER_LUNCH]: colors.paleBlue,
  [TimeIntervalDescription.BEFORE_DINNER]: colors.intenseLila,
  [TimeIntervalDescription.AFTER_DINNER]: colors.paleLila,
  [TimeIntervalDescription.BEDTIME]: colors.charcoal,
  [TimeIntervalDescription.NIGHT]: colors.silverMedium,
};

export const PERIODS_DESCRIPTION_KEYS = {
  [TimeIntervalDescription.BEFORE_BREAKFAST]: 'timePeriods.beforeBreakfast',
  [TimeIntervalDescription.AFTER_BREAKFAST]: 'timePeriods.afterBreakfast',
  [TimeIntervalDescription.BEFORE_LUNCH]: 'timePeriods.beforeLunch',
  [TimeIntervalDescription.AFTER_LUNCH]: 'timePeriods.afterLunch',
  [TimeIntervalDescription.BEFORE_DINNER]: 'timePeriods.beforeDinner',
  [TimeIntervalDescription.AFTER_DINNER]: 'timePeriods.afterDinner',
  [TimeIntervalDescription.BEDTIME]: 'timePeriods.bedtime',
  [TimeIntervalDescription.NIGHT]: 'timePeriods.night',
};

export const PERIODS_TIME_KEYS = {
  [TimeIntervalDescription.BEFORE_BREAKFAST]: 'timePeriods.time.breakfast',
  [TimeIntervalDescription.BEFORE_LUNCH]: 'timePeriods.time.lunch',
  [TimeIntervalDescription.BEFORE_DINNER]: 'timePeriods.time.dinner',
  [TimeIntervalDescription.BEDTIME]: 'timePeriods.time.night',
};

export const PERIODS_COUNTLY_KEYS = {
  EVENT_TITLE: 'Time Blocks Edited',
  SEG_DROPDOWN: 'Dropdown Menus Used',
  SEG_GRAPH: 'Graph Used',
  VALUE_TRUE: 'True',
  VALUE_FALSE: 'False',
};
