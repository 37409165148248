import { compose, map, omit, path } from 'ramda';
import { createStructuredSelector, createSelector } from 'reselect';
import { selectEC6UserUnitMeasurement } from 'src/core/user/user.selectors';

const MG_PER_DL = 'mg/dL';

export const selectTargetRanges = path(['targetRanges']);

export const selectRanges = createSelector(
  selectTargetRanges,
  path(['ranges']),
);

export const selectSimpleRanges = createSelector(
  selectRanges,
  path(['nonMealRelated']),
);

export const selectSimpleRangesParsed = createSelector(
  [selectSimpleRanges, selectEC6UserUnitMeasurement],
  (ranges, unit) =>
    compose(
      omit(['inTarget']),
      map((range) =>
        unit === MG_PER_DL ? parseInt(range, 10) : parseFloat(range).toFixed(1),
      ),
    )(ranges),
);

export const selectFetchingTargetRanges = createSelector(
  selectTargetRanges,
  path(['isFetchingTargetRanges']),
);

export const selectSuccessTargetRanges = createSelector(
  selectTargetRanges,
  path(['successTargetRanges']),
);

export const selectErrorTargetRanges = createSelector(
  selectTargetRanges,
  path(['errorTargetRanges']),
);

export const targetRangesConnector = createStructuredSelector({
  targetRanges: selectTargetRanges,
  isFetchingTargetRanges: selectFetchingTargetRanges,
  successTargetRanges: selectSuccessTargetRanges,
  errorTargetRanges: selectErrorTargetRanges,
});
