import * as React from 'react';

import { XIcon } from '../../../../../assets/icons/index';
import {
  Block,
  Button,
  LocalizedText,
  SuccessBadge,
} from '../../../../../components/index';
import { ButtonReset, colors } from '../../../../../core/styles/index';
import { translate } from '../../../../../i18n/index';
import { CommonHeader, ModalBody } from '../../../../modal/index';

export const CreateProfessionalSuccessModal = ({ destroyModal }) => (
  <ModalBody>
    <CommonHeader>
      <strong>
        <LocalizedText fontSize={4} textKey="professional.successModal.title" />
      </strong>
      <ButtonReset
        onClick={() => {
          destroyModal();
        }}
      >
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <Block py={2} px={6}>
      <Block p={4}>
        <Block pb={2} mb={3} display="flex" justifyContent="center">
          <SuccessBadge size={75} fillColor={colors.brandBlue} />
        </Block>
        <Block pb={3} mb={4} display="flex" justifyContent="center">
          <strong>
            <LocalizedText textKey="professional.successModal.description" />
          </strong>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            label={translate('professional.successModal.confirm')}
            onClick={destroyModal}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);
