export const SIDEBAR_MENU = 'sidebarMenu';
export const TOGGLER = 'toggler';
export const MENU_OPTION = 'option';
export const STRIP_MANAGEMENT = 'stripManagement';

/* const { dashboard, patient, stripManagement, indicators, adminStrip } = routes;
 */

export const getItems = (routes) => {
  const { dashboard, patient, stripManagement, indicators, adminStrip } =
    routes;
  return {
    bgm: {
      iconName: 'accu-chek-guide',
      label: 'sidebarmenu.bgm',
      path: dashboard.bgId,
    },
    cgm: {
      iconName: 'cgm-device',
      label: 'sidebarmenu.cgm',
      path: dashboard.cgmId,
    },
    patterns: {
      iconName: 'chart--line--data',
      label: 'sidebarmenu.patterns',
      path: indicators.main,
    },
    stripManagement: {
      iconName: 'active-test-strips',
      label: 'sidebarmenu.stripManagement',
      path: [
        patient.delivery,
        stripManagement.stripInfoByPatient,
        stripManagement.prescriptionInfoByPatient,
        stripManagement.alerts,
        adminStrip.stripInfoByPatient,
      ],
    },
    patientDevices: {
      iconName: 'devices',
      label: 'sidebarmenu.patientDevices',
      path: patient.listDevices,
    },
    editPatient: {
      iconName: 'settings',
      label: 'sidebarmenu.editPatient',
      path: patient.editPatient,
    },
  };
};
