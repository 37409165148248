import React, { Fragment } from 'react';
import { isNil } from 'ramda';

import { BLOOD_GLUCOSE_UNITS } from 'src/domains/patient-dashboards/bg/store/bg.constants';
import { strokeWidth, colors } from 'src/core/styles';
import { RenderIf } from 'src/domains/diagnostics/utils';
import { PointSeries, LineSeries, DisconnectedLineSeries } from 'src/lib';
import {
  TargetRange,
  ThresholdLine,
  GridLines,
  XShape,
  SquareShape,
  TriangleShape,
  CircleShape,
  BasalLine,
} from 'src/domains/diagnostics/components/graph';
import {
  FULL_OPACITY,
  FADED_OPACITY,
  DATA_TYPE,
  tbrTypeContentMap,
  GRAPH_Y_INTERVAL_MG,
  GRAPH_Y_INTERVAL_MMOL,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  getClickableCursorStyle,
  areDatesTheSameDay,
  getOpacity,
} from 'src/domains/diagnostics/scenes/graphs/graph.util';
import { toolTipWidth } from 'src/domains/diagnostics/components/tool-tip';

export const DetailGraph = ({
  height,
  width,
  yDirection,
  targetRange,
  threshold,
  horizontalDayTicks,
  showGridLines,
  graphData,
  onLineClick = () => undefined,
  selectedDate,
  onPointMouseOver,
  onPointMouseOut,
  tbrLines,
  tbrPoints,
  graphYMax,
  collapsed,
  bloodGlucoseUnit,
}) => {
  const GRAPH_Y_INTERVAL =
    bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MMOL_PER_L
      ? GRAPH_Y_INTERVAL_MMOL
      : GRAPH_Y_INTERVAL_MG;
  return (
    <Fragment>
      <RenderIf validate={showGridLines}>
        <GridLines
          width={width}
          height={height}
          horizontalCount={graphYMax / GRAPH_Y_INTERVAL}
          verticalCount={horizontalDayTicks.length}
        />
      </RenderIf>
      <TargetRange
        width={width}
        height={height}
        min={targetRange.min}
        max={targetRange.max}
      />
      <ThresholdLine
        width={width}
        height={height}
        threshold={threshold}
        yDirection={yDirection}
      />
      {graphData.lines.map((line, index) => (
        <Fragment key={index + '1'}>
          <LineSeries
            points={line}
            width={width}
            height={height}
            Line={(a, b) => (
              <line
                key={`${a.x}${a.y}${b.x}${b.y}-1`}
                x1={a.x}
                y1={a.y}
                x2={b.x}
                y2={b.y}
                strokeWidth={
                  areDatesTheSameDay(a.data.date, b.data.date) &&
                  areDatesTheSameDay(a.data.date, selectedDate)
                    ? strokeWidth.four
                    : strokeWidth.one
                }
                stroke={
                  areDatesTheSameDay(a.data.date, b.data.date) &&
                  areDatesTheSameDay(a.data.date, selectedDate)
                    ? colors.turqoise
                    : colors.black
                }
                opacity={getOpacity(selectedDate, a.data.date)}
              />
            )}
          />
        </Fragment>
      ))}
      <LineSeries
        points={graphData.meanBloodGlucosePoints}
        width={width}
        height={height}
        Line={(a, b) => (
          <line
            key={`${a.x}${a.y}${b.x}${b.y}`}
            x1={a.x}
            y1={a.y}
            x2={b.x}
            y2={b.y}
            opacity={isNil(selectedDate) ? FULL_OPACITY : FADED_OPACITY}
            strokeWidth={strokeWidth.two}
            stroke={colors.black}
          />
        )}
      />
      {/* Invisible clickable lines */}
      {graphData.lines.map((line, index) => (
        <LineSeries
          key={index + '2'}
          points={line}
          width={width}
          height={height}
          Line={(a, b) => (
            <line
              key={`${a.x}${a.y}${b.x}${b.y}-2`}
              x1={a.x}
              y1={a.y}
              x2={b.x}
              y2={b.y}
              stroke={colors.white}
              strokeWidth={10}
              opacity={0}
              onClick={onLineClick({
                type: DATA_TYPE.GLUCOSE,
                date: a.data.date,
              })}
              cursor={getClickableCursorStyle(!collapsed)}
            />
          )}
        />
      ))}
      <PointSeries
        points={graphData.glucoseMeasurements}
        width={width}
        height={height}
        Shape={({ shape, x, y, strokeColor, fillColor, data }) => {
          let ShapeComponent;

          if (shape === 'triangle') {
            ShapeComponent = TriangleShape;
          } else if (shape === 'x') {
            ShapeComponent = XShape;
          } else {
            ShapeComponent = SquareShape;
          }
          return (
            <ShapeComponent
              width={width}
              key={x * y}
              x={x}
              y={y}
              fillColor={
                isNil(selectedDate) || fillColor === colors.white
                  ? fillColor
                  : colors.lighterTurqoise
              }
              strokeColor={isNil(selectedDate) ? strokeColor : colors.turqoise}
              opacity={
                isNil(selectedDate) ||
                areDatesTheSameDay(data.date, selectedDate)
                  ? FULL_OPACITY
                  : FADED_OPACITY
              }
              onClick={onLineClick({
                type: DATA_TYPE.GLUCOSE,
                date: data.date,
              })}
              onMouseMove={(event) =>
                onPointMouseOver(
                  event,
                  { type: DATA_TYPE.GLUCOSE, ...data },
                  toolTipWidth,
                )
              }
              onMouseOut={onPointMouseOut}
              collapsed={collapsed}
              cursor={getClickableCursorStyle(!collapsed)}
            />
          );
        }}
      />
      <PointSeries
        points={graphData.meanBloodGlucosePoints}
        width={width}
        height={height}
        Shape={({ shape, x, y, strokeColor, fillColor, data, notEnoughData }) =>
          !notEnoughData && (
            <CircleShape
              width={width}
              key={`${x}${y}`}
              x={x}
              y={y}
              fillColor={fillColor}
              opacity={isNil(selectedDate) ? FULL_OPACITY : FADED_OPACITY}
              onMouseMove={(event) =>
                onPointMouseOver(
                  event,
                  { type: DATA_TYPE.MEAN_GLUCOSE, ...data },
                  toolTipWidth,
                )
              }
              onMouseOut={onPointMouseOut}
              collapsed={collapsed}
            />
          )
        }
      />
      <DisconnectedLineSeries
        width={width}
        height={height}
        points={tbrLines}
        Line={(a, b, index) => (
          <BasalLine
            ax={a.x}
            bx={b.x}
            ay={a.y}
            by={b.y}
            key={`${a.x}-${b.x}-${a.y}-${b.y}-${index}`}
            showA={a.show}
            showB={b.show}
            opacity={getOpacity(selectedDate, a.date)}
            onMouseMove={(event) =>
              onPointMouseOver(
                event,
                {
                  type: DATA_TYPE.BASAL,
                  ...a,
                },
                toolTipWidth,
              )
            }
            onMouseOut={onPointMouseOut}
            onClick={onLineClick({
              type: DATA_TYPE.INSULIN,
              date: a.date,
            })}
            cursor={getClickableCursorStyle(!collapsed)}
          />
        )}
      />
      <PointSeries
        width={width}
        height={height}
        points={tbrPoints}
        Shape={({ x, y, date, type }, index) => {
          const { Icon, label } = tbrTypeContentMap[type];
          return (
            <Icon
              key={`tbr event ${index} - ${x}${y}`}
              x={x}
              y={y}
              width={width}
              opacity={getOpacity(selectedDate, date)}
              onClick={onLineClick({
                type: DATA_TYPE.INSULIN,
                date: date,
              })}
              onMouseMove={(event) =>
                onPointMouseOver(
                  event,
                  {
                    type: DATA_TYPE.TBR_EVENT,
                    date: date,
                    tbrLabel: label,
                  },
                  toolTipWidth,
                )
              }
              onMouseOut={onPointMouseOut}
              cursor={getClickableCursorStyle(!collapsed)}
            />
          );
        }}
      />
    </Fragment>
  );
};
