import { DateTime } from 'luxon';
import { compose, uniq } from 'ramda';

import { TARGET_RANGES_GET_REQUEST } from 'src/widgets/target-ranges/store/target-ranges.actions';
import {
  GET_CGM_SUMMARY,
  GET_CGM_CLINCAL_DATA,
  CGM_SET_DATES,
  CGM_CLEAR_DATES,
  CGM_SET_SELECTED_DATE,
} from './cgm.actions';

import {
  subtractDays,
  toISO,
  toStartOfDay,
  toEndOfDay,
  convertJSDateGMT,
  convertStringToJSDate,
  isDateStringValid,
} from 'src/utils/date';

export type CGMSummary = {
  startDate: string | null;
  endDate: string | null;
  firstMeasurementDate: string | null;
  lastMeasurementDate: string | null;
  range?: any;
};

export type CGMMeasurementPerDay = {
  intervalFirstMeasurementDay: string;
  intervalLastMeasurementDay: string;
  intervalTotalMeasurementDay: number;
  interval: number;
};

export type CGMClinicalData = {
  totalMeasurements: number;
  glucoseSummation: number;
  intervals: CGMInterval;
  days: {
    [key: string]: CGMDataDay[];
  };
  daysStats: {
    [key: string]: CGMStatDataDay;
  };
};

export type CGMInterval = {
  interval: number;
  unit: string;
  intervalTotalMeasurements: number;
  intervalFirstMeasurement: string;
  intervalLastMeasurement: string;
  intervalDays: CGMIntervalDay;
}[];

export type CGMIntervalDay = { [key: string]: CGMMeasurementPerDay };

export type CGMDataDay = {
  hour: string;
  totalvalues: number;
  values: CGMMeasurement[];
};

export type CGMStatDataDay = {
  summationDayValues: number;
  totalDayMeasurement: number;
};

export type CGMMeasurement = {
  glucoseValue: number;
  timestamp: string;
  macro: number;
  date?: DateTime;
};

type CGMState = {
  summary: CGMSummary;
  measurements: CGMClinicalData;
  pristine: boolean;
  errors: string[];
  cgmClinicalDataFinish: boolean;
  selectedDate?: string;
};

export const INITIAL_STATE: CGMState = {
  summary: {
    startDate: '',
    endDate: '',
    firstMeasurementDate: '',
    lastMeasurementDate: '',
    range: 14,
  },
  measurements: {
    intervals: [],
    totalMeasurements: 0,
    glucoseSummation: 0,
    days: {},
    daysStats: {},
  },
  pristine: true,
  cgmClinicalDataFinish: false,
  errors: [],
};

const returnNullIfInvalidDateString = (dateString) =>
  isDateStringValid(dateString) ? convertStringToJSDate(dateString) : null;

const validateAndTransformDates = (dates) =>
  dates.map(returnNullIfInvalidDateString);

export const continuousMonitoringReducer = (
  state = INITIAL_STATE,
  action,
): CGMState => {
  switch (action.type) {
    case GET_CGM_SUMMARY.START:
      return INITIAL_STATE;

    case GET_CGM_SUMMARY.SUCCESS:
      const { firstMeasurementDate, lastMeasurementDate } = action.payload;

      const [lastMeasurement] = validateAndTransformDates([
        lastMeasurementDate,
      ]);

      const startDate = compose(
        toISO,
        toStartOfDay,
        subtractDays(13),
        convertJSDateGMT,
      )(lastMeasurement || new Date());
      const endDate = compose(
        toISO,
        toEndOfDay,
        convertJSDateGMT,
      )(lastMeasurement || new Date());

      return {
        ...state,
        summary: {
          startDate,
          endDate,
          firstMeasurementDate,
          lastMeasurementDate,
          range: null,
        },
        pristine: false,
      };

    case CGM_SET_DATES:
      const { summary } = state;
      const startDateParsed = action.payload.startDate.toISOString();
      const endDateParsed = action.payload.endDate.toISOString();
      const range = action.payload.range;
      return {
        ...state,
        summary: {
          ...summary,
          startDate: startDateParsed,
          endDate: endDateParsed,
          range,
        },
        cgmClinicalDataFinish: false,
      };
    case CGM_CLEAR_DATES: {
      return INITIAL_STATE;
    }

    case GET_CGM_CLINCAL_DATA.START:
      return {
        ...state,
        measurements: INITIAL_STATE.measurements,
      };

    case CGM_SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload.selectedDate,
      };

    case GET_CGM_CLINCAL_DATA.SUCCESS:
      const {
        intervals,
        totalMeasurements,
        glucoseSummation,
        days,
        daysStats,
      } = action.payload;
      return {
        ...state,
        pristine: false,
        measurements: {
          intervals,
          totalMeasurements,
          glucoseSummation,
          days,
          daysStats,
        },
        cgmClinicalDataFinish: true,
      };

    case GET_CGM_SUMMARY.ERROR:
    case TARGET_RANGES_GET_REQUEST.ERROR:
      const errors = uniq([action.type, ...state.errors]);
      return {
        ...INITIAL_STATE,
        errors,
      };

    default:
      return state;
  }
};
