import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { mapDispatchers, RenderIf } from 'src/utils';
import { createModal, MODAL_TYPES } from 'src/core/modal';
import { V3MenuIcon } from 'src/assets/icons';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';

import { MenuDropdownPopover } from './menu-dropdown-popover/menu-dropdown-popover.component';
import {
  MenuDropdownContainer,
  MenuDropdownAvatarContainer,
  IconContainer,
} from './menu-dropdown.style';
import { TimeTrackerExportModal } from './components/time-tracker-export-modal/time-tracker-export-modal.component';
import {
  selectEC6UserLanguage,
  selectIs12HourFormat,
} from 'src/core/user/user.selectors';
import {
  exportPatientsSessionStartAction,
  clearPatientExportData,
} from 'src/core/patient-session/patient-session.actions';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { SubmitModalProps } from './components/time-tracker-export-modal/time-tracker-export-modal.types';
import {
  selectIsPatientExportData,
  selectPatientExportData,
} from 'src/core/patient-session/patient-session.selectors';

import { ExportExcelComponent } from './components/excel-export/excel-export.component';
import { startSignoutAction } from 'src/core/login-system-error/login-system-error.action';
import { countlyEventTrigger } from 'src/navigation/countly';
import { EVENTS_KEYS } from 'src/widgets/top-navigation/menu-dropdown/menu-dropdown.countly.constants';
import { selectActiveDevices } from 'src/widgets/top-patient-bar/patient-info/patient-info.container';
import {
  REPORTS_DIALOG_ID,
  SHOW_REPORTS_MODAL_EVENT,
} from 'src/modules/reports-module/reports.constants';
import { selectJelloPermission } from 'src/core/permissions';

type MenuDropdownState = {
  showPopover: boolean;
};

type MenuDropdownProps = {
  routes: FixMe;
  locale: any;
  graphInfo: any;
  onSignOut: () => void;
  showDtcModal: () => void;
  showTimeTrackerExportModal: (locale, t, exportPatientsSessionStart) => void;
  showManufacturerInfoModal: () => void;
  t: any;
  exportPatientsSessionStart: () => any;
  clearPatientExportData: () => any;
  isPatientExportData: any;
  patientExportData: any;
  is12HourFormat: boolean;
  isJelloActivated: boolean;
};

export class MenuDropdownComponent extends React.Component<
  MenuDropdownProps,
  MenuDropdownState
> {
  private wrapperRef: HTMLElement;
  private ref = React.createRef<HTMLElement>();

  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  public componentDidMount() {
    if (this.ref.current) {
      this.wrapperRef = this.ref.current;
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const {
      onSignOut,
      routes,
      locale,
      showDtcModal,
      showTimeTrackerExportModal,
      showManufacturerInfoModal,
      t,
      exportPatientsSessionStart,
      isPatientExportData,
      patientExportData,
      clearPatientExportData,
      is12HourFormat,
      isJelloActivated,
    } = this.props;
    const { showPopover } = this.state;
    if (isPatientExportData) {
      setTimeout(() => clearPatientExportData(), 1000);
    }
    return (
      <MenuDropdownContainer ref={this.ref}>
        <MenuDropdownAvatarContainer onClick={this.toggleModalDisplay}>
          <IconContainer>
            <V3MenuIcon />
          </IconContainer>
        </MenuDropdownAvatarContainer>
        <MenuDropdownPopover
          onSignOut={onSignOut}
          showDtcModal={showDtcModal}
          showManufacturerInfoModal={showManufacturerInfoModal}
          showTimeTrackerExportModal={() =>
            showTimeTrackerExportModal(locale, t, exportPatientsSessionStart)
          }
          showPrintAndDownloadModal={() => this.handlePrintAndDownloadClick()}
          show={showPopover}
          routes={routes}
          closeMenu={this.closeMenu}
          isJelloActivated={isJelloActivated}
        />
        <RenderIf validate={isPatientExportData}>
          <ExportExcelComponent
            dataExcel={patientExportData}
            is12HourFormat={is12HourFormat}
          />
        </RenderIf>
      </MenuDropdownContainer>
    );
  }

  private closeMenu = () => {
    this.setState({ showPopover: false });
  };

  private handleClickOutside = (event) => {
    const { showPopover } = this.state;
    const { wrapperRef } = this;
    if (wrapperRef && !wrapperRef.contains(event.target) && showPopover) {
      this.closeMenu();
    }
  };

  private toggleModalDisplay = () => {
    this.setState({ showPopover: !this.state.showPopover });
  };

  private handlePrintAndDownloadClick = () => {
    const dialog = document.querySelector(`#${REPORTS_DIALOG_ID}`);
    dialog
      ? dialog.dispatchEvent(new CustomEvent(SHOW_REPORTS_MODAL_EVENT))
      : console.error('Reports dialog not found');
    countlyEventTrigger(EVENTS_KEYS.PRINT_AND_DOWNLOAD_ICON_CLICKED, {});
  };
}

export const MenuDropdown = compose<MenuDropdownProps, {}>(
  withTranslation,
  connect(
    createStructuredSelector({
      routes: getCombinedRoutes,
      locale: selectEC6UserLanguage,
      isPatientExportData: selectIsPatientExportData,
      patientExportData: selectPatientExportData,
      is12HourFormat: selectIs12HourFormat,
      activeDevicesList: selectActiveDevices,
      isJelloActivated: selectJelloPermission,
    }),
    mapDispatchers({
      exportPatientsSessionStart: exportPatientsSessionStartAction,
      onSignOut: startSignoutAction,
      clearPatientExportData,
      showDtcModal: () =>
        createModal({
          key: MODAL_TYPES.DTC,
        }),
      showTimeTrackerExportModal: (locale, t, exportPatientsSessionStart) =>
        createModal({
          key: MODAL_TYPES.CUSTOM,
          data: {
            customComponent: TimeTrackerExportModal,
            locale,
            onSubmitModal: (props: SubmitModalProps) => {
              exportPatientsSessionStart({
                t,
                startDate: props.startDate
                  .startOf('day')
                  .format('YYYY-MM-DD HH.mm.ss'),
                endDate: props.endDate
                  .endOf('day')
                  .format('YYYY-MM-DD HH.mm.ss'),
              });
            },
          },
        }),
      showManufacturerInfoModal: () =>
        createModal({
          key: MODAL_TYPES.MANUFACTURER_INFO,
        }),
    }),
  ),
)(MenuDropdownComponent);
