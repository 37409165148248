import styled from 'styled-components';

import { COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM } from 'src/domains/diagnostics/scenes/graphs/graph.constants';

import {
  MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM,
  COLLAPSED_STD_GRAPH_HEIGHT_IN_REM,
} from '../graph.constants';

export const TrendPlotWrapperDiv = styled.div`
  height: ${(props) =>
    props.collapsed
      ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM
      : props.collapsedGP
      ? COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM
      : 'inherit'};
  min-height: ${(props) =>
    props.collapsed
      ? COLLAPSED_STD_GRAPH_HEIGHT_IN_REM
      : props.collapsedGP
      ? COLLAPSED_STD_GRAPH_HEIGHT_GP_IN_REM
      : MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

TrendPlotWrapperDiv.displayName = 'TrendPlotWrapperDiv';
