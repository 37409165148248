import {
  postJSON,
  createAuthHeader,
  stringifyBody,
} from 'src/utils/service/service.utils';

import {
  CreatePatientWithFhirParams,
  CreatePatientWithFhirResponse,
  CreatePatientWithFhirTransformedResponse,
  CreatePatientWithFhirServiceImplType,
  CreatePatientWithFhirLoaderImplType,
} from './create-patient-with-fhir.types';

import { ENDPOINTS } from 'src/services/service.constants';

export const CreatePatientWithFhirLoaderImpl: CreatePatientWithFhirLoaderImplType =
  (
    { patient, dataSharing, pairingCode }: CreatePatientWithFhirParams,
    accessToken: string,
  ) =>
    postJSON(ENDPOINTS.createPatientWithFhir, {
      headers: {
        Authorization: createAuthHeader(accessToken),
        DataSharing: dataSharing,
        PairingCode: pairingCode,
      },
      body: stringifyBody(patient),
    });

export const CreatePatientWithFhirTransformImpl = ({
  model,
}: CreatePatientWithFhirResponse): CreatePatientWithFhirTransformedResponse => {
  const patientId = model.substring(model.lastIndexOf('/') + 1);
  const patientProfileUrl = `/patients/bg/${patientId}`;
  return patientProfileUrl;
};

export const CreatePatientWithFhirServiceImpl: CreatePatientWithFhirServiceImplType =
  (load, transform) => (query: any, accessToken: string, gigyaToken: string) =>
    load(query, accessToken, gigyaToken).then(transform);
