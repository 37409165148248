import { createStructuredSelector, createSelector } from 'reselect';
import { compose, isEmpty } from 'ramda';

import { selectBloodGlucoseUnit } from 'src/domains/diagnostics/store/selectors';
import { colors } from 'src/core/styles';
import {
  selectIsLoading,
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphThreshold,
  selectGraphDetailTargetRanges,
} from 'src/domains/diagnostics/store/selectors';
import { addEmptyFillerRadialSegmentWhenValuesZero } from 'src/domains/diagnostics/utils/graphs.util';

import {
  returnDivideBy100,
  returnZeroIfEmptyValueOrValue,
} from './distribution-card.selector.util';

const mapGraphValuesToDistribution = ({
  abovePercentage,
  aboveAndHyperPercentage,
  aboveNoHyperPercentage,
  withinPercentage,
  belowPercentage,
  hypoglycaemiaPercentage,
}) => {
  let above = returnZeroIfEmptyValueOrValue(abovePercentage);
  let abovehigh = returnZeroIfEmptyValueOrValue(aboveNoHyperPercentage);
  const aboveveryhigh = returnZeroIfEmptyValueOrValue(aboveAndHyperPercentage);
  const within = returnZeroIfEmptyValueOrValue(withinPercentage);
  const below = returnZeroIfEmptyValueOrValue(belowPercentage);
  const hypoglycaemia = returnZeroIfEmptyValueOrValue(hypoglycaemiaPercentage);

  const exceedsTotal =
    100 - abovehigh - aboveveryhigh - within - below - hypoglycaemia;
  if (exceedsTotal !== 0 && exceedsTotal !== 100) {
    if (above + exceedsTotal > 0) {
      above += exceedsTotal;
    }
    if (abovehigh + exceedsTotal > 0) {
      abovehigh += exceedsTotal;
    }
  }
  return {
    above: above,
    abovehigh: abovehigh,
    aboveveryhigh: aboveveryhigh,
    within: within,
    below: below,
    hypoglycaemia: hypoglycaemia,
  };
};

const mapDistributionToDistributionSegments = ({
  above,
  within,
  below,
  hypoglycaemia,
}) => [
  {
    name: 'above-segment',
    fill: colors.blueLight,
    value: returnDivideBy100(above),
  },
  {
    name: 'within-segment',
    fill: colors.trafficGreen,
    value: returnDivideBy100(within),
  },
  {
    name: 'below-segment',
    fill: colors.trafficOrange,
    value: returnDivideBy100(below),
  },
  {
    name: 'hypoglycaemia-segment',
    fill: colors.red,
    value: returnDivideBy100(hypoglycaemia),
  },
];

const selectDistribution = createSelector(
  selectGraphDetailTargetRanges,
  mapGraphValuesToDistribution,
);

const selectDistributionSegments = createSelector(
  selectDistribution,
  compose(
    addEmptyFillerRadialSegmentWhenValuesZero,
    mapDistributionToDistributionSegments,
  ),
);

const selectHasData = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectIsLoading,
  (measurements, isLoading) => isLoading || !isEmpty(measurements),
);

export const DistributionCardConnector = createStructuredSelector({
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  distribution: selectDistribution,
  distributionSegments: selectDistributionSegments,
  threshold: selectGraphThreshold,
  hasData: selectHasData,
  isLoading: selectIsLoading,
});
