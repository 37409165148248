import { Div } from 'src/components/div';
import styled from 'styled-components';

import { convertPxToRem } from 'src/utils';
import { LocalizedText, TableBody } from 'src/components';
import { TableCell } from 'src/components';

export const TARGET_RANGES_BREAK_POINT = 1300;

export const TargetRangesSection = styled(Div)``;
TargetRangesSection.displayName = 'TargetRangesSection';

export const TargetRangesWrapperHeader = styled(Div)`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 2rem;
  width: 100%;
  border-bottom: solid 1px;
  border-bottom-color: var(--jello-color-brand-05);
  color: var(--jello-color-brand-03);
  padding-bottom: 18px;
`;
TargetRangesWrapperHeader.displayName = 'TargetRangesWrapperHeader';

export const TargetRangesTopWrapper = styled(Div)``;
TargetRangesTopWrapper.displayName = 'TargetRangesTopWrapper';

export const TargetContainerTitle = styled(LocalizedText)`
  display: block;
  font-size: ${convertPxToRem(16)};
  text-transform: uppercase;
  padding-bottom: ${convertPxToRem(24)};
  max-width: 94%;
`;
TargetContainerTitle.displayName = 'TargetContainerTitle';

export const TargetContainerDesc = styled(LocalizedText)`
  display: block;
  font-size: 14px;
  max-width: 94%;
  margin-bottom: 7px;
`;
TargetContainerDesc.displayName = 'TargetContainerDesc';

export const TargetContainerDescWrapper = styled(Div)`
  margin: 0;
  min-height: 50px;
  @media all and (max-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
    min-height: inherit;
  }
`;
TargetContainerDescWrapper.displayName = 'TargetContainerDescWrapper';

export const RangeWrapper = styled(Div)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
RangeWrapper.displayName = 'RangeWrapper';

export const TBodyTargetRanges = styled(TableBody)``;
TBodyTargetRanges.displayName = 'TBodyTargetRanges';

export const TRangesWrapper = styled(Div)`
  &:first-of-type {
    @media all and (max-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
      width: 100%;
    }
    width: 70%;
  }
  &:last-of-type {
    @media all and (max-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
      margin-top: ${convertPxToRem(24)};
      padding-top: ${convertPxToRem(24)};
      border-top: ${({ theme }) => '1px solid ' + theme.colors.grayLight};
      padding-left: 0;
    }
    padding-left: 1.5rem;
  }
`;
TRangesWrapper.displayName = 'TRangesWrapper';

export const SimpleTargetsContainer = styled(TableCell)`
  @media all and (max-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
    border-left: none;
    padding-left: 0;
  }
  border-left: ${({ theme }) => '1px solid ' + theme.colors.grayLight};
  padding-left: 1.5rem;
`;
SimpleTargetsContainer.displayName = 'SimpleTargetsContainer';

export const TargetsContainer = styled(TableCell)`
  vertical-align: top;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  margin-bottom: ${convertPxToRem(24)};
  justify-content: space-around;
  @media all and (max-width: ${convertPxToRem(TARGET_RANGES_BREAK_POINT)}) {
    flex-direction: column;
  }
`;
TargetsContainer.displayName = 'TargetsContainer';

export const FormContainer = styled(Div)`
  width: 100%;
  min-width: ${convertPxToRem(500)};
  margin-top: ${convertPxToRem(45)};
`;
FormContainer.displayName = 'FormContainer';

export const ButtonsContainer = styled(FormContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  & > button {
    margin-left: ${({ theme }) => theme.spacing.four};
  }
`;
ButtonsContainer.displayName = 'ButtonsContainer';
