import * as React from 'react';
import { actions } from 'react-redux-form';
import { clone } from 'ramda';
import { LocalizedText } from 'src/components';
import { ControlSelect } from 'src/components/forms';
import {
  PERIODS_COLOR_CODE,
  PERIODS_TIME_KEYS,
} from './../../time-periods.constants';
import { ComboDropdown } from './combo';
import { convertTimeToFormat } from 'src/domains/diagnostics/utils/time-format';
import { TimeInterval } from './../../time-periods.types';
import {
  BetweenItemsDiv,
  ItemContainerGrey,
  Item,
  ItemAfterDiv,
  ItemsTitleDiv,
  ItemWrapper,
  PeriodTitle,
  PeriodsSummaryContainer,
  RoundSpan,
  SummaryPeriodsDivider,
  SummaryWrapper,
  LocalFormWrapper,
  ItemContainerGreyFlex,
} from './periods-summary.style';

import {
  convertSToTime,
  convertTimeToS,
  THIRTY_MINUTES_SECONDS,
  TOTAL_DAY_SECONDS,
} from '../periods-bar/periods-bar.utils';

type TimePeriodsSummaryProps = {
  timePeriods: TimeInterval[];
  setDirty: () => void;
  onUpdateValidTimePeriods: (periods: TimeInterval[]) => void;
  t: any;
  is12hourTimeFormat: boolean;
  setCountlyStatus: (status: {
    dropDownUpdated?: boolean;
    graphUpdated?: boolean;
  }) => void;
};

type TimePeriodsState = {
  timePeriods: TimeInterval[];
  initialState: any;
  elementsLists: any;
  dirty: boolean;
};

export class TimePeriodsSummary extends React.Component<
  TimePeriodsSummaryProps,
  TimePeriodsState
> {
  public formDispatch;
  private validPeriods: TimeInterval[];

  constructor(props) {
    super(props);
    this.formDispatch = () => undefined;
    const { timePeriods } = props;
    this.validPeriods = clone(timePeriods);
    this.state = {
      timePeriods,
      initialState: [],
      elementsLists: [],
      dirty: false,
    };
  }

  public componentWillReceiveProps(newProps) {
    if (
      !!this.formDispatch &&
      newProps.timePeriods !== this.props.timePeriods
    ) {
      const newState = this.generateModel(newProps.timePeriods);
      const { is12hourTimeFormat } = this.props;
      this.formDispatch(actions.load('timePeriods', newState));
      this.validPeriods = clone(newProps.timePeriods);
      const elementsLists = this.generateSelects(is12hourTimeFormat);
      this.setState({ ...this.state, dirty: false, elementsLists });
    }
  }

  public componentDidMount() {
    const initialState = this.generateModel(this.validPeriods);
    const { is12hourTimeFormat } = this.props;
    const elementsLists = this.generateSelects(is12hourTimeFormat);

    this.formDispatch(actions.load('timePeriods', initialState));
    this.setState({ ...this.state, initialState, elementsLists });
  }

  public render() {
    const { timePeriods, initialState, elementsLists } = this.state;
    const { is12hourTimeFormat } = this.props;
    return (
      <PeriodsSummaryContainer>
        <LocalFormWrapper
          model="timePeriods"
          initialState={initialState}
          getDispatch={(dispatch) => (this.formDispatch = dispatch)}
          onChange={this.validate}
          onUpdate={(form) => this.notifyErrors(form)}
        >
          {this.validPeriods.map((interval, i) => {
            const { description, endTime } = interval;
            return i % 2 === 0 ? (
              <React.Fragment key={'time-blocks-summary-' + i}>
                <SummaryWrapper loop={i} pb={30}>
                  <ItemWrapper>
                    <PeriodTitle>
                      <LocalizedText textKey={PERIODS_TIME_KEYS[description]} />
                    </PeriodTitle>
                    <ItemsTitleDiv>
                      <RoundSpan bg={PERIODS_COLOR_CODE[description]} />
                      <LocalizedText textKey="timePeriods.before" />
                    </ItemsTitleDiv>
                    <Item>
                      <ItemContainerGreyFlex>
                        <ControlSelect
                          component={ComboDropdown}
                          id={'startBefore-' + i}
                          model={`.${description.toLowerCase()}.startTime`}
                          options={elementsLists[description.toLowerCase()]}
                          onChange={(e) => {
                            this.setDirty(e);
                            this.countlyStatus();
                          }}
                        />
                      </ItemContainerGreyFlex>
                      <BetweenItemsDiv>
                        <LocalizedText textKey="timePeriods.to" />
                      </BetweenItemsDiv>
                      <ItemContainerGrey>
                        {convertTimeToFormat(endTime, is12hourTimeFormat)}
                      </ItemContainerGrey>
                    </Item>
                    <ItemAfterDiv>
                      <ItemsTitleDiv>
                        <RoundSpan
                          bg={
                            PERIODS_COLOR_CODE[timePeriods[i + 1].description]
                          }
                        />
                        <LocalizedText
                          textKey={
                            i === 6 ? 'timePeriods.rest' : 'timePeriods.after'
                          }
                        />
                      </ItemsTitleDiv>
                      <Item>
                        <ItemContainerGreyFlex>
                          <ControlSelect
                            component={ComboDropdown}
                            id={'startAfter-' + (i + 1)}
                            model={`.${timePeriods[
                              i + 1
                            ].description.toLowerCase()}.startTime`}
                            options={
                              elementsLists[
                                timePeriods[i + 1].description.toLowerCase()
                              ]
                            }
                            onChange={(e) => {
                              this.setDirty(e);
                              this.countlyStatus();
                            }}
                          />
                        </ItemContainerGreyFlex>
                        <BetweenItemsDiv>
                          <LocalizedText textKey="timePeriods.to" />
                        </BetweenItemsDiv>
                        <ItemContainerGrey>
                          {convertTimeToFormat(
                            this.validPeriods[i + 1].endTime,
                            is12hourTimeFormat,
                          )}
                        </ItemContainerGrey>
                      </Item>
                    </ItemAfterDiv>
                  </ItemWrapper>
                </SummaryWrapper>
                <SummaryPeriodsDivider className="periods-divider" />
              </React.Fragment>
            ) : (
              false
            );
          })}
        </LocalFormWrapper>
      </PeriodsSummaryContainer>
    );
  }

  private generateModel = (periods) => {
    const initialState = {};

    periods.forEach((element) => {
      const key = element.description.toLowerCase();
      initialState[key] = {};
      initialState[key].startTime = element.startTime;
      initialState[key].endTime = element.endTime;
    });

    return initialState;
  };

  private countlyStatus() {
    this.props.setCountlyStatus({
      dropDownUpdated: true,
    });
  }

  private generateSelects = (is12hourTimeFormat) => {
    const elementsLists = {};
    const timePeriods = clone(this.validPeriods);

    const firstStartTime = timePeriods[0].startTime;
    const lastStartTime = timePeriods[timePeriods.length - 1].startTime;
    this.validPeriods.forEach((element, index) => {
      const key = element.description.toLowerCase();
      // calculate the lists for the selects components
      elementsLists[key] = [];

      let nextLimitSeconds;
      let actualValue;
      if (index === 0) {
        const nextStartTime = timePeriods[index + 1].startTime;
        nextLimitSeconds =
          convertTimeToS(nextStartTime) - THIRTY_MINUTES_SECONDS;
        actualValue = convertTimeToS(lastStartTime) + THIRTY_MINUTES_SECONDS;
        nextLimitSeconds =
          (nextLimitSeconds < 0 ||
            nextLimitSeconds < convertTimeToS(firstStartTime)) &&
          actualValue > nextLimitSeconds
            ? nextLimitSeconds + TOTAL_DAY_SECONDS
            : nextLimitSeconds;
      } else if (index > 0 && index < timePeriods.length - 1) {
        const previousStartTime = timePeriods[index - 1].startTime;
        const nextStartTime = timePeriods[index + 1].startTime;
        nextLimitSeconds =
          convertTimeToS(nextStartTime) - THIRTY_MINUTES_SECONDS;
        actualValue =
          convertTimeToS(previousStartTime) + THIRTY_MINUTES_SECONDS;
        nextLimitSeconds =
          (nextLimitSeconds < 0 ||
            nextLimitSeconds < convertTimeToS(firstStartTime)) &&
          actualValue > nextLimitSeconds
            ? nextLimitSeconds + TOTAL_DAY_SECONDS
            : nextLimitSeconds;
      } else {
        const previousStartTime = timePeriods[index - 1].startTime;
        const nextStartTime = timePeriods[0].startTime;
        nextLimitSeconds =
          convertTimeToS(nextStartTime) - THIRTY_MINUTES_SECONDS;
        actualValue =
          convertTimeToS(previousStartTime) + THIRTY_MINUTES_SECONDS;
        nextLimitSeconds =
          (nextLimitSeconds < 0 ||
            nextLimitSeconds < convertTimeToS(firstStartTime)) &&
          actualValue > nextLimitSeconds
            ? nextLimitSeconds + TOTAL_DAY_SECONDS
            : nextLimitSeconds;
      }
      while (actualValue <= nextLimitSeconds) {
        elementsLists[key].push({
          label: convertTimeToFormat(
            convertSToTime(actualValue),
            is12hourTimeFormat,
          ),
          value: convertSToTime(actualValue),
        });
        actualValue += THIRTY_MINUTES_SECONDS;
      }
    });

    return elementsLists;
  };

  private notifyErrors = (data) => {
    return true;
  };

  private setDirty = (element) => {
    const { setDirty } = this.props;

    this.setState({ ...this.state, dirty: true });
    setDirty();
  };

  private validate = (values) => {
    const { onUpdateValidTimePeriods } = this.props;
    const { dirty } = this.state;
    const periods = clone(this.validPeriods);

    if (dirty) {
      periods.forEach((element, index) => {
        element.startTime = values[element.description.toLowerCase()].startTime;
        const i = index < periods.length - 1 ? index + 1 : 0;
        const nextElement = periods[i];
        const nextStartTimeSeconds = convertTimeToS(
          values[nextElement.description.toLowerCase()].startTime,
        );
        element.endTime = convertSToTime(nextStartTimeSeconds - 60);
      });
      this.validPeriods = periods;
      const newState = this.generateModel(periods);

      this.formDispatch(actions.load('timePeriods', newState));
      onUpdateValidTimePeriods(periods);
    }
  };
}
