import React from 'react';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height?: number,
};

export const SystemTrayIcon = ({ height = 39 }: Props) => {
  const originalWidth = 97;
  const originalHeight = 56;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g stroke="#0066CC">
            <g>
              <path
                d="M23.5170534,0.9 C19.2637119,0.9 17.4735191,1.24570218 15.6215455,2.23614848 C14.0183871,3.09352696 12.7656003,4.3463138 11.9082218,5.94947217 C10.9177755,7.80144574 10.5720733,9.59163854 10.5720733,13.8449801 L10.5720733,41.0642759 C10.5720733,43.1230125 10.7313719,43.947928 11.1869635,44.7998103 C11.5737129,45.5229687 12.1358705,46.0851264 12.8590289,46.4718757 C13.7109112,46.9274674 14.5358267,47.0867659 16.5945634,47.0867659 L80.1261701,47.0867659 C82.1849068,47.0867659 83.0098223,46.9274674 83.8617046,46.4718757 C84.5848629,46.0851264 85.1470206,45.5229687 85.53377,44.7998103 C85.9893616,43.947928 86.1486601,43.1230125 86.1486601,41.0642759 L86.1486601,13.8449801 C86.1486601,9.59163854 85.802958,7.80144574 84.8125117,5.94947217 C83.9551332,4.3463138 82.7023463,3.09352696 81.099188,2.23614848 C79.2472144,1.24570218 77.4570216,0.9 73.2036801,0.9 L23.5170534,0.9 Z"
                strokeWidth="1.35"
              ></path>
              <path
                d="M23.6874172,8.03316791 C21.6286805,8.03316791 20.803765,8.19246644 19.9518827,8.64805809 C19.2287243,9.03480742 18.6665666,9.59696511 18.2798173,10.3201235 C17.8242257,11.1720058 17.6649271,11.9969213 17.6649271,14.0556579 L17.6649271,33.931108 C17.6649271,35.9898446 17.8242257,36.8147601 18.2798173,37.6666424 C18.6665666,38.3898008 19.2287243,38.9519585 19.9518827,39.3387078 C20.803765,39.7942995 21.6286805,39.953598 23.6874172,39.953598 L73.0333163,39.953598 C75.092053,39.953598 75.9169685,39.7942995 76.7688508,39.3387078 C77.4920092,38.9519585 78.0541668,38.3898008 78.4409162,37.6666424 C78.8965078,36.8147601 79.0558064,35.9898446 79.0558064,33.931108 L79.0558064,14.0556579 C79.0558064,11.9969213 78.8965078,11.1720058 78.4409162,10.3201235 C78.0541668,9.59696511 77.4920092,9.03480742 76.7688508,8.64805809 C75.9169685,8.19246644 75.092053,8.03316791 73.0333163,8.03316791 L23.6874172,8.03316791 Z"
                strokeWidth="1.35"
              ></path>
              <path
                d="M4.53928867,47.5898263 C2.52936504,47.5898263 0.9,49.2191913 0.9,51.229115 L0.9,52.1684036 C0.9,53.6595725 2.10883118,54.8684036 3.6,54.8684036 L93.1207335,54.8684036 C94.6119023,54.8684036 95.8207335,53.6595725 95.8207335,52.1684036 L95.8207335,51.229115 C95.8207335,49.2191913 94.1913685,47.5898263 92.1814448,47.5898263 L4.53928867,47.5898263 Z"
                strokeWidth="1.35"
              ></path>
              <path
                d="M42.5607877,49.9056452 L54.8047507,49.9056452 C55.5356829,49.9056452 56.1282205,50.4981828 56.1282205,51.229115 C56.1282205,51.9600472 55.5356829,52.5525848 54.8047507,52.5525848 L42.5607877,52.5525848 C41.8298555,52.5525848 41.2373178,51.9600472 41.2373178,51.229115 C41.2373178,50.4981828 41.8298555,49.9056452 42.5607877,49.9056452 Z"
                strokeWidth="1.35"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
          <g transform="translate(52.000000, 28.000000)">
            <ellipse
              stroke="#0066CC"
              fill="#FFFFFF"
              cx="16.5"
              cy="8"
              rx="8.5"
              ry="8"
            ></ellipse>
            <g transform="translate(0.000000, 5.000000)" fillRule="nonzero">
              <ellipse fill="#7ED321" cx="6" cy="5.5" rx="6" ry="5.5"></ellipse>
              <g transform="translate(5.142857, 2.357143)" fill="#FFFFFF">
                <path d="M0.6409713,-2.58960077e-17 L1.07331441,2.58960077e-17 C1.2961941,-1.50463262e-17 1.37701566,0.0232064028 1.45849714,0.0667831759 C1.53997862,0.110359949 1.60392577,0.174307093 1.64750254,0.255788574 C1.69107931,0.337270056 1.71428571,0.418091615 1.71428571,0.6409713 L1.71428571,3.28760013 C1.71428571,3.51047981 1.69107931,3.59130137 1.64750254,3.67278285 C1.60392577,3.75426434 1.53997862,3.81821148 1.45849714,3.86178825 C1.37701566,3.90536503 1.2961941,3.92857143 1.07331441,3.92857143 L0.6409713,3.92857143 C0.418091615,3.92857143 0.337270056,3.90536503 0.255788574,3.86178825 C0.174307093,3.81821148 0.110359949,3.75426434 0.0667831759,3.67278285 C0.0232064028,3.59130137 1.00308841e-17,3.51047981 -1.72640051e-17,3.28760013 L1.72640051e-17,0.6409713 C-1.00308841e-17,0.418091615 0.0232064028,0.337270056 0.0667831759,0.255788574 C0.110359949,0.174307093 0.174307093,0.110359949 0.255788574,0.0667831759 C0.337270056,0.0232064028 0.418091615,1.50463262e-17 0.6409713,-2.58960077e-17 Z"></path>
                <path d="M0.6409713,4.71428571 L1.07331441,4.71428571 C1.2961941,4.71428571 1.37701566,4.73749212 1.45849714,4.78106889 C1.53997862,4.82464566 1.60392577,4.88859281 1.64750254,4.97007429 C1.69107931,5.05155577 1.71428571,5.13237733 1.71428571,5.35525701 L1.71428571,5.64474299 C1.71428571,5.86762267 1.69107931,5.94844423 1.64750254,6.02992571 C1.60392577,6.11140719 1.53997862,6.17535434 1.45849714,6.21893111 C1.37701566,6.26250788 1.2961941,6.28571429 1.07331441,6.28571429 L0.6409713,6.28571429 C0.418091615,6.28571429 0.337270056,6.26250788 0.255788574,6.21893111 C0.174307093,6.17535434 0.110359949,6.11140719 0.0667831759,6.02992571 C0.0232064028,5.94844423 1.00308841e-17,5.86762267 -1.72640051e-17,5.64474299 L1.72640051e-17,5.35525701 C-1.00308841e-17,5.13237733 0.0232064028,5.05155577 0.0667831759,4.97007429 C0.110359949,4.88859281 0.174307093,4.82464566 0.255788574,4.78106889 C0.337270056,4.73749212 0.418091615,4.71428571 0.6409713,4.71428571 Z"></path>
              </g>
            </g>
          </g>
        </g>
        <g
          transform="translate(65.000000, 33.000000)"
          stroke="#0066CC"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g>
            <polyline
              strokeWidth="0.8"
              points="3.5 8.8817842e-15 3.5 6.64356473 6.84678975 4.30341138"
            ></polyline>
            <polyline
              strokeWidth="0.8"
              points="0 4.32017581 3.5 6.64356473 3.5 8.12111687e-15"
            ></polyline>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
