import { STATE_ACTIONS } from 'src/core/state';

import {
  MysugrInviteActionType,
  MysugrState,
  MysugrStatusActions,
  MysugrStatusActionType,
  MysugrInviteActions,
} from './mysugr.types';

export const initialMySugrState: MysugrState = {
  isFetchingStatus: false,
  isError: false,
  status: null,
  isSendingInvitation: false,
  isSendingInvitationError: false,
  invitationSent: false,
};

export const mysugrReducer = (
  state = initialMySugrState,
  action: MysugrStatusActions | MysugrInviteActions,
): MysugrState => {
  switch (action.type) {
    case MysugrStatusActionType.MYSUGR_STATUS_START:
      return {
        ...state,
        isFetchingStatus: true,
        isError: false,
        status: null,
      };
    case MysugrStatusActionType.MYSUGR_STATUS_SUCCESS:
      return {
        ...state,
        isFetchingStatus: false,
        isError: false,
        status: action.payload,
      };
    case MysugrStatusActionType.MYSUGR_STATUS_ERROR:
      return {
        ...state,
        isFetchingStatus: false,
        isError: true,
        status: null,
      };
    case MysugrInviteActionType.MYSUGR_INVITE_START:
      return {
        ...state,
        isSendingInvitation: true,
        isSendingInvitationError: false,
        invitationSent: false,
      };
    case MysugrInviteActionType.MYSUGR_INVITE_SUCCESS:
      return {
        ...state,
        isSendingInvitation: false,
        isSendingInvitationError: false,
        invitationSent: true,
      };
    case MysugrInviteActionType.MYSUGR_INVITE_ERROR:
      return {
        ...state,
        isSendingInvitation: false,
        isSendingInvitationError: true,
        invitationSent: false,
      };
    case MysugrInviteActionType.MYSUGR_INVITE_RESET:
      return {
        ...state,
        isSendingInvitation: false,
        isSendingInvitationError: false,
        invitationSent: false,
      };
    case STATE_ACTIONS.CLEAR_EDIT_PATIENT:
      return initialMySugrState;
    default:
      return state;
  }
};
