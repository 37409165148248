import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { mapDispatchers } from '../../../../utils';
import { JELLO_DIALOG_EVENTS } from '../../../../components';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { LeaveChangingModalComponent } from '../../../../widgets/modal';
import {
  saveUserUnits,
  selectSuccessUserUnits,
  selectErrorUserUnits,
} from '../../store';
import { CONFIRMATION_COMPONENTS } from '../../../../components/change-navigation-route';

type UnitsConfirmModalProp = {
  id: string;
  header: string;
  title: string;
  content: string;
  okBtnText: string;
  cancelBtnText: string;
  disabled?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const UnitsConfirmModalContainer = compose<UnitsConfirmModalProp, any>(
  connect(
    createStructuredSelector({
      successUserUnits: selectSuccessUserUnits,
      errorUserUnits: selectErrorUserUnits,
    }),
    mapDispatchers({
      saveUserUnitsStart: saveUserUnits.start,
    }),
  ),
  withRouter,
  lifecycle<any, {}>({
    componentWillUpdate() {
      if (this.props.successUserUnits) {
        return this.props.onConfirm();
      } else if (this.props.errorUserUnits) {
        return this.props.onCancel();
      }
    },
    componentDidMount() {
      const modal = document.getElementById(
        CONFIRMATION_COMPONENTS.unitsConfirm.id,
      );
      if (modal) {
        modal.addEventListener(JELLO_DIALOG_EVENTS.primaryBtn, () => {
          const { saveUserUnitsStart, units } = this.props;
          saveUserUnitsStart(units);
        });
        modal.addEventListener(JELLO_DIALOG_EVENTS.secondaryBtn, () => {
          return this.props.onConfirm();
        });
        modal.addEventListener(JELLO_DIALOG_EVENTS.closeBtn, () => {
          return this.props.onCancel();
        });
      }
    },
  }),
  withTranslation,
)(LeaveChangingModalComponent);
