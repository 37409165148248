import * as React from 'react';
import { SvgIcon } from 'src/components/icon';

type PairingCodePropsType = {
  width: number;
  height: number;
};

export const ErrorCrossIcon: React.FC<PairingCodePropsType> = ({
  width,
  height,
}): JSX.Element => {
  return (
    <SvgIcon
      width={width}
      height={height}
      originalWidth={width}
      originalHeight={height}
    >
      <g clipPath="url(#clip0_2867_50020)">
        <path
          d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z"
          fill="#E4E9ED"
        />
        <path
          d="M100.91 60.0005C99.9899 114.551 19.9998 114.541 19.0898 60.0005C20.0098 5.45055 99.9998 5.46054 100.91 60.0005Z"
          fill="#EA0000"
        />
        <path
          d="M99.5802 70.7502C99.6002 70.6502 99.6302 70.5602 99.6502 70.4602L71.3702 44.9102L44.9502 71.3202L72.8302 99.0202C79.5402 96.9902 85.7402 93.3102 90.6002 87.9802C94.5102 83.4702 97.6102 78.0202 99.2902 71.8902C99.3902 71.5202 99.4902 71.1402 99.5802 70.7602V70.7502Z"
          fill="#CC0000"
        />
        <path
          d="M73.1803 46.8008L46.8203 73.2008"
          stroke="white"
          strokeWidth="5.45"
          strokeMiterlimit="10"
        />
        <path
          d="M73.1803 73.2008L46.8203 46.8008"
          stroke="white"
          strokeWidth="5.45"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2867_50020">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
