import { selectAccessToken } from 'src/app/session';

import { PATIENTS_ACTIONS } from './patients.constant';
import { setPatients } from './patients.action';

export const getPatientsListEpic = (patientListService) => (action$, store) =>
  action$.ofType(PATIENTS_ACTIONS.GET_PATIENTS).mergeMap(
    () =>
      patientListService(selectAccessToken(store.getState()))
        .then((patients) => setPatients(patients))
        .catch((err) => err), // dispatch error action when refactored
  );
