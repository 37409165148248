// tslint:disable
// @ts-nocheck
import * as React from 'react';
import { map, find, propEq, propOr } from 'ramda';
import { LocalForm, actions } from 'react-redux-form';

import { MODAL_TYPES } from 'src/core/modal';
import {
  BaseCard,
  Block,
  Button,
  ControlButton,
  GridItem,
  LocalizedText,
} from 'src/components';
import { LoadingMessage } from 'src/components/loading-message';
import { ControlSelect, InputError, ControlText } from 'src/components/forms';
import { RenderIf } from 'src/utils';
import { InputText } from 'src/widgets/forms/components';

import {
  DeliveryConfigurationProps,
  DeliveryConfigurationState,
} from './delivery-configuration.types';
import {
  ConfigurationLabel,
  DeliveryConfigurationSection,
  HeaderContainer,
  HeaderTitle,
  ReasonSelect,
  ButtonsContainer,
  ResponsiveGridContainer,
  DeliveryConfigurationContainer,
} from './delivery-configuration.styles';
import {
  DeliveryConfigurationValidators,
  DeliveryConfigurationErrors,
} from './delivery-configuration.validators';
import {
  AdditionalInfo,
  INFOS,
  HOME_DELIVERY,
} from 'src/domains/diagnostics/components/additional-info';

const {
  isRequired,
  isValidNumber,
  isValidPercentage,
  isValidStock,
  isValidTimeGap,
} = DeliveryConfigurationValidators;

export class DeliveryConfigurationComponent extends React.Component<
  DeliveryConfigurationProps,
  DeliveryConfigurationState,
  any
> {
  public formDispatch;

  private currentConfig;

  // tslint:disable-next-line:no-big-function
  public render() {
    const {
      deliveryConfigurationPrograms,
      goTo,
      t,
      patientId,
      patientProgram,
      isFetchingDeliveryConfiguration,
    } = this.props;

    const translatedDeliveryConfigErrors = map(
      (msg) => t(msg),
      DeliveryConfigurationErrors,
    );

    const initialState = patientProgram;
    return (
      <DeliveryConfigurationSection mb={80}>
        <BaseCard p={44}>
          <DeliveryConfigurationContainer>
            <HeaderContainer>
              <HeaderTitle>
                <LocalizedText textKey="patient.deliveryConfiguration.title" />
                <AdditionalInfo
                  info={INFOS.homeDelivery}
                  active={HOME_DELIVERY.configuration}
                  style={{ float: 'right' }}
                />
              </HeaderTitle>
            </HeaderContainer>
            <RenderIf validate={isFetchingDeliveryConfiguration}>
              <LoadingMessage delay={250} minHeight={400} infinite />
            </RenderIf>
            <RenderIf validate={!isFetchingDeliveryConfiguration}>
              <LocalForm
                model="configuration"
                getDispatch={(dispatch) => (this.formDispatch = dispatch)}
                onSubmit={this.handleSubmit}
                initialState={initialState}
              >
                <Block m={4}>
                  <ResponsiveGridContainer columns={12}>
                    <GridItem span={3}>
                      <ConfigurationLabel
                        id="deliveryProgramLabel"
                        label={t(
                          'patient.deliveryConfiguration.form.deliveryProgram',
                        )}
                      />
                      <ControlSelect
                        component={ReasonSelect}
                        id="deliveryProgram"
                        model=".deliveryProgramId"
                        options={deliveryConfigurationPrograms}
                        validators={{ isRequired }}
                        changeAction={(model, value) =>
                          this.handleProgramChange(value)
                        }
                      />
                    </GridItem>
                    <GridItem span={3}>
                      <ConfigurationLabel
                        id="supplyModelNameLabel"
                        label={t(
                          'patient.deliveryConfiguration.form.supplyModelName',
                        )}
                      />
                      <ControlText
                        component={InputText}
                        model=".supplyModelName"
                        id="supplyModelName"
                        disabled
                        readOnly
                        parser={(val) => (val ? this.getModelUnits() : val)}
                      />
                    </GridItem>
                    <GridItem span={2}>
                      <ConfigurationLabel
                        id="initialStock"
                        label={t(
                          'patient.deliveryConfiguration.form.initialStock',
                        )}
                      />
                      <ControlText
                        component={InputText}
                        model=".initialStock"
                        id="initialStock"
                        type="text"
                        validators={{ isRequired, isValidNumber, isValidStock }}
                        validateOn="blur"
                      />
                      <InputError
                        id="error.initialStock"
                        messages={{
                          isRequired: translatedDeliveryConfigErrors.isRequired,
                          isValidNumber:
                            translatedDeliveryConfigErrors.isValidNumber,
                          isValidStock:
                            translatedDeliveryConfigErrors.isValidStock,
                        }}
                        model={'.initialStock'}
                      />
                    </GridItem>
                    <GridItem span={2}>
                      <ConfigurationLabel
                        id="securityPercentage"
                        label={t(
                          'patient.deliveryConfiguration.form.securityPercentage',
                        )}
                      />
                      <ControlText
                        component={InputText}
                        id="securityPercentage"
                        model=".securityPercentage"
                        type="text"
                        validators={{
                          isRequired,
                          isValidNumber,
                          isValidPercentage,
                        }}
                        validateOn="blur"
                      />
                      <InputError
                        id="error.securityPercentage"
                        messages={{
                          isRequired: translatedDeliveryConfigErrors.isRequired,
                          isValidNumber:
                            translatedDeliveryConfigErrors.isValidNumber,
                          isValidPercentage:
                            translatedDeliveryConfigErrors.isValidPercentage,
                        }}
                        model=".securityPercentage"
                      />
                    </GridItem>
                    <GridItem span={2}>
                      <ConfigurationLabel
                        id="timeGapLabel"
                        label={t('patient.deliveryConfiguration.form.timeGap')}
                      />
                      <ControlText
                        component={InputText}
                        id="timeGap"
                        model=".timeGap"
                        type="text"
                        validators={{
                          isRequired,
                          isValidNumber,
                          isValidTimeGap,
                        }}
                        validateOn="blur"
                      />
                      <InputError
                        id="error.timeGap"
                        messages={{
                          isRequired: translatedDeliveryConfigErrors.isRequired,
                          isValidNumber:
                            translatedDeliveryConfigErrors.isValidNumber,
                          isValidTimeGap:
                            translatedDeliveryConfigErrors.isValidTimeGap,
                        }}
                        model=".timeGap"
                      />
                    </GridItem>
                  </ResponsiveGridContainer>
                  <ButtonsContainer>
                    <Button
                      onClick={() => goTo(`/patients/${patientId}`)}
                      buttonStyle="secondary"
                      label={t('patient.deliveryConfiguration.buttons.cancel')}
                    />
                    <ControlButton
                      model="configuration"
                      type="submit"
                      component={Button}
                      disabled={{ valid: false }}
                      mapProps={{
                        buttonStyle: 'primary',
                        label: t('patient.deliveryConfiguration.buttons.save'),
                      }}
                    />
                  </ButtonsContainer>
                </Block>
              </LocalForm>
            </RenderIf>
          </DeliveryConfigurationContainer>
        </BaseCard>
      </DeliveryConfigurationSection>
    );
  }

  private handleProgramChange(value) {
    const { allPrograms } = this.props;
    const configuration = find(propEq('deliveryProgramId', value))(allPrograms);
    this.currentConfig = configuration;
    this.formDispatch(actions.load('configuration', configuration));
    this.formDispatch(actions.resetValidity('configuration'));
  }

  private handleSubmit = (values) => {
    const { createModal, destroyModal, patientId, onSaveProgram } = this.props;
    onSaveProgram(patientId, values);
    destroyModal();
    createModal({
      key: MODAL_TYPES.LOADING,
    });
  };

  private getModelUnits() {
    const { patientProgram, t } = this.props;
    const configuration = this.currentConfig || patientProgram;
    const units = propOr(0, 'packagingUnits')(configuration);
    const key = units > 1 ? 'prescription.tests' : 'prescription.test';
    return `${configuration.supplyModelName} (${units} ${t(key)})`;
  }
}
