import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { transformServerAlertsToAlerts } from '../alerts.utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const GetAlertsLoaderImpl = ({ patientId }, accessToken) =>
  getJSON(endpointWithParams(ENDPOINTS.alerts, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken)        
      },
    },
  );

export const GetAlertsTransformImpl = transformServerAlertsToAlerts;

export const GetAlertsServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
