import styled from 'styled-components';

import { ControlText, Button, InputError } from 'src/components';
import { createStyledComponent } from 'src/utils/styles';

export const SearchPatientContainer = styled.div`
  &.hidden-true {
    display: none;
  }
`;

export const SearchPatientWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const ControlTextWrapper = createStyledComponent<FixMe, {}>(ControlText)`
  border-radius: 25px 0 0 25px;
  border: 1px solid #d9dde7;
  height: 30px;
  width: 205px;
  color: #506577;
  font-size: 12px;
  padding: 10px 12px 10px 15px;;
  background-color: #f7f8f9;
  border-right: 0;
  + span {
    display: none;
  }
`;

export const ButtonWrapper = styled(Button)`
  background: rgb(0, 102, 204);
  border-radius: 0px 25px 25px 0px;
  height: 30px;
  width: 38.25px;
  max-width: 38.25px;
  min-width: 38.25px;
  padding: 0;
  > span {
    margin-right: 5px;
  }
`;

export const SearchPatientError = styled(InputError)`
  position: absolute;
  top: 2rem;
  left: 1rem;
`;

export const PatientSearchFieldLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.black};
`;

PatientSearchFieldLabel.displayName = 'PatientSearchFieldLabel';
