import { is } from 'ramda';
import { createAuthHeader, getJSON } from 'src/utils/service/service.utils';

import {
  MysugrStatusLoaderImplType,
  MysugrStatusTransformImplType,
  MysugrStatusServiceImplType,
  MysugrStatusResponse,
  MysugrStatusResponseTransformed,
  OpenId,
} from './mysugr-status.types';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const MysugrStatusLoaderImpl: MysugrStatusLoaderImplType = (
  openId: OpenId,
  patientGigyaUid: string,
) => {
  return getJSON(
    endpointWithParams(ENDPOINTS.mySugarStatus, { patientGigyaUid }),
    {
      headers: {
        Authorization: createAuthHeader(openId.accessToken),
      },
    },
  );
};

export const MysugrStatusTransformImpl: MysugrStatusTransformImplType = (
  data: MysugrStatusResponse,
): MysugrStatusResponseTransformed => {
  const moment = require('moment');
  if (!is(String, data)) {
    const lastSync = data.lastSync ? moment(data.lastSync) : null;
    return {
      ...data,
      lastSync,
    };
  }
  return {
    status: data,
    lastSync: null,
  };
};

export const MysugrStatusServiceImpl: MysugrStatusServiceImplType =
  (
    load: MysugrStatusLoaderImplType,
    transform: MysugrStatusTransformImplType,
  ) =>
  (openId, patientGigyaUid) =>
    load(openId, patientGigyaUid).then(transform);
