import styled from 'styled-components';

import { colors } from 'src/core/styles';
import { combineRems, hasValue } from 'src/utils';
import { weight } from 'src/components/fonts/weights';

import { Headline } from 'src/components/fonts/headline/headline.style';

import { Div } from 'src/components/div';

export const SectionHeaderDiv = styled(Div)`
  margin: 0 auto
    ${({ ...props }) =>
      hasValue(props.mb) ? props.mb : props.theme.spacing.four};
  width: 100%;
`;

export const SectionHeaderHeadline = styled(Headline)`
  margin: 0 auto
    ${(props) =>
      hasValue(props.bottomMarginHeadline)
        ? props.bottomMarginHeadline
        : combineRems(props.theme.spacing.two, props.theme.spacing.three)};
  color: ${(props) => props.textColor};
  font-weight: ${(props) => (hasValue(props) ? props.fontWeight : weight.bold)};
`;
SectionHeaderHeadline.defaultProps = {
  color: colors.charcoal,
};
