import * as React from 'react';
import { Prompt } from 'react-router-dom';
import { ConfirmModal } from 'src/widgets/modal';

type RouteLeavingProps = {
  shouldBlockNavigation: (nextLocation: string) => any;
  navigate: (lastLocation: any) => any;
  when: boolean;
};
type RouteLeavingState = {
  inDocument: boolean;
  modalVisible: boolean;
  lastLocation: object;
  confirmedNavigation: boolean;
};

export class RouteLeavingGuard extends React.Component<
  RouteLeavingProps,
  RouteLeavingState
> {
  public state = {
    inDocument: true,
    modalVisible: false,
    lastLocation: {
      pathname: null,
    },
    confirmedNavigation: false,
  };

  public showModal = (location) => {
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });
  };

  public closeModal = (callback) =>
    this.setState(
      {
        modalVisible: false,
      },
      callback,
    );

  public handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation, inDocument } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (
      inDocument &&
      !confirmedNavigation &&
      shouldBlockNavigation(nextLocation)
    ) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  public handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            navigate(lastLocation.pathname);
          },
        );
      }
    });

  public componentDidMount() {
    document.addEventListener('mouseenter', this.setInDocument, true);
    document.addEventListener('mouseleave', this.setOutDocument, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mouseenter', this.setInDocument, true);
    document.removeEventListener('mouseleave', this.setOutDocument, false);
  }

  public setInDocument = () => this.setState({ inDocument: true });
  public setOutDocument = () => this.setState({ inDocument: false });

  public render() {
    const { when } = this.props;
    const { modalVisible } = this.state;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <ConfirmModal
          header={'confirmation.cancel'}
          visible={modalVisible}
          onCancel={this.closeModal}
          onConfirm={this.handleConfirmNavigationClick}
          messages={[
            'confirmation.message1',
            'confirmation.message2',
            'confirmation.message3',
          ]}
        />
      </>
    );
  }
}

export default RouteLeavingGuard;
