import React from 'react';
import { t } from 'i18next';
import { NonConsentsIcon } from 'src/assets/icons';
import styles from './bg-non-consents-view.module.css';

type bgNonConsentsViewComponentType = {
  patientNameSurname: string;
  gpDashboard: boolean;
};

export const BgNonConsentsViewComponent: React.FC<
  bgNonConsentsViewComponentType
> = ({ patientNameSurname, gpDashboard }) => {
  return (
    <div className={gpDashboard ? styles.gpDashboard : ``}>
      <jello-alert
        variant="warning"
        title-text={t('bgNonConsents.alertTitle')}
        block
      >
        {t('bgNonConsents.alertSubtitle', {
          patientNameSurname,
          interpolation: { prefix: '{{', suffix: '}}' },
        })}
      </jello-alert>
      <div className={styles.BgNonConsentsMessage}>
        <NonConsentsIcon width={192} height={192} />
        <jello-text class={styles.nonConsentsTitle} size="XL" weight="bold">
          {t('bgNonConsents.messageTitle')}
        </jello-text>
        <jello-text size="M" weight="regular">
          {t('bgNonConsents.messageSubtitle')}
        </jello-text>
      </div>
    </div>
  );
};
