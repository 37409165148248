import styled from 'styled-components';

import { MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM } from '../graph.constants';

export const StandardWeekTrendWrapperDiv = styled.div`
  flex: 1;
  min-height: ${MIN_EXPANDED_STD_GRAPH_HEIGHT_IN_REM};
`;

StandardWeekTrendWrapperDiv.displayName = 'StandardWeekTrendWrapperDiv';
