import { getConfig } from '@roche/roche-common';

export const SCRIPTS_PROPS = {
  consent: {
    path: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    queryParams: {
      id: 'banner',
      type: 'text/javascript',
      charset: 'UTF-8',
      'data-language': 'en',
      'data-domain-script': getConfig().REACT_APP_CONSENTS_DOMAIN_ID,
    },
  },
};

export const STATUS = {
  IS_READY: 'isReady',
  IS_ERROR: 'isError',
  IS_LOADING: 'isLoading',
  IS_CONSENT: 'isConsent',
  IS_ACCEPTED: 'isAccepted',
};

export const BGM = 'BG';
export const CGM = 'CGM';

export const SHORT_CUT = {
  BG: 'shortcutBG',
  CGM: 'shortcutCGM',
};

export const RESET_PWD_PATH = '/reset-password-professional';

export const HOME = 'home';
export const HOME_CGM = 'homeCgm';
