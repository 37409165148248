import * as React from 'react';

import { ControlError } from '../control-error';

import { InputErrorLabel } from './input-error.style';

export const showFieldError = (field: {
  touched: boolean;
  focus: boolean;
}): boolean => field.touched && !field.focus;

type InputErrorProps = {
  readonly id: string;
  readonly model: string;
  readonly messages: { [key: string]: string };
  readonly show?: () => boolean;
};

export const InputError: React.FunctionComponent<InputErrorProps> = ({
  id,
  model,
  messages,
  show = showFieldError,
  ...props
}) => (
  <InputErrorLabel htmlFor={id}>
    <ControlError model={model} show={show} messages={messages} {...props} />
  </InputErrorLabel>
);
