import * as React from 'react';

import { StyledGraphTabItem } from './graph-tab-item.style';
import { GraphTabItemProps } from './graph-tab-item.types';
import { graphTypeCountly } from './graph-tab-item.utils';

export const GraphTabItem = ({
  isActive,
  eventFunc,
  eventName,
  icon,
  text,
  graph,
}: GraphTabItemProps) => (
  <StyledGraphTabItem
    active={isActive}
    onClick={() => {
      graphTypeCountly(graph, eventName);
      return eventFunc(eventName);
    }}
  >
    {icon}
    {text}
  </StyledGraphTabItem>
);
