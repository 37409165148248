import React, { useState } from 'react';
import styles from './styles.module.css';
import { JelloIcon } from 'src/components';
import { colors } from 'src/core/styles/colors';
import { MenuOptionComponent } from './menu-option';
import { t } from 'i18next';

import { JELLO_ICON_SIZES } from 'src/app/app.jello.constants';
import { routes } from 'src/domains/routes';
import { SIDEBAR_MENU, TOGGLER, STRIP_MANAGEMENT, getItems } from './constants';
import { testId } from '@roche/roche-common';
import { matchPath } from 'react-router-dom';
import { ADMINISTRATIVE } from 'src/core/user/user.constants';

export const SidebarmenuJelloComponent = (props) => {
  const [expanded, setExpanded] = useState(false);
  const {
    disabled,
    hidden,
    patientId,
    location,
    patientProfileType,
    onClearBgDateRange,
    onClearCgmDateRange,
    goTo,
    profile,
    isPatientStrip,
  } = props;

  const { sidebarMenu, displayed, btn, btnExpanded, btnContainer } = styles;

  const ITEMS = getItems(routes);

  const checkSelected = (key, _path) => {
    const selected =
      key === STRIP_MANAGEMENT && profile === ADMINISTRATIVE
        ? !!matchPath(location?.pathname, {
            path: routes.adminStrip.stripInfoByPatient,
            exact: true,
          })
        : key === STRIP_MANAGEMENT
        ? ITEMS[STRIP_MANAGEMENT].path.find((p) => {
            return !!matchPath(location?.pathname, {
              path: p,
              exact: true,
            });
          })
        : !!matchPath(location?.pathname, { path: _path, exact: true });

    return !!selected;
  };

  return (
    <div
      className={`${sidebarMenu} ${expanded ? displayed : ''}`}
      {...testId(SIDEBAR_MENU, '')}
    >
      {Object.entries(ITEMS).map(([key, { iconName, label, path }]) => {
        const _path =
          key === STRIP_MANAGEMENT && profile === ADMINISTRATIVE
            ? `${routes.adminStrip.stripInfoByPatient}`
            : key === STRIP_MANAGEMENT
            ? isPatientStrip
              ? `${routes.stripManagement.stripInfoByPatient}`
              : `${routes.patient.delivery}`
            : `${path[patientProfileType] || path}`;

        return (
          <MenuOptionComponent
            id={key}
            key={key}
            expanded={expanded}
            selected={checkSelected(key, _path)}
            iconName={iconName}
            label={t(label)}
            path={_path.replace(':id', patientId)}
            disabled={disabled()[key]}
            hidden={hidden()[key]}
            onClearBgDateRange={onClearBgDateRange}
            onClearCgmDateRange={onClearCgmDateRange}
            goTo={goTo}
          />
        );
      })}

      <div className={btnContainer}>
        <>
          <hr className={styles.spacer} />
          <jello-tooltip
            delay="300"
            label={t(`sidebarmenu.${expanded ? 'collapse' : 'expand'}`)}
            position="right"
            style={{ display: 'block', float: 'right' }}
          >
            <button
              onClick={() => setExpanded(!expanded)}
              className={`${btn} ${expanded ? btnExpanded : ''}`}
              {...testId(SIDEBAR_MENU, TOGGLER)}
            >
              <JelloIcon
                iconName={`page--${expanded ? 'first' : 'last'}`}
                size={JELLO_ICON_SIZES.S}
                // @ts-ignore
                styles={{ color: colors.brandBlue, padding: '8px' }}
              />
            </button>
          </jello-tooltip>
        </>
      </div>
    </div>
  );
};
