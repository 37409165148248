import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { GraphSvgIcon } from 'src/components/icon';

type InformationIconProps = {
  title?: string;
  height?: number;
  fillColor?: string;
  originalWidth?: number;
  originalHeight?: number;
};

export const InformationIcon = ({
  height = 21,
  title,
  fillColor = colors.white,
  originalWidth = 20,
  originalHeight = 21,
}: InformationIconProps) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <GraphSvgIcon
      title={title}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M1.25 10.5C1.25 15.3325 5.16751 19.25 10 19.25C12.3206 19.25 14.5462 18.3281 16.1872 16.6872C17.8281 15.0462 18.75 12.8206 18.75 10.5C18.75 5.66751 14.8325 1.75 10 1.75C5.16751 1.75 1.25 5.66751 1.25 10.5ZM2.5 10.5C2.5 6.35786 5.85786 3 10 3C14.1421 3 17.5 6.35786 17.5 10.5C17.5 14.6421 14.1421 18 10 18C5.85786 18 2.5 14.6421 2.5 10.5ZM10 4.875C9.48223 4.875 9.0625 5.29473 9.0625 5.8125C9.0625 6.33027 9.48223 6.75 10 6.75C10.5178 6.75 10.9375 6.33027 10.9375 5.8125C10.9375 5.29473 10.5178 4.875 10 4.875ZM10.625 14.25V8.625H8.125V9.875H9.375V14.25H7.5V15.5H12.5V14.25H10.625Z"
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </GraphSvgIcon>
  );
};
