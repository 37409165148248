// tslint:disable
import styled from 'styled-components';
import { colors } from 'src/core';
import fonts from 'src/components-ds/fonts';
import { ModalBottom, ModalHeader } from 'src/components-ds/modal/modal.style';

export const StyledTime = styled.p`
  display: inline-block;
  font-size: 1.5em;
  color: #333333;
  margin: 0;
`;

StyledTime.displayName = 'StyledTime';

export const StyledLabel = styled.label<{ fontSize?: string }>`
  font-size: ${(props) => props.fontSize || '14px'};
  font-family: ${fonts.type.mainFont};
  color: ${(props) => props.color || colors.black};
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
`;

export const AddMoreDetailsSection = styled.div`
  margin-bottom: 1em;
  color: ${colors.mainBlue};
`;

export const MoreDetailsHeading = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 600;
`;

export const MoreDetailsContent = styled.div``;

export const MoreDetailsContentFirstSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 5px;
`;

export const MoreDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;

  &:first-child {
    padding-right: 1em;
  }
`;

//

export const StyledLabeledRadioButton = styled.label`
  cursor: pointer;
  color: ${colors.black};
  white-space: nowrap;
  margin: 10px 24px 10px 10px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.9em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const StyledSelectedDot = styled.div`
  height: 10px;
  width: 10px;
  background: ${colors.mainBlue};
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 50%;
`;

export const StyledRadioButton = styled.input`
  cursor: pointer;
  appearance: none;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid ${colors.mainBlue};

  &:focus {
    outline: none !important;
  }
`;

export const StyledMethodOfCommunicationSection = styled.div``;

export const StyledMethodOfCommunicationOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0 5px;
`;

export const StyledClearSelectionLink = styled.span`
  font-size: 0.8em;
  text-decoration: none;
  float: right;
  cursor: pointer;
`;

//
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;

  &:first-child {
    padding-right: 2em;
  }
`;

//

export const StyledPatientCareActivityGroup = styled.div`
  margin-bottom: 1em;
`;

export const StyledPatientCareActivityTitle = styled.h4`
  font-weight: ${fonts.weight.semiBold};
  margin: auto auto 0.7em;
  color: ${colors.mediumBlue};
`;

export const StyledPatientCareActivityOption = styled.div`
  line-height: 1.1em;
  margin-bottom: 0.8em;
  font-size: 14px;
`;

export const Title = styled.div`
  color: #003366;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 1rem;
`;

interface IContent {
  direction?: string;
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
}
export const Content = styled.div<IContent>`
  width: 100%;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: ${(props) => props.direction || 'row'};
  flex-wrap: nowrap;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-content: ${(props) => props.alignContent || 'stretch'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
`;

interface IRow {
  order?: number;
  width?: string;
  flex?: string;
  marginRight?: string;
}
export const Row = styled.div<IRow>`
  width: ${(props) => props.width || '100%'};
  order: ${(props) => props.order || 0};
  flex: ${(props) => props.flex || 0} 1 auto;
  margin-right: ${(props) => props.marginRight || 0};
  align-self: auto;
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border: 1px solid #e8e8e8;
  margin-top: 1.4rem;
  margin-bottom: 0.8rem;
  border-bottom: none;
`;

export const ItemRadioButton = styled.div`
  margin-right: 1.55rem;
`;

export const SubTitle = styled.div`
  color: #003e7d;
  font-family: Nunito Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 1rem;
`;
export const SectionCheckBoxes = styled.div`
  margin-bottom: 1.5rem;
  line-height: 1.2;
`;
export const ItemCheckbox = styled.div`
  margin-bottom: 1rem;
`;

export const ModalBottomWithShadow = styled(ModalBottom)`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 25px 0 rgba(6, 77, 50, 0.08);
`;

export const ModalHeaderTimeTracker = styled(ModalHeader)`
  font-size: 1rem;
  background-color: #004d99;
  text-transform: uppercase;
`;

export const StyledErrorMessage = styled.div`
  flex: 1 0;
  font-size: 0.9em;
  margin-left: auto;
  color: #e70a43;
`;

StyledErrorMessage.displayName = 'StyledErrorMessage';
