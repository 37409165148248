import { path } from 'ramda';
import { EC7ThresholdsTransformedGraphs } from 'src/domains/diagnostics/scenes/graphs/logbook.core.types';
import {
  getTimeBlock,
  withoutTargetRange,
} from 'src/domains/diagnostics/scenes/graphs/logbook.core.utils';
import {
  convertJSDateGMT,
  toDayOfWeekNumFormat,
  toFormat,
  toStartOfDay,
} from 'src/utils';

import {
  getBolusType1Value,
  getBolusType2Value,
  getBolusType3Value,
  getBolusTypeIcon,
  getGlucoseIcons,
  getPumpIcon,
  getTextAfterPumpIcon,
  getTextBeforePumpIcon,
} from './logbook-diary.util';

import { LogbookDiaryRowDataTransformerFn } from './logbook-diary.types';

export const logbookDiaryRowDataTransformer: LogbookDiaryRowDataTransformerFn =
  (timeIntervals, allThresholds, bloodGlucoseUnit) => (acc, measurement) => {
    const {
      value: glucose,
      date,
      beforeMeal,
      afterMeal,
      manuallyEntered,
    } = measurement;

    const aboveTargetRange = withoutTargetRange(
      'ABOVE',
      glucose,
      date,
      allThresholds,
      timeIntervals,
      beforeMeal,
      afterMeal,
    );
    const belowTargetRange = withoutTargetRange(
      'BELOW',
      glucose,
      date,
      allThresholds,
      timeIntervals,
      beforeMeal,
      afterMeal,
    );

    const timeBlock = getTimeBlock(date, timeIntervals);
    const timeBlockPrefix: keyof EC7ThresholdsTransformedGraphs =
      // prettier-ignore
      timeBlock.includes('BEFORE')
      ? 'pre'
      : timeBlock.includes('AFTER')
        ? 'post'
        : 'noct';
    const hypoThreshold = path([timeBlockPrefix, 'hypo'], allThresholds);

    return [
      ...acc,
      {
        originalDate: measurement.date,
        date: toFormat('cccc, d MMM yyyy')(convertJSDateGMT(measurement.date)),
        dateLine1: toFormat('cccc,')(convertJSDateGMT(measurement.date)),
        dateLine2: toFormat('d LLL yyyy')(convertJSDateGMT(measurement.date)),
        dateStringISO: toStartOfDay(
          convertJSDateGMT(measurement.date),
        ).toString(),
        time: toFormat('HH:mm')(
          convertJSDateGMT(measurement.date),
        ).toLowerCase(),
        dayOfWeek: toDayOfWeekNumFormat(convertJSDateGMT(measurement.date)),
        glucoseValue: measurement.value,
        manuallyEntered,
        aboveTargetRange,
        belowTargetRange,
        hypoSymptoms: measurement.hypoSymptoms,
        glucoseIcons: getGlucoseIcons(measurement, hypoThreshold),
        basalCbrf: measurement.basalCbrf,
        basalRateProfile: measurement.basalRateProfile,
        basalRemark: measurement.basalRemark,
        basalTbrdec: measurement.basalTbrdec,
        basalTbrinc: measurement.basalTbrinc,
        pumpIcon: getPumpIcon(measurement, acc[acc.length - 1]),
        pumpTextBeforeIcon: getTextBeforePumpIcon(measurement),
        pumpTextAfterIcon: getTextAfterPumpIcon(measurement),
        bolusType1Value: getBolusType1Value(measurement),
        bolusType2Value: getBolusType2Value(measurement),
        bolusType3Value: getBolusType3Value(measurement),
        bolusRemark: measurement.bolusRemark,
        bolusRegisterType: measurement.bolusRegisterType,
        bolusTypeIcon: measurement.bolusType && getBolusTypeIcon(measurement),
        carbohydrates: measurement.carbohydrates,
        bloodGlucoseUnit,
      },
    ];
  };
