import React from 'react';
import styles from './styles.module.css';

import { RenderIf } from 'src/utils';
import {
  JELLO_TEXT_WEIGHTS,
  JELLO_TEXT_SIZES,
  JELLO_ICON_SIZES,
  JELLO_TOOLBAR_SIZES,
  JELLO_TOOLBAR_ORIENTATIONS,
  JELLO_ICON_NAMES,
} from 'src/app/app.jello.constants';
import { ADMINISTRATIVE } from 'src/core/user/user.constants.js';
import { PatientActionsComponent } from '../patient-actions-jello';
import { DevicesTypes } from './patient-data-bar.constants';
import {
  formatDoB,
  formatDiagnosticsDate,
  deviceListContainsActivedDevice,
  hasPatientCGMAssociation,
} from './patient-data-bar-jello.utils';
import { t } from 'i18next';
import { testId } from '@roche/roche-common';
import { checkDiabetesType } from 'src/domains/general/widgets/components/patient-info/patient-info.utils';

export const PatientDataBarComponent = (props) => {
  const { patient, activeDevices, cgmSummary, profile } = props;
  const {
    surName,
    firstName,
    healthCareSystemId,
    diabetesTypeEC6,
    dateOfBirth,
    diagnosticDate,
  } = patient;

  return (
    <jello-toolbar
      orientation={JELLO_TOOLBAR_ORIENTATIONS.HORIZONTAL}
      sticky=""
      size={JELLO_TOOLBAR_SIZES.S}
      style={{
        borderBottom: '1px solid var(--jello-color-brand-05)',
        marginBottom: 0,
      }}
      {...testId('jello-toolbar', 'patient-databar')}
    >
      <div className={styles.toolbarPatientInfoWrapper}>
        <div className={styles.patientMainDataWrapper}>
          <jello-text
            class={styles.patientMainDataText}
            size={JELLO_TEXT_SIZES.M}
            weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
          >
            {`${surName}, ${firstName}`}
          </jello-text>
          <jello-text
            class={styles.patientMainDataText}
            size={JELLO_TEXT_SIZES.S}
          >
            {`${t('patientNav.id')} ${healthCareSystemId}`}
          </jello-text>
        </div>
        <div className={styles.toolbarDivider} />
        <div className={styles.patientSecondaryDataWrapper}>
          <div className={styles.patientTitleAndInfoWrapper}>
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientNav.dateOfBirth')}
            </jello-text>
            <jello-text
              class={styles.patientMainDataText}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {formatDoB(dateOfBirth)}
            </jello-text>
          </div>
          <div className={styles.patientTitleAndInfoWrapper}>
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientNav.diabetes')}
            </jello-text>
            <jello-text
              class={styles.patientMainDataText}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {checkDiabetesType(diabetesTypeEC6)}
            </jello-text>
          </div>
          <div className={styles.patientTitleAndInfoWrapper}>
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientNav.diagnosed')}
            </jello-text>
            <jello-text
              class={styles.patientMainDataText}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {formatDiagnosticsDate(diagnosticDate)}
            </jello-text>
          </div>
          <div className={styles.patientTitleAndInfoWrapper}>
            <jello-text
              class={styles.patientDataTitle}
              size={JELLO_TEXT_SIZES.S}
              weight={JELLO_TEXT_WEIGHTS.SEMIBOLD}
            >
              {t('patientBar.deviceTitle')}
            </jello-text>
            <div className={styles.toolbarIconWrapper}>
              <jello-tooltip
                delay="300"
                label={t('patientBar.deviceType.meter')}
                position="bottom"
              >
                <jello-icon
                  class={
                    deviceListContainsActivedDevice(
                      [DevicesTypes.Meter, DevicesTypes.Normal],
                      activeDevices,
                    )
                      ? styles.iconActive
                      : styles.iconInactive
                  }
                  size={JELLO_ICON_SIZES.S}
                  icon-name={JELLO_ICON_NAMES['ACCU-CHEK-GUIDE']}
                />
              </jello-tooltip>
              <jello-tooltip
                delay="300"
                label={t('patientBar.deviceType.cgmSensor')}
                position="bottom"
              >
                <jello-icon
                  class={
                    deviceListContainsActivedDevice(
                      [DevicesTypes.Cgm],
                      activeDevices,
                    ) || hasPatientCGMAssociation({ ...cgmSummary })
                      ? styles.iconActive
                      : styles.iconInactive
                  }
                  size={JELLO_ICON_SIZES.S}
                  icon-name={JELLO_ICON_NAMES['CGM-DEVICE']}
                />
              </jello-tooltip>
              <jello-tooltip
                delay="300"
                label={t('patientBar.deviceType.pump')}
                position="bottom"
              >
                <jello-icon
                  class={
                    deviceListContainsActivedDevice(
                      [DevicesTypes.Meterpump],
                      activeDevices,
                    )
                      ? styles.iconActive
                      : styles.iconInactive
                  }
                  size={JELLO_ICON_SIZES.S}
                  icon-name={JELLO_ICON_NAMES['ACCU-CHEK-INSIGHT-PUMP']}
                />
              </jello-tooltip>
            </div>
          </div>
        </div>
      </div>
      <jello-toolbar-spacer />
      <RenderIf validate={profile !== ADMINISTRATIVE}>
        <PatientActionsComponent
          visitModuleActiveSection={props.visitModuleActiveSection}
          setVisitModuleActiveSection={props.setVisitModuleActiveSection}
        />
      </RenderIf>
    </jello-toolbar>
  );
};
