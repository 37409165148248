import React, { useEffect } from 'react';

export const useInterval = (callback: (props?: any) => any, delay: number) => {
  const savedCallback = React.useRef();
  let newDelay = delay;

  useEffect(() => {
    savedCallback.current = callback as FixMe;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      (savedCallback as FixMe).current();
    };
    const id = setInterval(tick, (newDelay = 1000));
    return () => clearInterval(id);
  }, [newDelay]);
};
