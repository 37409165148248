// istanbul ignore file
import { isEmpty, isNil, is } from 'ramda';

import { EMPTY_VALUE_PLACEHOLDER } from '../store/constants';

export const validateDeviceStats = (metricGroup, metric, stats, fixTo) => {
  if (
    !isEmpty(stats) &&
    !isNil(stats[metricGroup][metric]) &&
    is(Number, Number(stats[metricGroup][metric]))
  ) {
    return fixTo !== 0
      ? Math.round(stats[metricGroup][metric])
      : stats[metricGroup][metric];
  } else {
    return EMPTY_VALUE_PLACEHOLDER;
  }
};
export const validateDeviceInfo = (data) =>
  !isEmpty(data) ? data : EMPTY_VALUE_PLACEHOLDER;
