import styled from 'styled-components';
import { convertPxToRem } from 'src/utils';

export const IconSectionDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1.625rem;
  gap: 1.5rem;
`;

export const IconsSectionTitle = styled.div`
  text-align: start;
  font-size: 16px;
`;

export const IconsGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0rem;
  min-width: ${convertPxToRem(80)};
  max-width: ${convertPxToRem(300)};
  gap: 1.5rem;
`;

export const IconsSingleCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-height: 22px;
  gap: 6rem;
`;

export const IconCardItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 6rem;
`;

export const IconImageItem = styled.div`
  display: flex;
  min-width: 24px;
`;

export const IconRotateImageItem = styled.div`
  display: flex;
  padding: 2px 0 1px 2px;
  transform: rotate(45deg);
`;

export const IconNameItem = styled.div`
  display: flex;
  padding: 2px;
  min-width: ${convertPxToRem(150)};
`;

export const IconRowNameItem = styled.div`
  display: flex;
  padding: 2px;
  max-width: ${convertPxToRem(190)};
`;

export const IconTitleRowDiv = styled.div`
  display: flex;
`;

export const FourIconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0rem;
  min-width: ${convertPxToRem(80)};
  gap: 2rem;
`;

export const FourSingleIconCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
`;

export const FourIconCardItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const FourIconNameItem = styled.div`
  display: flex;
  padding: 2px;
  font-size: 12px;
`;

export const RotatedMetabolicIconContainer = styled.div`
  display: inline-block;
  transform: rotate(45deg);
`;
