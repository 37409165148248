import * as React from 'react';
import { colors } from 'src/core/styles/colors';
import { createStyledComponent } from 'src/utils';
import { convertPxToRem } from 'src/utils';

import { ComboDropdownProps } from './combo.types';

const textDisabled = colors.silverDark;
const selectHeight = convertPxToRem(48);

export const styleSortDropdown = (
  DropdownComponent: React.FunctionComponent<ComboDropdownProps>,
) => createStyledComponent<any, ComboDropdownProps>(DropdownComponent)`
  .Select-input {
    height: auto;
  }
  &.Select.is-open > .Select-control {
    border-color: #c3d0e8;
  }
  
  &.Select.is-open > .Select-control {
    box-shadow: 0px -4px 4px 4px rgba(217,217,217,1);
  } 
  
  &.Select--single {
    width: 105px;
  }
  .Select-control {
     height: 41px;
  }
  .Select-placeholder,
  .Select-control .Select-value,
  .Select-option,
  &.Select--single > .Select-control .Select-value {
    padding: 12px 15px;
    text-align: left;
    color: #343434;
    font-size: 12px;
    font-weight: normal;
    height: 14px;
    letter-spacing: 0px;
    line-height: 14px;
    height: auto;
  }

  .Select-control > *:last-child {
    padding-right: 0;
  }
  
  .Select-option{
    border-bottom: 1px solid #c3d0e8;
    &:last-child {
      border-bottom: none;
    }
  }

  .Select-arrow-zone {
    background-color: ${colors.white};
    color: #1065c7;
    padding-right: 0;
    width: ${({ theme }) => theme.spacing.four};
  }
  
  &.Select.is-open .Select-arrow-zone {
    transform: rotate(180deg);
  } 

  .Select-menu-outer {
    border-radius: 4px;
    width: 100%;
    z-index: 20;
    border: 1px solid #c3d0e8;
    box-shadow: 0px 4px 4px 4px rgba(217,217,217,1);
  }

  .Select-option.is-focused {
    background-color: ${colors.silverMedium};
  }

  .Select-option.is-selected {
    background-color: #f7f9fd;
  }


  &.Select.is-focused:not(.is-open) {
    & > .Select-control {
      border-color: #c3d0e8;
      box-shadow: none;
      outline: none;
      color: ${colors.black};
    }

    .Select-arrow-zone {
      border-color: #c3d0e8;
      color: #1065c7;

      path {
        fill: #1065c7;
      }
    }
  }

  &.Select.is-disabled {
    > .Select-control {
      background-color: ${colors.white};
      border-color: #c3d0e8;
    }
    .Select-arrow-zone {
      color: #1065c7;

      path {
        fill: #1065c7;
      }
    }
    .Select-placeholder {
      color: ${textDisabled};
    }
  }

  &.Select.is-searchable {
    .Select-input {
      line-height: ${selectHeight};
      margin-left: 0;
      padding: 0;
      height: 100%;
    }
  }

  .Select-multi-value-wrapper {
    margin-bottom: -${({ theme }) => theme.spacing.two};
    max-height: 100%;
    padding: ${convertPxToRem(10)} ${({ theme }) => theme.spacing.three};
    min-width: 178px;
  }

    .Select-value-icon {
      border: none;
      border-radius: 0;
      font-size: ${({ theme }) => theme.fontSize.subheading};
      padding: ${({ theme }) => theme.spacing.one} ${({ theme }) =>
  theme.spacing.one} ${({ theme }) => theme.spacing.one} ${({ theme }) =>
  theme.spacing.two};
      transition: ${({ theme }) => theme.transitions.default};

      &:hover {
        background-color: ${({ theme }) => theme.colors.clear};
        color: ${({ theme }) => theme.colors.brandBlueDark};
      }
    }
  }

`;
