import * as React from 'react';
import { TFunction } from 'i18next';

import {
  LegendContainer,
  LegendWrapper,
  LegendTitle,
  LegendLineData,
  LegendDarkBlue,
  LegendLightBlue,
  LegendBlackLine,
  LegendGreenLine,
  LegendText,
  Legend,
  NavTabsListItemText,
  ArrowUpDown,
  NavTabsListItem,
} from './legend.style';

import { ArrowIcon } from 'src/assets/icons';
import { colors } from 'src/core';
import { RenderIf } from 'src/utils/render-if';

type legendProps = {
  t: TFunction;
};

export class LegendComponent extends React.Component<legendProps> {
  private wrapperRef: HTMLElement;
  private ref = React.createRef<HTMLElement>();
  public state = {
    selected: false,
  };
  public componentDidMount() {
    if (this.ref.current) {
      this.wrapperRef = this.ref.current;
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  public render() {
    const { t } = this.props;
    const { selected } = this.state;

    return (
      <Legend ref={this.ref}>
        <NavTabsListItem
          onClick={() => this.handleTabClick()}
          className={selected ? 'selected' : ''}
        >
          <NavTabsListItemText className={selected ? 'selected' : ''}>
            {t('graphDetails.legend.legend')}
            <ArrowUpDown className={selected ? 'selected' : ''}>
              <ArrowIcon height={6} fillColor={colors.charcoal} />
            </ArrowUpDown>
          </NavTabsListItemText>
        </NavTabsListItem>
        <RenderIf validate={selected}>
          <LegendContainer>
            <LegendWrapper>
              <LegendTitle>{t(`cgmDashboard.legend.title`)}</LegendTitle>
              <LegendLineData>
                <LegendDarkBlue>
                  {t(`cgmDashboard.legend.darkbluetitle`)}
                </LegendDarkBlue>
                <LegendText>{t(`cgmDashboard.legend.darkbluetext`)}</LegendText>
              </LegendLineData>
              <LegendLineData>
                <LegendLightBlue>
                  {t(`cgmDashboard.legend.lightbluetitle`)}
                </LegendLightBlue>
                <LegendText>
                  {t(`cgmDashboard.legend.lightbluetext`)}
                </LegendText>
              </LegendLineData>
              <LegendLineData>
                <LegendBlackLine />
                <LegendText>{t(`cgmDashboard.legend.darklinetext`)}</LegendText>
              </LegendLineData>
              <LegendLineData>
                <LegendGreenLine />
                <LegendText>
                  {t(`cgmDashboard.legend.greenlinetext`)}
                </LegendText>
              </LegendLineData>
            </LegendWrapper>
          </LegendContainer>
        </RenderIf>
      </Legend>
    );
  }
  private handleTabClick = () => {
    this.setState({
      selected: !this.state.selected,
    });
  };
  private handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        selected: false,
      });
    }
  };
}
