import * as React from 'react';
import { ModuleLoaderFactory } from '@roche/roche-common';
import { store } from 'src/app/app';
import { saveManifestModules } from 'src/core/manifest-modules/manifestModules.actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectIsSessionValid } from 'src/app/session';

const Loader = ({ isValidSession }) => {
  React.useEffect(() => {
    if (isValidSession) {
      const moduleLoader = new ModuleLoaderFactory();
      moduleLoader.loadAllModules({
        manifestPath: '/modules/manifests/',
        mainManifestName: 'rdcp-hcp-client-modules-manifest',
        domainUrl: window.location.origin,
        fetch,
        isDebug: false,
        callback: (data) => store.dispatch(saveManifestModules(data)),
      });
    }
  }, [isValidSession]);

  return <></>;
};

export const ModuleLoaderComponent = compose(
  connect(
    createStructuredSelector({
      isValidSession: selectIsSessionValid,
    }),
  ),
)(Loader as any);
