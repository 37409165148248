import { Div } from 'src/components/div';
import { combineRems, convertPxToRem } from 'src/utils';
import styled from 'styled-components';
import { InputTextArea } from 'src/components/input/input.style';
import { InputDropdown } from 'src/widgets/forms/components/input-dropdown';
import { PatientFormContainer } from 'src/widgets/patient/forms/patient-form.style';

export const DeactivatePatientSection = styled(Div)``;
DeactivatePatientSection.displayName = 'DeactivatePatientSection';

export const HeaderContainer = styled(Div)`
  flex: 1;
  border-bottom: 1px solid ${(props) => props.theme.colors.silverMedium};
`;
HeaderContainer.displayName = 'HeaderContainer';

export const HeaderTitle = styled.h1`
  color: ${(props) => props.theme.colors.charcoal};
  font-size: ${(props) => props.theme.fontSize.headline};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  margin: 0 auto
    ${(props) =>
      combineRems(props.theme.spacing.one, props.theme.spacing.three)};
`;
HeaderTitle.displayName = 'HeaderTitle';

export const FormContainer = styled(Div)`
  width: 48%;
  min-width: ${convertPxToRem(500)};
  margin-top: ${convertPxToRem(45)};
  margin-bottom: ${convertPxToRem(95)};
`;
FormContainer.displayName = 'FormContainer';

export const FooterContainer = styled(Div)`
  border-top: 2px solid ${(props) => props.theme.colors.silverMedium};
`;
FooterContainer.displayName = 'FooterContainer';

export const CommentsTextArea = styled(InputTextArea)`
  border-radius: 3px;
  flex: 1 1 80%;
  margin-top: 10px;
  resize: none;
  min-height: ${convertPxToRem(180)};
`;
CommentsTextArea.displayName = 'CommentsTextArea';

export const ReasonSelect = styled(InputDropdown)`
  margin-top: 10px;
  margin-bottom: 20px;
`;
ReasonSelect.displayName = 'ReasonSelect';

export const ButtonsContainer = styled(PatientFormContainer)`
  border-top: 1px solid ${(props) => props.theme.colors.silverMedium};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding-top: ${({ theme }) => theme.spacing.five};

  & > button {
    margin-left: ${({ theme }) => theme.spacing.four};
  }
  padding-top: 2rem;
`;
ButtonsContainer.displayName = 'ButtonsContainer';
