import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { transformJSONToPrescriptions } from '../prescription.util';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const GetPrescriptionLoaderImpl = (
  { patientId },
  accessToken  
) =>
  getJSON(
    endpointWithParams(ENDPOINTS.getPrescription, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),        
      },
    },
  );

export const GetPrescriptionTransformImpl = transformJSONToPrescriptions;

export const GetPrescriptionServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
