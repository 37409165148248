import {
  BLOOD_GLUCOSE_UNIT_PLACEHOLDER,
  LOGBOOK_STATS_TABLE_HEADER_COLUMNS,
  LogbookStatsTableColumn,
} from './logbook-stats-table-header.constants';

export const getTableHeaderColumnConfig = (
  bloodGlucoseUnit: string,
): LogbookStatsTableColumn[] =>
  LOGBOOK_STATS_TABLE_HEADER_COLUMNS.map((column) => ({
    ...column,
    headerRows: column.headerRows.map((headerRow) =>
      headerRow.map((subColumn) => ({
        ...subColumn,
        textKeys: subColumn.textKeys.map((key) =>
          key === BLOOD_GLUCOSE_UNIT_PLACEHOLDER ? bloodGlucoseUnit : key,
        ),
      })),
    ),
  }));
