import * as platform from 'platform';
import { getConfig } from '@roche/roche-common';

const MAC_OSX_REGEX = /(mac)|(OS\sX)/gi;

export const transformPlatformToOSMap = ({ os }) => {
  const isMacOS = os && os.toString().match(MAC_OSX_REGEX);
  return {
    name: isMacOS ? 'mac' : 'win',
    extension: isMacOS ? 'dmg' : 'exe',
  };
};

const { REACT_APP_URL } = getConfig();

export const generateDTCDownloadURL = () => {
  const os = transformPlatformToOSMap(platform);
  return `${REACT_APP_URL}/buckets/${os.name}/Setup.${os.extension}`;
};
