import React from 'react';
import { isEmpty } from 'ramda';
import { AutoSizer } from 'react-virtualized';

import {
  Candlestick,
  ThresholdLine,
  TargetRange,
  BackgroundPanelsFitted,
  GridLines,
  TrendToolTip,
  GraphPlot,
  BloodGlucoseAxis,
} from 'src/domains/diagnostics/components';
import { ResizeWrapper } from 'src/components/resize-wrapper/resize-wrapper.component';
import { Graph, Plot, TrendSeries } from 'src/lib';
import { colors } from 'src/core/styles';
import { ToolTip } from 'src/domains/diagnostics/components';
import { GraphControls } from 'src/domains/diagnostics/components/graph-controls';
import { filterVerticalTicksForViewAndVisibilityTolerance } from 'src/domains/diagnostics/utils/graphs.util';
import { RenderIf } from 'src/domains/diagnostics/utils';
import { withGraphLoader } from 'src/domains/diagnostics/utils';
import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';
import { withToolTip } from 'src/utils/with-tool-tip';

import { StandardWeekTrendWrapperDiv } from './standard-week-trend.style';

import { WeekAxis } from '../standard-week-detail/components';
import {
  getToolTipValueColor,
  getYAxisTickVisibilityTolerance,
} from '../graph.util';
import {
  GRAPH_MAX_HEIGHT,
  GRAPH_TYPE_TREND,
  COLLAPSED_STD_GRAPH_HEIGHT,
  MIN_EXPANDED_STD_GRAPH_HEIGHT,
} from '../graph.constants';

const X_AXIS_HEIGHT = 0.13;
const Y_AXIS_WIDTH = 0.05;

const PLOT_WIDTH = 1 - Y_AXIS_WIDTH * 2;
const PLOT_HEIGHT = 1 - X_AXIS_HEIGHT * 2;

const StandardWeekTrendPlot = ({
  x,
  y,
  width,
  height,
  points,
  padding = 0,
  targetRange,
  threshold,
  horizontalTicks,
  verticalTicks,
  yDirection = -1,
  showGridLines,
  onCandleStickMouseOver,
  onCandleStickMouseOut,
}) => (
  <Plot
    id="main-viewport"
    x={x}
    y={y}
    width={width}
    height={height}
    padding={padding}
  >
    <BackgroundPanelsFitted
      width={width}
      height={height}
      count={points.length / 2}
      primary={colors.silverLight}
      secondary={colors.white}
    />
    <RenderIf validate={showGridLines}>
      <GridLines
        width={width}
        height={height}
        verticalCount={horizontalTicks.length}
        horizontalCount={verticalTicks.filter((tick) => tick.gridLine).length}
      />
    </RenderIf>
    <TargetRange
      min={targetRange.min}
      max={targetRange.max}
      width={width}
      height={height}
      plotHeight={400}
    />
    <ThresholdLine
      threshold={threshold.value}
      width={width}
      height={height}
      plotHeight={400}
    />
    <TrendSeries
      width={width}
      height={height}
      points={points}
      candlestickShape={Candlestick}
      onMouseOver={onCandleStickMouseOver}
      onMouseOut={onCandleStickMouseOut}
      range={points.length}
      pointsOnAxes
    />
  </Plot>
);

const StandardWeekTrendWrapperDivWithLoader = withGraphLoader(
  StandardWeekTrendWrapperDiv,
);

export const StandardWeekTrend = withToolTip(
  ({
    bloodGlucoseUnit,
    measurements,
    graphData,
    graphDetails,
    showChangeGraphToggle,
    showGraphDetails = true,
    showGridLines,
    targetRange,
    threshold,
    horizontalTicks,
    verticalTicks,
    yDirection = -1,
    toolTip,
    showToolTip,
    hideToolTip,
    flexibleHeight,
    isLoading,
    graphYMax,
    t,
    graph,
    collapsed,
  }) => {
    const translatedHorizontalTicks = horizontalTicks.map((tick) => ({
      ...tick,
      label: t(tick.label),
    }));

    const yAxisTickVisibilityTolerance = getYAxisTickVisibilityTolerance({
      bloodGlucoseUnit,
    });

    return (
      <React.Fragment>
        <ResizeWrapper
          minHeight={
            collapsed
              ? COLLAPSED_STD_GRAPH_HEIGHT
              : MIN_EXPANDED_STD_GRAPH_HEIGHT
          }
          render={(height) => {
            const filteredVerticalTicks =
              filterVerticalTicksForViewAndVisibilityTolerance(
                verticalTicks,
                flexibleHeight,
                threshold,
                targetRange,
                yAxisTickVisibilityTolerance,
                graphYMax,
              );

            return (
              <StandardWeekTrendWrapperDivWithLoader
                hasError={isEmpty(measurements) && !isLoading}
                isLoading={isLoading}
              >
                <AutoSizer>
                  {({ width }) => (
                    <React.Fragment>
                      <RenderIf validate={!isLoading && !isEmpty(measurements)}>
                        <GraphControls
                          showChangeGraphToggle={showChangeGraphToggle}
                          graphStatistics={graphDetails}
                          graphType={GRAPH_TYPE_TREND}
                          graph={graph}
                          width={width}
                        />
                      </RenderIf>
                      <RenderIf validate={width && height}>
                        <Graph
                          viewportRight={width}
                          viewportBottom={height}
                          height={height}
                          anchor="xMidYMid"
                        >
                          <GraphPlot
                            width={width}
                            height={height}
                            xAxisHeight={X_AXIS_HEIGHT}
                            yAxisWidth={Y_AXIS_WIDTH}
                            plotHeight={PLOT_HEIGHT}
                            plotWidth={PLOT_WIDTH}
                            mainGraph={(dimensions) => (
                              <StandardWeekTrendPlot
                                {...dimensions}
                                points={graphData}
                                verticalTicks={filteredVerticalTicks}
                                horizontalTicks={translatedHorizontalTicks}
                                targetRange={targetRange}
                                threshold={threshold}
                                showGridLines={showGridLines}
                                onCandleStickMouseOver={showToolTip}
                                onCandleStickMouseOut={hideToolTip}
                              />
                            )}
                            xAxisBottom={(dimensions) => (
                              <WeekAxis
                                {...dimensions}
                                {...{ yDirection }}
                                ticks={translatedHorizontalTicks}
                              />
                            )}
                            yAxisLeft={(dimensions) => (
                              <BloodGlucoseAxis
                                {...dimensions}
                                ticks={filteredVerticalTicks}
                                unit={bloodGlucoseUnit}
                              />
                            )}
                          />
                        </Graph>
                      </RenderIf>
                    </React.Fragment>
                  )}
                </AutoSizer>
              </StandardWeekTrendWrapperDivWithLoader>
            );
          }}
        />
        <RenderIf validate={toolTip.x && toolTip.y}>
          {renderToolTip(toolTip, threshold, targetRange, bloodGlucoseUnit)}
        </RenderIf>
      </React.Fragment>
    );
  },
);

const renderToolTip = (
  { x, y, data },
  { data: thresholdData },
  { data: targetRangeData },
  bloodGlucoseUnit,
) => {
  const { stdDev, max, min, mean, count } = data;

  return (
    <ToolTip x={x} y={y}>
      <TrendToolTip
        bloodGlucoseUnit={bloodGlucoseUnit}
        max={max}
        min={min}
        mean={mean && mean.toFixed(1)}
        numMeasurements={count}
        stDev={
          !stdDev || stdDev === EMPTY_VALUE_PLACEHOLDER
            ? stdDev
            : stdDev.toFixed(1)
        }
        meanColor={getToolTipValueColor(
          mean,
          thresholdData.value,
          targetRangeData,
        )}
        maxColor={getToolTipValueColor(
          max,
          thresholdData.value,
          targetRangeData,
        )}
        minColor={getToolTipValueColor(
          min,
          thresholdData.value,
          targetRangeData,
        )}
      />
    </ToolTip>
  );
};
