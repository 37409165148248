import { STATE_ACTIONS } from 'src/core';
import {
  ParingCodeIdentityStatusState,
  PairingCodeIdentityStatusActions,
  PairingCodeIdentityStatusActionType,
} from './pairing-code-identity-status.types';

export const pairingCodeIdentityStatusInitialState: ParingCodeIdentityStatusState =
  {
    pairingCodeIdentityStatusStart: false,
    pairingCodeIdentityStatusSuccess: false,
    pairingCodeIdentityStatusError: false,
    identityStatusResponse: {
      identityId: '',
      identityStatus: false,
    },
    error: null,
  };

export const pairingCodeIdentityStatusReducer = (
  state = pairingCodeIdentityStatusInitialState,
  action: PairingCodeIdentityStatusActions,
): ParingCodeIdentityStatusState => {
  switch (action.type) {
    case PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_START:
      return {
        ...state,
        pairingCodeIdentityStatusStart: true,
        pairingCodeIdentityStatusSuccess: false,
        pairingCodeIdentityStatusError: false,
        identityStatusResponse: {
          identityId: null,
          identityStatus: false,
        },
        error: null,
      };
    case PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_SUCCESS:
      return {
        ...state,
        pairingCodeIdentityStatusStart: false,
        pairingCodeIdentityStatusSuccess: true,
        pairingCodeIdentityStatusError: false,
        identityStatusResponse: action.payload,
        error: null,
      };
    case PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_ERROR:
      return {
        ...state,
        pairingCodeIdentityStatusStart: false,
        pairingCodeIdentityStatusSuccess: false,
        pairingCodeIdentityStatusError: true,
        identityStatusResponse: {
          identityId: null,
          identityStatus: false,
        },
        error: action.payload,
      };
    case STATE_ACTIONS.CLEAR_PAIRING_CODE_IDENTITY:
      return pairingCodeIdentityStatusInitialState;
    default:
      return state;
  }
};
