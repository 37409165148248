import { ProfileTypes } from 'src/core/department/department.types';
import { STATE_ACTIONS } from 'src/core/state';
import { PatientGender } from 'src/core/patient/patient.types';

import {
  EditPatientActions,
  EditPatientActionType,
  EditPatientState,
} from './edit-patient.types';

export const initialEditPatientState: EditPatientState = {
  isEditingPatient: false,
  editWasSuccessful: null,
  isFetchingPatient: false,
  fetchingPatientHasError: false,
  fetchingPatientWasSuccessful: false,
  patientFormData: {
    grantedPermissions: [],
    profileType: ProfileTypes.none,
    patientInfo: {
      allowPatientAccess: true,
      gender: PatientGender.UNSPECIFIED,
    },
    healthInfo: {
      pregnant: undefined,
    },
  },
  patientFormDataBeforeEditing: null,
};

export const editPatientReducer = (
  state = initialEditPatientState,
  action: EditPatientActions,
): EditPatientState => {
  switch (action.type) {
    case EditPatientActionType.EDIT_PATIENT_START:
    case EditPatientActionType.EDIT_EMR_PATIENT_START:
      return {
        ...state,
        isEditingPatient: true,
      };
    case EditPatientActionType.EDIT_PATIENT_SUCCESS:
    case EditPatientActionType.EDIT_EMR_PATIENT_SUCCESS:
      return {
        ...state,
        isEditingPatient: false,
        editWasSuccessful: true,
      };
    case EditPatientActionType.EDIT_PATIENT_STATUS_MODAL_CONFIRMED:
      return {
        ...state,
        editWasSuccessful: null,
      };
    case EditPatientActionType.EDIT_PATIENT_ERROR:
    case EditPatientActionType.EDIT_EMR_PATIENT_ERROR:
      return {
        ...state,
        isEditingPatient: false,
        editWasSuccessful: false,
      };
    case EditPatientActionType.FETCH_PATIENT_FOR_EDIT_START:
      return {
        ...state,
        isFetchingPatient: true,
      };
    case EditPatientActionType.FETCH_PATIENT_FOR_EDIT_SUCCESS:
      return {
        ...state,
        isFetchingPatient: false,
        fetchingPatientWasSuccessful: true,
        patientFormDataBeforeEditing: action.payload,
      };
    case EditPatientActionType.FETCH_PATIENT_FOR_EDIT_ERROR:
      return {
        ...state,
        isFetchingPatient: false,
        fetchingPatientHasError: true,
      };
    case EditPatientActionType.NO_ASSOCIATED_PROFESSIONALS:
      return {
        ...state,
        patientFormData: {
          ...state.patientFormData,
          healthInfo: {
            ...state.patientFormData.healthInfo,
            associatedProfessional: undefined,
          },
        },
      };
    case STATE_ACTIONS.CLEAR_EDIT_PATIENT:
      return initialEditPatientState;
    default:
      return state;
  }
};
