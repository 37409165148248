import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { selectAccessToken, selectGigyaToken } from 'src/app/session';
import {
  PairingCodeIdentityStatusLoaderImplType,
  PairingCodeIdentityStatusTransformedResponseType,
} from '../../../../../../services/pairing-code/pairing-code-identity-status/pairing-code-identity-status.types';
import {
  PairingCodeIdentityStatusActionType,
  PairingCodeIdentityStatusStartAction,
} from './pairing-code-identity-status.types';
import {
  pairingCodeIdentityStatusSuccess,
  pairingCodeIdentityStatusError,
} from './pairing-code-identity-status.actions';

export const pairingCodeIdentityStatusEpic: (
  pairingCodeIdentityStatusService: PairingCodeIdentityStatusLoaderImplType,
) => Epic<any, any> = (pairingCodeIdentityStatusService) => (action$, store) =>
  action$
    .ofType(
      PairingCodeIdentityStatusActionType.PAIRING_CODE_IDENTITY_STATUS_START,
    )
    .flatMap(({ payload }: PairingCodeIdentityStatusStartAction) => {
      const accessToken = selectAccessToken(store.getState());
      const apiKey = selectGigyaToken(store.getState());
      return Observable.fromPromise(
        pairingCodeIdentityStatusService(accessToken, apiKey, payload),
      )
        .map((response: PairingCodeIdentityStatusTransformedResponseType) => {
          return pairingCodeIdentityStatusSuccess(response);
        })
        .pipe(
          catchError((err) =>
            Observable.of(pairingCodeIdentityStatusError(err)),
          ),
        );
    });
