import styled from 'styled-components';

import { convertPxToRem } from 'src/domains/diagnostics/utils';

import { weight } from '../weights';

export const Title = styled.h6`
  font-size: ${convertPxToRem(20)};
  font-weight: ${weight};
  margin: 0;
`;
