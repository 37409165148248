import { combineEpics, Epic } from 'redux-observable';
import { State } from 'src/app/store/app.types';
import { createModal, MODAL_TYPES } from 'src/core/modal';

import {
  ExpiringModalActions,
  ExpiringModalActionTypes,
} from './expiring-modal.types';

/* istanbul ignore next */ export const showExpiringModalEpic =
  (): Epic<ExpiringModalActions, State> => (action$) =>
    action$
      .ofType(ExpiringModalActionTypes.SHOW_EXPIRING_MODAL)
      .switchMap((action) => {
        return [
          createModal({
            key: MODAL_TYPES.TTL_EXPIRING,
            data: { ...action.payload },
          }),
        ];
      });

export const expiringModalEpics = combineEpics(showExpiringModalEpic());
