import styled from 'styled-components';

import { colors } from 'src/core';

export const VisibleWrapper = styled.div`
  .DateInput {
    display: none;
  }
  .SingleDatePicker_picker {
    position: initial;
  }
  .CalendarMonth_caption,
  .DayPicker_weekHeader {
    color: ${colors.blue};
  }
  .CalendarMonth_table {
    tr {
      border: none;
    }
  }
  * {
    border: none;
    box-shadow: none;
  }
`;
