import { Div } from 'src/components/div';
import { GridContainer } from 'src/components/grid-layout';
import styled from 'styled-components';
import { FormLabel } from 'src/components/forms';
import { InputDropdown } from 'src/widgets/forms/components/input-dropdown';
import { Block } from 'src/components/block';

import { InputTextArea } from 'src/components/input/input.style';
import { combineRems, convertPxToRem } from 'src/utils';
import { breakpoints } from 'src/core/styles/breakpoints';

export const DeliveryConfigurationSection = styled(Div)``;
DeliveryConfigurationSection.displayName = 'DeliveryConfigurationSection';

export const HeaderContainer = styled(Div)`
  flex: 1;
  border-bottom: 2px solid ${(props) => props.theme.colors.silverMedium};
`;
HeaderContainer.displayName = 'HeaderContainer';

export const HeaderTitle = styled.h3`
  color: ${(props) => props.theme.colors.brandBlue};
  font-size: ${(props) => props.theme.fontSize.title};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.four};
`;
HeaderTitle.displayName = 'HeaderTitle';

export const FormContainer = styled(Div)`
  width: 100%%;
  min-width: ${convertPxToRem(500)};
  margin-top: ${convertPxToRem(45)};
  margin-bottom: ${convertPxToRem(95)};
`;
FormContainer.displayName = 'FormContainer';

export const FooterContainer = styled(Div)`
  border-top: 2px solid ${(props) => props.theme.colors.silverMedium};
`;
FooterContainer.displayName = 'FooterContainer';

export const CommentsTextArea = styled(InputTextArea)`
  border-radius: 3px;
  flex: 1 1 80%;
  margin-top: 10px;
  resize: none;
  min-height: ${convertPxToRem(180)};
`;
CommentsTextArea.displayName = 'CommentsTextArea';

export const ReasonSelect = styled(InputDropdown)`
  margin-top: 10px;
  margin-bottom: 20px;
`;
ReasonSelect.displayName = 'ReasonSelect';

export const ButtonsContainer = styled(FormContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing.four};
  padding-top: ${({ theme }) => theme.spacing.one};

  & > button {
    margin-left: ${({ theme }) => theme.spacing.four};
  }
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const ResponsiveGridContainer = styled(GridContainer)`
  grid-gap: ${({ theme }) => theme.spacing.four};
  grid-auto-flow: row;

  @media all and (min-width: ${breakpoints.large}) {
    grid-gap: ${({ theme }) =>
      combineRems(theme.spacing.three, theme.spacing.four)}}
      ${({ theme }) => combineRems(theme.spacing.four, theme.spacing.five)}};
  }
`;

export const DeliveryConfigurationContainer = styled(Block)`
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colors.silverMedium};
`;
DeliveryConfigurationContainer.displayName = 'DeliveryConfigurationContainer';

export const ConfigurationLabel = styled(FormLabel)`
  min-height: ${convertPxToRem(40)};
`;
ConfigurationLabel.displayName = 'ConfigurationLabel';
