import * as React from 'react';
import { ToolTipNoDataDashboardComponent } from '../tooltip-no-data/tooltip-no-data.component';

import {
  NoDataToolTipText,
  NoDataToolTipTextContainer,
  NoDataToolTipContainer,
} from './no-data-tooltip.style';
import { RenderIf } from '../../utils';

type NodataToolTipComponentProps = {
  type: {
    text: string;
    toolTipTitle: string;
    toolTipDescription: string;
  };
};

export class NodataToolTipContainer extends React.Component<NodataToolTipComponentProps> {
  public state = {
    showToolTipValue: false,
  };
  public render() {
    const { showToolTipValue } = this.state;
    const { type } = this.props;
    const { text, toolTipTitle, toolTipDescription } = type;
    return (
      <React.Fragment>
        <NoDataToolTipContainer>
          <RenderIf validate={showToolTipValue}>
            <ToolTipNoDataDashboardComponent
              title={toolTipTitle}
              description={toolTipDescription}
            />
          </RenderIf>
          <NoDataToolTipTextContainer
            onMouseOver={this.showToolTipOver}
            onMouseLeave={this.showToolTipLeave}
          >
            <NoDataToolTipText className={'no-data-tool-tip-text'}>
              {text}
            </NoDataToolTipText>
          </NoDataToolTipTextContainer>
        </NoDataToolTipContainer>
      </React.Fragment>
    );
  }
  private showToolTipOver = () => {
    this.setState({
      showToolTipValue: true,
    });
  };
  private showToolTipLeave = () => {
    this.setState({
      showToolTipValue: false,
    });
  };
}
