import styled from 'styled-components';

import { transitionEasing, transitionSpeed } from 'src/core/index';

import { fadeIn } from '../../../theme/animations';
import { combineRems } from '../../../utils/index';

const { fast } = transitionSpeed;
const { enter } = transitionEasing;

export const InputErrorLabel = styled.label`
  color: ${({ theme }) => theme.colors.trafficRed};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.caption};
  min-height: ${({ theme }) =>
    combineRems(theme.spacing.two, theme.spacing.three)};
  padding: ${({ theme }) => theme.spacing.two} 0 0;

  div {
    animation: ${fadeIn} ${fast} ${enter} 0s backwards;
  }

  span:not(:first-child) {
    display: none;
  }
`;
