import React from 'react';
import { pick } from 'ramda';
import { connect } from 'react-redux';
import { mapDispatchers } from 'src/utils';
import {
  JELLO_ICON_BUTTON_SIZES,
  JELLO_ICON_BUTTON_VARIANTS,
  JELLO_ICON_NAMES,
} from 'src/app/app.jello.constants';
import styles from './styles.module.css';
import { VisitTimeTrackingComponent } from './visit-time-tracking-jello.component';
import { createSelector } from 'reselect';
import {
  selectPatient,
  selectPatientId,
} from 'src/core/patient/patient.selector';
import {
  selectIsPatientSessionRunning,
  selectPatientSessionEndStatus,
  selectPatientSessionTime,
  selectPatientVisitId,
} from 'src/core/patient-session/patient-session.selectors';
import {
  resetIsRunningAction,
  resetPatientSessionAction,
  resetSessionStatusAction,
  savePatientSessionStartAction,
} from 'src/core/patient-session/patient-session.actions';
import {
  selectIsRPMEnrolled,
  selectIsTimeTrackerEnrolled,
  selectPatientPermissions,
  selectPermissions,
} from 'src/core/permissions';
import { VisitModuleActiveSection } from 'src/domains/patient/utils/with-jello-wrapper';
import { t } from 'i18next';
import { hasVisitModulePermission } from 'src/modules/visit-module/visit-module-permissions';
import { DocumentsExportSectionComponent } from 'src/domains/patient-dashboards/patient-dashboard-jello/components/patient-actions-jello/documents-export-section.component';

const selectPatientInfo: any = createSelector(
  [selectPatient],
  pick([
    'firstName',
    'surName',
    'dateOfBirth',
    'diabetesTypeEC6',
    'healthCareSystemId',
    'diagnosticDate',
  ]),
);
const mapStateToProps = (state, props) => ({
  isRPMEnrolled: selectIsRPMEnrolled(state),
  isTimeTrackerEnrolled: selectIsTimeTrackerEnrolled(state),
  isPatientSessionRunning: selectIsPatientSessionRunning(state),
  patientSessionTime: selectPatientSessionTime(state),
  patient: selectPatientInfo(state),
  sessionEndStatus: selectPatientSessionEndStatus(state),
  patientId: selectPatientId(state),
  visitId: selectPatientVisitId(state),
  hcpPermissions: selectPermissions(state),
  patientPermissions: selectPatientPermissions(state),
});

const mapDispatchToProps = mapDispatchers({
  resetPatientSession: resetPatientSessionAction,
  resetSessionEndStatus: resetSessionStatusAction,
  endSessionStart: savePatientSessionStartAction,
  resetIsRunning: resetIsRunningAction,
});

export const PatientActionsComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const onClickNotesButton = () =>
    props.visitModuleActiveSection !== VisitModuleActiveSection.NOTES
      ? props.setVisitModuleActiveSection(VisitModuleActiveSection.NOTES)
      : props.setVisitModuleActiveSection(VisitModuleActiveSection.CLOSE);

  const onClickVideocallButton = () =>
    props.visitModuleActiveSection !== VisitModuleActiveSection.VIDEOCALL
      ? props.setVisitModuleActiveSection(VisitModuleActiveSection.VIDEOCALL)
      : props.setVisitModuleActiveSection(VisitModuleActiveSection.CLOSE);

  return (
    <>
      <VisitTimeTrackingComponent
        isRPMEnrolled={props.isRPMEnrolled}
        isTimeTrackerEnrolled={props.isTimeTrackerEnrolled}
        isPatientSessionRunning={props.isPatientSessionRunning}
        resetPatientSession={props.resetPatientSession}
        resetSessionEndStatus={props.resetSessionEndStatus}
        patientSessionTime={props.patientSessionTime}
        patient={props.patient}
        sessionEndStatus={props.sessionEndStatus}
        endSessionStart={props.endSessionStart}
        patientId={props.patientId}
        visitId={props.visitId}
        resetIsRunning={props.resetIsRunning}
      />
      <DocumentsExportSectionComponent />
      {hasVisitModulePermission(
        props.hcpPermissions,
        props.patientPermissions,
      ) && (
        <section
          className={styles.buttonsSection}
          data-testid="patient-actions-buttons-section"
        >
          <jello-tooltip
            role="tooltip"
            title="Notes & reports button"
            label={t('patientBar.notesTooltipText')}
          >
            <jello-icon-button
              data-testid="visit-notes-button"
              size={JELLO_ICON_BUTTON_SIZES.L}
              variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
              icon-name={JELLO_ICON_NAMES['DOCUMENT-SIGNATURE']}
              onClick={onClickNotesButton}
            />
          </jello-tooltip>
          <jello-tooltip
            role="tooltip"
            title="Video consultation button"
            label={t('patientBar.videocallTooltipText')}
          >
            <jello-icon-button
              data-testid="visit-videocall-button"
              size={JELLO_ICON_BUTTON_SIZES.L}
              variant={JELLO_ICON_BUTTON_VARIANTS.TERTIARY}
              icon-name={JELLO_ICON_NAMES.VIDEO}
              onClick={onClickVideocallButton}
            />
          </jello-tooltip>
        </section>
      )}
    </>
  );
});
