import * as React from 'react';
import { V3RpmSettingsIcon } from 'src/assets/icons/v3-rpm-settings-icon/v3-rpm-settings-icon.component';
import { pathOr } from 'ramda';
import { countlyEventTrigger } from 'src/navigation/countly';
import { EVENTS_KEYS } from 'src/widgets/patient/create-patient/countly.constants';
import { matchPath } from 'react-router-dom';

import { LocalizedText } from 'src/components';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';
import { WithPermissions } from 'src/utils/with-permissions/with-permissions.container';
import {
  NewPatientIcon,
  PrintAndDownloadIcon,
  V3AddProfessionalIcon,
  V3UploadDataIcon,
  V3StockIcon,
  V3InfoIcon,
  V3ExportTimeTrackerIcon,
} from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';
import { routes } from 'src/domains/routes';
import { selectIsHCPOrGPOrAdminUserProfile } from 'src/core/user/user.selectors';

import {
  PopoverList,
  PopoverListItem,
  PopoverListItemLink,
} from './menu-popover.style';
import {
  IconContainer,
  PopoverListItemText,
  PopoverListItemNoLink,
} from './menu-dropdown-popover.style';
import { Popover } from './menu-popover.component';
import { isHomeRoute } from 'src/utils/path-matchers';

export const listIcons = {
  top: [
    {
      key: 'createPatient',
      iconName: NewPatientIcon,
      iconText: 'hcpDashboard.createPatient',
      permissions: [PERMISSIONS.REGISTER_PATIENT],
      link: ['patient', 'createPatient'],
    },
    {
      key: 'addProfessional',
      iconName: V3AddProfessionalIcon,
      iconText: 'profileDropdownPopover.addProfessional',
      permissions: [PERMISSIONS.CREATE_PROFESSIONAL],
      link: ['professional', 'createProfessional'],
    },
    {
      key: 'stock',
      iconName: V3StockIcon,
      iconText: 'menuPopover.stock',
      permissions: [PERMISSIONS.STRIP_STOCK],
      link: ['stripManagement', 'stock'],
    },
    {
      key: 'uploadData',
      iconName: V3UploadDataIcon,
      iconText: 'menuPopover.uploadData',
      permissions: [PERMISSIONS.ROLE_DEVICE_LINK],
      link: ['newDeviceLink', 'main'],
      onClick: 'showDtcModal',
    },
    {
      key: 'rpmProgram',
      iconName: V3RpmSettingsIcon,
      iconText: 'menuPopover.rpmProgram',
      permissions: [PERMISSIONS.RPM_SETTINGS_MANAGEMENT],
      link: ['rpm', 'rpmSettings'],
    },
    {
      key: 'timeVTrackingExport',
      iconName: V3ExportTimeTrackerIcon,
      iconSize: 40,
      iconText: 'menuPopover.timeTrackingExport',
      permissions: [PERMISSIONS.ROLE_TIME_TRACKING],
      onClick: 'showTimeTrackerExportModal',
    },
    {
      key: 'printAndDownload',
      iconName: PrintAndDownloadIcon,
      iconSize: 40,
      iconText: 'menuPopover.printAndDownload',
      permissions: [],
      isJelloComponent: true,
      onClick: 'showPrintAndDownloadModal',
    },
  ],
  bottom: [
    {
      key: 'info',
      iconName: V3InfoIcon,
      iconText: 'menuPopover.info',
      permissions: [],
      onClick: 'showManufacturerInfoModal',
    },
  ],
};

type MenuDropdownPopover = {
  onSignOut: () => void;
  show: boolean;
  routes: FixMe;
  showDtcModal: () => void;
  showTimeTrackerExportModal: () => void;
  showManufacturerInfoModal: () => void;
  showPrintAndDownloadModal: () => void;
  closeMenu: () => void;
  isJelloActivated: boolean;
};

type TopListItem = {
  key: string;
  iconName: any;
  iconSize?: number;
  iconText: string;
  permissions?: string[];
  onClick?: any;
  link?: string[];
  isEnabled?: boolean;
};

type ListItem = {
  permissions?: string[];
  id: string;
  link?: string;
  iconName: any;
  iconSize?: number;
  iconText: string;
  onClick?: any;
  closeMenu: () => void;
  isJelloComponent?: boolean;
  isEnabled?: boolean;
};

const ListItem: React.FunctionComponent<ListItem> = ({
  permissions = [PERMISSIONS.EDIT_PATIENT],
  id,
  link,
  iconName,
  iconText,
  iconSize,
  onClick,
  closeMenu,
  isJelloComponent,
  isEnabled,
}) => {
  const countlyHandleListItemLink = (linkValue) => {
    if (linkValue === '/patient/create') {
      countlyEventTrigger(EVENTS_KEYS.NEW_PATIENT_PROCESS_INITIATED, {});
    }
  };

  return id !== 'uploadData' ? (
    <WithPermissions hasPermissions={permissions}>
      <PopoverListItem
        key={`pma-${id}`}
        onClick={() => countlyHandleListItemLink(link)}
      >
        {!!link && (
          <PopoverListItemLink to={link} className={getActive(link)}>
            {drawIcon(
              iconName,
              iconSize,
              closeMenu,
              isJelloComponent,
              isEnabled,
            )}
          </PopoverListItemLink>
        )}
        {!link && (
          <PopoverListItemNoLink onClick={onClick}>
            {drawIcon(
              iconName,
              iconSize,
              closeMenu,
              isJelloComponent,
              isEnabled,
            )}
          </PopoverListItemNoLink>
        )}
        <PopoverListItemText>
          <LocalizedText textKey={iconText} />
        </PopoverListItemText>
      </PopoverListItem>
    </WithPermissions>
  ) : (
    <WithPermissions
      hasPermissions={permissions}
      onAccessDenied={() => (
        <PopoverListItem
          key={`pma-${id}`}
          onClick={() => countlyHandleListItemLink(link)}
        >
          <PopoverListItemNoLink onClick={onClick}>
            {drawIcon(
              iconName,
              iconSize,
              closeMenu,
              isJelloComponent,
              isEnabled,
            )}
          </PopoverListItemNoLink>
          <PopoverListItemText>
            <LocalizedText textKey={iconText} />
          </PopoverListItemText>
        </PopoverListItem>
      )}
    >
      <PopoverListItem
        key={`pma-${id}`}
        onClick={() => countlyHandleListItemLink(link)}
      >
        <PopoverListItemLink to={link} className={getActive(link)}>
          {drawIcon(iconName, iconSize, closeMenu, isJelloComponent, isEnabled)}
        </PopoverListItemLink>
        <PopoverListItemText>
          <LocalizedText textKey={iconText} />
        </PopoverListItemText>
      </PopoverListItem>
    </WithPermissions>
  );
};
ListItem.displayName = 'MenuDropdownPopoverListItem';

export const MenuDropdownPopover: React.FunctionComponent<
  MenuDropdownPopover
> = (props) => {
  const { top } = listIcons;

  const isBGDashboardTab = !!matchPath(location.pathname, {
    path: routes.patient.patientBGDashboard,
    exact: false,
    strict: false,
  });
  const iconsToBeRendered = verifyIconsVisibility(
    top,
    !!selectIsHCPOrGPOrAdminUserProfile,
    isBGDashboardTab,
    props.isJelloActivated,
  );
  return (
    <Popover pushLeft={3} width={17} show={props.show}>
      <PopoverList>
        {iconsToBeRendered.map((icon) => drawItem(props, icon))}
      </PopoverList>
    </Popover>
  );
};

const drawIcon = (
  IconName,
  iconSize,
  closeMenu,
  isJelloComponent = false,
  isEnabled = true,
) => (
  <IconContainer
    onClick={() => closeMenu()}
    isJelloComponent={isJelloComponent}
  >
    <IconName
      fillColor={colors.blue3}
      height={iconSize}
      strokeColor="#508ee5"
      isEnabled={isEnabled}
    />
  </IconContainer>
);

const getActive = (link) => {
  const location = pathOr(null, ['location', 'pathname'], window);
  return location === link ? 'selected' : '';
};
const drawItem = (props, icon) => {
  const {
    key,
    permissions,
    iconName,
    iconSize,
    iconText,
    onClick,
    link,
    isJelloComponent = false,
    isEnabled = true,
  } = icon;
  const { routes, closeMenu } = props;
  const _onClick = onClick ? props[onClick] : null;
  const _link = link ? routes[link[0]][link[1]] : null;
  return (
    <React.Fragment key={key}>
      <ListItem
        id={key}
        permissions={permissions}
        iconName={iconName}
        iconSize={iconSize}
        iconText={iconText}
        closeMenu={closeMenu}
        onClick={_onClick}
        link={_link}
        isJelloComponent={isJelloComponent}
        isEnabled={isEnabled}
      />
    </React.Fragment>
  );
};

export const verifyIconsVisibility = (
  iconsList: TopListItem[],
  isHCPorGPorAdmin: boolean,
  isBGDashboardTab: boolean,
  isJelloActivated: boolean,
) => {
  let iconVisibleList = iconsList;
  if (isHomeRoute() || !isHCPorGPorAdmin || isJelloActivated)
    iconVisibleList = iconsList.filter(
      (icon) => icon.key !== 'printAndDownload',
    );
  iconVisibleList.forEach((icon) => {
    if (icon.key === 'printAndDownload') icon.isEnabled = isBGDashboardTab;
  });
  return iconVisibleList;
};
