import * as React from 'react';
import { XIcon } from 'src/assets/icons';
import { Block, Button, LocalizedText } from 'src/components';
import { ButtonReset, colors } from 'src/core/styles';
import { translate } from 'src/i18n';
import { CommonHeader, ModalBody } from 'src/widgets/modal';

export const NextShipmentConfirmationModal = ({
  destroyModal,
  data: { onConfirmation, status },
}) => (
  <ModalBody>
    <CommonHeader>
      <strong>
        <LocalizedText fontSize={4} textKey="nextShipment.title" />
      </strong>
      <ButtonReset
        onClick={() => {
          destroyModal();
        }}
      >
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <Block py={2} px={6}>
      <Block p={4}>
        <Block pb={3} mb={4} display="flex" justifyContent="center">
          <strong>
            {status === '2' && (
              <LocalizedText textKey="nextShipment.suspendconf" />
            )}
            {status !== '2' && (
              <LocalizedText textKey="nextShipment.restoreconf" />
            )}
          </strong>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            mr={4}
            label={translate('modals.support.accept')}
            onClick={() => {
              onConfirmation();
            }}
          />
          <Button
            buttonStyle="secondary"
            label={translate('editPatient.cancel')}
            onClick={() => {
              destroyModal();
            }}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);
