import {
  createAuthHeader,
  putJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const SaveAlertsTransformImpl = (value) => value;

export const SaveAlertsLoaderImpl = (
  { patientId, alerts },
  accessToken,
  gigyaToken,
) =>
  putJSON(
    endpointWithParams(ENDPOINTS.alerts, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken)        
      },
      body: stringifyBody(alerts),
    },
  );

export const SaveAlertsServiceImpl =
  (put, transform) => (query, accessToken, gigyaToken) =>
    put(query, accessToken, gigyaToken).then(transform);
