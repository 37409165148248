import { createPayloadAction } from 'src/app/store/app.actions';
import {
  DeactivatePatientActionType,
  DeactivatePatientParamsPayloadType,
  DeactivatePatientStartAction,
} from './deactivate-patient.types';

export const deactivatePatientStart = (
  params: DeactivatePatientParamsPayloadType,
): DeactivatePatientStartAction =>
  createPayloadAction(
    DeactivatePatientActionType.DEACTIVATE_PATIENT_START,
    params,
  );
