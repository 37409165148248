/* eslint-disable no-debugger */
import { selectAccessToken, selectGigyaToken } from 'src/app/session';

import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  correlatePairingCodeSuccess,
  correlatePairingCodeError,
  correlatePairingCodeErrorConflict,
} from './correlate-pairing-code.actions';

import {
  CorrelatePairingCodeActionType,
  CorrelatePairingCodeStartAction,
  CorrelatePairingCodeSuccessPayload,
} from './correlate-pairing-code.types';
import { correlatePairingCodeLoaderImplType } from 'src/services/pairing-code/pairing-code-correlation/pairing-code-correlation.types';

export const correlatePairingCodeEpic: (
  correlatePairingCodeService: correlatePairingCodeLoaderImplType,
) => Epic<any, any> = (correlatePairingCodeService) => (action$, store$) =>
  action$
    .ofType(CorrelatePairingCodeActionType.CORRELATE_PAIRING_CODE_START)
    .flatMap(({ payload }: CorrelatePairingCodeStartAction) => {
      const accessToken = selectAccessToken(store$.getState());
      const apiKey = selectGigyaToken(store$.getState());
      return Observable.fromPromise(
        correlatePairingCodeService(
          accessToken,
          apiKey,
          payload.code,
          payload.fhirId,
        ),
      )
        .map((response: CorrelatePairingCodeSuccessPayload) => {
          return correlatePairingCodeSuccess(response);
        })
        .pipe(
          catchError((err) => {
            const { status } = err;
            return Observable.of(
              status === 409
                ? correlatePairingCodeErrorConflict(err)
                : correlatePairingCodeError(err),
            );
          }),
        );
    });
