import * as React from 'react';

import { styleInputText } from './input-text.style';

export const Input: React.FunctionComponent<any> = ({
  id,
  model,
  type,
  minWidth,
  ...inputProps
}) => <input id={id} model={model} type={type} {...inputProps} />;

export const InputTextComponent: React.FunctionComponent<any> = ({
  hasError,
  ...props
}) => <Input type="text" {...props} />;

export const InputText = styleInputText(InputTextComponent);
