import { Div } from 'src/components/div';

import { convertPxToRem } from 'src/utils';
import styled from 'styled-components';

export const USER_UNITS_SELECTOR_BREAK_POINT = 1300;

export const UserUnitsSelectorSection = styled(Div)``;
UserUnitsSelectorSection.displayName = 'UserUnitsSelectorSection';

export const UserUnitsSelectorWrapperHeader = styled(Div)`
  margin: 0 auto 2rem;
  width: 100%;
  border-bottom: solid 1px;
  border-bottom-color: var(--jello-color-brand-05);
  color: var(--jello-color-brand-03);
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
`;
UserUnitsSelectorWrapperHeader.displayName = 'UserUnitsSelectorWrapperHeader';

export const UserUnitsSelectorInfoContainerWrapper = styled(Div)`
  padding-left: 1rem;
`;
UserUnitsSelectorInfoContainerWrapper.displayName =
  'UserUnitsSelectorInfoContainerWrapper';

export const UserUnitsSelectorMainContainerWrapper = styled(Div)``;
UserUnitsSelectorInfoContainerWrapper.displayName =
  'UserUnitsSelectorMainContainerWrapper';

export const UserUnitsSelectorContainerDescWrapper = styled(Div)`
  margin: 0;
  padding-bottom: 24px;
  @media all and (max-width: ${convertPxToRem(
      USER_UNITS_SELECTOR_BREAK_POINT,
    )}) {
    min-height: inherit;
  }
`;
UserUnitsSelectorContainerDescWrapper.displayName =
  'UserUnitsSelectorContainerDescWrapper';

export const UserUnitsSelectorFormContainer = styled(Div)`
  width: 16rem;
`;
UserUnitsSelectorFormContainer.displayName = 'UserUnitsSelectorFormContainer';

export const FormContainer = styled(Div)`
  width: 100%;
  min-width: ${convertPxToRem(500)};
  margin-top: ${convertPxToRem(45)};
`;
FormContainer.displayName = 'FormContainer';

export const ButtonsContainer = styled(FormContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  & > button {
    margin-left: ${({ theme }) => theme.spacing.four};
  }
`;
ButtonsContainer.displayName = 'ButtonsContainer';
