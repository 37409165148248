import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

export const FactoryWhiteIconComponent = ({
  height = 26,
  fillColor = colors.white,
  strokeColor = colors.black,
  strokeWidth = '2',
  t,
  title = 'graphs.iconTitles.factory',
}) => {
  const originalWidth = 31;
  const originalHeight = 29;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      title={t(title)}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path
        d="M24.2 1V13.63l-3.988-5.886-3.745 5.886-3.988-5.886-3.746 5.886-3.987-5.886-3.716 6.062L1 27.364h29V1z"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export const FactoryWhiteIcon = withTranslation(FactoryWhiteIconComponent);
