import 'core-js/features/array/fill';
import { addIndex, filter, keys, pipe, reduce } from 'ramda';
import React from 'react';
import { DateTime } from 'luxon';

import {
  PumpPauseIcon,
  PumpPowerDownIcon,
  PumpPowerUpIcon,
  PumpProfileChangeIcon,
  PumpRunIcon,
  PumpStopIcon,
  PumpTbrdecIcon,
  PumpTbrEndDecIcon,
  PumpTbrEndIncIcon,
  PumpTbrincIcon,
} from 'src/assets/icons';
import { fixToDecimalPlace } from 'src/utils';
import {
  isTbrIncrease,
  isTbrDecrease,
} from 'src/domains/diagnostics/scenes/graphs/graph.util';

import {
  BASAL_REMARKS,
  BOLUS_TYPE_ICONS,
  PUMP_ICONS,
} from './logbook-diary.constant';
import {
  bloodGlucoseIconNamesByIndex,
  bloodGlucoseIconsByName,
} from './logbook-diary.icons';

export const basalRemarkMatchesRegexCondition = (basalRemark) => (condition) =>
  condition instanceof RegExp && condition.test(basalRemark);

export const splitBy = (groupSize, defaultValue) => (arr) => {
  if (!arr.length) {
    return [new Array(groupSize).fill(defaultValue)];
  }
  return arr.reduce((result, current, index, arr) => {
    const arrPosition = [Math.floor(index / groupSize)];
    const itemPosition = index % groupSize;
    if (!result[arrPosition]) {
      result[arrPosition] = new Array(groupSize).fill(defaultValue);
    }

    result[arrPosition][itemPosition] = current;

    return result;
  }, []);
};

export const getGlucoseIcons = (
  { value, manuallyEntered, hypoSymptoms, beforeMeal, afterMeal, events },
  hypoThreshold,
) => {
  const icons = {
    manuallyEntered,
    hypo: value && value < hypoThreshold,
    hypoSymptoms,
    beforeMeal,
    afterMeal,
  };

  const iconNames = pipe(filter(Boolean), keys)(icons);

  const eventIconNames = ((events || '').match(/(\d+)/g) || []).map(
    (eventIndex) => bloodGlucoseIconNamesByIndex[eventIndex],
  );

  return splitBy(8, undefined)([...iconNames, ...eventIconNames]);
};

export const getBolusTypeIcon = (measurement) => {
  const measurementBolusType = measurement.bolusType.toLowerCase();

  const bolusTypeIcons = {
    std: BOLUS_TYPE_ICONS.STANDARD,
    scr: BOLUS_TYPE_ICONS.QUICK,
    ext: BOLUS_TYPE_ICONS.EXTENDED,
    mul: BOLUS_TYPE_ICONS.MULTIWAVE,
  };

  return bolusTypeIcons[measurementBolusType];
};

export const getPumpIconIdentifier = (
  basalRemark,
  basalTbrdec,
  basalTbrinc,
  previousMeasurement,
) => {
  if (
    basalRemarkMatchesRegexCondition(basalRemark)(BASAL_REMARKS.CHANGED_PROFILE)
  ) {
    return 'changedProfile';
  } else if (basalRemark === 'Run') {
    return 'run';
  } else if (basalRemark === 'Stop') {
    return 'stop';
  } else if (
    basalRemark === 'TBR End' ||
    basalRemark === 'TBR End (cancelled)'
  ) {
    if (isTbrIncrease(previousMeasurement)) {
      return 'tbrEndInc';
    } else if (isTbrDecrease(previousMeasurement)) {
      return 'tbrEndDec';
    }
  } else if (basalRemark === 'power up') {
    return 'powerUp';
  } else if (basalRemark === 'power down') {
    return 'powerDown';
  } else if (
    basalRemark === 'pause on / pause off' ||
    basalRemark === 'Pause'
  ) {
    return 'pauseOnPauseOff';
  } else if (basalTbrdec) {
    return 'tbrdec';
  } else if (basalTbrinc) {
    return 'tbrinc';
  }
};

export const getPumpIcon = (
  { basalRemark, basalTbrdec, basalTbrinc },
  previousMeasurement,
) => {
  const pumpIconIdentifier = getPumpIconIdentifier(
    basalRemark,
    basalTbrdec,
    basalTbrinc,
    previousMeasurement,
  );

  const pumpIcons = {
    tbrinc: PUMP_ICONS.TBR_INC,
    tbrdec: PUMP_ICONS.TBR_DEC,
    changedProfile: PUMP_ICONS.CHANGED_PROFILE,
    run: PUMP_ICONS.RUN,
    stop: PUMP_ICONS.STOP,
    tbrEndInc: PUMP_ICONS.TBR_END_INC,
    tbrEndDec: PUMP_ICONS.TBR_END_DEC,
    powerUp: PUMP_ICONS.POWER_UP,
    powerDown: PUMP_ICONS.POWER_DOWN,
    pauseOnPauseOff: PUMP_ICONS.PAUSE,
  };

  return pumpIcons[pumpIconIdentifier];
};

export const getTextBeforePumpIcon = ({ basalRemark }) => {
  if (
    basalRemarkMatchesRegexCondition(basalRemark)(BASAL_REMARKS.CHANGED_PROFILE)
  ) {
    return basalRemark[0];
  }
};

export const getTextAfterPumpIcon = ({
  basalRemark,
  basalTbrdec,
  basalTbrinc,
}) => {
  if (
    basalRemarkMatchesRegexCondition(basalRemark)(BASAL_REMARKS.CHANGED_PROFILE)
  ) {
    return basalRemark[4];
  } else if (basalTbrinc) {
    return basalTbrinc;
  } else if (basalTbrdec) {
    return basalTbrdec;
  }
};

export const reduceIndexed = addIndex(reduce);

export const getBolusType1Value = (measurement) =>
  measurement.bolusValue != null
    ? fixToDecimalPlace(measurement.bolusValue, 2)
    : measurement.insulin1
    ? fixToDecimalPlace(measurement.insulin1, 2)
    : undefined;

export const getBolusType2Value = (measurement) =>
  measurement.insulin2 ? fixToDecimalPlace(measurement.insulin2, 2) : undefined;

export const getBolusType3Value = (measurement) =>
  measurement.insulin3 ? fixToDecimalPlace(measurement.insulin3, 2) : undefined;

export const renderPumpIconType = (icon) => (t) => {
  if (icon === PUMP_ICONS.TBR_INC) {
    return <PumpTbrincIcon width={17} isGraphIcon={false} />;
  } else if (icon === PUMP_ICONS.TBR_DEC) {
    return <PumpTbrdecIcon width={17} isGraphIcon={false} />;
  } else if (icon === PUMP_ICONS.CHANGED_PROFILE) {
    return <PumpProfileChangeIcon width={16} />;
  } else if (icon === PUMP_ICONS.RUN) {
    return (
      <PumpRunIcon
        title={t('graphs.iconTitles.pumpRun')}
        width={17}
        isGraphIcon={false}
      />
    );
  } else if (icon === PUMP_ICONS.STOP) {
    return (
      <PumpStopIcon
        title={t('graphs.iconTitles.pumpStop')}
        width={17}
        isGraphIcon={false}
      />
    );
  } else if (icon === PUMP_ICONS.TBR_END_INC) {
    return <PumpTbrEndIncIcon width={19} isGraphIcon={false} />;
  } else if (icon === PUMP_ICONS.TBR_END_DEC) {
    return <PumpTbrEndDecIcon width={19} isGraphIcon={false} />;
  } else if (icon === PUMP_ICONS.POWER_UP) {
    return <PumpPowerUpIcon width={17} />;
  } else if (icon === PUMP_ICONS.POWER_DOWN) {
    return <PumpPowerDownIcon width={16} />;
  } else if (icon === PUMP_ICONS.PAUSE) {
    return (
      <PumpPauseIcon
        title={t('graphs.iconTitles.pumpPause')}
        width={17}
        isGraphIcon={false}
      />
    );
  }
};

export const flat = (arr) =>
  arr.reduce((arr, result) => arr.concat(result), []).filter(Boolean);

export const renderBloodGlucoseIconType = (icon) =>
  bloodGlucoseIconsByName[icon];

export const transformDataFromHours = (hour, is12hourTimeFormat) =>
  is12hourTimeFormat
    ? DateTime.fromFormat(hour, 'HH:mm')
        .toFormat('hh:mm a', { locale: 'us' })
        .toUpperCase()
    : DateTime.fromFormat(hour, 'HH:mm').toFormat('HH:mm');
