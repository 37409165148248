import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { mapDispatchers } from 'src/utils';

import { ChangePasswordConfirmationComponent } from './change-password-confirmation.component';
import { ChangePasswordConfirmationModalProps } from './change-password-confirmation.types';
import { startSignoutAction } from 'src/core/login-system-error/login-system-error.action';

const dispatchers = mapDispatchers({
  onSignout: startSignoutAction,
});

export const ChangePasswordConfirmationModal = compose<
  ChangePasswordConfirmationModalProps,
  {}
>(
  connect(null, dispatchers),
  withTranslation,
)(ChangePasswordConfirmationComponent);
