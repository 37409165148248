import React from 'react';

import { SVGContainer } from './legend-item.style';

import {
  CheckboxContainer,
  LeftLabelContainer,
} from '../../checkbox/checkbox.style';
import { Checkbox } from '../../checkbox';

export const CheckboxContainerComponent = ({
  modelPath,
  id,
  icon,
  t,
  graph,
  graphType,
  expandedGraph,
}) => (
  <CheckboxContainer key={modelPath}>
    <Checkbox
      id={t(id)}
      label={
        <LeftLabelContainer>
          <SVGContainer>{icon}</SVGContainer>
          {t(id)}
        </LeftLabelContainer>
      }
      labelBeforeCheckbox
      modelPath={modelPath}
      size={20}
      graph={graph}
      graphType={graphType}
      expandedGraph={expandedGraph}
    />
  </CheckboxContainer>
);
