import React from 'react';

import { Button, TableAltRow } from 'src/components';

import { ResultCell } from './search-results.style';

export const PatientRow = ({
  fullName,
  id,
  healthCareSystemId,
  birthDate,
  diabetesType,
  onHandle,
  isHcpOrGp,
  fhirId,
  t,
}) => (
  <TableAltRow>
    <ResultCell>{fullName}</ResultCell>
    <ResultCell>{healthCareSystemId}</ResultCell>
    <ResultCell>{birthDate ? birthDate : '-'}</ResultCell>
    <ResultCell>{t(`patientSearch.${diabetesType}`)}</ResultCell>
    <ResultCell align="right">
      <Button
        p={2}
        id={id}
        label={t('patientSearch.table.userInfo')}
        onClick={() => onHandle(id, fhirId, isHcpOrGp)}
        t={t}
      />
    </ResultCell>
  </TableAltRow>
);
