import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { selectAccessToken, selectGigyaToken } from 'src/app/session';

import { State } from 'src/app/store/app.types';
import {
  fetchHba1cListSuccess,
  fetchHba1cListError,
} from './list-values-hba1c.actions';
import { GetHba1cListServiceFnType } from 'src/services/';
import {
  FetchHba1cListStartAction,
  ListHba1cActionActions,
  ListHba1cActionType,
} from './list-values-hba1c.types';

export const fetchHba1cListEpics: (
  GetHba1cList: GetHba1cListServiceFnType,
) => Epic<ListHba1cActionActions, State> = (GetHba1cList) => (action$, store) =>
  action$
    .ofType(ListHba1cActionType.FETCH_HBA1C_LIST_START)
    .switchMap(({ payload }: FetchHba1cListStartAction) => {
      const accessToken = selectAccessToken(store.getState());
      const apiKey = selectGigyaToken(store.getState());
      return Observable.fromPromise(
        GetHba1cList(accessToken, apiKey, payload.fhirId),
      )
        .map((hba1cList) => fetchHba1cListSuccess(hba1cList))
        .pipe(catchError(() => Observable.of(fetchHba1cListError())));
    });
