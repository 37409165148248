import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { selectAccessToken, selectGigyaToken } from 'src/app/session';

import { State } from 'src/app/store/app.types';
import { createModal, MODAL_TYPES } from 'src/core';
import { DeliveryConfigurationErrorModal } from 'src/domains/patient/scenes/delivery-configuration/components';
import { DeliveryConfigurationSuccessModal } from 'src/domains/patient/scenes/delivery-configuration/components/delivery-configuration-success-modal';
import { Program } from 'src/domains/patient/scenes/delivery-configuration/delivery-configuration.types';

import {
  GetDeliveryConfigurationServiceFnType,
  GetPatientDeliveryConfigurationServiceFnType,
} from 'src/services/patient/delivery-configuration';
import {
  FetchDeliveryConfigurationStartAction,
  DeliveryConfigurationActions,
  DeliveryConfigurationActionType,
} from './delivery-configuration.types';
import {
  fetchDeliveryConfigurationSuccess,
  fetchDeliveryConfigurationError,
  saveDeliveryConfigurationError,
  saveDeliveryConfigurationSuccess,
  getPatientProgramSuccess,
} from './delivery-configuration.actions';

export const fetchDeliveryConfigurationEpic: (
  GetDeliveryConfiguration: GetDeliveryConfigurationServiceFnType,
  GetPatientDeliveryConfiguration: GetPatientDeliveryConfigurationServiceFnType,
) => Epic<DeliveryConfigurationActions, State> = (
  GetDeliveryConfiguration,
  GetPatientDeliveryConfiguration,
) => {
  return (action$, store) => {
    return action$
      .ofType(
        DeliveryConfigurationActionType.FETCH_DELIVERY_CONFIGURATION_START,
      )
      .switchMap(({ payload }: FetchDeliveryConfigurationStartAction) => {
        const accessToken = selectAccessToken(store.getState());
        const apiKey = selectGigyaToken(store.getState());
        return Observable.fromPromise(
          GetDeliveryConfiguration(accessToken, apiKey, payload),
        )
          .switchMap((defaultPrograms) => {
            return Observable.fromPromise(
              GetPatientDeliveryConfiguration(accessToken, apiKey, payload),
            )
              .mergeMap((patientProgram) => {
                return [
                  getPatientProgramSuccess(patientProgram),
                  fetchDeliveryConfigurationSuccess(defaultPrograms),
                ];
              })
              .pipe(
                catchError(() =>
                  Observable.of(
                    fetchDeliveryConfigurationSuccess(defaultPrograms),
                  ),
                ),
              );
          })
          .pipe(
            catchError(() => Observable.of(fetchDeliveryConfigurationError())),
          );
      });
  };
};

export const saveDeliveryConfigurationEpic: (
  service: FixMe,
) => Epic<FixMe, State> = (service) => {
  return (action$, store) => {
    return action$
      .ofType(DeliveryConfigurationActionType.SAVE_DELIVERY_CONFIGURATION_START)
      .switchMap(({ payload }: FixMe) => {
        const accessToken = selectAccessToken(store.getState());
        const apiKey = selectGigyaToken(store.getState());
        return Observable.fromPromise(
          service(accessToken, apiKey, payload.patientId, payload.program),
        )
          .mergeMap((program) => [
            saveDeliveryConfigurationSuccess(program as Program[]),
            createModal({
              key: MODAL_TYPES.CUSTOM,
              data: {
                customComponent: DeliveryConfigurationSuccessModal,
              },
            }),
          ])
          .pipe(
            // tslint:disable
            catchError(() => {
              return Observable.concat(
                Observable.of(saveDeliveryConfigurationError()),
                Observable.of(
                  createModal({
                    key: MODAL_TYPES.CUSTOM,
                    data: {
                      customComponent: DeliveryConfigurationErrorModal,
                    },
                  }),
                ),
              );
            }),
          );
      });
  };
};
