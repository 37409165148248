import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';
import { createAuthHeader, postJSON } from 'src/utils/service/service.utils';

import {
  ChangePasswordLoaderImplType,
  ChangePasswordServiceImplType,
} from './change-password.types';

export const changePasswordLoaderImpl: ChangePasswordLoaderImplType = (
  accesToken,
  accountId,
  password,
  newPassword,
  newPasswordConfirmation,
) =>
  postJSON(endpointWithParams(ENDPOINTS.changePassword, { accountId }), {
    headers: {
      Authorization: createAuthHeader(accesToken),
    },
    body: {
      password,
      newPassword,
      newPasswordConfirmation,
    } as FixMe,
  });

export const changePasswordTransformImpl = (result) => result;

export const changePasswordServiceImpl: ChangePasswordServiceImplType =
  (load: ChangePasswordLoaderImplType, transform) =>
  (accesToken, accountId, password, newPassword, newPasswordConfirmation) =>
    load(
      accesToken,
      accountId,
      password,
      newPassword,
      newPasswordConfirmation,
    ).then(transform);
