/* tslint:disable */

import * as React from 'react';
import { DropdownCalendar } from 'src/components/dropdown-select/dropdown-calendar';
import { FetchStatus } from 'src/core/device-settings-list/device-settings-list.types';
import { DropdownSelect } from 'src/components/dropdown-select';
import { isEmpty } from 'ramda';

import { theme } from 'src/theme';
import { convertStringToJSDate } from 'src/utils/date';
import { RenderIf } from 'src/utils';

import { CardDeviceSettingWrapper } from './device-settings.style';
import {
  TitleDeviceSettings,
  HeaderSubRowDeviceSettings,
  HeaderRowDeviceSettings,
  HeaderItemDeviceSettings,
  HeaderTextDeviceSettings,
  SeparatorDeviceSettings,
  HeaderColLeftDeviceSettings,
  HeaderColRightDeviceSettings,
  HeaderSubColumnDeviceSettings,
  LegendContainer,
  HeaderLegendContainer,
} from './components/device-settings-header';
import {
  DeviceSettingsProps,
  DeviceSettingsState,
} from './device-settings.types';
import { TabsData } from './components/tabs/tabs.types';
import {
  getDropdownPlaceholderMessage,
  getFirstItemLabel,
  doesPreviousSettingExists,
} from './device-settings.utils';
import { Tabs } from './components/tabs';
import { TabBasalBolusContainer } from './components/tab-basal-bolus/tab-basal-bolus.container';
import { TabGeneralContainer } from './components/tab-general/tab-general.container';
import { TabReminderContainer } from './components/tab-reminders/tab-reminders.container';
import { ToggleSwitchPrimary } from './components/toggle-switch-primary/toggle-switch-primary.component';

import { SIZE } from '../../components/no-data-disclaimer';
import { withGraphLoader } from '../../utils/with-graph-loader';
import { GraphControls } from '../../components/graph-controls';
import {
  GRAPHS,
  DEVICE_SETTINGS_TYPE_BASAL_BOLUS,
  DEVICE_SETTINGS_TYPE_GENERAL,
  DEVICE_SETTINGS_TYPE_REMINDERS,
} from '../graphs/graph.constants';
import {
  DropdownLightLabel,
  LabelledDropdownContainerDiv,
} from 'src/components/dropdown/labelled-dropdown/labelled-dropdown.style';

export class DeviceSettingsComponent extends React.Component<
  DeviceSettingsProps,
  DeviceSettingsState
> {
  constructor(props) {
    super(props);

    this.state = {
      tabSelected: DEVICE_SETTINGS_TYPE_BASAL_BOLUS,
      selectedDeviceId: {},
      selectedReportId: {},
      id: this.props.match.params.id,
      isLowResolution: false,
      isPreviousSettingsSelected: false,
    };
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillReceiveProps(newProps) {
    if (
      isEmpty(this.state.selectedDeviceId) &&
      !isEmpty(newProps.patientDevices)
    ) {
      this.setState({ selectedDeviceId: newProps.patientDevices[0] });
    }

    if (
      newProps.patientDevices !== this.props.patientDevices &&
      !isEmpty(newProps.patientDevices)
    ) {
      this.setState({ selectedDeviceId: newProps.patientDevices[0] });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  render() {
    const {
      patientDevices = [],
      patientDevicesStatus,
      deviceSettingsList = [],
      deviceSettingsListStatus,
      previousDeviceSettings,
      collapsed,
      t,
      graph,
    }: any = this.props;
    const transformedList = transformDeviceSettingsList(deviceSettingsList);
    const devicesStatusMessage = getDropdownPlaceholderMessage(
      patientDevicesStatus,
      patientDevices,
    );
    const legendContainer =
      collapsed && this.state.isLowResolution
        ? 'legendContainer2'
        : 'legendContainer';
    const DeviceSettingsWithLoader = withGraphLoader(
      CardDeviceSettingWrapper,
      'general.loading',
      { size: SIZE.MEDIUM },
    );

    const dataTabs: TabsData[] = [
      {
        key: DEVICE_SETTINGS_TYPE_BASAL_BOLUS,
        label: t('deviceSettings.container.basalBolusTab'),
      },
      {
        key: DEVICE_SETTINGS_TYPE_GENERAL,
        label: t('deviceSettings.container.generalTab'),
      },
      {
        key: DEVICE_SETTINGS_TYPE_REMINDERS,
        label: t('deviceSettings.container.remindersTab'),
      },
    ];
    return (
      <>
        <RenderIf validate={!isLoading(this.props)}>
          <HeaderRowDeviceSettings
            alignItems={collapsed ? 'flex-end' : 'center'}
            expanded={collapsed ? false : true}
          >
            <HeaderColLeftDeviceSettings expanded={collapsed ? false : true}>
              <HeaderItemDeviceSettings
                grid={12}
                alignSelf={!collapsed ? 'center' : ''}
              >
                <RenderIf validate={!collapsed}>
                  <TitleDeviceSettings>
                    {t('deviceSettings.container.deviceSettings')}
                  </TitleDeviceSettings>
                </RenderIf>
                <RenderIf validate={collapsed}>
                  <Tabs
                    data={dataTabs}
                    onChange={this.handleOnTabChange}
                    active={this.state.tabSelected}
                  />
                </RenderIf>
              </HeaderItemDeviceSettings>
            </HeaderColLeftDeviceSettings>
            <HeaderColRightDeviceSettings expanded={collapsed ? false : true}>
              <HeaderItemDeviceSettings>
                <LabelledDropdownContainerDiv style={{ width: '100%' }}>
                  <DropdownLightLabel htmlFor={'.period'}>
                    {t('deviceSettings.container.selectDevices')}
                  </DropdownLightLabel>
                  <DropdownSelect
                    width={'100%'}
                    options={patientDevices}
                    showArrow={true}
                    arrowIconHeight={8}
                    fillColor={theme.colors.grayDark}
                    placeholder={
                      devicesStatusMessage || getFirstItemLabel(patientDevices)
                    }
                    selectedOption={this.state.selectedDeviceId}
                    onChange={this.handleOnSelectDevice}
                  />
                </LabelledDropdownContainerDiv>
              </HeaderItemDeviceSettings>
              <SeparatorDeviceSettings />
              <HeaderItemDeviceSettings>
                <LabelledDropdownContainerDiv>
                  <DropdownLightLabel htmlFor={'.period'}>
                    {t('deviceSettings.container.deviceSettingsUploaded')}
                  </DropdownLightLabel>
                  <DropdownCalendar
                    data={transformedList}
                    placeholder={getStatusMessageByResponseType(
                      deviceSettingsListStatus,
                      transformedList,
                      t,
                    )}
                    selectedOption={this.state.selectedReportId}
                    onChange={this.handleOnSelectDeviceReport}
                  />
                </LabelledDropdownContainerDiv>
              </HeaderItemDeviceSettings>
              <SeparatorDeviceSettings />
              <HeaderLegendContainer>
                <GraphControls
                  marginLeft={'0px'}
                  margin={false}
                  graphType={GRAPHS.DEVICE_SETTINGS}
                  portalContainer={legendContainer}
                  graph={graph}
                />
              </HeaderLegendContainer>
            </HeaderColRightDeviceSettings>
            <RenderIf validate={collapsed}>
              <LegendContainer id="legendContainer2"></LegendContainer>
            </RenderIf>
          </HeaderRowDeviceSettings>
          <div id="legendContainer"></div>
          <RenderIf validate={!collapsed}>
            <HeaderSubRowDeviceSettings alignItems={'center'}>
              <HeaderSubColumnDeviceSettings width={'30%'}>
                <HeaderItemDeviceSettings
                  grid={12}
                  alignSelf={!collapsed ? 'center' : ''}
                >
                  <Tabs
                    data={dataTabs}
                    onChange={this.handleOnTabChange}
                    active={this.state.tabSelected}
                  />
                </HeaderItemDeviceSettings>
              </HeaderSubColumnDeviceSettings>
              <HeaderSubColumnDeviceSettings
                alignItems={'center'}
                justifyContainer={'flex-end'}
                width={'70%'}
              >
                <HeaderItemDeviceSettings style={{ padding: 0 }}>
                  <RenderIf
                    validate={doesPreviousSettingExists(
                      deviceSettingsList,
                      previousDeviceSettings,
                    )}
                  >
                    <HeaderTextDeviceSettings>
                      {t('deviceSettings.container.showChangePrev')}
                    </HeaderTextDeviceSettings>
                  </RenderIf>
                  <RenderIf
                    validate={
                      !doesPreviousSettingExists(
                        deviceSettingsList,
                        previousDeviceSettings,
                      )
                    }
                  >
                    <HeaderTextDeviceSettings>
                      {t('graphs.deviceSettings.noSetting')}
                    </HeaderTextDeviceSettings>
                  </RenderIf>
                </HeaderItemDeviceSettings>
                <HeaderItemDeviceSettings>
                  <ToggleSwitchPrimary
                    handleToggleChange={this.handleToggleChange}
                    currentValue={this.state.isPreviousSettingsSelected}
                    disabled={
                      !doesPreviousSettingExists(
                        deviceSettingsList,
                        previousDeviceSettings,
                      )
                    }
                  />
                </HeaderItemDeviceSettings>
              </HeaderSubColumnDeviceSettings>
            </HeaderSubRowDeviceSettings>
          </RenderIf>
        </RenderIf>
        <DeviceSettingsWithLoader
          isLoading={isLoading(this.props)}
          hasError={isError(this.props) && !isLoading(this.props)}
        >
          <hr style={{ border: '0.5px solid #eaeaea', margin: '20px 0' }} />
          <RenderIf
            validate={
              this.state.tabSelected === DEVICE_SETTINGS_TYPE_BASAL_BOLUS
            }
          >
            <TabBasalBolusContainer
              someProp={deviceSettingsList}
              isPreviousSettingsSelected={this.state.isPreviousSettingsSelected}
              isCollapsedView={collapsed}
            />
          </RenderIf>
          <RenderIf
            validate={this.state.tabSelected === DEVICE_SETTINGS_TYPE_GENERAL}
          >
            <TabGeneralContainer
              someProp={deviceSettingsList}
              comparePreviousSettings={collapsed ? false : true}
              isPreviousSettingsSelected={this.state.isPreviousSettingsSelected}
            />
          </RenderIf>
          <RenderIf
            validate={this.state.tabSelected === DEVICE_SETTINGS_TYPE_REMINDERS}
          >
            <TabReminderContainer
              someProp={deviceSettingsList}
              comparePreviousSettings={collapsed ? false : true}
              isPreviousSettingsSelected={this.state.isPreviousSettingsSelected}
            />
          </RenderIf>
        </DeviceSettingsWithLoader>
      </>
    );
  }

  handleWindowResize = () => {
    this.setState({ isLowResolution: window.outerWidth <= 1024 });
  };

  handleOnTabChange = (tab) =>
    this.setState({
      tabSelected: tab.key,
    });

  handleOnSelectDevice = (data) => {
    if (this.state.selectedDeviceId !== data) {
      this.setState({ selectedDeviceId: data, selectedReportId: -1 });
      this.props.fetchDeviceSettingsList({
        deviceSerialNumber: data.value,
        patientId: this.state.id,
        patientType: 'PWD',
      });
    }
  };

  handleOnSelectDeviceReport = (data) => {
    if (this.state.selectedReportId.value !== data.value) {
      this.setState({ selectedReportId: data });
      this.props.fetchDeviceSettings({
        deviceSerialNumber: this.state.selectedDeviceId.value,
        patientId: this.state.id,
        patientType: 'PWD',
        includePrevious: true,
        version: data.value,
      });
    }
  };

  handleToggleChange = (isChecked) => {
    this.setState({ isPreviousSettingsSelected: isChecked });
  };
}

const transformDeviceSettingsList = (deviceSettingsList) =>
  deviceSettingsList.map((setting, i) => ({
    value: setting.version,
    data: {
      date: convertStringToJSDate(setting.timestamp),
    },
  }));

const getStatusMessageByResponseType = (
  deviceSettingsListStatus: FetchStatus,
  deviceSettingsList: any,
  t: any,
) => {
  switch (deviceSettingsListStatus) {
    case FetchStatus.LOADING:
      return t('deviceSettings.container.loadingDevices');
    case FetchStatus.FAILURE:
      return t('deviceSettings.container.failLoadingDevices');
    case FetchStatus.SUCCESS:
      return deviceSettingsList.length > 0
        ? ''
        : t('deviceSettings.container.noDataAvailable');
    default:
      return t('deviceSettings.container.loadingDevices');
  }
};

const isLoading = (props) => {
  const {
    deviceSettingsStatus,
    deviceSettingsListStatus,
    deviceSettingsStatusAll,
  } = props;

  if (
    deviceSettingsStatus === FetchStatus.FAILURE ||
    deviceSettingsListStatus === FetchStatus.FAILURE ||
    deviceSettingsStatusAll === FetchStatus.FAILURE
  ) {
    return false;
  }

  if (
    deviceSettingsListStatus !== FetchStatus.SUCCESS ||
    deviceSettingsStatusAll !== FetchStatus.SUCCESS
  ) {
    return true;
  }

  return false;
};

const isError = ({
  deviceSettingsListStatus,
  patientDevicesStatus,
  patientDevices,
  deviceSettingsStatus,
  deviceSettingsStatusAll,
  deviceSettingsList,
}) => {
  if (
    deviceSettingsListStatus === FetchStatus.FAILURE ||
    deviceSettingsStatusAll === FetchStatus.FAILURE ||
    patientDevicesStatus === FetchStatus.FAILURE ||
    deviceSettingsStatus === FetchStatus.FAILURE
  ) {
    return true;
  }

  if (
    (deviceSettingsListStatus !== FetchStatus.LOADING &&
      patientDevicesStatus !== FetchStatus.LOADING &&
      // deviceSettingsStatus !== FetchStatus.LOADING &&
      isEmpty(patientDevices)) ||
    isEmpty(deviceSettingsList)
  ) {
    return true;
  }

  return false;
};
