// @ts-nocheck
import * as React from 'react';
import {
  DoneButtonWrapper,
  DoneButton,
  NavButtonContainer,
} from './calendar.style';
import {
  V3CalendarIcon,
  V3ForwardIcon,
  V3BackIcon,
  ArrowIcon,
} from 'src/assets/icons';
import { DateRangePicker } from 'react-dates';
import { withTranslation as translateHOC } from 'react-i18next';
import { getClassName, noMeasurements, getDateFormat } from './calendar.utils';
import { t } from 'i18next';
import * as C from './calendar.constants';
import { throttle } from 'lodash';
import { breakpointsMap } from 'src/core';
import { countlyEventTrigger } from 'src/navigation/countly';
import {
  EVENTS_KEYS,
  SEGMENT_KEYS,
  SEGMENT_VALUES,
} from '../period-selectors.countly.constants';
import { JelloIconButton } from 'src/components/jello-icon-button';
import { JelloIcon } from 'src/components/jello-icon';
import styles from './styles.module.css';
import {
  JELLO_HEADINGS_SIZES,
  JELLO_HEADINGS_LEVELS,
  JELLO_HEADINGS_WEIGHTS,
  JELLO_ICON_NAMES,
  JELLO_ICON_SIZES,
} from 'src/app/app.jello.constants';

type CalendarProps = {
  maxDateRange?: any;
  locale?: string;
  disabled?: string;
  range: number;
  wrapWidth?: number;
  showArrows?: boolean;
  className?: string;
  cgmSelectedDate?: any;
  id?: string;
  dateInfo: {
    locale: string;
    startDate: string;
    endDate: string;
    firstMeasurementDate: string;
    lastMeasurementDate: string;
  };
  onDatesChange: (
    startDate: moment.Moment,
    endDate: moment.Moment,
    range: any,
  ) => void;
  isPatternActive: boolean;
  isBgActive?: boolean;
  isCgmActive?: boolean;
  displayCal: boolean;
};
type CalendarState = {
  range: number;
  originalStartDate: any;
  originalEndDate: any;
  startDate: any;
  endDate: any;
  minDate: any;
  maxDate: any;
  focusedInput: any;
  isCustom: boolean;
  wrapWidth: number;
};

export class CalendarClass extends React.Component<
  CalendarProps,
  CalendarState
> {
  public moment = require('moment');
  public state = {
    range: 14,
    originalStartDate: this.moment(),
    originalEndDate: this.moment(),
    wrapWidth: window.outerWidth,
    startDate: this.moment(),
    endDate: this.moment(),
    minDate: this.moment(),
    maxDate: this.moment(),
    focusedInput: null,
    isCustom: false,
  };
  private onResize = throttle(() => {
    this.handleResize();
  }, 300);
  public constructor(props) {
    super(props);
    this.moment.updateLocale(this.moment.locale(props.locale), {
      invalidDate: C.NO_DATA,
    });
  }
  public componentWillReceiveProps(nextProps) {
    const nDateInfo = nextProps.dateInfo;
    const oDateInfo = this.props.dateInfo;
    const nDisabled = nextProps.disabled;
    const oDisabled = this.props.disabled;
    if (
      JSON.stringify(nDateInfo) !== JSON.stringify(oDateInfo) ||
      nDisabled !== oDisabled
    ) {
      this.initState(nextProps);
    }
  }
  public componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }
  public componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
  public render() {
    const { startDate, endDate, focusedInput } = this.state;
    const {
      wrapWidth,
      dateInfo,
      disabled,
      showArrows = true,
      className,
      id,
      isCgmActive,
      cgmSelectedDate,
    } = this.props;
    const isCgmAndDisabled = isCgmActive && disabled;

    const drawButton = (way) => (
      <jello-col style={{ padding: 0 }}>
        <button
          onClick={() => this.onChangePeriod(way)}
          className={styles.calendarNavBtn}
          disabled={disabled}
        >
          {way === C.PREV && (
            <JelloIconButton
              size={JELLO_ICON_SIZES.M}
              iconName={JELLO_ICON_NAMES['CHEVRON--LEFT']}
              variant="secondary"
              disabled={disabled}
            />
          )}
          {way === C.NEXT && (
            <JelloIconButton
              size={JELLO_ICON_SIZES.M}
              iconName={JELLO_ICON_NAMES['CHEVRON--RIGHT']}
              variant="secondary"
              disabled={disabled}
            />
          )}
        </button>
      </jello-col>
    );
    const drawNavButton = (way) => {
      return (
        <NavButtonContainer className={`navBtn${way}`}>
          <ArrowIcon height={8} />
        </NavButtonContainer>
      );
    };
    const renderPresets = (t, handleClick) => {
      return (
        <React.Fragment>
          <DoneButtonWrapper>
            <DoneButton onClick={handleClick}>
              {t('datePicker.done')}
            </DoneButton>
          </DoneButtonWrapper>
        </React.Fragment>
      );
    };
    const breakPoint = breakpointsMap[1];
    return (
      <jello-grid className={className}>
        <jello-row>
          <jello-col xs-auto>
            <div
              className={
                disabled
                  ? `${styles.calendarComponent} ${styles.calendarComponentDisabled}`
                  : styles.calendarComponent
              }
            >
              <button
                disabled={disabled}
                onClick={() => this.shoWCalendar()}
                className={`${styles.calendarIconBtn} ${
                  disabled ? 'disabled' : ''
                }`}
              >
                <JelloIcon
                  size={JELLO_ICON_SIZES.XS}
                  iconName={JELLO_ICON_NAMES.CALENDAR}
                  color={
                    disabled
                      ? 'var(--jello-color-brand-09)'
                      : 'var(--jello-color-brand-02)'
                  }
                />
              </button>
              <DateRangePicker
                id={id}
                customArrowIcon={
                  <JelloIcon
                    size={JELLO_ICON_SIZES.XS}
                    iconName={'subtract--tiny'}
                    color={
                      disabled
                        ? 'var(--jello-color-brand-09)'
                        : 'var(--jello-color-brand-02)'
                    }
                  />
                }
                minimumNights={0}
                startDatePlaceholderText={C.NO_DATA}
                endDatePlaceholderText={C.NO_DATA}
                keepOpenOnDateSelect={true}
                horizontalMargin={C.HORIZONAL_MARGIN}
                daySize={C.DAY_SIZE}
                displayFormat={C.DATE_FORMAT}
                readOnly={true}
                noBorder={true}
                navPrev={drawNavButton(C.PREV)}
                navNext={drawNavButton(C.NEXT)}
                disabled={disabled}
                startDate={this.getDate(
                  disabled,
                  startDate,
                  isCgmActive,
                  cgmSelectedDate,
                )}
                startDateId={C.START_DATE_ID}
                endDate={this.getDate(
                  disabled,
                  endDate,
                  isCgmActive,
                  cgmSelectedDate,
                )}
                endDateId={C.END_DATE_ID}
                onDatesChange={({ startDate, endDate }) =>
                  this.handleDatesChange({ startDate, endDate })
                }
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) =>
                  this.handleFocusChange(focusedInput)
                }
                renderCalendarInfo={() =>
                  renderPresets(t, () => {
                    this.onChangeDates();
                    this.onHideCalendar();
                  })
                }
                isOutsideRange={(d) =>
                  noMeasurements(
                    dateInfo.firstMeasurementDate,
                    dateInfo.lastMeasurementDate,
                    d,
                  )
                }
              />
            </div>
          </jello-col>
          <jello-col>
            <jello-grid id="buttons">
              <jello-row>
                {showArrows ? drawButton(C.PREV) : ''}
                {showArrows ? drawButton(C.NEXT) : ''}
              </jello-row>
            </jello-grid>
          </jello-col>
        </jello-row>
      </jello-grid>
    );
  }
  private initState = (nextProps) => {
    const {
      dateInfo,
      range,
      isCgmActive,
      isPatternActive,
      onDatesChange,
      displayCal,
      disabled,
    } = nextProps;

    const { firstMeasurementDate, lastMeasurementDate, startDate, endDate } =
      dateInfo;

    if (disabled && !isCgmActive) {
      this.setState({
        startDate: null,
        endDate: null,
      });
      return;
    }

    let focus: any = null;
    const getDate = (d) =>
      typeof d === 'string' ? this.moment(d.split('T')[0]) : d;
    const sDate = getDate(startDate);
    const eDate = getDate(endDate);
    const minDate = this.moment(firstMeasurementDate);
    const maxDate = this.moment(lastMeasurementDate);
    const pRange = parseInt(range, 10);
    const isCustom = pRange === 4;
    const hasRangechanged: boolean = this.props.range !== range;

    if (isCgmActive && !isCustom && !disabled) {
      onDatesChange(sDate.endOf(C.DAY), eDate.endOf(C.DAY), pRange);
    }

    if (displayCal && isCustom && hasRangechanged) {
      focus = isPatternActive ? C.END_DATE_ID : C.START_DATE_ID;
    }

    this.setState({
      range: pRange,
      originalStartDate: sDate,
      originalEndDate: eDate,
      startDate: sDate,
      endDate: eDate,
      minDate,
      maxDate,
      isCustom,
      focusedInput: focus,
    });
  };
  private getDate = (disabled, date, isCgmActive, cgmSelectedDate) => {
    return disabled && !(isCgmActive && cgmSelectedDate)
      ? this.moment('--')
      : date;
  };
  private shoWCalendar = () => {
    const { disabled, isPatternActive } = this.props;
    if (!disabled) {
      const focus = isPatternActive ? C.END_DATE_ID : C.START_DATE_ID;
      this.setState({ focusedInput: focus });
    }
  };
  private handleFocusChange = (focusedInput) => {
    if ([C.START_DATE_ID, C.END_DATE_ID].indexOf(focusedInput) > -1) {
      this.setState({ focusedInput });
    }
  };
  private handleResize = () => {
    const focus = this.state.focusedInput;
    if (focus) {
      this.setState({
        focusedInput: null,
      });
      this.setState({
        focusedInput: focus,
      });
    }
    this.setState({
      wrapWidth: window.outerWidth,
    });
  };
  private onHideCalendar = () => {
    setTimeout(() => {
      this.setState({
        focusedInput: null,
      });
    }, 0);
  };
  private handleDatesChange = ({ startDate, endDate }) => {
    const isCustom = this.state.range === 4;
    const sDate = startDate;
    const eDate = endDate || sDate.clone();
    let range = this.props.range - 1;
    let letCustom = isCustom;
    if (isCustom && this.outOfMaxRange(sDate, eDate)) {
      range = this.props.maxDateRange - 1;
      letCustom = false;
    }
    if (!sDate.isSame(this.state.startDate)) {
      this.setState({
        startDate: sDate,
        endDate: letCustom
          ? eDate.endOf(C.DAY)
          : sDate.clone().add(range, C.DAYS),
      });
      return;
    }
    if (!eDate.isSame(this.state.endDate)) {
      this.setState({
        startDate: letCustom
          ? sDate.startOf(C.DAY)
          : eDate.clone().subtract(range, C.DAYS),
        endDate: eDate,
      });
      return;
    }
  };
  private onChangeDates = () => {
    const { startDate, endDate, originalStartDate, originalEndDate, range } =
      this.state;
    if (
      !startDate
        .clone()
        .startOf(C.DAY)
        .isSame(originalStartDate.clone().startOf(C.DAY), C.DAY) ||
      !endDate
        .clone()
        .startOf(C.DAY)
        .isSame(originalEndDate.clone().startOf(C.DAY), C.DAY)
    ) {
      this.props.onDatesChange(startDate, endDate, range);
    }
    countlyEventTrigger(EVENTS_KEYS.DATE_RANGE_CHANGED, {
      [SEGMENT_KEYS.DATE_CHANGE_METHOD]: SEGMENT_VALUES.MANUAL,
      [SEGMENT_KEYS.DATE_CHANGE_LOCATION]: this.props.isPatternActive
        ? SEGMENT_VALUES.PATTERNS_VIEW
        : this.props.isCgmActive
        ? SEGMENT_VALUES.CGM_VIEW
        : SEGMENT_VALUES.BG_VIEW,
    });
    this.onHideCalendar();
  };
  private onChangePeriod = (way) => {
    countlyEventTrigger(EVENTS_KEYS.DATE_RANGE_CHANGED, {
      [SEGMENT_KEYS.DATE_CHANGE_METHOD]: SEGMENT_VALUES.ARROWS,
      [SEGMENT_KEYS.DATE_CHANGE_LOCATION]: this.props.isPatternActive
        ? SEGMENT_VALUES.PATTERNS_VIEW
        : this.props.isCgmActive
        ? SEGMENT_VALUES.CGM_VIEW
        : SEGMENT_VALUES.BG_VIEW,
    });
    let startDate = this.state.startDate.clone();
    let endDate = this.state.endDate.clone();
    const days = Math.abs(endDate.diff(startDate, C.DAYS)) + 1;

    if (way === C.NEXT) {
      startDate = startDate.startOf(C.DAY).add(days, C.DAYS);
      endDate = endDate.endOf(C.DAY).add(days, C.DAYS);
    }
    if (way === C.PREV) {
      startDate = startDate.startOf(C.DAY).subtract(days, C.DAYS);
      endDate = endDate.endOf(C.DAY).subtract(days, C.DAYS);
    }

    this.setState(
      {
        endDate,
        startDate,
      },
      () => {
        this.onHideCalendar();
        this.props.onDatesChange(startDate, endDate, this.props.range);
      },
    );
  };
  private outOfMaxRange = (startDate, endDate) => {
    const { maxDateRange } = this.props;
    return (
      !!maxDateRange &&
      Math.abs(
        startDate.startOf(C.DAY).diff(endDate.endOf(C.DAY), C.DAYS) - 2,
      ) > maxDateRange
    );
  };
}

export const Calendar = translateHOC('translations')(CalendarClass);
