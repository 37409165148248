export const STATE_ACTIONS = {
  CLEAR_STRIP_DELIVERY: 'STATE/CLEAR_STRIP_DELIVERY',
  CLEAR_PRESCRIPTIONS: 'STATE/CLEAR_PRESCRIPTIONS',
  CLEAR_ALERTS: 'STATE/CLEAR_ALERTS',
  CLEAR_PATIENT_STOCK: 'STATE/CLEAR_PATIENT_STOCK',
  CLEAR_PATIENT_DASHBOARD: 'STATE/CLEAR_PATIENT_DASHBOARD',
  CLEAR_PATIENT_SEARCH_RESULTS: 'STATE/CLEAR_PATIENT_SEARCH_RESULTS',
  CLEAR_ORGANIZATION_STATE: 'STATE/CLEAR_ORGANIZATION_STATE',
  CLEAR_ORG_STOCK: 'STATE/CLEAR_ORG_STOCK',
  CLEAR_PERMISSIONS: 'STATE/CLEAR_PERMISSIONS',
  CLEAR_PATIENT_PERMISSIONS: 'STATE/CLEAR_PATIENT_PERMISSIONS',
  CLEAR_CREATE_PATIENT: 'STATE/CLEAR_CREATE_PATIENT',
  CLEAR_CREATE_PROFESSIONAL: 'STATE/CLEAR_CREATE_PROFESSIONAL',
  CLEAR_EDIT_PATIENT: 'STATE/CLEAR_EDIT_PATIENT',
  ADD_OPEN_CONNECTIVITY: 'STATE/ADD_OPEN_CONNECTIVITY',
  CLEAR_PAIRING_CODE_IDENTITY: 'STATE/CLEAR_PAIRING_CODE_IDENTITY'
};
