// @ts-nocheck
import { createSelector, createStructuredSelector } from 'reselect';
import { includes, keys, isEmpty, path, map, pipe, pluck } from 'ramda';

import { selectOngoingRequests } from 'src/core/request';
import { FETCH_PATIENT_REQUEST } from 'src/core/patient/patient.constant';
import { TARGET_RANGES_GET_REQUEST } from 'src/widgets/target-ranges/store/target-ranges.actions';
import { GET_CGM_SUMMARY, GET_CGM_CLINCAL_DATA } from './../cgm.actions';
import { selectAllBasals } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import { pickBasalYMax } from 'src/domains/diagnostics/scenes/graphs/graph.selector';
import { isBetween, hourStringToFloat } from 'src/utils/date';
import {
  createTbrLine,
  isTbrStart,
  addTbrType,
  formatTbrEvent,
  normalizeTbrLine,
  convertTbrLineToPoint,
  groupByDay,
  addEndDates,
  convertBasalsToLines,
  sortBasalLines,
  prependInitialLine,
  normalizeBasalLine,
} from 'src/domains/diagnostics/scenes/graphs/standard-day-detail/standard-day-detail.selector';

import {
  filterDuplicateLines,
  transformBasal,
} from 'src/domains/diagnostics/scenes/graphs/graph.util';

const REQUIREMENTS = [
  GET_CGM_SUMMARY.BASE,
  TARGET_RANGES_GET_REQUEST.BASE,
  FETCH_PATIENT_REQUEST.BASE,
];

export const selectCGM = path(['continuousMonitoring']);

export const selectCGMErrors = createSelector(selectCGM, path(['errors']));

export const selectCGMPristine = createSelector(selectCGM, path(['pristine']));

export const selectCGMClinicalDataFinish = createSelector(
  selectCGM,
  path(['cgmClinicalDataFinish']),
);

export const selectCGMSummary = createSelector(selectCGM, path(['summary']));

export const selectCGMMeasurements = createSelector(
  selectCGM,
  path(['measurements']),
);

export const selectIsLoadingCGMSummary = createSelector(
  selectOngoingRequests,
  includes(GET_CGM_SUMMARY.BASE),
);

export const selectIsLoadingCGMClinicalData = createSelector(
  selectOngoingRequests,
  includes(GET_CGM_CLINCAL_DATA.BASE),
);

export const selectCGMFirstMeasurement = createSelector(
  selectCGMSummary,
  path(['firstMeasurementDate']),
);

export const selectCGMLastMeasurement = createSelector(
  selectCGMSummary,
  path(['lastMeasurementDate']),
);

export const selectCGMStartDate = createSelector(
  selectCGMSummary,
  path(['startDate']),
);

export const selectCGMRange = createSelector(selectCGMSummary, path(['range']));

export const selectCGMEndDate = createSelector(
  selectCGMSummary,
  path(['endDate']),
);

export const selectIsSummaryEmpty = createSelector(
  selectCGMFirstMeasurement,
  (date) => date === null,
);

export const selectIsLoadingCGMRquirements = createSelector(
  selectOngoingRequests,
  selectCGMErrors,
  selectCGMFirstMeasurement,
  (reqs, errs, first) => {
    const ongoing = (reqs as string[]).some((val) =>
      includes(val, REQUIREMENTS),
    );
    const noerrs = isEmpty(errs);
    const nofirst = first === '';
    return ongoing || (noerrs && nofirst);
  },
);

export const selectHasCGMMeasurements = createSelector(
  selectIsLoadingCGMSummary,
  selectCGMFirstMeasurement,
  (loading, first) => !loading && first,
);

export const selectTotalMeasurements = createSelector<{}, {}, number>(
  selectCGMMeasurements,
  path(['totalMeasurements']),
);

// TODO: test
export const selectGlucoseSummation = createSelector<{}, {}, number>(
  selectCGMMeasurements,
  path(['glucoseSummation']),
);

export const selectDataDays = createSelector(
  selectCGMMeasurements,
  path(['days']),
);

export const selectDataStatsDays = createSelector(
  selectCGMMeasurements,
  path(['daysStats']),
);

// TODO: test
export const selectIntervals = createSelector(
  selectCGMMeasurements,
  path(['intervals']),
);

export const selectIntervalsArray = createSelector(
  selectIntervals,
  map(path(['interval'])),
);

export const selectDataDaysList = createSelector(selectDataDays, (days) =>
  keys(days),
);

export const selectSingleDataDay = (day: string) =>
  createSelector(selectDataDays, (days) => days[day]);

export const selectCGMSelectedDate = createSelector(
  selectCGM,
  path(['selectedDate']),
);

export const isDateInDateRange = (date, selected) => {
  return isBetween(
    date,
    selected.clone().startOf('day'),
    selected.clone().endOf('day'),
  );
};

export const filterByDateRange = (objects, selected) =>
  objects.filter(({ date }) => isDateInDateRange(date, selected));

export const selectBasalsInRange = createSelector(
  selectAllBasals,
  selectCGMSelectedDate,
  (basals, selectedDate) => {
    const moment = require('moment');
    return filterByDateRange(basals, moment(`${selectedDate}`));
  },
);

export const selectMaxBasalMeasurement = createSelector(
  selectBasalsInRange,
  pipe(pluck('basalCbrf'), (basalsValues) => Math.max(...basalsValues)),
);

export const selectBasalYMax = createSelector(
  selectMaxBasalMeasurement,
  pickBasalYMax,
);

export const selectNumericalSelectedTime = createSelector(
  selectCGMSelectedDate,
  (graphStartTime) =>
    hourStringToFloat(graphStartTime || new Date().toISOString()),
);

export const selectBasalLines = createSelector(
  selectBasalsInRange,
  selectBasalYMax,
  selectNumericalSelectedTime,
  (basals, yMax, numericalGraphStartTime) =>
    groupByDay(addEndDates(basals))
      .map(map(transformBasal))
      .map(convertBasalsToLines(numericalGraphStartTime))
      .map(sortBasalLines)
      .map(prependInitialLine)
      .map(map(normalizeBasalLine(yMax)))
      .map(filterDuplicateLines),
);

export const selectTbrLines = createSelector(
  selectBasalsInRange,
  selectNumericalSelectedTime,
  (basals, numericalGraphStartTime) =>
    basals
      .filter(isTbrStart)
      .map(addTbrType)
      .map(formatTbrEvent)
      .map(createTbrLine(numericalGraphStartTime))
      .map(normalizeTbrLine),
);

export const selectTbrPoints = createSelector(selectTbrLines, (tbrLines) =>
  tbrLines.map(convertTbrLineToPoint),
);

export const setectGlucose = path([
  'ui',
  'patientDashboard',
  'bgOverview',
  'glucoseMeasurements',
]);

export const cgmDateConnector = createStructuredSelector({
  range: selectCGMRange,
  startDate: selectCGMStartDate,
  endDate: selectCGMEndDate,
  firstMeasurementDate: selectCGMFirstMeasurement,
  lastMeasurementDate: selectCGMLastMeasurement,
});
