import React from 'react';

import { translate } from 'src/i18n';

import { SvgIcon } from 'src/components/icon';

export const CircleHighBlueComponent = ({
  height,
  width,
  title = 'circle-high-blue',
  fillColor,
}) => {
  const originalWidth = width ? width : 35;
  const originalHeight = height ? height : 38;
  const aspectRatio = width / height;
  const originalFillColor = fillColor ? fillColor : '#5d8fdf';
  return (
    <SvgIcon
      title={translate(title)}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      fillColor={originalFillColor}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Patient-Card-/-Expanded-/-VALENTINE-+-ETAPES"
          transform="translate(-1095.000000, -247.000000)"
        >
          <g id="Group-6" transform="translate(1.000000, 118.000000)">
            <g
              id="Icon/-circle-high-BLUE"
              transform="translate(1094.000000, 129.000000)"
            >
              <circle
                id="Oval"
                fill={fillColor}
                cx="18"
                cy="18"
                r="18"
              ></circle>
              <g
                id="Group"
                strokeWidth="1"
                transform="translate(10.800000, 9.900000)"
                stroke="#FFFFFF"
                strokeLinecap="round"
              >
                <polyline
                  id="Path"
                  strokeWidth="1.98"
                  fillRule="nonzero"
                  transform="translate(7.200000, 7.200000) rotate(-315.000000) translate(-7.200000, -7.200000) "
                  points="2.7 11.7 2.7 2.7 11.7 2.7"
                ></polyline>
                <line
                  x1="7.2"
                  y1="3.15"
                  x2="7.2"
                  y2="15.75"
                  id="Line-4"
                  strokeWidth="1.98"
                  fillRule="nonzero"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const CircleHighBlue = CircleHighBlueComponent;
