import styled from 'styled-components';

import { FlexItem } from '../device-settings-header/flex-grid';

export const LeftColumnGeneral = styled(FlexItem)`
  box-sizing: border-box;
  padding-left: 0rem;
  padding-right: 1.5rem;
  &:first-child {
    padding-right: 1.5rem;
  }
`;

export const RightColumnGeneral = styled(FlexItem)`
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 0rem;
  &:last-child {
    padding-left: 1.5rem;
  }
`;
