import * as React from 'react';

import { GenerateTripleColumnIconsComponent } from '../icon-components.component';

import { colors } from 'src/core/styles';

import {
  CrossMarkIcon,
  RectangleMarkIcon,
  HypoLimitIcon,
  DoubleEllipseIcon,
} from 'src/assets/icons';

const metabolicRateIconsTitle =
  'manufacturerInfo.metabolicRate.metabolicRateIconsTitle';

const metabolicIconTags = [
  {
    objectComponent: (
      <CrossMarkIcon
        key={1}
        height={20}
        minX={-5}
        minY={-5}
        fillColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.metabolicRate.meanBGSD',
  },
  {
    objectComponent: (
      <RectangleMarkIcon
        key={2}
        height={16}
        fillColor={colors.white}
        minX={-5}
        minY={-5}
        strokeColor={colors.charcoal}
      />
    ),
    nametag: 'manufacturerInfo.metabolicRate.meanBGmeanSD',
  },
  {
    objectComponent: (
      <DoubleEllipseIcon
        key={3}
        height={21}
        minX={-5}
        minY={-5}
        strokeColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.metabolicRate.1SD2SD',
  },
  {
    objectComponent: (
      <HypoLimitIcon
        key={4}
        height={20}
        fillColor={colors.red}
        minX={0}
        minY={-7}
      />
    ),
    nametag: 'manufacturerInfo.metabolicRate.hypolimit',
  },
  {
    objectComponent: (
      <RectangleMarkIcon
        key={5}
        height={20}
        fillColor={colors.white}
        minX={-5}
        minY={-5}
        strokeColor={colors.green}
      />
    ),
    nametag: 'manufacturerInfo.metabolicRate.targetRange',
  },
];

export const MetabolicRateIconsComponent = () => (
  <GenerateTripleColumnIconsComponent
    generalIconTags={metabolicIconTags}
    generalIconsTitle={metabolicRateIconsTitle}
  />
);
