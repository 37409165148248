import * as React from 'react';
import { XIcon } from 'src/assets/icons';
import { Block, Button, LocalizedText } from 'src/components';
import { ButtonReset, colors } from 'src/core/styles';
import { translate } from 'src/i18n';
import { CommonHeader, ModalBody } from 'src/widgets/modal';
import { RenderIf } from 'src/utils';
import { testId } from '@roche/roche-common';
import { ModalOverlay, ModalWrapper, ModalCard } from 'src/widgets/modal';
import {
  ButtonsContainer,
  MessageContainer,
  Disclaimer,
  Text,
} from './confirm.modal.style';

export const ConfirmModal = ({
  header,
  visible,
  onCancel,
  onConfirm,
  messages,
}) => (
  <RenderIf validate={visible}>
    <ModalOverlay id="confirmModal">
      <ModalWrapper>
        <ModalCard>
          <ModalBody>
            <CommonHeader>
              <LocalizedText fontSize={4} textKey={header} />
              <ButtonReset
                onClick={() => {
                  onCancel();
                }}
                {...testId('button', 'reset')}
              >
                <XIcon height={14} fillColor={colors.white} />
              </ButtonReset>
            </CommonHeader>
            <Block pl={4} pr={4} pb={3} pt={4}>
              <MessageContainer>
                <Disclaimer
                  withBorder={true}
                  height={50}
                  width={50}
                  borderColor={colors.blueMarine}
                  iconColor={colors.blueMarine}
                  borderFillColor="transparent"
                />
                {messages.map((message, i) => (
                  <Text
                    key={i}
                    textKey={message}
                    className={i === messages.length - 1 ? 'gray' : 'blue'}
                  />
                ))}
              </MessageContainer>
              <ButtonsContainer>
                <Button
                  label={translate('confirmation.no')}
                  buttonStyle="secondary"
                  onClick={() => {
                    onCancel();
                  }}
                  {...testId('button', 'confirmation-no')}
                />
                <Button
                  autoFocus
                  label={translate('confirmation.yes')}
                  onClick={() => {
                    onConfirm();
                  }}
                  {...testId('button', 'confirmation-yes')}
                />
              </ButtonsContainer>
            </Block>
          </ModalBody>
        </ModalCard>
      </ModalWrapper>
    </ModalOverlay>
  </RenderIf>
);
