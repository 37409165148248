import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

import { EC6Patient } from '../patient.types';

import { GetPatientInfoParams } from './patient-info.types';
import { transformEC6Patient } from './patient-info.util';

// Mule Api V2 points to EC6
export const GetPatientInfoLoaderImpl = (
  { patientId }: GetPatientInfoParams,
  openId: any,
) => {
  return getJSON(endpointWithParams(ENDPOINTS.patientV2, { patientId }), {
    headers: {
      Authorization: createAuthHeader(openId.accessToken),
      ApiKey: openId.gigyaToken,
    },
  });
};

export const GetPatientInfoTransformImpl = ({ model }: { model: EC6Patient }) =>
  transformEC6Patient(model);

export const GetPatientInfoServiceImpl = (load, transform) => (query, openId) =>
  load(query, openId).then(transform);
