// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { LocalForm } from 'react-redux-form';
import { InputDropdown } from 'src/widgets/forms/components';
import { BaseCard } from 'src/components/base-card/base-card.component';
import { EDIT_USER_UNIT_MEASUREMENT } from './user-units-selector.constants';

import { FormLabel, ControlSelect } from 'src/components/forms';
import { SettingsButtonsWrapper } from '../../domains/professional/scenes/default-graphic-settings/default-graphic-settings.styles';
import {
  AdditionalInfo,
  INFOS,
  SIZES,
} from 'src/domains/diagnostics/components/additional-info';

import {
  UserUnitsSelectorContainerDescWrapper,
  UserUnitsSelectorInfoContainerWrapper,
  UserUnitsSelectorFormContainer,
  UserUnitsSelectorWrapperHeader,
  UserUnitsSelectorSection,
  ButtonsContainer,
  UserUnitsSelectorMainContainerWrapper,
} from './user-units-selector.styles';
import { t } from 'i18next';
import { testId } from '@roche/roche-common';
import { RouteLeavingGuard } from 'src/components/route-guard';
import { RenderIf } from 'src/utils';
import { LoadingMessage } from 'src/components/loading-message';
import {
  ChangeNavigationRoute,
  CONFIRMATION_COMPONENTS,
} from 'src/components/change-navigation-route';

type UserUnitSelectorProps = {
  userUnitMeasurementType: string;
  saveUserUnits: any;
  allUserUnitsMeasurement: any;
  clearUserUnits: any;
  getUserUnits: any;
  isFetchingUserUnits: boolean;
  userUnitsError: boolean;
  userUnitsSuccess: boolean;
  goTo: (url) => any;
  canChangeTab?: (isDirty: any) => void;
};

type UserUnitSelectorState = {
  measuramentUnitSelected: {
    text: string;
    info: string;
  };
  isDirty: boolean;
};

export const UserUnitSelectorComponent: React.FunctionComponent<
  UserUnitSelectorProps
> = (props) => {
  const {
    getUserUnits,
    userUnitMeasurementType,
    isFetchingUserUnits,
    allUserUnitsMeasurement,
    saveUserUnits,
  } = props;
  const formInputsModel = {
    measurementUnit: '.measurementUnit',
  };
  const [measuramentUnitSelected, setMeasuramentUnitSelected] = useState({
    text: '',
    info: '',
  });
  useEffect(() => {
    getUserUnits();
  }, []);
  useEffect(() => {
    setMeasuramentUnitSelected({
      text: userUnitMeasurementType,
      info: '',
    });
  }, [userUnitMeasurementType]);

  const [isDirty, setIsDirty] = useState(false);

  const getUnitInfo = (text) => {
    const defaultValue =
      allUserUnitsMeasurement.length > 0 &&
      allUserUnitsMeasurement.filter((el) => el.label === text);
    return defaultValue.length ? defaultValue[0].unit : text;
  };

  const setSelectedMeasurementUnitValue = (selectedValue) => {
    const unitInfo = getUnitInfo(selectedValue);
    setMeasuramentUnitSelected({
      text: selectedValue,
      info: unitInfo,
    });
  };

  const changeSetings = (event) => {
    setSelectedMeasurementUnitValue(event);
    setIsDirty(true);
  };

  const cancelChange = () => {
    const { goTo } = props;
    goTo('/home');
  };

  const saveUserBloodGlucoseUnits = () => {
    const unitInfo = getUnitInfo(measuramentUnitSelected.text);
    saveUserUnits(unitInfo);
    setIsDirty(false);
  };

  return (
    <UserUnitsSelectorSection mb={16}>
      <BaseCard
        pt={24}
        pr={24}
        pb={32}
        pl={24}
        minHeight={'87vh'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <UserUnitsSelectorMainContainerWrapper>
          <UserUnitsSelectorWrapperHeader>
            <jello-heading
              className="jello-heading-demo__component"
              size="XL"
              level="H5"
              weight="bold"
              {...testId('setting-units-blood-glucose', 'heading-label')}
            >
              {t('unit-settings.title')}
            </jello-heading>
            <AdditionalInfo info={INFOS.unitsMeasurement} size={SIZES.l} />
          </UserUnitsSelectorWrapperHeader>
          <RenderIf validate={isFetchingUserUnits}>
            <LoadingMessage delay={250} minHeight={400} infinite />
          </RenderIf>
          <UserUnitsSelectorInfoContainerWrapper>
            <UserUnitsSelectorContainerDescWrapper>
              <RenderIf validate={!isFetchingUserUnits}>
                <jello-text
                  size="S"
                  weight="regular"
                  {...testId(
                    'setting-units-blood-glucose',
                    'description-label',
                  )}
                >
                  {t('unit-settings.description')}
                </jello-text>
              </RenderIf>
            </UserUnitsSelectorContainerDescWrapper>
            <UserUnitsSelectorFormContainer>
              <RenderIf validate={!isFetchingUserUnits}>
                <LocalForm model={EDIT_USER_UNIT_MEASUREMENT}>
                  <FormLabel
                    id="languageId"
                    label={t('unit-settings.unit.label')}
                  />
                  <ControlSelect
                    component={InputDropdown}
                    id="measurementUnitId"
                    model={formInputsModel.measurementUnit}
                    defaultValue={userUnitMeasurementType}
                    placeholder={t('unit-settings.unit.label')}
                    onChange={(e) => {
                      changeSetings(e);
                    }}
                    options={allUserUnitsMeasurement}
                  />
                </LocalForm>
              </RenderIf>
            </UserUnitsSelectorFormContainer>
          </UserUnitsSelectorInfoContainerWrapper>
        </UserUnitsSelectorMainContainerWrapper>
        <ButtonsContainer>
          <RenderIf validate={!isFetchingUserUnits}>
            <SettingsButtonsWrapper>
              <jello-button
                className="jello-button-demo__button"
                onClick={() => cancelChange()}
                size="XL"
                variant="secondary"
                {...testId('setting-units-blood-glucose', 'cancel-btn')}
              >
                {t('unit-settings.cancel-button')}
              </jello-button>
            </SettingsButtonsWrapper>
            <SettingsButtonsWrapper>
              <jello-button
                onClick={saveUserBloodGlucoseUnits}
                className="jello-button-demo__button"
                size="XL"
                variant="primary"
                {...testId('setting-units-blood-glucose', 'save-btn')}
              >
                {t('unit-settings.save-button')}
              </jello-button>
            </SettingsButtonsWrapper>
          </RenderIf>
        </ButtonsContainer>
      </BaseCard>
      <RouteLeavingGuard
        when={isDirty}
        navigate={(path) => props.goTo(path)}
        shouldBlockNavigation={() => true}
      />
      <ChangeNavigationRoute
        when={isDirty}
        navigate={(path) => props.goTo(path)}
        shouldBlockNavigation={() => true}
        title={'modals.confirmationModal.title'}
        content={'modals.confirmationModal.content'}
        header={'modals.confirmationModal.header'}
        okBtnText={'modals.confirmationModal.save'}
        cancelBtnText={'modals.confirmationModal.cancel'}
        component={CONFIRMATION_COMPONENTS.unitsConfirm.modal}
        {...{
          units: measuramentUnitSelected.info
            ? measuramentUnitSelected.info
            : getUnitInfo(measuramentUnitSelected.text),
        }}
      />
    </UserUnitsSelectorSection>
  );
};
