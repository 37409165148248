import styled from 'styled-components';

import { Button } from 'src/components/button';
import { weight } from 'src/components/fonts/weights';
import { borderRadius, colors, fontSize, spacing } from 'src/core/styles';
import { combineRems } from 'src/utils/rem-calc';

export const PaginationBarOuterBoxDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${colors.silver};
  justify-content: center;
  border-radius: ${borderRadius.two};
  padding: ${combineRems(spacing.one, spacing.two)}
    ${combineRems(spacing.one, spacing.three)};
`;
PaginationBarOuterBoxDiv.displayName = 'PaginationBarOuterBoxDiv';

export const NumberButton = styled(Button)`
  border-radius: 50%;
  height: ${combineRems(spacing.one, spacing.four)};
  width: ${combineRems(spacing.one, spacing.four)};
  padding: ${spacing.one};
  margin: ${spacing.two} ${spacing.two};
  color: ${(props: { isSelected: boolean }) =>
    props.isSelected ? colors.silver : colors.brandBlue};
  background-color: ${(props) =>
    props.isSelected ? colors.brandBlue : colors.silver};
  min-width: auto;
  border: 0;
`;
NumberButton.displayName = 'NumberButton';

export const NavigationButton = styled.button`
  border: none;
  min-width: ${spacing.five};
  background-color: transparent;
  color: ${colors.brandBlue};
  cursor: pointer;
  font-weight: ${weight.bold};
  font-size: ${fontSize.p};
  padding: ${spacing.three} 0;
  &:focus {
    outline: none;
    border: 1px solid ${colors.brandBlue};
  }
  &:hover {
    color: ${colors.brandBlueDark};
  }
  &:hover path {
    fill: ${colors.brandBlueDark};
  }
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
`;
NavigationButton.displayName = 'Navigation Button';

export const NavigationLabel = styled.span`
  padding: ${spacing.two};
`;
