import * as React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  OptionalSpan,
  AditionalSpan,
  styleFormLabel,
} from './form-label.style';
import { FormLabelProps } from './form-label.types';

export const FormLabelComponent: React.FunctionComponent<FormLabelProps> = ({
  className,
  label,
  id,
  optional = false,
  aditional,
  t,
}) => {
  const translatedSpanLabel = t('createPatient.patientInfoLabels.optional');
  return (
    <label className={className} htmlFor={id}>
      {label}
      <OptionalSpan>{optional ? translatedSpanLabel : ''}</OptionalSpan>
      {aditional && <AditionalSpan>{aditional}</AditionalSpan>}
    </label>
  );
};

export const FormLabel = withTranslation(styleFormLabel(FormLabelComponent));
