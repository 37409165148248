import React from 'react';

import { SvgIcon } from 'src/components/icon';

export const V3MenuIcon = ({
  fillColor = '#508EE5',
  height = 15,
  originalWidth = 31,
  originalHeight = 31,
}) => {
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      fillColor={fillColor}
      height={height}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
    >
      <g
        id="v3-menu"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icon/Primary/Menu"
          transform="translate(-19.000000, -18.000000)"
          fill={fillColor}
          fillRule="nonzero"
        >
          <g id="Group">
            <g id="menuAsset-6" transform="translate(19.000000, 18.000000)">
              <circle id="Oval-2" cx="3.8" cy="3.8" r="3.08"></circle>
              <circle id="Oval-Copy-2" cx="3.8" cy="15.46" r="3.08"></circle>
              <circle id="Oval-Copy-7" cx="3.8" cy="27.12" r="3.08"></circle>
              <circle id="Oval-Copy-3" cx="15.46" cy="3.8" r="3.08"></circle>
              <circle id="Oval-Copy-5" cx="15.46" cy="15.46" r="3.08"></circle>
              <circle id="Oval-Copy-8" cx="15.46" cy="27.12" r="3.08"></circle>
              <circle id="Oval-Copy-4" cx="27.12" cy="3.8" r="3.08"></circle>
              <circle id="Oval-Copy-6" cx="27.12" cy="15.46" r="3.08"></circle>
              <circle id="Oval-Copy-9" cx="27.12" cy="27.12" r="3.08"></circle>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
