import React from 'react';
import { SIZES } from 'src/domains/diagnostics/components/additional-info/additional-info.constants';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  Tabs,
  Card,
  GridContainer,
  GridItem,
  TabsContent,
  TabsHeader,
  TabLinkItem,
} from 'src/components';
import { INFOS } from 'src/domains/diagnostics/components/additional-info';

export const OrgStripManagementComponent = ({ match, children, t }) => (
  <GridContainer marginBottom>
    <GridItem span="12">
      <Card
        info={{
          info: INFOS.stockDetails,
          style: { paddingTop: '18px' },
        }}
        iconSize={SIZES.l}
        customHeaderComponent={
          <Tabs>
            <TabsHeader mb={4} mr="auto" ml="0">
              <TabLinkItem name={t('orgStock.title')} path="" />
            </TabsHeader>
            <TabsContent>{children}</TabsContent>
          </Tabs>
        }
      ></Card>
    </GridItem>
  </GridContainer>
);

export const OrgStripManagement = withTranslation(OrgStripManagementComponent);
