import * as React from 'react';
import { XIcon } from 'src/assets/icons';
import { Block, Button, LocalizedText } from 'src/components';
import { ButtonReset, colors } from 'src/core/styles';
import { WhiteHeader, ModalBody } from 'src/widgets/modal';

export const ConfirmDateModal = ({
  destroyModal,
  data: { t, newDate, onConfirmation },
}) => (
  <ModalBody>
    <WhiteHeader>
      <LocalizedText
        fontSize={4}
        textKey="nextShipment.changeDate.confirm.title"
      />
      <ButtonReset
        onClick={() => {
          destroyModal();
        }}
      >
        <XIcon height={14} fillColor={colors.black} />
      </ButtonReset>
    </WhiteHeader>
    <Block py={2} px={6}>
      <Block p={4}>
        <Block pb={3} mb={4} display="flex" justifyContent="center">
          <strong>
            <p>
              {`${t('nextShipment.changeDate.confirm.message')} ${newDate}?`}
            </p>
          </strong>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            mr={4}
            label={t('modals.support.accept')}
            uppercase
            onClick={() => {
              onConfirmation();
            }}
          />
          <Button
            buttonStyle="secondary"
            label={t('editPatient.cancel')}
            uppercase
            onClick={() => {
              destroyModal();
            }}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);
