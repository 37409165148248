import * as React from 'react';
import { connect } from 'react-redux';
import { TFunction } from 'i18next';
import { matchPath, withRouter } from 'react-router-dom';
import * as H from 'history';
import { compose, lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { includes, valuesIn } from 'ramda';
import logo from 'src/assets/images/logo.png';

import {
  selectIsEMR,
  selectIsPatientEMR,
  selectNotificationCenterPermission,
  selectProfessionalPermission,
  selectJelloPermission,
  selectFhirPermission,
} from 'src/core/permissions/permissions.selectors';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { mapDispatchers, RenderIf } from 'src/utils';
import { PatientDashboardsSelector } from './patient-dashboards-selector/patient-dashboards-selector.component';
import { HcpDashboardSelector } from './hcp-dashboard-selector/hcp-dashboard-selector';
import {
  filterPatientsSet,
  search,
  clearCriteria,
} from 'src/domains/general/widgets/store/hcp-dashboard.actions';
import { DEFAULT_FILTERS } from 'src/domains/general/widgets/components/advanced-search/filters/filters.utils';

import { ProfileDropdown } from './profile-dropdown/profile-dropdown.component';
import { PatientSearch } from './patient-search';
import { PdfReportButton } from './pdf-report/pdf-report.component';
import {
  selectIsAdministrative,
  selectIsPayer,
} from 'src/domains/general/widgets/store/hcp-dashboard.selectors';
import { selectUserProfile } from 'src/core/user/user.selectors';
import {
  AppLogoLink,
  TopNav,
  TopNavSpacer,
  RightSideItems,
} from './top-navigation.style';
import { IconsContainer } from './icons-container/icons-container.component';
import { selectIsHcpOrGp } from 'src/core/user/user.selectors';
import { selectHasMngHome } from 'src/core/permissions';
import { EventSystem } from '@roche/roche-common';
import { clearPatientState } from 'src/core';
import { testId } from '@roche/roche-common';
import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  selectCurrentDashboardLocation,
  selectUnitGlucoseForService,
} from 'src/domains/general/widgets/store/hcp-dashboard.selectors';

type TopNavigationContainerProps = {};

type TopNavigationProps = {
  routes: FixMe;
  isProfessional: boolean;
  isHcpEMR: boolean;
  isPatientEMR: boolean;
  isAdmin: boolean;
  location: H.Location<{}>;
  profile: any;
  mngHome: boolean;
  hasNotificationCenterPermission: boolean;
  shouldReloadNotifications: boolean;
  isHcpOrGp: boolean;
  isLoading: boolean;
  isPayer: boolean;
  isJelloActivated: boolean;
  t: TFunction;
  dashboardLocation: string;
  unitGlucoseForService: string;
  isFhir: boolean;
  filterPatientsSet: any;
  search: any;
  clearCriteria: any;
} & TopNavigationContainerProps;

export const TopNavigationComponent = ({
  routes,
  isProfessional,
  isHcpEMR,
  isPatientEMR,
  isAdmin,
  hasNotificationCenterPermission,
  location,
  profile,
  isHcpOrGp,
  mngHome,
  isPayer,
  t,
  isJelloActivated,
  dashboardLocation,
  unitGlucoseForService,
  isFhir,
  clearCriteria,
  filterPatientsSet,
  search,
}: TopNavigationProps) => {
  const isPatientRoute = !!matchPath(location.pathname, {
    path: routes.patient.patientById,
    exact: false,
    strict: false,
  });
  const isHomeRoute = !!matchPath(location.pathname, {
    path: routes.general.home,
    exact: true,
  });
  const isHomeCgmRoute = !!matchPath(location.pathname, {
    path: routes.general.homeCgm,
    exact: true,
  });
  const isAuthentication = includes(
    location.pathname,
    valuesIn(routes.authentication),
  );

  const clearSearchInput = () => {
    clearCriteria();
    filterPatientsSet(DEFAULT_FILTERS);
    search(unitGlucoseForService, dashboardLocation, '', isFhir);
  };

  return (
    <RenderIf validate={!isAuthentication && !!profile}>
      <TopNav className="print-hide">
        <AppLogoLink
          to={isPayer ? routes.payerPortal.main : routes.general.home}
          onClick={clearSearchInput}
          {...testId('home', 'logo')}
        >
          <img
            src={logo}
            height={36}
            width={36}
            alt={`${t('general.appName')}`}
            title={`${t('general.appName')}`}
          />
        </AppLogoLink>
        <RenderIf
          validate={
            !!profile &&
            !(!!isAdmin && (!!isHomeRoute || !!isHomeCgmRoute)) &&
            !(!!isHcpOrGp && !mngHome && (!!isHomeRoute || !!isHomeCgmRoute)) &&
            !isPayer
          }
        >
          <TopNavSpacer />
          <PatientSearch />
        </RenderIf>
        <RightSideItems>
          <RenderIf
            validate={
              !!profile &&
              !isPatientRoute &&
              !(!!isAdmin && (!!isHomeRoute || !!isHomeCgmRoute)) &&
              !(!!isHcpOrGp && !mngHome) &&
              (!!isHomeRoute || !!isHomeCgmRoute) &&
              !isPayer
            }
          >
            <RenderIf validate={!isJelloActivated}>
              <HcpDashboardSelector />
            </RenderIf>
            <TopNavSpacer />
          </RenderIf>

          <RenderIf validate={isPatientRoute && isProfessional}>
            <RenderIf validate={!isJelloActivated}>
              <PatientDashboardsSelector />
            </RenderIf>
            {!isJelloActivated && (
              <RenderIf validate={isHcpEMR && isPatientEMR && !isPayer}>
                <TopNavSpacer />
                <PdfReportButton data-testid="PDF-report-button-container" />
              </RenderIf>
            )}

            <TopNavSpacer />
          </RenderIf>

          <RenderIf validate={!isPayer}>
            <IconsContainer
              isHomeRoute={isHomeRoute}
              isHomeCgmRoute={isHomeCgmRoute}
              hasNotificationCenterPermission={hasNotificationCenterPermission}
              clearCriteria={clearSearchInput}
            />
          </RenderIf>
          <ProfileDropdown />
        </RightSideItems>
      </TopNav>
    </RenderIf>
  );
};

export const TopNavigation = compose<
  TopNavigationProps,
  TopNavigationContainerProps
>(
  withRouter,
  withTranslation,
  connect(
    createStructuredSelector({
      routes: getCombinedRoutes,
      isProfessional: selectProfessionalPermission,
      isHcpEMR: selectIsEMR,
      isPatientEMR: selectIsPatientEMR,
      hasNotificationCenterPermission: selectNotificationCenterPermission,
      isAdmin: selectIsAdministrative,
      profile: selectUserProfile,
      mngHome: selectHasMngHome,
      isHcpOrGp: selectIsHcpOrGp,
      isPayer: selectIsPayer,
      isJelloActivated: selectJelloPermission,
      dashboardLocation: selectCurrentDashboardLocation,
      unitGlucoseForService: selectUnitGlucoseForService,
      isFhir: selectFhirPermission,
    }),
    mapDispatchers({
      clearPatientState,
      filterPatientsSet,
      search,
      clearCriteria,
    }),
  ),
  lifecycle<any, void>({
    componentDidUpdate(prevProps) {
      const { hasNotificationCenterPermission, history, clearPatientState } =
        this.props;
      if (
        prevProps.hasNotificationCenterPermission !==
          hasNotificationCenterPermission &&
        hasNotificationCenterPermission
      ) {
        const nameEvent = 'NotificationRedirect';
        const eventSystem = new EventSystem();
        eventSystem.initializePubSub();
        eventSystem.subscribe(nameEvent, (name, data: any) => {
          clearPatientState();
          history.replace(`/${data.notificationAction}`, {});
        });
      }
    },
  }),
)(TopNavigationComponent);
