import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { SvgIcon } from 'src/components/icon';
import { colors } from 'src/core/styles/colors';

const originalWidth = 9;
const originalHeight = 11;
const aspectRatio = originalWidth / originalHeight;

export const Logbook24hourInsulin1IconComponent = ({
  height = originalHeight,
  fillColor = colors.white,
  t,
}) => (
  <SvgIcon
    title={t('graphs.iconTitles.logbook24hours.insSum')}
    width={height * aspectRatio}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
  >
    <g
      id="Logbook-24-Hour-Chart"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="24-Hour-Logbook-XL-(V001)"
        transform="translate(-1238.000000, -619.000000)"
        fillRule="nonzero"
      >
        <g id="Group-18" transform="translate(1230.000000, 507.000000)">
          <g id="Group-16" transform="translate(0.000000, 34.000000)">
            <g id="Group-21" transform="translate(8.378378, 15.000000)">
              <g id="InsulinSum1" transform="translate(0.000000, 63.000000)">
                <rect
                  id="Rectangle-7"
                  fill="#FF0B39"
                  x="0"
                  y="0"
                  width="2.09459459"
                  height="11"
                ></rect>
                <rect
                  id="Rectangle-8"
                  fill="#FFA6B0"
                  x="2.09459459"
                  y="0"
                  width="6.28378378"
                  height="7"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const Logbook24hourInsulin1Icon = withTranslation(
  Logbook24hourInsulin1IconComponent,
);
