import { equals, last } from 'ramda';
import * as React from 'react';
import { TFunction } from 'i18next';

import { Block, CaretIconLeft, CaretIconRight } from 'src/components';
import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { countlyPageChanged } from './pagination-bar.utils';
import {
  NavigationButton,
  NavigationLabel,
  NumberButton,
  PaginationBarOuterBoxDiv,
} from './pagination-bar.styles';

type PaginationBarProps = {
  pageNumbers: number[];
  setPage: ({ page: number }) => void;
  currentPage: number;
  t: TFunction;
};

export const PaginationBarComponent: React.FunctionComponent<
  PaginationBarProps
> = ({ pageNumbers, setPage, currentPage, t }) => (
  <PaginationBarOuterBoxDiv>
    <NavigationButton
      onClick={() => {
        const prevPage = currentPage - 1;
        countlyPageChanged(prevPage);
        setPage({ page: currentPage - 1 });
      }}
      disabled={equals(currentPage, 1)}
    >
      <CaretIconLeft fillColor={colors.brandBlue} />
      <NavigationLabel>
        {t('prescription.customClinicGuides.previous')}
      </NavigationLabel>
    </NavigationButton>
    <Block display="flex" justifyContent="center" flexWrap="wrap">
      {pageNumbers.map((number) => (
        <NumberButton
          label={`${number}`}
          isSelected={equals(number, currentPage)}
          onClick={() => setPage({ page: number })}
          key={number}
          className={equals(number, currentPage) ? 'isSelected' : ''}
        />
      ))}
    </Block>
    <NavigationButton
      onClick={() => {
        const nextPage = currentPage + 1;
        countlyPageChanged(nextPage);
        setPage({ page: currentPage + 1 });
      }}
      disabled={equals(currentPage, last(pageNumbers))}
    >
      <NavigationLabel>
        {t('prescription.customClinicGuides.next')}
      </NavigationLabel>
      <CaretIconRight fillColor={colors.brandBlue} />
    </NavigationButton>
  </PaginationBarOuterBoxDiv>
);

export const PaginationBar = withTranslation(PaginationBarComponent);
