import { convertPxToRem } from 'src/utils';
import { Div } from 'src/components/div';
import { LocalizedText } from 'src/components';
import styled from 'styled-components';
import { DisclaimerIcon } from 'src/assets/icons';
import { weight } from 'src/components/fonts/weights';

export const MessageContainer = styled(Div)`
  text-align: center;
  background-color: ${(props) => props.theme.colors.silverLight};
  border: 1px solid ${(props) => props.theme.colors.grayLighter};
  margin-bottom: ${convertPxToRem(30)};
  padding: ${convertPxToRem(20)};
  max-width: ${convertPxToRem(450)};
`;
MessageContainer.displayName = 'messageContainer';

export const Disclaimer = styled(DisclaimerIcon)`
  display: 'block';
`;
Disclaimer.displayName = 'Disclaimer';

export const Text = styled(LocalizedText)`
  color: ${(props) => props.theme.colors.bueMarine};
  font-size: ${convertPxToRem(20)};
  display: block;
  font-weight: ${weight.bold};
  margin-top: ${convertPxToRem(5)};
  &.gray {
    font-size: ${convertPxToRem(16)};
    color: ${(props) => props.theme.colors.grayMedium};
  }
`;
Text.displayName = 'Text';

export const ButtonsContainer = styled(Div)`
  text-align: center;
  margin-top: ${convertPxToRem(15)};
  padding-top: ${convertPxToRem(15)};
  border-top: 1px solid ${(props) => props.theme.colors.grayLighter};
  button {
    margin: ${convertPxToRem(15)};
  }
`;
ButtonsContainer.displayName = 'ButtonsContainer';
