import * as React from 'react';

import { SvgIcon } from 'src/components/icon';

export const NoFiltersResults = ({ locationKey }) => (
  <SvgIcon
    width={120}
    height={120}
    originalHeight={120}
    originalWidth={120}
    fill="none"
    title="No filters results"
  >
    <path
      d="M60 120c33.137 0 60-26.863 60-60S93.137 0 60 0 0 26.863 0 60s26.863 60 60 60Z"
      fill="#E4E9ED"
    />
    <path
      d="M100.91 60c-.92 54.55-80.91 54.54-81.82 0 .92-54.55 80.91-54.54 81.82 0Z"
      fill="#FFC414"
    />
    <path
      d="M62.06 62.37h-3.81c-.46 0-.84-.36-.86-.82l-.72-16.81a3.486 3.486 0 0 1 3.48-3.63 3.48 3.48 0 0 1 3.48 3.63l-.72 16.81c-.02.46-.4.82-.86.82h.01ZM64.42 73.565a4.425 4.425 0 1 0-8.85 0 4.425 4.425 0 0 0 8.85 0Z"
      fill="#fff"
    />
  </SvgIcon>
);
