import {
  createAuthHeader,
  postJSON,
  stringifyBody,
} from 'src/utils/service/service.utils';

import { serverToClientStripModelStocks } from '../org-stock.utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const AddOrgStockLoaderImpl = (
  { clinicId, stripModelReference, actionPerformedAt, numberOfStrips },
  accessToken,
  gigyaToken,
) =>
  postJSON(
    endpointWithParams(ENDPOINTS.addOrgStock, { clinicId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),        
      },
      body: stringifyBody({
        stripModelReference,
        actionPerformedAt,
        numberOfStrips,
      }),
    },
  );

export const AddOrgStockTransform = ({ totalStock, lastShipments }) => ({
  aggregateStripStock: totalStock,
  stripModelStocks: serverToClientStripModelStocks(lastShipments),
});

export const AddOrgStockServiceImpl =
  (load, transform) => (params, accessToken, gigyaToken) =>
    load(params, accessToken, gigyaToken).then(transform);
