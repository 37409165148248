import React, { Fragment } from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { VerticalAxis } from 'src/lib';
import {
  BG_UNIT_KEYS,
  BLOOD_GLUCOSE_UNITS
} from 'src/domains/diagnostics/store/constants';
import { colors, strokeWidth } from 'src/core/styles';
import { X_AXIS_LEFT, TEXT_DELTA_Y, AXIS_FONT_SIZE } from './axis.constants';

export const BloodGlucoseTick = ({
  width,
  tick,
  collapsed,
  collapsedGP,
  isSmallResolution,
  unit
}) => (
  <Fragment>
    <line
      x1={width}
      x2={width * X_AXIS_LEFT.TICK_END}
      y1={tick.value}
      y2={tick.value}
      strokeWidth={strokeWidth.one}
      stroke={tick.color ? tick.color : colors.silverDark}
    />
    <text
      x={width * X_AXIS_LEFT.TEXT_OFFSET}
      y={tick.value}
      textAnchor="end"
      alignmentBaseline="text-after-edge"
      fill={tick.color ? tick.color : colors.black}
      fontSize={
        (collapsed || collapsedGP) && isSmallResolution
          ? AXIS_FONT_SIZE - 2
          : AXIS_FONT_SIZE
      }
      dy={TEXT_DELTA_Y}
    >
      {unit === BLOOD_GLUCOSE_UNITS.MG_PER_DL
        ? tick.label
        : parseFloat(tick.label).toFixed(1)}
    </text>
  </Fragment>
);

const BloodGlucoseAxisComponent = ({
  y,
  width,
  height,
  t,
  ticks,
  unit,
  collapsed,
  collapsedGP,
}) => (
  <Fragment>
    <VerticalAxis
      x={0}
      y={y}
      height={height}
      Tick={(tick) => (
        <BloodGlucoseTick
          width={width}
          tick={tick}
          key={`${tick.value}`}
          isSmallResolution={width < 58}
          collapsed={collapsed}
          collapsedGP={collapsedGP}
          unit={unit}
        />
      )}
      ticks={ticks}
    />
    <text
      x={0}
      y={-height / 2 + AXIS_FONT_SIZE}
      transform={`rotate(270, 0, ${-height / 2})`}
      fontSize={AXIS_FONT_SIZE}
    >
      {`${t('graphs.axisLabels.bloodGlucose')} (${t(BG_UNIT_KEYS[unit])})`}
    </text>
  </Fragment>
);

export const BloodGlucoseAxis = withTranslation(BloodGlucoseAxisComponent);
