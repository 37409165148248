import { createStructuredSelector, createSelector } from 'reselect';
import { map, compose, isEmpty } from 'ramda';

import { average } from 'src/utils';
import { getFormattedStandardDeviation } from 'src/domains/diagnostics/utils';
import { roundBGMeasurement } from 'src/domains/diagnostics/utils/measurements';
import {
  selectBloodGlucoseUnit,
  selectIsLoading,
  selectGlucoseMeasurementsInDateSliderRange,
  selectFormattedGlobalDatesAverageTestsPerDay,
} from 'src/domains/diagnostics/store/selectors/diagnostics.selector';
import {
  selectHasStripMng,
  selectPatientHasStripMng,
  selectHasHomeDelivery,
  selectPatientHasHomeDelivery,
} from 'src/core/permissions/permissions.selectors.js';
import { selectHasSufficiencyToStatisticsCalculation } from 'src/domains/diagnostics/widgets/status-card/status-card.selector';

const getMeasurementValues = (measurements) =>
  map((measurement) => measurement.value, measurements);

const toDashIfMatch = (match) => (value) => value !== match ? value : '-';

const selectGlucoseMeasurementValues = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  getMeasurementValues,
);

const selectTotalTests = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  (measurements) => measurements.length,
);

const calculateBloodGlucoseStandardDeviation = (
  bloodGlucoseUnit,
  bloodGlucoseValues,
) =>
  getFormattedStandardDeviation(
    bloodGlucoseValues,
    roundBGMeasurement(bloodGlucoseUnit),
  );

const selectBloodGlucoseStandardDeviation = createSelector(
  selectBloodGlucoseUnit,
  selectGlucoseMeasurementValues,
  compose(toDashIfMatch(null), calculateBloodGlucoseStandardDeviation),
);

const calculateBloodGlucoseMean = (
  bloodGlucoseUnit,
  bloodGlucoseValues,
  totalTests,
) =>
  totalTests > 0
    ? roundBGMeasurement(bloodGlucoseUnit)(average(bloodGlucoseValues))
    : null;

const selectBloodGlucoseMean = createSelector(
  selectBloodGlucoseUnit,
  selectGlucoseMeasurementValues,
  selectTotalTests,
  compose(toDashIfMatch(null), calculateBloodGlucoseMean),
);

const selectHasData = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectIsLoading,
  (measurements, isLoading) => isLoading || !isEmpty(measurements),
);

export const selectHasStripsOrHDelivery = createSelector(
  selectHasStripMng,
  selectPatientHasStripMng,
  selectHasHomeDelivery,
  selectPatientHasHomeDelivery,
  (
    professionalStripMng,
    patientStripMng,
    professionalHomeDelivery,
    patientHomeDelivery,
  ) =>
    (professionalStripMng && patientStripMng) ||
    (professionalHomeDelivery && patientHomeDelivery),
);

export const BGStatsCardConnector = createStructuredSelector({
  bloodGlucoseMean: selectBloodGlucoseMean,
  bloodGlucoseStandardDeviation: selectBloodGlucoseStandardDeviation,
  testsPerDay: compose(
    toDashIfMatch(null),
    selectFormattedGlobalDatesAverageTestsPerDay,
  ),
  numberOfTests: selectTotalTests,
  bloodGlucoseUnit: selectBloodGlucoseUnit,
  isLoading: selectIsLoading,
  hasData: selectHasData,
  hasStripsOrHdelivery: selectHasStripsOrHDelivery,
  hasStatisticsSufficiency: selectHasSufficiencyToStatisticsCalculation,
});
