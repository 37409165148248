import { connect } from 'react-redux';
import {
  selectIsSessionValid,
  selectAccessToken,
  selectGigyaToken,
} from 'src/app/session';

export const withToken = (Component) =>
  connect((state) => ({
    isValid: !selectIsSessionValid(state),
    accessToken: selectAccessToken(state),
    gigyaToken: selectGigyaToken(state),
  }))(Component);
