import { Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProfileTypesServiceImplType } from 'src/services/department/profile-types/profile-types.types';

import { selectAccessToken, selectGigyaToken } from 'src/app/session';

import {
  getDepartmentProfileTypesError,
  getDepartmentProfileTypesSuccess,
  getProfessionalsError,
  getProfessionalsSuccess,
} from './department.actions';
import {
  DepartmentActions,
  DepartmentActionType,
  GetDepartmenProfileTypesStartAction,
} from './department.types';

export const getDepartmentProfileTypesEpic: (
  getDepartmentProfileTypesService: ProfileTypesServiceImplType,
) => Epic<DepartmentActions, any> =
  (getDepartmentProfileTypesService) => (action$, store) =>
    action$
      .ofType(DepartmentActionType.GET_DEPARTMENT_PROFILE_TYPES_START)
      .switchMap(({ payload }: GetDepartmenProfileTypesStartAction) =>
        Observable.fromPromise(
          getDepartmentProfileTypesService(
            payload,
            selectAccessToken(store.getState()),
            selectGigyaToken(store.getState()),
          ),
        )
          .map((response) => getDepartmentProfileTypesSuccess(response))
          .pipe(
            catchError((err) =>
              Observable.of(getDepartmentProfileTypesError(err)),
            ),
          ),
      );

export const getProfessionalsEpic = (getProfessionals) => (action$, store) =>
  action$
    .ofType(DepartmentActionType.GET_PROFESSIONALS_START)
    .switchMap(({ payload }) =>
      Observable.fromPromise(
        getProfessionals(
          payload.departmentId,
          selectAccessToken(store.getState()),
          selectGigyaToken(store.getState()),
        ),
      )
        .map(getProfessionalsSuccess)
        .pipe(catchError((err) => Observable.of(getProfessionalsError(err)))),
    );
