import * as React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height?: number;
  fillColor?: string;
  strokeColor?: string;
  lineColor?: string;
};

export const V3RpmSettingsIcon = ({
  height = 17,
  fillColor = '#E7EEFA',
  lineColor = '#508EE5',
  strokeColor = colors.brandBlue,
}: Props) => {
  const originalWidth = 36;
  const originalHeight = 35;
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      fill={fillColor}
      strokeColor={lineColor}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill={strokeColor}
        fillRule="evenodd"
      >
        <g id="Group-17" transform="translate(0.500000, 0.500000)">
          <polygon
            id="Path-52"
            fill={fillColor}
            points="2 2.97343169 2 30.3549805 8.5 25.8779297 30.0869141 25.8779297 33.5 23.2631836 33.5 5.14990234 30.7578125 1.5 4.02148438 1.5"
          />
          <path
            d="M30.5,-2.48689958e-14 C32.9142733,-2.48689958e-14 34.8844891,1.90123652 34.995102,4.2881643 L35,4.5 L35,22.7592593 C35,25.1735326 33.0987635,27.1437483 30.7118357,27.2543613 L30.5,27.2592593 L9.38788139,27.2592593 C9.27481759,27.2592593 9.16597861,27.2975386 9.07843118,27.3665206 L9.01702943,27.4238955 L4.35425976,32.58008 C3.4281784,33.6041594 1.84726075,33.6836025 0.823181364,32.7575211 C0.346704892,32.3266405 0.0562341013,31.7307403 0.00735905237,31.0950402 L0,30.9032613 L0,4.5 C0,2.08572667 1.90123652,0.115510911 4.2881643,0.0048979895 L4.5,-2.48689958e-14"
            id="Path"
            fill={lineColor}
            fillRule="nonzero"
          />
          <path
            d="M30.5,3 L4.5,3 C3.72030388,3 3.07955132,3.59488808 3.00686658,4.35553999 L3,4.5 L3,29.604 L6.79191772,25.4117132 C7.35673042,24.7871319 8.12407914,24.390844 8.95006462,24.286732 L9.17667908,24.2656299 L9.38788139,24.2592593 L30.5,24.2592593 C31.2796961,24.2592593 31.9204487,23.6643712 31.9931334,22.9037193 L32,22.7592593 C32,16.7557951 32,12.2531969 32,9.25146484 C32,8.19558377 32,6.61176215 32,4.5 C32,3.72030388 31.4051119,3.07955132 30.64446,3.00686658"
            id="Path"
            fill={fillColor}
            fillRule="nonzero"
          />
          <path
            d="M25,8.5 C25.8284271,8.5 26.5,9.17157288 26.5,10 C26.5,10.7796961 25.9051119,11.4204487 25.14446,11.4931334 L25,11.5 L10,11.5 C9.17157288,11.5 8.5,10.8284271 8.5,10 C8.5,9.22030388 9.09488808,8.57955132 9.85553999,8.50686658 L10,8.5 L25,8.5 Z"
            id="Line-4"
            fill={lineColor}
            fillRule="nonzero"
          />
          <path
            d="M25,16 C25.8284271,16 26.5,16.6715729 26.5,17.5 C26.5,18.2796961 25.9051119,18.9204487 25.14446,18.9931334 L25,19 L10,19 C9.17157288,19 8.5,18.3284271 8.5,17.5 C8.5,16.7203039 9.09488808,16.0795513 9.85553999,16.0068666 L10,16 L25,16 Z"
            id="Line-4"
            fill={lineColor}
            fillRule="nonzero"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
