import * as React from 'react';
import { TFunction } from 'i18next';

import { getLabel } from 'src/utils/i18n';
import { testId } from '@roche/roche-common';

import {
  ManufacturerInfoTitle,
  ManufacturerInfoImportantInfoDescription,
} from '../manufacturer-info-modal.style';
import { IMPORTANT_INFO } from './manufacturer.constants';
type Props = {
  t: TFunction;
};

export const ImportantInformationView = ({ t }: Props) => (
  <div style={{ marginBottom: '1.5rem' }} {...testId(IMPORTANT_INFO, '')}>
    <ManufacturerInfoTitle>
      {t('manufacturerInfo.importantInfo.title')}
    </ManufacturerInfoTitle>
    <ManufacturerInfoImportantInfoDescription>
      {getLabel(t('manufacturerInfo.importantInfo.description'))}
    </ManufacturerInfoImportantInfoDescription>
  </div>
);
