import * as React from 'react';

import { colors } from '../../../core/styles/colors';
import { SvgIcon } from 'src/components/icon';

type MedicalDeviceIdProps = {
  width?: number;
  height?: number;
  fillColor?: string;
};

export const MedicalDeviceIdIcon = ({
  width = 32,
  height = 20,
  fillColor = colors.black,
}: MedicalDeviceIdProps) => (
  <SvgIcon width={width} height={height} viewBox="0 0 32 20" fill="none">
    <g clipPath="url(#clip0_10322_23239)">
      <path d="M32 0.0264893H0V19.9736H32V0.0264893Z" fill={fillColor} />
      <path d="M30.8724 1.1543H1.12793V18.9164H30.8724V1.1543Z" fill="white" />
      <path
        d="M4.65234 10.1763V5.45386H7.40124V10.7402C7.40124 12.0089 7.96512 12.6433 8.81093 12.6433C9.65675 12.6433 10.2206 12.0089 10.2206 10.7402V5.45386H12.899V10.1058C12.899 13.0662 11.5598 14.4759 8.81093 14.4759C6.06204 14.4759 4.72283 12.9957 4.72283 10.1058L4.65234 10.1763Z"
        fill={fillColor}
      />
      <path
        d="M14.7314 14.4759V5.45386H18.8196C21.0751 5.45386 22.5552 6.93403 22.5552 9.96487C22.5552 12.9957 21.0046 14.4759 18.8196 14.4759H14.7314ZM17.4099 7.56839V12.3613H18.8196C19.3129 12.3613 20.0178 11.6565 20.0178 9.96487C20.0178 8.27324 19.5244 7.56839 18.8196 7.56839H17.4099Z"
        fill={fillColor}
      />
      <path
        d="M27.0663 5.45386H24.3174V14.4054H27.0663V5.45386Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_10322_23239">
        <rect
          width={width}
          height={height}
          fill="white"
          transform="translate(0 0.0264893)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
