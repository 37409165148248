import React from 'react';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height?: number,
  width?: number,
};

export const RoundInfoIconComponent = ({ height = 22, width = 23 }: Props) => {
  const originalWidth = 22;
  const originalHeight = 21;
  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <title>round-info</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="round-info-24px" fill="#3D70C4" fillRule="nonzero">
          <g id="Rounded">
            <path
              d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,15 L10,15 C9.45,15 9,14.55 9,14 L9,10 C9,9.45 9.45,9 10,9 L10,9 C10.55,9 11,9.45 11,10 L11,14 C11,14.55 10.55,15 10,15 Z M11,7 L9,7 L9,5 L11,5 L11,7 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
