import * as React from 'react';

import { LocalizedText, Popover } from 'src/components';
import { PERMISSIONS } from 'src/core/permissions/permissions.constants';
import { WithPermissions } from 'src/utils/with-permissions/with-permissions.container';
import {
  PopoverListContainerDiv,
  PopoverList,
  PopoverListItem,
  PopoverListItemLink,
  PopoverListItemLinkTextSpan,
  PopoverListHeader,
  POPOVER_LINK_ACTIVE_CLASS,
} from 'src/components/popover';

import { SignOutLink } from './profile-dropdown-popover.style';
import { testId } from '@roche/roche-common';
import { UserActivityContext } from 'src/app/session/components/user-activity';
import { countlyEventTrigger } from 'src/navigation/countly';
import {
  EVENTS_KEYS,
  SEGMENT_KEYS,
  SEGMENT_VALUES,
} from '../profile-dropdown.countly.constants';
import { LOGS, customDatadogLog } from 'src/app/logger';

type ProfileDropdownPopover = {
  show: boolean;
  routes: FixMe;
  closeMenu: () => void;
  currentPath: string;
};

type ListItem = {
  permissions?: string[];
  ckey: string;
  link: string;
  isCurrentPath?: boolean;
};

export const ListItem: React.FunctionComponent<ListItem> = ({
  permissions = [],
  ckey,
  link,
  isCurrentPath,
}) => (
  <WithPermissions hasPermissions={permissions}>
    <PopoverListItem key={`pma-${ckey}`}>
      <PopoverListItemLink
        exact
        to={link}
        className={isCurrentPath ? POPOVER_LINK_ACTIVE_CLASS : ''}
        {...testId('profile-dropdown', ckey)}
      >
        <PopoverListItemLinkTextSpan>
          <LocalizedText textKey={`profileDropdownPopover.${ckey}`} />
        </PopoverListItemLinkTextSpan>
      </PopoverListItemLink>
    </PopoverListItem>
  </WithPermissions>
);
ListItem.displayName = 'ProfileDropdownPopoverListItem';

export const ProfileDropdownPopover: React.FunctionComponent<
  ProfileDropdownPopover
> = ({ show, routes, closeMenu, currentPath }) => {
  const { onSignOutAction } = React.useContext(UserActivityContext) as any;
  return (
    <Popover pushLeft={5} width={17} show={show} overwriteStyle={true}>
      <PopoverListContainerDiv>
        <PopoverListHeader>
          <LocalizedText textKey="profileDropdown.manageYourProfile" />
        </PopoverListHeader>
        <PopoverList onClick={() => closeMenu()}>
          <ListItem
            ckey="updateDetails"
            link={routes.profile.editProfile}
            isCurrentPath={currentPath === routes.profile.editProfile}
          />
          <ListItem
            ckey="changePassword"
            link={routes.profile.changePassword}
            isCurrentPath={currentPath === routes.profile.changePassword}
          />
          <ListItem
            ckey="defaultSettings"
            link={routes.professional.defaultGraphicSettings}
            permissions={[PERMISSIONS.RELEVANT_CLINICAL_DATA]}
            isCurrentPath={
              currentPath === routes.professional.defaultGraphicSettings
            }
          />
        </PopoverList>
      </PopoverListContainerDiv>
      <SignOutLink
        onClick={() => {
          countlyEventTrigger(EVENTS_KEYS.USER_SIGNED_OUT, {
            [SEGMENT_KEYS.SIGNOUT_METHOD]: SEGMENT_VALUES.MANUAL,
          });
          customDatadogLog('session', LOGS.signOutDropdown);
          onSignOutAction();
        }}
        {...testId('profile-dropdown', 'sign-out')}
      >
        <LocalizedText textKey="profileDropdown.signOut" />
      </SignOutLink>
    </Popover>
  );
};
