import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height?: number,
  fillColor?: string,
};

export const DeviceDownloadIcon = ({
  height = 39,
  fillColor = colors.grayMedium,
}: Props) => {
  const originalWidth = 343;
  const originalHeight = 101;

  const aspectRatio = originalWidth / originalHeight;

  const filterId = 'shadowBlur';
  const cssFilter = {
    filter: `url(#${filterId})`,
  };

  return (
    <SvgIcon
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <defs>
        <filter id={filterId}>
          <feGaussianBlur stdDeviation="10 0" in="SourceGraphic" />
        </filter>
      </defs>

      <g fill="none" fillRule="evenodd">
        <g fill="#06C" fillRule="nonzero">
          <path d="M176.45 37.62c0 3.428-2.996 6.217-6.678 6.217H131.72v-2.368h38.052c2.332 0 4.158-1.69 4.158-3.85 0-2.157-1.826-3.848-4.158-3.848h-43.344c-3.682 0-6.678-2.789-6.678-6.217 0-3.428 2.996-6.217 6.678-6.217h38.052v2.368h-38.052c-2.332 0-4.158 1.69-4.158 3.85 0 2.157 1.826 3.848 4.158 3.848h43.344c3.682 0 6.678 2.789 6.678 6.217z" />
          <path d="M125.977 40.373h-.03v3.74h.03c.066.352.284.624.547.624h3.245c.263 0 .481-.272.547-.623.013-.066.03-.13.03-.201v-3.338c0-.07-.017-.136-.03-.201-.066-.35-.284-.623-.547-.623h-3.245c-.263-.001-.481.271-.547.622zm-.658 0h-3.769v3.74h3.77v-3.74zm-1.884 1.247v-.623h.628v.623h-.628zm0 1.87v-.623h.628v.623h-.628zM170.018 24.314h.03v-3.74h-.03c-.065-.352-.284-.624-.546-.624h-3.246c-.263 0-.481.272-.546.623-.014.066-.03.13-.03.201v3.338c0 .07.016.136.03.201.065.351.283.623.546.623h3.246c.262.001.48-.271.546-.622zm.658 0h3.77v-3.74h-3.77v3.74zm1.885-1.247v.623h-.629v-.623h.629zm0-1.87v.623h-.629v-.623h.629z" />
        </g>
        <g transform="translate(.95 .637)">
          <path
            d="M10.8.9C5.332.9.9 5.332.9 10.8v59.4c0 5.468 4.432 9.9 9.9 9.9h54c5.468 0 9.9-4.432 9.9-9.9V10.8c0-5.468-4.432-9.9-9.9-9.9h-54z"
            stroke="#06C"
            strokeWidth={1.8}
          />
          <ellipse
            stroke="#06C"
            strokeWidth={1.35}
            fill="#FFF"
            cx={14.58}
            cy={66.024}
            rx={4.185}
            ry={3.944}
          />
          <ellipse
            stroke="#06C"
            strokeWidth={1.35}
            fill="#FFF"
            cx={61.29}
            cy={66.024}
            rx={3.915}
            ry={3.944}
          />
          <ellipse
            stroke="#06C"
            strokeWidth={1.35}
            fill="#FFF"
            cx={38.07}
            cy={65.753}
            rx={6.075}
            ry={5.846}
          />
          <path
            d="M15.3 10.8a4.5 4.5 0 0 0-4.5 4.5v30.6a4.5 4.5 0 0 0 4.5 4.5h45a4.5 4.5 0 0 0 4.5-4.5V15.3a4.5 4.5 0 0 0-4.5-4.5h-45z"
            stroke="#06C"
            strokeWidth={1.8}
          />
          <path
            d="M37.848 19.04c.195.01.385.098.519.239 3.85 4.196 7.533 9.181 7.533 13.994 0 4.784-3.598 8.75-8.1 8.75s-8.1-3.966-8.1-8.75c0-4.813 3.682-9.798 7.533-13.994a.786.786 0 0 1 .615-.24zm-.048 1.95c-3.516 3.954-6.557 8.491-6.557 12.283 0 4.029 2.969 7.218 6.557 7.218 3.588 0 6.557-3.19 6.557-7.218 0-3.792-3.04-8.329-6.557-12.282zm4.291 11.062c.349.036.65.336.687.682.289 2.386-1.25 4.94-3.447 6.129-.357.194-.86.049-1.055-.305-.196-.354-.05-.853.307-1.048 1.6-.865 2.85-3.065 2.664-4.596-.06-.457.383-.91.844-.862z"
            fill="#06C"
            fillRule="nonzero"
          />
        </g>
        <path
          d="M13.55 94.237h54a6.062 6.062 0 0 1-6.062 6.062H19.612a6.062 6.062 0 0 1-6.062-6.062zm199.634 0h126a6.062 6.062 0 0 1-6.063 6.062H219.246a6.062 6.062 0 0 1-6.062-6.062z"
          fill="#E0E8F8"
          style={cssFilter}
        />
        <path
          d="M234.395 5.137c-4.253 0-6.044.346-7.896 1.336a8.914 8.914 0 0 0-3.713 3.713c-.99 1.852-1.336 3.643-1.336 7.896v45.832c0 2.06.16 2.884.615 3.736a4.007 4.007 0 0 0 1.672 1.672c.852.456 1.677.615 3.735.615h94.156c2.058 0 2.883-.16 3.735-.615a4.007 4.007 0 0 0 1.672-1.672c.456-.852.615-1.677.615-3.736V18.082c0-4.253-.346-6.044-1.336-7.896a8.914 8.914 0 0 0-3.713-3.713c-1.852-.99-3.643-1.336-7.896-1.336h-80.31z"
          stroke="#06C"
          strokeWidth={1.8}
        />
        <path
          d="M237.372 15.037c-2.058 0-2.883.16-3.735.615a4.007 4.007 0 0 0-1.672 1.672c-.456.852-.615 1.677-.615 3.735v32.955c0 2.06.16 2.884.615 3.736a4.007 4.007 0 0 0 1.672 1.672c.852.456 1.677.615 3.735.615h74.356c2.058 0 2.883-.16 3.735-.615a4.007 4.007 0 0 0 1.672-1.672c.456-.852.615-1.677.615-3.736V21.06c0-2.058-.16-2.883-.615-3.735a4.007 4.007 0 0 0-1.672-1.672c-.852-.456-1.677-.615-3.735-.615h-74.356z"
          stroke="#06C"
          strokeWidth={1.8}
        />
        <path
          d="M275.452 29.89v.019c0 .603.405 1.107.972 1.287a6.302 6.302 0 0 1 4.428 6.012c0 .468-.054.909-.153 1.34-.126.577.108 1.17.621 1.477l.01.009c.773.45 1.781.045 1.988-.82a8.745 8.745 0 0 0 .234-2.006 9.012 9.012 0 0 0-6.372-8.613c-.855-.261-1.728.396-1.728 1.296zm-1.854 13.546c-2.69-.387-4.878-2.574-5.274-5.256a6.297 6.297 0 0 1 4.347-6.984c.576-.171.981-.684.981-1.287v-.018c0-.9-.873-1.557-1.737-1.296a9.018 9.018 0 0 0-6.282 9.864c.531 3.942 3.717 7.128 7.66 7.659 2.825.378 5.435-.55 7.316-2.277a1.344 1.344 0 0 0-.234-2.151c-.522-.306-1.17-.207-1.62.198-1.323 1.206-3.159 1.845-5.157 1.548z"
          fill="#06C"
          fillRule="nonzero"
          stroke="#F7F9FD"
          strokeWidth={0.9}
        />
        <path
          d="M212.45 69.937a4.5 4.5 0 0 0-4.5 4.5v3.6a2.7 2.7 0 0 0 2.7 2.7h127.8a2.7 2.7 0 0 0 2.7-2.7v-3.6a4.5 4.5 0 0 0-4.5-4.5h-124.2z"
          stroke="#06C"
          strokeWidth={1.8}
        />
        <path
          d="M266.45 73.762h17.1a1.575 1.575 0 1 1 0 3.15h-17.1a1.575 1.575 0 1 1 0-3.15z"
          stroke="#06C"
          strokeWidth={1.35}
          fill="#FFF"
        />
        <text fontFamily="Nunito-Regular, Nunito" fontSize={10.8} fill="#06C">
          <tspan x={121.76} y={70.137}>
            CONNECT
          </tspan>
        </text>
      </g>
    </SvgIcon>
  );
};
