import { ProfileTypes } from 'src/core';
import { ProfileType } from 'src/services/department/profile-types/profile-types.types';
import { CreatePatientParams } from 'src/services/patient/create-patient/create-patient.types';
import { TFunction } from 'i18next';

import { FormControlOption } from '../forms/forms.types';

import { CreatedPatient } from './store/create-patient.types';

export enum CreatePatientContexts {
  NORMAL = 'normal',
  UDTC = 'udtc',
}

export type CreatePatientExternalProps = {
  onStep: (step: number) => void;
  onCreatePatient: (payload: CreatePatientParams) => void;
  context: CreatePatientContexts;
  goToHome?: () => void;
  onComplete: () => void;
  onCancel?: () => void;
  onSelectPatient?: () => void;
  renderSuccess?: (parentProps: any) => React.ReactElement<any>;
  renderError?: (parentProps) => React.ReactElement<any>;
  t: TFunction;
  currentStep: number;
};

export type CreatePatientConnectProps = {
  accessToken: string;
  apiKey: string;
  associatedProfessionals: FormControlOption[];
  countries: FormControlOption[];
  creationHasError: boolean;
  creationWasSuccessful: boolean;
  departmentId: number;
  getCountries: () => void;
  getLanguages: () => void;
  getConfigurables: () => void;
  clearCreatePatient: () => void;
  addOpenConnectivity: () => void;
  resetForm: (model: string) => any;
  changeForm: (model: string, value: string) => void;
  getProfileTypes: ({ departmentId }) => void;
  getProfessionals: ({ departmentId }) => void;
  isCreatingPatient: boolean;
  languages: FormControlOption[];
  professionalId: number;
  newPatient: CreatedPatient;
  isHomeDeliveryProfile: boolean;
  isMandatoryMailTrue: boolean;
  departmentProfileTypes: ProfileType[];
  profileType: ProfileTypes;
  clearPatientState: ({ clearRelatedPatientData: boolean }) => void;
  isLoadingConfigurables: boolean;
  configurablePermissions: string[];
  isReadyToRender: boolean;
  isConfigurablesError: boolean;
  isFranceCountry: boolean;
  insStatus: string;
  hasPatientPortalAccess: boolean;
  hasOpenConnectivity: boolean;
  isRPMProgramEnrolled: boolean;
  hasMySugarInvitation: boolean;
  createdPatientPregnancyState?: boolean;
  createdPatientDiabetesType: string;
  createdPatientGender: string;
  patientInfoFormData: object;
  insRequestError: boolean;
  insRequestSuccess: boolean;
  insRequestCancelled: boolean;
};

export type CreatePatientHandlerProps = {
  getDepartmentProfessionals: (departmentId: number) => void;
  getDepartmentProfileTypes: (departmentId: number) => void;
  getINSIdentity: () => void;
  createModal: (any) => void;
};

export type CreatePatientProps = CreatePatientConnectProps &
  CreatePatientExternalProps &
  CreatePatientHandlerProps;

export type CreatePatientInternalState = {
  hasErrored: boolean;
  healthInfoCompleted: boolean;
  patientInfoCompleted: boolean;
  profileTypeSelected: boolean;
  profileTypeLoaded: boolean;
  createPatientStep: any;
};
