import { combineReducers } from 'redux';
import { createForms } from 'react-redux-form';
import { routerReducer } from 'react-router-redux';
import { sessionReducer, CIAM_SDK_NAMESPACE } from 'src/app/session';
import { navigationReducer } from 'src/navigation/store/navigation.reducers';
import { nextShipmentReducer } from 'src/domains/patient/scenes/next-shipment/store/next-shipment.reducer';
import { deliveryConfigurationReducer } from 'src/domains/patient/scenes/delivery-configuration/store/delivery-configuration.reducer';
import { patientSessionReducer } from 'src/core/patient-session/patient-session.reducer';
import { alertReducer } from 'src/core/alert/alert.reducer';

import { requestReducer, modalsReducer } from 'src/core';
import { prescriptionReducer } from 'src/core/prescription';
import { patientStockReducer } from 'src/core/patient-stock';
import {
  permissionsReducer,
  patientPermissionsReducer,
} from 'src/core/permissions/permissions.reducers';
import { targetRangesReducer } from 'src/widgets/target-ranges/store';

import { alertsReducer } from './core/alerts';
import { patientDashboardReducer } from './domains/patient-dashboards/bg/store/bg.reducers';
import { timePeriodsReducer } from './domains/patient/scenes/time-periods/store';
import { stripDeliveryReducer } from './core/strip-delivery/strip-delivery.reducers';
import { patientSearchReducer } from './core/patient-search/patient-search.reducers';
import { patientReducer } from './core/patient/patient.reducers';
import { hba1cCardReducer } from './domains/diagnostics/widgets/hba1c-card/store/hba1c-card.reducer';
import { newEntryHba1cReducer } from './domains/diagnostics/widgets/hba1c-modal/components/new-entry-hba1c/store/new-entry-hba1c.reducer';
import { listValuesHba1cReducer } from './domains/diagnostics/widgets/hba1c-modal/components/list-values-hba1c/store/list-values-hba1c.reducer';
import { patientDateRangeReducer } from './domains/diagnostics/core/patient-date-range/patient-date-range.reducers';
import { orgStockReducer } from './core/org-stock/org-stock.reducers';
import { deviceAssignmentReducer } from './core/device-assignment';
import { configurablePermissionsReducer } from './core/configurables/configurables.reducers';
import { listDevicesReducer } from './core/list-devices/list-devices.reducer';
import { departmentReducer } from './core/department/department.reducers';
import { createPatientReducer } from './widgets/patient/create-patient/store/create-patient.reducer';
import { createProfessionalReducer } from './widgets/professional/create-professional/store/create-professional.reducer';
import { countriesReducer } from './core/countries/countries.reducer';
import { languagesReducer } from './core/languages/languages.reducer';
import { editPatientReducer } from './core/edit-patient/edit-patient.reducer';
import { editProfileReducer } from './widgets/profile/edit-profile/store/edit-profile.reducer';
import { latestPatientsReducer } from './core/latest-patients/index';
import { currentFormsReducer } from './core/current-forms/current-forms.reducer';
import { deviceSettingsListReducer } from './core/device-settings-list/device-settings-list.reducer';
import { deviceSettingsReducer } from './core/device-settings/device-settings.reducer';
import { mysugrReducer } from './core/mysugr/mysugr.reducer';
import { hcpDashboardReducer } from './domains/general/widgets/store/hcp-dashboard.reducers';
import { patientReportsReducer } from './core/patient-reports/patient-reports.reducer.ts';
import { continuousMonitoringReducer } from './domains/patient-dashboards/cgm/store/cgm.reducers';
import { rpmSettingsReducer } from './core/rpm/rpm-settings/rpm-settings.reducer';
import { manifestModulesReducer } from './core/manifest-modules/manifestModules.reducer';
import { UserUnitsReducer } from './widgets/user-units-selector/store/user-units-selector.reducer';
import { INSIdentityReducer } from './widgets/patient/INS-patient/store/INS-Identity.reducer';
import { checkPairingCodeReducer } from './widgets/patient/create-patient/create-patient-with-platform/store/check-pairing-code/check-pairing-code.reducer';
import { createPatientWithFhirReducer } from './widgets/patient/create-patient/create-patient-with-platform/store/create-patient-with-fhir/create-patient-with-fhir.reducer';
import { editPatientWithFhirReducer } from './widgets/patient/create-patient/create-patient-with-platform/store/edit-patient-with-fhir/edit-patient-with-fhir.reducer';
import { correlatePairingCodeReducer } from './widgets/patient/create-patient/create-patient-with-platform/store/correlate-pairing-code/correlate-pairing-code.reducer';
import { pairingCodeIdentityStatusReducer } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/pairing-code-identity-status/pairing-code-identity-status.reducer';
import { checkDataSharingConsentReducer } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.reducer';
import { invitePatientReducer } from './widgets/patient/create-patient/create-patient-with-platform/store/invite-patient/invite-patient.reducer';
import { configReducer } from './domains/authentication/config';
import { changedPasswordReducer } from 'src/domains/profile/scenes/change-password/store/change-password.reducer';

export const reducers = {
  router: routerReducer,
  request: requestReducer,
  navigation: navigationReducer,
  patient: patientReducer,
  patientReports: patientReportsReducer,
  patientSession: patientSessionReducer,
  alerts: alertsReducer,
  prescription: prescriptionReducer,
  permissions: permissionsReducer,
  patientPermissions: patientPermissionsReducer,
  configurablePermissions: configurablePermissionsReducer,
  listDevices: listDevicesReducer,
  deviceSettingsList: deviceSettingsListReducer,
  deviceSettings: deviceSettingsReducer,
  timePeriods: timePeriodsReducer,
  deliveryConfiguration: deliveryConfigurationReducer,
  targetRanges: targetRangesReducer,
  orgStock: orgStockReducer,
  deviceAssignment: deviceAssignmentReducer,
  modals: modalsReducer,
  department: departmentReducer,
  countries: countriesReducer,
  languages: languagesReducer,
  currentForms: currentFormsReducer,
  mysugr: mysugrReducer,
  lastHba1cValue: hba1cCardReducer,
  newEntryHba1c: newEntryHba1cReducer,
  listValuesHba1c: listValuesHba1cReducer,
  hcpDashboard: hcpDashboardReducer,
  continuousMonitoring: continuousMonitoringReducer,
  insIdentity: INSIdentityReducer,
  pairingCodePatient: checkPairingCodeReducer,
  ...createForms({
    ui: combineReducers({
      patientDashboard: patientDashboardReducer,
      patientDateRange: patientDateRangeReducer,
    }),
    patientSearch: patientSearchReducer,
    latestPatients: latestPatientsReducer,
    deviceAssignmentPatientSearch: deviceAssignmentReducer,
    stripDelivery: stripDeliveryReducer,
    patientStock: patientStockReducer,
    createPatientWithFhir: createPatientWithFhirReducer,
    createPatient: createPatientReducer,
    createProfessional: createProfessionalReducer,
    editPatient: editPatientReducer,
    editPatientWithFhir: editPatientWithFhirReducer,
    correlatePairingCode: correlatePairingCodeReducer,
    invitePatient: invitePatientReducer,
    editProfile: editProfileReducer,
    nextShipment: nextShipmentReducer,
    hcpDashboard: hcpDashboardReducer,
    rpmSettings: rpmSettingsReducer,
    userUnitsMeasurement: UserUnitsReducer,
    pairingCodeIdentityStatus: pairingCodeIdentityStatusReducer,
    checkDataSharingConsent: checkDataSharingConsentReducer,
  }),
  [CIAM_SDK_NAMESPACE]: combineReducers({
    config: configReducer,
    session: sessionReducer,
  }),
  isChangedPassword: changedPasswordReducer,
  uiAlerts: alertReducer,
  manifestModules: manifestModulesReducer,
};

const asyncReducers = {};

export const injectReducer = (store, { key, reducer }) => {
  if (typeof asyncReducers[key] !== 'undefined') {
    return;
  }

  asyncReducers[key] = reducer;

  store.replaceReducer(
    combineReducers({
      ...reducers,
      ...asyncReducers,
    }),
  );
};
