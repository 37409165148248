import platform from 'platform';

import { getConfig } from '@roche/roche-common';
import { createAuthHeader, getJSON } from 'src/utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

const { REACT_APP_API_VERSION, REACT_APP_API_ROOT } = getConfig();

const MAC_OSX_REGEX = /(mac)|(OS\sX)/gi;
export const OS_NAME = { MAC: 'mac', WINDOWS: 'windows' };
export const transformPlatformToGenericOSName = ({ os }) =>
  !!os && os.toString().match(MAC_OSX_REGEX) ? OS_NAME.MAC : OS_NAME.WINDOWS;

export const DTCLoaderImpl = (payload, token) => {
  const os = transformPlatformToGenericOSName(platform);
  return getJSON(
    endpointWithParams(ENDPOINTS.dtc, { os }),
    {},
    {
      Authorization: createAuthHeader(token),
    },
  );
};

export const DTCTransformImpl = (data) => data;

export const DTCServiceImpl = (loader, transform) => (query, token) =>
  loader(query, token).then(transform);
