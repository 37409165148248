// @ts-nocheck
import * as React from 'react';

import { DisclaimerIcon } from 'src/assets/icons';
import { Badge } from 'src/components/badge';
import { colors } from 'src/core/styles';

type WarningBadgeProps = {
  size: number;
};

export const WarningBadge: React.FunctionComponent<WarningBadgeProps> = ({
  size,
}) => (
  <Badge
    bgColor={colors.trafficRed}
    icon={
      <DisclaimerIcon
        height={size * 0.75}
        width={size * 0.75}
        borderFillColor={colors.clear}
        borderColor={colors.clear}
        withBorder
      />
    }
    size={size}
    emptyInnerCircle={false}
    disabled={false}
  />
);
