// @ts-nocheck
import * as React from 'react';
import { Errors, LocalForm } from 'react-redux-form';

import { MODAL_TYPES } from 'src/core/modal';
import { colors } from 'src/core/styles/colors';
import {
  BaseCard,
  Block,
  Button,
  ControlButton,
  ControlText,
  LocalizedText,
} from 'src/components';
import { ControlSelect } from 'src/components/forms';

import { DeactivatePatientConfirmationModal } from './components/deactivate-patient-confirmation-modal';
import { DeactivatePatientProps } from './deactivate-patient.types';
import {
  DEACTIVATE_REASON_OPTIONS,
  DEACTIVATE_INITIAL_STATE,
  DEACTIVATE_MAX_LENGTH,
} from './deactivate-patient.constants';
import {
  DeactivatePatientSection,
  FormContainer,
  HeaderContainer,
  HeaderTitle,
  CommentsTextArea,
  ReasonSelect,
  ButtonsContainer,
} from './deactivate-patient.styles';
import { testId } from '@roche/roche-common';

export class DeactivatePatientComponent extends React.Component<
  DeactivatePatientProps,
  any
> {
  public render() {
    const { t } = this.props;
    const selectOptions = DEACTIVATE_REASON_OPTIONS.map((opt) => {
      return {
        label: t(opt.label),
        value: opt.value,
      };
    });
    return (
      <DeactivatePatientSection mb={80}>
        <BaseCard p={88}>
          <HeaderContainer>
            <HeaderTitle>
              <LocalizedText textKey="deactivatePatient.title" />
            </HeaderTitle>
          </HeaderContainer>
          <LocalForm
            model="deactivate"
            onSubmit={(values) => this.handleSubmit(values)}
            initialState={DEACTIVATE_INITIAL_STATE}
          >
            <FormContainer {...testId('deactivatePatient', 'formContainer')}>
              <span>
                <LocalizedText textKey="deactivatePatient.form.reason" />
              </span>
              <ControlSelect
                component={ReasonSelect}
                id="motive"
                model=".motive"
                options={selectOptions}
              />
              <Block mt={40} />
              <span>
                <LocalizedText textKey="deactivatePatient.form.comments" />
              </span>
              <ControlText
                model=".comment"
                id="comment"
                component={CommentsTextArea}
                placeholder={t('deactivatePatient.form.placeholder')}
                maxLength={DEACTIVATE_MAX_LENGTH}
                validators={{
                  length: (val) =>
                    !((val || '').length > DEACTIVATE_MAX_LENGTH),
                }}
              />
              <Block
                mt={15}
                color={colors.red}
                fontSize={13}
                position="absolute"
              >
                <Errors
                  model=".comment"
                  messages={{
                    length: t('deactivatePatient.validations.length'),
                  }}
                />
              </Block>
            </FormContainer>
            <ButtonsContainer>
              <Button
                onClick={() => this.handleCancel()}
                buttonStyle="secondary"
                label={t('deactivatePatient.buttons.cancel')}
              />
              <ControlButton
                model="deactivate"
                type="submit"
                component={Button}
                mapProps={{
                  buttonStyle: 'primary',
                  label: t('deactivatePatient.buttons.deactivate'),
                }}
                disabled={{ valid: false }}
              />
            </ButtonsContainer>
          </LocalForm>
        </BaseCard>
      </DeactivatePatientSection>
    );
  }

  private handleSubmit(values) {
    const { createModal } = this.props;
    createModal({
      key: MODAL_TYPES.CUSTOM,
      data: {
        customComponent: DeactivatePatientConfirmationModal,
        onConfirm: () => this.deactivatePatient(values),
      },
    });
  }

  private deactivatePatient(values) {
    const { deactivatePatientStart, createModal, destroyModal } = this.props;
    const { comment, motive } = values;
    destroyModal();
    createModal({
      key: MODAL_TYPES.LOADING,
    });
    deactivatePatientStart({
      patientId: this.props.match.params.id,
      comment: comment || '',
      motive,
    });
  }

  private handleCancel() {
    this.props.history.push(`/patients/${this.props.match.params.id}`);
  }
}
