import { isNil } from 'ramda';
import React from 'react';

import { BasalLine } from 'src/domains/diagnostics/components/graph';
import {
  DATA_TYPE,
  FULL_OPACITY,
  FADED_OPACITY,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import {
  areDatesTheSameDay,
  getClickableCursorStyle,
} from 'src/domains/diagnostics/scenes/graphs/graph.util';
import { DisconnectedLineSeries } from 'src/lib';
import { toolTipWidth } from 'src/domains/diagnostics/components/tool-tip/detail-tool-tip';

const getOpacity = (selectedDate, measurementDate) =>
  isNil(selectedDate) || areDatesTheSameDay(selectedDate, measurementDate)
    ? FULL_OPACITY
    : FADED_OPACITY;

export const BasalGraph = ({
  height,
  width,
  selectedDate,
  basalLines = [],
  tbrLines = [],
  tbrPoints = [],
  onLineClick = () => null,
  onMouseMove = () => null,
  onMouseOut = () => null,
  collapsed,
}) => {
  const commonProps = { height, width };
  const lineProps = {
    ...commonProps,
    Line: (a, b, index) => (
      <BasalLine
        ax={a.x}
        bx={b.x}
        ay={a.y}
        by={b.y}
        key={`${a.x}-${b.x}-${a.y}-${b.y}-${index}`}
        showA={a.show}
        showB={b.show}
        opacity={getOpacity(selectedDate, a.date)}
        onMouseMove={(event) =>
          onMouseMove(
            event,
            {
              type: DATA_TYPE.BASAL,
              ...a,
            },
            toolTipWidth,
          )
        }
        onMouseOut={onMouseOut}
        onClick={onLineClick({
          type: DATA_TYPE.INSULIN,
          date: a.date,
        })}
        cursor={getClickableCursorStyle(!collapsed)}
      />
    ),
  };
  return (
    <React.Fragment>
      {basalLines.map((basalLine, index) => (
        <DisconnectedLineSeries key={index} {...lineProps} points={basalLine} />
      ))}
    </React.Fragment>
  );
};
