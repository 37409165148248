import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import { isNil } from 'ramda';
import moment from 'moment';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { VisibleWrapper } from './date-picker-visible.style';

import {
  dateOrNull,
  momentOrNull,
  toMomentGMT,
  updateMomentLocale,
} from '../date-picker.util';

export class DatePickerVisibleComponent extends Component {
  constructor(props) {
    super(props);
    updateMomentLocale(moment, props.locale);
    this.state = {
      date: momentOrNull(props.date),
      focused: false,
      initialMonth: isNil(props.date) ? moment() : toMomentGMT(props.date),
    };
  }

  componentWillMount() {
    this.setState({
      focused: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    updateMomentLocale(moment, nextProps.locale);
    if (nextProps.date) {
      this.setState({
        date: toMomentGMT(nextProps.date),
        initialMonth: toMomentGMT(nextProps.date),
      });
    }
  }

  render() {
    const {
      checkIfDateOutOfRange,
      displayFormat,
      readOnly,
      locale,
      numberOfMonths,
    } = this.props;
    const { date, initialMonth } = this.state;

    updateMomentLocale(moment, locale);

    return (
      <div ref={(node) => (this.wrapper = node)}>
        <VisibleWrapper>
          <SingleDatePicker
            date={date}
            displayFormat={displayFormat}
            focused={true}
            initialVisibleMonth={() => initialMonth}
            isOutsideRange={checkIfDateOutOfRange}
            keepOpenOnDateSelect={true}
            onFocusChange={() => this.handleOnFocusChange()}
            onDateChange={(date) => this.handleOnDateChange(date)}
            renderCalendarInfo={this.renderDropShadow}
            readOnly={readOnly}
            numberOfMonths={numberOfMonths}
          />
        </VisibleWrapper>
      </div>
    );
  }

  collapseCalendar = () => {
    this.wrapper.click();
  };

  handleOnDateChange = (date) => {
    this.setState({
      date,
    });
    this.props.onDateChange(dateOrNull(date));
  };

  handleOnFocusChange = () =>
    this.setState({
      focused: true,
    });
}

DatePickerVisibleComponent.defaultProps = {
  date: null,
  displayFormat: 'MMM D YYYY',
  readOnly: false,
};

export const DatePickerVisible = withTranslation(DatePickerVisibleComponent);
