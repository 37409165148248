import React from 'react';

import { LocalizedText } from 'src/domains/diagnostics/components';

import { Column, HeaderRow } from '../insulin-table/insulin-table.style';

export const BolusTableHeader = ({ expanded }) => (
  <HeaderRow expanded={expanded}>
    {[
      'graphs.bolus.bolusType',
      'graphs.bolus.categoryName',
      'graphs.bolus.bolusPercentage',
      'graphs.bolus.days',
    ].map((textKey, index) => (
      <Column expanded={expanded} key={`${textKey}-${index}`}>
        <LocalizedText textKey={textKey} />
      </Column>
    ))}
  </HeaderRow>
);
