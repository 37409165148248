import * as React from 'react';
import { TFunction } from 'i18next';

import { getLabel } from 'src/utils/i18n';

import {
  ManufacturerInfoTitle,
  ManufacturerInfoIntenUseDescription,
} from '../manufacturer-info-modal.style';

type Props = {
  t: TFunction;
};

export const IntendedUseView = ({ t }: Props) => (
  <div style={{ marginBottom: '1.5rem' }}>
    <ManufacturerInfoTitle>
      {t('manufacturerInfo.intendedUse.title')}
    </ManufacturerInfoTitle>
    <ManufacturerInfoIntenUseDescription>
      {getLabel(t('manufacturerInfo.intendedUse.descriptionText'))}
    </ManufacturerInfoIntenUseDescription>
  </div>
);
