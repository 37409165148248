import React from 'react';

import { colors } from 'src/core/styles';

import { SvgIcon } from 'src/components/icon';

export const InsulinToggleIcon = ({
  height = 11,
  width = 12,
  fillColor = colors.carb,
  minX = 0,
  minY = 0,
  originalWidth = 16,
  originalHeight = 9
}) => {

  return (
    <SvgIcon
      title="Basal"
      width={width}
      height={height}
      minX={minX}
      minY={minY}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g fill={fillColor}>
        <rect fill="#FF0B39" x="0" y="0" width="2" height="11" />
        <rect fill="#3488D6" x="10" y="0" width="2" height="11" />
        <rect fill="#3A3535" x="14" y="0" width="2" height="11" />
        <rect fill="#FFA6B0" x="2" y="0" width="6" height="7" />
      </g>
    </SvgIcon>
  );
};
