import { createStructuredSelector, createSelector } from 'reselect';
import { isEmpty } from 'ramda';

import {
  selectTimeIntervals,
  selectGlucoseMeasurementsInDateSliderRange,
  selectGraphThreshold,
} from 'src/domains/diagnostics/store/selectors';
import { selectHasStripsOrHDelivery } from 'src/domains/patient-dashboards/bg/scenes/dashboard/widgets/bg-stats-card/bg-stats-card.selector.js';
import { isDatetimeWithinInterval } from 'src/utils';
import { MEAL_TIMES } from 'src/domains/diagnostics/constants/logbook.constants';
import { selectIsLoading } from 'src/domains/diagnostics/store/selectors/diagnostics.selector';

const findNightTimeInterval = (timeIntervals) =>
  timeIntervals.find(
    (timeInterval) => timeInterval.description === MEAL_TIMES.NIGHT,
  );

const selectNightTimeIntervals = createSelector(
  selectTimeIntervals,
  findNightTimeInterval,
);

const findNightGlucoseMeasurements = (measurements, nightTimeInterval) =>
  nightTimeInterval
    ? measurements.filter(({ date }) =>
        isDatetimeWithinInterval(
          date,
          nightTimeInterval.startTime,
          nightTimeInterval.endTime,
        ),
      )
    : [];

const findDayGlucoseMeasurements = (measurements, nightTimeInterval) =>
  nightTimeInterval
    ? measurements.filter(
        ({ date }) =>
          !isDatetimeWithinInterval(
            date,
            nightTimeInterval.startTime,
            nightTimeInterval.endTime,
          ),
      )
    : [];

const selectFindHypoglycemiaEvents = (thresholds, measurements) =>
  thresholds && measurements
    ? measurements.filter((m) => m.value < thresholds.glucoseIdealIntervalMin)
    : [];

const selectHypoglycaemiaMeasurements = createSelector(
  selectGraphThreshold,
  selectGlucoseMeasurementsInDateSliderRange,
  selectFindHypoglycemiaEvents,
);

const selectTotalHypoglycaemiaMeasurements = createSelector(
  selectHypoglycaemiaMeasurements,
  (m) => m.length,
);

const selectLowHypoMeasurements = createSelector(
  selectGraphThreshold,
  selectHypoglycaemiaMeasurements,
  (t, m) =>
    m.filter(
      (m) =>
        m.value < t.glucoseIdealIntervalMin &&
        m.value >= t.hypoglycemiaThreshold,
    ),
);

const selectLowHypoMeasurementsNight = createSelector(
  selectLowHypoMeasurements,
  selectNightTimeIntervals,
  findNightGlucoseMeasurements,
);

const selectLowHypoMeasurementsDay = createSelector(
  selectLowHypoMeasurements,
  selectNightTimeIntervals,
  findDayGlucoseMeasurements,
);

const selectLowHypoMeasurementsNightTotal = createSelector(
  selectLowHypoMeasurementsNight,
  (m) => m.length,
);

const selectLowHypoMeasurementsDayTotal = createSelector(
  selectLowHypoMeasurementsDay,
  (m) => m.length,
);

const selectLowHypoMeasurementsTotal = createSelector(
  selectLowHypoMeasurements,
  (m) => m.length,
);

const selectVeryLowHypoMeasurements = createSelector(
  selectGraphThreshold,
  selectHypoglycaemiaMeasurements,
  (t, m) => m.filter((m) => m.value < t.hypoglycemiaThreshold),
);

const selectVeryLowHypoMeasurementsNight = createSelector(
  selectVeryLowHypoMeasurements,
  selectNightTimeIntervals,
  findNightGlucoseMeasurements,
);

const selectVeryLowHypoMeasurementsDay = createSelector(
  selectVeryLowHypoMeasurements,
  selectNightTimeIntervals,
  findDayGlucoseMeasurements,
);

const selectVeryLowHypoMeasurementsNightTotal = createSelector(
  selectVeryLowHypoMeasurementsNight,
  (m) => m.length,
);

const selectVeryLowHypoMeasurementsDayTotal = createSelector(
  selectVeryLowHypoMeasurementsDay,
  (m) => m.length,
);

const selectVeryLowHypoMeasurementsTotal = createSelector(
  selectVeryLowHypoMeasurements,
  (m) => m.length,
);

const selectHasData = createSelector(
  selectGlucoseMeasurementsInDateSliderRange,
  selectIsLoading,
  (measurements, isLoading) => isLoading || !isEmpty(measurements),
);

export const HypoglycaemiaCardConnector = createStructuredSelector({
  isLoading: selectIsLoading,
  hasData: selectHasData,
  total: selectTotalHypoglycaemiaMeasurements,
  totalLow: selectLowHypoMeasurementsTotal,
  dayLow: selectLowHypoMeasurementsDayTotal,
  nightLow: selectLowHypoMeasurementsNightTotal,
  totalVeryLow: selectVeryLowHypoMeasurementsTotal,
  dayVeryLow: selectVeryLowHypoMeasurementsDayTotal,
  nightVeryLow: selectVeryLowHypoMeasurementsNightTotal,
  isHDeliveryOrStrpMng: selectHasStripsOrHDelivery,
});
