import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { TableRow } from 'src/domains/diagnostics/components';
import { BG_UNIT_KEYS } from 'src/domains/diagnostics/store/constants';
import { formatBGMeasurement } from 'src/domains/diagnostics/utils';

import { DistributionTableHeaderWrapper } from './distribution-table-header.style';

import { DistributionTableCell } from '../distribution-table-row';

export const DistributionTableHeaderComponent = ({
  bloodGlucoseUnit,
  t,
  threshold,
}) => (
  <DistributionTableHeaderWrapper>
    <TableRow>
      <DistributionTableCell textAlign="left">
        {t('dashboard.distributionCard.statsTable.targetRange')}
      </DistributionTableCell>
      <DistributionTableCell textAlign="right">
        {`${formatBGMeasurement(bloodGlucoseUnit)(
          threshold.glucoseIdealIntervalMin,
        )} - ${formatBGMeasurement(bloodGlucoseUnit)(
          threshold.glucoseIdealIntervalMax,
        )} ${t(BG_UNIT_KEYS[bloodGlucoseUnit])}`}
      </DistributionTableCell>
    </TableRow>
  </DistributionTableHeaderWrapper>
);

export const DistributionTableHeader = withTranslation(
  DistributionTableHeaderComponent,
);
