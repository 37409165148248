import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import { transformServerToClientDeliveryStatus } from '../delivery-status.util';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const GetLastDeliveryStatusLoaderImpl = (
  { patientId },
  accessToken  
) =>
  getJSON(
    endpointWithParams(ENDPOINTS.lastDeliveryStatus, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),        
      },
    },
  );

export const GetLastDeliveryStatusTransformImpl = (status) => {
  const {
    trafficLightStatus,
    trafficLightStatusConditions,
    trafficLightStatusComment,
    trafficLightStatusCommentState,
    numberOfStripsToDeliver,
    trafficLightStatusDateCalculated,
  } = transformServerToClientDeliveryStatus(status);
  return {
    lastTrafficLightStatus: trafficLightStatus,
    lastTrafficLightStatusConditions: trafficLightStatusConditions,
    lastTrafficLightStatusComment: trafficLightStatusComment,
    lastTrafficLightStatusCommentState: trafficLightStatusCommentState,
    lastNumberOfStripsToDeliver: numberOfStripsToDeliver,
    lastTrafficLightStatusDateCalculated: trafficLightStatusDateCalculated,
  };
};

export const GetLastDeliveryStatusServiceImpl =
  (load, transform) => (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then(transform);
