import styled from 'styled-components';

import { Column } from 'src/components/column';
import { combineRems, convertPxToRem } from 'src/utils';

export const Hr = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighter};
  width: 100%;
`;

export const Content = styled.div`
  border: 1px solid ${(props) => props.theme.colors.quartzBlue};
  padding: ${(props) =>
      combineRems(props.theme.spacing.two, props.theme.spacing.three)}
    ${(props) => props.theme.spacing.four};
  background-color: ${(props) => props.theme.colors.blueMarineAlpha};
  border-radius: ${(props) => props.theme.borderRadius.three};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing.four};
  max-width: ${convertPxToRem(460)};
  text-align: center;
`;

export const TitleSpan = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const Subheading = styled.h2`
  color: ${(props) => props.theme.colors.brandBlue};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSize.title};
  line-height: 1.25;
  margin: 0 auto
    ${({ theme }) => combineRems(theme.spacing.one, theme.spacing.three)};
  max-width: 100%;
`;

export const CustomBody = styled(Column)`
  flex: 1 1 auto;
  padding: ${(props) => props.theme.spacing.four};
`;
export const Buttons = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.spacing.four};
  justify-content: center;
`;
