import styled from 'styled-components';
import { breakpoints } from 'src/core';
import { convertPxToRem } from 'src/utils';
import { createStyledComponent } from 'src/utils/styles';
import { Span } from 'src/components';
import { Div } from 'src/components/div';

import { LocalForm } from 'react-redux-form';

export const PeriodsSummaryContainer = styled(Div)`
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  .periods-divider:last-child {
    display: none;
  }

  @media (max-width: ${breakpoints.large}) {
    max-width: 860px;
  }
`;
PeriodsSummaryContainer.displayName = 'SummaryContainer';

type SummaryWrapperProps = {
  loop: number;
};
export const SummaryWrapper = createStyledComponent<FixMe, SummaryWrapperProps>(
  Div,
)`
  display: inline-block;
  width: calc(25% - 4px);
  @media (max-width: ${breakpoints.large}) {
    min-width: 50%;
    text-align: center;
    ${({ loop }) => (loop < 3 ? 'margin-bottom: 40px;' : '')}
    border-bottom: 1px solid ${({ theme, loop }) =>
      loop < 3 ? theme.colors.silverMedium : theme.colors.white};

    > * {
      text-align: left;
    }
  }
`;
SummaryWrapper.displayName = 'SummaryWrapper';

export const ItemWrapper = styled(Div)`
  @media (max-width: ${breakpoints.large}) {
    display: inline-block;
  }
`;
ItemWrapper.displayName = 'ItemWrapper';

export const PeriodTitle = styled.h3`
  color: ${(props) => props.theme.colors.charcoal};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${convertPxToRem(16)};
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 35px;
  text-transform: uppercase;
`;
PeriodTitle.displayName = 'PeriodTitle';

export const ItemContainer = styled(Div)`
  min-height: 48px;
  min-width: 88px;
  border-radius: 2px;
  height: 48px;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  padding: 5px;
  font-size: ${convertPxToRem(14)};
  color: ${(props) => props.theme.colors.charcoal};
  font-weight: 600;
`;
ItemContainer.displayName = 'ItemContainer';

export const ItemContainerGrey = styled(ItemContainer)`
  background-color: ${(props) => props.theme.colors.silverLight};
`;
ItemContainerGrey.displayName = 'ItemContainerGrey';

export const ItemContainerFlex = styled(Div)`
  min-height: 48px;
  min-width: 88px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: ${convertPxToRem(14)};
  color: ${(props) => props.theme.colors.charcoal};
  font-weight: 600;
`;
ItemContainer.displayName = 'ItemContainer';

export const ItemContainerGreyFlex = styled(ItemContainerFlex)`
  background-color: ${(props) => props.theme.colors.silverLight};
`;
ItemContainerGreyFlex.displayName = 'ItemContainerGreyFlex';

export const ItemContainerWhite = styled(ItemContainer)`
  border: 1px solid ${(props) => props.theme.colors.grayLighter};
`;
ItemContainerWhite.displayName = 'ItemContainerWhite';

export const Item = styled(Div)`
  display: inline-flex;
  width: 100%;
  font-size: 12px;
  align-items: center;
  @media (max-width: ${breakpoints.large}) {
    width: auto;
  }
`;
Item.displayName = 'Item';

export const BetweenItemsDiv = styled(Div)`
  width: 30px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.gray};
  text-align: center;
  font-size: ${convertPxToRem(14)};
`;
BetweenItemsDiv.displayName = 'BetweenItemsDiv';

export const ItemAfterDiv = styled(Div)`
  padding-top: 35px;
`;
ItemAfterDiv.displayName = 'ItemAfterDiv';

export const ItemsTitleDiv = styled(Div)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${convertPxToRem(14)};
  margin-bottom: 18px;

  @media (max-width: ${breakpoints.large}) {
    display: inline-block;
    width: 100px;
  }

  > span {
    color: ${(props) => props.theme.colors.black};
    vertical-align: top;
  }
`;
ItemsTitleDiv.displayName = 'ItemsTitleDiv';

export const RoundSpan = styled(Span)`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;
RoundSpan.displayName = 'RoundSpan';

export const SummaryPeriodsDivider = styled(Div)`
  width: 1px;
  background-color: ${(props) => props.theme.colors.silverMedium};
  display: inline-block;
  @media (max-width: ${breakpoints.large}) {
    display: none;
  }
`;
SummaryPeriodsDivider.displayName = 'SummaryPeriodsDivider';

export const LocalFormWrapper = styled(LocalForm)`
  width: 100%;
`;
LocalFormWrapper.displayName = 'LocalFormWrapper';
