import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';
import { isNil } from 'ramda';
import { widthAdjustmentToAllocateForVisitManagement } from 'src/modules/visit-module/visit-module-style-changes-for-hcp-client';
import { connect } from 'react-redux';
import { withPatientSummary } from 'src/domains/patient/utils/with-patient-summary';
import { TabsHeader } from 'src/domains/patient-dashboards/components/tabs-header';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { Tabs } from 'src/domains/diagnostics/components/tabs';
import { PERMISSIONS } from 'src/core/permissions';
import {
  AdditionalInfo,
  INFOS,
} from 'src/domains/diagnostics/components/additional-info';
import { CardMinimizer } from 'src/components/card';

import { tabs, gpTabs } from './graph-wrapper.constants';
import {
  GraphTabsContainer,
  GraphTabsContent,
  GraphWrapperGridContainer,
  GridItemGraph,
  NavContainerOptions,
} from './graph-wrapper.style';
import { getGraphNameFromUrl } from './graph-wrapper.util';

import { FlexibleHeightCard } from '../scenes/graphs/graph.style';
import { connectGraphs } from '../scenes/graphs/graph.container';

export class GraphPage extends React.Component {
  componentDidMount() {
    const {
      changeGraph,
      location: { pathname: path },
      match: { url },
    } = this.props;
    changeGraph(getGraphNameFromUrl(path, url));
  }

  render() {
    const {
      children,
      changeGraph,
      graph,
      match,
      isHCPProfessional,
      hcpPermissions,
      patientPermissions,
      t,
    } = this.props;
    let filteredTabs = (_g) => [];
    if (!isNil(isHCPProfessional)) {
      filteredTabs = isHCPProfessional ? tabs : gpTabs;
    }
    const translatedTabNames = filteredTabs(changeGraph).map((item) => ({
      activeTitle: '',
      name: t(item.name),
      ...item,
    }));
    const hasVisitManagement =
      hcpPermissions && hcpPermissions.includes(PERMISSIONS.VISIT_MANAGEMENT);
    const patientHasEtapes =
      patientPermissions &&
      patientPermissions.includes(PERMISSIONS.ETAPES_PROGRAM);

    const patientHasRPM = patientPermissions.includes(PERMISSIONS.RPM_PROGRAM);

    const patientIsEnrolled = patientHasEtapes || patientHasRPM;
    const visitManagementPresent = hasVisitManagement && patientIsEnrolled;

    return (
      <StickyContainer>
        <GraphWrapperGridContainer
          addExtraMarginBottom={
            graph === 'trend' ||
            graph === 'standard-week' ||
            graph === 'standard-day'
          }
          marginBottom
          gridTemplateRows={'98%'}
        >
          <GridItemGraph
            span="12"
            style={
              visitManagementPresent
                ? widthAdjustmentToAllocateForVisitManagement
                : {}
            }
          >
            <FlexibleHeightCard>
              <Tabs
                render={(children) => (
                  <GraphTabsContainer>{children}</GraphTabsContainer>
                )}
              >
                <NavContainerOptions>
                  <TabsHeader
                    items={translatedTabNames}
                    breakpoint={isHCPProfessional ? 1600 : 2000}
                    active={graph}
                    expanded={true}
                  ></TabsHeader>
                  <AdditionalInfo
                    info={INFOS.graphReports}
                    active={graph}
                    style={{ position: 'absolute', right: '40px', top: '8px' }}
                  />
                  <CardMinimizer link={`/patients/${match.params.id}`} />
                </NavContainerOptions>

                <GraphTabsContent>{children}</GraphTabsContent>
              </Tabs>
            </FlexibleHeightCard>
          </GridItemGraph>
        </GraphWrapperGridContainer>
      </StickyContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  hcpPermissions: state.permissions.permissions,
  patientPermissions: state.patientPermissions,
  ...ownProps,
});

export const GraphPageWrapper = compose(
  withPatientSummary,
  connectGraphs,
  withRouter,
  withTranslation,
  connect(mapStateToProps),
)(GraphPage);
