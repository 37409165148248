import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getConfig } from '@roche/roche-common';
import { selectManifestModuleNameListKeys } from 'src/core/manifest-modules/manifestModules.selectors';

import { appVersion } from '../../manufacturer-info-modal.utils';

import { GeneralInformationViewComponent } from './general-info-view.component';

const rdcpManufactureDate = getConfig().REACT_APP_RDCP_MANUFACTURE_DATE
  ? getConfig().REACT_APP_RDCP_MANUFACTURE_DATE
  : '';

export const GeneralInfoViewContainer = compose(
  connect((state) => ({
    moduleDataList: selectManifestModuleNameListKeys(state),
    appVersion,
    rdcpManufactureDate,
  })),
)(GeneralInformationViewComponent);
