import { PERMISSIONS } from '../../core/permissions';

export const hcpHasPermission = (hcpPermissions?: string[]) =>
  hcpPermissions !== undefined
    ? hcpPermissions.includes(PERMISSIONS.VISIT_MANAGEMENT)
    : false;

const patientHasPermission = (patientPermissions?: string[]) => {
  if (!patientPermissions) return false;
  const patientHasEtapes = patientPermissions.includes(
    PERMISSIONS.ETAPES_PROGRAM,
  );
  const patientHasRPM = patientPermissions.includes(PERMISSIONS.RPM_PROGRAM);
  return patientHasEtapes || patientHasRPM;
};

export const hasVisitModulePermission = (
  hcpPermissions?: string[],
  patientPermissions?: string[],
) => {
  const hasVisitManagement = hcpHasPermission(hcpPermissions);
  const patientIsEnrolled = patientHasPermission(patientPermissions);
  return hasVisitManagement && patientIsEnrolled;
};
