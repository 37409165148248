import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  formatBGMeasurement,
  withGraphLoader,
} from 'src/domains/diagnostics/utils';
import { SIZE } from 'src/domains/diagnostics/components/no-data-disclaimer';
import { BG_UNIT_KEYS } from 'src/domains/diagnostics/store/constants';

import { StatusCardRow } from './components';
import { StatusCardWrapper } from './status-card.style';

const StatusCardWrapperWithLoader = withGraphLoader(
  StatusCardWrapper,
  'general.loading',
  { size: SIZE.SMALL },
);

export const EMPTY_VALUE_PLACEHOLDER = '- - ';

const StatusCardComponent = ({
  bloodGlucoseUnit,
  hasReliableInfo,
  hypoRisk,
  meanBloodGlucose,
  placeholderStatusLabel,
  showPlaceholderStatusLabelAndHideValues,
  showStatusLabels,
  threshold: { glucoseIdealIntervalMin, glucoseIdealIntervalMax },
  variability,
  isLoading,
  hasData,
  t,
}) => {
  const translatedMeanBloodGlucoseLabel = Array.isArray(
    meanBloodGlucose.status.label,
  )
    ? meanBloodGlucose.status.label.reduce(
        (text, key) => `${text} ${t(key)}`,
        '',
      )
    : t(meanBloodGlucose.status.label);

  return (
    <StatusCardWrapperWithLoader isLoading={isLoading}>
      <StatusCardRow
        color={hasReliableInfo && hypoRisk.status.color}
        hasReliableInfo={hasReliableInfo}
        showLabels={showStatusLabels}
        statusTitle={t('dashboard.statusCard.hypoRisk')}
        statusResult={
          hasReliableInfo ? t(hypoRisk.status.label) : EMPTY_VALUE_PLACEHOLDER
        }
        showPlaceholderStatusLabelAndHideValues={
          showPlaceholderStatusLabelAndHideValues
        }
        valueSingle={
          showPlaceholderStatusLabelAndHideValues
            ? EMPTY_VALUE_PLACEHOLDER
            : hypoRisk.value.lbgi
        }
        unit={t('general.units.lbgi')}
        hasData={hasData}
        unitTitle={t('general.units.tooltips.lbgi')}
      />
      <StatusCardRow
        color={showStatusLabels && meanBloodGlucose.status.color}
        hasReliableInfo={showStatusLabels}
        showLabels={showStatusLabels}
        statusTitle={t('dashboard.statusCard.averageBloodGlucose')}
        statusResult={
          showStatusLabels
            ? translatedMeanBloodGlucoseLabel
            : EMPTY_VALUE_PLACEHOLDER
        }
        value={
          showPlaceholderStatusLabelAndHideValues
            ? EMPTY_VALUE_PLACEHOLDER
            : `${formatBGMeasurement(bloodGlucoseUnit)(
                meanBloodGlucose.value,
              )} ${t(BG_UNIT_KEYS[bloodGlucoseUnit])}`
        }
        valueSingle={
          showPlaceholderStatusLabelAndHideValues
            ? EMPTY_VALUE_PLACEHOLDER
            : formatBGMeasurement(bloodGlucoseUnit)(meanBloodGlucose.value)
        }
        unit={t(BG_UNIT_KEYS[bloodGlucoseUnit])}
        hasData={hasData}
      />
      <StatusCardRow
        color={showStatusLabels && variability.status.color}
        hasReliableInfo={showStatusLabels}
        showLabels={showStatusLabels}
        statusTitle={t('dashboard.statusCard.variability')}
        statusResult={
          showStatusLabels
            ? t(variability.status.label)
            : EMPTY_VALUE_PLACEHOLDER
        }
        valueSingle={
          showPlaceholderStatusLabelAndHideValues
            ? EMPTY_VALUE_PLACEHOLDER
            : variability.valueWithoutUnit
        }
        unit={'%'}
        hasData={hasData}
      />
    </StatusCardWrapperWithLoader>
  );
};

export const StatusCard = withTranslation(StatusCardComponent);
