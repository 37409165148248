import React, { Component } from 'react';

import { withGraphLoader } from 'src/domains/diagnostics/utils';
import { colors } from 'src/core/styles';
import { SIZE } from 'src/domains/diagnostics/components/no-data-disclaimer';

import {
  DistributionCardWrapper,
  DistributionCardStatsWrapper,
  DistributionGraphWrapper,
  StickLevelContainer,
} from './distribution-card.style';
import { DistributionTable } from './components/distribution-table';
import { StickLevel } from './components/stick-level/stick-level.component';

import { StackedRadialChart } from '../../components';

const DistributionCardWrapperWithLoader = withGraphLoader(
  DistributionCardWrapper,
  'general.loading',
  { size: SIZE.SMALL },
);

export const DistributionCard = ({
  bloodGlucoseUnit,
  distribution,
  distributionSegments,
  threshold,
  isLoading,
  hasData,
}) => (
  <DistributionCardWrapperWithLoader isLoading={isLoading} hasError={!hasData}>
    <DistributionGraphWrapper>
      <StackedRadialChart diameter={100} segments={distributionSegments} />
    </DistributionGraphWrapper>
    <DistributionCardStatsWrapper>
      <DistributionTable
        bloodGlucoseUnit={bloodGlucoseUnit}
        distribution={distribution}
        threshold={threshold}
      />
    </DistributionCardStatsWrapper>
  </DistributionCardWrapperWithLoader>
);

export class StickDistributionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heightContentDistributionCard: () => {
        const distributionCardHTHM =
          document.getElementsByClassName('distributionCard');
        if (distributionCardHTHM[3]) {
          // paddingTop - paddingBottom - minHeightOfEachLevel(20x5) - separationBetweenLevels(3x4)
          return distributionCardHTHM[3].offsetHeight;
        }
        return 222;
      },
    };
  }
  componentDidMount() {
    this.adaptToWindowSize();
    window.addEventListener('resize', this.adaptToWindowSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adaptToWindowSize);
  }

  render() {
    const { distribution, threshold, isLoading, hasData } = this.props;
    const { heightContentDistributionCard } = this.state;
    return (
      <DistributionCardWrapperWithLoader
        isLoading={isLoading}
        hasError={!hasData}
      >
        <StickLevelContainer>
          <StickLevel
            threshold={threshold.upperHyperThreshold}
            value={distribution.aboveveryhigh}
            bg={colors.blueLight}
            height={
              Math.round(
                (distribution.aboveveryhigh * heightContentDistributionCard) /
                  100,
              ) + 20
            }
            text="target-ranges.actualHyper"
            first
          />
          <StickLevel
            threshold={threshold.glucoseIdealIntervalMax}
            value={distribution.abovehigh}
            bg={colors.blueLight}
            height={
              Math.round(
                (distribution.abovehigh * heightContentDistributionCard) / 100,
              ) + 20
            }
            text="target-ranges.hyper"
          />
          <StickLevel
            threshold={threshold.glucoseIdealIntervalMin}
            value={distribution.within}
            bg={colors.trafficGreen}
            height={
              Math.round(
                (distribution.within * heightContentDistributionCard) / 100,
              ) + 20
            }
            text="target-ranges.inTarget"
          />
          <StickLevel
            threshold={threshold.hypoglycemiaThreshold}
            value={distribution.below}
            bg={colors.trafficOrange}
            height={
              Math.round(
                (distribution.below * heightContentDistributionCard) / 100,
              ) + 20
            }
            text="target-ranges.warning"
          />
          <StickLevel
            value={distribution.hypoglycaemia}
            bg={colors.red}
            height={
              Math.round(
                (distribution.hypoglycaemia * heightContentDistributionCard) /
                  100,
              ) + 20
            }
            text="target-ranges.hypo"
            last
          />
        </StickLevelContainer>
      </DistributionCardWrapperWithLoader>
    );
  }

  adaptToWindowSize = () => {
    const distributionCardHTHM =
      document.getElementsByClassName('distributionCard');
    let heightContentDistributionCard = 0;
    if (distributionCardHTHM[3]) {
      // paddingTop - paddingBottom - minHeightOfEachLevel(20x5) - separationBetweenLevels(3x4)
      heightContentDistributionCard =
        distributionCardHTHM[0].offsetHeight -
        distributionCardHTHM[1].offsetHeight -
        18 -
        11 -
        100 -
        12;
    }
    this.setState({ ...this.state, heightContentDistributionCard });
  };
}
