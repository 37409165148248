import React from 'react';
import { TFunction } from 'i18next';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height?: number,
  fillColor?: string,
  minX?: number,
  minY?: number,
  t: TFunction,
};

export const HypoLimitIconComponent = ({
  height = 12,
  fillColor = colors.red,
  minX = 0,
  minY = -7,
  t,
}: Props) => {
  const originalWidth = 12;
  const originalHeight = 12;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.hypoLimit')}
      width={height * aspectRatio}
      height={height}
      minX={minX}
      minY={minY}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path d="M0 1.5h15.591V0H0z" fill={fillColor} fillRule="evenodd" />
    </SvgIcon>
  );
};

export const HypoLimitIcon = withTranslation(HypoLimitIconComponent);
