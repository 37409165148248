import { TFunction } from 'i18next';
import { History } from 'history';
import { match } from 'react-router';
import { DeactivatePatientParamsPayloadType } from './store';

export type DeactivatePatientProps = {
  t: TFunction;
  createModal: (any) => void;
  destroyModal: () => void;
  deactivatePatientStart: (params: DeactivatePatientParamsPayloadType) => void;
  history: History;
  match: match<{ id: string }>;
};

export enum DeactivatePatientMotives {
  OTHERS = 'OTHERS',
  CHANGE_OF_PROFESSIONAL = 'CHANGE_OF_PROFESSIONAL',
  SERVICE_NOT_NEEDED = 'SERVICE_NOT_NEEDED',
}
