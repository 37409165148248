import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

import { Professional } from './get-professionals.types';

export const FetchProfessionalLoaderImpl = (
  departmentId: number,
  accessToken: string,
): Promise<Professional> => {
  return getJSON(
    endpointWithParams(ENDPOINTS.getProfessionals, { departmentId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const FetchProfessionalTransformImpl = ({
  model,
}: {
  model: Professional[];
}) => model;

export const FetchProfessionalServiceImpl =
  (loader, transform) => (query, accessToken, gigyaToken) =>
    loader(query, accessToken, gigyaToken).then(transform);
