import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors, fontSize } from 'src/core';
import {
  LOGBOOK_TYPE_STATS,
  LOGBOOK_TYPE_DIARY,
  LOGBOOK_TYPE_DETAILS,
} from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { Button } from 'src/components';
import { DisclaimerIcon } from 'src/assets/icons';
import { RenderIf } from 'src/utils';

import {
  ChangeTypeContainerDiv,
  ChangeTypeButton,
  GraphSettingsRowContainerDiv,
  GraphSettingsWrapper,
  LogbookSettingsRowContainerDiv,
  CardSectionTitleDiv,
} from './graph-settings-row.style';

import {
  LineGraphIcon,
  BoxGraphIcon,
  LogbookStatsIcon,
  LogbookDiaryIcon,
  LogbookIcon,
} from '../../assets/icons';

const renderChangeTypeButtons = (graphType, changeGraphType, t) => (
  <ChangeTypeContainerDiv>
    <ChangeTypeButton
      active={graphType === 'details'}
      onClick={() => changeGraphType('details')}
      title={t('graphs.iconTitles.lineGraph')}
    >
      <LineGraphIcon
        fillColor={graphType === 'details' ? colors.white : colors.grayDark}
        height={18}
      />
    </ChangeTypeButton>
    <ChangeTypeButton
      active={graphType === 'trend'}
      onClick={() => changeGraphType('trend')}
      title={t('graphs.iconTitles.boxGraph')}
    >
      <BoxGraphIcon
        fillColor={graphType === 'trend' ? colors.white : colors.grayDark}
        height={18}
      />
    </ChangeTypeButton>
  </ChangeTypeContainerDiv>
);

const renderChangeTypeButtonsLogbook = (logbookType, changeLogbookType) => (
  <ChangeTypeContainerDiv>
    <ChangeTypeButton
      active={logbookType === LOGBOOK_TYPE_DETAILS}
      onClick={() => changeLogbookType(LOGBOOK_TYPE_DETAILS)}
    >
      <LogbookIcon
        fillColor={
          logbookType === LOGBOOK_TYPE_DETAILS ? colors.white : colors.grayDark
        }
        height={18}
      />
    </ChangeTypeButton>
    <ChangeTypeButton
      active={logbookType === LOGBOOK_TYPE_DIARY}
      onClick={() => changeLogbookType(LOGBOOK_TYPE_DIARY)}
    >
      <LogbookDiaryIcon
        fillColor={
          logbookType === LOGBOOK_TYPE_DIARY ? colors.white : colors.grayDark
        }
        height={18}
      />
    </ChangeTypeButton>
    <ChangeTypeButton
      active={logbookType === LOGBOOK_TYPE_STATS}
      onClick={() => changeLogbookType(LOGBOOK_TYPE_STATS)}
    >
      <LogbookStatsIcon
        fillColor={
          logbookType === LOGBOOK_TYPE_STATS ? colors.white : colors.grayDark
        }
        height={18}
      />
    </ChangeTypeButton>
  </ChangeTypeContainerDiv>
);

export const LogbookSettingsRow = (props) => {
  const {
    logbookType,
    changeLogbookType,
    absolutePosition,
    onClickDisclaimer,
    t,
  } = props;
  return (
    <GraphSettingsWrapper
      isLogbook={true}
      absolutePosition={absolutePosition}
      top="3rem"
      alignItems="center"
    >
      <Button
        label={t('general.disclaimers')}
        fontSize={fontSize.subheading}
        fontWeight="bold"
        mr={3}
        buttonStyle="info"
        onClick={onClickDisclaimer}
        icon={
          <DisclaimerIcon
            height="17"
            withBorder
            iconColor={colors.white}
            borderFillColor={colors.blueMarine}
            borderColor={colors.transparent}
          />
        }
      />
      <LogbookSettingsRowContainerDiv>
        {renderChangeTypeButtonsLogbook(logbookType, changeLogbookType)}
      </LogbookSettingsRowContainerDiv>
    </GraphSettingsWrapper>
  );
};

export const GraphSettingsRow = (props) => {
  const {
    graphType,
    translatedGraphType,
    changeGraphType,
    showChangeGraphToggle,
    children,
    t,
  } = props;
  return (
    <GraphSettingsWrapper>
      <RenderIf validate={showChangeGraphToggle}>
        <CardSectionTitleDiv>{translatedGraphType}</CardSectionTitleDiv>
        <GraphSettingsRowContainerDiv>
          {renderChangeTypeButtons(graphType, changeGraphType, t)}
        </GraphSettingsRowContainerDiv>
      </RenderIf>
      {children}
    </GraphSettingsWrapper>
  );
};

export const GraphSettingsRowComponent = withTranslation(GraphSettingsRow);

export const LogbookSettingsRowComponent = withTranslation(LogbookSettingsRow);
