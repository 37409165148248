import { path } from 'ramda';
import { createSelector } from 'reselect';

export const selectEditPatientState = path(['editPatient']);

export const selectIsFetchingPatientForEdit = createSelector(
  selectEditPatientState,
  path(['isFetchingPatient']),
);

export const selectFetchingPatientForEditHasError = createSelector(
  selectEditPatientState,
  path(['fetchingPatientHasError']),
);

export const selectFetchingPatientForEditWasSuccessful = createSelector(
  selectEditPatientState,
  path(['fetchingPatientWasSuccessful']),
);
