import React from 'react';
import { testId } from '@roche/roche-common';

import { Portal } from 'src/domains/diagnostics/components/portal';

import { ToolTipContainerDiv } from './tool-tip.style';

export const ToolTip = ({ x, y, children }) => (
  <Portal rootId="tool-tip-root">
    <ToolTipContainerDiv x={x} y={y} {...testId('tooltip', 'container')}>
      {children}
    </ToolTipContainerDiv>
  </Portal>
);
