import {
  assocPath,
  compose as ramdaCompose,
  isEmpty,
  isNil,
  path,
  pick,
  pipe,
  dissoc,
} from 'ramda';
import * as React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
  selectAccessToken,
  selectHcpFullname,
  selectHcpId,
  fetchOidcTokensSuccess,
  validateSessionStart,
  selectGigyaToken,
  selectSession,
} from 'src/app/session';

import { store } from 'src/app/app';
import { selectPatient, selectPatientFhirId } from 'src/core';
import { getConfig } from '@roche/roche-common';
import { injectEpicFactory } from 'src/epic';
import i18n from 'src/i18n';
import { injectModuleReducer } from 'src/modules';
import { theme } from 'src/theme';

import { withPatientSummary } from 'src/domains/patient/utils/with-patient-summary';
import { FETCH_PATIENT_REQUEST } from 'src/core/patient/patient.constant';
import {
  PATIENT_DATE_ACTIONS,
  FETCH_PATIENT_DATE_RANGE_REQUEST,
} from 'src/core/patient-date-range/patient-date-range.constant';
import { fetchPatientDateRangeRequest } from 'src/core/patient-date-range/patient-date-range.action';
import { dateRangeConnector } from 'src/core/patient-date-range/patient-date-range.selector';
import { GET_CURRENT_USER } from 'src/core/user/user.constants';
import {
  selectEC6UserLanguage,
  selectEC6UserUnitMeasurement,
  selectEC6TimeFormat,
  buildLanguageCode,
} from 'src/core/user/user.selectors';
import { fetchPatientRequest } from 'src/domains/diagnostics/core';
import { ModuleBundleComponent } from 'src/navigation/module-bundle.component';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { widthAdjustmentToAllocateForVisitManagement } from 'src/modules/visit-module/visit-module-style-changes-for-hcp-client';
import {
  selectPatientPermissions,
  selectPermissions,
  selectFhirPermission,
  selectJelloPermission,
  PERMISSIONS,
} from 'src/core/permissions';
import { hasVisitModulePermission } from 'src/modules/visit-module/visit-module-permissions';
import { Column, LoadingRing, withLoader } from 'src/components';
import {
  INFOS,
  PATTERNS,
  AdditionalInfo,
} from 'src/domains/diagnostics/components/additional-info';
import { checkDataSharingConsentStart } from '../patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.actions';
import {
  selectDataSharingHasBeenCalled,
  selectCheckDataSharingConsentError,
  selectCheckDataSharingConsentSuccess,
  selectIsCheckingDataSharingConsent,
  selectDataSharingConsentResponse,
} from '../patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.selector';
import { BgNonConsentsViewComponent } from '../patient-dashboards/bg/scenes/dashboard/widgets/bg-non-consents-view/bg-non-consents-view.component';
import { RenderIf, RenderIfElse } from 'src/utils';
import { withJelloWrapper } from '../patient/utils/with-jello-wrapper';
import { setForcePersist } from 'src/store';

const {
  REACT_APP_EC6_API_ROOT,
  REACT_APP_PI_BACKEND_API_ROOT,
  REACT_APP_API_GATEWAY,
  REACT_APP_API_MULE,
} = getConfig();

export const CONFIG = {
  baseBackendPIHost: REACT_APP_PI_BACKEND_API_ROOT,
  baseObservationsHost: REACT_APP_API_GATEWAY,
  baseEconectaHost: REACT_APP_EC6_API_ROOT,
  baseMuleHost: REACT_APP_API_MULE,
};

const configReducer =
  (initialConfig) =>
  (state = dissoc('_persist', initialConfig), action) => {
    switch (action.type) {
      case 'REFRESH_OIDC_TOKENS_SUCCESS':
        setForcePersist(action);
        return {
          ...state,
          error: null,
          isGettingToken: false,
          accessToken: action.payload.accessToken,
          token: action.payload.accessToken,
          ...action.payload,
        };
      case FETCH_PATIENT_REQUEST.SUCCESS:
        return ramdaCompose(
          assocPath(['patientId'], action.payload.id),
          assocPath(['patient'], action.payload),
        )(state);

      case GET_CURRENT_USER.SUCCESS:
        return {
          ...state,
          locale: buildLanguageCode(action.payload.user),
          hcpId: action.payload.user.id,
          bloodGlucoseUnit: action.payload.user.unitMeasurement,
          loggedInAs: action.payload.user.fullname,
        };

      case PATIENT_DATE_ACTIONS.CLEAR:
        return {
          ...state,
          dateRange: {
            endDate: undefined,
            startDate: undefined,
            lastMeasurementDate: undefined,
            firstMeasurementDate: undefined,
          },
        };

      case PATIENT_DATE_ACTIONS.SET_DATES:
        return ramdaCompose(
          assocPath(['dateRange', 'endDate'], action.payload.endDate),
          assocPath(['dateRange', 'startDate'], action.payload.startDate),
        )(state);

      case FETCH_PATIENT_DATE_RANGE_REQUEST.SUCCESS:
        return ramdaCompose(
          assocPath(
            ['dateRange', 'lastMeasurementDate'],
            action.payload.latestMeasurement,
          ),
          assocPath(
            ['dateRange', 'firstMeasurementDate'],
            action.payload.firstMeasurement,
          ),
        )(state);
      default:
        return state;
    }
  };

const injectEpic = injectEpicFactory([]);

const addLifeCycles = lifecycle<AdvancedIndicatorsBundleProps, {}>({
  componentWillReceiveProps(nextProps) {
    const { onFetchOidcTokensSuccess, onValidateSessionStart, session } =
      this.props;
    const { session: nextSession } = nextProps;
    if (nextSession.accessToken === '') {
      onFetchOidcTokensSuccess(session);
      onValidateSessionStart();
    }
  },
  componentWillMount() {
    const {
      onFetchDateRange,
      dateRange,
      patientFhirId,
      hasUserFhirPermission,
    } = this.props;
    const patientId = this.props.match.params.id;

    if (isNil(dateRange.firstMeasurementDate) && patientFhirId) {
      onFetchDateRange({ patientId, hasUserFhirPermission, patientFhirId });
    }
  },

  componentDidMount() {
    const { onFetchPatient, patient, match } = this.props;
    const patientId = match.params.id;
    if (isEmpty(patient) || isNil(patient)) {
      onFetchPatient({ patientId });
    }
  },

  componentDidUpdate(prevProps) {
    const {
      onFetchDateRange,
      dateRange,
      patientFhirId,
      hasUserFhirPermission,
      hcpPermissions,
      checkDataSharingConsent,
      dataSharingHasBeenCalled,
      match: { params },
    } = this.props;
    const patientId = params.id;
    if (
      prevProps.patientFhirId !== patientFhirId &&
      isNil(dateRange.firstMeasurementDate) &&
      patientFhirId
    ) {
      onFetchDateRange({ patientId, hasUserFhirPermission, patientFhirId });
    }
    if (
      hcpPermissions?.includes(PERMISSIONS.ROLE_PLATFORM) &&
      hcpPermissions?.includes(PERMISSIONS.ROLE_FHIR) &&
      patientFhirId &&
      !dataSharingHasBeenCalled
    ) {
      checkDataSharingConsent(patientFhirId);
    }
  },
});

type AdvancedIndicatorsBundleQueryParams = {
  id: string;
};

type AdvancedIndicatorsBundleProps = {
  onFetchPatient: ({ patientId }: { patientId: string }) => any;
  goToHome: any;
  onFetchDateRange: ({
    patientId,
    hasUserFhirPermission,
    patientFhirId,
  }: {
    patientId: string;
    hasUserFhirPermission?: boolean;
    patientFhirId?: string;
  }) => void;
  checkDataSharingConsent: (id: string) => void;
  onFetchOidcTokensSuccess: (session: any) => void;
  onValidateSessionStart: () => void;
  patient: FixMe;
  dateRange: FixMe;
  match: match<AdvancedIndicatorsBundleQueryParams>;
  hcpPermissions?: string[];
  patientPermissions?: string[];
  session: FixMe;
  accessToken?: FixMe;
  INFOS: object;
  AdditionalInfo: FixMe;
  store: FixMe;
  patientFhirId: string;
  hasUserFhirPermission: boolean;
  dataSharingHasBeenCalled: boolean;
  isCheckingDataSharingConsent: boolean;
  checkDataSharingConsentSuccessful: boolean;
  checkDataSharingConsentHasError: boolean;
  dataSharingConsentResponse: any;
};

export const AdvancedIndicatorsBundle = (
  props: AdvancedIndicatorsBundleProps,
) => {
  const visitModuleAllowed = hasVisitModulePermission(
    props.hcpPermissions,
    props.patientPermissions,
  );
  const defaultStyle = {
    width: '100%',
    margin: '0 1rem',
  };

  const isConsentRevoked =
    props.checkDataSharingConsentHasError &&
    props.dataSharingConsentResponse === 409;

  const isConsentRevokedError =
    props.checkDataSharingConsentHasError &&
    props.dataSharingConsentResponse !== 409;

  if (isConsentRevokedError) {
    props.goToHome(`/home`);
  }

  props = {
    ...props,
    INFOS: { ...INFOS, ...PATTERNS },
    AdditionalInfo,
  };

  return (
    <>
      <RenderIf validate={props.isCheckingDataSharingConsent}>
        <Column align="center" height="100vh" justifyContent="center">
          <LoadingRing infinite />
        </Column>
      </RenderIf>
      <RenderIfElse validate={isConsentRevoked && !isConsentRevokedError}>
        <BgNonConsentsViewComponent
          patientNameSurname={props.patient.fullName}
          gpDashboard={false}
        />
        <div
          style={
            visitModuleAllowed
              ? widthAdjustmentToAllocateForVisitManagement
              : defaultStyle
          }
        >
          <ModuleBundleComponent
            bundleWillLoad={async () => {
              const { createPatternsAndIndicators } = await import(
                /* webpackChunkName: "patternsAndIndicators" */ '@roche/rdcp-hcp-pi-client'
              );
              return {
                component: createPatternsAndIndicators(
                  store as FixMe,
                  injectModuleReducer,
                  injectEpic,
                  { ...CONFIG, ...props },
                  configReducer,
                  theme,
                  i18n,
                ),
              };
            }}
            bundleDidLoad={(Component: React.ComponentType<any>) => (
              <Component />
            )}
          />
        </div>
      </RenderIfElse>
    </>
  );
};

const AdvancedIndicatorsBundleContainer = compose<
  AdvancedIndicatorsBundleProps,
  {}
>(
  connect(
    createStructuredSelector({
      patient: pipe(
        selectPatient,
        pick([
          'id',
          'firstName',
          'fullName',
          'surName',
          'dateOfBirth',
          'diabetesType',
          'healthCareSystemId',
          'centerName',
          'fhirId',
        ]),
      ),
      patientId: (state, ownProps) => path(['match', 'params', 'id'], ownProps),
      authorizationToken: selectAccessToken,
      loggedInAs: selectHcpFullname,
      hcpId: selectHcpId,
      locale: selectEC6UserLanguage,
      accessToken: selectAccessToken,
      gigyaApiKey: selectGigyaToken,
      bloodGlucoseUnit: selectEC6UserUnitMeasurement,
      dateRange: dateRangeConnector,
      dateActionType: (state) => 'PATIENT/SET_DATES',
      hcpPermissions: selectPermissions,
      patientPermissions: selectPatientPermissions,
      session: selectSession,
      timeFormat: selectEC6TimeFormat,
      hasUserFhirPermission: selectFhirPermission,
      patientFhirId: selectPatientFhirId,
      isJelloActivated: selectJelloPermission,
      dataSharingHasBeenCalled: selectDataSharingHasBeenCalled,
      dataSharingConsentResponse: selectDataSharingConsentResponse,
      isCheckingDataSharingConsent: selectIsCheckingDataSharingConsent,
      checkDataSharingConsentSuccessful: selectCheckDataSharingConsentSuccess,
      checkDataSharingConsentHasError: selectCheckDataSharingConsentError,
    }),
    mapDispatchers({
      goToHome: (path) => push(path),
      onFetchPatient: fetchPatientRequest.start,
      checkDataSharingConsent: checkDataSharingConsentStart,
      onFetchDateRange: fetchPatientDateRangeRequest.start,
      onFetchOidcTokensSuccess: fetchOidcTokensSuccess,
      onValidateSessionStart: validateSessionStart,
    }),
  ),
  addLifeCycles,
  withPatientSummary,
  withLoader({
    loaderProps: {
      infinite: true,
      flexibleHeight: true,
      minHeight: '500',
    },
    validators: {
      dateRange: (dateRange) => {
        if (
          !!dateRange.dataSharingCalled &&
          dateRange.dataSharingResponse === 409
        ) {
          return true;
        } else {
          return !!dateRange.startDate && !!dateRange.endDate;
        }
      },
      patientId: (patientId) => !!patientId,
      authorizationToken: (authorizationToken) => !!authorizationToken,
      loggedInAs: (loggedInAs) => !!loggedInAs,
      hcpId: (hcpId) => !!hcpId,
      accessToken: (accessToken) => !!accessToken,
      gigyaApiKey: (gigyaApiKey) => !!gigyaApiKey,
      bloodGlucoseUnit: (bloodGlucoseUnit) => !!bloodGlucoseUnit,
      dateActionType: (dateActionType) => !!dateActionType,
      hcpPermissions: (hcpPermissions) => !!hcpPermissions,
      patientPermissions: (patientPermissions) => !!patientPermissions,
      session: (session) => !!session,
    },
  }),
)(
  withJelloWrapper
    ? withJelloWrapper(AdvancedIndicatorsBundle)
    : AdvancedIndicatorsBundle,
);

export const AugmentedAdvancedIndicatorsBundleContainer = compose(withRouter)(
  AdvancedIndicatorsBundleContainer,
);
