import { createAuthHeader, postJSON } from 'src/utils/service/service.utils';

import {
  RemoveDeviceLoaderImplType,
  RemoveDeviceServiceImplType,
} from './remove-device.types';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const RemoveDeviceLoaderImpl: RemoveDeviceLoaderImplType = (
  accessToken: string,
  gigyaToken: string,
  patientId: string,
  deviceId: number,
) => {
  return postJSON(
    endpointWithParams(ENDPOINTS.removeDevice, { patientId, deviceId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

export const RemoveDeviceServiceImpl: RemoveDeviceServiceImplType =
  (load: RemoveDeviceLoaderImplType) =>
  (accessToken, gigyaToken, patientId, deviceId) =>
    load(accessToken, gigyaToken, patientId, deviceId);
