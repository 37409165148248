import * as React from 'react';

import { XIcon } from 'src/assets/icons';
import { Block, Button, LocalizedText, WarningBadge } from 'src/components';
import { ButtonReset, colors } from 'src/core/styles';
import { translate } from 'src/i18n';
import { CommonHeader, ModalBody } from 'src/widgets/modal';

export const TargetRangesErrorModal = ({ destroyModal }) => (
  <ModalBody>
    <CommonHeader>
      <strong>
        <LocalizedText
          fontSize={4}
          textKey="target-ranges.modal.saveTargetRangesTitle"
        />
      </strong>
      <ButtonReset
        onClick={() => {
          destroyModal();
        }}
      >
        <XIcon height={14} fillColor={colors.white} />
      </ButtonReset>
    </CommonHeader>
    <Block py={2} px={6}>
      <Block p={4}>
        <Block pb={2} mb={3} display="flex" justifyContent="center">
          <WarningBadge size={75} />
        </Block>
        <Block pb={3} mb={4} display="flex" justifyContent="center">
          <strong>
            <LocalizedText textKey="requestsLoading.error" />
          </strong>
        </Block>
        <Block display="flex" justifyContent="center">
          <Button
            autoFocus
            label={translate('target-ranges.modal.continue')}
            onClick={() => {
              destroyModal();
            }}
          />
        </Block>
      </Block>
    </Block>
  </ModalBody>
);
