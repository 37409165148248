import React from 'react';
import { checkboxChangedCountly } from 'src/domains/diagnostics/components/checkbox/checkbox.utils';

import { hasValue } from 'src/utils';
import { CheckmarkIcon } from 'src/assets/icons';

import {
  CheckboxCheckMark,
  CheckboxCheckMarkContainer,
  CheckboxContainer,
  CheckboxControl,
  CheckboxInput,
} from './checkbox.style';

export class Checkbox extends React.Component {
  render() {
    const {
      id = '',
      label,
      labelBeforeCheckbox = false,
      size,
      disabled = false,
      modelPath,
      graph = '',
      graphType = '',
      expandedGraph = false,
      ...other
    } = this.props;
    const ControlledOrDefaultInput = hasValue(modelPath)
      ? CheckboxControl
      : CheckboxInput;
    return (
      <CheckboxContainer
        htmlFor={id}
        leftPadding={labelBeforeCheckbox ? 0 : 25}
        rightPadding={!labelBeforeCheckbox ? 0 : 25}
        disabled={disabled}
      >
        {label}
        <ControlledOrDefaultInput
          id={id}
          type="checkbox"
          disabled={disabled}
          model={modelPath}
          onChange={() => {
            checkboxChangedCountly(graph, expandedGraph, graphType);
          }}
          {...other}
        />
        <CheckboxCheckMarkContainer
          labelBeforeCheckbox={labelBeforeCheckbox}
          size={size}
          disabled={disabled}
        >
          <CheckboxCheckMark>
            <CheckmarkIcon height={size / 2} />
          </CheckboxCheckMark>
        </CheckboxCheckMarkContainer>
      </CheckboxContainer>
    );
  }
}
