// @ts-nocheck
import * as React from 'react';
import { map } from 'ramda';
import { LocalForm, actions } from 'react-redux-form';
import { RenderIf } from 'src/utils';
import {
  LocalizedText,
  Table,
  TableRow,
  TableCell,
  ControlText,
} from 'src/components';

import { RangeProps, RangeState } from './range.types';
import {
  RangeTitle,
  RangeSection,
  FormContainer,
  RangeTableBody,
  SpanVeryHigh,
  SpanHigh,
  SpanInTarget,
  SpanLow,
  SpanVeryLow,
  InputCell,
  InputContainer,
  Unit,
  LabelCell,
  InputContainerDown,
  RangeError,
  InputTextRanges,
  RangeCGMTitle,
} from './range.styles';

import { getUnit, getLimits } from './range.utils';

import { RangeValidators, RangeErrors } from './range.validators';
import { testId } from '@roche/roche-common';

const { isRequired, isValidVal, isValidDec, isBiggerThan, isValidNum } =
  RangeValidators;

export class RangeComponent extends React.Component<
  RangeProps,
  RangeState,
  any
> {
  public formDispatch;

  /* istanbul ignore next */
  public componentWillReceiveProps(newProps) {
    if (!!this.formDispatch && newProps.range !== this.props.range) {
      this.formDispatch(actions.load(this.props.form, newProps.range));
    }
  }

  public render() {
    const { form, range, unit, setDirty, withLabel } = this.props;
    const tUnit = getUnit(unit);
    const disableVeryLow = ['afterEating', 'beforeBed'].indexOf(form) > -1;
    const initialState = range;
    return (
      <RangeSection className={form} withLabel={withLabel}>
        <FormContainer>
          <LocalForm
            model={form}
            initialState={initialState}
            getDispatch={(dispatch) => (this.formDispatch = dispatch)}
            onChange={this.validate}
            onUpdate={(form) => this.notifyErrors(form)}
          >
            <RenderIf validate={form !== 'nonMealRelated'}>
              <RangeTitle>
                <LocalizedText textKey={`target-ranges.${form}`} />
              </RangeTitle>
            </RenderIf>
            <RenderIf validate={form === 'nonMealRelated'}>
              <RangeCGMTitle>
                <LocalizedText textKey={''} />
              </RangeCGMTitle>
            </RenderIf>
            <Table>
              <RangeTableBody>
                <TableRow>
                  <RenderIf validate={withLabel}>
                    <LabelCell>
                      <LocalizedText textKey={'target-ranges.actualHyper'} />
                    </LabelCell>
                  </RenderIf>
                  <TableCell>
                    <SpanVeryHigh className={`veryHigh-${form}`} />
                  </TableCell>
                  <InputCell>
                    <InputContainer>
                      <ControlText
                        id="actualHyper"
                        component={InputTextRanges}
                        model=".actualHyper"
                        maxLength="4"
                        onKeyUp={() => setDirty()}
                        mapProps={{
                          hasError: ({ fieldValue }) => !fieldValue.valid,
                        }}
                        {...testId(`target-ranges-${form}`, 'actualHyper')}
                      />
                      <RangeError
                        id="error.actualHyper"
                        model={'.actualHyper'}
                        messages={this.getMessages('actualHyper', 'hyper')}
                      />
                      <Unit>{tUnit}</Unit>
                    </InputContainer>
                  </InputCell>
                </TableRow>
                <TableRow>
                  <RenderIf validate={withLabel}>
                    <LabelCell>
                      <LocalizedText textKey={'target-ranges.hyper'} />
                    </LabelCell>
                  </RenderIf>
                  <TableCell>
                    <SpanHigh className={`high-${form}`} />
                  </TableCell>
                  <InputCell>
                    <InputContainer>
                      <ControlText
                        id="hyper"
                        component={InputTextRanges}
                        model=".hyper"
                        maxLength="4"
                        onKeyUp={() => setDirty()}
                        mapProps={{
                          hasError: ({ fieldValue }) => !fieldValue.valid,
                        }}
                        {...testId(`target-ranges-${form}`, 'hyper')}
                      />
                      <RangeError
                        id="error.hyper"
                        model={'.hyper'}
                        messages={this.getMessages('hyper', 'warning')}
                      />
                      <Unit>{tUnit}</Unit>
                    </InputContainer>
                  </InputCell>
                </TableRow>
                <TableRow>
                  <RenderIf validate={withLabel}>
                    <LabelCell>
                      <LocalizedText textKey={'target-ranges.inTarget'} />
                    </LabelCell>
                  </RenderIf>
                  <TableCell>
                    <SpanInTarget className={`inTarget-${form}`} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <RenderIf validate={withLabel}>
                    <LabelCell>
                      <LocalizedText textKey={'target-ranges.warning'} />
                    </LabelCell>
                  </RenderIf>
                  <TableCell>
                    <SpanLow className={`low-${form}`} />
                  </TableCell>
                  <InputCell>
                    <InputContainerDown>
                      <ControlText
                        id="warning"
                        component={InputTextRanges}
                        model=".warning"
                        maxLength="4"
                        onKeyUp={() => setDirty()}
                        mapProps={{
                          hasError: ({ fieldValue }) => !fieldValue.valid,
                        }}
                        {...testId(`target-ranges-${form}`, 'warning')}
                      />
                      <RangeError
                        id="error.warning"
                        model={'.warning'}
                        messages={this.getMessages('warning', 'hypo')}
                      />
                      <Unit>{tUnit}</Unit>
                    </InputContainerDown>
                  </InputCell>
                </TableRow>
                <TableRow>
                  <RenderIf validate={withLabel}>
                    <LabelCell>
                      <LocalizedText textKey={'target-ranges.hypo'} />
                    </LabelCell>
                  </RenderIf>
                  <TableCell>
                    <SpanVeryLow className={`veryLow-${form}`} />
                  </TableCell>
                  <InputCell>
                    <InputContainerDown>
                      <ControlText
                        id="hypo"
                        component={InputTextRanges}
                        model=".hypo"
                        disabled={disableVeryLow}
                        maxLength="4"
                        onKeyUp={() => setDirty()}
                        mapProps={{
                          hasError: ({ fieldValue }) => !fieldValue.valid,
                        }}
                        changeAction={(model, value) => this.changeHypo(value)}
                        {...testId(`target-ranges-${form}`, 'hypo')}
                      />
                      <RenderIf
                        validate={
                          ['nonMealRelated', 'beforeEating'].indexOf(form) > -1
                        }
                      >
                        <RangeError
                          id="error.hypo"
                          model={'.hypo'}
                          messages={this.getMessages('hypo')}
                        />
                      </RenderIf>
                      <Unit>{tUnit}</Unit>
                    </InputContainerDown>
                  </InputCell>
                </TableRow>
              </RangeTableBody>
            </Table>
          </LocalForm>
        </FormContainer>
      </RangeSection>
    );
  }
  private notifyErrors = (data) => {
    const { form, setError } = this.props;
    setError(`${form}Valid`, data.$form.valid);
  };
  private changeHypo = (value: string) => {
    const { onChangeHypo, form } = this.props;
    if (onChangeHypo) {
      onChangeHypo(value);
    }
    this.formDispatch(actions.change(`${form}.hypo`, value));
  };
  private validate = (values) => {
    const { unit, form, setRange } = this.props;
    const l = getLimits(unit);
    const models = ['actualHyper', 'hyper', 'warning', 'hypo'];
    models.forEach((model, i) => {
      this.formDispatch(
        actions.validate(`${form}.${model}`, {
          isRequired,
          isValidNum,
          isValidVal: (v) => isValidVal(v, l[form][model]),
          isValidDec: (v) => isValidDec(v, unit),
          isBiggerThan: (v) => isBiggerThan(v, values[models[i + 1]]),
        }),
      );
    });
    setRange(form, values);
  };
  private getMessages = (model: string, previous?: string) => {
    const { unit, t, form } = this.props;
    const l = getLimits(unit);
    const e = map(
      (msg) => t(msg, { value: t(`target-ranges.${previous}`) }),
      RangeErrors,
    );
    const { isRequired, isValidNum, isValidDec, isValidVal, isBiggerThan } = e;
    return {
      isRequired,
      isValidNum,
      isValidVal: () =>
        `${isValidVal} ${l[form][model][0]}-${l[form][model][1]}.`,
      isValidDec,
      isBiggerThan,
    };
  };
}
