import React from 'react';
import { TFunction } from 'i18next';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height?: number,
  fillColor?: string,
  t: TFunction,
};

const TargetRangeIconComponent = ({
  height = 19,
  fillColor = colors.transparentGreen,
  t,
}: Props) => {
  const originalWidth = 19;
  const originalHeight = 19;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.targetRangeIcon')}
      width={height * aspectRatio}
      height={height}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <path d="M0 15h15V0H0z" fill={fillColor} fillRule="evenodd" />
    </SvgIcon>
  );
};

export const TargetRangeIcon = withTranslation(TargetRangeIconComponent);
