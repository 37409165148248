import * as React from 'react';
import ReactSelect from 'react-select';

import { ArrowIcon } from 'src/assets/icons/arrow-icon';

import { ComboDropdownProps } from './combo.types';

import { styleSortDropdown } from './combo.style';

export const ComboComponent: React.FunctionComponent<ComboDropdownProps> = ({
  disabled = false,
  id,
  label,
  onChange,
  options,
  placeholder,
  required,
  multiSelect = false,
  searchable = false,
  clearable = false,
  tabSelectsValue = false,
  closeOnSelect = true,
  backspaceRemoves = false,
  ...inputProps
}) => (
  <ReactSelect
    aria-label={label}
    arrowRenderer={() => (
      <span>
        <ArrowIcon height={8} fillColor={'#1065c7'} />
      </span>
    )}
    disabled={disabled}
    id={id}
    onChange={onChange}
    options={options}
    placeholder={placeholder}
    required={required}
    clearable={clearable}
    searchable={searchable}
    simpleValue={true}
    multi={multiSelect}
    backspaceRemoves={backspaceRemoves}
    deleteRemoves={clearable}
    escapeClearsValue={clearable}
    tabSelectsValue={tabSelectsValue}
    closeOnSelect={closeOnSelect}
    {...inputProps}
  />
);
export const ComboDropdown = styleSortDropdown(ComboComponent);
