import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { CGMClinicalData } from 'src/domains/patient-dashboards/cgm/store/cgm.reducers';

import {
  CGMClinicalDataLoaderImplType,
  CGMClinicalDataTransformImplType,
  CGMClinicalDataServiceImplType,
} from './cgm-clinical-data.types';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';
import { formatCGMDateString } from 'src/utils/date';

const UNIT_MMOLL = 'MMOLL';
const UNIT_MGDL = 'MGDL';

export const CGMClinicalDataLoaderImpl: CGMClinicalDataLoaderImplType = (
  { fhirId, startDate, endDate, unit, hasRoleFhir },
  accessToken,
) => {
  const endpoint = hasRoleFhir
    ? ENDPOINTS.getFhirRoleClinicalDataCgm
    : ENDPOINTS.getClinicalDataCgm;

  const headers = {
    Authorization: createAuthHeader(accessToken),
    rangeStartDate: startDate,
    rangeEndDate: endDate,
    bgUnit: unit === 'mg/dL' ? UNIT_MGDL : UNIT_MMOLL,
  };

  const pathParameters = {
    ...(!hasRoleFhir && { fhirId, startDate, endDate, unit }),
    ...(hasRoleFhir && { fhirId }),
  };

  return getJSON(endpointWithParams(endpoint, pathParameters), { headers });
};

const updateDateTimeObject = (
  day: string,
  hour: number,
  macro: number,
  timestamp: string,
  glucoseValue: number,
  interval: any,
  dataObject: CGMClinicalData,
  defaultInterval: any,
) => {
  if (!dataObject.days[day]) dataObject.days[day] = new Array(23);

  if (!dataObject.daysStats[day]) {
    dataObject.daysStats[day] = {
      summationDayValues: 0,
      totalDayMeasurement: 0,
    };
  }

  if (!dataObject.days[day][hour]) {
    dataObject.days[day][hour] = {
      hour: `${hour}:00`,
      totalvalues: 0,
      values: [],
    };
  }

  dataObject.days[day][hour].values.push({
    timestamp,
    glucoseValue,
    macro,
  });

  dataObject.days[day][hour].totalvalues++;
  dataObject.totalMeasurements++;
  dataObject.glucoseSummation += glucoseValue;
  dataObject.daysStats[day].summationDayValues += glucoseValue;
  dataObject.daysStats[day].totalDayMeasurement++;

  // First and Last Measurements per sequence
  const firstTimestamp = interval.intervalFirstMeasurement || timestamp;
  const lastTimestamp = interval.intervalLastMeasurement || timestamp;
  interval.intervalFirstMeasurement =
    timestamp < firstTimestamp ? timestamp : firstTimestamp;
  interval.intervalLastMeasurement =
    timestamp > lastTimestamp ? timestamp : lastTimestamp;

  // First and Last Measurements per day
  if (!interval.intervalDays[day]) {
    interval.intervalDays[day] = {
      intervalFirstMeasurementDay: timestamp,
      intervalLastMeasurementDay: timestamp,
      intervalTotalMeasurementDay: 0,
    };
  }
  interval.intervalDays[day].intervalTotalMeasurementDay++;
  interval.intervalDays[day].interval = defaultInterval;

  if (timestamp < interval.intervalDays[day].intervalFirstMeasurementDay) {
    interval.intervalDays[day].intervalFirstMeasurementDay = timestamp;
  }
  if (timestamp > interval.intervalDays[day].intervalLastMeasurementDay) {
    interval.intervalDays[day].intervalLastMeasurementDay = timestamp;
  }
};

export const CGMClinicalDataTransformImpl: CGMClinicalDataTransformImplType = (
  data,
) => {
  const obj: CGMClinicalData = {
    intervals: [],
    days: {},
    daysStats: {},
    totalMeasurements: 0,
    glucoseSummation: 0,
  };

  if (data.hasRoleFhir) {
    const length = data.glucoseValues?.length;

    const interval = {
      intervalTotalMeasurements: length,
      interval: data.interval,
      unit: data.intervalUnit,
      intervalFirstMeasurement: '',
      intervalLastMeasurement: '',
      intervalDays: {},
    };

    data.glucoseValues?.forEach(({ date, glucose }) => {
      const { day, hour, macro } = formatCGMDateString(date);
      updateDateTimeObject(
        day,
        hour,
        macro,
        date,
        glucose,
        interval,
        obj,
        data.interval,
      );
    });

    obj.intervals.push(interval as any);
  } else {
    data.device.forEach((dvce) => {
      dvce.data.forEach((dta) => {
        dta.sequences.forEach((sqnce, index) => {
          const len = sqnce.data.length;
          const interval = {
            intervalTotalMeasurements: len,
            interval: sqnce.interval,
            unit: sqnce.intervalUnit,
            intervalFirstMeasurement: '',
            intervalLastMeasurement: '',
            intervalDays: {},
          };

          for (let i = 0; i < len; i++) {
            const { timestamp, glucoseValue } = sqnce.data[i];
            const defaultInterval = sqnce.interval;
            // values of object days
            const { day, hour, macro } = formatCGMDateString(timestamp);

            updateDateTimeObject(
              day,
              hour,
              macro,
              timestamp,
              glucoseValue,
              interval,
              obj,
              defaultInterval,
            );
          }
          obj.intervals.push(interval);
        });
      });
    });
  }

  return obj;
};

export const CGMClinicalDataServiceImpl: CGMClinicalDataServiceImplType =
  (
    load: CGMClinicalDataLoaderImplType,
    transform: CGMClinicalDataTransformImplType,
  ) =>
  (query, accessToken, gigyaToken) =>
    load(query, accessToken, gigyaToken).then((data) =>
      transform({ ...data, hasRoleFhir: query.hasRoleFhir }),
    );
