import * as React from 'react';
import { compose, lifecycle } from 'recompose';
import { testId } from '@roche/roche-common';

import {
  Block,
  Button,
  LocalizedText,
  Row,
  Form,
  InputError,
  ControlText,
  SearchFormControl,
} from 'src/components';
import { CreatePatientIcon } from 'src/assets/icons';
import { colors } from 'src/core/styles/colors';
import { RenderIf } from 'src/utils/render-if';

import {
  SearchPatientFormDiv,
  SearchPatientWrapper,
  SearchPatientDiv,
  TitleName,
  Description,
  AddNewPatientButton,
  SearchPatientContainer,
  PatientSearchFieldLabel,
} from './patient-search.slim.style';
import {
  patientNameErrors,
  patientNameValidators,
} from './patient-search.validators';

export const PatientSearchForm = ({
  onSearch,
  onCreatePatient,
  t,
  hasCreatePatient,
  isHcpOrGp,
}) => {
  const patientNameErrorsMessages = {
    isNameLongEnough: t(patientNameErrors.isNameLongEnough),
  };
  return (
    <React.Fragment>
      <RenderIf validate={!isHcpOrGp}>
        <SearchPatientWrapper>
          <SearchPatientContainer>
            <SearchPatientDiv>
              <SearchPatientFormDiv>
                <Form
                  model="patientSearch"
                  onSubmit={onSearch}
                  autoComplete="off"
                >
                  <Block mb={4}>
                    <TitleName {...testId('basic-home-screen', 'slim-title')}>
                      <LocalizedText textKey="patientSearch.title" />
                    </TitleName>
                    <Description>
                      <LocalizedText textKey="patientSearch.description" />
                    </Description>
                  </Block>
                  <Block mb={4}>
                    <Block pb={2} mb={3}>
                      <PatientSearchFieldLabel>
                        {t('searchBar.patientId')}
                      </PatientSearchFieldLabel>
                      <ControlText
                        component={SearchFormControl}
                        id="patient-id"
                        model="patientSearch.patientID"
                        placeholder={t('searchBar.patientId')}
                        p={2}
                        mr={3}
                      />
                    </Block>
                    <PatientSearchFieldLabel>
                      {t('searchBar.name')}
                    </PatientSearchFieldLabel>
                    <ControlText
                      component={SearchFormControl}
                      id="patient-name"
                      model="patientSearch.fullName"
                      validators={patientNameValidators}
                      mapProps={{
                        valid: ({ fieldValue }) => fieldValue.valid,
                        focus: ({ fieldValue }) => fieldValue.focus,
                      }}
                      placeholder={t('searchBar.name')}
                      p={2}
                      mr={3}
                    />
                    <InputError
                      id="patient-name"
                      messages={patientNameErrorsMessages}
                      model="patientSearch.fullName"
                    />
                  </Block>
                  <Row>
                    <Button type="submit" label={t('searchBar.search')} {...testId('basic-home-screen', 'search-button')}/>
                  </Row>
                </Form>
              </SearchPatientFormDiv>
            </SearchPatientDiv>
            <AddNewPatientButton
              className={hasCreatePatient ? 'disabled' : 'active'}
              onClick={() => {
                if (!hasCreatePatient) {
                  onCreatePatient();
                }
              }}
              {...testId('basic-home-screen', 'slim-create-patient')}
            >
              <Block display="inline-block" mr={3}>
                <CreatePatientIcon
                  height={25}
                  fillColor={
                    colors[hasCreatePatient ? 'grayMedium' : 'brandBlue']
                  }
                />
              </Block>
              <LocalizedText textKey="patientSearch.addPatient" />
            </AddNewPatientButton>
          </SearchPatientContainer>
        </SearchPatientWrapper>
      </RenderIf>
    </React.Fragment>
  );
};
export const PatientSearchFormSlim = compose(
  lifecycle({
    componentWillMount() {
      const { isHcpOrGp, onSearch } = this.props;
      if (isHcpOrGp) {
        onSearch({});
      }
    },
  }),
)(PatientSearchForm);
