import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { oidcEpics, sessionEpics } from 'src/app/session';
import { generatePatientReportsEpic } from 'src/core/patient-reports/patient-reports.epic';
import { continuousMonitoringEpics } from 'src/domains/patient-dashboards/cgm/store/cgm.epics';
import { savePatientSessionEndEpic } from 'src/core/patient-session/patient-session.epics';
import {
  logoutEpic,
  reloadAfterLogoutEpic,
} from 'src/core/login-system-error/login-system-error.epics';
import { pairingCodeIdentityStatusEpic } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/pairing-code-identity-status/pairing-code-identity-status.epic';
import { checkDataSharingConsentEpic } from 'src/domains/patient-dashboards/bg/store/check-data-sharing-consent/check-data-sharing-consent.epic';

import {
  getDeliveryStatusEpic,
  getThresholdsEpic,
  getTimeIntervalsEpic,
  saveDeliveryEpic,
  setDeliveryStatusEpic,
  getDeliveryEpic,
  patientChangeEpic,
  fetchPatientEpic,
  fetchCurrentPatientErrorEpic,
  fetchPatientDateRangeEpic,
  getClinicalDataOnDatesChangeEpic,
  getPatientStockEpic,
  submitLostStripsEpic,
  submitManualDeliveryEpic,
  getPatientSearchEpic,
  addPrescriptionEntryEpic,
  createUnsavedPrescriptionEntryEpic,
  getClinicGuidesEpic,
  saveClinicGuideEpic,
  deleteClinicGuideEpic,
  getCurrentPrescriptionEpic,
  getFrequenciesEpic,
  getStripModelsEpic,
  getTherapiesEpic,
  savePrescriptionEpic,
  setCurrentPrescriptionsEpic,
  onPatientChangeResetPrescriptionEpic,
  initializePrescriptionEpic,
  fetchTemporaryPrescriptionReasonsEpic,
  onClinicGuidesUpdateEpic,
  onSaveClinicGuideEpic,
  clearPatientStateEpic,
  onPrescriptionSaveFetchLatestEpic,
  onDeliveryStatusChangeFetchStripStockEpic,
  getDeliveryHistoryEpic,
  getAlertsEpic,
  saveAlertsEpic,
  fetchOrgStockEpic,
  addOrgStockEpic,
  changeLanguageEpic,
  clearOrganizationStateEpic,
  modalDomSideEffectsEpics,
  createPatientStatusNotificationModalEpic,
  updateLastDeliveryStatusEpic,
  createSendPatientStatusModalEpic,
  getLastDeliveryStatusEpic,
  userEpic,
  saveDeliveryStatusCommentEpic,
  createLoadingModalEpic,
  successLoadingModalEpic,
  removeLoadingModalEpic,
  errorLoadingModalEpic,
  successRequiresConfirmationModalEpic,
  refreshClinicGuidesEpic,
  getDeviceAssociationEpic,
  updateDeviceAssociationEpic,
  redirectAfterDeviceAssignmentCancelEpic,
  updateAssociationOnConfirmEpic,
  resetFeatureStateAfterCancelEpic,
  getAlreadyAssignedPatientEpic,
  redirectAfterDeviceAssignmentDoneEpic,
  fetchPatientForEditEpic,
  checkAssociatedProfessionalsExistOnEditPatientEpic,
  updateAllowPatientAccessOnProfileEditEpic,
  fetchPatientPermissionsEpic,
  updateProfileTypeOnFetchPatientSuccessEpic,
  getProfessionalsEpic,
  editPatientEpic,
  editPatientWithFhirEpic,
  editEMRPatientEpic,
  oidcLoginErrorEpic,
  retryLoginEpic,
  fetchConfigurablePermissionsEpic,
  fetchPatientDevicesEpic,
  removePatientDeviceEpic,
  mysugrStatusEpic,
  mysugrSendInviteEpic,
  mysugrResetInviteEpic,
  fetchDeviceSettingsListEpic,
  fetchDeviceSettingsEpic,
  fetchAllDeviceSettingsDataEpic,
  exportPatientsSessionEpic,
  startPatientSessionEpic,
} from 'src/core';

import {
  PatientStockFactoryImpl,
  PatientListFactoryImpl,
  PatientFactoryImpl,
  DateRangeFactoryImpl,
  ClinicalDataFactoryImpl,
  ClinicalDataFromFhirFactoryImpl,
  TimeIntervalFactoryImpl,
  ThresholdFactoryImpl,
  GetDeliveryStatusFactoryImpl,
  GetAlertsFactoryImpl,
  SaveAlertsFactoryImpl,
  TherapiesFactoryImpl,
  FrequenciesFactoryImpl,
  PatientSearchFactoryImpl,
  GetPrescriptionFactoryImpl,
  SavePrescriptionFactoryImpl,
  SaveDeliveryFactoryImpl,
  SetDeliveryStatusFactoryImpl,
  GetDeliveryFactoryImpl,
  CurrentUserFactoryImpl,
  GetClinicGuidesFactoryImpl,
  SaveClinicGuideFactoryImpl,
  DeleteClinicGuideFactoryImpl,
  StripModelsFactoryImpl,
  LostStripsFactoryImpl,
  ManualDeliveryFactoryImpl,
  PrescriptionReasonsFactoryImpl,
  GetOrgStockFactoryImpl,
  PermissionsFactory,
  AddOrgStockFactoryImpl,
  SaveDeliveryStatusCommentFactoryImpl,
  GetDeviceAssociationFactoryImpl,
  UpdateDeviceAssociationFactoryImpl,
  ProfileTypesFactory,
  GetActiveSystemLanguagesFactoryImpl,
  GetPatientInfoFactoryImpl,
  FetchProfessionalFactoryImpl,
  GetLatestPatientsFactoryImpl,
  GetLatestPatientsSimpleTransformImpl,
  EditPatientFactoryImpl,
  EditEMRPatientFactoryImpl,
  FetchConfigurablesFactoryImpl,
  ListDevicesFactoryImpl,
  RemoveDeviceFactoryImpl,
  DeactivatePatientFactoryImpl,
  NextShipmentFetchFactoryImpl,
  NextShipmentChangeStatusFactoryImpl,
  NextShipmentChangeDateFactoryImpl,
  GetTimeBlocksFactoryImpl,
  GetDeliveryConfigurationFactoryImpl,
  GetPatientDeliveryConfigurationFactoryImpl,
  PostDeliveryConfigurationFactoryImpl,
  PostTimeBlocksFactoryImpl,
  MysugrStatusFactoryImpl,
  MysugrSendInviteFactoryImpl,
  StockAdjustmentsFactoryImpl,
  GetTargetRangesFetchFactoryImpl,
  SaveTargetRangesFetchFactoryImpl,
  GetHba1cCardFactoryImpl,
  GetHba1cListFactoryImpl,
  PostNewEntryHba1cFactoryImpl,
  ClinicalStatisticsFactoryImpl,
  DeviceSettingsListFactoryImpl,
  DeviceSettingsFactoryImpl,
  PatientReportsFactoryImpl,
  GetRpmSettingsFactoryImpl,
  UpdateRpmSettingsFactoryImpl,
  PatientSessionEndFactoryImpl,
  PatientsSessionExportFactoryImpl,
  PatientSessionStartFactoryImpl,
  CheckExternalPatientFactoryImpl,
  SaveUserUnitsFactoryImpl,
  GetUserUnitsFactoryImpl,
  GetINSIdentityFetchFactoryImpl,
  CheckPairingCodeFactoryImp,
  CorrelatePairingCodeFactoryImp,
  EditPatientWithFhirFactoryImpl,
  InvitePatientFactoryImp,
} from './services';
import { correlatePairingCodeEpic } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/correlate-pairing-code/correlate-pairing-code.epics';
import { invitePatientEpic } from 'src/widgets/patient/create-patient/create-patient-with-platform/store/invite-patient/invite-patient.epics.ts'
import { getPatientsListEpic } from './domains/patient/scenes/patients/store/patients.epic';
import {
  fetchBGOverviewClinicalDataEpic,
  fetchBGOverviewClinicalDataFromFhirEpic,
  fetchBGOverviewEndDateEpic,
  fetchCheckExternalPatientEpic,
  getClinicalDataEpic,
  getClinicalDataFromFhirEpic,
  getClinicalDataOnDateRangeEpic,
  getClinicalDataOnBGOverviewEndDateEpic,
  setClinicalDataEpic,
} from './domains/patient-dashboards/bg/store/bg.epics';
import {
  permissionsEpic,
  patientPermissionsEpic,
} from './core/permissions/permissions.epics';
import { GetLastDeliveryStatusFactoryImpl } from './services/delivery-status/get-last-delivery-status';
import { CreatePatientFactoryImpl } from './services/patient/create-patient';
import { CreatePatientWithFhirFactoryImpl } from './services/patient/create-patient-with-fhir';
import { CreateProfessionalFactoryImpl } from './services/professionals/create-professional';
import { EditProfileFactoryImpl } from './services/profile';
import { EditEMRProfileFactoryImpl } from './services/profile-emr';
import {
  createPatientEpic,
  createPatientWithFhirEpic,
  getCreatedPatientEpic,
  populateCreatePatientLanguageEpic,
  populateCreatePatientProfessionalsEpic,
  setDefaultHCPWhenCreatingPatientEpic,
  setDefaultLanguageWhenCreatingPatientEpic,
  setPatientCountryFromDepartmentEpic,
  updateAllowPatientAccessOnProfileChangeEpic,
  updateCreatePatient,
  updateCreatePatientHealthInfo,
} from './widgets/patient/create-patient/store/create-patient.epics';
import {
  populateCreateProfessionalLanguageEpic,
  createProfessionalEpic,
  setDefaultLanguageWhenCreatingProfessionalEpic,
  setOpenConnectivityWhenCreatingProfessionalEpic,
} from './widgets/professional/create-professional/store/create-professional.epics';
import {
  editProfileEpic,
  editEMRProfileEpic,
} from './widgets/profile/edit-profile/store/edit-profile.epics';
import { generalLinks } from './domains/general/routes';
import { getDepartmentProfileTypesEpic } from './core/department/department.epics';
import { countryService } from './services/country/country.service';
import { countriesEpic } from './core/countries/countries.epics';
import { fetchLanguagesEpic } from './core/languages/languages.epics';
import {
  getLatestPatientsEpic,
  getLatestPatientsAfterPatientCreatedEpic,
} from './core/latest-patients/index';
import { deactivatePatientEpic } from './domains/patient/scenes/deactivate-patient';
import {
  fetchNextShipmentEpic,
  changeStatusNextShipmentEpic,
  changeDateNextShipmentEpic,
} from './domains/patient/scenes/next-shipment';
import {
  fetchPatientTimeBlocksEpic,
  savePatientTimeBlocksEpic,
} from './domains/patient/scenes/time-periods';
import {
  fetchDeliveryConfigurationEpic,
  saveDeliveryConfigurationEpic,
} from './domains/patient/scenes/delivery-configuration';
import { fetchHba1cCardEpics } from './domains/diagnostics/widgets/hba1c-card/store/hba1c-card.epics';
import { fetchHba1cListEpics } from './domains/diagnostics/widgets/hba1c-modal/components/list-values-hba1c/store/list-values-hba1c.epics';
import { expiringModalEpics } from './app/session/core/expiring-modal/expiring-modal.epics';
import { saveNewEntryHba1cEpic } from './domains/diagnostics/widgets/hba1c-modal/components/new-entry-hba1c/store/new-entry-hba1c.epics';
import { fetchPatientsEpic } from './domains/general/widgets/store/hcp-dashboard.epics';
import {
  fetchRpmSettingsEpic,
  updateRpmSettingsEpic,
} from './core/rpm/rpm-settings/rpm-settings.epics';
import {
  saveUserUnitsEpic,
  getUserUnitsEpic,
} from './widgets/user-units-selector';
import { getINSIdentityEpic } from './widgets/patient/INS-patient/store/INS-identity.epic';
import {
  getTargetRangesEpic,
  saveTargetRangesEpic,
} from './widgets/target-ranges/store/target-ranges.epics';
import { checkPairingCodeEpic } from './widgets/patient/create-patient/create-patient-with-platform/store/check-pairing-code/check-pairing-code.epics';
import { pairingCodeIdentityStatusFactoryImp } from 'src/services/pairing-code/pairing-code-identity-status/pairing-code-identity-status.factory';
import { CheckDataSharingConsentFactoryImp } from 'src/services/patient/check-data-sharing-consent/check-data-sharing-consent.factory';

export const rootEpic = combineEpics(
  getPatientStockEpic(PatientStockFactoryImpl({ devMode: false })),
  getPatientsListEpic(PatientListFactoryImpl({ devMode: false })),
  fetchPatientEpic(PatientFactoryImpl({ devMode: false })),
  fetchCurrentPatientErrorEpic(),
  oidcLoginErrorEpic(),
  retryLoginEpic(),
  fetchPatientDateRangeEpic(DateRangeFactoryImpl({ devMode: false })),
  fetchBGOverviewEndDateEpic(DateRangeFactoryImpl({ devMode: false })),
  getClinicalDataEpic(ClinicalDataFactoryImpl({ devMode: false })),
  getClinicalDataFromFhirEpic(
    ClinicalDataFromFhirFactoryImpl({ devMode: false }),
  ),
  fetchCheckExternalPatientEpic(
    CheckExternalPatientFactoryImpl({ devMode: false }),
  ),
  fetchBGOverviewClinicalDataEpic(ClinicalDataFactoryImpl({ devMode: false })),
  fetchBGOverviewClinicalDataFromFhirEpic(
    ClinicalDataFromFhirFactoryImpl({ devMode: false }),
  ),
  getClinicalDataOnDateRangeEpic(),
  getClinicalDataOnBGOverviewEndDateEpic(),
  setClinicalDataEpic(),
  getClinicalDataOnDatesChangeEpic(),
  getTimeIntervalsEpic(TimeIntervalFactoryImpl({ devMode: false })),
  getThresholdsEpic(ThresholdFactoryImpl({ devMode: false })),
  getDeliveryStatusEpic(GetDeliveryStatusFactoryImpl({ devMode: false })),
  getAlertsEpic(GetAlertsFactoryImpl({ devMode: false })),
  getCurrentPrescriptionEpic(GetPrescriptionFactoryImpl({ devMode: false })),
  saveAlertsEpic(SaveAlertsFactoryImpl({ devMode: false })),
  getTherapiesEpic(TherapiesFactoryImpl({ devMode: false })),
  getClinicGuidesEpic(GetClinicGuidesFactoryImpl({ devMode: false })),
  saveClinicGuideEpic(SaveClinicGuideFactoryImpl({ devMode: false })),
  deleteClinicGuideEpic(DeleteClinicGuideFactoryImpl({ devMode: false })),
  getFrequenciesEpic(FrequenciesFactoryImpl({ devMode: true })),
  getPatientSearchEpic(PatientSearchFactoryImpl({ devMode: false })),
  getStripModelsEpic(StripModelsFactoryImpl({ devMode: false })),
  savePrescriptionEpic(SavePrescriptionFactoryImpl({ devMode: false })),
  getDeliveryEpic(GetDeliveryFactoryImpl({ devMode: false })),
  saveDeliveryEpic(SaveDeliveryFactoryImpl({ devMode: false })),
  setDeliveryStatusEpic(SetDeliveryStatusFactoryImpl({ devMode: false })),
  getDeliveryHistoryEpic(StockAdjustmentsFactoryImpl({ devMode: false })),
  getTargetRangesEpic(GetTargetRangesFetchFactoryImpl({ devMode: false })),
  saveTargetRangesEpic(SaveTargetRangesFetchFactoryImpl({ devMode: false })),
  addPrescriptionEntryEpic(),
  createUnsavedPrescriptionEntryEpic(),
  patientChangeEpic(),
  setCurrentPrescriptionsEpic(),
  onPatientChangeResetPrescriptionEpic(),
  submitLostStripsEpic(LostStripsFactoryImpl({ devMode: false })),
  submitManualDeliveryEpic(ManualDeliveryFactoryImpl({ devMode: false })),
  initializePrescriptionEpic(),
  fetchTemporaryPrescriptionReasonsEpic(
    PrescriptionReasonsFactoryImpl({ devMode: false }),
  ),
  checkAssociatedProfessionalsExistOnEditPatientEpic(),
  fetchPatientPermissionsEpic(),
  onClinicGuidesUpdateEpic(),
  onSaveClinicGuideEpic(),
  clearPatientStateEpic,
  onPrescriptionSaveFetchLatestEpic(),
  onDeliveryStatusChangeFetchStripStockEpic(),
  fetchOrgStockEpic(GetOrgStockFactoryImpl({ devMode: false })),
  addOrgStockEpic(AddOrgStockFactoryImpl({ devMode: false })),
  permissionsEpic(PermissionsFactory({ devMode: false })),
  changeLanguageEpic(),
  clearOrganizationStateEpic,
  modalDomSideEffectsEpics(),
  createPatientStatusNotificationModalEpic(),
  updateLastDeliveryStatusEpic(),
  patientPermissionsEpic(
    PermissionsFactory({ devMode: false, type: 'patient' }),
  ),
  createSendPatientStatusModalEpic(),
  getLastDeliveryStatusEpic(
    GetLastDeliveryStatusFactoryImpl({ devMode: false }),
  ),
  userEpic(CurrentUserFactoryImpl({ devMode: false })),
  saveDeliveryStatusCommentEpic(
    SaveDeliveryStatusCommentFactoryImpl({ devMode: false }),
  ),
  createLoadingModalEpic(),
  successLoadingModalEpic(),
  removeLoadingModalEpic(),
  errorLoadingModalEpic(),
  successRequiresConfirmationModalEpic(),
  refreshClinicGuidesEpic(),
  getDeviceAssociationEpic(GetDeviceAssociationFactoryImpl({ devMode: false })),
  updateDeviceAssociationEpic(
    UpdateDeviceAssociationFactoryImpl({ devMode: false }),
  ),
  updateAssociationOnConfirmEpic(),
  redirectAfterDeviceAssignmentCancelEpic(generalLinks.home),
  resetFeatureStateAfterCancelEpic(),
  getAlreadyAssignedPatientEpic(PatientFactoryImpl({ devMode: false })),
  createPatientEpic(CreatePatientFactoryImpl({ devMode: false })),
  createPatientWithFhirEpic(
    CreatePatientWithFhirFactoryImpl({ devMode: false }),
  ),
  createProfessionalEpic(CreateProfessionalFactoryImpl({ devMode: false })),
  editProfileEpic(EditProfileFactoryImpl({ devMode: false })),
  editEMRProfileEpic(EditEMRProfileFactoryImpl({ devMode: false })),
  getProfessionalsEpic(FetchProfessionalFactoryImpl({ devMode: false })),
  getDepartmentProfileTypesEpic(ProfileTypesFactory({ devMode: false })),
  redirectAfterDeviceAssignmentDoneEpic(),
  countriesEpic(countryService({ devMode: false })),
  getCreatedPatientEpic(PatientFactoryImpl({ devMode: false })),
  fetchLanguagesEpic(
    GetActiveSystemLanguagesFactoryImpl({
      devMode: false,
    }),
  ),
  fetchPatientForEditEpic(
    GetPatientInfoFactoryImpl({ devMode: false }),
    FetchConfigurablesFactoryImpl({ devMode: false }),
  ),
  updateProfileTypeOnFetchPatientSuccessEpic(),
  updateAllowPatientAccessOnProfileChangeEpic(),
  updateCreatePatient(),
  updateCreatePatientHealthInfo(),
  updateAllowPatientAccessOnProfileEditEpic(),
  getLatestPatientsEpic(
    GetLatestPatientsFactoryImpl(
      { devMode: false },
      GetLatestPatientsSimpleTransformImpl,
    ),
  ),
  getLatestPatientsAfterPatientCreatedEpic(),
  editPatientEpic(EditPatientFactoryImpl({ devMode: false })),
  editPatientWithFhirEpic(EditPatientWithFhirFactoryImpl({ devMode: false })),
  editEMRPatientEpic(EditEMRPatientFactoryImpl({ devMode: false })),
  populateCreatePatientLanguageEpic(),
  populateCreateProfessionalLanguageEpic(),
  populateCreatePatientProfessionalsEpic(),
  setPatientCountryFromDepartmentEpic(),
  setDefaultHCPWhenCreatingPatientEpic(),
  setDefaultLanguageWhenCreatingPatientEpic(),
  setDefaultLanguageWhenCreatingProfessionalEpic(),
  setOpenConnectivityWhenCreatingProfessionalEpic(),
  expiringModalEpics,
  fetchConfigurablePermissionsEpic(
    FetchConfigurablesFactoryImpl({ devMode: false }),
  ),
  fetchPatientDevicesEpic(ListDevicesFactoryImpl({ devMode: false })),
  fetchAllDeviceSettingsDataEpic(ListDevicesFactoryImpl({ devMode: false })),
  removePatientDeviceEpic(RemoveDeviceFactoryImpl({ devMode: false })),
  deactivatePatientEpic(DeactivatePatientFactoryImpl({ devMode: false })),
  fetchNextShipmentEpic(NextShipmentFetchFactoryImpl({ devMode: false })),
  changeStatusNextShipmentEpic(
    NextShipmentChangeStatusFactoryImpl({ devMode: false }),
  ),
  changeDateNextShipmentEpic(
    NextShipmentChangeDateFactoryImpl({ devMode: false }),
  ),
  fetchPatientTimeBlocksEpic(GetTimeBlocksFactoryImpl({ devMode: false })),
  fetchDeliveryConfigurationEpic(
    GetDeliveryConfigurationFactoryImpl({ devMode: false }),
    GetPatientDeliveryConfigurationFactoryImpl({ devMode: false }),
  ),
  fetchHba1cCardEpics(GetHba1cCardFactoryImpl({ devMode: false })),
  fetchHba1cListEpics(GetHba1cListFactoryImpl({ devMode: false })),
  saveNewEntryHba1cEpic(PostNewEntryHba1cFactoryImpl({ devMode: false })),
  savePatientTimeBlocksEpic(PostTimeBlocksFactoryImpl({ devMode: false })),
  saveDeliveryConfigurationEpic(
    PostDeliveryConfigurationFactoryImpl({ devMode: false }),
  ),
  fetchPatientsEpic(ClinicalStatisticsFactoryImpl({ devMode: false })),
  mysugrStatusEpic(MysugrStatusFactoryImpl({ devMode: false })),
  mysugrSendInviteEpic(MysugrSendInviteFactoryImpl({ devMode: false })),
  mysugrResetInviteEpic,
  oidcEpics,
  sessionEpics,
  fetchDeviceSettingsListEpic(
    DeviceSettingsListFactoryImpl({ devMode: false }),
  ),
  fetchDeviceSettingsEpic(DeviceSettingsFactoryImpl({ devMode: false })),
  generatePatientReportsEpic(PatientReportsFactoryImpl({ devMode: false })),
  continuousMonitoringEpics,
  fetchRpmSettingsEpic(GetRpmSettingsFactoryImpl({ devMode: false })),
  updateRpmSettingsEpic(UpdateRpmSettingsFactoryImpl({ devMode: false })),
  savePatientSessionEndEpic(PatientSessionEndFactoryImpl({ devMode: false })),
  exportPatientsSessionEpic(
    PatientsSessionExportFactoryImpl({ devMode: false }),
  ),
  startPatientSessionEpic(PatientSessionStartFactoryImpl({ devMode: false })),
  saveUserUnitsEpic(SaveUserUnitsFactoryImpl({ devMode: false })),
  getUserUnitsEpic(GetUserUnitsFactoryImpl({ devMode: false })),
  // getTargetRangesEpic(GetTargetRangesFetchFactoryImpl({ devMode: false })),
  // saveTargetRangesEpic(SaveTargetRangesFetchFactoryImpl({ devMode: false })),
  getINSIdentityEpic(GetINSIdentityFetchFactoryImpl({ devMode: true })),
  checkPairingCodeEpic(CheckPairingCodeFactoryImp({ devMode: false })),
  correlatePairingCodeEpic(CorrelatePairingCodeFactoryImp({ devMode: false })),
  invitePatientEpic(InvitePatientFactoryImp({ devMode: false })),
  logoutEpic(),
  reloadAfterLogoutEpic(),
  pairingCodeIdentityStatusEpic(
    pairingCodeIdentityStatusFactoryImp({ devMode: false }),
  ),
  checkDataSharingConsentEpic(
    CheckDataSharingConsentFactoryImp({ devMode: false }),
  ),
);
const epic$ = new BehaviorSubject(rootEpic);

export const appEpic = (action$, store) =>
  epic$.mergeMap((epic) => epic(action$, store, null));

export const injectEpic = (asyncEpic) => epic$.next(asyncEpic);
export const injectEpicFactory =
  (injectedEpicNamespaces) => (namespace, asyncEpic) => {
    if (injectedEpicNamespaces.indexOf(namespace) >= 0) {
      return epic$;
    }
    injectedEpicNamespaces = [...injectedEpicNamespaces, namespace];
    return epic$.next(asyncEpic);
  };
