import {
  complement,
  isNil,
  map,
  split,
  values,
  clone,
  max,
  sort,
  sum,
  equals,
  always,
  cond,
} from 'ramda';
import {
  TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS,
  STICKY_MARGIN_TOP,
  NO_STICKY_MARGIN_TOP,
  LEFT_SUMMARY_HEADER_POSITION,
  DATES_HEADER_NAME,
  LEFT_DATE_HEADER_POSITION,
  LEFT_MAINTABLE_HEADER_POSITION,
  SHARED_COLLAPSED_PROPS,
} from 'src/domains/diagnostics/widgets/logbook-24hrs/logbook-24hrs.constants';
import { DateTime } from 'luxon';

import { compareAsc, convertJSDateGMT } from 'src/utils';
import { BLOOD_GLUCOSE_UNITS } from '../../store/constants';

export const convertMeasurementDateToGMT = (measurement) => ({
  ...measurement,
  originalDate: convertJSDateGMT(measurement.originalDate),
});

export const isArrowShown = (collapsed, isLoading, measurements) => {
  if (collapsed) {
    return !isLoading && measurements.length !== 0;
  }
  return false;
};

export const invertRowVariantType = cond([
  [
    equals(TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS.PRIMARY),
    always(TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS.SECONDARY),
  ],
  [
    equals(TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS.SECONDARY),
    always(TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS.PRIMARY),
  ],
]);

const DATE_DELIMITER = '_';
export const LOGBOOK_DAY_FORMAT = `EEEE${DATE_DELIMITER}d LLL yyyy`;

const isNotNil = complement(isNil);

export const MEASUREMENT_TYPE = {
  BG: 'bloodGlucose',
  CARBS: 'carbohydrates',
  INS1: 'bolusType1Value',
  INS2: 'bolusType2Value',
  INS3: 'bolusType3Value',
};

export const generateEmptyArray = (length) => Array.from({ length }, () => []);

export const generateEmpty24HourArray = () => generateEmptyArray(24);

const processMeasurement = ({
  acc,
  measurementDate,
  measurementHour,
  measurementType,
  measurementValue,
  measurement,
}) => {
  if (isNotNil(measurementValue)) {
    // place measurement in type and in 24 hour grouping
    acc[measurementDate].measurementsGroupedByTypeAndHours[measurementType][
      measurementHour
    ].push(measurement);

    acc[measurementDate].originalDate = measurement.originalDate;

    // Update amount of measurement rows
    const amountOfRowsForMeasurementHour =
      acc[measurementDate].measurementsGroupedByTypeAndHours[measurementType][
        measurementHour
      ].length;

    acc[measurementDate].totalRows[measurementType] = max(
      acc[measurementDate].totalRows[measurementType],
      amountOfRowsForMeasurementHour,
    );

    // increment count of measurement type
    acc[measurementDate].measurementCounts[measurementType] =
      acc[measurementDate].measurementCounts[measurementType] + 1;
    // add measurement value to statistics aggregator
    if (
      measurementType[0] === MEASUREMENT_TYPE.INS1 ||
      measurementType[0] === MEASUREMENT_TYPE.INS2 ||
      measurementType[0] === MEASUREMENT_TYPE.INS3
    ) {
      acc[measurementDate].measurementStatistics.totals[measurementType] = +(
        acc[measurementDate].measurementStatistics.totals[measurementType] +
        measurementValue
      ).toFixed(2);
    } else {
      acc[measurementDate].measurementStatistics.totals[measurementType] =
        acc[measurementDate].bloodGlucoseUnit === BLOOD_GLUCOSE_UNITS.MG_PER_DL
          ? Math.round(
              acc[measurementDate].measurementStatistics.totals[
                measurementType
              ] + measurementValue,
            )
          : acc[measurementDate].measurementStatistics.totals[measurementType] +
            measurementValue;
    }
  }
};

export const reduceMeasurements = (acc, measurement) => {
  const measurementDate = measurement.originalDate.toFormat(LOGBOOK_DAY_FORMAT);
  const measurementHour = measurement.originalDate.hour.toString();

  acc[measurementDate] = acc[measurementDate]
    ? acc[measurementDate]
    : clone(placeholder);

  acc[measurementDate].dayDate = measurementDate;
  acc[measurementDate].bloodGlucoseUnit = measurement.bloodGlucoseUnit;
  acc[measurementDate].originalDate = measurement.originalDate;

  const baseMeasurement = {
    measurementDate,
    measurementHour,
    measurement,
    acc,
  };

  processMeasurement({
    ...baseMeasurement,
    measurementValue: measurement.glucoseValue,
    measurementType: [MEASUREMENT_TYPE.BG],
  });

  processMeasurement({
    ...baseMeasurement,
    measurementValue: measurement.carbohydrates,
    measurementType: [MEASUREMENT_TYPE.CARBS],
  });

  processMeasurement({
    ...baseMeasurement,
    measurementValue: measurement.bolusType1Value,
    measurementType: [MEASUREMENT_TYPE.INS1],
  });

  processMeasurement({
    ...baseMeasurement,
    measurementValue: measurement.bolusType2Value,
    measurementType: [MEASUREMENT_TYPE.INS2],
  });

  processMeasurement({
    ...baseMeasurement,
    measurementValue: measurement.bolusType3Value,
    measurementType: [MEASUREMENT_TYPE.INS3],
  });

  return acc;
};

const placeholder = {
  date: null,
  dayDate: null,
  rowVariant: TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS.PRIMARY,
  measurementCounts: {
    [MEASUREMENT_TYPE.BG]: 0,
    [MEASUREMENT_TYPE.CARBS]: 0,
    [MEASUREMENT_TYPE.INS1]: 0,
    [MEASUREMENT_TYPE.INS2]: 0,
    [MEASUREMENT_TYPE.INS3]: 0,
  },
  totalRows: {
    [MEASUREMENT_TYPE.BG]: 0,
    [MEASUREMENT_TYPE.CARBS]: 0,
    [MEASUREMENT_TYPE.INS1]: 0,
    [MEASUREMENT_TYPE.INS2]: 0,
    [MEASUREMENT_TYPE.INS3]: 0,
  },
  measurementStatistics: {
    averages: {
      [MEASUREMENT_TYPE.BG]: 0,
    },
    totals: {
      [MEASUREMENT_TYPE.BG]: 0,
      [MEASUREMENT_TYPE.CARBS]: 0,
      [MEASUREMENT_TYPE.INS1]: 0,
      [MEASUREMENT_TYPE.INS2]: 0,
      [MEASUREMENT_TYPE.INS3]: 0,
    },
  },
  measurementsGroupedByTypeAndHours: {
    [MEASUREMENT_TYPE.BG]: generateEmpty24HourArray(),
    [MEASUREMENT_TYPE.CARBS]: generateEmpty24HourArray(),
    [MEASUREMENT_TYPE.INS1]: generateEmpty24HourArray(),
    [MEASUREMENT_TYPE.INS2]: generateEmpty24HourArray(),
    [MEASUREMENT_TYPE.INS3]: generateEmpty24HourArray(),
  },
};

/*
  Determines the row variant TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS
  to apply to a measurement day.

  If the previous row has an even amount of rows, than use
  TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS.PRIMARY
  otherwise use the opposite variant type of the previous measurement day.
  This allows the rows across days to continue with the correct visual pattern
 */
export const calculateRowPatternStyle = (measurementDay, index, arr) => {
  if (index !== 0) {
    const previousDayTotalRows = sum(values(arr[index - 1].totalRows));

    if (equals(previousDayTotalRows % 2, 0)) {
      measurementDay.rowVariant = arr[index - 1].rowVariant;
    } else {
      measurementDay.rowVariant = invertRowVariantType(
        arr[index - 1].rowVariant,
      );
    }
  }

  return measurementDay;
};

export const groupByDayAndTimeAndType = (result) =>
  sort(
    (a, b) => compareAsc(a.originalDate, b.originalDate),
    Object.keys(result).map((measurementDay) => {
      const bgTotal =
        result[measurementDay].measurementStatistics.totals[
          MEASUREMENT_TYPE.BG
        ];
      const numberOfBGMeasurements =
        result[measurementDay].measurementCounts[MEASUREMENT_TYPE.BG];
      if (
        result[measurementDay].bloodGlucoseUnit ===
        BLOOD_GLUCOSE_UNITS.MG_PER_DL
      ) {
        result[measurementDay].measurementStatistics.averages[
          MEASUREMENT_TYPE.BG
        ] = Math.round(bgTotal / numberOfBGMeasurements);
      } else {
        result[measurementDay].measurementStatistics.averages[
          MEASUREMENT_TYPE.BG
        ] = Math.round((bgTotal * 10) / numberOfBGMeasurements) / 10;
      }

      result[measurementDay].rowVariant =
        TWENTY_FOUR_HOUR_LOGBOOK_ROW_VARIANTS.PRIMARY;
      return result[measurementDay];
    }),
  );

export const splitDayByDelimiter = split(DATE_DELIMITER);

export const countRows = (measurements) =>
  map((measurementDay) => {
    const { totalRows } = measurementDay;
    return { rowCount: sum(values(totalRows)) };
  }, measurements);

export const rowHeight =
  (rowCounts) =>
  (singleItemHeight, offset) =>
  ({ index }) => {
    const rows = rowCounts[index].rowCount;
    const height = singleItemHeight * rows;
    return height + offset;
  };

export const formatHour = (i, is12hourTimeFormat) =>
  is12hourTimeFormat
    ? DateTime.fromObject({ hour: i })
        .toFormat('hh:mm a', { locale: 'us' })
        .toUpperCase()
    : DateTime.fromObject({ hour: i }).toFormat('HH:mm');

export const setHeadersCSS = (props) =>
  props.collapsed
    ? {
        ...SHARED_COLLAPSED_PROPS,
        left:
          props.name === DATES_HEADER_NAME
            ? LEFT_DATE_HEADER_POSITION
            : LEFT_SUMMARY_HEADER_POSITION,
      }
    : {
        ...props.properStyle,
        marginTop: props.isSticky ? STICKY_MARGIN_TOP : NO_STICKY_MARGIN_TOP,
      };

export const setMainTableHeaderCSS = (style, collapsed, isSticky, width) =>
  collapsed
    ? {
        ...SHARED_COLLAPSED_PROPS,
        left: LEFT_MAINTABLE_HEADER_POSITION,
        zIndex: 1,
        overflowX: 'hidden',
        overflowY: 'hidden',
      }
    : {
        ...style,
        marginTop: isSticky ? STICKY_MARGIN_TOP : NO_STICKY_MARGIN_TOP,
        zIndex: 1,
        overflowX: 'hidden',
        overflowY: 'hidden',
        width,
      };
