import { PayloadAction, Action } from 'src/app/store/app.types';

export enum UserUnitsActionTypes {
  USER_UNITS_GET = 'USER_UNITS_GET',
  USER_UNITS_GET_START = 'USER_UNITS_GET_START',
  USER_UNITS_GET_SUCCESS = 'USER_UNITS_GET_SUCCESS',
  USER_UNITS_GET_ERROR = 'USER_UNITS_GET_ERROR',
  USER_UNITS_SAVE = 'USER_UNITS_SAVE',
  USER_UNITS_SAVE_START = 'USER_UNITS_SAVE_START',
  USER_UNITS_SAVE_SUCCESS = 'USER_UNITS_SAVE_SUCCESS',
  USER_UNITS_SAVE_ERROR = 'USER_UNITS_SAVE_ERROR',
  USER_UNITS_CLEAR = 'USER_UNITS_CLEAR',
}

export type GetUserUnitsAction =
  Action<UserUnitsActionTypes.USER_UNITS_GET_START>;

export type GetUserUnitsSuccessAction = PayloadAction<
  UserUnitsActionTypes.USER_UNITS_GET_SUCCESS,
  UserUnitsResponsePayloadType
>;

export type GetUserUnitsErrorAction =
  Action<UserUnitsActionTypes.USER_UNITS_GET_ERROR>;

export type SaveUserUnitsAction = PayloadAction<
  UserUnitsActionTypes.USER_UNITS_SAVE_START,
  UserUnitsParamsPayloadType
>;

export type SaveUserUnitsSuccessAction = PayloadAction<
  UserUnitsActionTypes.USER_UNITS_SAVE_SUCCESS,
  UserUnit
>;

export type SaveUserUnitsErrorAction = PayloadAction<
  UserUnitsActionTypes.USER_UNITS_SAVE_ERROR,
  UserUnit
>;

export type ClearUnitsRequestAction =
  Action<UserUnitsActionTypes.USER_UNITS_CLEAR>;

export type UserUnitsParamsPayloadType = {
  unitMeasurement: string;
};

export type UserUnitsResponsePayloadType = {
  userUnitMeasurement: string;
};

export type UserUnitMeasurementState = {
  isFetching: boolean;
  successUserUnitMeasure: boolean;
  errorUserUnitMeasure: boolean;
  getUnitSuccess: boolean;
  getUnitError: boolean;
  allUserMeasurements: any;
};

export type UserUnit = {
  userUnit: string;
};

export type UserUnitsActions =
  | SaveUserUnitsAction
  | SaveUserUnitsSuccessAction
  | SaveUserUnitsErrorAction
  | GetUserUnitsAction
  | GetUserUnitsSuccessAction
  | GetUserUnitsErrorAction
  | ClearUnitsRequestAction;
