import { Div } from 'src/components/div';
import styled from 'styled-components';
import { PatientFormContainer } from 'src/widgets/patient/forms/patient-form.style';

import { convertPxToRem } from 'src/utils';

export const NextShipmentSection = styled(Div)`
  border: 1px solid ${(props) => props.theme.colors.silverMedium};
`;
NextShipmentSection.displayName = 'NextShipmentSection';

export const HeaderContainer = styled(Div)`
  flex: 1;
  border-bottom: 3px solid ${(props) => props.theme.colors.silverMedium};
`;
HeaderContainer.displayName = 'HeaderContainer';

export const Quantity = styled.p`
  margin-top: ${({ theme }) => theme.spacing.one};
  span {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    margin-right: 3px;
    &:first-of-type {
      font-weight: ${(props) => props.theme.fontWeights.regular};
    }
    &:last-of-type {
      margin-left: 3px;
    }
  }
  input {
    padding: 0px;
    border: none;
    font-weight: ${(props) => props.theme.fontWeights.bold};
    width: 20px;
    text-align: right;
  }
`;
Quantity.displayName = 'Quantity';

export const PencilContainer = styled.span`
  cursor: pointer;
`;
PencilContainer.displayName = 'PencilContainer';

export const Label = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: 13px;
`;
Label.displayName = 'Label';

export const Error = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: 13px;
  padding: ${({ theme }) => theme.spacing.four};
`;
Error.displayName = 'Label';

export const NoDeliveries = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: 13px;
  padding-top: ${({ theme }) => theme.spacing.two};
`;
NoDeliveries.displayName = 'NoDeliveries';

export const Status = styled.p`
  border: 1px solid blue;
  padding: ${({ theme }) => theme.spacing.three};
  border-radius: 0.1875rem;
  min-width: 80px;
`;
NoDeliveries.displayName = 'Status';

export const LabelAsterisk = styled(Label)`
  &:after {
    display: 'block';
    content: '*';
    color: ${(props) => props.theme.colors.blue};
    margin-left: 3px;
  }
`;
LabelAsterisk.displayName = 'LabelAsterisk';

export const ShipmentDate = styled.p`
  margin-bottom: 0px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  padding-top: ${({ theme }) => theme.spacing.two};
  input {
    padding: 0px;
    border: none;
    width: 85px;
    margin-right: 5px;
  }
`;
ShipmentDate.displayName = 'ShipmentDate';

export const HeaderTitle = styled.h1`
  color: ${(props) => props.theme.colors.blue};
  font-size: ${(props) => props.theme.fontSize.title};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  text-transform: uppercase;
  margin: ${({ theme }) => theme.spacing.four};
`;
HeaderTitle.displayName = 'HeaderTitle';

export const FormContainer = styled(Div)`
  width: 75%;
  min-width: ${convertPxToRem(650)};
  margin: ${({ theme }) => theme.spacing.four};
  input {
    color: inherit !important;
    background-color: ${(props) => props.theme.colors.white}!important;
  }
  min-height: 110px;
`;
FormContainer.displayName = 'FormContainer';

export const Caption = styled.p`
  font-size: ${(props) => props.theme.fontSize.caption};
  margin: ${({ theme }) => theme.spacing.four};
  &:before {
    display: 'block';
    content: '*';
    color: ${(props) => props.theme.colors.blue};
    margin-right: 3px;
  }
  span {
    font-weight: ${(props) => props.theme.fontWeights.bold};
    margin-right: 3px;
  }
`;
Caption.displayName = 'Caption';

export const ButtonsContainer = styled(PatientFormContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.four};
  & > button {
    margin-left: ${({ theme }) => theme.spacing.four};
  }
  padding-top: 2rem;
`;
ButtonsContainer.displayName = 'ButtonsContainer';
