import { not, path } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';

import { selectPatientId } from 'src/core/patient/patient.selector';
import { selectIs12HourFormat } from 'src/core/user/user.selectors';

export const blocksSelector = path(['timePeriods', 'blocks']);
export const selectIsFetching = path(['timePeriods', 'isFetchingBlocks']);
export const selectHasError = path(['timePeriods', 'isFetchingBlocksError']);
export const isSavingBlocks = path(['timePeriods', 'isSavingBlocks']);
export const isSavingBlocksError = path(['timePeriods', 'isSavingBlocksError']);
export const selectBlocksSaved = path(['timePeriods', 'blocksSaved']);

export const readyToRenderSelector = createSelector(
  selectIsFetching,
  selectHasError,
  blocksSelector,
  (fetching, err, blocks) => not(fetching) && not(err) && !!blocks,
);

export const timePeriodsConnector = (state) =>
  createStructuredSelector({
    periods: blocksSelector,
    isReadyToRender: readyToRenderSelector,
    patientId: selectPatientId,
    isSavingBlocks,
    isSavingBlocksError,
    blocksWereSaved: selectBlocksSaved,
    is12hourTimeFormat: selectIs12HourFormat,
  });
