// @ts-nocheck
import { combineEpics, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Action, State } from 'src/app/store/app.types';
import { gigyaAccountService } from '../../services/gigya';
import { logoutService } from '../../services/logout';
import {
  selectApiGateway,
  selectClientSecret,
  selectGigyaToken,
  selectOidcClientId,
  selectUID,
  selectAccessEndpoint,
  selectRedirectUri,
} from '../config/config.selectors';
import {
  selectAccessToken,
  selectIsOidcSessionValid,
} from '../oidc/oidc.selectors';

import {
  signoutError,
  signoutStart,
  signoutSuccess,
  validateSessionError,
  validateSessionSuccess,
} from './session.actions';
import {
  selectLegacyToken,
  selectIsSignedOut,
  selectIsSessionValid,
} from './session.selectors';
import { SessionActions, SessionActionType } from './session.types';
import { getCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { push } from 'react-router-redux';
import { customDatadogLog, LOGS } from 'src/app/logger';

import { ENDPOINTS } from 'src/services/service.constants';
import { endpointWithParams } from '@roche/roche-common';
import { authenticationLinks } from 'src/domains/authentication/routes';
export const validateSessionEpic: (
  gigyaAccountService: any,
) => Epic<SessionActions, State> = (gigyaService) => (action$, store$) => {
  const getProps = (store) => {
    return {
      token: selectAccessToken(store.getState()),
      gigyaToken: selectGigyaToken(store.getState()),
      apiGateway: selectApiGateway(store.getState()),
      client_id: selectOidcClientId(store.getState()),
      client_secret: selectClientSecret(store.getState()),
    };
  };
  const props = {
    gigyaUid: selectUID(store$.getState()),
  };
  return action$
    .ofType(SessionActionType.VALIDATE_SESSION_START)
    .switchMap(() => {
      return Observable.fromPromise(
        gigyaService({ ...getProps(store$), ...props }),
      ).switchMap((gigyau) => {
        return [
          validateSessionSuccess({
            gigyau,
            timeout: () => {
              const timeout = 240;
              return Date.now() + timeout;
            },
            idToken: selectLegacyToken(store$.getState()),
          }),
        ];
      });
    })
    .catch((e) => {
      customDatadogLog('session', LOGS.gigyaValidationError + 'test', {
        ...e,
        ...getProps(store$),
        ...props,
      });
    });
};

/* istanbul ignore next */ export const signOutEpic =
  (service) => (action$, store$) => {
    return action$
      .ofType(SessionActionType.SIGN_OUT_AFTER_REFRESH_START)
      .switchMap(() => {
        const getProps = (store) => {
          return {
            token: selectAccessToken(store.getState()),
            gigyaToken: selectGigyaToken(store.getState()),
            apiGateway: selectApiGateway(store.getState()),
            gigyaUid: selectUID(store.getState()),
            clientId: selectOidcClientId(store.getState()),
            isSignedOut: selectIsSignedOut(store.getState()),
            isSessionValid: selectIsSessionValid(store.getState()),
          };
        };

        const reject = (props) => {
          const { token: t, gigyaUid: g, isSignedOut, isSessionValid } = props;
          const noToken = [t, g].some((i) => [undefined, null, ''].includes(i));
          return isSignedOut || noToken || !isSessionValid;
        };

        return Observable.fromPromise(
          reject(getProps(store$))
            ? Promise.reject({ noToken, ...getProps(store$) })
            : service({
                UID: getProps(store$).token,
                ...getProps(store$),
              }),
        );
      })
      .switchMap((data) => [signoutSuccess(data)])
      .pipe(
        catchError((error) => [
          customDatadogLog('session', LOGS.signOutError, error),
          signoutError(error),
        ]),
      );
  };

/* istanbul ignore next */ export const signOutSuccessOrError = () => {
  return (action$, store$) =>
    action$
      .ofType(
        SessionActionType.SIGN_OUT_SUCCESS,
        SessionActionType.SIGN_OUT_ERROR,
      )
      .switchMap(() => {
        const routes = getCombinedRoutes(store$.getState());
        return [push(routes.authentication.redirect)];
      });
};

export const sessionEpics = combineEpics(
  signOutSuccessOrError(),
  validateSessionEpic(gigyaAccountService({ devMode: false })),
  signOutEpic(logoutService({ devMode: false })),
);
