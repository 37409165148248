import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { GraphSvgIcon, SvgIconSpan } from 'src/components/icon';

const PumpRunIconComponent = ({
  x,
  y,
  height,
  width,
  strokeWidth,
  opacity,
  title,
  isGraphIcon = true,
  t,
  cursor,
  onMouseMove,
  onMouseOut,
  onClick,
}) => {
  const originalWidth = 17;
  const originalHeight = 17;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  const iconTitle = title || t('graphs.iconTitles.pumpRun');

  const coreIcon = (
    <GraphSvgIcon
      x={x}
      y={y}
      title={iconTitle}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
      titleNotToBeRendered={onMouseMove && true}
    >
      <g
        fill="none"
        fillRule="evenodd"
        cursor={cursor}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
        onClick={onClick}
      >
        <rect
          fill="#7ED321"
          stroke="#7ED321"
          strokeWidth={strokeWidth}
          opacity={opacity}
          width={originalWidth}
          height={originalHeight}
        />
        <path fill="#FFF" d="M 11.645 8.479 l -5 3 v -6 z" />
      </g>
    </GraphSvgIcon>
  );

  return !isGraphIcon ? SvgIconSpan(iconTitle, coreIcon) : coreIcon;
};

export const PumpRunIcon = withTranslation(PumpRunIconComponent);
