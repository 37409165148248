import { TimeInterval } from 'src/services/time-interval/time-interval.type';

import {
  GraphThresholdsTransformerFn,
  TimeBlocksInMsMapperFn,
  TimeIntervalInMs,
  WithoutTargetRangeFn,
} from './logbook.core.types';

export const graphThresholdsTransformer: GraphThresholdsTransformerFn = ({
  actualHyper,
  hyper,
  hypo,
  warning,
}) => {
  const {
    noctIdealInterval: hyperNoct = 0,
    postIdealInterval: hyperPost = 0,
    preIdealInterval: hyperPre = 0,
  } = actualHyper;
  const {
    noctIdealInterval: upperNoct = 0,
    postIdealInterval: upperPost = 0,
    preIdealInterval: upperPre = 0,
  } = hyper;
  const {
    noctIdealInterval: lowerNoct = 0,
    postIdealInterval: lowerPost = 0,
    preIdealInterval: lowerPre = 0,
  } = warning;
  const {
    noctIdealInterval: hypoNoct = 0,
    postIdealInterval: hypoPost = 0,
    preIdealInterval: hypoPre = 0,
  } = hypo;

  return {
    pre: {
      hyper: hyperPre,
      upper: upperPre,
      lower: lowerPre,
      hypo: hypoPre,
    },
    post: {
      hyper: hyperPost,
      upper: upperPost,
      lower: lowerPost,
      hypo: hypoPost,
    },
    noct: {
      hyper: hyperNoct,
      upper: upperNoct,
      lower: lowerNoct,
      hypo: hypoNoct,
    },
  };
};

export const convertTimeToMs = (
  hour: number,
  minute: number,
  seconds: number,
) => seconds * 1000 + minute * 60 * 1000 + hour * 60 * 60 * 1000;

export const timeBlocksInMsMapper: TimeBlocksInMsMapperFn = ({
  description,
  startTime, // 'hh:mm:ss'
  endTime,
}) => {
  const splitStartTime = startTime.split(':');
  const splitEndTime = endTime.split(':');

  const startHour = parseInt(splitStartTime[0], 10);
  const startMinute = parseInt(splitStartTime[1], 10);
  const startSecond = parseInt(splitStartTime[2], 10);

  const endHour = parseInt(splitEndTime[0], 10);
  const endMinute = parseInt(splitEndTime[1], 10);
  const endSecond = parseInt(splitEndTime[2], 10);

  return {
    description,
    startTimeMs: convertTimeToMs(startHour, startMinute, startSecond),
    endTimeMs: convertTimeToMs(endHour, endMinute, endSecond),
  };
};

export const isWithinBlock = (
  timeInMs: number,
  startTimeMs: number,
  endTimeMs: number,
) => {
  const isCrossoverBlock = endTimeMs < startTimeMs;
  const dayInMs = 24 * 60 * 60 * 1000;

  const firstDayEndMs = dayInMs - 1;
  const secondDayStartMs = 0;

  // we assume that timeblocks are mutually exclusive
  // e.g. 23:00:00 - 00:59:59, 01:00:00 - 08:29:59
  if (isCrossoverBlock) {
    return (
      (startTimeMs <= timeInMs && timeInMs <= firstDayEndMs) ||
      (secondDayStartMs <= timeInMs && timeInMs <= endTimeMs)
    );
  }

  return startTimeMs <= timeInMs && timeInMs <= endTimeMs;
};

const sortTimeBlocksChronologically = (
  { startTimeMs: startA }: TimeIntervalInMs,
  { startTimeMs: startB }: TimeIntervalInMs,
) => (startA < startB ? -1 : startA > startB ? 1 : 0);

export const getTimeBlock = (date: Date, timeIntervals: TimeInterval[]) => {
  const timeUTCInMs = convertTimeToMs(
    date.getUTCHours() || 0,
    date.getUTCMinutes() || 0,
    date.getUTCSeconds() || 0,
  );

  const timeBlock = timeIntervals
    .map(timeBlocksInMsMapper)
    .sort(sortTimeBlocksChronologically)
    .find(({ startTimeMs, endTimeMs }) =>
      isWithinBlock(timeUTCInMs, startTimeMs, endTimeMs),
    );

  // timeblocks currently do not include milliseconds, hence the fallback
  // e.g. Date: 08:29:29.541, TB1: 01:00:00 - 08:29:59, TB2: 08:30:00 - 11:59:59
  return timeBlock ? timeBlock.description : 'NIGHT';
};

export const withoutTargetRange: WithoutTargetRangeFn = (
  aboveBelow,
  glucose,
  date,
  allThresholds,
  timeIntervals,
  beforeMeal,
  afterMeal,
) => {
  const {
    pre: { upper: upperPre, lower: lowerPre },
    post: { upper: upperPost, lower: lowerPost },
    noct: { upper: upperNoct, lower: lowerNoct },
  } = allThresholds;

  const DEFAULT_PREPRANDIAL = 'BEFORE_BREAKFAST';
  const DEFAULT_POSTPRANDIAL = 'AFTER_BREAKFAST';

  const timeBlock =
    beforeMeal !== afterMeal
      ? beforeMeal
        ? DEFAULT_PREPRANDIAL
        : afterMeal
        ? DEFAULT_POSTPRANDIAL
        : getTimeBlock(date, timeIntervals)
      : getTimeBlock(date, timeIntervals);

  const DEFAULT_VALUE = false;

  if (timeBlock.includes('BEFORE')) {
    if (aboveBelow === 'ABOVE') {
      return glucose ? glucose > upperPre : DEFAULT_VALUE;
    } else if (aboveBelow === 'BELOW') {
      return glucose ? glucose < lowerPre : DEFAULT_VALUE;
    } else {
      return DEFAULT_VALUE;
    }
  } else if (timeBlock.includes('AFTER')) {
    if (aboveBelow === 'ABOVE') {
      return glucose ? glucose > upperPost : DEFAULT_VALUE;
    } else if (aboveBelow === 'BELOW') {
      return glucose ? glucose < lowerPost : DEFAULT_VALUE;
    } else {
      return DEFAULT_VALUE;
    }
  } else {
    if (aboveBelow === 'ABOVE') {
      return glucose ? glucose > upperNoct : DEFAULT_VALUE;
    } else if (aboveBelow === 'BELOW') {
      return glucose ? glucose < lowerNoct : DEFAULT_VALUE;
    } else {
      return DEFAULT_VALUE;
    }
  }
};
