import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { SvgIcon } from 'src/components/icon';
import { colors } from 'src/core/styles/colors';

const originalWidth = 3;
const originalHeight = 16;
const aspectRatio = originalWidth / originalHeight;

export const Logbook24hourCarbsIconComponent = ({
  height = originalHeight,
  width = originalWidth,
  fillColor = colors.white,
  t,
}) => (
  <SvgIcon
    title={t('graphs.iconTitles.logbook24hours.carbsSum')}
    width={height * aspectRatio}
    height={height}
    originalWidth={originalWidth}
    originalHeight={originalHeight}
  >
    <g
      id="Logbook-24-Hour-Chart"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="24-Hour-Logbook-XL-(V001)"
        transform="translate(-1238.000000, -583.000000)"
        fill="#E59037"
      >
        <g id="Group-18" transform="translate(1230.000000, 507.000000)">
          <g id="Group-16" transform="translate(0.000000, 34.000000)">
            <g id="Group-21" transform="translate(8.378378, 15.000000)">
              <g id="Carbs" transform="translate(0.000000, 27.000000)">
                <polygon
                  id="Rectangle-26-Copy-8"
                  points="0 0 2.09459459 0 2.09459459 4 0 4"
                ></polygon>
                <polygon
                  id="Rectangle-26-Copy-8"
                  points="0 6 2.09459459 6 2.09459459 10 0 10"
                ></polygon>
                <polygon
                  id="Rectangle-26-Copy-8"
                  points="0 12 2.09459459 12 2.09459459 16 0 16"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export const Logbook24hourCarbsIcon = withTranslation(
  Logbook24hourCarbsIconComponent,
);
