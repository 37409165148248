import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { createModal, destroyModal } from 'src/core/modal';
import { mapDispatchers } from 'src/utils/map-dispatchers';
import { withToken } from 'src/utils/with-token';
import { withPatientSummary } from 'src/domains/patient/utils/with-patient-summary';

import { DeactivatePatientComponent } from './deactivate-patient.component';
import { deactivatePatientStart } from './store/deactivate-patient.actions';

const dispatchers = mapDispatchers({
  createModal,
  destroyModal,
  deactivatePatientStart,
});

export const DeactivatePatient = compose(
  withToken,
  connect(null, dispatchers),
  withPatientSummary,
  withTranslation,
)(DeactivatePatientComponent);
