import styled from 'styled-components';

import { colors, fontSize, spacing } from 'src/core';
import { convertPxToRem } from 'src/utils';

export const LogbookWrapper = styled.div`
  background-color: ${(props) =>
    props.blueBackground && colors.blueMarineAlpha5};
  min-height: inherit;
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  justify-content: center;
`;

LogbookWrapper.displayName = 'LogbookWrapper';

export const LogbookGraphWrapperDiv = styled.div`
  margin: ${(props) =>
    props.collapsed || props.collapsedGP
      ? '0'
      : `${spacing.two} ${spacing.four} 1.8rem`};
  overflow-x: auto;
  flex: 1 1 0;
  ${(props) => props.collapsed && 'flex-basis: auto'};
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

LogbookGraphWrapperDiv.displayName = 'LogbookGraphWrapperDiv';

export const GraphDetailContainer = styled.div`
  position: relative;
  justify-content: flex-end;
`;

GraphDetailContainer.displayName = 'GraphDetailContainer';

export const LogbookGraphWrapper = styled.div`
  height: ${(props) =>
    props.tableHeight ? `${props.tableHeight}px` : 'initial'};
`;
LogbookGraphWrapper.displayName = 'LogbookGraphWrapper';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
Container.displayName = 'Container';

export const CardSectionTitleDiv = styled.div`
  font-size: ${fontSize.headline};
  color: ${colors.charcoal};
  padding-left: ${spacing.four};
  margin-bottom: ${convertPxToRem(-5)};
  display: flex;
  justify-content: flex-start;
  padding: 0 ${convertPxToRem(330)} 0 ${convertPxToRem(50)};
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

CardSectionTitleDiv.displayName = 'CardSectionTitleDiv';
