import { GridContainer, GridItem } from 'src/components';
import { BaseCardDiv } from 'src/components/base-card/base-card.style';
import { breakpoints } from 'src/core/styles/breakpoints';
import { ButtonReset, HrReset } from 'src/core/styles/resets';
import { combineRems, convertPxToRem } from 'src/utils';
import { createStyledComponent } from 'src/utils/styles';
import styled from 'styled-components';
import { colors } from 'src/core';

const ADDITIONAL_OPTIONS_MAX_WIDTH_PX = 1136;

export const ResponsiveGridContainer = styled(GridContainer)`
  grid-gap: ${({ theme }) => theme.spacing.four};
  grid-auto-flow: row;

  @media all and (min-width: ${breakpoints.large}) {
    grid-gap: ${({ theme }) =>
      combineRems(theme.spacing.three, theme.spacing.four)}};
  }
`;

export const ResponsiveGridContainerSmall = styled(GridContainer)`
  grid-gap: ${({ theme }) => theme.spacing.three};

  ${GridItem} {
    padding-bottom: 0;
    padding-top: 0;
  }

  @media all and (min-width: ${breakpoints.large}) {
    grid-gap: ${({ theme }) => theme.spacing.four};
  }
`;

export const PatientFormContainer = styled.div`
  margin: 0 auto;
  max-width: ${convertPxToRem(1500)};
  padding: 2rem;
  .insContainer {
    background: ${(props) => props.theme.colors.silverLight};
    padding: 2rem;
    .insTitle {
      padding-bottom: 1.5rem;
    }
    .spacer {
      border-bottom: 1px solid ${colors.grayLight};
      width: 100%;
      display: block;
      margin: 1rem 0 1.75rem 0;
    }
    .insStatus {
      text-align: right;
      .status {
        margin-left: ${({ theme }) => theme.spacing.two};
        color: ${({ theme }) => theme.colors.red};
        &.provisional {
          color: ${(props) => props.theme.colors.trafficRed};
        }
        &.validated {
          color: ${(props) => props.theme.colors.trafficOrange};
        }
        &.qualified {
          color: ${(props) => props.theme.colors.trafficGreen};
        }
        &.retrieved {
          color: ${(props) => props.theme.colors.blueMarine};
        }
        &.confirmed {
          color: ${(props) => props.theme.colors.grayDark};
        }
      }
    }
    .requestInsBtn {
      text-align: right;
    }
  }
`;

export const PatientFormWrapper = styled.div`
  padding: ${(props) => props.theme.spacing.four}
    ${(props) => props.theme.spacing.five};
`;
PatientFormWrapper.displayName = 'PatientFormWrapper';

export const PatientFormHeader = styled.h1`
  color: ${(props) => props.theme.colors.brandBlue};
  font-size: ${(props) => props.theme.fontSize.headline};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  line-height: 1;
  margin: 0 auto
    ${(props) =>
      combineRems(props.theme.spacing.one, props.theme.spacing.three)};
`;

export const PatientFormSubHeader = styled.h3`
  color: ${(props) => props.theme.colors.brandBlue};
  font-size: ${(props) => props.theme.fontSize.subheading};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  margin: 0 auto
    ${(props) => combineRems(props.theme.spacing.one, props.theme.spacing.two)};
`;

export const Hr = styled(HrReset)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLighter};
  margin-bottom: 2rem;
  width: 100%;
`;

export const SmallHr = styled(HrReset)`
  border-bottom: 2px solid ${(props) => props.theme.colors.blueMarine};
  margin: 0 auto;
  max-width: ${(props) => convertPxToRem(50)};
  width: 100%;
`;

export const RadioContainer = styled.div`
  align-items: center;
  display: flex;
  min-height: ${convertPxToRem(60)};
`;

export const CancelButton = styled(ButtonReset)`
  color: ${({ theme }) => theme.colors.brandBlue};
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  background-color: ${({ theme }) => theme.colors.clear};
  padding: ${({ theme }) => theme.spacing.three}
    ${({ theme }) => theme.spacing.four};

  &:focus {
    color: 1px solid ${({ theme }) => theme.colors.brandBlueDark};
  }
`;

export const ButtonsContainer = styled(PatientFormContainer)`
  padding: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.five};

  & > button {
    margin-left: ${({ theme }) => theme.spacing.four};
  }
`;

export const AdditionalOptionsContainer = createStyledComponent<FixMe, any>(
  BaseCardDiv,
)`
  padding: 0;
  box-shadow: none;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: ${convertPxToRem(ADDITIONAL_OPTIONS_MAX_WIDTH_PX)};
`;
AdditionalOptionsContainer.displayName = 'AdditionalOptionsContainer';

export const AdditionalOptionsHeader = createStyledComponent<'div', any>('div')`
  background-color: ${({ theme }) => theme.colors.blueFadedLight};
  border: 1px solid ${({ theme }) => theme.colors.quartzBlue};
  border-radius: ${({ theme }) => theme.borderRadius[0]} ${({ theme }) =>
  theme.borderRadius[0]} 0 0;
`;

export const AdditionalOptionsHeaderTitle = createStyledComponent<'h2', any>(
  'h2',
)`
  color: ${({ theme }) => theme.colors.brandBlue};
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.4px;
  padding: 0 1rem;
  padding-top: 2px;
`;
AdditionalOptionsHeaderTitle.displayName = 'AdditionalOptionsHeaderTitle';

export const AdditionalOptionsGrid = createStyledComponent<FixMe, any>(
  GridContainer,
)`
  border: 1px solid ${({ theme }) => theme.colors.silverDark};
  border-top: 0;
  border-radius: 0 0 ${({ theme }) => theme.borderRadius[0]} ${({ theme }) =>
  theme.borderRadius[0]};
  padding: ${({ theme }) => theme.spacing.four};
  margin: 0!important;
`;
AdditionalOptionsGrid.displayName = 'AdditionalOptionsGrid';

export const StatusContainer = createStyledComponent<'p', any>('p')`
  float: right;
  font-size: ${({ theme }) => theme.fontSize.p};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: 0;
`;
StatusContainer.displayName = 'StatusContainer';

export const Status = createStyledComponent<'span', any>('span')`
  margin-left: ${({ theme }) => theme.spacing.one};
  color: ${({ theme }) => theme.colors.red};
  &.provisional {
    color: ${(props) => props.theme.colors.trafficRed};
  }
  &.validated {
    color: ${(props) => props.theme.colors.trafficOrange};
  }
  &.qualified {
    color: ${(props) => props.theme.colors.trafficGreen};
  }
  &.retrieved {
    color: ${(props) => props.theme.colors.blueMarine};
  }
  &.confirmed {
    color: ${(props) => props.theme.colors.grayDark};
  }
`;
Status.displayName = 'Status';

export const BelowLabel = styled.div`
  padding-top: 0.5rem;
  font-size: ${(props) => props.theme.fontSize.p};
  color: ${({ theme }) => theme.colors.grayDark};
`;

BelowLabel.displayName = 'BelowLabel';
