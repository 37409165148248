import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import {
  LocalizedText,
  StackedRadialChart,
} from 'src/domains/diagnostics/components';
import { colors } from 'src/core/styles';
import {
  RadialChartContainerDiv,
  RadialChartBlockDiv,
  DetailRow,
  BasalBolusDetail,
  BasalBolusDetailContainer,
  BasalBolusDetailLabel,
  InsulinDataWrapperDiv,
} from 'src/domains/diagnostics/scenes/insulin/insulin.style';
import { DetailBlock } from 'src/domains/diagnostics/scenes/insulin/components';
import { withGraphLoader } from 'src/domains/diagnostics/utils/with-graph-loader';

const DetailRowWithLoader = withGraphLoader(
  DetailRow,
  'graphs.insulin.loading',
);

export const InsulinDetailsComponent = ({
  avgBolusBasalPerDay,
  avgBolusBasalPerDaySegments,
  hasNoData,
  isLoading,
  t,
  expanded,
  bolusAdviceData,
  bolusAdviceSegments,
}) => {
  const {
    totalBolusAvg,
    totalBasalAvg,
    detailInsulinPerDay,
    hasBasalData,
    hasBolusData,
  } = avgBolusBasalPerDay;
  const {
    bolusAcceptedPercentage,
    numBolusAccepted,
    bolusModifiedPercentage,
    numBolusModified,
    bolusWithoutCalPercentage,
    numBolusWithoutCal,
    totalBolusWithIns1,
  } = bolusAdviceData;

  return (
    <DetailRowWithLoader isLoading={isLoading} hasError={hasNoData}>
      <BasalBolusDetailContainer>
        <BasalBolusDetailLabel expanded={expanded}>
          <LocalizedText textKey={'graphs.bolus.basalBolusTitle'} />
        </BasalBolusDetailLabel>
        <BasalBolusDetail>
          <RadialChartBlockDiv>
            <RadialChartContainerDiv>
              <StackedRadialChart
                diameter={3.5}
                segments={avgBolusBasalPerDaySegments}
                strokeWidth={0.6}
              />
            </RadialChartContainerDiv>
          </RadialChartBlockDiv>
          <InsulinDataWrapperDiv>
            <DetailBlock
              flex={1}
              primaryTitle={t('graphs.bolus.basalInsulin')}
              secondaryTitle={`${detailInsulinPerDay.basalUPerDay} ${t(
                'graphs.bolus.unitsDay',
              )}`}
              value={totalBasalAvg}
              circleFill={hasBasalData ? colors.blueMarine : ''}
              hasData={hasBasalData}
              hasSufficiencyData={hasBolusData}
            />
            <DetailBlock
              flex={1}
              primaryTitle={t('graphs.bolus.bolus')}
              secondaryTitle={`${detailInsulinPerDay.bolusUPerDay} ${t(
                'graphs.bolus.unitsDay',
              )}`}
              value={totalBolusAvg}
              circleFill={hasBolusData ? colors.blueVeryLight : ''}
              hasData={hasBolusData}
              hasSufficiencyData={hasBasalData}
            />
            <DetailBlock
              flex={1}
              primaryTitle={t('graphs.bolus.totalInsulin')}
              value={`${detailInsulinPerDay.totalUPerDay} ${t(
                'graphs.bolus.unitsDay',
              )}`}
              hasData={hasBasalData || hasBolusData}
              hasSufficiencyData={hasBasalData && hasBolusData}
            />
          </InsulinDataWrapperDiv>
        </BasalBolusDetail>
      </BasalBolusDetailContainer>
      <BasalBolusDetailContainer>
        <BasalBolusDetailLabel expanded={expanded}>
          <LocalizedText textKey={'graphs.bolus.bolusAdviseTitle'} />
        </BasalBolusDetailLabel>
        <BasalBolusDetail>
          <RadialChartBlockDiv>
            <RadialChartContainerDiv>
              <StackedRadialChart
                diameter={3.5}
                segments={bolusAdviceSegments}
                strokeWidth={0.6}
              />
            </RadialChartContainerDiv>
          </RadialChartBlockDiv>
          <InsulinDataWrapperDiv>
            <DetailBlock
              flex={1}
              primaryTitle={t('graphs.bolus.acceptedBolus')}
              secondaryTitle={`${numBolusAccepted} ${t(
                'graphs.bolus.inTotal',
              )}`}
              value={`${bolusAcceptedPercentage}%`}
              circleFill={hasBolusData ? colors.blueVeryLight : ''}
              hasData={hasBolusData}
              hasSufficiencyData={true}
            />
            <DetailBlock
              flex={1}
              primaryTitle={t('graphs.bolus.modifiedBolus')}
              secondaryTitle={`${numBolusModified} ${t(
                'graphs.bolus.inTotal',
              )}`}
              value={`${bolusModifiedPercentage}%`}
              circleFill={hasBolusData ? colors.blueMarine : ''}
              hasData={hasBolusData}
              hasSufficiencyData={true}
            />
            <DetailBlock
              flex={1}
              primaryTitle={t('graphs.bolus.bolusWithoutCal')}
              secondaryTitle={`${numBolusWithoutCal} ${t(
                'graphs.bolus.inTotal',
              )}`}
              value={`${bolusWithoutCalPercentage}%`}
              circleFill={hasBolusData && colors.purpleBolusCal}
              hasData={hasBolusData}
              hasSufficiencyData={true}
            />
            <DetailBlock
              flex={1}
              primaryTitle={t('graphs.bolus.totalBolus')}
              value={totalBolusWithIns1}
              hasData={hasBolusData}
              hasSufficiencyData={true}
            />
          </InsulinDataWrapperDiv>
        </BasalBolusDetail>
      </BasalBolusDetailContainer>
    </DetailRowWithLoader>
  );
};

export const InsulinDetails = withTranslation(InsulinDetailsComponent);
