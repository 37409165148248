import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';
import { RectangleMarkIcon } from 'src/assets/icons';
import { BG_UNIT_TO_UNIT_KEY } from 'src/domains/diagnostics/scenes/graphs/graph.constants';
import { colors } from 'src/core/styles';
import { LocalizedText } from 'src/domains/diagnostics/components/localized-text/localized-text.component';

import {
  IconContainer,
  ListSection,
  ListsContainer,
  List,
  ListElement,
  ListElementSectionHeader,
  ListsContainerWrapper,
} from '../../graph-statistics.style';

export const GraphDetailTargetRangesComponent = ({
  abovePercentage,
  belowAndHypoPercentage,
  bloodGlucoseUnit,
  hypoglycaemiaNumber,
  hypoglycaemiaPercentage,
  targetBloodGlucoseMinimum,
  targetBloodGlucoseMaximum,
  withinPercentage,
  borderRight = true,
  t,
}) => (
  <ListSection {...{ borderRight }}>
    <ListElementSectionHeader>
      <IconContainer>
        <RectangleMarkIcon
          strokeColor={colors.transparentGreen}
          fillColor={colors.transparentGreen}
        />
      </IconContainer>
      <LocalizedText
        textKey={'graphDetails.statistics.targetRanges.targetRange'}
      />
      {` (${targetBloodGlucoseMinimum}-${targetBloodGlucoseMaximum} ${t(
        BG_UNIT_TO_UNIT_KEY[bloodGlucoseUnit],
      )})`}
    </ListElementSectionHeader>
    <ListsContainerWrapper>
      <ListsContainer>
        <List flex="none" iconPadding>
          <ListElement>
            <LocalizedText
              textKey={'graphDetails.statistics.targetRanges.above'}
            />
          </ListElement>
          <ListElement>
            <LocalizedText
              textKey={'graphDetails.statistics.targetRanges.inRange'}
            />
          </ListElement>
          <ListElement>
            <LocalizedText
              textKey={'graphDetails.statistics.targetRanges.below'}
            />
          </ListElement>
        </List>
        <List>
          <ListElement bold>{`${abovePercentage}%`}</ListElement>
          <ListElement bold>{`${withinPercentage}%`}</ListElement>
          <ListElement bold>{`${belowAndHypoPercentage}%`}</ListElement>
        </List>
      </ListsContainer>
      <ListsContainer>
        <List noBorder flex="none">
          <ListElement>
            <LocalizedText
              textKey={'graphDetails.statistics.targetRanges.hypoVeryLow'}
            />
          </ListElement>
        </List>
        <List>
          <ListElement bold>
            {`${hypoglycaemiaPercentage}% (${hypoglycaemiaNumber})`}
          </ListElement>
        </List>
      </ListsContainer>
    </ListsContainerWrapper>
  </ListSection>
);

export const GraphDetailTargetRanges = withTranslation(
  GraphDetailTargetRangesComponent,
);
