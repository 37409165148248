// @ts-nocheck
import * as React from 'react';

import { GenerateTripleColumnIconsComponent } from '../icon-components.component';
import { JelloIcon } from 'src/components/jello-icon';

import {
  V3HomeIcon,
  NewPatientIcon,
  V3StockIcon,
  LogbookIcon,
  LogbookDiaryIcon,
  V3HelpIcon,
  BoxGraphIcon,
  LineGraphIcon,
  InformationIcon,
  V3MenuIcon,
  V3CalendarIcon,
  V3ExportTimeTrackerIcon,
  NoDataIcon,
  ArrowIcon,
  PdfDownloadIcon,
  V3UploadDataIcon,
  InformationFilledIcon,
} from 'src/assets/icons';

import { V3UserIcon } from 'src/assets/icons/v3-user-icon/v3-user-icon.component';
import { Logbook24HourIcon } from 'src/assets/icons/index.js';
import { V3RpmSettingsIcon } from 'src/assets/icons/v3-rpm-settings-icon/v3-rpm-settings-icon.component';

import { colors } from 'src/core/styles';

const generalIconsTitle = 'manufacturerInfo.generalIcons.generalIconsTitle';

const generalIconTags = [
  {
    objectComponent: (
      <V3HomeIcon
        key={1}
        height={24}
        fillColor={colors.white}
        strokeColor={colors.brandBlue}
        originalWidth={24}
        originalHeight={24}
        minX={7}
        minY={6}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.home',
  },
  {
    objectComponent: (
      <NewPatientIcon
        key={2}
        height={20}
        originalWidth={20}
        originalHeight={21}
        fillColor={colors.white}
        strokeColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.createNewPatient',
  },
  {
    objectComponent: (
      <JelloIcon
        size="M"
        color={colors.brandBlue}
        iconName={'no--enough--data'}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.notEnoughDataAvailable',
  },
  {
    objectComponent: (
      <V3StockIcon
        key={4}
        height={20}
        fillColor={colors.brandBlue}
        strokeColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.stock',
  },
  {
    objectComponent: (
      <V3UserIcon
        key={5}
        height={20}
        innerColor={colors.white}
        width={15}
        originalWidth={20}
        originalHeight={44}
        minX={8}
        fillColor={colors.white}
        strokeColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.hcpProfile',
  },
  {
    objectComponent: (
      <LogbookIcon key={6} height={18} fillColor={colors.brandBlue} />
    ),
    nametag: 'manufacturerInfo.generalIcons.logbook',
  },
  {
    objectComponent: (
      <V3UploadDataIcon
        key={7}
        height={19}
        strokeColor={colors.white}
        fillColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.deviceLink',
  },
  {
    objectComponent: (
      <LogbookDiaryIcon
        key={9}
        height={24}
        fillColor={colors.brandBlue}
        originalWidth={6}
        originalHeight={17}
        minX={6}
        minY={-2}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.diary',
  },
  {
    objectComponent: (
      <Logbook24HourIcon key={9} height={17} fillColor={colors.brandBlue} />
    ),
    nametag: 'manufacturerInfo.generalIcons.24HRLogbook',
  },
  {
    objectComponent: (
      <V3HelpIcon
        key={10}
        height={24}
        width={18}
        minX={2}
        originalWidth={6}
        originalHeight={24}
        tspanY={20}
        fillColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.help',
  },
  {
    objectComponent: (
      <BoxGraphIcon key={11} height={17} fillColor={colors.brandBlue} />
    ),
    nametag: 'manufacturerInfo.generalIcons.details',
  },
  {
    objectComponent: (
      <V3RpmSettingsIcon
        key={12}
        height={17}
        fillColor={colors.white}
        strokeColor={colors.brandBlue}
        lineColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.RPMSettings',
  },
  {
    objectComponent: (
      <InformationFilledIcon
        key={13}
        height={20}
        fillColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.information',
  },
  {
    objectComponent: (
      <LineGraphIcon key={14} height={17} fillColor={colors.brandBlue} />
    ),
    nametag: 'manufacturerInfo.generalIcons.trend',
  },
  {
    objectComponent: (
      <JelloIcon
        size="M"
        color={colors.brandBlue}
        iconName={'no--enough--data'}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.disclaimer',
  },
  {
    objectComponent: (
      <InformationIcon key={16} height={20} fillColor={colors.brandBlue} />
    ),
    nametag: 'manufacturerInfo.generalIcons.productInformation',
  },
  {
    objectComponent: (
      <JelloIcon iconName={'maximize'} size={'XS'} color={colors.brandBlue} />
    ),
    nametag: 'manufacturerInfo.generalIcons.expand',
  },
  {
    objectComponent: (
      <V3MenuIcon
        key={18}
        height={18}
        originalWidth={31}
        originalHeight={31}
        fillColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.generalFunctions',
  },
  {
    objectComponent: (
      <V3CalendarIcon
        key={19}
        height={20}
        dotColor={colors.brandBlue}
        fillColor={colors.white}
        strokeColor={colors.brandBlue}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.calendarPicker',
  },
  {
    objectComponent: (
      <JelloIcon iconName={'minimize'} size={'XS'} color={colors.brandBlue} />
    ),
    nametag: 'manufacturerInfo.generalIcons.collapse',
  },
  {
    objectComponent: (
      <V3ExportTimeTrackerIcon
        key={21}
        height={26}
        width={26}
        originalWidth={34}
        originalHeight={34}
        minX={7}
        minY={6}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.exportBillingReport',
  },
  {
    objectComponent: <NoDataIcon key={22} height={20} />,
    nametag: 'manufacturerInfo.generalIcons.noData',
  },
  {
    objectComponent: (
      <ArrowIcon key={23} height={20} fillColor={colors.brandBlue} />
    ),
    nametag: 'manufacturerInfo.generalIcons.dropdown',
  },
  {
    objectComponent: (
      <PdfDownloadIcon
        key={24}
        borderColor={colors.white}
        color={colors.brandBlue}
        viewBox={'6 4 32 32'}
      />
    ),
    nametag: 'manufacturerInfo.generalIcons.sendEMR',
  },
];

export const GeneralIconsComponent = () => (
  <GenerateTripleColumnIconsComponent
    generalIconTags={generalIconTags}
    generalIconsTitle={generalIconsTitle}
  />
);
