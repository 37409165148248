
const value1 = 57.0
const value2 = 157.0

const glucoseValue = {
  glucose: 117.0,
  date: '2016-11-30T06:23:33Z',
  carbohydrates: null,
  insulin1: null,
  insulin2: null,
  insulin3: null,
  flagAboveTargetRange: false,
  flagBelowTargetRange: false,
  flagUsedDrum: false,
  flagExpiredStrip: false,
  flagManuallyEntered: false,
  flagTemperatureWarning: false,
  beforeMeal: true,
  afterMeal: false,
  mealSizeNoMeal: false,
  mealSizeSmall: false,
  mealSizeMedium: false,
  mealSizeLarge: false,
  control: false,
  controlType: null,
  hypoSymptoms: true,
  events: null,
  fType: null,
  fEvt: null,
  specialMeasurement: false,
  advicedBolus: null,
}

export const mockClinicalData = {
  resultDescription: 'getClinicalDataOK',
  model: {
    glucoseValues: [glucoseValue,glucoseValue,glucoseValue],
    insulinPump: {
      name: null,
      serialNumber: null,
      pumpDate: null,
      restUseDays: null,
      activeProfile: null,
      ipProfile: [],
    },
    newData: {
      bolus: [
        {
          date: '2018-02-14T16:01:00+0000',
          value: 0,
          remark: 'Bolus Total',
          registerType: 'BolusTotal',
          bolusType: null,
          cmdType: null,
        },
        {
          date: '2018-02-18T09:38:00+0000',
          value: 17.6,
          remark: 'Bolus+Basal Total',
          registerType: 'BolusPlusBasalTotal',
          bolusType: null,
          cmdType: null,
        },
        {
          date: '2018-02-27T17:13:00+0000',
          value: 2,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
          cmdType: null,
        },
        {
          date: '2018-02-28T00:00:00+0000',
          value: 8,
          remark: 'Bolus Total',
          registerType: 'BolusTotal',
          bolusType: null,
          cmdType: null,
        },
        {
          date: '2018-02-28T08:17:00+0000',
          value: 1.5,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
          cmdType: null,
        },
        {
          date: '2018-02-28T13:00:00+0000',
          value: 3.5,
          remark: null,
          registerType: 'Bolus',
          bolusType: 'Std',
          cmdType: null,
        },
        {
          date: '2018-02-28T18:00:00+0000',
          value: 3,
          remark: '1.00 / 3.00  1:58 h',
          registerType: 'Bolus',
          bolusType: 'Mul',
          cmdType: null,
        },
      ],
      basals: [
        {
          date: '2018-02-14T16:01:00+0000',
          cbrf: 1.1,
          profile: '1',
          cmd: null,
          remark: null,
          virtual: null,
          tsb: null,
          tsbDiffMins: null,
          tbrdec: null,
          tbrinc: null,
        },
        {
          date: '2018-02-18T09:38:00+0000',
          cbrf: 0.7,
          profile: '1',
          cmd: null,
          remark: null,
          virtual: null,
          tsb: null,
          tsbDiffMins: null,
          tbrdec: null,
          tbrinc: null,
        },
        {
          date: '2018-02-22T00:40:00+0000',
          cbrf: 0.9,
          profile: '1',
          cmd: null,
          remark: null,
          virtual: null,
          tsb: null,
          tsbDiffMins: null,
          tbrdec: null,
          tbrinc: null,
        },
      ],
      events: [],
      lastEventDate: null,
      lastBasalDate: null,
      lastBolusDate: null,
    },
    removedData: {
      bolus: [],
      basals: [],
      events: [],
      lastEventDate: null,
      lastBasalDate: null,
      lastBolusDate: null,
    },
    lastGlucoseDate: null,
    info: 'Gluco Values : 59\nNew Data - Bolus: 0\nNew Data - Basal: 0\nNew Data - Events: 0',
    pumpData: false,
    unit: 'mg/dL',
  },
};

mockClinicalData.model.glucoseValues[1] = value1;
mockClinicalData.model.glucoseValues[2] = value2;

export const mockTransformedClinicalData = {
  measurements: [
    {
      aboveTargetRange: false,
      afterMeal: false,
      beforeMeal: true,
      belowTargetRange: false,
      carbohydrates: null,
      date: '2016-11-30T11:23:33.000Z',
      hypoSymptoms: true,
      value: 117.0,
    },
    {
      aboveTargetRange: false,
      afterMeal: false,
      beforeMeal: false,
      belowTargetRange: true,
      carbohydrates: null,
      date: '2016-11-30T16:23:33.000Z',
      hypoSymptoms: true,
      value: 57.0,
    },
    {
      aboveTargetRange: true,
      afterMeal: false,
      beforeMeal: true,
      belowTargetRange: false,
      carbohydrates: null,
      date: '2016-11-30T21:23:33.000Z',
      hypoSymptoms: true,
      value: 157.0,
    },
  ],
  insulin: {
    bolus: [
      {
        date: '2018-02-14T16:01:00+0000',
        value: 0,
        remark: 'Bolus Total',
        registerType: 'BolusTotal',
        bolusType: null,
      },
      {
        date: '2018-02-18T09:38:00+0000',
        value: 17.6,
        remark: 'Bolus+Basal Total',
        registerType: 'BolusPlusBasalTotal',
        bolusType: null,
      },
      {
        date: '2018-02-27T17:13:00+0000',
        value: 2,
        remark: null,
        registerType: 'Bolus',
        bolusType: 'Std',
      },
      {
        date: '2018-02-28T00:00:00+0000',
        value: 8,
        remark: 'Bolus Total',
        registerType: 'BolusTotal',
        bolusType: null,
      },
      {
        date: '2018-02-28T08:17:00+0000',
        value: 1.5,
        remark: null,
        registerType: 'Bolus',
        bolusType: 'Std',
      },
      {
        date: '2018-02-28T13:00:00+0000',
        value: 3.5,
        remark: null,
        registerType: 'Bolus',
        bolusType: 'Std',
      },
      {
        date: '2018-02-28T18:00:00+0000',
        value: 3,
        remark: '1.00 / 3.00  1:58 h',
        registerType: 'Bolus',
        bolusType: 'Mul',
      },
    ],
    basals: [
      {
        date: '2018-02-14T16:01:00+0000',
        cbrf: 1.1,
        profile: 1,
      },
      {
        date: '2018-02-18T09:38:00+0000',
        cbrf: 0.7,
        profile: 1,
      },
      {
        date: '2018-02-22T00:40:00+0000',
        cbrf: 0.9,
        profile: 1,
      },
    ],
  },
};
