import { createStyledComponent } from 'src/utils/styles';
import styled from 'styled-components';
import { Block } from 'src/components/block';
import { LabelledDropdown } from 'src/components/dropdown/labelled-dropdown';
import { MediumTitle } from 'src/components/typography';
import { breakpoints } from 'src/core';

export const BlockWrapper = styled(Block)``;
BlockWrapper.displayName = 'BlockWrapper';

export const Periods = createStyledComponent<'div', {}>('div')`
  > span {
    letter-spacing: 0.3px;
    vertical-align: top;
  }
  max-width: 270px;
  @media (max-width: ${breakpoints.changeMedium}) {
    padding-top: 0px;
    max-width: 93px
  }
`;
Periods.displayName = 'Periods';

export const ButtonsList = createStyledComponent<'div', {}>('div')`
  width: 100%;
  display: flex;
  padding: 0;
  justify-content: space-between;
  margin: 0;
  button {
    width: 100%;
    margin-right: 0;
    min-width: 50px
  }
  li:last-of-type {
    padding-right: 0;
    button {
      margin-right: 0;
      min-width: 70px
    }
  }
`;
ButtonsList.displayName = 'ButtonsList';

export const ButtonsListItem = createStyledComponent<'div', {}>('div')`
  display: flex;
`;
ButtonsListItem.displayName = 'ButtonsListItem';

export const LabelledDropdownWrapper = styled(LabelledDropdown)`
  max-width: 120px;
`;
LabelledDropdownWrapper.displayName = 'LabelledDropdownWrapper';

export const ControlContainer = createStyledComponent<'div', {}>('div')`
  form {
    > div {
      width: 100% !important;
    }
  }
`;
ControlContainer.displayName = 'ControlContainer';

export const Title = styled(MediumTitle)`
  @media (max-width: 1299px) {
    vertical-align: top;
    font-size: 9px;
  }
`;
Title.displayName = 'PatientInfoTitleText';
