import { isNil } from 'ramda';

import {
  calculateHypoRiskColor,
  calculateMeanColor,
  isHypoRed,
  isHypoYellow,
  isHyperRed,
  isHyperYellow,
  isInRange,
  isHypoRiskLow,
  isHypoRiskMedium,
  isHypoRiskHigh,
  TRAFFIC_LIGHT_COLORS,
  VARIABILITY_THRESHOLD,
} from 'src/domains/diagnostics/scenes/blood-glucose-overview/store';
import { EMPTY_VALUE_PLACEHOLDER } from 'src/domains/diagnostics/store/constants';

export const STATUS_LABELS = {
  LOW: 'dashboard.statusCard.low',
  MODERATE: 'dashboard.statusCard.moderate',
  MEDIUM: 'dashboard.statusCard.medium',
  HIGH: 'dashboard.statusCard.high',
  STABLE: 'dashboard.statusCard.stable',
  UNSTABLE: 'dashboard.statusCard.unstable',
  ABOVE: 'dashboard.statusCard.above',
  WITHIN: 'dashboard.statusCard.within',
  BELOW: 'dashboard.statusCard.below',
  TARGET_RANGE: 'dashboard.statusCard.targetRange',
  HYPO_LIMIT: 'dashboard.statusCard.hypoLimit',
  NONE: '- - ',
};

const emptyStatus = {
  color: TRAFFIC_LIGHT_COLORS.GRAY,
  label: STATUS_LABELS.NONE,
};

export const toVariabilityStatus = (value, numberOfMeasurements) => {
  if (
    numberOfMeasurements === 0 ||
    isNil(value) ||
    value === EMPTY_VALUE_PLACEHOLDER
  ) {
    return emptyStatus;
  }

  if (value <= VARIABILITY_THRESHOLD) {
    return {
      color: TRAFFIC_LIGHT_COLORS.GREEN,
      label: STATUS_LABELS.STABLE,
    };
  } else {
    return {
      color: TRAFFIC_LIGHT_COLORS.RED,
      label: STATUS_LABELS.UNSTABLE,
    };
  }
};

const calculateMeanLabel = (
  value,
  {
    glucoseIdealIntervalMin,
    glucoseIdealIntervalMax,
    hypoglycemiaThreshold,
    upperHyperThreshold,
  },
) => {
  if (isHypoRed(value, hypoglycemiaThreshold)) {
    return [STATUS_LABELS.BELOW, STATUS_LABELS.HYPO_LIMIT];
  }
  if (isHypoYellow(value, hypoglycemiaThreshold, glucoseIdealIntervalMin)) {
    return [STATUS_LABELS.BELOW, STATUS_LABELS.TARGET_RANGE];
  }
  if (isInRange(value, glucoseIdealIntervalMin, glucoseIdealIntervalMax)) {
    return [STATUS_LABELS.WITHIN, STATUS_LABELS.TARGET_RANGE];
  }
  if (
    isHyperYellow(value, glucoseIdealIntervalMax, upperHyperThreshold) ||
    isHyperRed(value, upperHyperThreshold)
  ) {
    return [STATUS_LABELS.ABOVE, STATUS_LABELS.TARGET_RANGE];
  }
  return STATUS_LABELS.NONE;
};

export const toMeanStatus = (numberOfMeasurements, value, thresholds) => {
  if (isNil(numberOfMeasurements) || numberOfMeasurements === 0) {
    return emptyStatus;
  }

  return {
    color: calculateMeanColor(value, thresholds),
    label: calculateMeanLabel(value, thresholds),
  };
};

export const calculateHypoRiskLabel = (lbgi) => {
  if (isHypoRiskLow(lbgi)) {
    return STATUS_LABELS.LOW;
  } else if (isHypoRiskMedium(lbgi)) {
    return STATUS_LABELS.MEDIUM;
  } else if (isHypoRiskHigh(lbgi)) {
    return STATUS_LABELS.HIGH;
  }

  return STATUS_LABELS.NONE;
};

export const toHypoRiskStatus = (hypoRisk, hasReliableInfo) => {
  if (
    isNil(hypoRisk) ||
    hypoRisk.numberOfMeasurements === 0 ||
    !hasReliableInfo
  ) {
    return emptyStatus;
  }

  const { lbgi, numberOfMeasurements } = hypoRisk;

  return {
    color: calculateHypoRiskColor(lbgi, numberOfMeasurements),
    label: calculateHypoRiskLabel(lbgi),
  };
};
