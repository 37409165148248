import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { SvgIcon } from 'src/components/icon';

const RunningIconComponent = ({ height, width, t }) => {
  const originalWidth = 15;
  const originalHeight = 18;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.running')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="_icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="NEW-ICONS"
          transform="translate(-888.000000, -527.000000)"
          fillRule="nonzero"
        >
          <g id="Group-14" transform="translate(888.000000, 527.000000)">
            <path
              d="M4.03599901,8.176468 C4.04865743,9.05882353 4.65623056,9.76470071 5.41570476,9.74999418 L6.85870261,9.73528765 C7.1245295,9.73528765 7.37766678,9.77940724 7.61817681,9.88235294 L7.61817681,9.07353006 C7.70678577,7.98528312 8.03587359,6.94119199 8.56752735,6.02942341 L12.3269231,0 L6.85870261,0 L4.94737429,5.16177441 C4.66888898,5.89706465 4.30182589,6.57352876 3.84615385,7.17649646 C3.97273808,7.48533357 4.03603019,7.80884099 4.04868861,8.16179769 L4.03599901,8.176468 Z"
              id="Shape"
              fill="#F0D6BF"
            />
            <path
              d="M6.76596204,8.78311613 L5.36057941,8.79881951 C5.04556648,8.79881951 4.77904692,8.45334509 4.76693103,8.04509581 L4.76693103,8.01368904 C4.76693103,7.35418563 4.60942457,6.71034693 4.33078913,6.17647059 C4.25809383,6.30209765 4.18539854,6.4434281 4.11270325,6.56905516 C4.10058737,6.58475855 4.08847149,6.60046193 4.0763556,6.61616531 C4.00366031,6.74179238 3.91884914,6.86741944 3.84615385,6.97734312 C3.96731267,7.30711416 4.02789208,7.65254991 4.04000796,8.0294311 L4.04000796,8.06083787 C4.05212384,9.00300217 4.63365633,9.75672588 5.36057941,9.74102249 L6.74173027,9.72531911 C6.99616379,9.72531911 7.23845159,9.77242926 7.46865335,9.88235294 L7.46865335,9.01870555 C7.46865335,8.98729879 7.48076923,8.94018864 7.48076923,8.90878187 C7.25059732,8.8301876 7.00827968,8.78311613 6.76596204,8.78311613 Z"
              id="Shape"
              fill="#101215"
              opacity="0.2"
            />
            <path
              d="M1.27020178,11.1176471 L0.211538462,12.3190114 L8.69342328,16.8135789 C9.1791604,17.1386728 9.72720611,17.2941176 10.2876767,17.2941176 L12.5545011,17.2799831 C13.0526937,17.2375796 13.5384615,16.8842515 13.5384615,15.8666011"
              id="Shape"
              fill="#F7F7FB"
            />
            <polyline
              id="Shape"
              fill="#ADA2B5"
              points="7.48076923 9.44169122 8.54424127 8.64705882 9.90384615 11.1176471"
            />
            <path
              d="M8.30721779,11.1176471 C8.18227156,11.1176471 8.05732533,11.0370898 7.98235759,10.8759752 L7.5200873,9.82877001 L6.85790304,10.4248543 C6.68297832,10.5698574 6.45810587,10.5215231 6.33315964,10.2959626 C6.22070803,10.0704022 6.2581919,9.78043563 6.43311663,9.61932104 L7.43265571,8.73323047 C7.52011808,8.65267317 7.62007506,8.63656171 7.73252667,8.65267317 C7.83248366,8.68489609 7.91994602,8.76545339 7.96992451,8.8782336 L8.6446034,10.3765199 C8.74456038,10.6020804 8.68208727,10.9081584 8.50716254,11.0370501 C8.44465865,11.1015356 8.38218553,11.1176471 8.30721779,11.1176471 Z"
              id="Shape"
              fill="#454B54"
            />
            <path
              d="M3.79639299,6.17647059 L1.77201879,8.75264396 C1.38065373,9.24533994 1.31317127,9.92108483 1.59656438,10.4841709 L10.7197947,15.9743554 L13.4729462,16.0588235 C14.1207447,16.0588235 14.8360255,15.8194972 14.7415501,15.157865 L14.7415501,15.143787 C14.7145571,14.9607728 14.6335781,14.7777585 14.4986132,14.651091 L11.9208828,13.8627567 L11.6644494,13.6093523 L9.34315239,10.1885325 C8.87080838,9.48466625 8.08804503,9.0623257 7.25129572,9.0623257 L5.71276201,9.07640372 C4.90300568,9.07640372 4.25520727,8.4006935 4.24171077,7.57012508 L4.24171077,7.54196904 C4.24174402,7.03519505 4.07981934,6.55657709 3.79639299,6.17647059 Z"
              id="Shape"
              fill="#68AF1A"
            />
            <path
              d="M10.9688796,16.1911393 C10.319064,16.1911393 9.69524238,16.0401227 9.12340854,15.7497346 L0.285865854,12.0674233 C0.168893293,12.0209566 0.0909115854,11.9280233 0.0649176829,11.8118567 C0.0389237805,11.69569 0.0779146341,11.59114 0.168893293,11.5098233 L1.27357012,10.522464 C1.42953354,10.383064 1.6764436,10.383064 1.81941006,10.522464 C1.97537348,10.661864 1.97537348,10.882552 1.81941006,11.0103353 L1.11760671,11.6376067 L9.46126524,15.1224633 C9.4742622,15.1224633 9.48725915,15.13408 9.48725915,15.13408 C9.92912348,15.3664133 10.4360046,15.4825513 10.9558506,15.4825513 L13.2952058,15.4709347 C13.7370701,15.4360847 13.9450213,15.1689014 13.9450213,14.6577967 C13.9450213,14.4603134 14.1139817,14.3093253 14.3348979,14.3093253 C14.555846,14.3093253 14.7247744,14.460342 14.7247744,14.6577967 C14.7247744,15.9704227 13.6590884,16.1446727 13.3341646,16.167906 C13.3211677,16.167906 13.3211677,16.167906 13.3081707,16.167906 L10.9688796,16.1911393 Z"
              id="Shape"
              fill="#417505"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const RunningIcon = withTranslation(RunningIconComponent);
