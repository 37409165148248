import { createAction, createPayloadAction } from 'src/app/store/app.actions';
import {
  EditPatientParams,
  TransformedEditPatientErrorResponse,
  TransformedEditPatientSuccessResponse,
  EditEMRPatientParams,
  TransformedEditEMRPatientErrorResponse,
  TransformedEditEMRPatientSuccessResponse,
} from 'src/services';

import {
  EditPatientActionType,
  EditPatientErrorAction,
  EditPatientStartAction,
  EditPatientState,
  EditPatientSuccessAction,
  EditEMRPatientStartAction,
  EditEMRPatientSuccessAction,
  EditEMRPatientErrorAction,
  EditPatientSuccessConfirmedAction,
  fetchPatientForEditErrorAction,
  fetchPatientForEditErrorActionPayload,
  fetchPatientForEditStartAction,
  fetchPatientForEditStartActionPayload,
  fetchPatientForEditSuccessAction,
  fetchPatientForEditSuccessActionPayload,
  NoAssociatedProfessionalsAction,
  UpdateEditPatientAction,
} from './edit-patient.types';

export const fetchPatientForEditStart = (
  payload: fetchPatientForEditStartActionPayload,
): fetchPatientForEditStartAction =>
  createPayloadAction(
    EditPatientActionType.FETCH_PATIENT_FOR_EDIT_START,
    payload,
  );

export const fetchPatientForEditSuccess = (
  payload: fetchPatientForEditSuccessActionPayload,
): fetchPatientForEditSuccessAction =>
  createPayloadAction(
    EditPatientActionType.FETCH_PATIENT_FOR_EDIT_SUCCESS,
    payload,
  );

export const fetchPatientForEditError = (
  payload: fetchPatientForEditErrorActionPayload,
): fetchPatientForEditErrorAction =>
  createPayloadAction(
    EditPatientActionType.FETCH_PATIENT_FOR_EDIT_ERROR,
    payload,
  );

export const editPatientStart = (
  payload: EditPatientParams,
): EditPatientStartAction =>
  createPayloadAction(EditPatientActionType.EDIT_PATIENT_START, payload);

export const editPatientSuccess = (
  payload: TransformedEditPatientSuccessResponse,
): EditPatientSuccessAction =>
  createPayloadAction(EditPatientActionType.EDIT_PATIENT_SUCCESS, payload);

export const editPatientError = (
  payload: TransformedEditPatientErrorResponse,
): EditPatientErrorAction =>
  createPayloadAction(EditPatientActionType.EDIT_PATIENT_ERROR, payload);

export const editEMRPatientStart = (
  payload: EditEMRPatientParams,
): EditEMRPatientStartAction =>
  createPayloadAction(EditPatientActionType.EDIT_EMR_PATIENT_START, payload);

export const editEMRPatientSuccess = (
  payload: TransformedEditEMRPatientSuccessResponse,
): EditEMRPatientSuccessAction =>
  createPayloadAction(EditPatientActionType.EDIT_EMR_PATIENT_SUCCESS, payload);

export const editEMRPatientError = (
  payload: TransformedEditEMRPatientErrorResponse,
): EditEMRPatientErrorAction =>
  createPayloadAction(EditPatientActionType.EDIT_EMR_PATIENT_ERROR, payload);

export const editPatientStatusModalConfirmed =
  (): EditPatientSuccessConfirmedAction =>
    createAction(EditPatientActionType.EDIT_PATIENT_STATUS_MODAL_CONFIRMED);

export const updateEditPatient = (
  payload: EditPatientState,
): UpdateEditPatientAction =>
  createPayloadAction(EditPatientActionType.UPDATE_EDIT_PATIENT, payload);

export const NoAssociatedProfessionals = (): NoAssociatedProfessionalsAction =>
  createAction(EditPatientActionType.NO_ASSOCIATED_PROFESSIONALS);
