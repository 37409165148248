import styled, { StyledFunction } from 'styled-components';

import { colors, fontSize } from 'src/core';
import { weight } from 'src/components/fonts/weights';

const colorActive = colors.blue;
const newBreakPointMedium = '1200px';

type TabsContentProps = {};

const TabsContentDiv: StyledFunction<TabsContentProps> = styled.li;

export const TabsContent = TabsContentDiv`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export type TabContentProps = {
  className?: string;
  onClick(): any;
};

const TabContentDiv: StyledFunction<TabContentProps> = styled.ul;

export const TabContent = TabContentDiv`
  font-size: ${fontSize.subheading};
  font-weight: ${weight.semiBold};
  color: ${colors.charcoal};
  padding: 0 0.87rem 0 0.87rem;
  border-right: 1px solid ${colors.grayLighter};
  cursor: pointer;
  &.active {
    color: ${colorActive}
  }
  &:first-child {
    padding: 0 0.87rem 0 0;
  } 

  @media (max-width: ${newBreakPointMedium}) {
    font-size: ${fontSize.caption};
  }

`;
