import {
  path,
  filter,
  last,
  map,
  pipe,
  sort,
  values,
  head,
  isEmpty,
  flatten,
} from 'ramda';

import {
  convertISOGMT,
  toFormat,
  convertJSDate,
  isAfter,
  convertJSDateGMT,
  convertMillisGMT,
} from 'src/utils';

import {
  LOGBOOK_UNITS_UNIT,
  LOGBOOK_STATUS_COLOR,
} from '../../constants/logbook.constants';

export const mealTimeIsBeforeMeal = (mealTime) =>
  mealTime === 'BEDTIME' || mealTime.substring(0, 6) === 'BEFORE';

export const relatedMealTimesMap = {
  BEDTIME: 'NIGHT',
  NIGHT: 'BEDTIME',
  BEFORE_BREAKFAST: 'AFTER_BREAKFAST',
  AFTER_BREAKFAST: 'BEFORE_BREAKFAST',
  BEFORE_LUNCH: 'AFTER_LUNCH',
  AFTER_LUNCH: 'BEFORE_LUNCH',
  BEFORE_DINNER: 'AFTER_DINNER',
  AFTER_DINNER: 'BEFORE_DINNER',
};

export const getUnitsFromBeforeAndAfterMealTimeMeasurements = (measurement) => {
  const beforeGlucose = path(['before', 'glucose'], measurement);
  const beforeManuallyEntered = path(
    ['before', 'manuallyEntered'],
    measurement,
  );
  const beforeCarbohydrates = path(['before', 'carbohydrates'], measurement);
  const beforeBolus = path(['before', 'insulin', 'value'], measurement);
  const afterGlucose = path(['after', 'glucose'], measurement);
  const afterManuallyEntered = path(['after', 'manuallyEntered'], measurement);
  const afterBolus = path(['after', 'insulin', 'value'], measurement);

  return [
    {
      type: 'glucose',
      value: beforeGlucose,
      manuallyEntered: beforeManuallyEntered,
    },
    { type: 'carbohydrates', value: beforeCarbohydrates },
    { type: 'bolus', value: beforeBolus },
    {
      type: 'glucose',
      value: afterGlucose,
      manuallyEntered: afterManuallyEntered,
    },
    { type: 'bolus', value: afterBolus },
  ];
};

const mealTimeSortMap = {
  NIGHT: 0,
  BEFORE_BREAKFAST: 1,
  AFTER_BREAKFAST: 2,
  BEFORE_LUNCH: 3,
  AFTER_LUNCH: 4,
  BEFORE_DINNER: 5,
  AFTER_DINNER: 6,
  BEDTIME: 7,
};

export const getLogbookMealTimeNames = (allMealTimeNames) => [
  'NIGHT',
  ...filter(
    mealTimeIsBeforeMeal,
    sort((a, b) => mealTimeSortMap[a] - mealTimeSortMap[b], allMealTimeNames),
  ),
];

export const getNumberOfRows = pipe(
  values,
  map((value) => value.length),
  sort((a, b) => a - b),
  last,
);

export const getMaxMealTimeRows = (mealTimes) => {
  if (!Object.keys(mealTimes).length) {
    return 0;
  }
  const combinedMealTimes = {
    NIGHT: mealTimes.NIGHT,
    BREAKFAST: [...mealTimes.BEFORE_BREAKFAST, ...mealTimes.AFTER_BREAKFAST],
    LUNCH: [...mealTimes.BEFORE_LUNCH, ...mealTimes.AFTER_LUNCH],
    DINNER: [...mealTimes.BEFORE_DINNER, ...mealTimes.AFTER_DINNER],
    BEDTIME: mealTimes.BEDTIME,
  };
  return getNumberOfRows(combinedMealTimes);
};

export const createMealTimeMatrix = (
  beforeMealTimeData: Array<LogbookMeasurement>,
  afterMealTimeData: Array<LogbookMeasurement>,
) => {
  const beforeDataWithChronologicalBeforeAfterFlag = beforeMealTimeData.map(
    (measurement) => ({
      ...measurement,
      chronologicallyBeforeMealTime: true,
    }),
  );
  const afterDataWithChronologicalBeforeAfterFlag = afterMealTimeData.map(
    (measurement) => ({
      ...measurement,
      chronologicallyBeforeMealTime: false,
    }),
  );

  const sortedMeasurements = sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    [
      ...beforeDataWithChronologicalBeforeAfterFlag,
      ...afterDataWithChronologicalBeforeAfterFlag,
    ],
  );

  const mealTimeMatrix = sortedMeasurements.reduce((acc, measurement) => {
    const { beforeMeal, afterMeal, chronologicallyBeforeMealTime } =
      measurement;
    if (beforeMeal || (!afterMeal && chronologicallyBeforeMealTime)) {
      return [
        ...acc,
        {
          before: measurement,
          after: {},
        },
      ];
    } else if (!acc.length || acc[acc.length - 1].after !== measurement) {
      return [
        ...acc,
        {
          before: {},
          after: measurement,
        },
      ];
    }
    return acc;
  }, []);

  return mealTimeMatrix;
};

export const getSelectedRowId = (match, logbookData) => {
  const urlDate = parseInt(match.params.selectedDate, 10);

  const index = logbookData.findIndex((datum) => {
    const { date } = datum;
    return convertISOGMT(date).day === convertMillisGMT(urlDate).day;
  });

  return index;
};

export const getCombinedMealTimeName = (mealTime) => {
  const mealTimeHeaderMap = {
    NIGHT: 'general.mealBlocks.night',
    BEFORE_BREAKFAST: 'general.mealBlocks.breakfast',
    BEFORE_LUNCH: 'general.mealBlocks.lunch',
    BEFORE_DINNER: 'general.mealBlocks.dinner',
    BEDTIME: 'general.mealBlocks.bedTime',
  };

  return mealTimeHeaderMap[mealTime] || 'none';
};

export const getCombinedMealTimeDate = (mealTimeData) => {
  const first = head(mealTimeData.measurements);
  return mealTimeData.hasBeforeAndAfterIntervals
    ? !isEmpty(first.before)
      ? first.before.date
      : first.after.date
    : first.date;
};

export const toMealTimeModalDate = (date) =>
  toFormat('dd LLL yyyy')(convertJSDateGMT(date));

export const flattenCombinedMeasurements = (combinedMeasurements) =>
  flatten(combinedMeasurements.map((m) => [m.before, m.after]));

export const toMeasurementIndicatorColor = (measurement) => {
  const { aboveTargetRange, belowTargetRange, hypoSymptoms } = measurement;
  if (aboveTargetRange) return LOGBOOK_STATUS_COLOR.BLUE;
  if (belowTargetRange || hypoSymptoms) return LOGBOOK_STATUS_COLOR.RED;
  return LOGBOOK_STATUS_COLOR.GREEN;
};

export const toMealTimeModalMeasurements = (measurement, bloodGlucoseUnit) => ({
  glucose: {
    manuallyEntered: measurement.manuallyEntered,
    value: measurement.glucose,
    unit: LOGBOOK_UNITS_UNIT[bloodGlucoseUnit],
  },
  bolus: {
    value: measurement.insulin && measurement.insulin.value,
    unit: LOGBOOK_UNITS_UNIT.BOLUS,
  },
  carbohydrates: {
    value: measurement.carbohydrates,
    unit: LOGBOOK_UNITS_UNIT.CARBOHYDRATES,
  },
  time: toFormat('HH:mm')(convertJSDateGMT(measurement.date)),
  afterMeal: measurement.afterMeal,
  beforeMeal: measurement.beforeMeal,
  statusColor: toMeasurementIndicatorColor(measurement),
});

export const getMealTimeModalMeasurements = (
  mealTimeData,
  bloodGlucoseUnit,
) => {
  const measurements = mealTimeData.hasBeforeAndAfterIntervals
    ? flattenCombinedMeasurements(mealTimeData.measurements)
    : mealTimeData.measurements;

  const AscByTimeComparer = (a, b) =>
    isAfter(convertJSDate(a.time), convertJSDate(b.time));

  return measurements
    .filter((m) => !isEmpty(m))
    .map((m) => toMealTimeModalMeasurements(m, bloodGlucoseUnit))
    .sort(AscByTimeComparer);
};

export const convertDateStringToMs = (
  timeString = '00:00:00',
  delimiter = ':',
) => {
  const [hour, minute, second] = timeString.split(delimiter);
  return hour * 60 * 60 * 1000 + minute * 60 * 1000 + second * 1000;
};

export const isCrossoverBlock = (startTimeMs, endTimeMs) =>
  endTimeMs < startTimeMs;

export const convertCurrentDateInMs = (date) =>
  date.getUTCHours() * 60 * 60 * 1000 +
  date.getUTCMinutes() * 60 * 1000 +
  date.getUTCSeconds() * 1000 +
  date.getUTCMilliseconds();
