const RANGE_VALUES = {
  actualHyper: '',
  hyper: '',
  inTarget: '',
  warning: '',
  hypo: '',
};

export const TARGET_RANGES_STATE = {
  isDirty: false,
  unit: '',
  nonMealRelatedValid: true,
  beforeEatingValid: true,
  afterEatingValid: true,
  beforeBedValid: true,
  successTargetRanges: false,
  nonMealRelated: RANGE_VALUES,
  beforeEating: RANGE_VALUES,
  afterEating: RANGE_VALUES,
  beforeBed: RANGE_VALUES,
};

export const TARGET_RANGES_COUNTLY_KEYS = {
  EVENT_TITLE_GLUCOSE: 'Glucose Target Ranges Edited',
};

export const DEFAULT_TARGET_RANGES_PATH =
  '/professional/default-graphic-settings';
