import * as React from 'react';
import { XIcon } from 'src/assets/icons';
import { Block, Button, LocalizedText } from 'src/components';
import { ButtonReset, colors } from 'src/core/styles';
import { translate } from 'src/i18n';
import { WhiteHeader, ModalBody } from 'src/widgets/modal';
import { DatePickerVisible } from 'src/components/date-picker/date-picker-visible';
import { validShipDate } from 'src/components/date-picker/date-picker.util';

export const CalendarModal = ({
  destroyModal,
  data: { date, locale, setNewDate, onConfirmation },
}) => (
  <ModalBody>
    <WhiteHeader>
      <LocalizedText
        fontSize={4}
        textKey="nextShipment.changeDate.calendar.title"
      />
      <ButtonReset
        onClick={() => {
          destroyModal();
        }}
      >
        <XIcon height={14} fillColor={colors.black} />
      </ButtonReset>
    </WhiteHeader>
    <Block display="flex" justifyContent="center">
      <DatePickerVisible
        date={date}
        numberOfMonths={1}
        onDateChange={(newDate) => setNewDate(newDate.format('DD/MM/YYYY'))}
        checkIfDateOutOfRange={(d) => validShipDate(d, date)}
        locale={locale}
      />
    </Block>
    <Block p={4} display="flex" justifyContent="center">
      <Button
        buttonStyle="secondary"
        mr={4}
        label={translate('nextShipment.changeDate.calendar.cancel')}
        uppercase
        onClick={() => {
          destroyModal();
        }}
      />
      <Button
        autoFocus
        label={translate('nextShipment.changeDate.calendar.select')}
        uppercase
        onClick={() => {
          onConfirmation();
        }}
      />
    </Block>
  </ModalBody>
);
