import React from 'react';

import { withTranslation } from 'src/utils/i18n/with-translation';

import { SvgIcon } from 'src/components/icon';

const HypoWarningIconComponent = ({ height, width, t }) => {
  const originalWidth = 18;
  const originalHeight = 17;

  const aspectRatio = originalWidth / originalHeight;
  const calculatedWidth = width ? width : height * aspectRatio;
  const calculatedHeight = height ? height : width / aspectRatio;

  return (
    <SvgIcon
      title={t('graphs.iconTitles.hypoWarning')}
      width={calculatedWidth}
      height={calculatedHeight}
      originalWidth={originalWidth}
      originalHeight={originalHeight}
    >
      <g
        id="_icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="NEW-ICONS" transform="translate(-541.000000, -527.000000)">
          <g id="Group-3" transform="translate(541.000000, 527.000000)">
            <path
              d="M9.03670957,-1.15037594e-05 C8.47531455,-1.15037594e-05 7.96608793,0.290959985 7.67982181,0.798061805 C5.270873,5.08863505 2.84993838,9.37904265 0.433903224,13.6678742 C0.14756882,14.1764554 0.146887758,14.7918835 0.433903224,15.2999816 C0.771542082,15.8975559 1.31053482,16.1067172 1.87344404,16.1052448 L16.1999749,16.1052448 C16.7631464,16.1075455 17.3019099,15.8974869 17.6395157,15.2999816 C17.9265995,14.7918835 17.9259163,14.1764554 17.6395157,13.6678742 C15.2457684,9.36560626 12.7731018,5.10637385 10.3935973,0.798061805 C10.1073312,0.290959985 9.59810459,-1.15037594e-05 9.03670957,-1.15037594e-05 Z"
              id="Shape"
              fill="#D13231"
              fillRule="nonzero"
            />
            <path
              d="M10.8119029,7.55475713 C10.9795015,7.55475713 11.1135783,7.60724117 11.2141375,7.71221082 C11.3146967,7.81718047 11.3649755,7.9571379 11.3649755,8.13208732 L11.3649755,12.3383502 C11.3649755,12.5132996 11.3146967,12.6545067 11.2141375,12.7619756 C11.1135783,12.8694445 10.9795015,12.9231782 10.8119029,12.9231782 C10.6443043,12.9231782 10.5114245,12.8706941 10.4132596,12.7657245 C10.3150947,12.6607548 10.266013,12.5182982 10.266013,12.3383502 L10.266013,10.6588442 L7.93161555,10.6588442 L7.93161555,12.3383502 C7.93161555,12.5132996 7.88133672,12.6545067 7.78077756,12.7619756 C7.6802184,12.8694445 7.54853576,12.9231782 7.38572569,12.9231782 C7.21812708,12.9231782 7.08405021,12.8706941 6.98349105,12.7657245 C6.88293189,12.6607548 6.83265306,12.5182982 6.83265306,12.3383502 L6.83265306,8.13208732 C6.83265306,7.9571379 6.88293189,7.81718047 6.98349105,7.71221082 C7.08405021,7.60724117 7.21812708,7.55475713 7.38572569,7.55475713 C7.55332429,7.55475713 7.68620405,7.60724117 7.78436894,7.71221082 C7.88253384,7.81718047 7.93161555,7.9571379 7.93161555,8.13208732 L7.93161555,9.72911761 L10.266013,9.72911761 L10.266013,8.13208732 C10.266013,7.9571379 10.3150947,7.81718047 10.4132596,7.71221082 C10.5114245,7.60724117 10.6443043,7.55475713 10.8119029,7.55475713 Z"
              id="H"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const HypoWarningIcon = withTranslation(HypoWarningIconComponent);
