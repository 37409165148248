/* tslint:disable */

import * as React from 'react';
import { isEmpty } from 'ramda';

import { TablePrimary } from 'src/domains/diagnostics/components';
import { RenderIf } from 'src/utils';

import {
  GenerateTable,
  transformAppointmentData,
  transformBGReminderData2,
} from './tab-reminders.utils';
import {
  RemainderContainerLeft,
  RemainderContainerRight,
  RemainderItem,
  ReminderTitle,
  RemainderRow,
} from './tab-reminders.style';

import {
  getRowStyledProps,
  getCellStyledProps,
} from '../../device-settings.utils';
import { FlexContainer } from '../device-settings-header/flex-grid';
import { Disclaimer } from '../disclaimer/disclaimer.component';

export interface Props {
  pumpReminder: any;
  alarmClockReminder: any;
  appointmentReminder: any;
  bgTestReminder: any;
  meterSetting: any;
}
export const TabReminderComponent: React.FunctionComponent<any> = ({
  isPreviousSettingsSelected,
  comparePreviousSettings,
  pumpReminder,
  previousPumpReminder,
  alarmClockReminder,
  previousAlarmClockReminder,
  appointmentReminder,
  previousAppointmentReminder,
  bgReminder,
  previousBgReminder,
  meterSetting,
  t,
  is12HourFormat,
}) => {
  const showPreviousValue = isPreviousSettingsSelected;
  const appointmentData: any = transformAppointmentData(appointmentReminder);
  const previousAppointmentData: any = transformAppointmentData(
    previousAppointmentReminder,
  );
  const bgReminderData: any = transformBGReminderData2(bgReminder, t);
  const previousBgReminderData: any = transformBGReminderData2(
    previousBgReminder,
    t,
  );

  const tableGenerator = new GenerateTable({
    units: meterSetting,
    showPreviousValue: showPreviousValue,
    comparePreviousValue: comparePreviousSettings,
    t: t,
    is12HourFormat,
  });
  const keyToCompare = {
    infusionsetchange: 'Name',
    missedbolus: 'Name',
    deliverbolus: 'Name',
    infusion: 'Name',
    bgtest: 'Name',
    injection: 'Name',
    medication: 'Name',
    other: 'Name',
    appointment: 'Type',
    bgReminder: 'Name',
  };

  const infusionPumpReminderTable: any = tableGenerator.getTable({
    data: pumpReminder.infusionsetchange,
    headerTitle: 'deviceSettings.tabReminder.infusionTableTitle',
    previousData: previousPumpReminder.infusionsetchange,
    keyPreviousData: keyToCompare.infusionsetchange,
  });
  const missedPumpReminderTable: any = tableGenerator.getTable({
    data: pumpReminder.missedbolus,
    headerTitle: 'deviceSettings.tabReminder.missedBolusTableTitle',
    previousData: previousPumpReminder.missedbolus,
    keyPreviousData: keyToCompare.missedbolus,
  });
  const deliveryPumpReminderTable: any = tableGenerator.getTable({
    data: pumpReminder.deliverbolus,
    headerTitle: 'deviceSettings.tabReminder.bolusDeliveryTableTitle',
    previousData: previousPumpReminder.deliverbolus,
    keyPreviousData: keyToCompare.deliverbolus,
  });
  const alarmClockReminderTable: any = tableGenerator.getTable({
    data: alarmClockReminder.other,
    headerTitle: 'deviceSettings.tabReminder.alarmClockTableTitle',
    previousData: previousAlarmClockReminder.other,
    keyPreviousData: keyToCompare.other,
  });
  const bgTestReminderTable: any = tableGenerator.getTable({
    data: alarmClockReminder.bgtest,
    headerTitle: 'deviceSettings.tabReminder.bgTestTableTitle',
    previousData: previousAlarmClockReminder.bgtest,
    keyPreviousData: keyToCompare.bgtest,
  });
  const medicalReminderTable: any = tableGenerator.getTable({
    data: alarmClockReminder.medication,
    headerTitle: 'deviceSettings.tabReminder.medicationTableTitle',
    previousData: previousAlarmClockReminder.medication,
    keyPreviousData: keyToCompare.medication,
  });
  const injectionReminderTable: any = tableGenerator.getTable({
    data: alarmClockReminder.injection,
    headerTitle: 'deviceSettings.tabReminder.basalInjectionTableTitle',
    previousData: previousAlarmClockReminder.injection,
    keyPreviousData: keyToCompare.injection,
  });
  const appointmentReminderTable: any = tableGenerator.getTable({
    data: appointmentData,
    headerTitle: 'deviceSettings.tabReminder.dateTableTitle',
    previousData: previousAppointmentData,
    keyPreviousData: keyToCompare.appointment,
  });
  const bgReminderTable: any = tableGenerator.getTable({
    data: bgReminderData.body,
    headerTitle: 'deviceSettings.tabReminder.bgTableTitle',
    previousData: previousBgReminderData.body,
    keyPreviousData: keyToCompare.bgReminder,
  });
  const bgReminderHeader: any = tableGenerator.getHeaderTable([
    bgReminderData.header,
  ]);

  return (
    <FlexContainer direction="column">
      <RemainderRow direction="row">
        <RemainderContainerLeft width="50%" direction="column">
          <FlexContainer direction="column" width="100%">
            <TablePrimary
              bodyData={appointmentReminderTable.body}
              headerData={appointmentReminderTable.header}
              rowStyles={getRowStyledProps()}
              cellStyles={getCellStyledProps()}
            />
            <RenderIf validate={isEmpty(appointmentReminderTable.body)}>
              <Disclaimer />
            </RenderIf>
          </FlexContainer>
        </RemainderContainerLeft>
        <RemainderContainerRight width="50%" direction="column">
          <FlexContainer direction="column" width="100%">
            <TablePrimary
              bodyData={bgReminderTable.body}
              headerData={bgReminderHeader}
              rowStyles={getRowStyledProps()}
              cellStyles={getCellStyledProps()}
            />
            <RenderIf validate={isEmpty(bgReminderTable.body)}>
              <Disclaimer />
            </RenderIf>
          </FlexContainer>
        </RemainderContainerRight>
      </RemainderRow>
      <RemainderRow direction="row">
        <RemainderContainerLeft width="50%" direction="column">
          <ReminderTitle grid="12" paddings={false}>
            {t('deviceSettings.tabReminder.pumpTitle')}
          </ReminderTitle>
          <RemainderItem grid="12" paddings={false}>
            <FlexContainer direction="column" width="100%">
              <TablePrimary
                bodyData={infusionPumpReminderTable.body}
                headerData={infusionPumpReminderTable.header}
                rowStyles={getRowStyledProps()}
                cellStyles={getCellStyledProps()}
              />
              <RenderIf validate={isEmpty(infusionPumpReminderTable.body)}>
                <Disclaimer />
              </RenderIf>
            </FlexContainer>
          </RemainderItem>
          <RemainderItem grid="12" paddings={false}>
            <FlexContainer direction="column" width="100%">
              <TablePrimary
                bodyData={deliveryPumpReminderTable.body}
                headerData={deliveryPumpReminderTable.header}
                rowStyles={getRowStyledProps()}
                cellStyles={getCellStyledProps()}
              />
              <RenderIf validate={isEmpty(deliveryPumpReminderTable.body)}>
                <Disclaimer />
              </RenderIf>
            </FlexContainer>
          </RemainderItem>
          <RemainderItem grid="12" paddings={false}>
            <FlexContainer direction="column" width="100%">
              <TablePrimary
                bodyData={missedPumpReminderTable.body}
                headerData={missedPumpReminderTable.header}
                rowStyles={getRowStyledProps()}
                cellStyles={getCellStyledProps()}
              />
              <RenderIf validate={isEmpty(missedPumpReminderTable.body)}>
                <Disclaimer />
              </RenderIf>
            </FlexContainer>
          </RemainderItem>
        </RemainderContainerLeft>
        <RemainderContainerRight width="50%" direction="column">
          <ReminderTitle grid="12" paddings={false}>
            {t('deviceSettings.tabReminder.timeTitle')}
          </ReminderTitle>
          <RemainderItem grid="12" paddings={false}>
            <FlexContainer direction="column" width="100%">
              <TablePrimary
                bodyData={bgTestReminderTable.body}
                headerData={bgTestReminderTable.header}
                rowStyles={getRowStyledProps()}
                cellStyles={getCellStyledProps()}
              />
              <RenderIf validate={isEmpty(bgTestReminderTable.body)}>
                <Disclaimer />
              </RenderIf>
            </FlexContainer>
          </RemainderItem>
          <RemainderItem grid="12" paddings={false}>
            <FlexContainer direction="column" width="100%">
              <TablePrimary
                bodyData={medicalReminderTable.body}
                headerData={medicalReminderTable.header}
                rowStyles={getRowStyledProps()}
                cellStyles={getCellStyledProps()}
              />
              <RenderIf validate={isEmpty(medicalReminderTable.body)}>
                <Disclaimer />
              </RenderIf>
            </FlexContainer>
          </RemainderItem>
          <RemainderItem grid="12" paddings={false}>
            <FlexContainer direction="column" width="100%">
              <TablePrimary
                bodyData={injectionReminderTable.body}
                headerData={injectionReminderTable.header}
                rowStyles={getRowStyledProps()}
                cellStyles={getCellStyledProps()}
              />
              <RenderIf validate={isEmpty(injectionReminderTable.body)}>
                <Disclaimer />
              </RenderIf>
            </FlexContainer>
          </RemainderItem>
          <RemainderItem grid="12" paddings={false}>
            <FlexContainer direction="column" width="100%">
              <TablePrimary
                bodyData={alarmClockReminderTable.body}
                headerData={alarmClockReminderTable.header}
                rowStyles={getRowStyledProps()}
                cellStyles={getCellStyledProps()}
              />
              <RenderIf validate={isEmpty(alarmClockReminderTable.body)}>
                <Disclaimer />
              </RenderIf>
            </FlexContainer>
          </RemainderItem>
        </RemainderContainerRight>
      </RemainderRow>
    </FlexContainer>
  );
};

export default TabReminderComponent;
