import styled from 'styled-components';
import { Div } from 'src/components/div';
import { combineRems } from 'src/utils';

export const HeaderContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  flex: 1;
  border-bottom: 1px solid ${(props) => props.theme.colors.silverMedium};
`;
HeaderContainer.displayName = 'HeaderContainer';

export const HeaderTitle = styled.h1`
  color: ${(props) => props.theme.colors.charcoal};
  font-size: ${(props) => props.theme.fontSize.headline};
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  margin: 0 0
    ${(props) =>
      combineRems(props.theme.spacing.one, props.theme.spacing.three)};
`;
HeaderTitle.displayName = 'HeaderTitle';

export const ButtonsContainer = styled(Div)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.five};

  & > button {
    margin-left: ${({ theme }) => theme.spacing.four};
  }
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const Loader = styled(Div)`
  z-index: 15;
`;
Loader.displayName = 'Loader';
