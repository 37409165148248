import {
  all,
  pathOr,
  prop,
  propOr,
  pipe,
  toUpper,
  ifElse,
  isNil,
  identity,
  toLower,
  includes,
} from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  FALLBACK_LANGUAGE,
  FALLBACK_LOCALE,
} from 'src/utils/i18n/i18.constants';
import { CIAM_SDK_NAMESPACE } from 'src/app/session';

import { hasValue } from 'src/utils';

import * as userContants from './user.constants';

export const buildLanguageCode = (state) =>
  toLower(
    `${pathOr(FALLBACK_LANGUAGE, ['languageIsoCode'], state)}-${pathOr(
      FALLBACK_LOCALE,
      ['languageCountryIsoCode'],
      state,
    )}`,
  );

export const buildLanguageCodeUpper = (state) =>
  `${pathOr(FALLBACK_LANGUAGE, ['languageIsoCode'], state)}-${pathOr(
    FALLBACK_LOCALE,
    ['languageCountryIsoCode'],
    state,
  )}`;

export const selectSessionState = pathOr({}, [CIAM_SDK_NAMESPACE, 'session']);

export const selectAuthConfigState = pathOr({}, [CIAM_SDK_NAMESPACE, 'config']);

export const selectEC6UserState = createSelector(
  [selectAuthConfigState],
  (sessionState) => propOr({}, 'user', sessionState),
);

export const selectEC6DepartmentId = createSelector(
  [selectEC6UserState],
  (user) => prop('departmentId', user),
);

export const selectEC6UserId = createSelector([selectEC6UserState], (user) =>
  prop('id', user),
);

export const selectUserProfile = createSelector([selectEC6UserState], (user) =>
  propOr(null, 'profile', user),
);

export const selectIsGPUserProfile = createSelector(
  [selectUserProfile],
  (profile) => profile === userContants.GENERAL_PRACTITIONER,
);

export const selectIsHCPUserProfile = createSelector(
  [selectUserProfile],
  (profile) => profile === userContants.HCP_MASTER_PROFILE,
);

export const selectIsHCPOrGPOrAdminUserProfile = createSelector(
  [selectUserProfile],
  (profile) =>
    profile === userContants.GENERAL_PRACTITIONER ||
    profile === userContants.HCP_MASTER_PROFILE ||
    profile === userContants.ADMINISTRATIVE,
);

export const selectEC6UserCountryId = createSelector(
  [selectEC6UserState],
  (user) => prop('countryId', user),
);

export const selectEC6UserLanguage = createSelector(
  [selectEC6UserState],
  buildLanguageCode,
);

export const selectIfusLang = createSelector(
  [selectEC6UserState],
  buildLanguageCodeUpper,
);

export const selectEC6UserLanguageId = createSelector(
  [selectEC6UserState],
  (user) => prop('languageId', user),
);

export const selectCenterLanguageId = createSelector(
  [selectEC6UserState],
  (user) => prop('centerLanguageId', user),
);

export const selectEC6UserUnitMeasurement = createSelector(
  [selectEC6UserState],
  (user) => propOr(null, 'unitMeasurement', user),
);

export const selectGigyaUserState = createSelector(
  [selectSessionState],
  (sessionState) => propOr({}, 'gigyaUser', sessionState),
);

export const selectGigyaProfile = createSelector(
  [selectGigyaUserState],
  (userState) => propOr({}, 'profile', userState),
);

export const selectGigyaData = createSelector([selectGigyaUserState], (user) =>
  propOr({}, 'data', user),
);

export const selectUID = createSelector([selectGigyaUserState], (user) =>
  propOr(null, 'UID', user),
);

export const selectHCPIsActive = createSelector([selectGigyaData], (data) =>
  propOr(false, 'HCPIsActive', data),
);

export const selectHCPId = createSelector([selectGigyaData], (data) =>
  propOr('', 'nationalHealthNumber', data),
);

export const selectHomeCountry = createSelector([selectEC6UserState], (user) =>
  pipe(propOr(null, 'countryIsoCode'), ifElse(isNil, identity, toUpper))(user),
);

export const selectEmail = createSelector([selectGigyaProfile], (profile) =>
  prop('email', profile),
);

export const selectFirstName = createSelector([selectGigyaProfile], (profile) =>
  prop('firstName', profile),
);

export const selectLastName = createSelector([selectGigyaProfile], (profile) =>
  prop('lastName', profile),
);

export const selectLangIso = createSelector([selectGigyaProfile], (profile) =>
  propOr(null, 'languages', profile),
);

export const selectFullName = createSelector(
  [selectFirstName, selectLastName],
  (firstName, lastName) =>
    all(hasValue)([firstName, lastName]) ? `${firstName} ${lastName}` : '',
);

export const selectAge = createSelector([selectGigyaProfile], (profile) =>
  prop('age', profile),
);

export const selectBirthDay = createSelector([selectGigyaProfile], (profile) =>
  prop('birthDay', profile),
);

export const selectBirthMonth = createSelector(
  [selectGigyaProfile],
  (profile) => prop('birthMonth', profile),
);

export const selectBirthYear = createSelector([selectGigyaProfile], (profile) =>
  prop('birthYear', profile),
);

export const selectEC6TimeZone = createSelector([selectEC6UserState], (user) =>
  propOr('UTC', 'timeZone', user),
);

export const selectEC6TimeFormat = createSelector(
  [selectEC6UserState],
  (user) => propOr('24H', 'timeFormat', user),
);

export const selectIs12HourFormat = createSelector(
  [selectEC6UserState],
  (user) =>
    propOr('24H', 'timeFormat', user) === userContants.MERIDIAN_TIME_FORMAT,
);

export const selectEC6FullName = createSelector([selectEC6UserState], (user) =>
  prop('fullname', user),
);

export const selectIsHcpOrGp = createSelector([selectUserProfile], (profile) =>
  includes(profile, [
    userContants.HCP_MASTER_PROFILE,
    userContants.GENERAL_PRACTITIONER,
  ]),
);

export const connectToProfile = (Component) =>
  compose(
    connect(
      createStructuredSelector({
        profile: selectUserProfile,
      }),
    ),
  )(Component);
