import React, { useEffect, useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { ProtectedRoute } from 'src/components/protected-route';
import { StripManagementDashboardInnerRoutes as StripRoutes } from 'src/domains/strip-management/routes';
import { hasValue } from 'src/utils';
import { Column, LoadingRing } from 'src/components';
import {
  ADMINISTRATIVE,
  GENERAL_PRACTITIONER,
} from 'src/core/user/user.constants';

import {
  connectWithDiagnosticsPermissionsAndCheckExternalIdLifecycle,
  hasPatientDiagnostics,
} from './utils';
import {
  BgDashboardGpBundle,
  BgDashboardBundle,
  CgmDashboardBundle,
} from './bundles';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  connectToPermissions,
  PERMISSIONS,
  verifyPermission,
} from 'src/core/permissions';
import { connectToProfile } from 'src/core/user/user.selectors';

import {
  fetchCheckExternalPatient,
  setIsExternalPatient,
} from './bg/store/bg.actions';
import { patientExternalConnector } from './bg/store';
import { mapDispatchers } from 'src/utils';
import { addDiagnosticsPermissionAndCheckExternalIdLifecycle } from 'src/domains/patient-dashboards/utils';
import { withRouter } from 'react-router-dom';

export const patientDashboardsLinks = {
  byId: '/patients/:id',
  bgId: '/patients/bg/:id',
  cgmId: '/patients/cgm/:id',
};

const loadingRing = (
  <Column align="center" height="100vh" justifyContent="center">
    <LoadingRing infinite />
  </Column>
);

const CheckExternal = props => {

  const {
    setIsExternalPatient,
    fetchCheckExternalPatient,
    location,
    match: { params },
    currentPermissions,
    isExternalPatient,
    externalPatient,
    profile,
  } = props;

  const patientId = params.id;
  const urlSearchParams = new URLSearchParams(location.search);
  const externalType = urlSearchParams.get('type') === 'external';

  const [isBusy, setIsBusy] = useState(true);

  useEffect(() => {
    if (externalType) {
      fetchCheckExternalPatient({ externalPatientId: patientId })
    }
  }, []);

  useEffect(() => {
    if (externalPatient.patientId || !externalType) {
      setIsBusy(false);
    }
  }, [externalPatient]);

  if (!hasValue(currentPermissions) || isBusy) {
    return loadingRing;
  }

  if (isExternalPatient) {
    const id = externalPatient.patientId;
    return <Redirect to={id ? patientDashboardsLinks.byId.replace(':id', id) : '/home'} />
  }

  if (profile === ADMINISTRATIVE) {
    return <Redirect to={'/home'} />;
  }

  if (profile === GENERAL_PRACTITIONER) {
    return <Route render={routerProps => <BgDashboardGpBundle {...routerProps} />} />;
  }

  return <Route render={routerProps => <BgDashboardBundle {...routerProps} />} />;
};

const CheckExternalConnected = compose(
  withRouter,
  connectToPermissions,
  connectToProfile,
  connect(
    patientExternalConnector,
    mapDispatchers({
      setIsExternalPatient,
      fetchCheckExternalPatient: fetchCheckExternalPatient.start,
    }),
  ),

)(CheckExternal);

export const DashboardRoutes = () => (
  <Switch>
    <ProtectedRoute
      exact
      path={patientDashboardsLinks.byId}
      component={connectWithDiagnosticsPermissionsAndCheckExternalIdLifecycle(
        ({ currentPermissions, ...routerProps }) => {
          const { bgId } = patientDashboardsLinks;
          if (!hasValue(currentPermissions)) return loadingRing;
          if (!hasPatientDiagnostics(currentPermissions))
            return <StripRoutes {...routerProps} />;
          return (
            <Redirect to={bgId.replace(':id', routerProps.match.params.id)} />
          );
        },
      )}
    />

    <ProtectedRoute
      exact
      path={patientDashboardsLinks.bgId}
      component={() => <CheckExternalConnected />}
    />

    <ProtectedRoute
      exact
      path={patientDashboardsLinks.cgmId}
      component={connectWithDiagnosticsPermissionsAndCheckExternalIdLifecycle(
        ({ currentPermissions, profile, ...routerProps }) => {
          if (!hasValue(currentPermissions)) return loadingRing;
          if (profile === ADMINISTRATIVE) return <Redirect to={'/home'} />;
          return <CgmDashboardBundle {...routerProps} />;
        },
      )}
    />
  </Switch>
);
