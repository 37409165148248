// @ts-nocheck
import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';
import { formatDateString } from 'src/utils';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const PatientSearchLoaderImpl = (
  { patientID, fullName }: any,
  accessToken: string,
) => {
  const healthCareId = patientID ? patientID.replace(/\s/g, '') : patientID;
  const name = fullName ? fullName.trim() : fullName;
  const freeText = name ? `?freeText=${encodeURIComponent(name)}` : '';

  return getJSON(endpointWithParams(ENDPOINTS.patientSearch, { freeText }), {
    headers: {
      Authorization: createAuthHeader(accessToken),
      ...(!!healthCareId && { healthCareId }),
    },
  });
};

export const PatientSearchTransformImpl = (results) =>
  results.model.patientList.map((d) => ({
    id: d.id,
    healthCareSystemId: d.healthCareSystemId,
    fullName: d.user.fullname,
    birthDate: formatDateString({
      dateString: d.user.birthday,
      format: 'dd/LL/yyyy',
      timeZone: 'Etc/GMT+0',
    }),
    treatment: d.nameTreatment,
    diabetesType: d.diabetesType,
    fhirId: d.fhirId,
  }));

export const PatientSearchServiceImpl =
  (loader, transform) => (query, accessToken, gigyaToken) =>
    loader(query, accessToken, gigyaToken).then(transform);
