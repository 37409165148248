import { DateTime } from 'luxon';
import { getJSON, createAuthHeader } from 'src/utils/service/service.utils';

import {
  AdjustmentType,
  StockAdjustmentsLoaderImplType,
  StockAdjustmentsTransformType,
  StockAdjustmentsServiceImplType,
} from './stock-adjustments.types';

import { ENDPOINTS, endpointWithParams } from 'src/services/service.constants';

export const StockAdjustmentsLoaderImpl: StockAdjustmentsLoaderImplType = (
  { patientId },
  accessToken: string,
) => {
  return getJSON(
    endpointWithParams(ENDPOINTS.stockAdjustments, { patientId }),
    {
      headers: {
        Authorization: createAuthHeader(accessToken),
      },
    },
  );
};

const getDateFormat = (datestring) => {
  const date = DateTime.fromISO(datestring);
  return date.isValid ? date.toFormat('dd/LL/yyyy') : '';
};

export const StockAdjustmentsTransformImpl: StockAdjustmentsTransformType = (
  adjustmentsList,
) =>
  adjustmentsList
    .filter(
      ({ stockChange, action }) =>
        stockChange !== 0 && action !== 'consumption report',
    )
    .map(({ createdAt, action, lastStock, stockChange }) => {
      return {
        createdAt: getDateFormat(createdAt),
        createdISO: createdAt,
        type:
          action === 'delivery' ? AdjustmentType.AUTO : AdjustmentType.MANUAL,
        before: lastStock,
        delivered: stockChange < 0 ? 'N/A' : stockChange,
        after: lastStock + stockChange,
        lost: action === 'lost strips report' ? stockChange : 'N/A',
      };
    });

export const StockAdjustmentsServiceImpl: StockAdjustmentsServiceImplType =
  (
    load: StockAdjustmentsLoaderImplType,
    transform: StockAdjustmentsTransformType,
  ) =>
  (options, accessToken, gigyaToken) =>
    load(options, accessToken, gigyaToken).then(transform);
