// tslint:disable
import { DateTime } from 'luxon';

import { TimeInterval } from './../../time-periods.types';

export const TOTAL_BAR_SECONDS = 108000;
export const TOTAL_DAY_SECONDS = 86400;
export const TWELVE_HOURS_SECONDS = 43200;
export const THREE_HOURS_SECONDS = 10800;
export const THIRTY_MINUTES_SECONDS = 1800;
export const FIFTEEN_MINUTES_SECONDS = 900;

/**
 * Given two times, '06:00:00' & '09:00:00' returns a percentage of the time between both.
 */
export const convertTimeToWidth = (start, end) => {
  const startS = convertTimeToS(start);
  let endS = convertTimeToS(end);
  if (startS > endS) endS += TOTAL_DAY_SECONDS;
  return (100 * Math.abs(startS - endS)) / TOTAL_BAR_SECONDS;
};

/**
 * TODO
 */
export const convertTimeToLeftPosition = (startTime, zeroReference) => {
  let timeS = convertTimeToS(startTime);
  if (timeS < zeroReference) timeS += TOTAL_DAY_SECONDS;
  zeroReference = zeroReference - THREE_HOURS_SECONDS;
  return (100 * Math.abs(timeS - zeroReference)) / TOTAL_BAR_SECONDS;
};

/**
 * TODO
 */
export const firstHandlerLeftPosition = (startTime, zeroReference) => {
  let timeS = convertTimeToS(startTime);
  let leftHours = zeroReference - THREE_HOURS_SECONDS;
  if (leftHours < 0) leftHours += TOTAL_DAY_SECONDS;
  if (leftHours > timeS) timeS += TOTAL_DAY_SECONDS;
  return (100 * Math.abs(leftHours - timeS)) / TOTAL_BAR_SECONDS;
};

/**
 * Given a TimeInteval[] return an array of strings of sequential time gaps
 * f.i. ...rest.timeStart: 10:00:00 returns [7:00:00, 10:00:00, 16:00:00 ...
 */
export const getGapsFromPeriodsArray = (periods: TimeInterval[]): string[] => {
  const reference = periods[7].startTime;
  const time = DateTime.fromISO(reference);
  const distances = [-3, 0, 6, 12, 18, 24, 27];
  return distances.map((d) => time.plus({ hours: d }).toFormat('HH:mm:ss'));
};

/**
 * Given a time HH:MM:SS returns seconds
 * '12:00:00' -> 43200
 */
export const convertTimeToS = (timeString: string) =>
  timeString
    .split(':')
    .map((intString, index) => {
      const intValue = parseInt(intString, 10);
      switch (index) {
        case 0:
          return intValue * 60 * 60; // hour
        case 1:
          return intValue * 60; // minute
        default:
          return intValue; // second
      }
    })
    .reduce((total, current) => total + current, 0);

/**
 * Given seconds returns HH:MM:SS string
 * 43200 -> '12:00:00'
 */
export const convertSToTime = (seconds: number): string => {
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 8);
};

export const nextLimitAddTotalDaySeconds = (nextLimit) =>
  nextLimit < 0 ? (nextLimit+TOTAL_DAY_SECONDS) : nextLimit;

export const previousLimitAddTotalDaySeconds = (previousLimit) =>
  previousLimit < 0 ? (previousLimit+TOTAL_DAY_SECONDS) : previousLimit;
