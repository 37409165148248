import { includes } from 'ramda';

import { PERMISSIONS as P } from 'src/core/permissions/permissions.constants';

export const removePermissionsEMR = (permissions) => {
  const restrictions = [P.REGISTER_PATIENT, P.CREATE_PROFESSIONAL];
  return includes(P.ELECTRONIC_MEDICAL_RECORD, permissions)
    ? permissions.filter((i) => !includes(i, restrictions))
    : permissions;
};
