import { DateTime } from 'luxon';
import { isEmpty, isNil } from 'ramda';

export const formatDoB = (date) => {
  if (!date) return '-';
  const luxon = DateTime.fromISO(date);
  const format = luxon.toFormat('dd LLL yyyy');
  const age = Math.floor(Math.abs(luxon.diffNow('years').years));
  return `${format} (${age})`;
};

export const formatDiagnosticsDate = (date) => {
  if (!date) return '-';
  const luxon = DateTime.fromISO(date);
  const format = luxon.toFormat('dd LLL yyyy');
  return `${format}`;
};

export const deviceListContainsActivedDevice = (deviceTypes, activeDevices) => {
  return deviceTypes.some((type) => activeDevices?.includes(type));
};

export const hasPatientCGMAssociation = ({ lastMeasurementDate }) => {
  return !isEmpty(lastMeasurementDate) && !isNil(lastMeasurementDate);
};
