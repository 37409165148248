import styled from 'styled-components';

import { weight } from 'src/domains/diagnostics/components';

export const StatusCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

StatusCardWrapper.displayName = 'StatusCardWrapper';

export const StatusCardLabelResult = styled.span`
  font-weight: ${weight.semiBold};
`;

StatusCardLabelResult.displayName = 'StatusCardLabelResult';
