// @ts-nocheck
import * as React from 'react';
import { ModuleAnchorComponent } from '@roche/roche-common';
import { connect } from 'react-redux';
import { selectManifestModuleName } from 'src/core/manifest-modules/manifestModules.selectors';
import {
  VISIT_MODULE_NAME,
  VISIT_MODULE_NAME_OLD,
  VISIT_MODULE_SIDEBAR_ANCHOR_NAME,
} from 'src/modules/visit-module/visit-module-constants';

import { VisitModuleSidebarAnchorConfig } from './visit-module-sidebar-anchor-config';
import { SidebarHeightSetter } from './sidebar-height-setter';

import {
  selectPermissions,
  selectPatientPermissions,
} from 'src/core/permissions';
import { hasVisitModulePermission } from '../visit-module-permissions';
import { withRefreshAccessToken } from 'src/modules/visit-module/with-refresh-access-token';
import { ModuleLoader } from 'src/modules/visit-module/retrocompatibility/load-modules';
import { VisitModuleActiveSection } from 'src/domains/patient/utils/with-jello-wrapper';

interface StateProps {
  moduleName: {};
  hcpPermissions: string[];
  patientPermissions: string[];
}

export interface VisitModuleProps {
  activeSection: VisitModuleActiveSection;
}

class VisitModuleSidebarAnchorComponent extends React.Component<
  VisitModuleProps,
  StateProps
> {
  private sidebarHeightSetter: SidebarHeightSetter = new SidebarHeightSetter();

  public componentDidMount(): void {
    ModuleLoader.emitLoadModuleEvent(VISIT_MODULE_NAME_OLD);
    ModuleLoader.emitLoadModuleEvent(VISIT_MODULE_NAME);
    this.sidebarHeightSetter.setup();
  }

  public componentWillUnmount(): void {
    this.sidebarHeightSetter.cleanup();
  }

  public render() {
    return hasVisitModulePermission(
      this.props.hcpPermissions,
      this.props.patientPermissions,
    ) ? (
      <React.Fragment>
        <VisitModuleSidebarAnchorConfig
          activeSection={this.props.activeSection}
        >
          <ModuleAnchorComponent
            anchorName={VISIT_MODULE_SIDEBAR_ANCHOR_NAME}
            moduleName={this.props.moduleName}
            document={document}
          />
        </VisitModuleSidebarAnchorConfig>
      </React.Fragment>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state): StateProps => ({
  moduleName: selectManifestModuleName(VISIT_MODULE_NAME)(state),
  hcpPermissions: selectPermissions(state),
  patientPermissions: selectPatientPermissions(state),
});

export const VisitModuleSidebarAnchor = connect(mapStateToProps)(
  withRefreshAccessToken(VisitModuleSidebarAnchorComponent),
);
