import * as React from 'react';
import { Route } from 'react-router-dom';

interface ConditionalRouteProps {
  component: JSX.Element;
}

export const ConditionalRouteForVisitModuleStickyPatientSummaryBar: React.FunctionComponent<
  ConditionalRouteProps
> = (props) => (
  <Route
    exact={true}
    path={[
      '/patients/bg/:id',
      '/patients/bg/:id/graph/:allGraphTypes',
      '/patients/bg/:id/graph/blood-glucose-general-stats/all',
      '/patients/bg/:id/graph/logbook',
      '/patients/bg/:id/graph/logbook/:selectedDate?',
      '/patients/bg/:id/graph/logbook-stats/:selectedDate?',
      '/patients/bg/:id/graph/logbook-diary/:selectedDate?',
      '/patients/bg/:id/graph/blood-glucose-general-stats/:deviceId',
      '/patients/bg/:id/blood-glucose-overview',
      '/patients/cgm/:id',
      '/patients/:id/advanced-indicators',
    ]}
    render={() => props.component}
  />
);

export const ConditionalRouteForVisitModulePatientSummaryBar: React.FunctionComponent<
  ConditionalRouteProps
> = (props) => (
  <Route
    exact={true}
    path={[
      '/patients/:patientId/strip-information',
      '/patients/:patientId/strip-information/:anyTab',
      '/patients/:patientId/strip-information/:anyTab/*',
    ]}
    render={() => props.component}
  />
);
