export * from './config/config.selectors';
export { oidcEpics } from './oidc/oidc.epics';
export * from './oidc/oidc.selectors';
export { sessionEpics } from './session/session.epics';
export { sessionReducer } from './session/session.reducer';
export * from './session/session.selectors';
export { SessionActionType } from './session/session.types';
export * from './oidc/oidc.actions';
export * from './session/session.actions';

export const utils = {
  isTokenExpired: (timeout: number): boolean => timeout < Date.now(),
};
