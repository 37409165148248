import { PayloadAction } from 'src/app/store/app.types';
import {
  RpmSettings,
  UpdateRpmSettingsParams,
  TransformedUpdateSettingsErrorResponse,
  TransformedUpdateSettingsSuccessResponse,
} from 'src/services/rpm/rpm-settings/rpm-settings.types';
import { Diff } from 'src/utils';

export enum RpmSettingsActionType {
  FETCH_RPMSETTINGS_START = 'FETCH_RPMSETTINGS_START',
  FETCH_RPMSETTINGS_SUCCESS = 'FETCH_RPMSETTINGS_SUCCESS',
  FETCH_RPMSETTINGS_ERROR = 'FETCH_RPMSETTINGS_ERROR',
  UPDATE_RPMSETTINGS_START = 'UPDATE_RPMSETTINGS_START',
  UPDATE_RPMSETTINGS_SUCCESS = 'UPDATE_RPMSETTINGS_SUCCESS',
  UPDATE_RPMSETTINGS_ERROR = 'UPDATE_RPMSETTINGS_ERROR',
}

export type RpmSettingsPayload = RpmSettings;

export type FetchRpmSettingsStart = PayloadAction<
  RpmSettingsActionType.FETCH_RPMSETTINGS_START,
  RpmSettingsPayload
>;
export type FetchRpmSettingsSuccess = PayloadAction<
  RpmSettingsActionType.FETCH_RPMSETTINGS_SUCCESS,
  RpmSettingsPayload
>;
export type FetchRpmSettingsError = PayloadAction<
  RpmSettingsActionType.FETCH_RPMSETTINGS_ERROR,
  any
>;

export type UpdateRpmSettingsStart = PayloadAction<
  RpmSettingsActionType.UPDATE_RPMSETTINGS_START,
  UpdateRpmSettingsParams
>;
export type UpdateRpmSettingsSuccess = PayloadAction<
  RpmSettingsActionType.UPDATE_RPMSETTINGS_SUCCESS,
  TransformedUpdateSettingsSuccessResponse
>;
export type UpdateRpmSettingsError = PayloadAction<
  RpmSettingsActionType.UPDATE_RPMSETTINGS_ERROR,
  TransformedUpdateSettingsErrorResponse
>;

export type RpmSettingsState = {
  isLoading?: boolean;
  rpmSettings: RpmSettings;
  error?: any;
  updateHasError?: boolean;
  updateWasSuccessful?: boolean;
  isUpdatingSettings?: boolean;
};

export type RpmSettingsEpicActions = FetchRpmSettingsStart;

export type RpmSettingsActions =
  | FetchRpmSettingsStart
  | FetchRpmSettingsSuccess
  | FetchRpmSettingsError
  | UpdateRpmSettingsStart
  | UpdateRpmSettingsSuccess
  | UpdateRpmSettingsError;

export type RpmSettingsReducerActions = Diff<
  RpmSettingsActions,
  RpmSettingsEpicActions
>;
