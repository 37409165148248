import { isEmpty } from 'ramda';

import { colors, spacing } from '../../../../core';
import {
  BodyRowType,
  HeaderRowType,
} from '../../components/table-primary/table-primary.types';

export const getFirstItemLabel = (patientDevices) =>
  patientDevices && !isEmpty(patientDevices)
    ? patientDevices[0].label
    : 'There is no device associated with patient.';

export const getDropdownPlaceholderMessage = (
  patientDevicesStatus,
  patientDevices,
) => {
  switch (patientDevicesStatus) {
    case 'loading':
      return 'Devices are loading.';
    case 'failure':
      return 'There was problem with loading of your devices.';
    case 'success':
      if (isEmpty(patientDevices))
        return 'There is no device associated with patient.';
      return null;
    default:
      return 'There was problem with loading of your devices.';
  }
};

export const getCellStyledProps = () => ({
  [HeaderRowType.Extended]: {
    semiBold: true,
  },
  [HeaderRowType.Basic]: {
    semiBold: true,
  },
});

export const getRowStyledProps = () => ({
  [HeaderRowType.Basic]: {
    paddingBottom: spacing.two,
    borderBottom: true,
  },
  [HeaderRowType.Extended]: {
    backgroundColor: colors.silverLight,
  },
  [HeaderRowType.Empty]: {},
  [BodyRowType.Basic]: {
    borderBottom: true,
  },
});

export const UNITS = {
  INSULINE: 'Insuline',
  INSULINE_UNIT: 'U',
  CARBO: 'Carbohydrates',
  GLUCO: 'BloodGlucose',
  MINUTES: 'Min',
  BGUNIT: 'mg/dL',
  CARBUNIT: 'g',
  HOURS: 'H',
  HOURS_SMALL: 'h',
  PERCENTAGE: '%',
};

export const doesPreviousSettingExists = (
  deviceSettingsList,
  previousDeviceSettings,
) => {
  return deviceSettingsList.length >= 2 && !isEmpty(previousDeviceSettings);
};

export const convertToHourMinuteFormat = (stringTime, is12HourFormat) => {
  const moment = require('moment');
  if (!stringTime) return '';
  const hourMinuteFormatConverted = moment(stringTime, [
    'HH:mm:ss',
    'HH:mm',
  ]).format('HH:mm');

  return is12HourFormat
    ? moment(hourMinuteFormatConverted, 'HH:mm').format('h:mm A')
    : hourMinuteFormatConverted;
};
