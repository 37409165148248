import { match } from 'react-router';
import { I18nTranslate } from 'src/utils/i18n/i18n.utils.types';

export type TimeInterval = {
  id: null;
  description: TimeIntervalDescription;
  startTime: string;
  endTime: string;
  label: null;
};

export enum TimeIntervalDescription {
  BEFORE_BREAKFAST = 'BEFORE_BREAKFAST',
  AFTER_BREAKFAST = 'AFTER_BREAKFAST',
  BEFORE_LUNCH = 'BEFORE_LUNCH',
  AFTER_LUNCH = 'AFTER_LUNCH',
  BEFORE_DINNER = 'BEFORE_DINNER',
  AFTER_DINNER = 'AFTER_DINNER',
  BEDTIME = 'BEDTIME',
  NIGHT = 'NIGHT',
}

export type TimePeriodsComponentProps = {
  isReadyToRender: boolean;
  isSavingBlocks: boolean;
  isSavingBlocksError: boolean;
  is12hourTimeFormat: boolean;
  blocksWereSaved: boolean;
  goTo: (url) => any;
  resetStatus: () => void;
  fetchTimeBlocks: (id) => any;
  onUpdateTimePeriods: (patientId: number, periods: TimeInterval[]) => void;
  moveTimePeriods: (periods: TimeInterval[]) => void;
  match: match<{ id: string }>;
  patientId: number;
  periods: TimeInterval[];
  createModal: (any) => void;
  destroyModal: () => void;
  t: I18nTranslate;
};

export type TimePeriodsComponentState = {
  isDirty: boolean;
  validTimePeriods?: TimeInterval[];
  graphUpdated: boolean;
  dropDownUpdated: boolean;
};

export type TimePeriodsLifeCycleProps = {
  fetchTimeBlocks: (id) => any;
  match: match<{ id: string }>;
};
