import React from 'react';

import { colors } from 'src/core/styles/colors';

import { SvgIcon } from 'src/components/icon';

type Props = {
  height: number,
  fillColor?: string,
  strokeColor?: string,
};

export const V3StockIcon = ({
  fillColor = '#508EE5',
  height = 15,
  strokeColor = colors.white,
}: Props) => {
  const originalWidth = 17;
  const originalHeight = 23;

  const aspectRatio = originalWidth / originalHeight;

  return (
    <SvgIcon
      height={height}
      fillColor={fillColor}
      originalHeight={originalHeight}
      originalWidth={originalWidth}
      width={height * aspectRatio}
    >
      <g
        id="v3-stock"
        stroke={strokeColor}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Main-Menu"
          transform="translate(-124.000000, -139.000000)"
          fill={fillColor}
        >
          <g id="Group-20" transform="translate(111.000000, 130.000000)">
            <g id="Stock" transform="translate(1.000000, 9.000000)">
              <g id="stock-icon" transform="translate(12.000000, 0.000000)">
                <g id="Group-19">
                  <path
                    d="M1,7 C1.55228475,7 2,7.44771525 2,8 L2,22 C2,22.5522847 1.55228475,23 1,23 C0.44771525,23 6.76353751e-17,22.5522847 0,22 L0,8 C-6.76353751e-17,7.44771525 0.44771525,7 1,7 Z"
                    id="Rectangle"
                  ></path>
                  <path
                    d="M6,7 C6.55228475,7 7,7.44771525 7,8 L7,22 C7,22.5522847 6.55228475,23 6,23 C5.44771525,23 5,22.5522847 5,22 L5,8 C5,7.44771525 5.44771525,7 6,7 Z"
                    id="Rectangle"
                  ></path>
                  <path
                    d="M16,7 C16.5522847,7 17,7.44771525 17,8 L17,22 C17,22.5522847 16.5522847,23 16,23 C15.4477153,23 15,22.5522847 15,22 L15,8 C15,7.44771525 15.4477153,7 16,7 Z"
                    id="Rectangle"
                  ></path>
                  <rect
                    id="Rectangle"
                    x="10"
                    y="0"
                    width="2"
                    height="23"
                    rx="1"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
