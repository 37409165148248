import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withTranslation as translateHOC } from 'react-i18next';
import { mapDispatchers } from 'src/utils/map-dispatchers';

import { unitConector } from './store/user-units-selector.selectors';
import { saveUserUnits, getUserUnits, clearUserUnitRequest } from './store';
import { UserUnitSelectorComponent } from './user-units-selector.component';

export const UserUnitSelectorContainer = compose<any, any>(
  connect(
    unitConector,
    mapDispatchers({
      goTo: (path) => push(path),
      saveUserUnits: saveUserUnits.start,
      getUserUnits: getUserUnits.start,
      clearUserUnits: clearUserUnitRequest,
    }),
  ),
  lifecycle<any, void>({
    componentWillUnmount() {
      const { clearUserUnits } = this.props;
      clearUserUnits();
    },
  }),
  translateHOC('translations'),
)(UserUnitSelectorComponent);
